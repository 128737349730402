import { Injectable, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService } from '../../general';
import { RippleApiService } from '../../ripple-api/ripple-api.service';
import * as moment_ from 'moment';
const moment = moment_;
import { ActivationEnd, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { environment } from '@ripple/environment';

export interface LogRequest {
  firstDate?: Date;
  secondDate?: Date;

  page: number;
  pageSize?: number;

  entityIds: number[];
  entityTypeIds?: number[];
  userIds?: number[];
  childEntityTypes?: number[];
  actionTypes?: string[];
}

export interface RevisionHistorySnapshot
{
    id: number;

    entityID: number;
    entityTypeID: number;
    entityTypeName: string;

    /** DateTime string */
    performed: string;
    performedBy: string;
    userID: number;

    action: string;
    content: string;

    label: string;
    entityName: string;
    form: boolean;
}

export interface Revision {
  label: string;
  unchangeableName: string;
  change: string;
}

@Injectable({
  providedIn: 'root',
})
export class WarpEntityLogService extends RippleApiService {
  private restURL = '/api/v2/warpentitylog';
  private components: Map<string, any> = new Map();

  constructor(
    http: HttpClient,
    authService: AuthService,
    messageService: MessageService,
    private router: Router,
    private confirm: ConfirmationService
  ) {
    super(http, messageService, authService);

    authService.loggedIn.subscribe((login) => {
      if (authService.getSyncLoggedInUser() != null) {
        if (login)
          this.logView([], 'Logged In');
        else
          this.logView([], 'Logged Out');
      }
    });

    router.events.subscribe((event) => {
      if (
        event instanceof ActivationEnd &&
        event.snapshot.component instanceof Type
      ) {
        const componentName = (<any> (event.snapshot.component)).prototype.componentName;
        if (!environment.logExemptComponents.includes(componentName)) {
          // Grab component so we can log even if it gets destroyed before the timeout hits
          var component = this.components.get(componentName);
          // Set a timeout so the component has a chance to load its variables
          setTimeout(() => {
            if (!component) component = this.components.get(componentName);

            if (component && component.logFromUrl != undefined) {
              const entities = component.logFromUrl(this.router.url);
              const format = component.getLogFormat(this.router.url);

              if (
                !entities ||
                (typeof entities === 'number' &&
                  (isNaN(entities) || entities <= 0)) ||
                (entities instanceof Array && entities.length == 0 && !format)
              )
                return;

              this.messageService.add(
                this.constructor.name,
                `Logging ${componentName} (${format}, ${entities})`
              );
              this.logView(entities, format);
            } else {
                if (window.location.href.includes('localhost'))
                  this.sendBasePageError(componentName);
            }
          }, 1000);
        }
      }
    });
  }

  public setComponent(component: any) {
    this.messageService.add('WarpEntity Log Service', `Set Component: ${component.componentName}`, component);
    this.components.set(component.componentName, component);
  }

  public removeComponent(component: any) {
    this.components.delete(component.componentName);
  }

  public sendBasePageError(name: string) {
    this.confirm.confirm({
      header: `BasePage Warning`,
      message: `<p>Warning: <b>${name}</b> is not extending <b>BasePage</b> and/or overriding it's methods. Go to the <b>${name}</b> and extend <b>BasePage</b>. There are two methods you need to override, <b>logFromUrl(url: string)</b> and <b>getLogFormat(url: string)</b>. Both are called at the same time whenever there is a router change and the url is passed into the variable in case you need to change the return based on the url.</p>
      <br/>
      <p>When a router event registers that this component has been activated, it waits <b>500ms</b> before logging from that component. This is so your component has time to fill all its variables based on the route. There are some very rare cases where the component does not load in time (usually either never happens or always happens for that component). In this case you will have to parse the provided url parameter.</p>
      <br/>
      <p>By implementing these methods, the <b>WarpEntityLogService</b> will automatically log when entities or a page is viewed according to the given implementation. It should be very rare for you not to log something.</p>
      <br/>
      <p><b>logFromUrl(url: string)</b> is a method in which you may return (number | number[]). You should return the entity or entities you wish to log as viewed upon hitting a route that activates or reactivates that component again.</p>
      <p> <b>NOTE:</b> You should always <b>return the primary entity in the first index of the array</b>, that is the primary entity used by the log as the actual entity being viewed along with the entity type being viewed</p>
      <p> - If you want to log an entity or entities as viewed return their id(s). (primary entity must go in first index)</p>
      <p> - If you want to log something as viewed that doesnt directly tie to an entity, return [] (Ex. "Viewed report page").</p>
      <p> - If you dont want to log anything, return -1 (This should be a rare case!)</p>
      <br/>
      <p><b>getLogFormat(url: string)</b> is a method in which you may return a string. You should return the format for how this component's log will appear in the logs.</p>
      <p> - If you returned an entity or entities in logFromUrl you can reference them in your format by using {index} placeholders. (Ex. "Viewed {0}'s files" where in logFromUrl you might have returned [123])</p>
      <p> - If you returned an entity but dont need a specific format, you can return '' and it will automatically default to 'Viewed {0}'</p>
      <p> - If you returned [] in logFromUrl you can just specify a static format that doesnt relate to any entities (Ex. "Viewed report page")</p>
      <p> - If you dont want to log anything, return '' (This should be a rare case!)</p>`,
      rejectVisible: false,
      acceptLabel: `I will implement BasePage in ${name}`,
      blockScroll: true,
      key: 'basepage-dialog',
    });
    console.error(new Error(`${name} is not extending BasePage`));
  }

  public getRevisionHistory(body: LogRequest) {
    return this._post<RevisionHistorySnapshot[]>(this.restURL, body)
      .toPromise();
  }

  public logView(entities: number[] | number, format: string) {
    if (typeof entities == 'number') entities = [entities];
    const body = { entities, format };
    return this._post(this.restURL.concat('/view'), body,  "WarpEntityLogService.logView()").toPromise();
  }

  public getChanges(revisionId: number, entityId: number): Promise<{
    changes?: Revision[],
    formChanges?: Revision[]
  }> {
    return this._get(
      this.restURL.concat(`/changes/${revisionId}/${entityId}`)
    ).toPromise();
  }

  public getEntityTypes(entityTypeIds: number[]): Promise<any> {
    const body = { entityTypeIds };
    return this._post('/api/v2/entitytypes/info', body, "WarpEntityLogService.getEntityTypes()").toPromise();
  }

  public getRecentlyTouched(entityTypeIds: number[], count = 10): Promise<number[]> {
    const body = { entityTypeIds, count };
    return this._post( this.restURL.concat(`/recentlyTouched`), body).toPromise();
  }
}
