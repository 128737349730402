<div class="p-grid p-grid-layout p-justify-center">
    <div class="p-col-12 p-xl-10">
      <ripple-form-builder #fb action="build" [formID]="formid" class="p-d-flex p-flex-column p-p-2" [hideControls]="true"></ripple-form-builder>
    </div>
</div>
  
<div class="sidebar-controls">
    <div class="sidebar-controls__inner">
        <div class="sidebar-controls__buttons">
        <div class="funky-button">
            <button pButton (click)="fb.openAddSection()" icon="pi pi-plus"></button>
            <label>Add a Section</label>
        </div>
        <div class="funky-button" *ngIf="fb.sections.length > 1">
            <button pButton (click)="fb.openMoveSections()" icon="pi pi-sort"></button>
            <label>Re-order Sections</label>
        </div>
        <hr class="w-100"/>
        <div class="funky-button">
            <button pButton (click)="fb.showVersions()" icon="pi pi-list"></button>
            <label>Versions</label>
        </div>
        <div class="funky-button">
            <button pButton (click)="fb.switchViewMode()" icon="pi pi-eye"></button>
            <label>{{fb.userUse ? 'Builder' : 'Preview'}}</label>
        </div>
        <hr class="w-100"/>
        <div class="funky-button">
            <button pButton (click)="fb.saveFormBuilt(fb.toPublish)" [disabled]="fb.saving"
            icon="pi pi-{{fb.saving ? 'spin pi-cog' : 'check'}}">
            </button>
            <label>{{fb.toPublish ? 'Publish' : 'Save'}}</label>
        </div>
        <div class="funky-button">
            <button pButton (click)="remove()" icon="pi pi-trash"></button>
            <label>Delete Form</label>
        </div>
        <hr class="w-100"/>
        <div class="funky-button">
            <button pButton routerLink=".." icon="pi pi-home"></button>
            <label>Back</label>
        </div>
        </div>
    </div>
</div>