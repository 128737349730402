<div class="pane pane--lg-h100">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
            <div class="header__heading">
                <ripple-breadcrumb [items]="breadcrumbs"></ripple-breadcrumb>
            </div>
            <div class="header__actions">
                <ripple-button-list [buttons]="splitButtons"></ripple-button-list>
                <button pButton label="Referral" icon="pi pi-external-link"
                    *ngIf="showReferralButton"
                    (click)="refer()"></button>
                <button pButton label="Unrefer" icon="pi pi-external-link"
                    *ngIf="showUnreferButton"
                    (click)="unRefer()"></button>
                <button pButton label="Original" icon="pi pi-external-link"
                    *ngIf="claresLaw && claresLaw.originalclareslaw"
                    (click)="goToOriginal()"></button>
                <button pButton label="Back" icon="pi pi-arrow-left" (click)="back()"></button>
            </div>
        </div>
    </div>
    <div class="pane__body">
        <div class="p-grid h-100">
            <div class="p-col h-100">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-bars"></i> Details</h3>
                      </div>
                      <p-steps [model]="statusMenuItems" [(activeIndex)]="activeIndex"></p-steps>
                    </div>
                    <p-tabView class="h-100" [(activeIndex)]="tabIndex">
                        <p-tabPanel>
                            <ng-template pTemplate = "header">
                                <i class="pi pi-question-circle"></i>
                                &nbsp;
                                <span>Request</span>
                            </ng-template>
                            <ripple-scroll-card class="clares-law-details-tab">
                                <div class="p-grid">
                                    <div class="p-col-12 p-md-6">
                                        <ripple-entity-details [entity]="claresLawSensitiveInformation" [hideButtons]="true" *ngIf="claresLawSensitiveInformation"
                                            [generatorOptions]="sensitiveInfoGeneratorOptions" [fieldOrders]="sensitiveInfoFieldOrders"
                                        ></ripple-entity-details>
                                        <ripple-entity-details [entity]="claresLaw" [hideButtons]="true"
                                            [generatorOptions]="generatorOptions" [fieldOrders]="fieldOrders"
                                            (afterSave)="afterSave($event)" #ed></ripple-entity-details>
                                    </div>
                                    <div class="p-col-12 p-md-6">
                                        <div class="ui-widget">
                                            <label>
                                                Relevant Considerations
                                            </label>
                                        </div>
                                        <sagesse-tags [entity]="claresLaw" [claresLawOnly]="true"></sagesse-tags>
                                    </div>
                                </div>
                            </ripple-scroll-card>
                        </p-tabPanel>
                        <p-tabPanel *ngIf="claresLaw?.clareslawstatus?.id === ClaresLawStatus.COMPLETE || claresLaw?.clareslawstatus?.id === ClaresLawStatus.REFERRALREFUSED">
                            <ng-template pTemplate = "header">
                                <i class="pi pi-info-circle"></i>
                                &nbsp;
                                <span>Result</span>
                            </ng-template>
                            <ripple-loading-box [loading]="fb.isSubmitting || fb.isSaving" [fullHeight]="true">
                                <ripple-scroll-card class="clares-law-details-tab">
                                    <ripple-form-builder
                                        #fb
                                        [(formID)]="linkedForm.properties.formtype"
                                        [formData]="formData"
                                        action="view"
                                        style="display: none"
                                    >
                                    </ripple-form-builder>
                                    <sagesse-clares-law-result [filledForm]="linkedForm" [formData]="formData" [viewOnly]="true"></sagesse-clares-law-result>
                                </ripple-scroll-card>
                               
                              </ripple-loading-box>
                        </p-tabPanel>
                    </p-tabView>
                   
                </ripple-scroll-card>
            </div>
            <div class="p-md-3 p-sm-12 h-100" *ngIf="claresLaw && claresLaw.entityId > 0">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-list"></i> Notes</h3>
                      </div>
                    </div>
                    <ripple-entity-notes [header]="false" [entity]="claresLaw" noteType="Clare's Law Note" [showNoteTypes]="['Clare\'s Law Note']">
                    </ripple-entity-notes>
                </ripple-scroll-card>
            </div>
        </div>
    </div>
</div>
  
  