import { Component, ChangeDetectionStrategy } from '@angular/core';
import { SectionWrapper } from '../section-wrapper';

@Component({
  selector: 'ripple-form-wrapper-table-row',
  template: `
    <tr>
      <td>
        <label *ngIf="to.label && to.hideLabel !== true" [for]="id">
          {{ to.label }}
          <span *ngIf="to.required && to.hideRequiredMarker !== true" aria-hidden="true">*</span>
        </label>
      <td>
      <td>
        <ng-container #fieldComponent></ng-container>
      </td>
      <td>
        <small *ngIf="showError" class="p-error">
          <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
        </small>
      </td>
    </tr>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormWrapperTableRowComponent extends SectionWrapper {
}
