import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ccasa-report-questions',
  templateUrl: './report-questions.component.html',
  styleUrls: ['./report-questions.component.scss']
})
export class ReportQuestionsComponent implements OnInit {
    filtered = false;
    @Input() inQuestion: any;
    @Input() selectedValue: number;
    @Input() required: boolean;
    @Output() filterTicket: EventEmitter<any> = new EventEmitter(); 

    public question: any;
    public totalPercent: any = 0;
    public total: any = 0;

  constructor() { }

  ngOnInit() {
      // console.log('Report Questions.', this);
    this.init(this.inQuestion);
  }

  init(question:any) {
    this.question = question;
    if (this.question.isCheckbox) {
        this.question.checkBoxChoice.sort(this.sortAnswers);
        this.question.checkBoxChoice.forEach(c => {
            this.total += c.result;
        });
    } else if (this.question.isRadio) {
        this.question.customFieldChoices.sort(this.sortAnswers);
        this.question.customFieldChoices.forEach(c => {
            this.total += +c.result;
            if (c.percentage)
                this.totalPercent += (+c.percentage * 1);
        });
    }

   // console.log("Init", this.question)
}

    sortAnswers = (n1, n2) => {
        if (n1.result < n2.result) {
            return 1;
        }
        if (n1.result > n2.result) {
            return -1;
        }
        return 0;
    }



UpdateFilter(item) {
    console.log('UpdateFilter in question', item);
    item.filtered = !item.filtered;
    this.filterTicket.emit(item);
}

}