import { Guid } from 'guid-typescript';
import type {
  IUpdateable,
  Identifiable,
  ISectionFrontendTemplate,
  IFieldFrontendTemplate
} from '@ripple/models';

import { FormField } from './form-field-general';
import type { SectionWrapperName } from '../service-resources/question-type-names';
import type { FormBuilderComponent } from '../form-builder.component';

export class FormSection implements IUpdateable, Identifiable {
  internalID: string = Guid.create().toString();

  _containsCustomJson = false;
  get containsCustomJson() {
    return this._containsCustomJson || !!this.customRenderIdentifier || this.children.some(c => c.containsCustomJson);
  }
  set containsCustomJson(value: boolean) {
    this._containsCustomJson = value;
  }

  customRenderIdentifier = '';
  internalType: 'section' = 'section';
  wrapper: SectionWrapperName = 'panel'; // must be an entry in wrappers
  sectionName = '';
  children: (FormSection | FormField)[] = [];

  deletable: boolean;
  editable: boolean;
  hidden: boolean;
  /**
   * Template sections are used to bulk edit many questions that have the same options/settings.
   */
  isTemplateSection: boolean;

  className = '';

  INDEX_OF_SUB_SECTION_ARRAY = 0; // For now it's 1 because index 0 is for the add subsection button in the fieldGroups array

  parent: FormBuilderComponent | FormSection;

  get label() {
    return this.sectionName;
  }

  get viewOnly() {
    return this.parent.viewOnly;
  }

  get hideOptions() {
    return this.parent.hideOptions;
  }

  constructor(
    parent: FormBuilderComponent | FormSection,
    data: Partial<ISectionFrontendTemplate> = { },
    private optionIds: Map<string, number> = new Map(),
  ) {
    this.parent = parent;
    this.deletable = true;
    this.editable = true;
    this.hidden = false;

    this.updateSelf(data, true);
  }

  get fieldGroups() { return this.children.map(c => c.getJson()); }

  get name() { return this.sectionName ? this.sectionName : 'Sample'; }

  iterateFields(callback: (field: FormField) => void) {
    this.children.forEach( (element: FormField | FormSection) => {
      if (element instanceof FormField)
        callback(element);
      else
        element.iterateFields(callback);
    });
  }

  getByKey(key: string): FormSection | FormField | null {
    for (const child of this.children as any[]) {
      if (child.key == key) return child;
    }

    return null;
  }

  getById(childId: string): FormSection | FormField | null {
    if (this.internalID === childId) return this;
    for (const child of this.children) {
      const ret = child.getById(childId);
      if (ret) return ret;
    }
    return null;
  }

  addSubSection(subSection: FormSection, updateBuilder?: boolean);
  addSubSection(subSectionData: Partial<ISectionFrontendTemplate>, optionIds?: Map<string, number>, updateBuilder?: boolean);
  addSubSection(subData: Partial<ISectionFrontendTemplate> | FormSection, opts: Map<string, number> | boolean = true, update?: boolean) {
    if (subData instanceof FormSection) {
      subData.parent = this;
      this.children.push(subData);
      if (opts) this.getFormBuilder().updateFields();
    } else {
      if (!(opts instanceof Map)) opts = new Map();
      this.addNewSubSection(subData, opts as Map<string, number>, update);
    }
  }

  addNewSubSection(
    subSectionData: Partial<ISectionFrontendTemplate>,
    optionIds: Map<string, number> = new Map(),
    updateBuilder: boolean = true
  ) {
    this.children.push( new FormSection(this, subSectionData, optionIds) );
    if (updateBuilder) this.getFormBuilder().updateFields();
  }

  addAField(fieldModel: Partial<IFieldFrontendTemplate>, optionIds: Map<string, number> = new Map(), updateBuilder = true): string {
    const field = new FormField(this, fieldModel, optionIds);

    this.children.push(field);

    if (updateBuilder)
      this.getFormBuilder().updateFields();

    return field.key;
  }

  getChildIndex(childId: string): number {
    return this.children.findIndex(c => c.internalID === childId);
  }

  getChild(childId: string): FormField | FormSection {
    const index = this.getChildIndex(childId);
    return index === -1 ? null : this.children[ index ];
  }

  updateChild(childId: string, data: Partial<IFieldFrontendTemplate> | Partial<ISectionFrontendTemplate>) {
    this.getChild(childId).updateSelf(data);
    this.getFormBuilder().updateFields();
  }

  deleteChild(childId: string) {
    const index = this.getChildIndex(childId);
    this.children.splice(index, 1);
  }

  getFormBuilder(): FormBuilderComponent {
    if (this.parent instanceof FormSection)
      return this.parent.getFormBuilder();
    else
      return this.parent; // FormBuilderComponent
  }

  updateSelf(data: Partial<ISectionFrontendTemplate>, hard = false): FormSection {
    this.getPropertyNames()
      .forEach((propName) => this[propName] = propName in data ? data[propName] : this[propName]);

    if (hard && data.subData && data.subData.length) {
      this.children = [];
      data.subData.forEach(child => {
        if (child.internalType === 'section') this.addSubSection(child, this.optionIds, false);
        else  if (child.internalType === 'field') this.addAField(child, this.optionIds, false);
      });
    }

    return this;
  }

  private getPropertyNames() {
    const oldKeys = [
      'sectionName', 'className', 'deletable', 'editable', 'hidden'
    ];

    return Array.from(new Set(
      Object.keys(this)
      .concat(oldKeys)
      .filter( key => !['parent', 'children', '_containsCustomJson'].includes(key))
    ));
  }

  // for backend saves (ripple to use)
  exportSelf(data: Partial<ISectionFrontendTemplate> = { }): ISectionFrontendTemplate {
    const retVal = { } as ISectionFrontendTemplate;

    this.getPropertyNames()
      .forEach((propName) => retVal[propName] = propName in data ? data[propName] : this[propName]);

    return {
      ...retVal,
      ...data,
      internalType: this.internalType,
      wrapper: this.wrapper,
      customRenderIdentifier: this.customRenderIdentifier,
      containsCustomJson: this._containsCustomJson || !!this.customRenderIdentifier,
      sectionName: this.sectionName,
      className: this.className,
      deletable: this.deletable,
      editable: this.editable,
      hidden: this.hidden,
      subData: this.children.map( c => c.exportSelf() ),
    };
  }

  // for formly to use
  getJson() {
    const formBuilder = this.getFormBuilder();

    const allowAddField = !this.isTemplateSection || this.children.length === 0;
    // for now, template sections cannot have children
    const allowAddSection = !this.isTemplateSection;

    const editControls = (!this.editable) ? [] : [
      {
        label: 'Edit Section', icon: 'pi pi-fw pi-refresh',
        command: () => formBuilder.openUpdateSection(this.internalID)
      },
      // {
      //   label: 'Move Sections', icon: 'pi pi-fw pi-sort-alt',
      //   command: () => formBuilder.openMoveSections()
      // },
      {
        label: 'Move Sub-Components', icon: 'pi pi-fw pi-sort-alt',
        command: () => formBuilder.openMoveSubSection(this.internalID)
      },
      ...(!allowAddSection ? [] : [{
        label: 'Add a Sub-Section', icon: 'pi pi-fw pi-plus',
        command: () => formBuilder.openAddSection(this.internalID)
      }]),
      ...(!allowAddField ? [] : [{
        label: 'Add a Field', icon: 'pi pi-fw pi-plus',
        command: () => formBuilder.openAddField(this.internalID)
      }]),
      {
        label: 'Copy Field', icon: 'pi pi-fw pi-plus',
        command: () => formBuilder.openCopyField(this.internalID)
      }
    ];

    const deleteButton = this.deletable ? [{
      label: 'Delete Section', icon: 'pi pi-fw pi-times',
      command: () => formBuilder.openDeleteASection(this.internalID) }] : [];

    return {
      internalID: this.internalID,
      className: '' + this.className,
      key: '',
      wrappers: [ this.wrapper ],
      hideExpression: () => this.hideOptions && this.hidden,
      templateOptions: {
        isTemplateSection: this.isTemplateSection,
        isSection: true,
        isHidden: this.hidden,
        title: this.name,
        onSaveClick: ($event) => formBuilder.openUpdateSection(this.internalID),
        onHideClick: ($event) => {
          this.hidden = !this.hidden;
          formBuilder.updateFields();
          formBuilder.workingChanges.emit(true);
          return this.hidden;
        },
        items: [
          ...editControls,
          ...deleteButton
        ],
        containsCustomJson: this.containsCustomJson,
        hideOptions: this.hideOptions
      },
      fieldGroupClassName: 'p-grid ui-fluid',
      fieldGroup: this.fieldGroups
    };
  }
}
