import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomFieldInModule, EntityFilter, IGenFormlyOptions } from '@ripple/models';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService } from '@ripple/services';
import { EntityListComponent } from '@ripple/ui';
import { Office, EntityTypes, Organization } from '../../../_core/models';

@Component({
  selector: 'sagesse-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.scss']
})
export class OfficesComponent implements OnInit {

  @Input() organization: Organization;

  @ViewChild('officeList') contactList: EntityListComponent;

  officeService: WarpEntityServiceCache<Office>;
  filter: EntityFilter;

  officeSelected: Office;
  officeDialogShow = false;
  fieldOrders = [
    'ishead',
    'name',
    'address 1',
    'address 2',
    'city',
    'province',
    'country',
    'postal code',
    'phonenumber'  
  ];
  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => !this.fieldOrders.includes(cfim.unchangeableName.toLowerCase()),
    styles: {
      name: 'p-sm-12 p-md-12 ui-fluid',
      'address 1': 'p-sm-12 p-md-12 ui-fluid',
      'address 2': 'p-sm-12 p-md-12 ui-fluid',
      city: 'p-sm-12 p-md-12 ui-fluid',
      province: 'p-sm-12 p-md-12 ui-fluid',
      country: 'p-sm-12 p-md-12 ui-fluid',
      'postal code': 'p-sm-12 p-md-12 ui-fluid',
      ishead: 'p-sm-12 p-md-12 ui-fluid',
      phonenumber: 'p-sm-12 p-md-12 ui-fluid'
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService
  ) {
    this.officeService = factory.get(EntityTypes.Office) as WarpEntityServiceCache<Office>;
  }

  ngOnInit(): void {
    this.filter = EntityFilter.Advanced({organization_lid: this.organization.entityId});
  }

  edit = (office: Office) => {
    this.officeSelected = office;
    this.officeDialogShow = true;
  };
  add = () => {
    this.officeSelected = Office.empty().linkedProperty('organization', this.organization.entityId);
    this.officeDialogShow = true;
  };



}
