import { Injectable, Injector } from '@angular/core';
import { first, filter, switchMap, shareReplay, tap, mergeMap, map, bufferTime, retryWhen } from 'rxjs/operators';
import { AsyncSubject, Subscription, Subject } from 'rxjs';

import { Hub, HubService, HubWrapper, HubSubscription } from 'ngx-signalr-hubservice';

import { WarpEntity, SignalRAction, SignalREntityMessage, EntityFilter } from '@ripple/models';
import { environment } from '@ripple/environment';

import { GenericWarpEntityService } from './generic-warp-entity.service';
import { WarpEntityServiceCache } from './warp-entity-service-cache';
import { EntityFocusService } from './entity-focus.service';

import { HubConnectionService } from '../hubs/hub-connection.service';
import { MessageService } from '../general/message.service';
import { AuthService } from '../general/auth.service';
import { genericRetryStrategy } from '../utilities';

@Injectable({
  providedIn: 'root'
})
@Hub({ hubName: 'entityTypeHub'})
export class WarpEntityCacheFactoryService {
  public signalRConnected = new AsyncSubject<boolean>();
  private hubWrapper: HubWrapper;
  private signalRConnections: Map<number, Subscription> = new Map();
  private services = new Map<number, WarpEntityServiceCache<WarpEntity>>();
  private registeredServices = new Map();
  private registeredModels = new Map();
  private initialLoad = true;

  /** Throttle Time for signalR messages, in ms */
  public signalRThrottleTime = 500; // this can be lowered since the updates to SignalRSupressor
  private signalRIndividualMessages = new Subject<SignalREntityMessage>();

  constructor(protected injector: Injector,
              private hubService: HubService,
              private messageService: MessageService,
              private warpService: GenericWarpEntityService,
              private hubConnectionService: HubConnectionService,
              private entityFocus: EntityFocusService,
              private authService: AuthService) {
    this.registeredModels.set(-1, WarpEntity); // default type
    this.hubService = this.hubConnectionService.getHubService();
    this.hubWrapper = this.hubService.register(this);

    //TODO: this part looks like it was a bandaid, we should redesign to make more reliable
    this.hubConnectionService.connectionChanged.subscribe(connectionState => {
      this.messageService.add('SignalR Hub Connection', `connection changed ${connectionState}`, connectionState);
      if (connectionState) {
        this.signalRConnected.next(connectionState);
        this.signalRConnected.complete();

        if (!this.initialLoad)
          this.clearCaches();
        this.initialLoad = false;

        if (this.hubWrapper)
          this.hubWrapper.unregister();

        this.hubService = this.hubConnectionService.getHubService();
        this.hubWrapper = this.hubService.register(this);
        for (const id of this.signalRConnections.keys())
          this.registerSignalR(id, true);
      }
    });

    this.authService.loggedIn.subscribe(login => {
      if (!login) {
        this.clearCaches();
        this.messageService.add('WarpEntityCacheFactoryService', 'Caches cleared');
      }
    });

    this.entityFocus.onMessages.subscribe( ms => {
      const start = new Date().getTime();
      this.sendMessageToService(ms, false);
      const duration = new Date().getTime() - start;
      this.entityFocus.log(`Dump Messages [${ms.length} item(s)] took ${duration}ms`, duration);
    });

    this.signalRIndividualMessages
      .pipe(bufferTime(this.signalRThrottleTime), filter( ms => ms.length > 0 ))
      .subscribe( ms => {
        const latestActions = new Map(ms.map( m => [m.entityId, m] )); // take the last message for every entity
        this.sendMessageToService([...latestActions.values()], this.entityFocus.isActive);
      });
  }

  //#region SignalR
  @HubSubscription('receiveMessageHash')
  private signalRDispatcherHash(subID: string, entityTypeId: string, entityId: string, message: string, hash: string) {
    // TODO: throttle this by something like 100ms and only send last one, to account for entity afterSaves sending doubles
    const subscriberId = parseInt(subID, 10);
    const action = message as SignalRAction;
    if (environment.subscriberId === subscriberId && action !== SignalRAction.NONE)
       this.signalRIndividualMessages.next(
         { subscriberId, entityTypeId: parseInt(entityTypeId, 10), entityId: parseInt(entityId, 10), action, hash });
  }

  private sendMessageToService(messages: SignalREntityMessage[], enableQueue: boolean) {
    this.messageService.add( 'SignalR Dispatch',
      `${messages.length} updates ${MessageService.prettyArray(messages.map(m => m.entityId))}`,
      messages, `with${enableQueue ? '' : 'out'} queue`);

    if (enableQueue)
      // remove all the messages that are queued
      messages = messages.filter(message => !this.entityFocus.queueMessage(message) );

    // group by entityType
    const typeMap = messages.reduce((_typeMap, message) => {
      if (_typeMap.has(message.entityTypeId))
        _typeMap.get(message.entityTypeId).push(message);
      else
        _typeMap.set(message.entityTypeId, [message]);
      return _typeMap;
    }, new Map<number, SignalREntityMessage[]>());

    // get all at once, and store in a replay subject, so each can be accessed separately
    const entities = this.warpService
      .getPage(99999, 0, messages.length, EntityFilter.All().only(...messages.map(m => m.entityId)))
      .pipe(
        tap( e => this.messageService.add('SignalR Dispatch', `Fetched ${messages.length} entities`, e)),
        first(),
        retryWhen(genericRetryStrategy({
          maxRetryAttempts: 10,
          scalingDuration: 2000,
          log: (...a) => this.messageService.add('SignalR Dispatch', `Error fetching all entities`, ...a)
        })),
        shareReplay(1),
        mergeMap( page => page.data),
        map( e => new WarpEntity(e))
      );

    // handle each type separately
    typeMap.forEach((allForType, entityTypeId) => {
      const messagesWithEntities = allForType.map(
        ({ entityId, action, hash }) => ({ entityId, action, hash, entity: entities.pipe(filter( e => e.entityId === entityId)) }));
        // ({ entityId, action }) => ({ entityId, action, entity: this.warpService.get(entityId)}));

      if (this.services.has(entityTypeId))
        this.services.get(entityTypeId).signalRDispatch( messagesWithEntities );
    });
  }

  private registerSignalR(id: number, reconnect = false) {
    if (this.signalRConnections.has(id) && !reconnect) // only connect once
      return;

    this.signalRConnections.set(id,
      this.signalRConnected
        .pipe(
          // on success
          filter( successful => successful ),
          // only once
          first(),
          // subscribe
          switchMap( (connected: true) => this.hubWrapper.invoke('subscribe', id)),
          // or fail
          retryWhen(genericRetryStrategy({
            maxRetryAttempts: 10,
            scalingDuration: 2000,
            log: (...a) => this.messageService.add('SignalR Dispatch', `Error Subscribing to hub <${id}>`, ...a)
          })),
        )
        .subscribe(data => {
          this.messageService.add('SignalR Connection', `Subscribed ${id}`);
        })
    );
  }
  //#endregion

  private _getModelConstructor(id: number) {
    let retVal = this.registeredModels.get(-1); // default, WarpEntity

    if (this.registeredModels.has(id))
        retVal = this.registeredModels.get(id);

    return retVal;
  }

  private _getService(id: number) {
    if (!this.services.has(id)) {
      if (this.registeredServices.has(id)) {
        const tConstr = this.registeredServices.get(id);
        this.services.set(id, new tConstr(this.injector));
      }
      else
        this.services.set(id, new WarpEntityServiceCache(this.injector, this._getModelConstructor(id), id));
      this.registerSignalR(id);
    }
    return this.services.get(id);
  }

  get<T extends WarpEntityServiceCache<E>, E extends WarpEntity = WarpEntity>(id: number): T
  get<T extends WarpEntity>(id: number): WarpEntityServiceCache<T>
  get(id: number): WarpEntityServiceCache<WarpEntity> {
    return this._getService(id);
  }

  forceGet(id: number, areYouAbsolutelySure: string): WarpEntityServiceCache<WarpEntity> {
    if (areYouAbsolutelySure === 'YES I\'M ABSOLUTELY POSITIVE')
      return this._getService(id);
    else
      throw new Error('Think this one through, then get back to me.');
  }

  registerExtended<T extends WarpEntityServiceCache<V>, V extends WarpEntity>(id: number, tConstr: new(inj: Injector) => T) {
    this.registeredServices.set(id, tConstr);
  }

  registerModel<M extends WarpEntity>(id: number, mConstr: new(o) => M) {
    this.registeredModels.set(id, mConstr);
  }

  clearCaches(loadAll = false) {
    this.services.forEach(service => service.emptyCache());
  }
}
