import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export enum PrimaryContactRoles {
    EXECUTIVEDIRECTOR = 193277, // Executive Director
    INTAKEMANAGER = 193501, // Intake Manager
    BILLING = 193726, // Billing
    OPEERATIONS = 196509 // Operations
}

export class ContactRole extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.ContactRole;
    }

    static empty(): ContactRole {
        return new ContactRole(super.emptyEntity(EntityTypes.ContactRole));
    }

}