<ng-container *ngIf="!autoSearch">
  <p-accordion>
    <p-accordionTab header="Search" [selected]="true">
      <div class="ui-fluid p-grid">
        <div class="ui-fluid p-sm-12 p-md-6">
          <label class="filter-label">Entity Types</label>
          <p-multiSelect
            class="filter-input"
            [options]="entityTypes"
            [(ngModel)]="entityTypesSelected"
            [filter]="true"
            filterBy="label,value"
            defaultLabel="All"
          ></p-multiSelect>
        </div>
        <div class="ui-fluid p-sm-12 p-md-6">
          <label class="filter-label">Users</label>
          <ripple-virtual-entity-select
            [multiSelect]="true"
            [(selectedIds)]="usersSelected"
            [listDescriptor]="{ id: 583 }"
            [virtualPageSize]="25"
            [filterable]="true"
          ></ripple-virtual-entity-select>
        </div>
        <div class="ui-fluid p-sm-12 p-md-6">
          <label class="filter-label">Date Range</label>
          <p-calendar
            #searchDatesCalendar
            selectionMode="range"
            [monthNavigator]="true"
            [readonlyInput]="true"
            [showButtonBar]="true"
            placeholder="Search by Date(s)"
            class="ui-column-filter"
            [(ngModel)]="dateSearch"
            (onSelect)="updateRange()"
            (onClearClick)="clearRange()"
          ></p-calendar>
        </div>
        <div class="ui-fluid p-sm-12 p-md-6 p-flex p-justify-end p-align-end">
          <p-button
            label="Search"
            (onClick)="getLogs()"
            icon="pi pi-{{ loading ? 'spin pi-spinner' : 'search' }}"
          ></p-button>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>

  <p-accordion>
    <p-accordionTab header="Filter" class="filter-tab">
      <div class="ui-fluid p-grid">
        <div class="ui-fluid p-sm-12 p-md-6">
          <label class="filter-label">Entities</label>
          <p-multiSelect
            class="filter-input"
            [options]="entityFilterOptions"
            [(ngModel)]="entityFilter"
            [filter]="true"
            filterBy="label,value"
            defaultLabel="All"
            (onChange)="filter()"
          ></p-multiSelect>
        </div>
        <div class="ui-fluid p-sm-12 p-md-6">
          <label class="filter-label">Entity Types</label>
          <p-multiSelect
            class="filter-input"
            [options]="entityTypeFilterOptions"
            [(ngModel)]="entityTypeFilter"
            [filter]="true"
            filterBy="label,value"
            defaultLabel="All"
            (onChange)="filter()"
          ></p-multiSelect>
        </div>
        <div class="ui-fluid p-sm-12 p-md-6">
          <label class="filter-label">Employees</label>
          <p-multiSelect
            class="filter-input"
            [options]="filterEmployees"
            [(ngModel)]="userFilter"
            [filter]="true"
            filterBy="label"
            defaultLabel="All"
            (onChange)="filter()"
          ></p-multiSelect>
        </div>
        <div class="ui-fluid p-sm-12 p-md-6">
          <label class="filter-label">Date Range</label>
          <p-dropdown
            class="compare-dropdown"
            [options]="dateOptions"
            [(ngModel)]="dateCompareOption"
            (onChange)="filter()"
          ></p-dropdown>
          <p-calendar
            selectionMode="range"
            [monthNavigator]="true"
            [readonlyInput]="true"
            [showButtonBar]="true"
            placeholder="Filter by Date(s)"
            class="ui-column-filter"
            [(ngModel)]="dateFilter"
            (onSelect)="filter()"
            (onClearClick)="filter()"
          ></p-calendar>
        </div>
        <div class="ui-fluid p-col-12 p-flex p-justify-end p-align-end">
          <p-button
            (click)="clearFilter()"
            label="Clear"
            icon="pi pi-times"
          ></p-button>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>

  <span style="float: right; margin-top: '1em'">
    <p-selectButton
      class="filter-input"
      [options]="orderItems"
      [(ngModel)]="orderSelection"
      (onChange)="changeOrder()"
    ></p-selectButton>
  </span>
</ng-container>

<div style="clear:both">
  <ng-container *ngIf="noLogs">
    <div class="d-flex justify-content-center flex-column">
      No Activity Found.
      <p-button label="Try Again" icon="pi pi-refresh" (click)="getLogs()"></p-button>
    </div>
  </ng-container>

  <h2 *ngIf="optionalTitle != ''">
    {{optionalTitle}}
  </h2>

  <ripple-marker-lined-block  *ngFor="let dailyLog of filteredDailyLogs">
    <label class="date-label">{{ dailyLog.date }}</label>
    <p-accordion>
      <p-accordionTab
        class="log-tab tab-{{log.action.toLowerCase()}}"
        *ngFor="let log of dailyLog.logs"
        [disabled]="log.action !== 'UPDATE'"
        [(selected)]="log.open"
        (click)="open(log)"
      >
        <p-header>
          <span
            style="float: left; width: 100%; margin-bottom: 0.5em"
            class="forms-accordion__status"
          >
          <span class="pill pill--info" *ngIf="showReversionHistoryId">
            {{ log.id }}
          </span>
            <span class="pill pill--warning">
              {{ log.performed | date: 'shortTime' }}
            </span>
            <span class="pill pill--secondary">
              {{ log.entityTypeName }}
            </span>
            <span class="pill pill--info">
              {{ log.performedBy }}
            </span>
          </span>
          <span
            ><h5 style="margin: 0; display: inline-block">
              {{ getDescription(log) }}
            </h5></span
          >
        </p-header>
        <span *ngIf="log.action === 'UPDATE'" [style]="(log.open ? '' : 'display:none' )">
          <p-progressSpinner *ngIf="log.loadingChanges"></p-progressSpinner>
          <h4 *ngIf="log.changes?.length">What Changed?</h4>
          <ul
            *ngIf="log.changes?.length"
            style="list-style: none"
          >
            <li *ngFor="let change of log.changes">
              <span style="font-weight: bold">{{ change.label }}: </span>
              <ng-container *ngIf="change.change">
                <div *ngIf="isSignature(change); else normal" style="height:140px">
                  <canvas-whiteboard [options]="canvasOptions" id="{{canvasId(log, change)}}"></canvas-whiteboard>
                </div>
                <ng-template #normal>
                  <span [innerHTML]="change.change"></span>
                </ng-template>
              </ng-container>
            </li>
          </ul>
        </span>
      </p-accordionTab>
    </p-accordion>
  </ripple-marker-lined-block>

  <div class="d-flex justify-content-center" *ngIf="nextPage > 0">
    <p-button label="Load More" icon="pi pi-refresh" (click)="getLogs(nextPage)"></p-button>
  </div>
</div>
