<div class="pane pane--lg-h100">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
            <div class="header__heading">
                <ripple-breadcrumb [items]="breadcrumbs"></ripple-breadcrumb>
            </div>
            <div class="header__actions">
                <ripple-button-list [buttons]="splitButtons"></ripple-button-list>
                <button pButton label="Back" icon="pi pi-arrow-left" (click)="back()"></button>
            </div>
        </div>
    </div>
    <div class="pane__body">
        <div class="p-grid h-100">
            <div class="p-col h-100">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-user"></i> Details</h3>
                      </div>
                    </div>
                    <ripple-entity-details [entity]="contact" [hideButtons]="true"
                        [generatorOptions]="generatorOptions" [fieldOrders]="fieldOrders"
                        (afterSave)="afterSave($event)" #ed></ripple-entity-details>
                    <p-tabView *ngIf="ed.loaded" class="h-100" [(activeIndex)]="tabIndex">
                        <ng-container *ngFor="let tab of ed.fields[0].fieldGroup[0].fieldGroup">
                            <p-tabPanel *ngIf="ifTabShow(tab)">
                                <!--header-->
                                <ng-template pTemplate = "header">
                                    <i class="{{tab.templateOptions.wrapperData.headerIcon}}"></i>
                                    &nbsp;
                                    <span>{{tab.templateOptions.wrapperData.label}}</span>
                                    <span class="badge badge--primary ml-1"
                                        *ngIf="tab.templateOptions.wrapperData.tabId === 'organizations'">
                                        {{organizationList?.totalEntities || contact.numOfOrganizations}}
                                    </span>
                                    <span class="badge badge--primary ml-1"
                                        *ngIf="tab.templateOptions.wrapperData.tabId === 'teams'">
                                        {{teamContacts.length}}
                                    </span>
                                </ng-template>
                
                                <ng-template pTemplate="content">
                                    <ripple-scroll-card class="contact-tab-scroll-card">
                                        <!--basic form-->
                                        <formly-form [fields]="[tab]" [model]="contact.properties"></formly-form>
                    
                                        <!--custom form part-->
                                        <div style="padding-top: 0.625em; padding-bottom: 0.625em;" *ngIf="tab.templateOptions.wrapperData.tabId === 'generalInfo'">
                                            <ng-container *ngIf="false">
                                                <div class="ui-widget">
                                                    <label>
                                                        Guest Portal URL
                                                    </label>
                                                </div>
                                                <div class="heading-action-row">
                                                    <input pInputText id="guestPortalUrl" type="text"
                                                        class="heading-action-row__heading w-100" [(ngModel)]="guestPortalUrl" readonly />
                                                    <button pButton class="heading-action-row__action" icon="pi pi-copy"
                                                        (click)="copyGuestPortalUrl()"></button>
                                                </div>
                                            </ng-container>
                                            
                                        </div>
                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'organizations'">
                                            <div class="pane pane--lg-h100">
                                                <div class="pane__header">
                                                    <div class="header">
                                                        <button pButton class="ui-button-secondary mb-3" icon="pi pi-plus" label="Add New Organization"
                                                            (click)="addOrganizationContact()">
                                                        </button>
                                                        <div class="header__actions">
                                                        </div>
                                                    </div>
                                            
                                                </div>
                                                <div class="pane__body">
                                                    <ripple-entity-scroll-view #organizationList fullHeight="true"
                                                        [entityService]="organizationContactService" [filter]="organizationContactFilter" itemSize="100">
                                                        <ng-template #container let-organizationContact>
                                                            <div class="heading-action-row">
                                                                <div class="heading-action-row__heading">
                                                                    <sagesse-contact-organization-card [organizationContact]="organizationContact">
                                                                    </sagesse-contact-organization-card>
                                                                </div>
                                                                <div class="heading-action-row__actions">
                                                                    <button pButton icon="pi pi-pencil" (click)="editOrganizationContact(organizationContact)" class="mx-4">
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </ng-template>
                                                    </ripple-entity-scroll-view>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'teams'">
                                            <ripple-loading-box [loading]="teamContactsLoading">
                                                <p-table class="ui-table--generic-report" [value]="teamContacts">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Memberships</th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-teamcontact>
                                                        <tr>
                                                            <td>
                                                                <a href="javascript:;" (click)="goToTeam(teamcontact)">
                                                                    {{teamcontact.team.name}}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                <a href="javascript:;" (click)="channelListShow = true;teamContactSelected=teamcontact">
                                                                    <span class="badge badge--primary">
                                                                        {{teamcontact.numOfChannels}}
                                                                    </span>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <!-- <ng-container *ngFor="let teamContact of teamContacts">
                                                    <div class="heading-action-row">
                                                        <div class="heading-action-row__heading">
                                                            {{teamContact.team.name}}
                                                        </div>
                                                        <div class="heading-action-row__actions">
                                                            <button pButton icon="pi pi-external-link" (click)="goToTeam(teamContact)" class="mx-4">
                                                            </button>
                                                        </div>
                                                    </div>
                                                </ng-container> -->
                                            </ripple-loading-box>
                                        </div>
                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'notes'">
                                            <ripple-entity-notes [header]="false" [entity]="contact" noteType="Contact Note" [showNoteTypes]="['Contact Note']"
                                                [view]="'big'" [allowPin]="true" [allowTag]="true" [maxLength]="5000" [scrollTo]="noteScrollTo">
                                            </ripple-entity-notes>
                                        </div>
                                    </ripple-scroll-card>
                                </ng-template>
                            </p-tabPanel>
                        </ng-container>
                    </p-tabView>
                </ripple-scroll-card>
            </div>
            <div class="p-md-3 p-sm-12 h-100" *ngIf="contact && contact.entityId > 0">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-list"></i> Notes</h3>
                      </div>
                    </div>
                    <ripple-entity-notes [header]="false" [entity]="contact" noteType="Contact Note" [showNoteTypes]="['Contact Note']"
                        [showFullNotes]="false" (noteClick)="noteClick($event)" [allowPin]="true" [allowTag]="true" [maxLength]="5000">
                    </ripple-entity-notes>
                </ripple-scroll-card>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Organization Contact Details"
    [(visible)]="contactDialogShow"
    *ngIf="contactDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-organization-contact-details
        [organizationContactSelected]="organizationContactSelected"
        (afterSave)="contactDialogShow = false"
        [from]="'contact'"
        #orgContactDetails>
    </sagesse-organization-contact-details>
    <p-footer>
        <ripple-button-list [buttons]="orgContactDetails.splitButtons"></ripple-button-list>
    </p-footer>
</p-dialog>

<p-dialog header="Organization Contact Details"
    [(visible)]="contactDialogShow"
    *ngIf="contactDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-organization-contact-details
        [organizationContactSelected]="organizationContactSelected"
        (afterSave)="contactDialogShow = false"
        [from]="'contact'"
        #orgContactDetails>
    </sagesse-organization-contact-details>
    <p-footer>
        <ripple-button-list [buttons]="orgContactDetails.splitButtons"></ripple-button-list>
    </p-footer>
</p-dialog>

<p-dialog header="Channel List"
    [(visible)]="channelListShow"
    *ngIf="channelListShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-channel-members [teamContact]="teamContactSelected"></sagesse-channel-members>
</p-dialog>

<p-dialog header="Email Already Exists"
    [(visible)]="emailExistsDialogShow"
    *ngIf="emailExistsDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <h3>This email is already used, do you still want to use this one? </h3>
    <p-footer>
        <button pButton label="Yes" icon="pi pi-check" (click)="emailExistsDialogShow = false; save(true);"></button>
        <button pButton label="No" icon="pi pi-times" (click)="emailExistsDialogShow = false"></button>
    </p-footer>
    
</p-dialog>

<p-toast position="bottom-center" key="bc"></p-toast>
  
  