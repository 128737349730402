export enum MessageType {
  Instant = 'instant', // messages that will only pop up once
  Task = 'task', // messages that will be displaying the status of an ongoing status
}

export enum OngoingMessageStatus {
  InProgress = 'on-going', // messages that will be displaying the status of an ongoing status
  Complete = 'complete',
  Failed = 'failed'
}
