import { Injectable, Type } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@ripple/environment';

@Injectable({
  providedIn: 'root',
})
export class TemplateBuilderService  {

  constructor(
    public http: HttpClient,
  ) {
  }

  public getAllEntityTypes(subscriberId = -1): Promise<any> {
    return this.http.get(environment.restEndpointUrl + '/api/TemplateBuilder/TemplateBuilder/EntityTypes?id=' + subscriberId).toPromise();
  }
}
