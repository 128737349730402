import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class EvaluationDBProgramCategory extends WarpEntity {
  id: string | number;
  actionMenu: any;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.EvaluationDBProgramCategory;
  }

  static empty(): EvaluationDBProgramCategory {
    return new EvaluationDBProgramCategory(super.emptyEntity(EntityTypes.EvaluationDBProgramCategory));
  }

}
