import { Component, OnInit } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';

@Component({
  selector: 'ripple-form-description',
  templateUrl: './form-description.component.html',
  styleUrls: ['./form-description.component.scss']
})
// @dynamic
export class FormDescriptionComponent extends RippleFieldGenericComponent implements OnInit {
  static availableFields = ['question-description-field'];
  protected static overrideFields = { label: 'description-label' };

  ngOnInit(): void {
    this.field.templateOptions.required = false;
  }
}
