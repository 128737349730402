<ripple-loading-box [loading]="loading">
    <h1>Hello {{contact.name}}, you are a member of following teams or channels:</h1>
    <div *ngFor="let team of teams">
        <h2><a href="{{team.sharePointUrl}}" target="_blank">{{team.name}}</a></h2>
        <div *ngFor="let channel of team.channels">
            <h3>&nbsp;&nbsp; - <a href="{{channel.sharePointUrl}}" target="_blank">{{channel.name}}</a></h3>
        </div>
        
    </div>
</ripple-loading-box>
