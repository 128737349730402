import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class TeamContactRoleType extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.TeamContactRoleType;
    }

    static empty(): TeamContactRoleType {
        return new TeamContactRoleType(super.emptyEntity(EntityTypes.TeamContactRoleType));
    }

}