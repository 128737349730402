<div class="ripple-breadcrumb">
    <h1 class="header__heading" *ngIf="!items">
        {{defaultTitle}}
    </h1>
    <div *ngIf="items">
        <h1 class="header__heading">
            <span  *ngFor="let item of items; index as i; last as isLast">
                <a href="javascript:;" *ngIf="!isLast" (click)="linkClicked(i, item)">{{item.name}}</a>
                <span *ngIf="isLast">{{item.name}}</span>
    
                <span *ngIf="!isLast">&nbsp;<i class="pi pi-angle-right"></i>&nbsp;</span>
            </span>
    
        </h1>
    </div>
</div>
