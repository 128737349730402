import { Component, OnInit } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'ripple-form-button',
  template: `
    <ng-template #normal>
      <button
        pButton
        *ngIf="!(viewOnlyObservable | async)"
        [type]="to.type"
        [ngClass]="to.class"
        [label]="to.buttonText"
        [icon]="to.icon"
        [iconPos]="to.iconPos"
        [disabled]="to.disabled"
        [pTooltip]="to.tooltip"
        [tooltipPosition]="to.tooltipPosition || 'top'"
        [style]="to.style"
        (click)="onClick($event)"
      ></button>
    </ng-template>

    <ng-template #split>
      <p-splitButton
        *ngIf="!(viewOnlyObservable | async)"
        [ngClass]="to.class"
        [label]="to.buttonText"
        [icon]="to.icon"
        [iconPos]="to.iconPos"
        [disabled]="to.disabled"
        [pTooltip]="to.tooltip"
        [tooltipPosition]="to.tooltipPosition || 'top'"
        [style]="styleObj"
        [model]="to.items"
        (onClick)="onClick($event)"
      ></p-splitButton>
    </ng-template>

    <ng-container *ngIf="to.items; then split; else normal"></ng-container>
  `,
  styleUrls: ['./form-button.component.scss']
})
export class FormBuilderButtonComponent extends RippleFieldGenericComponent implements OnInit {
  get styleObj() {
    return {
      width: '5em',
      ...this.to.style
    };
  }

  get viewOnlyObservable(): Observable<boolean> {
    if (typeof this.viewOnly === 'boolean')
      return of(this.viewOnly);
    else if (this.viewOnly instanceof Observable)
      return this.viewOnly;
    else
      return of(false);
  }

  onClick($event) {
    if (this.to.onClick) this.to.onClick($event);
  }

  ngOnInit(): void { }
}
