import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrivacyService {
  public privacyModeEnabled = new BehaviorSubject<boolean>(false);

  constructor(public cookiesService: CookieService)
  {
    cookiesService.get('privacyModeEnabled') === 'true' ? this.privacyModeEnabled.next(true) : this.privacyModeEnabled.next(false);
  }

  public togglePrivacyMode() {
    this.setPrivacyModeEnabled(!this.privacyModeEnabled.value);
  }

  public isPrivacyModeEnabled() {
    return this.privacyModeEnabled.value;
  }

  private setPrivacyModeEnabled(value: boolean) {
    this.cookiesService.put('privacyModeEnabled', value.toString());
    this.privacyModeEnabled.next(value);
  }
}