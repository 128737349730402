import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';
import { MessageService } from '@ripple/services';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'ripple-form-datetime-picker',
  templateUrl: './form-datetime-picker.component.html',
  styleUrls: ['./form-datetime-picker.component.scss']
})
// @dynamic
export class FormDatetimePickerComponent extends RippleFieldGenericComponent implements OnInit {
  protected static availableFields = ['question-date-showtime-field'];

  get dateOnly() {
    return !this.to.showTime;
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    msg: MessageService
  ) {
    super(msg);
  }

  ngOnInit(): void {
    this.log('form-datetime-picker.component.ts ngOnInit()');
    if (this.viewOnly)
      this.formControl.disable();


    // convert from a string to a date so that it shows up in the form properly.
    this.nextSub = this.formControl.valueChanges
      .pipe(startWith(this.formControl.value))
      .subscribe(value => {
        const formattedValue = this.formatValue();
        if (formattedValue)
          this.formControl.setValue(formattedValue, { emitEvent: false });
      });
  }

  formatValue() {
    // return undefined will keep the value as is
    if (!this.formControl.value)
      return undefined;

    if (this.dateOnly) {
      // if this is a date object we have to assume it has the correct timezone, so a locale date will be correct
      if (this.formControl.value instanceof Date) {
        // if this is a date object from the server:
        // it was sent without a timezone, and was interpreted as local timezone
        // so we need to remove the offset that was added when it was cast to local timezone
        // then we can use utc toISOString to get the correct date.

        this.log('Interpreting input Date as correct', this.formControl.value);
        const date = this.formControl.value;
        const utc = new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ));

        return utc.toISOString().split("T")[0];
      }

      // proper format for input, leave as is
      if (/^\d{4}-\d{2}-\d{2}$/.test(this.formControl.value))
        return undefined;

      // this is server format, it has a time which could cause an issue... so strip time, and ignore timezone.
      // "02/15/1990 12:00 AM", "02-15-1990 12:00 AM",
      // "mm/dd/yyyy hh:mm AM/PM", "mm-dd-yyyy hh:mm AM/PM"
      if (/^\d{2}[\/\-]\d{2}[\/\-]\d{4}/.test(this.formControl.value)) {
        // strip the time, then the date constructor will assume UTC, so ISO string will be correct
        const noTime = this.formControl.value.substring(0, 10);
        const pureDateString = new Date(noTime).toISOString().substring(0, 10);

        this.log('Stripping Time From Server Value', pureDateString, this.formControl.value);
        return pureDateString.replace(/\//g, '-');
      }

      // we don't know what this is, so let the input handle it
      return undefined;
    }

    // with time...

    // the p-calendar expects a date object
    if (this.formControl.value instanceof Date)
      return undefined;

    return new Date(this.formControl.value);
  }

  change(value: string | number | Date) {
    this.log('change', value);
    if (this.to.change)
      this.to.change(this.field, value);
  }

  blur() {
    this.log('mark for check after blur in general input');
    this.formControl.setValue(this.formControl.value);
    this.cdRef.markForCheck();
  }
}
