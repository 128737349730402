<div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">Program Details</h1>
      <div class="header__actions">
        <p-splitButton [model]="actionMenu" label="Save" icon="pi pi-{{loading ? 'spin pi-spinner' : 'check'}}"
          (onClick)="save()" appendTo="body">
        </p-splitButton>
      </div>
    </div>
  </div>
  <div class="pane__body">


    <ripple-entity-details [entity]="program" [hideButtons]="true" [generatorOptions]="generatorOptions"
      [fieldOrders]="fieldOrders" (afterSave)="handleAfterSave($event)">
    </ripple-entity-details>
    <p-card header="Statistic Groups">
      <div class="progress-spinner" *ngIf="loadingGroups">
        <p-progressSpinner></p-progressSpinner>
      </div>
      <div *ngIf="!loadingGroups">
        <button style="width:200px; margin-bottom: 10px;" pButton type="button" icon="pi pi-plus"
          label="Add Statistic Group" (click)="showGroupModal('add')"></button>
        <div style="margin-top: 5x;" class="Statistic-Groups" *ngFor="let group of statisticGroups">
          <sagesse-satistic-group [group]="group"> </sagesse-satistic-group>
        </div>
      </div>
    </p-card>
  </div>
</div>

<p-dialog header="Add Group" [(visible)]="showGroupModalFlag" [appendTo]="'body'" class="group-modal" [modal]="true"
  [draggable]="false" [baseZIndex]="10000" [dismissableMask]="true">
  <div class="modal-content" style="width: 350px;">
    <ripple-entity-details [entity]="statGroup" [hideButtons]="true" [generatorOptions]="generatorOptionsGroup">
    </ripple-entity-details>
    <!-- [disabled]="statName == ''" -->
  </div>
  <p-footer>
    <button pButton type="button" icon="pi pi-{{addingGroup ? 'spin pi-spinner' : 'plus'}}" label="Add Group"
      (click)="addGroup()"></button>
  </p-footer>
</p-dialog>
