import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { EntityFilter, WarpEntity } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { MenuItem } from 'primeng/api';
import { ClaresLaw, ClaresLawStatus, EntityTypes } from '../_core/models';

@Component({
  selector: 'sagesse-clares-laws',
  templateUrl: './clares-laws.component.html',
  styleUrls: ['./clares-laws.component.scss']
})
export class ClaresLawsComponent extends BasePage implements OnInit {

  claresLawService: WarpEntityServiceCache<ClaresLaw>;
  filter: EntityFilter;
  filterEntity: WarpEntity;

  splitButtons: MenuItem[] = [
    {
      label: 'Add New Clare\'s Law',
      icon: 'pi pi-plus',
      command: () => this.add(),
    },
    {
      label: 'Generate Report',
      icon: 'pi pi-chart-bar',
      command: () => this.reportShow = true,
    }
  ];

  reportShow = false;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService
  ) {
    super(logService);
    this.claresLawService = factory.get(EntityTypes.ClaresLaw) as WarpEntityServiceCache<ClaresLaw>;
    this.filter = EntityFilter.None.orderBy('receiveddate', 'desc');
    const claresLawFilterEntity = ClaresLaw.empty();
    claresLawFilterEntity.properties.clareslawstatus = [
      { id: ClaresLawStatus.NEW, optionName: 'New' },
      { id: ClaresLawStatus.REFERRED, optionName: 'Referred' }
    ];
    this.filterEntity = claresLawFilterEntity;
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

  edit = (cl: ClaresLaw) => window.open( this.router.serializeUrl(this.router.createUrlTree([`${cl.entityId}`], { relativeTo: this.route })).replace("/claresLaw", "#/claresLaw"));
  add = () => this.router.navigate(['add'], { relativeTo: this.route });


}
