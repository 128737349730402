import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class User extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.User;
    }

    static empty(): User {
        return new User(super.emptyEntity(EntityTypes.User));
    }

    get personChar () {
        return this.properties['first name'] ?
            this.properties['first name'].charAt(0) : '?';
    }

}