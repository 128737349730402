import type { WrapperName } from '../service-resources/question-type-names';
import {
  FormWrapperPanelComponent,
  FormWrapperTableComponent,
  FormWrapperTableRowComponent,
} from '../partials';


export function formSectionFactory(sectionWrapperName: WrapperName) {
  switch (sectionWrapperName) {
    case 'table-panel':
      return FormWrapperTableComponent;
    case 'table-row':
      return FormWrapperTableRowComponent;
    case 'panel':
    default:
      return FormWrapperPanelComponent;
  }
}
