import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { WarpEntity } from '@ripple/models';
import { BasePage } from '@ripple/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ripple-button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss']
})
/** A cute li'l extension of split button to show multiple in a line */
export class ButtonListComponent extends BasePage implements OnChanges {

  get componentName(): string {
    return 'ButtonListComponent';
  }

  @Input() buttons: MenuItem[] | Observable<MenuItem[]>;
  @Input() appendTo;

  bSub: Subscription;
  _buttons: MenuItem[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.buttons && this.buttons) {
      if (this.bSub && !this.bSub.closed)
        this.bSub.unsubscribe();

      if (this.buttons instanceof Observable)
        this.bSub = this.buttons.subscribe( b => this._buttons = b);
      else
        this._buttons = this.buttons;
    }
  }
}
