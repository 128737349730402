import { Component, OnDestroy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ExtendedFormlyFieldConfig } from '../../service-resources';
import { IGenericObject, WarpEntity } from '@ripple/models';
import { MessageService } from '@ripple/services';
import { Subscription } from 'rxjs';
import { FormBuilderFormState } from '../../form-builder.component';

@Component({
  selector: 'ripple-field-generic',
  templateUrl: './ripple-field-generic.component.html',
  styleUrls: ['./ripple-field-generic.component.scss']
})
// @dynamic
export class RippleFieldGenericComponent extends FieldType implements OnDestroy {
  protected static availableFields = [];
  protected static overrideFields = { };
  protected static commonQuestionField = ['question-is-required-field', 'question-is-signed-field'];
  protected lastValue = undefined;

  protected _subs: Subscription[] = [];
  set nextSub(s: Subscription) { this._subs.push(s); }

  ngOnDestroy(): void {
    this._subs.forEach(s => s.unsubscribe());
  }

  get logName() { return this.field.type; }
  get viewOnly() { return this.to.viewOnly; }

  get formDataEntity() {
    return this.options && (<FormBuilderFormState>this.formState)?.formDataEntity?.value;
  }

  isEqual(object1: object, object2: object): boolean {
    return true;
  }

  constructor(private msg: MessageService) { super(); }

  public tryEmitOnChange() {
    const curr = this.formControl.value;
    if (!this.form.touched)
      this.lastValue = curr;

    const prev = this.lastValue;
    if ( !this.equalArrayOrSingle(curr, prev) ) {
      this.log('Check for Change for Manual OnChange', { prev, curr });
      if (typeof this.to.change === "function") {
        // safe to use the function
        this.to?.change(this.field, curr);
    }

      this.lastValue = curr;
    }
  }

  // tslint:disable-next-line: no-any
  protected equalArrayOrSingle(v1: any | any[], v2: any | any[]) {
    const v1Array = Array.isArray(v1) ? v1 : (v1 ? [v1] : []);
    const v2Array = Array.isArray(v2) ? v2 : (v2 ? [v2] : []);

    return v1Array.length === v2Array.length && v1Array.every( a => v2Array.some(b => this.entityPropEqual(a, b)));
  }

  // tslint:disable-next-line: no-any
  protected entityPropEqual(v1: any, v2: any) {
    // tslint:disable-next-line: triple-equals
    return v1?.hasOwnProperty('id') ? v1.id == v2?.id : v1 == v2;
  }

  protected log(...args) {
    this.msg.add(
      `FormbuilderPartial ${this.logName} (${this.to.label})`, ...args, MessageService.VERBOSE);
  }
  protected err(...args) {
    this.msg.add(
      `FormbuilderPartial ${this.logName} (${this.to.label})`, ...args, MessageService.ERROR);
  }

  static getCommonField() {
    return RippleFieldGenericComponent.commonQuestionField;
  }

  static getFieldEditOptions(availableFields: ExtendedFormlyFieldConfig[]): [ExtendedFormlyFieldConfig[], IGenericObject] {
    const myFields = RippleFieldGenericComponent.commonQuestionField.concat( this.availableFields || [] );
    const model = { };

    ((document as any).messageService || console)
      .log('Formbuilder - Generic Input', 'updating question edit fields', availableFields, this.availableFields, this.overrideFields);

    return [
      availableFields.filter(field => {
        if (field.internalType !== 'question-options') {
          if (this.overrideFields[field.key as string]) {
            model[field.key as string] = this.overrideFields[field.key as string];
            return false; // hide this input
          }
          return true;
        }
        else
          return myFields.includes(field.internalID);
      }),
      model];
  }
}
