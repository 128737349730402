<ripple-loading-box [loading]="loading">
    <p-table class="ui-table--generic-report" [value]="channelMembers">
        <ng-template pTemplate="header">
            <tr>
                <th>Team</th>
                <th>Channel</th>
                <th>Role</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-channelmember>
            <tr>
                <td>
                    <a href="/#/team/{{channelmember?.teamContactEntity?.team?.id}}">
                        {{channelmember?.teamContactEntity?.team?.name}}
                    </a>
                </td>
                <td>
                    <a href="/#/team/{{channelmember?.teamContactEntity?.team?.id}}/channel/{{channelmember?.channel?.id}}">
                        {{channelmember?.channel?.name}}
                    </a>
                </td>
                <td>
                    {{channelmember?.teamcontactroletype?.name}}
                </td>
            </tr>
        </ng-template>
    </p-table>
</ripple-loading-box>