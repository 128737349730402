import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class Program extends WarpEntity {
  id: string | number;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.Program;
  }

  static empty(): Program {
    return new Program(super.emptyEntity(EntityTypes.Program));
  }

}
