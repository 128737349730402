

<div class="pane pane--lg-h100">
    <div class="pane__header pane__header--sticky bg-white">
      <div class="header">
        <h1 class="header__heading">
          Clare's Law Report
        </h1>
        <div class="header__actions">
        </div>
      </div>
    </div>
    <div class="pane__body">
        <div class="generic-report-button-container">
            <button pButton class="ui-button-secondary" *ngIf="ifEmailView" icon="pi pi-arrow-left" label="Back" (click)="ifEmailView = !ifEmailView">
            </button>
            <button pButton class="ui-button-secondary" *ngIf="!ifEmailView" icon="pi pi-envelope" label="Send Report Email" (click)="ifEmailView = !ifEmailView">
            </button>
        </div>
        <sagesse-clares-law-report #report [ifEmailView]="ifEmailView" (dialogClose)="ifEmailView = !ifEmailView">
        </sagesse-clares-law-report>
        <div class="generic-report-button-container">
            <button pButton class="ui-button-secondary" *ngIf="ifEmailView" icon="pi pi-upload" label="Send" (click)="report.send()"
                [disabled]="report.sending || report.loading">
            </button>
        </div>
    </div>
</div>
