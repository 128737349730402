
<p-dialog
#formActionDialog
header="Form Actions"
[(visible)]="show"
[style]="{ width: '95vw' }"
[baseZIndex]="10000"
(onShow)="onShowFormActionDialog()"
*ngIf="builderControls"
[closable]="false"
>

<div id="drawflowDiv">
  <div class="wrapper">
    <div class="col">
      
      <div class="drawflow-section" draggable="true" (dragstart)="drawflowDrag($event)" >
        <span> Triggers </span>
      </div>
      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="saveForm">
        <i class="fab fa-telegram"></i><span> Save Form</span>
      </div>
      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="createForm">
        <i class="fab fa-telegram"></i><span> Create Form</span>
      </div>

      <div class="drawflow-section" draggable="true" (dragstart)="drawflowDrag($event)" data-node="sendEmail">
        <span>Form Actions</span>
      </div>

      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="sendEmail">
        <i class="fab fa-telegram"></i><span> Send Email</span>
      </div>

      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="createTask">
        <i class="fab fa-telegram"></i><span> Create Task</span>
      </div>

      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="copyDataFormToForm">
        <i class="fab fa-telegram"></i><span> Copy data from Form-to-Form</span>
      </div>
  
      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="syncClientDetailsString">
        <i class="fab fa-telegram"></i><span> Sync Text Client Details</span>
      </div>
      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="syncClientDetailsCommunications">
        <i class="fab fa-telegram"></i><span> Sync Contact Client Details</span>
      </div>

      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="syncPostEventStatistics">
        <i class="fab fa-telegram"></i><span> Sync Post Event Statistics</span>
      </div>
      
      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="TSC40SubScorer">
        <i class="fab fa-telegram"></i><span> Calculate TSC-40 Sub Scores</span>
      </div>

      <!--   
      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="createOtherForm">
        <i class="fab fa-telegram"></i><span> Create another Form</span>
      </div>

      <div class="drag-drawflow" draggable="true" (dragstart)="drawflowDrag($event)" data-node="syncOtherForm">
        <i class="fab fa-telegram"></i><span> Sync another Form</span>
      </div> -->

    </div>
    <div class="col-right">
      <div id="drawflow"  (drop)="drawflowDrop($event)" (dragover)="drawflowAllowDrop($event)"></div>
    </div>
  </div>
</div>

<p-footer>
  <button
  type="button"
  pButton
  icon="pi pi-refresh"
  (click)="resetFormActions()"
  label="Reset"
></button>

<button
type="button"
pButton
icon="pi pi-times"
(click)="cancelFormActions()"
label="Cancel"
></button>

  <button
    type="button"
    pButton
    icon="pi pi-check"
    (click)="saveFormActions()"
    label="Save"
  ></button>
  
</p-footer>
</p-dialog>