import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { EntityFilter } from '@ripple/models';
import { WarpEntityServiceCache, WarpEntityLogService, WarpEntityCacheFactoryService } from '@ripple/services';
import { EntityListComponent } from '@ripple/ui';
import { MenuItem } from 'primeng/api';
import { Team, EntityTypes, Organization } from '../_core/models';
import { TeamService } from '../_core/services';

@Component({
  selector: 'sagesse-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent extends BasePage implements OnInit {

  @Input() organization: Organization;
  @ViewChild('teamList') teamList: EntityListComponent;

  filter = EntityFilter.Exclude({hide: 'Yes'});

  splitButtons: MenuItem[] = [
    {
      label: 'Add New Team',
      icon: 'pi pi-plus',
      command: () => this.add(),
    },
    {
      label: 'Azure AD Team Sync',
      icon: 'pi pi-refresh',
      command: () => this.azureAdTeamSync(),
    },
    {
      label: 'Azure AD Channel Sync',
      icon: 'pi pi-refresh',
      command: () => this.azureAdChannelSync(),
    },
  ];

  public teamService: TeamService;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService,
    
  ) {
    super(logService);
    this.teamService = factory.get(EntityTypes.Team) as TeamService;
  }

  ngOnInit(): void {
    if (this.organization)
      this.filter = this.filter.Advanced({organization_lid: this.organization.entityId});
      
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (t: Team | number) => {
    if(this.organization)
      this.router.navigate([`/team/${t instanceof Team ? t.entityId : t}`])
    else
      this.router.navigate([`${t instanceof Team ? t.entityId : t}`], { relativeTo: this.route })
  };
  add = () => this.router.navigate(['add'], { relativeTo: this.route });

  azureAdTeamSync() {
    this.teamService.getAzureAdTeamSync('1', '0').toPromise().then(result => {
      alert(result);
    });
  }

  azureAdChannelSync() {
    this.teamService.getAzureAdTeamSync('0', '1').toPromise().then(result => {
      alert(result);
    });
  }


}
