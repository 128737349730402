import { NgModule } from '@angular/core';

import { BasePage } from './base-page';

import { DateParsePipe } from './pipes/date-parse.pipe';
import { FirstPipe } from './pipes/first.pipe';
import { CFIMPipe } from './pipes/cfim.pipe';
import { LogPipe } from './pipes/log.pipe';


@NgModule({
  declarations: [
    BasePage,

    DateParsePipe,
    FirstPipe,
    CFIMPipe,
    LogPipe,
  ],
  exports: [
    BasePage,

    DateParsePipe,
    FirstPipe,
    CFIMPipe,
    LogPipe,
  ],
})
export class RippleCoreUtilModule { }
