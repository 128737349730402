import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Organization } from '../../_core/models';
import { environment } from '../../../environments/environment';
import { InternalCookieService } from '@ripple/services';

@Component({
  selector: 'sagesse-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss']
})
export class OrganizationCardComponent implements OnInit {

  @Input() organization: Organization;

  environment = environment;

  // filter
  tagsFilter = [];
  showDistance = false;
  showNumTagMatches = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public cookieService: InternalCookieService
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      // filter from query params
      const tagsFilterStr = params.tags;
      if (tagsFilterStr) {
        const tagsFilter = tagsFilterStr.split(',');
        this.tagsFilter = tagsFilter.map(tag => parseInt(tag));
        this.showNumTagMatches = true;
      } else {
        this.tagsFilter = [];
        this.showNumTagMatches = false;
      }

      if (params.community || params.postalCode)
        this.showDistance = true;
      else
        this.showDistance = false;
  
    });
  }

  edit = () => {
    if (this.cookieService.getCookie('referenceNumber')) this.referralSelect();
    this.router.navigate([`${this.organization.entityId}`], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
    return false;
  }

  viewContact = (id) => this.router.navigate([`/contact/${id}`], { relativeTo: this.route });

  referralSelect() {
    this.cookieService.setCookie('referralID', this.organization.entityId.toString());
    this.cookieService.setCookie('referralName', this.organization.name);
  }

  generateHeadOfficeLocation() {
    let retVal = '';
    if (this.organization && this.organization.offices && this.organization.offices.length > 0) {
      retVal += this.organization.offices[0];
      if (this.organization.offices.length > 1) retVal += `+${this.organization.offices.length - 1} more`;
    }
    return retVal;
  }

  generateHeadOfficeLocationForGoolgeMap() {
    let location = this.generateHeadOfficeLocation();
    while (location.includes(' ')) {
      location = location.replace(' ', '+');
    }
    return location;
  }

}
