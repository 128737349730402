<div class="pane" *ngIf="programManager">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">Program Builder</h1>
      <div class="header__actions"></div>
      <button pButton type="button" icon="pi pi-plus" label="Add Program" (click)="addProgram() "></button>
    </div>
  </div>
  <div class="pane__body">
    <p-table #dt [value]="programs" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
      [showCurrentPageReport]="true"  [loading]="programLoading" [paginator]="false"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
          </th>

          <th class="actions-col"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-program>
        <tr class="ui-selectable-row">
          <td>{{ program.properties.name }}</td>
          <td class="actions-col">
            <p-splitButton [model]="program.actionMenu" label="{{ program.deleting ? 'Deleting' : 'View' }}"
              icon="pi pi-{{program.deleting ? 'spin pi-spinner' : 'window-maximize'}}" [disabled]="program.deleting"
              (onClick)="view(program.id)" appendTo="body">
            </p-splitButton>

          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2" class="empty-message-col">No Programs found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<div *ngIf="!programManager">
  Must be a program manager to view programs
</div>
