import { Component } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { RippleFieldGenericComponent } from "../ripple-field-generic/ripple-field-generic.component";
import type { MultiSelectBoxOption, SelectBoxOption } from "./select-box.model";

export function valueAsArray<T>(value: T | T[]) {
  if (value instanceof Array)
    return value;

  return value ? [value] : [];
}

export function valueAsSingle<T>(value: T | T[]) {
  if (!value)
    return undefined;

  return value instanceof Array ? value[0] : value;
}

/*
  TODO: SelectBox, Radio, Checkbox, and Dropdown should all be merged into this component
  - The only difference between them is the template, so this component can just change
    the nested component depending on the form-builder-type
*/

export type InputSelectBoxOption = {
  id?: number;
  value?: number;
  optionName?: string;
  specifytext?: string | string[];
} & Partial<SelectBoxOption>;

@Component({
  selector: 'ripple-field-generic',
  template: ''
})
export class SelectBoxFieldComponent extends RippleFieldGenericComponent {
  protected static commonSelectBoxFields = [
    // 'question-all-has-specify-text',
    // 'question-all-specify-is-number',
    // 'question-all-is-multi-specify',
    // 'question-all-max-specify',
    // 'question-all-specify-label',
  ];

  public selectBoxOptions$ = new BehaviorSubject<SelectBoxOption[]>([]);

  /** this will be the currently selected data, for the corresponding option objects, use `getSelectedOptions` */
  get selectedMulti(): InputSelectBoxOption[] {
    return valueAsArray(this.formControl.value);
  }

  /** this will be the first of the currently selected data. */
  get selectedSingle(): InputSelectBoxOption | undefined {
    return valueAsSingle(this.formControl.value);
  }

  /** returns the **options** which are selected in model */
  getSelectedOptions(options: SelectBoxOption[], value?: any) {
    const formValue = valueAsArray(value || this.formControl.value);
    return (options).filter(option => !!this.optionInModel(option, formValue))
  }

  /** Updates the selectBoxOptions$ to denote which options are selected, and fixes */
  protected mapOptionToSelectBoxOptions(modelValue?: InputSelectBoxOption[]) {
    if (!(this.to.options instanceof Observable))
      this.setOptions(this.to.options, modelValue);
    else
      this.nextSub = this.to.options.subscribe(options => this.setOptions(options, modelValue));
  }

  private setOptions(options: InputSelectBoxOption[], modelValue: InputSelectBoxOption[]) {
    const resultOptionBoxes = options.map(option => {
      const checked = this.checkSelectedAndSetSpecifyText(option, modelValue);
      return this.generateSelectBoxOptionFromOption(option, checked);
    });

    this.selectBoxOptions$.next(resultOptionBoxes);
  }

  private checkSelectedAndSetSpecifyText(option: InputSelectBoxOption, modelValue: InputSelectBoxOption[]): boolean {
    const model = this.model;
    const key = this.key;

    if (!modelValue && !(key instanceof Array) && model)
      modelValue = valueAsArray(model[key]);

    let optionExistsInModel = false;
    delete option.specifytext;

    if (modelValue) {
      // get the option in the model, and set the specify text
      let optionInModel = this.optionInModel(option, modelValue);
      if (optionInModel) {
        this.appendOptionSettings(option, optionInModel);

        if (optionInModel.specifytext !== undefined)
          option.specifytext = optionInModel.specifytext;

        optionExistsInModel = true;
      }
    }

    return optionExistsInModel;
  }

  private optionInModel(option: InputSelectBoxOption, modelValue: InputSelectBoxOption[]) {
    if (!modelValue)
      return undefined;

    return modelValue?.find(element => element.id === option.id);
  }

  protected generateSelectBoxOptionFromOptionWithTempId(option, checked: boolean = false): SelectBoxOption {
    return this.generateSelectBoxOptionFromOption(option, checked, true);
  }

  protected generateSelectBoxOptionFromOption(option, selected: boolean = false, generateTempId = false): SelectBoxOption {

    return this.appendOptionSettings(option, {
      // value
      id: option.id ? option.id : generateTempID(option.optionName),
      value: option.value ? option.value : 0,
      specifytext: option.specifytext,
      selected,
    })
  }

  /** this will add settings to an option, like label, isSpecify, specifyLabel, etc.  */
  appendOptionSettings(source: InputSelectBoxOption, target: InputSelectBoxOption): SelectBoxOption {
    if (!source || !target)
      return target as SelectBoxOption;

    const bool = (key: string) => {
      if (this.to && this.to[key] !== undefined)
        return this.to[key] ? true : false;
      else
        return source[key] ? true : false;
    };

    const value = (key: string, defaultValue: any) => {
      if (this.to && this.to[key] !== undefined)
        return this.to[key];
      else
        return source[key] ? source[key] : defaultValue;
    };

    // options
    target.optionName = value('optionName','');
    target.hasSpecifyText = bool('hasSpecifyText');
    target.specifyIsNumber = bool('specifyIsNumber');
    target.isMultiSpecify = bool('isMultiSpecify');
    (target as MultiSelectBoxOption).maxSpecify = value('maxSpecify', 1);
    target.specifyLabel = value('specifyLabel', 'Type here...');

    return target as SelectBoxOption;
  }
}

/** creates a persistent temporary id for an option using a hash, for testing in formbuilder before a cfcID is given */
function generateTempID(str: string) {
  let hash = 0;
  if (!str) return hash;

  for (let i = 0, chr = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
