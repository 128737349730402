// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// The list of file replacements can be found in `angular.json`.
// const staging = true;
const staging = window.location.href.includes("staging");

let host = '';
let rest = '';
let azureLoginRedirect = '';

let haveMade = {};
let ifYes = {};

if (window.location.href.includes("localhost")) {
  host = 'http://localhost:4200/#';
  rest = 'http://localhost:56696';
  azureLoginRedirect = 'http://localhost:4200/#/login';
  haveMade = {
    yes: '1000325',
    no: '1000324'
  };
  ifYes = {
    success: '1000120',
    noInterest: '1000119',
    noInterestAny: '1001387',
    noResponse: '1000118',
    other: '1001568'
  };
} else if (staging) {
  host = 'https://srmstaging.ripplegroup.ca/#';
  rest = 'https://srmstagingadmin.ripplegroup.ca/';
  azureLoginRedirect = 'https://srmstaging.ripplegroup.ca/#/login';
  haveMade = {
    yes: '2030',
    no: '2031'
  };
  ifYes = {
    success: '2530',
    noInterest: '2531',
    noInterestAny: '2531',
    noResponse: '2532',
    other: '1001568'
  };
} else {
  host = 'https://srm.ripplegroup.ca/#';
  rest = 'https://srmadmin.ripplegroup.ca/';
  azureLoginRedirect = 'https://srm.ripplegroup.ca/#/login';
  haveMade = {
    yes: '1001124',
    no: '1001123'
  };
  ifYes = {
    success: '1000919',
    noInterest: '1000918',
    noInterestAny: '1001567',
    noResponse: '1000917',
    other: '1001568'
  };
}

const organizationsTeamAzureAdObjectId = window.location.href.includes("localhost") ? 'ed870b37-77f9-4907-9a7f-014023ac8ec2' : '91b5f232-58a8-4695-8201-61a555278466';
const organizationsTeamChannelAzureAdObjectId = window.location.href.includes("localhost") ? '19:d5ff465678274ff1ac37e96bb09f3561@thread.tacv2' : '19:3783714a9c22451595ca7115f60773a5@thread.tacv2';
const teamTemplatesTeamAzureAdObjectId = '9226dbd9-6e41-4dba-955f-e79663153df5';

const sagesseOrgId = window.location.href.includes("localhost") ? 207942 : 204330;

const allowNonMemberOrgTeamIds = window.location.href.includes("localhost") ? [207027] : [205715];

const claresLawTeamId = window.location.href.includes("localhost") ? 223298 : 207659;

export const environment = {
  production: false,
  host,
  subscriberId: 141,
  loginPage: 'login',
  restEndpointUrl: rest,
  hubUrl: rest + '/signalr',
  caching: {
    defaultLoadStyle: 'lazy',
    defaultPageSize: 100,
  },
  useAzureAdAuth: true,
  azureAdClientId: '6eb63163-acae-42f3-9f92-78766143a9a1',
  azureAdAuthority: 'https://login.microsoftonline.com/ec6e2783-95d0-4d85-9d88-94a4a65b25b1',
  azureLoginRedirect,
  accessTokenRequest: {
    scopes: ["user.read"]
  },
  noteTypeStyling: {
    'Organization Note':  { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-list' },
    'Contact Note':  { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-list' },
    'Team Note':  { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-list' },
    'Clare\'s Law Note':  { highlightColor: 'var(--gray300)', bgColor: 'var(--white)', iconClass: 'pi pi-list' },
  },
  primaryContactRoleIcons: {
    193277: 'pi pi-user', // Executive Director
    193501: 'pi pi-sign-in', // Intake Manager
    193726: 'pi pi-dollar', // Billing
    196509: 'pi pi-briefcase' // Operations
  },
  organizationsTeamAzureAdObjectId,
  teamTemplatesTeamAzureAdObjectId,
  organizationsTeamChannelAzureAdObjectId,
  staging,
  sagesseOrgId,
  allowNonMemberOrgTeamIds,
  claresLawTeamId,
  cookieLocation: 'cookie',
  haveMade,
  ifYes
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
