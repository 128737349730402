import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class StrategicOutcome extends WarpEntity {
  id: string | number;
  actionMenu: any;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.StrategicOutcome;
  }

  static empty(): StrategicOutcome {
    return new StrategicOutcome(super.emptyEntity(EntityTypes.StrategicOutcome));
  }

}
