import { Component, OnInit } from '@angular/core';
import { AuthService, WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { EntityFilter, Notification } from '@ripple/models';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'ripple-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css']
})
export class NotificationListComponent implements OnInit {

  notificationService: WarpEntityServiceCache<Notification>;
  filter: EntityFilter;
  options = {
    cols: null,
    extraCols: [
      {
        order: -2,
        header: 'Created',
        field: 'created'
      },
      {
        order: -1,
        header: 'Updated',
        field: 'updated'
      }
    ],
    excludeCols: null
  };
  customFields: FormlyFieldConfig[] = [
    {
      key: 'created',
      type: 'datetime-picker',
      className: 'p-col-12 p-md-6 ui-fluid',
      templateOptions: {
        label: 'Created',
        selectionMode: 'range'
      }
    }
  ];

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService,
    private authService: AuthService
  ) {
    this.notificationService = warpEntityFactory.get(343) as WarpEntityServiceCache<Notification>;
    this.authService.getLoggedInUser().subscribe(user => {
      this.filter = EntityFilter.Advanced({
        EmployeeID_lid: user.entityId,
      }).orderBy('created', 'desc');
    });
   }

  ngOnInit(): void {
  }

}
