import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, AuthService } from '@ripple/services';
import { EntityTypes, Program, StatisticGroup, Statistic, FilledStatistic } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { first, map, switchMap, startWith, tap, distinctUntilChanged } from 'rxjs/operators';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { AgencyStatsService } from '../../_core/services';


@Component({
  selector: 'sagesse-enter-stats',
  templateUrl: './enter-stats.component.html',
  styleUrls: ['./enter-stats.component.scss']
})
export class EnterStatsComponent extends BasePage implements OnInit {
  programService: WarpEntityServiceCache<Program>;
  statisticGroupService: WarpEntityServiceCache<StatisticGroup>;
  statisticService: WarpEntityServiceCache<Statistic>;
  filledStatisticService: WarpEntityServiceCache<FilledStatistic>;

  userStats: Array<Statistic> = [];
  userGroups: Array<StatisticGroup> = [];
  saving = false;
  loading = false;
  edited = false;

  // Grab the month and year on load
  month: number = new Date().getMonth() + 1;
  year: number = new Date().getFullYear();

  statsToUpdate: Array<FilledStatistic> = [];

  constructor(
    private logService: WarpEntityLogService,
    private authService: AuthService,
    private agencyService: AgencyStatsService,
    private factory: WarpEntityCacheFactoryService,
    private route: ActivatedRoute,
  ) {
    super(logService);
    this.programService = factory.get(EntityTypes.Program) as WarpEntityServiceCache<Program>;
    this.statisticGroupService = factory.get(EntityTypes.StatisticGroup) as WarpEntityServiceCache<StatisticGroup>;
    this.statisticService = factory.get(EntityTypes.Statistic) as WarpEntityServiceCache<Statistic>;
    this.filledStatisticService = factory.get(EntityTypes.FilledStatistic) as WarpEntityServiceCache<FilledStatistic>;
   }


  ngOnInit(): void {
    console.log(this.route)
    if (this.route.snapshot.params.year && this.route.snapshot.params.month) {
      this.year = parseInt(this.route.snapshot.params.year);
      this.month = parseInt(this.route.snapshot.params.month);
    }
    console.log(" year ,month", this.year, this.month)
    this.getOrCreateStats();

  }

  monthChanged(event) {
    this.month = event.month;
    this.year = event.year;

    this.getOrCreateStats();
  }

  getOrCreateStats() {
    this.loading = true;
    this.userStats = [];

    this.nextSub = this.agencyService.getOrCreateStatsAssignedToUser(this.month, this.year).subscribe((resp: any) => {
      this.userStats = resp;
      this.loading = false;
      this.userStats.sort((a,b) => {
        
        if (a.LinkedProgram > b.LinkedProgram) 
          return 1;
        else if (a.LinkedProgram < b.LinkdProgram)
          return -1;
        
        if (a.LinkedStat.properties.linkedstatisticgroup > b.LinkedStat.properties.linkedstatisticgroup) {
          return 1;
        } else if (a.LinkedStat.properties.linkedstatisticgroup < b.LinkedStat.properties.linkedstatisticgroup) {
          return -1;
        } else {
            if (a.LinkedStat.properties.orderindex > b.LinkedStat.properties.orderindex)
              return 1;
            else if (a.LinkedStat.properties.orderindex < b.LinkedStat.properties.orderindex)
              return -1;
            else
              return 0;
        }
      });
      console.log("user stats", resp);
    });
  }


  save() {

    this.saving = true;
    this.statsToUpdate.forEach(stat => {
      stat.edited= false;
      this.filledStatisticService.update(stat).toPromise().then(resp => {
        this.saving = false;
      });
    });

  }

  statsUpdated(stat) {
    if (!this.statsToUpdate.includes(stat)) {
      this.statsToUpdate.push(stat);
    }
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

}



// Garbage Code
    // this.authService.getLoggedInUser().subscribe(user => {
    //   console.log("Logged In User", user);

    //   // Gets stats the user is assigned too
    //   const filter = EntityFilter.Advanced({employees_lid: user.entityId });
    //   this.statisticService.initQuery(filter, 9999999).getPage(0).subscribe(resp => {
    //     console.log("user stats", resp);
    //     this.userStats = resp;


    //     this.getGroupsFromStats(this.userStats);
    //   });

    //   // Gets programs the user is a manager of and then all of the stats contained in those
    //   // Programs
    //   const filter2 = EntityFilter.Advanced({employees_lid: user.entityId });
    //   this.programService.initQuery(filter2, 9999999).getPage(0).subscribe(resp => {
    //     this.programs = resp;
    //     if (this.programs.length > 0) {

    //     }
    //     console.log("user programs", resp);
    //   });
    //});


  // getGroupsFromStats(userStats:Array<Statistic>) {
  //   let groupIds: number[] = [];
  //   userStats.forEach(stat => {
  //     if (!groupIds.includes(stat.linkedProperties?.linkedstatisticgroup?.id) && stat.linkedProperties?.linkedstatisticgroup?.id != undefined)
  //       groupIds.push(stat.linkedProperties?.linkedstatisticgroup?.id)
  //   });

    // this.statisticGroupService.initQuery(EntityFilter.None.in(groupIds), 9999999).getPage(0).subscribe(resp => {
    //   this.userGroups = resp;
    //   console.log("Groups the user stats belong to", this.userGroups)
    // });
  //}
