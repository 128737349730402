import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, MessageService } from '@ripple/services';
import {
  CanvasWhiteboardComponent,
  CanvasWhiteboardUpdate,
} from 'ng2-canvas-whiteboard';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';

@Component({
  selector: 'ripple-form-signature',
  templateUrl: './form-signature.component.html',
  styleUrls: ['./form-signature.component.scss'],
})
// @dynamic
export class FormBuilderSignatureComponent
  extends RippleFieldGenericComponent
  implements AfterViewInit, OnInit
{
  protected static availableFields = [
    'question-input-height-field',
    'question-signature-autofill-field',
  ];

  @ViewChild('canvasWhiteboard') canvasWhiteboard: CanvasWhiteboardComponent;

  height = '500px';

  saveData = '';
  canvasUpdates: CanvasWhiteboardUpdate[] = [];
  hide = false;
  loading = true;
  firstClick = true;
  listenForChanges = false;
  showComponent = true;

  canvasOptions = {
    saveDataButtonText: 'Save',
    drawingEnabled: true,

    drawButtonClass: 'drawButtonClass',
    drawButtonText: 'Draw',

    clearButtonClass: 'clearButtonClass',
    clearButtonText: 'Clear',
    clearButtonEnabled: true,

    undoButtonText: 'Undo',
    undoButtonEnabled: false,

    redoButtonText: 'Redo',
    redoButtonEnabled: false,

    colorPickerEnabled: false,
    shouldDownloadDrawing: false,

    lineWidth: 5,
    strokeColor: 'rgb(0,0,0)',
  };
  user: any;

  get autofillUser() {
      return (this.to && this.user) ? this.to.autoFillSignature && this.user['autofillsignature'] : false;
  }

  get autofillField () {
    return this.to ? this.to.autoFillSignature : false;
  }

  get handoff() {
    return this.router.url.toLocaleLowerCase().includes('handoff');
  }

  get isEmpty() {
    return (
      !this.formControl ||
      !this.formControl.value ||
      this.formControl.value === '[]' ||
      (this.formControl.value instanceof Array &&
        this.formControl.value.length === 0)
    );
  }

  constructor(msg: MessageService, private router: Router, private authService: AuthService) {
    super(msg);

    this.nextSub = this.authService.getLoggedInUser().subscribe(user => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    if (this.viewOnly || this.handoff) {
      this.canvasOptions.clearButtonEnabled = !this.viewOnly;
      this.firstClick = false;
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 1);
    }

    if (this.autofillUser) {
      this.loading = true;
      setTimeout(() => (this.loading = false), 250);
    } else this.loading = false;

    if (this.formControl.value) this.to.signatureData = this.formControl.value;

    if (((this.autofillField && this.handoff) || this.autofillUser) && this.isEmpty) {
      this.height = '0px';
      this.hide = true;
    }

    this.nextSub = this.formControl.valueChanges.subscribe(() => {
      const value = this.formControl.value;
      if (value)
        if (value === 'HIDE') {
          this.hide = true;
          this.height = '0px';

          setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 1);
        } else {
          this.to.signatureData = value;
          const sigData =
            typeof this.to.signatureData === 'string'
              ? JSON.parse(this.to.signatureData)
              : this.to.signatureData;
          const updates = sigData.map((jsonUpdate) =>
            CanvasWhiteboardUpdate.deserializeJson(JSON.stringify(jsonUpdate))
          );
          this.canvasUpdates = updates;
          this.canvasWhiteboard.drawUpdates(updates);
        }
    });
  }

  public ngAfterViewInit() {
    if (this.to.signatureData) {
      const sigData = typeof this.to.signatureData === 'string'
        ? JSON.parse(this.to.signatureData)
        : this.to.signatureData;

      const updates = sigData.map((jsonUpdate) =>
        CanvasWhiteboardUpdate.deserializeJson(JSON.stringify(jsonUpdate))
      );

      this.canvasUpdates = updates;
      this.canvasWhiteboard.drawUpdates(updates);
    }

    // No drawing allowed until first click
    if (this.canvasWhiteboard)
      this.canvasWhiteboard.setDrawingEnabled(false);

    if (this.to.height)
      this.height = this.to.height;
  }

  enableDrawing() {
    const self = this;
    this.firstClick = false;
    if (self.canvasWhiteboard && !(this.handoff && this.autofillField)) {
      self.canvasWhiteboard.setDrawingEnabled(
        !self.hide && !self.to.disabled && !self.viewOnly
      );
    }

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }

  onSave(event) {
    this.saveData = JSON.stringify(this.canvasUpdates);
    this.formControl.setValue(this.saveData);
  }

  onCanvasClear() {
    if (!this.isEmpty) {
      if (this.formControl.value instanceof Array) this.formControl.setValue([]);
      else this.formControl.setValue('');
    }
    this.canvasUpdates = [];
    this.saveData = '';
    this.refresh();
  }

  sendBatchUpdate(event) {
    event.forEach((drawn) => this.canvasUpdates.push(drawn));
    this.saveData = JSON.stringify(this.canvasUpdates);
    this.formControl.setValue(this.saveData);
  }

  refresh() {
    this.showComponent = false;
    setTimeout(x=>this.showComponent=true);
  }
}
