<div class="d-flex flex-wrap justify-content-center align-items-stretch align-content-stretch mb-3">
    <ccasa-stat-card *ngFor="let card of cards"
        [label]="card.label"
        [value]="card.value"
        [icon]="card.icon"
        (click)="click(card)"
        class="stat-card"
        [class.clickable]="!!card.click"
    ></ccasa-stat-card>
</div>
