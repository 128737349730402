import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntityFilter, FilterOperator, WarpEntity } from '@ripple/models';
import { AuthService, MessageService, WarpEntityCacheFactoryService, WarpEntityLogService } from '@ripple/services';
import * as moment_ from 'moment';
import { Subscription } from 'rxjs';
import { FormBuilderReportService } from '../../form-builder-report.service';
import { FormBuilderService } from '../../form-builder.service';

const moment = moment_;

@Component({
  selector: 'form-builder-form-aggregate',
  templateUrl: './form-aggregate.component.html',
  styleUrls: ['./form-aggregate.component.scss'],
})
export class FormAggregateComponent {

  filledFormSub: Subscription;
  _forms: (WarpEntity)[] = [];
  forms: (WarpEntity)[] = [];
  filledForms: any[] = [];
  cfims: any[] = [];
  filterForms: any[] = [];

  // filter stuff.
  formFilters: any[] = [];
  extraFilters: any[] = [];
  filterChange = false;

  statCards: any[] = [];
  loading = false;

  statuses2 = [
    { label: 'Draft', value: 1276 },
    { label: 'Submitted', value: 1277 },
    { label: 'Todo', value: 1397 },
  ];
  statuses = [];
  sessions = [];


  dayOfWeek = [];
  timeOfDay = [];
  types = [];
  formTypes = [];
  statusFilter: number[] = [];
  formTypeFilter: number[] = [];

  calendarRange = [
    moment(moment().format('M/1/YYYY')).toDate(),
    moment(moment().format('M/1/YYYY')).add(1, 'M').toDate(),
  ];

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService,
    public messageService: MessageService,
    private fbReportService: FormBuilderReportService,
    private route: ActivatedRoute,
    public authService: AuthService,
    private router: Router,
    logService: WarpEntityLogService,
    private formService: FormBuilderService
  ) {
    console.log('Date', this.calendarRange);
  }

  ResetReport() {
    this.calendarRange = [
      moment(moment().format('M/1/YYYY')).toDate(),
      moment(moment().format('M/1/YYYY')).add(1, 'M').toDate(),
    ];
    this.statusFilter = [];
    this.formFilters = [];
    this.extraFilters = [];
  }

  GetReport() {
    this.loading = true;
    this.filterChange = false;
    const startString = moment(this.calendarRange[0]).format('YYYY/MM/DD');
    const endString = moment(this.calendarRange[1]).format('YYYY/MM/DD');

    const advFilters = [];
    if (this.formFilters?.length > 0) {
      console.log(this.formFilters);
      this.formFilters.forEach((element) => {
        if (advFilters.some((i) => i.field === element.field && !i.unchangeablename))
          advFilters.find((w) => w.field === element.field).list.push(element.id);
        else if (!element.unchangeablename)
          advFilters.push({
            field: element.field,
            list: [element.id],
            formType: element.formType,
            formTypeId: element.formTypeId,
          });

        if (advFilters.some((i) => i.unchangeablename === element.unchangeablename))
          advFilters.find((w) => w.unchangeablename === element.unchangeablename).list.push(element.id);
        else if (element.unchangeablename)
          advFilters.push({
            unchangeablename: element.unchangeablename,
            list: [element.id],
            formType: element.formType,
            formTypeId: element.formTypeId,
          });
      });
    }

    var formFilters = [];

    for (const adv of advFilters) {
      const formTypeId = adv.formTypeId;
      let filter = formFilters.find((filter) => filter.formTypeId === formTypeId);

      if (!filter) {
        filter = { formType: adv.formType, formTypeId, filters: [] };
        formFilters.push(filter);
      }

      if (adv.field) {
        filter.filters.push({
          key: adv.field,
          value: adv.list.join('|'),
          operator: FilterOperator.InList,
        });
      } else if (adv.unchangeablename) {
        filter.filters.push({
          key: adv.unchangeablename + '_lid',
          value: adv.list.join('|'),
          operator: FilterOperator.InList,
        });
      }
    }

    for (const filter of formFilters) {
      filter.filters = EntityFilter.Advanced(filter.filters).advanced;
      filter.filters = filter.filters.substring(
        'advancedFilter='.length,
        filter.filters.indexOf('&advancedFilterUnion=')
      );
    }

    console.log('FORM FILTERS: ', formFilters);
    console.log('this.FORM FILTERS: ', this.formFilters);
    console.log('EXTRA FILTERS', this.extraFilters);

    this.fbReportService
      .getReport(
        [startString, endString],
        [],
        [],
        this.statusFilter,
        formFilters,
        this.extraFilters,
        false
      )
      .then((data) => {
        console.log('FormBuilder Report Service ', data);

        this.messageService.add('Forms', 'loaded', data);
        this.forms = [...data.Entities.values()];
        this.cfims = [...Object.values(data.CFIMs)];
        this.filledForms = [...Object.values(data.FilledForms)];
        console.log('Forms', this.forms);

        // Ok we just got the sessions information back, so update the cards. and other report information.

        const _filterForms = [];
        const _statCards = [];
        const _clients = data.ccasaclient.filter((item) => item.ccasaclient).map((item) => item.ccasaclient);
        const _statuses = [];
        const _types = [];
        const _sessions = data.ccasasession.filter((item) => item.ccasasession).map((item) => item.ccasasession);

        const _dayOfWeek = [];
        for (var dayOfWeek of data.dayofweek) {
          const existingFilter = this.extraFilters.find((filter) => filter.day == dayOfWeek.day);

          if (existingFilter) dayOfWeek = existingFilter;

          dayOfWeek.count = dayOfWeek.total;
          _dayOfWeek.push(dayOfWeek);
        }
        this.dayOfWeek = _dayOfWeek;

        const _timeOfDay = [];
        for (var timeOfDay of data.timeofday) {
          const existingFilter = this.extraFilters.find((filter) => filter.hour == timeOfDay.hour);

          if (existingFilter) timeOfDay = existingFilter;

          timeOfDay.count = timeOfDay.total;
          _timeOfDay.push(timeOfDay);
        }
        this.timeOfDay = _timeOfDay;

        console.log('Filled Forms', this.filledForms);

        // Reset counts to 0 before they're set below
        for (const formType of this.formTypes) formType.count = 0;

        this.filledForms.forEach((form) => {
          /// Build the following into a reusable generic piece of code that goes through the result set and aggregates all of the form questions.
          /// Can use the cfim result set to determine which questions need this.
          /// Should be all Checkboxes, Radios, Selects and Multiselects.

          form.count = form.Entities.length;

          const existingFormType = this.formTypes.find((formType) => formType.EntityTypeId == form.EntityTypeId);
          if (existingFormType) {
            existingFormType.count = form.count;
          } else {
            this.formTypes.push({
              Name: form.Name,
              EntityTypeId: form.EntityTypeId,
              count: form.count,
            });
          }

          const cfims = [];

          for (const key of Object.keys(form.CFIMs)) {
            const cfim = form.CFIMs[key];
            cfim.unchangeableName = key;

            if (cfim.type === 'FormBuilder--Select') {
              cfim.choiceData = [];
              cfim.totalChoices = 0;
            }

            cfims.push(cfim);
          }

          form.CFIMs = cfims;

          var hasChoice = false;

          for (const entity of form.Entities) {
            for (const key of Object.keys(entity)) {
              if (entity[key] && !(key.endsWith('_cfcid') || key.endsWith('_lid'))) {
                const label = entity[key];
                var value = entity[key + '_cfcid'];
                if (!value) value = entity[key + '_lid'];

                if (value) {
                  const cfim = form.CFIMs.find((cfim) => cfim.id == key);

                  if (cfim && cfim.choiceData) {
                    const choice = cfim.choiceData.find((choice) => choice.id == value || choice.label == label);

                    cfim.totalChoices += 1;

                    if (!choice) {
                      hasChoice = true;

                      let choice = {
                        id: value,
                        label: label,
                        count: 1,
                      };

                      const existingFilter = this.formFilters.find(
                        (filter) =>
                          filter.id == value && filter.label == label && filter.field == cfim.unchangeableName
                      );

                      if (existingFilter) {
                        choice = existingFilter;
                        choice.count = 1;
                      }

                      if (cfim.choiceData.length == 0) {
                        cfim.choiceData.push(choice);
                      } else {
                        var index = 0;
                        while (index < cfim.choiceData.length && cfim.choiceData[index].label < label) index++;

                        cfim.choiceData.splice(index, 0, choice);
                      }
                    } else {
                      choice.count += 1;
                    }
                  }
                }
              }
            }
          }

          if (hasChoice) _filterForms.push(form);
        });

        this.filterForms = _filterForms.sort((a, b) => {
          return a.Name.localeCompare(b.Name);
        });

        this.sessions = _sessions;
        this.types = _types;
        this.statuses = _statuses;
        this.loading = false;

        _statCards.push({ label: 'Forms', value: this.forms.length, icon: 'fa fa-hashtag' });
        _statCards.push({ label: 'Unique Clients', value: _clients.length, icon: 'fa fa-hashtag' });
        _statCards.push({ label: 'Unique Sessions', value: _sessions.length, icon: 'fa fa-hashtag' });
        this.statCards = _statCards;
      });
  }

  UpdateFilter22($event, filledForm = null) {
    this.UpdateFilter2(filledForm, $event.item, $event.unchangeablename, $event.label, $event.data);
  }

  UpdateFilter2(filledForm, item, field, title, value) {
    this.filterChange = true;
    if (filledForm) {
      item.formType = filledForm.Name;
      item.formTypeId = filledForm.EntityTypeId;
    }
    item.filtered = !item.filtered;
    item.field = field;
    item.title = title;
    item.answer = value;
    let arrayToUse = filledForm ? this.formFilters : this.extraFilters;
    if (arrayToUse != null) {
      if (item.filtered) {
        // add the item to the filter.
        arrayToUse.push(item);
      } else {
        // remove the item from the filter.
        for (let index = 0; index < arrayToUse.length; index++) {
          const element = arrayToUse[index];
          if (element.id === item.id) {
            arrayToUse.splice(index, 1);
            break;
          }
        }
      }

      console.log('UPDATE FILTER: ', arrayToUse);
    }
  }

  RemoveFilter(arrayToUse, item) {
    console.log('Remove Filter ArrayToUser', arrayToUse);
    console.log('Remove Filter item', item);
    this.filterChange = true;
    item.filtered = false;
    var loc = -1;
    for (let index = 0; index < arrayToUse.length; index++) {
      const element = arrayToUse[index];
      if (element.field === item.field && element.answer === item.answer) {
        loc = index;
        break;
      }
    }
    if (loc >= 0) arrayToUse.splice(loc, 1);
  }

  GetSessionName(session) {
    if (session?.indexOf(' on') > 0) return session?.substring(0, session?.indexOf(' on'));
    return session;
  }

  View(form) {
    console.log('View', form);

    if (form.URL) window.open(form.URL, '_blank');
  }
}
