import { WarpEntity } from '../warp-entity';

// @dynamic
export class EntityNote extends WarpEntity {
  static entityTypeId = 36;

  entityTypeId = 36;

  static empty(): EntityNote {
    return new EntityNote(super.emptyEntity(36));
  }
}
