import { Component, OnInit, Input } from '@angular/core';
import { IGenericObject } from '@ripple/models';
import { RouterLink } from '@angular/router';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'ripple-paperwork',
  templateUrl: './paperwork.component.html',
  styleUrls: ['./paperwork.component.scss']
})
export class PaperworkComponent implements OnInit {

  @Input() iconClass: string;
  @Input() link: RouterLink;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() date: Date;
  @Input() sessionDateTime: Date;
  @Input() includeCount: boolean;

  @Input() numOutstandingItems: number;
  @Input() statusClass: IGenericObject;
  @Input() pillText;
  @Input() pillClass;

  @Input() numOutstandingItems2: number;
  @Input() statusClass2: IGenericObject;
  @Input() pillText2;
  @Input() pillClass2;

  @Input() numOutstandingItems3: number;
  @Input() statusClass3: IGenericObject;
  @Input() pillText3;
  @Input() pillClass3;

  @Input() numOutstandingItems4: number;
  @Input() statusClass4: IGenericObject;
  @Input() pillText4;
  @Input() pillClass4;

  @Input() flagText: string;
  @Input() inActive: boolean = false;

  get _statusClass() {
    if(this.statusClass)
      return this.statusClass;
    else if (this.inActive) {
      return 'paperwork--inactive';
    }
    else if (this.date) {
      const daysSince = moment(new Date()).diff(this.date, 'days');
      return {
        'paperwork--outstanding-low' : daysSince >= 3 && daysSince < 7,
        'paperwork--outstanding-high': daysSince >= 7
      };
    } else if (this.numOutstandingItems !== undefined)
      return {
        'paperwork--outstanding-low' : this.numOutstandingItems >= 3 && this.numOutstandingItems < 7,
        'paperwork--outstanding-high': this.numOutstandingItems >= 7
      };
    else return this.statusClass || { };
  }

  get pill() {
    if (this.pillText !== undefined) return  (this.includeCount ? this.numOutstandingItems : '') + ' ' + this.pillText;
    else return `${ this.numOutstandingItems } outstanding`;
  }

  get pill2() {
    return (this.includeCount ? this.numOutstandingItems2 : '') + ' ' + this.pillText2;
  }
  get pill3() {
    return (this.includeCount ? this.numOutstandingItems3 : '') + ' ' + this.pillText3;
  }

  get pill4() {
    return (this.includeCount ? this.numOutstandingItems4 : '') + ' ' + this.pillText4;
  }

  constructor() { }

  ngOnInit(): void {
  }
}
