import {
  Component,
  Input,
  SimpleChanges,
  OnChanges,
  HostBinding,
  TemplateRef,
  AfterContentInit,
  QueryList,
  ContentChildren } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';

@Component({
  selector: 'ripple-loading-box',
  template: `
    <div *ngIf="loading && !failed" class="loading-box-spinner">
      <p-progressSpinner></p-progressSpinner>
      <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
    </div>
    <ng-container *ngIf="!loading && !failed">
      <ng-content></ng-content>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </ng-container>
    <ng-container *ngIf="failed">
      <ng-container *ngTemplateOutlet="failedTemplate"></ng-container>
    </ng-container>
  `,
  styleUrls: ['./loading-box.component.scss']
})
export class LoadingBoxComponent implements OnChanges, AfterContentInit {
  @Input() loading: boolean;
  @Input() failed = false;
  @HostBinding('class.full-height') @Input() fullHeight = false;

  @ContentChildren(PrimeTemplate) // tslint:disable-next-line: no-any
  templates: QueryList<any>;

  // tslint:disable-next-line: no-any
  failedTemplate: TemplateRef<any>;
  // tslint:disable-next-line: no-any
  loadingTemplate: TemplateRef<any>;
  // tslint:disable-next-line: no-any
  contentTemplate: TemplateRef<any>;

  ngOnChanges(changes: SimpleChanges): void { }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'failed':
          this.failedTemplate = item.template;
          break;
        case 'loading':
          this.loadingTemplate = item.template;
          break;

        case 'content':
        default:
          this.contentTemplate = item.template;
          break;
      }
  });
  }
}
