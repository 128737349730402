import { Component, Input, OnInit } from '@angular/core';
import { TeamContact } from 'subscribers/sagesse-srm/src/app/_core/models';

@Component({
  selector: 'sagesse-team-contact-card',
  templateUrl: './team-contact-card.component.html',
  styleUrls: ['./team-contact-card.component.scss']
})
export class TeamContactCardComponent implements OnInit {

  @Input() contact: TeamContact;
  channelListShow = false;

  constructor() { }

  ngOnInit(): void {
  }

}
