import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ccasa-stat-cards',
  templateUrl: './stat-cards.component.html',
  styleUrls: ['./stat-cards.component.scss']
})
export class StatCardsComponent implements OnInit {

@Input() cards: any [];

  constructor() { }



  ngOnInit(): void {
  }

  click(card: any) {
    if (card.click)
      card.click();
  }

}
