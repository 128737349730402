import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { routes } from './_core';

@NgModule({
  imports: [ RouterModule.forRoot(routes ,{ useHash: true, initialNavigation: isInIframe() ? 'disabled' : undefined }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
