import { Component, OnInit } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';


@Component({
  selector: 'ripple-form-color-picker',
  templateUrl: './form-color-picker.component.html',
  styleUrls: ['./form-color-picker.component.scss']
})
export class FormColorPickerComponent extends RippleFieldGenericComponent implements OnInit {

  ngOnInit(): void {
  }

}
