<div class="pane__body">
  <div class="filter-container">
    <p-accordion>
      <p-accordionTab header="Filter" [selected]="true">
        <div class="ui-fluid p-grid p-align-end vertical-container">
          <div class="ui-fluid p-sm-6 p-md-3">
            <label>Date Range</label>
            <p-calendar
              [(ngModel)]="calendarRange"
              selectionMode="range"
              placeholder="Date Range"
              class="ui-column-filter"
            ></p-calendar>
          </div>
          <!-- <div class="ui-fluid p-sm-6 p-md-3">
            <label>Clients</label>
            <p-multiSelect
              [options]="clientOptions"
              [(ngModel)]="clientFilter"
              placeholder="Select Client(s)"
              class="ui-column-filter"
            ></p-multiSelect>
          </div>
          <div class="ui-fluid p-sm-6 p-md-3">
            <label>Workers</label>
            <p-multiSelect
              [options]="workers"
              [(ngModel)]="workerFilter"
              placeholder="Select Worker(s)"
              class="ui-column-filter"
            ></p-multiSelect>
          </div>
          <div class="ui-fluid p-sm-6 p-md-3">
            <label>Status</label>
            <p-multiSelect
              [options]="statuses2"
              [(ngModel)]="statusFilter"
              placeholder="Select Status(es)"
              class="ui-column-filter"
            ></p-multiSelect>
          </div> -->
          <!-- <div class="ui-fluid p-sm-6 p-md-3">
                <label>Form Type</label>
                <p-multiSelect
                  [options]="formTypes"
                  [(ngModel)]="formTypeFilter"
                  placeholder="Select Form Type(s)"
                  class="ui-column-filter"
                ></p-multiSelect>
              </div> -->
          <div class="ui-fluid p-sm-2 p-md-1">
            <button pButton class="ui-button-secondary" icon="pi pi-filter" label="Filter" (click)="GetReport()"></button>
          </div>
          <div class="ui-fluid p-sm-2 p-md-1">
            <button pButton class="ui-button-primary" (click)="ResetReport()" label="Reset"></button>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </div>

  <p-card header="Form Question Filters" *ngIf="formFilters?.length > 0">
    <table width="30%" border="1">
      <tr style="background-color: #e0e0e0">
        <td>Form Type</td>
        <td>Question</td>
        <td>Answer</td>
        <td></td>
      </tr>
      <tr *ngFor="let item of formFilters">
        <td>{{ item.formType }}</td>
        <td>{{ item.title }}</td>
        <td>{{ item.answer }}</td>

        <td (click)="RemoveFilter(formFilters, item)">Remove Filter</td>
      </tr>
    </table>
  </p-card>

  <p-card header="Additional Filters" *ngIf="extraFilters?.length > 0">
    <table width="30%" border="1">
      <tr style="background-color: #e0e0e0">
        <td>Question</td>
        <td>Answer</td>
        <td></td>
      </tr>
      <tr *ngFor="let item of extraFilters">
        <td>{{ item.title }}</td>
        <td>{{ item.answer }}</td>

        <td (click)="RemoveFilter(extraFilters, item)">Remove Filter</td>
      </tr>
    </table>
  </p-card>
<!-- 
  <ripple-loading-box [loading]="loading"></ripple-loading-box>

  <ccasa-stat-cards [cards]="statCards"></ccasa-stat-cards> -->

  <p-tabView>
    <p-tabPanel header="Summary">
      <ng-container *ngIf="forms.length > 0">
        <p-card styleClass="p-card-shadow">
          <hr />
          <div class="form-group">
            <h3>Activity by Form Type</h3>
            <table border="1">
              <tr
                *ngFor="let item of formTypes"
                form-builder-snippet-filter
                [item]="item"
                [unchangeablename]="'formtype'"
                [label]="'Activity By Form Type'"
                [data]="item.Name"
                [dataLength]="forms.length"
                (filterUpdate)="UpdateFilter22($event)"
              ></tr>
            </table>
          </div>
          <div class="form-group">
            <h3>Activity by Day of Week</h3>
            <table border="1">
              <tr
                *ngFor="let item of dayOfWeek"
                form-builder-snippet-filter
                [item]="item"
                [unchangeablename]="'day'"
                [label]="'Activity by Day of Week'"
                [data]="item.day"
                [dataLength]="forms.length"
                (filterUpdate)="UpdateFilter22($event)"
              ></tr>
            </table>
          </div>
          <div class="form-group">
            <h3>Activity by Time of Day</h3>
            <table border="1">
              <tr
                *ngFor="let item of timeOfDay"
                form-builder-snippet-filter
                [item]="item"
                [unchangeablename]="'hour'"
                [label]="'Activity by Time of Day'"
                [data]="item.hour + ':00'"
                [dataLength]="forms.length"
                (filterUpdate)="UpdateFilter22($event)"
              ></tr>
            </table>
          </div>
        </p-card>
      </ng-container>

      <!-- <button
        mat-raised-button
        color="primary"
        style="position: fixed; bottom: 50px; right: 50px"
        *ngIf="forms.length > 0"
        (click)="ViewScores()"
      >
        View Scores
      </button> -->

      <button
        mat-raised-button
        color="primary"
        style="position: fixed; bottom: 50px; right: 175px"
        *ngIf="filterChange"
        (click)="GetReport()"
      >
        Apply Filter
      </button>
    </p-tabPanel>

    <p-tabPanel header="Filters">
      <ng-container *ngIf="forms.length > 0">
        <p-card styleClass="p-card-shadow">
          <div *ngIf="filterForms.length == 1">
            <h1>{{ filterForms[0].Name }} - {{ filterForms[0].Entities.length }}</h1>
            <hr />
            <div *ngFor="let cfim of filterForms[0].CFIMs">
              <div class="form-group" *ngIf="cfim.choiceData && cfim.choiceData.length > 0">
                <h3>{{ cfim.label }}</h3>
                <table border="1">
                  <tr
                    *ngFor="let item of cfim.choiceData"
                    form-builder-snippet-filter
                    [item]="item"
                    [label]="cfim.label"
                    [data]="item.label"
                    [unchangeablename]="cfim.unchangeableName"
                    [dataLength]="cfim.totalChoices"
                    (filterUpdate)="UpdateFilter22($event, filterForms[0])"
                  ></tr>
                </table>
              </div>
            </div>
          </div>
          <p-accordion *ngIf="filterForms.length > 1">
            <p-accordionTab *ngFor="let filledForm of filterForms">
              <p-header> {{ filledForm.Name }} - {{ filledForm.Entities.length }} </p-header>
              <div *ngFor="let cfim of filledForm.CFIMs">
                <div class="form-group" *ngIf="cfim.choiceData && cfim.choiceData.length > 0">
                  <h3>{{ cfim.label }}</h3>
                  <table border="1">
                    <tr
                      *ngFor="let item of cfim.choiceData"
                      form-builder-snippet-filter
                      [item]="item"
                      [label]="cfim.label"
                      [data]="item.label"
                      [unchangeablename]="cfim.unchangeableName"
                      [dataLength]="cfim.totalChoices"
                      (filterUpdate)="UpdateFilter22($event, filledForm)"
                    ></tr>
                  </table>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </p-card>
      </ng-container>
    </p-tabPanel>

    <p-tabPanel header="Report">
      <p-table
        #dt
        [value]="forms"
        dataKey="id"
        styleClass="ui-table--generic-report"
        [rowHover]="true"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
      >
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="2614">Date Created<p-sortIcon field="2614"></p-sortIcon></th>
            <th pSortableColumn="5132">Date Completed<p-sortIcon field="5132"></p-sortIcon></th>
            <th pSortableColumn="5691">Status<p-sortIcon field="5691"></p-sortIcon></th>
            <th pSortableColumn="5692">Type<p-sortIcon field="5692"></p-sortIcon></th>
            <th pSortableColumn="5693_lid">Session<p-sortIcon field="5693_lid"></p-sortIcon></th>
            <th class="actions-col"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-form>
          <tr class="ui-selectable-row">
            <td>{{ form['2614'] }}</td>
            <td>{{ form['5132'] }}</td>
            <td>{{ form['5691'] }}</td>
            <td>{{ form['5645'] }}</td>
            <td>{{ GetSessionName(form['5693']) }}</td>
            <td class="actions-col">
              <p-splitButton
                [model]="form.actionMenu"
                label="View"
                icon="pi pi-{{ form.loadingURL ? 'spin pi-spinner' : 'window-maximize' }}"
                (onClick)="View(form)"
                [disabled]="form.loadingURL"
                appendTo="body"
              ></p-splitButton>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="3" class="empty-message-col">No Results found.</td>
          </tr>
        </ng-template>
      </p-table>
    </p-tabPanel>
  </p-tabView>
</div>
