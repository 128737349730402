import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { SelectBoxOption } from '../select-box.model';

@Component({
  selector: 'ripple-form-specify-text',
  styles: [`
    .specify-text-container {
      border-left: 4px solid #c9c9c9;
      border-radius: 4px;
      padding-left: 0.75rem;
      margin-left: 0.5rem;
    }
  `],
  template: `
    <div *ngIf="option && option.hasSpecifyText && !hidden" class="mt-2 specify-text-container">
      <!-- show option name -->
      <label *ngIf="showOptionName">{{option.optionName}}</label>

      <ng-container *ngIf="!showMulti; else multiSpecify">
        <ng-container *ngIf="viewOnly; then view; else edit"></ng-container>
      </ng-container>
    </div>

    <ng-template #edit>
      <input #specifytext
        class="specify-text"
        [type]="type"
        name="to.name"
        pInputText
        [value]="single(option.specifytext) || ''"
        (change)="specifyTextChange($event, option)"
        [placeholder]="option.specifyLabel || 'Type here...'"
      />
    </ng-template>

    <ng-template #view>
      <div class="ui-state-disabled ui-fluid ui-widget ui-corner-all ui-inputtext" style="white-space: pre-wrap">{{single(option.specifytext) || ''}}</div>
    </ng-template>


    <ng-template #multiSpecify>

      <!-- Create a specify text for each option in the multi-specify -->
      <div class="ui-inputgroup" *ngFor="let specifytext of option.specifytext; let i = index" [class.mb-2]="i < (option.specifytext.length - 1)">
        <!-- show input number -->
        <span *ngIf="hasManySpecify" class="ui-inputgroup-addon">#{{i+1}}</span>

        <ng-container *ngIf="!viewOnly; else view">
          <input
            class="specify-text mt-0"
            [type]="type"
            name="to.name"
            pInputText
            [id]="'specifytext_' + i"
            [value]="specifytext"
            (change)="specifyTextChange($event, option, i)"
            [placeholder]="option.specifyLabel || 'Type here...'"
          />

          <button
            *ngIf="hasManySpecify"
            type="button" pButton icon="pi pi-times" styleClass="ui-button-secondary"
            (click)="removeSpecifyText($event, option, i)"
          ></button>
        </ng-container>

        <ng-template #view>
          <div class="ui-state-disabled ui-fluid ui-widget ui-corner-all ui-inputtext" style="white-space: pre-wrap">{{specifytext || ''}}</div>
        </ng-template>
      </div>

      <!-- Add new specify text button -->
      <p-button
        *ngIf="!viewOnly"
        icon="pi pi-plus" class="mt-2"
        (click)="addSpecifyText($event, option)"
        [hidden]="option.specifytext.length >= maxSpecify || option.specifytext.includes('')">
      </p-button>
    </ng-template>
  `
})
export class FormBuilderSpecifyTextComponent {
  get showMulti() {
    return this.option.isMultiSpecify && Array.isArray(this.option.specifytext);
  }

  get hasManySpecify() {
    return this.option.specifytext.length > 1;
  }

  @Input()
  hidden: boolean;

  @Input()
  viewOnly: boolean;

  @Input()
  showOptionName: boolean = false;

  private _option: SelectBoxOption;

  @Input()
  set option(option: SelectBoxOption) {
    if (!option.specifytext || option.specifytext.length === 0)
      option.specifytext = option.isMultiSpecify ? [''] : '';
    else if (option.isMultiSpecify && !Array.isArray(option.specifytext))
      option.specifytext = [option.specifytext];

    this._option = option;
  }

  get option() {
    return this._option;
  }

  get maxSpecify() {
    // enforce a minimum of 2 specify text when multiSpecify is true
    let max = Number((this.option as any).maxSpecify) || 2;
    return max > 2 ? max : 2;
  }

  get type() {
    return this.option.specifyIsNumber ? 'number' : 'text';
  }

  single(text: string | string[]): string {
    if (Array.isArray(text))
      return text[0];
    return text;
  }

  multi(text: string | string[]): string[] {
    if (Array.isArray(text))
      return text;
    return [text];
  }

  @Output()
  optionChanged: EventEmitter<{$event: InputEvent, option: SelectBoxOption}> = new EventEmitter();

  ngOnInit(): void { }

  specifyTextChange($event: InputEvent, option: SelectBoxOption, index?: number) {
    const inputElement = ($event.target as HTMLInputElement);
    //handle the array logic, then send the event for the field component to handle it

    if (!option.specifytext) {
      // Set specifytext to the option default value
      option.specifytext = (option.isMultiSpecify && this.maxSpecify > 1) ? [''] : '';
    }

    if (Array.isArray(option.specifytext) && (option.isMultiSpecify && this.maxSpecify > 1)) {
      // Invalid index cases - Should never occur
      if (index === undefined || index < 0 || index >= option.specifytext.length)
        return;

      // Update the specifytext at the index
      option.specifytext[index] = inputElement.value;
    } else
      option.specifytext = inputElement.value; // Single Specify Case - The Easy Case

    this.optionChanged.emit({ $event, option });
  }

  addSpecifyText($event, option: SelectBoxOption) {
    // Only add specifytext if the option is multiSpecify and maxSpecify > 1
    if (!option.isMultiSpecify || this.maxSpecify <= 1)
      return;

    // Only add specifytext if the option has not reached the maxSpecify
    if (Array.isArray(option.specifytext) && option.specifytext.length >= this.maxSpecify)
      return;

    // Add specifytext to the option
    option.specifytext = Array.isArray(option.specifytext) ? [...option.specifytext, ''] : [option.specifytext, ''];

    // Update the model
    this.optionChanged.emit({ $event, option });
  }

  removeSpecifyText($event, option: SelectBoxOption, index: number) {
    // Only remove specifytext if the option is multiSpecify and maxSpecify > 1
    if (!Array.isArray(option.specifytext))
      return;
    // Check the specified index is valid
    if (index < 0 || index >= option.specifytext.length)
      return;
    // Remove the specifytext at the index
    option.specifytext.splice(index, 1);

    // Update the model
    this.optionChanged.emit({ $event, option });
  }

  isArray(value): boolean {
    return Array.isArray(value);
  }
}
