import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { EntityFilter, WarpEntity } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { first } from 'rxjs/operators';
import { EntityTypes, Team } from '../../_core/models';

@Component({
  selector: 'sagesse-partials-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

  //@Output() teamsDoneLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() entity: WarpEntity;
  allTeams: Team[] = [];
  teamService: WarpEntityServiceCache<Team>;

  constructor(
    factory: WarpEntityCacheFactoryService,
  ) {
    this.teamService = factory.get(EntityTypes.Team) as WarpEntityServiceCache<Team>;
   }

  async ngOnInit() {
    const teamFilter = EntityFilter.Exclude({ hide: 'Yes' });
    this.allTeams = await this.teamService.initQuery(teamFilter, 9999999).getPage(0).pipe(first()).toPromise();
    this.updateReference(true);
    //this.teamsDoneLoading.emit(true);
  }

  updateReference(removeNotExist = false) {
    if (this.entity.properties.teams)
      if (this.entity.properties.teams instanceof Array) {
        this.entity.properties.teams = this.entity.properties.teams
          .map(c => this.allTeams.find(co => co.entityId === c.id) ? this.allTeams.find(co => co.entityId === c.id) : c);
        if (removeNotExist)
          this.entity.properties.teams = this.entity.properties.teams.filter(c => c instanceof Team);
      } else {
        if (this.allTeams.find(co => co.entityId === this.entity.properties.teams.id))
          this.entity.properties.teams = this.allTeams.find(co => co.entityId === this.entity.properties.teams.id);
        else if (removeNotExist)
          this.entity.properties.teams = null;
      }
  }

}
