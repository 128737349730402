<div class="p-grid ui-fluid">
    <!-- contact -->
    <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
            <label>
                Contact
            </label>
        </div>
        <span *ngIf="teamContactSelected.entityId > 0">
            <sagesse-team-contact-card [contact]="teamContactSelected"></sagesse-team-contact-card>
        </span>
    </div>

    <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
            <label>
                Team Contact Role
            </label>
        </div>
        <p-dropdown
            appendTo='body'
            [baseZIndex]="99999999"
            filter="true"
            [options]="teamContactRoleTypes"
            [(ngModel)]="channelMemberSelected.properties.teamcontactroletype"
            placeholder="Select a Role"
        ></p-dropdown>
    </div>
</div>