<div *ngIf="autofillUser && isEmpty && !handoff" class="autofillbox">
  This signature field will automatically be filled with the signature saved
  in your profile if enabled
</div>

<div *ngIf="autofillField && isEmpty && handoff" class="autofillbox">
  Signature for Clinician
</div>

<div
  [ngStyle]="{ height: to.height || '140px' }"
  [hidden]="hide || loading"
  class="canvas-container"
  (click)="enableDrawing()"
>
  <canvas-whiteboard
    *ngIf="showComponent"
    #canvasWhiteboard
    [options]="canvasOptions"
    [saveDataButtonEnabled] = "false"
    [batchUpdateTimeoutDuration]="1000"
    (onBatchUpdate)="sendBatchUpdate($event)"
    (onClear)="onCanvasClear()"
    (onSave)="onSave($event)"
  >
  </canvas-whiteboard>

  <textarea
  [formControl]="formControl"
  [formlyAttributes]="field"
  hidden
  pInputTextarea
  >
</textarea>
</div>
