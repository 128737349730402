<div class="pane pane--lg-h100" *ngIf="hasPermission">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
            <div class="header__heading">
                <ripple-breadcrumb [items]="breadcrumbs"></ripple-breadcrumb>
            </div>
            <div class="header__actions">
                <button pButton label="Open in SharePoint" icon="pi pi-sharepoint" (click)="goToExternalLink(sharePointLink)"></button>
                <button pButton label="Open in Teams" icon="pi pi-teams" (click)="goToExternalLink(msTeamLink)"></button>
                <button pButton label="Export Member Directory" icon="pi pi-{{isExporting ? 'spin pi-spinner' : 'file-excel'}}"
                    [disabled]="isExporting"
                    (click)="exportReport()"></button>
                <ripple-button-list [buttons]="splitButtons"></ripple-button-list>
                <button pButton label="Back" icon="pi pi-arrow-left" (click)="back()"></button>
            </div>
        </div>
    </div>
    <div class="pane__body">
        <div class="p-grid h-100">
            <div class="p-col h-100">
                <p-blockUI [blocked]="saving" [styleClass]="'block-area'">
                    <div class="p-d-flex h-100">
                        <i class="pi pi-spin pi-spinner p-mx-auto p-my-auto" style="font-size: 3rem"></i>
                    </div>
                </p-blockUI>
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-sitemap"></i> Details</h3>
                      </div>
                    </div>
                    <ripple-entity-details [entity]="channel" [hideButtons]="true"
                        [generatorOptions]="generatorOptions" [fieldOrders]="fieldOrders"
                        (afterSave)="afterSave($event)" #ed></ripple-entity-details>
                    <p-tabView *ngIf="ed.loaded" class="h-100">
                        <ng-container *ngFor="let tab of ed.fields[0].fieldGroup[0].fieldGroup">
                            <p-tabPanel *ngIf="ifTabShow(tab)">
                                
                                <!--header-->
                                <ng-template pTemplate = "header">
                                    <i class="{{tab.templateOptions.wrapperData.headerIcon}}"></i>
                                    &nbsp;
                                    <span>{{tab.templateOptions.wrapperData.label}}</span>
                                </ng-template>
                
                                <ng-template pTemplate="content">
                                    <ripple-scroll-card class="channel-tab-scroll-card">
                                        <!--basic form-->
                                        <formly-form [fields]="[tab]" [model]="channel.properties"></formly-form>
                    
                                        <!--custom form part-->
                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'generalInfo'">
                                            <ripple-tags [entity]="channel"></ripple-tags>
                                        </div>
                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'members'">
                                            <div class="pane pane--lg-h100">
                                                <div class="pane__header">
                                                    <div class="header">
                                                        <input type="text" class="w-100" pInputText
                                                            placeholder="Team Member Search..." 
                                                            [(ngModel)]="contactSearch"
                                                            (keyup)="filterContacts()" (keyup.Enter)="filterContacts(true)" />
                                                    </div>
                                            
                                                </div>
                                                <div class="pane__body">
                                                    <ripple-loading-box [loading]="channelMemberLoading || teamContactLoading">
                                                        <ripple-scroll-card>
                                                            <ng-container *ngFor="let contact of teamContactsFiltered">
                                                                <div class="heading-action-row">
                                                                    <div class="heading-action-row__heading">
                                                                        <div class="p-grid align-items-center">
                                                                            <div class="p-col-2 text-center" *ngIf="!ifPublic">
                                                                                <p-checkbox name="contacts" [value]="contact.entityId" (onChange)="addOrRemoveContact(contact, $event)"
                                                                                    class="team-contact-checkbox" [(ngModel)]="teamContactIdsSelected">
                                                                                </p-checkbox>
                                                                            </div>
                                                                            <div class="p-col">
                                                                                <sagesse-team-contact-card [contact]="contact"></sagesse-team-contact-card>
                                                                            </div>
                                                                            <div class="p-col-2">
                                                                                <i class="pi pi-user"></i> {{getRole(contact)}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="heading-action-row__actions">
                                                                        <button pButton icon="pi pi-user" style="width: 10rem;"
                                                                            label="{{findChannelMemberFromTeamContact(contact) ? 'Change Role' : (ifPublic ? 'Sync ' + (nextHour | amTimeAgo) : 'Not Included')}}"
                                                                            (click)="openChannelMemberDetails(contact)">
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                
                                                            </ng-container>
                                                        </ripple-scroll-card>
                                                    </ripple-loading-box>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'folders/files'">
                                            <ripple-ms-team-file-explorer *ngIf="team && channel && team.azureadobjectid && channel.azureadobjectid"
                                                [teamId]="team.azureadobjectid" [channelId]="channel.azureadobjectid">
                                            </ripple-ms-team-file-explorer>
                                        </div>
                                    </ripple-scroll-card>
                                </ng-template>
                            </p-tabPanel>
                        </ng-container>
                    </p-tabView>
                </ripple-scroll-card>
               
            </div>
            <div class="p-md-3 p-sm-12 h-100" *ngIf="channel && channel.entityId > 0">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-tag"></i> Notes</h3>
                      </div>
                    </div>
                    <ripple-entity-notes [header]="false" [entity]="channel" noteType="Team Note"
                        [showNoteTypes]="['Team Note']">
                    </ripple-entity-notes>
                </ripple-scroll-card>
            </div>
        </div>
        
    </div>
</div>

<div *ngIf="!hasPermission" style="padding: 1%; text-align: center; display: inline">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
</div>

<p-dialog header="{{addingOrDeleting}}"
    [(visible)]="addingOrDeletingDialogShow"
    *ngIf="addingOrDeletingDialogShow"
    contentStyleClass="h-100"
    [baseZIndex]="10000"
    [modal]="true"
    [closable]="false">
    <ripple-loading-box [loading]="addingOrDeletingDialogShow"></ripple-loading-box>
    <h3>{{addingOrDeleting}}...</h3>
</p-dialog>

<p-dialog header="Contact for {{team?.properties.name}}"
    [(visible)]="memberdialogShow"
    *ngIf="memberdialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-channel-member-details
        [channelMemberSelected]="channelMemberSelected"
        [teamContactSelected]="teamContactSelected"
        [channel]="channel"
        (afterSave)="memberdialogShow = false"
        #channelMemberDetails>
    </sagesse-channel-member-details>
    
    <p-footer>
        <ripple-button-list [buttons]="channelMemberDetails.splitButtons"></ripple-button-list>
    </p-footer>
</p-dialog>

<p-dialog header="Duplicate"
    [(visible)]="duplicateShow"
    *ngIf="duplicateShow"
    contentStyleClass="h-100"
    [baseZIndex]="10000"
    [modal]="true">
    <h3>This person is already added to this channel. If you cannot see that, try to refresh the page. </h3>
</p-dialog>

<p-dialog header="Channel Limit Exceeded"
    [(visible)]="channelLimitDialogShow"
    *ngIf="channelLimitDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <h3>You cannot create a {{ ifPublic ? 'public' : 'private'}} channel because the {{ ifPublic ? '200 public' : '30 private'}} channel limit is already exceeded. </h3>
</p-dialog>