import {
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { LazyLoadEvent } from 'primeng/api';
import { BehaviorSubject, fromEvent, Observable, ReplaySubject, Subscription } from 'rxjs';
import { filter, map, filter as rxFilter, take } from 'rxjs/operators';

import {
  CustomFieldInModule,
  EntityFilter,
  FilterInput,
  FilterOperator,
  FilterStrategy,
  IFilterableEntityTypeDescriptor,
  IGenericObject,
  WarpEntity,
  OneOrMany,
  OneOrObservable,
} from '@ripple/models';
import {
  CachedQueryToken,
  MessageService,
  WarpEntityCacheFactoryService,
  WarpEntityServiceCache,
} from '@ripple/services';
import { VirtualScroller } from 'primeng/virtualscroller';
import { OverlayPanel } from 'primeng/overlaypanel';

interface LinkedEntity {
  id: number;
  name: string;
  color?: string;
  computedProperties?: IGenericObject;
}

interface Selectable {
  selected?: boolean;
  customState?: boolean;
  // disabled?: boolean;
  // required?: boolean;
}

interface SelectionOptions {
  performOnChange?: boolean;
  renderChanges?: boolean;
  markAsTouched?: boolean;
  unselectOthers?: boolean;
  forceAll?: boolean;
  selected?: boolean;
}

@Directive({
  selector: '[rippleVirtualEntitySelectRemoveEntity]'
})
export class VirtualEntitySelectRemoveEntityDirective {
  _entity: LinkedEntity & Selectable;
  @Input('rippleVirtualEntitySelectRemoveEntity') set entity(v: LinkedEntity & Selectable) {
    this._entity = v;
    this.ref.nativeElement.dataset.entityId = v.id.toString(10);
    this.ref.nativeElement.classList.add('remove-selected-entity-token');
  }

  constructor(public ref: ElementRef<HTMLElement>) { }
}

export type TemplateInputItem = LinkedEntity & Selectable;

@Component({
  selector: 'ripple-virtual-entity-select',
  template: `
    <!-- Input -->
    <div
      #inputArea
      (click)="inputClick(dropdown, $event)"
      [style]="inputStyle"
      [ngClass]="{ 'virtual-entity-select ui-multiselect ui-corner-all d-flex p-2': true, 'multiple': multiSelect, 'disabled': disabled || forceDisabled}"
    >
      <div class="label-container" >
        <span *ngIf="!_selected || _selected.length === 0 && !readOnly" class="no-selection" [innerText]="placeholder || 'Choose...'"></span>
        <ng-container
          *ngFor="let item of _selected; index as i; count as c;"
          [ngTemplateOutlet]="selectedItem ? selectedItem : selectedDefault"
          [ngTemplateOutletContext]="{ $implicit: item, index: i, count: c, invalid: i > 0 && !multiSelect }"
        ></ng-container>
      </div>
      <div  *ngIf="!readOnly" [ngClass]="{'ui-multiselect-trigger ui-state-default ui-corner-right':true}">
        <span class="ui-multiselect-trigger-icon ui-clickable" [ngClass]="dropdownIcon"></span>
      </div>
    </div>

    <p-overlayPanel #dropdown [style]="widthObj" styleClass="virtual-select-overlay" [hidden]="readOnly" [appendTo]="'body'" [baseZIndex]="baseZIndex" (onShow)="focusOnSearch()">
      <ng-template pTemplate>
        <!-- Dropdown -->
        <p-virtualScroller #scroller [value]="options" [rows]="virtualPageSize"
          [lazy]="true" (onLazyLoad)="lazyLoad($event)" [scrollHeight]="height" [itemSize]="itemSize || 30" [class.ui-height-100]="fullHeight">
          <p-header *ngIf="_filterable && searchProperties.length">
            <input
              #search pInputText
              class="w-100 ui-dropdown--custom-search-input"
              type="text" autocomplete="off" [placeholder]="searchPlaceholder"
              (keyup)="updateFilter(search.value)" (keyup.Enter)="updateFilter(search.value, true)"
              [(ngModel)]="searchString"
            />
            <i *ngIf="search.value && (!(disabled || forceDisabled)) " class="p-multiselect-clear-icon pi pi-times" (click)="search.value = '' ; focusOnSearch()"></i>
            <!-- // TODO: for if we want to support narrower search criteria in this box -->
            <!--
              <p class="small search-properties" *ngIf="_automaticSearchProperties && _automaticSearchProperties.length">
                <span class="search-properties-label" *ngFor="let cfim of _automaticSearchProperties">{{cfim.label}}</span>
              </p>
            -->
          </p-header>

          <ng-template let-entity pTemplate="item">
            <div
              (mouseenter)="expandItemMouseEnter($event)"
              (mouseleave)="expandItemMouseLeave($event)"
              (click)="toggleSelection(entity, { markAsTouched: true })"
              [class.selected]="entity.selected && !multiSelect"
              [class.selectable]="!entity.customState && !((_disabledEntities | async).has(entity.id))"
              class="loaded-item"
              style="height: {{itemSize || 30}}px"
            >
              <ng-container
                [ngTemplateOutlet]="entity.customState? statusCard : selectableItem"
                [ngTemplateOutletContext]="{ $implicit: entity }"
              ></ng-container>
            </div>
          </ng-template>

          <ng-template let-entity pTemplate="loadingItem">
            <ng-container
              [ngTemplateOutlet]="loadingItem ? loadingItem : loadingDefault"
              [ngTemplateOutletContext]="{ $implicit: entity }"
            ></ng-container>
          </ng-template>
        </p-virtualScroller>

      </ng-template>
    </p-overlayPanel>

    <ng-template #colorTag let-entity>
      <ng-container *ngIf="entityHasColorField && !hideColorTag">
        <i *ngIf="entity.color; else noColorTag" class="color-dot" [style.backgroundColor]="entity.color"></i>

        <ng-template #noColorTag>
          <i class="color-dot no-color"></i>
        </ng-template>
      </ng-container>
    </ng-template>

    <ng-template #selectableItem let-entity>
      <p-checkbox *ngIf="multiSelect" [binary]="true" [ngModel]="entity.selected" [disabled]="((_requiredEntities | async).has(entity.id) || (_disabledEntities | async).has(entity.id))"></p-checkbox>

      <ng-container
        [ngTemplateOutlet]="colorTag"
        [ngTemplateOutletContext]="{ $implicit: entity }"
      ></ng-container>

      <ng-container
        [ngTemplateOutlet]="loadedItem? loadedItem : loadedDefault"
        [ngTemplateOutletContext]="{ $implicit: entity }"
      ></ng-container>
    </ng-template>

    <ng-template #selectedDefault let-entity let-i="index" let-c="count" let-invalid="invalid">
      <span class="selected-item" [attr.data-id]="entity.id">
        <i
          *ngIf="!readOnly && (multiSelect || !required) && !(disabled || forceDisabled) && !((_requiredEntities | async).has(entity.id) || (_disabledEntities | async).has(entity.id))"
          class="pi pi-times-circle"
          (click)="toggleSelection(entity, {  markAsTouched: true, selected: false })"
        ></i>

        <span [class.invalid]="invalid">
          {{entity.name || 'unnamed'}}{{i != (c - 1) ? ',' : ''}}
        </span>
      </span>
    </ng-template>

    <ng-template #loadedDefault let-entity>
      <!-- // ??? Entity.name might be the CFIM instead of the entityLabel */ -->
      <span class="loaded-item-text" [title]="entity.name" [attr.data-id]="entity.id">
        {{entity.name}}
      </span>
    </ng-template>

    <ng-template #loadingDefault>
      <div class="loading-item"></div>
    </ng-template>

    <ng-template #statusCard>
      <label class="result-item">{{stateString}}</label>
    </ng-template>
  `,
  //   <!-- For testing the lazy load and stuff -->
  //   <br />
  //   <p>_state:        <span>{{_state}}</span> </p>
  //   <p>searchString:  <span>{{searchString}}</span> </p>
  //   <p>totalPages:    <span>{{totalPages}}</span> </p>
  //   <p>totalOptions:  <span>{{totalOptions}}</span> </p>
  //   <p>options:       <span>{{options.length}}</span> </p>
  //   <p>_selected:     <span>{{_selected.length}}</span> </p>
  //   <p>filter:        <span>{{filter?.querystring}}</span> </p>
  //   <p>page statuses:
  //     <ng-container *ngFor="let item of pageStatuses">
  //       <br />
  //       <span style="padding-left: 2rem">{{item ? item : 'undefined'}}</span>
  //     </ng-container>
  //   </p>
  // `,
  styleUrls: ['./virtual-entity-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: VirtualEntitySelectComponent
  }]
})
export class VirtualEntitySelectComponent
  implements AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor
{
  //#region State
  stateString = 'Loading Results...';
  _state: 'invalid-filter' | 'not-initialized' | 'loading' | 'no-results' | 'loaded' = 'not-initialized';

  get state() {
    return this._state;
  }
  set state(v: VirtualEntitySelectComponent['_state']) {
    this._state = v;

    switch (this._state) {
      case 'not-initialized':
      case 'invalid-filter':
        this.stateString = 'Waiting for input...';
        break;
      case 'loading':
        this.stateString = 'Loading Results...';
        break;
      case 'no-results':
        this.stateString = 'No results found...';
        break;
      case 'loaded':
        this.stateString = 'Results loaded';
        break;
    }
  }

  inputStyle = "";
  disabled = false;
  lastEmittedChangeValues: Set<number> = undefined;
  //#endregion

  //#region Options / Filter
  @Input() placeholder: string;
  @Input() required = false;
  @Input() multiSelect = true;
  @Input() logKey: string;
  @Input() dropdownIcon = 'pi pi-chevron-down';
  @Input() sortOptionsAlphabetical = false;

  _requiredEntitiesSub : Subscription;
  _requiredEntities = new BehaviorSubject<Set<number>>(new Set());
  @Input()
  set requiredEntities(inputIds: OneOrObservable<Set<number> | number[]>) {
    if (this._requiredEntitiesSub && !this._requiredEntitiesSub.closed) {
      this._requiredEntitiesSub.unsubscribe();
      this._requiredEntitiesSub = undefined;
    }

    if (inputIds instanceof Observable)
      this._requiredEntitiesSub = inputIds
        .pipe(map(inputIdsVal => new Set(inputIdsVal || [])))
        .subscribe(this._requiredEntities);
    else {
      this._requiredEntities.next(new Set(inputIds || []));
    }
  }

  _disabledEntitiesSub : Subscription;
  _disabledEntities = new BehaviorSubject<Set<number>>(new Set());
  @Input()
  set disabledEntities(inputIds: OneOrObservable<Set<number> | number[]>) {
    if (this._disabledEntitiesSub && !this._disabledEntitiesSub.closed) {
      this._disabledEntitiesSub.unsubscribe();
      this._disabledEntitiesSub = undefined;
    }

    if (inputIds instanceof Observable)
      this._disabledEntitiesSub = inputIds
        .pipe(map(inputIdsVal => new Set(inputIdsVal || [])))
        .subscribe(this._disabledEntities);
    else {
      this._disabledEntities.next(new Set(inputIds || []));
    }
  }

  //** these will not be selectable or de-selectable */
  // _disabledIds = new Set<number>();
  // _disabledIdsSub: Subscription;
  // @Input() set disabledIds(val: OneOrObservable<number[]>) {
  //   if (this._disabledIdsSub && !this._disabledIdsSub.closed)
  //     this._disabledIdsSub.unsubscribe();

  //   if (val instanceof Observable)
  //     val.subscribe(ids => this.updateDisabledIds(ids));
  //   else
  //     this.updateDisabledIds(val);
  // }

  @Input() listDescriptor: IFilterableEntityTypeDescriptor;
  @Input() requireFilterMinCharacters = 3;
  @Input() requireFilter = false;
  @Input() automaticSearchProperties = true;
  @Input() forceDisabled = false;
  @Input() hideColorTag = false;
  entityHasColorField = false;

  public searchFilterStrategy: FilterStrategy = FilterStrategy.ModuleSettingFlag | FilterStrategy.FilterColumns;

  overrideSearchProperties = false;
  _searchProperties: string[];
  _automaticSearchProperties: CustomFieldInModule[];

  @Input() set searchProperties(val: string[]) {
    this.overrideSearchProperties = !!(val && val.length);
    this._searchProperties = val;

    if (this.overrideSearchProperties) {
      this.automaticSearchProperties = false;
    }
  }

  get searchProperties() {
    const searchProperties = this.automaticSearchProperties ?
      (this._automaticSearchProperties || []).map(cfim => cfim.unchangeableName) : this._searchProperties;

    if (!searchProperties || searchProperties.length == 0)
      return [];

    return searchProperties;
  }

  @Input() virtualPageSize = 10;
  @Input() filterable = true;

  get _filterable() {
    return this.filterable || this.requireFilter;
  }

  searchPlaceholder = 'Search...';
  searchString: string;
  filter: EntityFilter;
  filterTimeout: number;
  defaultFilter: EntityFilter;

  _entityServiceChanges = new BehaviorSubject<WarpEntityServiceCache<WarpEntity>>(undefined);
  get whenEntityServiceExists() {
    return this._entityServiceChanges.pipe(
      filter(s => !!s),
      take(1)
    ).toPromise();
  }

  get entityService(): WarpEntityServiceCache<WarpEntity> {
    return this._entityServiceChanges.value;
  }
  set entityService(v: WarpEntityServiceCache<WarpEntity>) {
    this._entityServiceChanges.next(v);
  }

  queryToken: CachedQueryToken<WarpEntity>;
  forThisQuery: ReplaySubject<CachedQueryToken<WarpEntity>> = new ReplaySubject(1);
  totalOptions = 0;
  totalPages = 0;
  pageStatuses: (undefined | 'loading' | 'loaded' | 'empty')[] = [];
  /** first load per query, this will change lots */
  queryNeedsReset = false;
  options: (LinkedEntity & Selectable)[] = [];
  initialLoad = true;

  entityTypeSub: Subscription;
  fetchSelectedSub: Subscription;
  pageSubs: Subscription[] = [];
  mainQuerySub: Subscription = new Subscription();
  scrollerSub: Subscription;
  removeButtonsSub: Subscription;
  removeButtonListSub: Subscription;
  //#endregion

  _selected: LinkedEntity[] = [];
  get first() {
    return this._selected[0] || undefined;
  }

  @Input() set selectedIds(ids: number[]) {
    if (ids && ids.length)
      this.selectByIds(ids, { unselectOthers: true, forceAll: true, markAsTouched: false });
    else {
      this._unselectAll();
      this.onChange({ markAsTouched: false });
    }
  }

  /** the only selected option, or the latest if multiSelect */
  @Input() useFullEntities = true;
  @Input() set selected(v: OneOrMany<LinkedEntity>) {
    const arr = v instanceof Array ? [...v] : [v];
    if (v && arr.length) {
      this._selectMultiple(arr, true, false);
      this.onChange({  unselectOthers: true, markAsTouched: false });
    } else {
      this._unselectAll();
      this.onChange({ markAsTouched: false });
    }
  }

  @Output() selectedChange: EventEmitter<LinkedEntity[]> = new EventEmitter<LinkedEntity[]>();
  @Output() selectedIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  //#region Rendering
  @Input() baseZIndex = 100000;
  @Input() fullHeight = false;
  @Input() height = '200px';
  @Input() itemSize = 30;
  @Input() readOnly = false;
  widthObj = { };
  itemHeightTemp = "30px"; // Used to hold previous height for the hover event

  search: ElementRef<HTMLInputElement>;

  primeScroller: VirtualScroller;
  @ViewChildren('scroller') scrollers: QueryList<VirtualScroller>;
  @ViewChild('dropdown') dropdown: OverlayPanel;
  @ViewChild('search') set _search(component: ElementRef<HTMLInputElement>) {
    // this is in the overlay, so it only exists when the overlay is active
    this.search = component;
    if (this.search)
      setTimeout(() => this.search?.nativeElement?.focus(), 33);
  }

  /** how to display the items in the list */
  @ContentChild('loadedItem') loadedItem: TemplateRef<TemplateInputItem>;
  /** how to display the loading items in the list */
  @ContentChild('loadingItem') loadingItem: TemplateRef<TemplateInputItem>;
  /** how to display the selected items in the input bar */
  @ContentChild('selectedItem') selectedItem: TemplateRef<TemplateInputItem>;

  @ContentChildren(VirtualEntitySelectRemoveEntityDirective, {
    descendants: true
  }) removeEntityDirectives: QueryList<VirtualEntitySelectRemoveEntityDirective>;
  //#endregion

  constructor(
    private serviceFactory: WarpEntityCacheFactoryService,
    private messageService: MessageService,
    private element: ElementRef<HTMLElement>,
  ) { }

  //#region Rendering
  ngAfterViewInit(): void {
    this.functionLog('ngAfterViewInit', ...arguments);
    this.setupRemoveButtons();
    this.removeButtonListSub = this.removeEntityDirectives.changes.subscribe(() => this.setupRemoveButtons());

    this.loadInitialOnScrollerLoad();
  }

  ngOnDestroy(): void {
    this.functionLog('ngOnDestroy', ...arguments);

    if (this.entityTypeSub && !this.entityTypeSub.closed)
      this.entityTypeSub.unsubscribe();
    if (this.fetchSelectedSub && !this.fetchSelectedSub.closed)
      this.fetchSelectedSub.unsubscribe();

    if (this.mainQuerySub && !this.mainQuerySub.closed)
      this.mainQuerySub.unsubscribe();
    if (this.scrollerSub && !this.scrollerSub.closed)
      this.scrollerSub.unsubscribe();
    if (this.removeButtonsSub && !this.removeButtonsSub.closed)
      this.removeButtonsSub.unsubscribe();
    if (this.removeButtonListSub && !this.removeButtonListSub.closed)
      this.removeButtonListSub.unsubscribe();
    if (this._requiredEntitiesSub && !this._requiredEntitiesSub.closed)
      this._requiredEntitiesSub.unsubscribe();
    if (this._disabledEntitiesSub && !this._disabledEntitiesSub.closed)
      this._disabledEntitiesSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.functionLog('ngOnChanges', ...arguments);

    if (changes.listDescriptor && this.listDescriptor) {
      this.log('listDescriptor changed', this.listDescriptor);
      if (this.listDescriptor.entityFilter instanceof Function)
        throw Error(`Virtual Entity Select does not support dynamic filters,
          please use a static filter and change the listDescriptor reference to update it`);

      this.defaultFilter = this.listDescriptor.entityFilter || EntityFilter.All();
      this.filter = this.defaultFilter;
      this.entityService = this.serviceFactory.get(this.listDescriptor.id);
      // if we change the filter, load the first page again
      this.initialLoad = true;
    }

    if (changes.requireFilter && !changes.requireFilter.isFirstChange()) {
      this.log(`requireFilter changed [${this.requireFilter}]`, this.listDescriptor);
      this.loadInitialOnScrollerLoad();
    }

    if (
      // on change of any of these, and we have all of them
      (changes.filterable || changes.requireFilter || changes.listDescriptor)
      && (this._filterable && this.listDescriptor && this.entityService)
    ) {

      if (this.entityTypeSub && !this.entityTypeSub.closed)
        this.entityTypeSub.unsubscribe();

      this.entityTypeSub = this.entityService.getEntityStructure().subscribe(structure => {
        let cols = structure.getColumnFilters(FilterStrategy.ModuleSettingFlag, FilterStrategy.FilterColumns, FilterStrategy.ReportColumns)
          .filter(cfim => cfim.cf_dataField === 'textdata');
        // if no module settings, no filter columns and no report columns
        // then use all text fields, and if none, use nothing
        if (cols.length == 0)
          cols = structure.getColumnFilters(FilterStrategy.TextFields);

        this._automaticSearchProperties = cols;
        const colorCfim = structure.getColorCfim();
        this.entityHasColorField = !!colorCfim;

        this.log('got structure for search properties', structure, cols);
      });
    }
  }

  inputClick(dropdown: OverlayPanel, $event: Event) {
    if (!this.disabled && !this.readOnly)
      dropdown?.toggle($event);
    this.updateSize();
  }

  @HostListener('window:resize')
  updateSize() {
    this.functionLog('updateSize', ...arguments);
    const width = Math.max(this.element.nativeElement.offsetWidth, 200); // min-width: 200px
    this.widthObj = { width: `${width}px` };

    this.element.nativeElement.style.setProperty('--overlay-width', `${width}px`);
    this.log(`resized to ${width}px`, this.element);
  }

  private loadInitialOnScrollerLoad() {
    this.functionLog('loadInitialOnScrollerLoad', ...arguments);
    if (this.requireFilter === true)
      this.searchPlaceholder = `Search (min. ${this.requireFilterMinCharacters} characters)...`;
    else
      this.searchPlaceholder = 'Search...';

    if (this.scrollerSub && !this.scrollerSub.closed)
      this.scrollerSub.unsubscribe();

    // overlay panel destroys contents when inactive, so we need to re-create the query
    this.scrollerSub = this.scrollers.changes.subscribe(scrollers => {
        this.primeScroller = scrollers.first;
        // We deal with this only the first time the scroller appears.
        if (this.primeScroller && !this.requireFilter && this.initialLoad) {
          this.log('Performing initial load');
          this.initialLoad = false;
          this.initQuery();
        }
      });
  }

  // if the async pipes in the template are too slow, we can do this to track the requiredEntities and disabledEntities as a prop on linked entities
  // updateDisabledIds(ids: number[]) {
  //   const disabledIds = new Set(ids || []);

  //   // if no change, don't do anything
  //   if (disabledIds.size == this._disabledIds.size) {
  //     let deep_changes = false;
  //     for (let id of disabledIds)
  //       if (!this._disabledIds.has(id))
  //         deep_changes = true;

  //     if (!deep_changes)
  //       return;
  //   }

  //   this._disabledIds = disabledIds;
  //   this.log('disabledIds changed', this._disabledIds);

  //   if (this.options && this.options.length > 0) {
  //     this.options
  //       .filter(opt => !!opt)
  //       .forEach(opt => opt.disabled = disabledIds.has(opt.id));
  //   }
  // }

  setupRemoveButtons() {
    this.functionLog('setupRemoveButtons', ...arguments);
    this.log('setting up remove buttons on children', this.removeEntityDirectives);

    if (this.removeButtonsSub)
      this.removeButtonsSub.unsubscribe();

    // if this is readonly, we don't want to show the remove buttons, if they exist
    this.removeEntityDirectives.forEach( dir => {
      if (!dir.ref.nativeElement.dataset.oldDisplay)
        dir.ref.nativeElement.dataset.oldDisplay = dir.ref.nativeElement.style.display;

      dir.ref.nativeElement.style.display = this.readOnly ? 'none' : dir.ref.nativeElement.dataset.oldDisplay;
    });

    if (!this.readOnly && this.removeEntityDirectives && this.removeEntityDirectives.length > 0)
      this.removeButtonsSub = fromEvent(this.removeEntityDirectives.map( target => target.ref.nativeElement ), 'click')
        .subscribe( ($event: PointerEvent) => {
          const id = Number(($event.target as HTMLElement).dataset.entityId);
          const option = this._selected.find( opt => opt.id === id );
          this.log(`Removing an option directly ${id}`, option, $event);
          if (option)
            this.toggleSelection(option, { markAsTouched: true, selected: false });

          $event.stopPropagation(); // don't close the overlay
        });
  }

  focusOnSearch() {
    this.functionLog('focusOnSearch', ...arguments);
    if (this._filterable && this.search)
      setTimeout(() => this.search?.nativeElement?.focus(), 33);
  }

  private setResultsEmpty(state?: this['_state']) {
    this.functionLog('setResultsEmpty', ...arguments);
    if (state !== undefined)
      this.state = state;

    const infoItem: Selectable & Omit<LinkedEntity, 'name'> = {
      customState: true,
      selected: false,
      id: -1,
    };
    Object.defineProperty(infoItem, 'name', { get: () => this.stateString });

    if (this.options && this.options[0] && this.options[0].customState)
      return;

    this.totalOptions = 1;
    this.totalPages = 0;
    this.options =  [infoItem as LinkedEntity & Selectable];
  }

  /**
   * Expand an item vertically if its content is greater than its current height
   * This is meant to be used as a hover function, so there are two functions; one for mouseenter and one for mouseleave
   * @param event - The mouseleave event, used to grab the event's target
   */
  expandItemMouseEnter(event: Event) {
    this.functionLog('expandItem', ...arguments);
    let itemElement = event.target as HTMLElement;
    let itemParent = itemElement.parentElement;
    let childElement = itemElement.children[0] as HTMLElement;

    this.itemHeightTemp = itemElement.style.height;

    // Create a temporary element to get the height of the item's content
    let tempElement = itemElement.cloneNode(true) as HTMLElement;
    tempElement.style.position = 'absolute';
    tempElement.style.visibility = 'hidden';
    tempElement.style.height = 'auto';
    let tempElementContent = tempElement.children[0] as HTMLElement;
    tempElementContent.style.whiteSpace = 'initial';
    itemElement.parentElement.appendChild(tempElement);
    let computedStyle = getComputedStyle(tempElementContent);
    // Don't include padding in the height calculation, as it was causing false positives in some cases
    let calculatedHeight = tempElementContent.offsetHeight - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom);

    itemElement.parentElement.removeChild(tempElement);

    if(calculatedHeight > itemElement.offsetHeight) {
      itemElement.style.height = calculatedHeight + computedStyle.paddingTop + computedStyle.paddingBottom + 'px';
      itemParent.style.height = calculatedHeight + computedStyle.paddingTop + computedStyle.paddingBottom + 'px';
      childElement.style.whiteSpace = 'initial';
    }
  }

  /**
   * Set an item back to its original height
   * This is meant to be used as a hover function, so there are two functions; one for mouseenter and one for mouseleave
   * @param event - The mouseleave event, used to grab the event's target
   */
  expandItemMouseLeave(event: Event) {
    this.functionLog('expandItem', ...arguments);
    let itemElement = event.target as HTMLElement;
    let itemParent = itemElement.parentElement;
    let childElement = itemElement.children[0] as HTMLElement;

    itemElement.style.height = this.itemHeightTemp;
    itemParent.style.height = this.itemHeightTemp;
    childElement.style.whiteSpace = 'nowrap';
  }

  getSelectedColors() {
    if (!this._selected.length)
      return [];

    return this._selected.map(s => s.color);
  }

  updateColor() {
    if (this._selected.length) {
      let color = this.getSelectedColors().find(c => c && CSS.supports('color', c));
      if (color)
        return this.inputStyle = `border-bottom: 3px solid ${color};`;
    }

    return this.inputStyle = '';
  }

  //#endregion

  //#region Query
  initList(totalEntities: number) {
    this.functionLog('initList', ...arguments);
    this.totalOptions = totalEntities;
    this.totalPages = Math.ceil(totalEntities / this.virtualPageSize);
    if (totalEntities < 1) {
      const validSearch = !this.requireFilter || this.searchString && this.searchString.length >= this.requireFilterMinCharacters;
      this.setResultsEmpty(validSearch ? 'no-results' : 'invalid-filter');
      return;
    }

    this.pageStatuses.length = this.totalPages;
    this.options.length = totalEntities;
    this.state = 'loaded';

    this.log(`Loaded filter, total entities: ${totalEntities}, expecting ${this.totalPages} pages.`);
  }

  clearForLoad() {
    this.functionLog('clearForLoad', ...arguments);
    if (this.primeScroller)
      this.primeScroller.clearCache();
    this.pageStatuses.fill(undefined);
    this.options.fill(undefined);
    this.options = [...this.options];
  }

  /**
   * TODO: `id` and `name` might be cfims, instead of what they should be: `WarpEntity.warpEntityName` and `WarpEntity.entityId` - {@link WarpEntity}\
   * \
   * that should be fixed in warp-entity.ts:
   * - we should enforce that id and name are never overwritten by property accessors
   * - we will have to check every place that uses `id` and `name` to make sure it's using a cfim accessor
   * - can make it `entity.use().name` or `entity.use('id').value` for the cfims (and any others that conflict with warpEntityFields)
  */
  private warpEntityToLinkedSelectable(entity: WarpEntity): LinkedEntity & Selectable {
    const baseEnt: (WarpEntity | IGenericObject) = entity;
    baseEnt.id = entity.entityId || entity.id,
    baseEnt.name = entity.warpEntityName || entity.name || `unknown`,
    baseEnt.selected = this._selected.some(s => s.id === entity.id)

    return baseEnt as LinkedEntity & Selectable & (IGenericObject | WarpEntity);
  }

  private appendPage(event: LazyLoadEvent, entities: WarpEntity[]) {
    this.functionLog('appendPage', ...arguments);
    const [page, pageSize] = this.pageInfo(event.first, event.rows);

    if (!entities || entities.length < 1) {
      this.log('No entities returned for page', event);
      this.pageStatuses[page] = 'empty';
    }

    // sometimes the last element in the last page is the first element
    // in the next page, so remove it here
    // partial pages can also contain that element
    const prevElement = this.options && this.options[event.first - 1];
    const firstNew = entities[0];
    if (prevElement && firstNew && prevElement.id === firstNew.id)
      entities.shift();

    const newOptions = entities.map(this.warpEntityToLinkedSelectable.bind(this));

    Array.prototype.splice.apply(this.options, [ event.first, newOptions.length, ...newOptions]);
    if (this.options.length < this.totalOptions)
      this.options.length = this.totalOptions;

    this.options = [...this.options].filter(item => {
      return (item && item.name.trim() !== '') ?? false;
    });
    if(this.sortOptionsAlphabetical){
      this.options.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }));
    }
    this.options.length = this.totalOptions;

    this.pageStatuses[page] = 'loaded';
    this.log(`Lazy Loaded Page ${page + 1} of ${this.totalPages}`, this.options, newOptions);
  }

  /** @returns [page number, page size] */
  private pageInfo(first: number, num: number): [number, number] {
    return [Math.floor(first / (num * 1)), num * 1];
  }

  initQuery(state?: this['state']) {
    this.functionLog('initQuery', ...arguments);
    if (this.disabled) {
      this.state = 'not-initialized';
      return;
    }

    let step =  'Initialized';

    if (this.mainQuerySub) {
      this.mainQuerySub.unsubscribe();
      step = 'Reinitialized';
    }

    if (state === 'invalid-filter') {
      this.setResultsEmpty('invalid-filter');
      return;
    }

    this.setResultsEmpty('loading');
    this.clearForLoad();

    this.whenEntityServiceExists.then(() => {
      this.queryToken = this.entityService.initQuery(this.filter, this.virtualPageSize);
      this.forThisQuery.next(this.queryToken);

      this.mainQuerySub = this.queryToken.totalForFilter
        .pipe(rxFilter(v => v !== null))
        .subscribe(v => this.initList(v));

      if (this.primeScroller) {
        this.primeScroller.clearCache();
        this.primeScroller.scrollToIndex(0);
        this.primeScroller.loadPage(0);
      }
    });

    this.log(`${step} query with filter:`, this.filter);
  }

  lazyLoad(event: LazyLoadEvent) {
    this.functionLog('lazyLoad', ...arguments);
    const [page, pageSize] = this.pageInfo(event.first, event.rows);

    this.log(`Lazy Load Called by Scroller [${page + 1} of ${this.totalPages}]`, event);
    if (this.queryToken)
      this._load(this.queryToken, event, false);
    else {
      this.setResultsEmpty('not-initialized');
      this.log('Cannot load page, query not initialized');

      this.mainQuerySub.add(
        this.forThisQuery.subscribe(q => this._load(q, event, true)));
    }
  }

  private _load(queryToken: CachedQueryToken<WarpEntity>, event: LazyLoadEvent, wasAsync: boolean) {
    this.functionLog('_load', ...arguments);
    const [page, size] = this.pageInfo(event.first, event.rows);
    this.pageStatuses[page] = 'loading';

    // if we already have a subscription for this page, resubscribe
    if (this.pageSubs[page]) {
      this.mainQuerySub.remove(this.pageSubs[page]);
      this.pageSubs[page].unsubscribe();
    }

    const sub = queryToken.getPage(page)
      .pipe(rxFilter(list => list.length > 0))
      .subscribe(entities => this.appendPage(event, entities));

    this.pageSubs[page] = sub;
    this.mainQuerySub.add(sub);
    this.log(`Requesting page${wasAsync ? ' [delayed]' : ''} ${page + 1} of ${this.totalPages}`);
  }

  updateFilter(searchStr: string, now: boolean = false) {
    this.functionLog('updateFilter', ...arguments);
    if (!this.defaultFilter)
      return;

    if (this.filterTimeout)
        clearTimeout(this.filterTimeout);

    const value = (searchStr || '')
      .split(/\s/)
      .map((v) => v.trim())
      .filter((t) => t && t !== '');

    // count non-space chars
    const valLength = value.reduce( (t, s) => t + s.length, 0);
    this.log(`Updating filter with ${valLength} chars`, searchStr, value);

    if (this.requireFilter && valLength < this.requireFilterMinCharacters) {
      this.initQuery('invalid-filter');
      return;
    }

    const filters: FilterInput = (this.searchProperties)
      .map((key) => ({ key, value, operator: FilterOperator.Like }));

    const filter = this.defaultFilter.Subset();

    const doFilter = () => {
      this.filter = value && value.length ? filter.AdvancedUnion(filters) : filter;
      this.initQuery();
    };

    if (now)
      doFilter();
    else
      this.filterTimeout = +setTimeout(doFilter.bind(this), 400);
  }
  //#endregion

  //#region Selection
  selectByIds(selections: (number | LinkedEntity)[], options: SelectionOptions = { }) {
    this.functionLog('selectByIds', ...arguments);
    // strip to ids
    let ids = selections
      .map(id => typeof id === 'number' ? id : id.id)
      .filter(id => !!id);

    if (options.unselectOthers)
      this._unselectAll(ids);

    if (!this.multiSelect && !options.forceAll)
      ids.splice(1); // only allow the first one

    // if we have them loaded, select them and remove them from the list
    if (this.options.length > 0 && this.options[0] && !this.options[0].customState)
      ids = ids.filter(id => {
        const option = this.options.find(o => o && (o.id === id));
        if (option) {
          this._selectSingle(option, true);
          return false;
        }
        return true;
      });

    if (!this.useFullEntities)
      ids = ids.filter(id => {
        const selection = selections.find(s => typeof s !== 'number' && s.id === id) as LinkedEntity & Selectable;
        if (selection) {
          this._selectSingle(selection, true);
          return false;
        }
        return true;
      });

    if (ids.length) {
      if (this.fetchSelectedSub)
        this.fetchSelectedSub.unsubscribe();
      // get these individually because they aren't loaded yet

      this.whenEntityServiceExists.then(() => {
        this.fetchSelectedSub = this.entityService
          .getPage(EntityFilter.All().only(...ids))
          .subscribe((entities) => {
            this.log(`filtered By Ids [${entities.size}]`, entities);
            this._selectMultiple(
              [...entities.values()]
                .map(this.warpEntityToLinkedSelectable.bind(this)), true, true);
            this.onChange(options);
          });
      });

    } else
      this.onChange(options);
  }

  toggleSelection(item: LinkedEntity & Selectable, options: SelectionOptions =  { }) {
    this.functionLog('toggleSelection', ...arguments);
    // ignore click of info items (could be used as groups in the future)
    if (item && item.customState)
      return;

    // can't unselect a required one, or a disabled one
    if (this._requiredEntities.value.has(item.id) || this._disabledEntities.value.has(item.id))
      return;

    if (!this.multiSelect) {
      this._unselectAll();
      if (this.dropdown)
        this.dropdown.hide();
    }

    if (!item) {
      this.onChange(options);
      return;
    }

    const selected = options.selected === undefined ? !item.selected : options.selected;
    // if (!!item.selected === selected)
    //   return;

    this._selectSingle(item, selected);
    this.onChange(options);
  }

  private _unselectAll(ids?: number[]) {
    this.functionLog('unselectAll', ...arguments);
    this._selected.forEach( s => (s as Selectable).selected = false);
    this._selected.splice(0, this._selected.length);
  }

  private _selectSingle(item: LinkedEntity & Selectable, selected: boolean) {
    this.functionLog('_toggleSingle', item.name, ...arguments);

    if (selected && !this._selected.find(s => s.id === item.id))
      this._selected.push(item);
    else if (!selected) {
      const index = this._selected.findIndex(s => s.id === item.id);
      if (index >= 0)
        this._selected.splice(index, 1);
    }

    item.selected = selected;
  }

  private _selectMultiple(entities: (LinkedEntity & Selectable)[], selected: boolean, append: boolean) {
    this.functionLog('selectMultiple', ...arguments);

    if (!this.multiSelect || !append)
      this._unselectAll();

    if (!entities.length)
      return;

    if (!this.multiSelect)
      entities.splice(1); // only allow the first one

    const toAdd = entities.filter((e) => {
      e.selected = true;
      return !this._selected.find(s => s.id === e.id);
    });

    this._selected.splice(0, 0, ...toAdd);
  }


  updateLastEmitted() {
    this.functionLog('updateLastEmitted', ...arguments);
    this.lastEmittedChangeValues = this.selectedAsSet();
  }

  selectedAsSet() {
    this.functionLog('selectedAsSet', ...arguments);
    return new Set( this._selected.map( s => s.id ).filter(s => !!s));
  }

  shouldDoOnChange(previous = this.lastEmittedChangeValues, current = this._selected) {
    this.functionLog('shouldDoOnChange', ...arguments);
    // first change, emit
    if (!this.lastEmittedChangeValues)
      return true;

    const prevSize = this.lastEmittedChangeValues.size
    const currSize = this._selected.length;

    // both empty, ignore
    if ( !currSize && !prevSize )
      return false;

    // either empty, not both, emit
    if ( currSize !== prevSize )
      return true;

    const prev = this.lastEmittedChangeValues;
    const curr = this.selectedAsSet();

    // sets of same length, so if all of A exist in B, they are equal
    for (const selected of curr) {
      // not equal, emit
      if (!prev.has(selected))
        return true;
    }

    // equal, ignore
    return false;
  }

  onChange({ markAsTouched = false, performOnChange = true, renderChanges = true }: SelectionOptions =  { }) {
    this.functionLog('!!onChange!!', ...arguments);
    this.updateColor();

    if (markAsTouched)
      this.markAsTouched();

    if ( this.shouldDoOnChange() ) {
      if (performOnChange)
        this.updateLastEmitted();

      if (renderChanges)
        this.renderChanges();

      if (markAsTouched || performOnChange)
        this.emitChanges();
    }
  }

  private emitChanges() {
    const controlValueEmission = this._selected.map(s => ({
      id: s.id,
      name: s.name,
      computedProperties: s['ComputedProperties'] || s['computedProperties'] || undefined
    }));

    this.functionLog('emitChanges', controlValueEmission);
    this._controlValueAccessorOnChange(controlValueEmission);

    this.selectedChange.emit(this._selected);
    this.selectedIdsChange.emit(this._selected.map(s => s.id));
  }

  renderChanges() {
    this.functionLog('renderChanges', ...arguments);
    //trigger change detection
    this._selected = [...this._selected];
  }
  //#endregion

  //#region ControlValueAccessor / Validator
  _controlValueAccessorOnChange: (value: LinkedEntity[]) => void = () => { };
  _controlValueAccessorOnTouched: () => void = () => { };

  markAsTouched() {
    this.functionLog('markAsTouched', ...arguments);
    this._controlValueAccessorOnTouched();
  }

  writeValue(obj: OneOrMany<LinkedEntity>): void {
    const arr = obj instanceof Array ? obj : [obj];
    this.functionLog('writeValue', `[${this._selected.length}] => [${arr.length}]`, ...arguments);

    if (obj && arr.length)
      this.selectByIds(arr, { unselectOthers: true, forceAll: true, performOnChange: true, markAsTouched: false });
    else {
      // Move this before error checking because the unselect all is needed to clear the selected array
      this._unselectAll();

      // undefined and empty array are ok, but null was probably a mistake
      if (obj === null) {
        this.messageService.add(this.logName, 'Ignoring Null Input!', ...arguments, MessageService.WARNING);
        this.lastEmittedChangeValues = undefined;
        return;
      }

      // programmatic changes shouldnt emit, but should update the last emitted
      this.onChange({ markAsTouched: false, performOnChange: false });
    }

    this.log(`Wrote value [${this._selected.length}]`, this._selected.map(s => [s.id, s.name]));
  }

  registerOnChange(fn: VirtualEntitySelectComponent['_controlValueAccessorOnChange']): void {
    this.functionLog('registerOnChange', ...arguments);
    this._controlValueAccessorOnChange = fn;
  }

  registerOnTouched(fn: VirtualEntitySelectComponent['_controlValueAccessorOnTouched']): void {
    this.functionLog('registerOnTouched', ...arguments);
    this._controlValueAccessorOnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.disabled !== isDisabled) {
      this.disabled = isDisabled;

      if (!this.disabled && this.state === 'not-initialized')
        this.initQuery();
    }

  }
  //#endregion

  private get logName() {
    const key = this.logKey ? `<${this.logKey}>` : '';
    return 'VirtualEntitySelect' + key;
  }

  // tslint:disable-next-line: no-any
  log(...args: any[]) {
    this.messageService.log(this.logName, ...args, MessageService.VERBOSE);
  }

  functionLog(funcName, ...args: any[]) {
    this.messageService.log(this.logName, `${funcName} [${this._selected.length}] ${typeof args[0] == 'string' ? args[0] : ''}`, ...args, this._selected.slice(), MessageService.VERBOSE);
  }
}
