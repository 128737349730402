<!-- <div > -->
    <div class="stat-card-content">
        <div>
            <i class="{{icon}}"></i>
        </div>
        <div class="align-items-end">
            <p class="value">{{value}}</p>
            <p class="label">{{label}}</p>
        </div>
    </div>
<!-- </div> -->
