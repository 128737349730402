import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, SimpleChanges } from '@angular/core';
import { EntityTypes, FilledStatistic, Statistic } from '../../_core/models';
import { AgencyStatsService } from '../../_core/services';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, AuthService } from '@ripple/services';
import { SubscriptionContainer } from '@ripple/models';
import { MonthNote } from '../../_core/models/MonthNote';



@Component({
  selector: 'sagesse-enter-stats-table',
  templateUrl: './enter-stats-table.component.html',
  styleUrls: ['./enter-stats-table.component.scss']
})


export class EnterStatsTableComponent extends SubscriptionContainer implements OnInit {
  @Input() stats: Array<FilledStatistic>;
  @Input() name: string;
  @Input() showGroup: boolean = true;
  @Input() month: number = new Date().getMonth() + 1;
  @Input() year: number = new Date().getFullYear();
  @Input() edited = false;
  @Input() loading = true;
  statisticService: WarpEntityServiceCache<Statistic>;
  monthNotesService: WarpEntityServiceCache<MonthNote>;
  filledStatisticService: WarpEntityServiceCache<FilledStatistic>;
  quarter: number = Math.ceil(this.month / 4 );
  displayMonthNoteModal = false;
  monthNote = '';
  selectedFilledStat: FilledStatistic;
  editingNote:MonthNote | null;
  sendingNote = false;



  @Output() updateStats = new EventEmitter<FilledStatistic>();
  @Output() monthChanged = new EventEmitter<any>();
  updatedStats: Array<FilledStatistic> = [];
  // month: number = new Date().getMonth() +1;
  // year: number= new Date().getFullYear();
  disableStatChange: boolean = false;

  constructor(
    private agencyService: AgencyStatsService,
    private factory: WarpEntityCacheFactoryService,
  ) {
    super();
    this.statisticService = factory.get(EntityTypes.Statistic) as WarpEntityServiceCache<Statistic>;
    this.filledStatisticService = factory.get(EntityTypes.FilledStatistic) as WarpEntityServiceCache<FilledStatistic>;
    this.monthNotesService = factory.get(EntityTypes.MonthNote) as WarpEntityServiceCache<MonthNote>;

  }


  ngOnInit(): void {
    console.log("these are the passed in stats", this.stats);
  }

  updateTargets(stat, $event) {
    const oldValue = parseInt(stat.properties.value);
    const newValue = parseInt($event)
    if (oldValue >= 0 && newValue  >= 0 && stat.targetInformation) {
      const difference = newValue - oldValue;
      // update progress on target info for selected stat
      stat.targetInformation.progress += difference;
      // update progress on target info for stats related to the outcome stat
      if (stat.targetInformation.otherStats && stat.targetInformation.otherStats.length > 0 ) {
      const relatedStats = this.stats.filter(s => stat.targetInformation.otherStats.some(o => s.linkedProperties.linkedstatistic.id === o.ID));
      // update them through the references
      relatedStats.forEach(s => {
        s.targetInformation.progress += difference;
      });
      }

    }

  }

  // When Input changes add the stat to an array of stats to save
  onChange(stat: FilledStatistic, valueOrNote) {
    stat.edited = true;
    switch (valueOrNote) {
      case 'value':
        if (parseInt(stat.properties.value) >= 0) {
          this.nextSub = this.agencyService.sanityCheck(stat.id, stat.properties.value).subscribe(resp => {
            if (resp) {
              stat.properties.passedsanitycheck = "Yes";
              stat.properties.passedsanitycheck_cfcid = "1166";
            } else {
              stat.properties.passedsanitycheck = "No";
              stat.properties.passedsanitycheck_cfcid = "1167";
            }
          });
        }
        this.updateStats.emit(stat);
        break;
      case 'note':
        this.statisticService.update(stat.LinkedStat).toPromise().then(resp => {
          this.loading = false;
          console.log("note chaged", resp)
        })
        break;
    }

  }

  passedSanityCheck(stat): boolean {
    if (stat.properties.passedsanitycheck && stat.properties?.passedsanitycheck.toLowerCase() === "no")
      return false;
    else
      return true;
  }

  isCarryover(stat): boolean {
    if (stat.properties.carryover && stat.properties?.carryover.toLowerCase() !== "none")
      return true;
    else
      return false;
  }

  getMonthName(month) {
    const d = new Date();
    d.setDate(1);
    d.setMonth(month - 1);
    return d.toLocaleString("default", { month: "long" });
  }

  monthChange(change: number) {

    this.month += change;
    if (this.month == 0) {
      this.month = 12;
      this.year--;
    } else if (this.month == 13) {
      this.month = 1;
      this.year++;
    }

    let currMonth = new Date().getMonth() + (new Date().getFullYear() - 1) * 12;
    let enterMonth = this.month + (this.year - 1) * 12;

    console.log(currMonth, enterMonth, currMonth-enterMonth);

    if (currMonth - enterMonth > 2)
      this.disableStatChange = true;
    else
      this.disableStatChange = false;


    this.quarter = Math.ceil(this.month / 4);

    this.monthChanged.emit( { month: this.month, year: this.year});
    this.editingNote = null;
  }

  openNotesModal(stat) {
    this.monthNote = '';
    this.editingNote = null;
    this.selectedFilledStat = stat;
    this.displayMonthNoteModal = true;
  }

  editMonthNote() {
    this.editingNote.properties.notes = this.monthNote;
    this.sendingNote = true;
    this.monthNotesService.update(this.editingNote).subscribe(x => {
      this.editingNote = null;
      this.displayMonthNoteModal = false;
      this.sendingNote = false;
    });
  }


  openEditMonthNotes(notes) {
    this.monthNote = '';
    this.editingNote = notes[0];
    if (this.editingNote && this.editingNote.properties){
    this.monthNote = this.editingNote.properties.notes;
    this.displayMonthNoteModal = true;
    }
  }

  submitMonthNote() {
    const monthNoteEnt = new MonthNote(MonthNote.empty());
    monthNoteEnt.properties.notes = this.monthNote;
    monthNoteEnt.properties.month = this.month;
    monthNoteEnt.properties.year = this.year;
    const selectedStat = this.selectedFilledStat.LinkedStat;
    this.sendingNote = true;
    this.monthNotesService.create(monthNoteEnt).subscribe(res => {
        const unchangeablename = 'monthcomments';
        const resObj = { id: res, unchangeablename};
        // exactly 1 comment already exists
        if (selectedStat.linkedProperties.monthcomments && !selectedStat.multiValuedProperties.monthcomments) {
          const currentId = selectedStat.linkedProperties.monthcomments.id;
          delete selectedStat.linkedProperties.monthcomments;
          if (!selectedStat.linkedPropertiesList.some(x => x.id === currentId)) {
          selectedStat.linkedPropertiesList.push( { id: currentId, unchangeablename});
          }
          selectedStat.linkedPropertiesList.push(resObj);
        }
        // this is the very first comment on the stat
        else if(!selectedStat.linkedPropertiesList.some(s => s.unchangeablename === unchangeablename) && !selectedStat.multiValuedProperties.monthcomments) {
          selectedStat.linkedProperties.monthcomments = { id: res};
        }
        // multiple comments already exist
        else {
          delete selectedStat.linkedProperties.monthcomments;
          selectedStat.multiValuedProperties.monthcomments.forEach(x => {
            if (!selectedStat.linkedPropertiesList.some(y => y.id === x.id)){
            selectedStat.linkedPropertiesList.push( { id: x.id, unchangeablename});
            }
          });
          selectedStat.multiValuedProperties.monthcomments.push(resObj);
          selectedStat.linkedPropertiesList.push(resObj);
        }
        monthNoteEnt.id = res;
        monthNoteEnt.entityId = res;

        this.selectedFilledStat.monthComments.push(monthNoteEnt);
        // force rerun pipe
        this.selectedFilledStat = Object.assign({} , this.selectedFilledStat);
        const index =  this.stats.findIndex(s => s.id === this.selectedFilledStat.id);
        this.stats[index] = this.selectedFilledStat;

        this.statisticService.update(selectedStat).subscribe(x => {
       this.monthNote = '';
       this.sendingNote = false;
       this.displayMonthNoteModal = false;
      });


    });

  }


}

@Pipe({
  name: 'MonthNote'
})
export class MonthNotePipe implements PipeTransform{
    transform(stat, month: number) {
      if (!stat.monthComments)  return [];
      return stat.monthComments.filter(x => x.properties.month == month);
    }
}
