import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { environment as rippleEnvironment } from '@ripple/environment';
import '@angular/compiler';

if (environment.production) {
  enableProdMode();
}

Object.keys(environment).forEach(prop => {
  rippleEnvironment[prop] = environment[prop];
});

Sentry.init({
  dsn: "https://d23c480939394f289753f3ddc49a56ad@o32642.ingest.sentry.io/5747971" ,
  integrations: [
    // Registers and configures the Tracing integration,
    // which automatically instruments your application to monitor its
    // performance, including custom Angular routing instrumentation
    new Integrations.BrowserTracing({
      tracingOrigins: [environment.host, environment.restEndpointUrl + '/api'],
      routingInstrumentation: Sentry.routingInstrumentation,

    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
