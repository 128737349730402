import { Component, OnInit } from '@angular/core';
import { FormBuilderService } from '@ripple/formbuilder';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityLogService } from '@ripple/services';
import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
  selector: 'sagesse-form-builders',
  templateUrl: './form-builders.component.html',
  styleUrls: ['./form-builders.component.scss']
})
export class FormBuildersComponent extends BasePage implements OnInit {

  cols = [
    { header: 'name', field: 'name' },
  ];
  forms = [];
  loading = true;

  constructor(
    private logService: WarpEntityLogService,
    protected formService: FormBuilderService, 
    private router: Router,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
  ) {
    super(logService);
   }

  ngOnInit(): void {
    this.formService.getForms().subscribe( forms => {
      this.forms = forms;

      this.forms.forEach( f => {
        f.actionMenu = new Array<MenuItem>();
        f.actionMenu.push({
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => this.remove(f),
        });
      });

      this.loading = false;
    });
  }

  logFromUrl(url: string): number | number[] {
    return [];
  }

  getLogFormat(url: string): string {
    return 'Viewed FormBuilder list';
  }

  edit(formId) {
    this.router.navigate([`${formId}`], { relativeTo: this.route });
  }

  remove(form) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete form ID ${form.id}?`,
      accept: () => this.formService.deleteForm(form.id).subscribe(_ => {
          const i = this.forms.indexOf(form);
          this.forms.splice(i, 1);
          this.forms = this.forms.splice(0, this.forms.length); // refresh view
        })
    });
  }

  goToReport(formId) {
    this.router.navigate([`report/${formId}`], { relativeTo: this.route });
  }
}
