<div class="entity-note-wrapper" [ngClass]="{'in-delete-mode': deleteMode}">
  <div class="entity-note" [style.backgroundColor]="getNoteTypeStyling().bgColor" (click)="deleteMode = false">
    <div *ngIf="allowPin">
      <i class="fas fa-thumbtack pin-icon"
        class="icon {{ loading ? 'pi pi-spin pi-spinner' : 'fas fa-thumbtack pin-icon' }}"  
        [style.color]="ifPinned ? 'var(--danger)' : getNoteTypeStyling().highlightColor"
        (click)="pin()"></i>
    </div>
    <div [class]="noteTypeFlagCustomClass ? noteTypeFlagCustomClass : 'red-flag'" *ngIf="getNoteTypeStyling().flag"></div>
    <div class="status" [ngClass]="_statusClass" [style.backgroundColor]="getNoteTypeStyling().highlightColor"></div>

    <i *ngIf="!allowPin && (iconClass || loading)" class="icon {{ loading ? 'pi pi-spin pi-spinner' : getNoteTypeStyling().iconClass }}" [style.color]="getNoteTypeStyling().highlightColor"></i>

    <div class="details">
      <input class="title" *ngIf="editMode && view === 'small'" pInputText type="text" #text (keyup.Enter)="_save(text.value)" (blur)="cancelEdit()" [(ngModel)]="note.description">
      <div class="title" *ngIf="editMode && view === 'big'">
        <textarea pInputTextarea #noteTextArea [maxLength]="maxLength"
          class="w-100" [(ngModel)]="note.description"
          [rows]="5" [autoResize]="true"></textarea>
        <div class="d-flex mb-3">
          <p-button icon="pi pi-check" class="ml-auto"
            label="Save" (click)='_save(noteTextArea.value)'></p-button>
          <p-button icon="pi pi-refresh"
            label="Cancel" (click)='cancelEdit()'></p-button>
        </div>
      </div>
     
      <div class="title" style="white-space: pre-wrap; word-wrap: break-word; word-break: break-all;" *ngIf="!editMode" (click)="onNoteClick()">{{ title }}</div>
      <div *ngIf="allowTag">
        <ng-container *ngIf="!tagEditMode">
          <span><i class="pi pi-tags tag-icon mr-2" (click)="tagEditMode = true"></i></span>
          <span *ngFor="let tag of tags"
            class="badge rounded-pill badge--white mr-1 mb-1">
            {{tag.name}}
          </span>
        </ng-container>
        <ng-container *ngIf="tagEditMode">
          <div>
            <ripple-tags [entity]="note"></ripple-tags>
          </div>
          <div class="d-flex my-3">
            <p-button icon="pi pi-check" class="ml-auto"
              label="Save" (click)='saveTag()'></p-button>
            <p-button icon="pi pi-refresh"
              label="Cancel" (click)='cancelTagEdit()'></p-button>
          </div>
        </ng-container>
      </div>
      <div class="text-right">
        <div class="date sub-text">{{ date || subTitle }}</div>
        <div class="creator-pill pill" [ngClass]="_statusClass">{{ pill }}</div>
      </div>
    </div>
  </div>
  <div class="delete-toggler">
    <button class="r-btn" (click)="deleteMode = true"><i class="pi pi-chevron-left"></i></button>
  </div>
  <div class="deleter">
    <button class="r-btn" (click)="confirmDelete()"><i class="pi pi-trash"></i></button>
  </div>
</div>
<!-- <p-overlayPanel #op [appendTo]="'body'">
  <ng-template pTemplate>
    <ng-container *ngIf="modifyPerm">
      <button type="text" pButton label="Edit" (click)="startEdit()"></button>
      <button type="text" pButton label="Delete" (click)="confirmDelete()"></button>
    </ng-container>
    <ng-container *ngIf="!modifyPerm">
      No Options available.
    </ng-container>
  </ng-template>
</p-overlayPanel> -->
