<div class="pane">
    <div class="pane__header pane__header--sticky bg-white">
      <div class="header">
        <h1 class="header__heading">
          Offices
        </h1>
        <div class="header__actions">
        </div>
      </div>
    </div>
    <div class="pane__body">
      <ripple-entity-list #officeList
        [entityService]="officeService" [filter]="filter"
        [showFilter]="false"
        [editButton]="edit" [addButton]="add"></ripple-entity-list>
    </div>
</div>

<p-dialog header="Office for {{organization?.properties.name}}"
    [(visible)]="officeDialogShow"
    *ngIf="officeDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <ripple-entity-details [entity]="officeSelected" [hideButtons]="true" #officeDetails
      [generatorOptions]="generatorOptions" [fieldOrders]="fieldOrders"
      (afterSave)="officeDialogShow=false"
    ></ripple-entity-details>
    <p-footer>
        <ripple-button-list [buttons]="officeDetails.splitButtons"></ripple-button-list>
    </p-footer>
</p-dialog>