import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CustomFieldInModule, EntityFilter, IGenFormlyOptions } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { EntityScrollViewComponent } from '@ripple/ui';
import { EntityTypes, Organization, OrganizationContact } from '../../../_core/models';

@Component({
  selector: 'sagesse-organization-contacts',
  templateUrl: './organization-contacts.component.html',
  styleUrls: ['./organization-contacts.component.scss']
})
export class OrganizationContactsComponent implements OnInit {

  @Input() organization: Organization;

  @ViewChild('contactList') contactList: EntityScrollViewComponent;

  organizationContactFilter: EntityFilter = EntityFilter.None;
  organizationContactService: WarpEntityServiceCache<OrganizationContact>;

  // new contact dialog
  contactDialogShow = false;
  organizationContactSelected: OrganizationContact;

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService
  ) {
    this.organizationContactService = this.warpEntityFactory.get(EntityTypes.OrganizationContact) as WarpEntityServiceCache<OrganizationContact>;
  }

  ngOnInit(): void {
    this.organizationContactFilter = EntityFilter.Advanced({ organization_lid: this.organization.entityId});
  }

  edit = (orContact: OrganizationContact) => {
    this.organizationContactSelected = orContact;
    this.contactDialogShow = true;
  };
  add = () => {
    this.organizationContactSelected = OrganizationContact.empty().linkedProperty('organization', this.organization);
    this.contactDialogShow = true;
  };

}
