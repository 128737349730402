import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class ExportService {

  public constructor() {
  }

  exportXLSX(json, fileName) {
    const worksheet = XLSX.utils.json_to_sheet(json);
    const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  /**
   * Creates a single workbook with multiple sheets
   * @param jsonSheets Map of sheet name to json data
   * @param fileName Name of the file to save
   */
  exportXLSXMultipleSheets(jsonSheets: Map<string, any>, fileName) {
    const workbook = { Sheets: {}, SheetNames: [] };
    jsonSheets.forEach((json, sheetName) => {
      const worksheet = XLSX.utils.json_to_sheet(json);
      workbook.Sheets[sheetName] = worksheet;
      workbook.SheetNames.push(sheetName);
    });
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([excelBuffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}
