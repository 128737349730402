import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

export interface BreadcrumbItem {
  name: string;
  url?: string;
}

@Component({
  selector: 'ripple-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit {

  @Input() defaultTitle: string;
  @Input() items: BreadcrumbItem[];
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  linkClicked(index: number, item: BreadcrumbItem) {
    let url = item.url;
    if(!item.url)
    {
      url = '';
      for(let i = 0; i <= index; i++)
      {
        url += '../';
      }
    }
    
    this.router.navigate([url]);
    return false;
  }

}
