import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class Office extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.Office;
    }

    static empty(): Office {
        return new Office(super.emptyEntity(EntityTypes.Office));
    }
}