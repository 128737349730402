import { Injector } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject, forkJoin, of } from 'rxjs';
import { first, filter, map, concatMap } from 'rxjs/operators';

import { WarpEntityType, WarpEntity, SignalRAction, IEntityPage, EntityFilter, SignalRHandlerObject, IGenericObject, IWarpEntityInputObject } from '@ripple/models';
import { environment } from '@ripple/environment';

import { CachedPageToken, CacheMetaData, LoadStyle, CachedQueryToken } from './caching/cache-token';
import { GenericWarpEntityService } from './generic-warp-entity.service';
import { MessageService } from '../general/message.service';
import { Mutex } from 'async-mutex';
import { RippleApiService } from '../ripple-api/ripple-api.service';

const pluck = (...names: string[]) => (obj) => {
  const subset = { };
  names.forEach(name => {
    subset[name] = obj[name];
  });
  return subset;
};


export class WarpEntityServiceCache<T extends WarpEntity> {
  private meta: CacheMetaData;
  public get settings() { return this.meta; }

  private entityType: ReplaySubject<WarpEntityType>;
  private entityTypeDisplayName: string;
  public get typeName() { return this.entityTypeDisplayName; }

  protected apiService: RippleApiService;
  protected warpService: GenericWarpEntityService;
  public messageService: MessageService;

  /** Used to load every item in this cache, currently only affects LoadAll and getById */
  protected loadFilter?: EntityFilter;

  private mutex;

  protected _change: Subject<SignalRHandlerObject[]>;
  public onChange: Observable<SignalRHandlerObject[]>;
  get onEveryChange() { return this.onChange.pipe(concatMap(list => list)); }

  private _signalRHandler: (...incoming: SignalRHandlerObject[]) => void;
  /** this will override the signalR logic, \
   * ensure you push any taken updates to `this.change`
   */
  protected set signalRHandler(fun: (...incoming: SignalRHandlerObject[]) => void) { this._signalRHandler = fun; }

  protected entities: BehaviorSubject<Map<number, T>>;
  protected loadingEntities: Set<number> = new Set();
  /** organizes tokens by filter, then page */
  protected cacheTokens: Map<string, CachedQueryToken<T>>;

  get totalEntities() { return this.meta.totalEntities; }

  get status() { return this.meta.status; }
  set loadStyle(style: LoadStyle) {
    this.meta.loadStyle = style;
    // if (style === 'eager') this.loadCache();
  }

  constructor(protected injector: Injector, private cast: new (o) => T, public entityTypeId: number,
              public pageSize = 1000) {
    this.warpService = injector.get(GenericWarpEntityService);
    this.messageService = injector.get(MessageService);
    this.apiService = injector.get(RippleApiService);

    this.entities = new BehaviorSubject(new Map());
    this.entityType = new ReplaySubject(1);
    this.entityTypeDisplayName = `${this.constructor.name}<${this.entityTypeId}>`;
    this.mutex = new Mutex();

    this.cacheTokens = new Map();
    this.meta = new CacheMetaData({
      pageSize,
      loadStyle: 'lazy',
    });

    this.log(`Creating ${this.typeName}`);

    this.warpService
      .getEntityStructure(entityTypeId)
      .subscribe(type => {
        this.entityType.next(type);
        // this.entityType.complete();
        this.entityTypeDisplayName = `${type.name} (${this.entityTypeId})`;
      }, err => {
        this.log('Error Loading Type', err, MessageService.ERROR);
        this.entityType.error(err);
      });

    this.entities.subscribe(m => this.meta.currentEntities = m.size);

    this._change = new Subject();
    this.onChange = this._change.asObservable();
  }

  //#region SignalR
  /** Entry point for signalR messages.
   * The default handler maintains the single cache, if lazy loading, all updated entities are marked stale
   * @event `this.signalRHandler` or default handler
   */
  public signalRDispatch(messages: SignalRHandlerObject[]) {
    this.messageService.add(
      `${this.typeName} SignalR`,
      `${messages.length} Updates(s)`,
      messages.map(({ entityId, action }) => ({ entityId, action })));

    if (typeof this._signalRHandler === 'function') this._signalRHandler(...messages);
    else {
      let doClear = false;

      // Default handler
      const { DELETED, ADDED, UPDATED, /* NONE */ } = messages.reduce((outs, message) => {
        outs[message.action.toUpperCase()]?.push(message);
        return outs;
      }, { DELETED: [], ADDED: [], UPDATED: [], NONE: [] } as { [key in SignalRAction]: SignalRHandlerObject[] });

      if (DELETED.length > 0) {
        this.removeItemsFromCache(...DELETED.map(m => m.entityId));
        doClear = true;
      }

      const trueUpdates: SignalRHandlerObject[] = [];
      const trueAdds: SignalRHandlerObject[] = [...ADDED];

      if (UPDATED.length > 0)
        UPDATED.forEach(element => {
          const hash = element.hash;
          if (this.entities.value.has(element.entityId)) { // we only care if it's in the cache
            const ent = this.entities.value.get(element.entityId);
            if (hash !== ent.hash)
              trueUpdates.push(element);
            else
              this.log(`SignalR Ignore ${element.entityId}: Not Changed.`, element, MessageService.VERBOSE);
          } else {
            this.log(`SignalR Ignore ${element.entityId}: Not In Cache.`, element, MessageService.VERBOSE );
            trueAdds.push(element);
          }
        });

      if ((trueUpdates.length + trueAdds.length) > 0) {
        // KG: 2024-09-24: There are currently no eager loading caches, so this is always false
        if (this.meta.loadStyle === 'eager')
          //TODO: this needs to handle computed properties, childrenProperties, loadFilter, and loadChildren
          forkJoin(
            trueAdds.concat(trueUpdates)
              .map( m => m.entity)
              .filter(e => !!e)
          )
            .toPromise()
            .then(all => all.length > 0 && this.updateItemsInCache( ...all.map( e => this.constructEntity(e)) ));
        else
          this.removeItemsFromCache(...trueAdds.concat(trueUpdates).map(m => m.entityId));

        doClear = true;
      }

      if (doClear) {
        this.clearTokens([...DELETED, ...ADDED, ...trueUpdates]);
      }
    }

    const realMessages = messages.filter(({ action }) => action !== SignalRAction.NONE);
    if (realMessages.length > 0)
      this._change.next(realMessages);
  }
  //#endregion

  //#region Cache Functions

  protected setResponseModifications(entity: T, filterUsed: EntityFilter) {
    //TODO? cache the requested computed and child properties here, for possibly more efficient checks later (below)
    entity.__requestedChildren = filterUsed.loadChildrenIds;
    return entity;
  }

  /**
   * Checks to see if the given entity has any computed properties, and if so, then checks to see if the have the ones the filter is looking for
   * @param filter The filter to get the computed properties from. Assumes the filter has already been checked for computed properties
   * @param entity The entity to check for computed properties
   * @returns true if the entity has the computed properties the filter is looking for, false otherwise
   */
  protected checkResponseModifications(entity: T, filter: EntityFilter): boolean {
    if (filter.hasLoadChildren) {
      const loadedChildren = entity.__requestedChildren || [];
      // if none are loaded or any loadChildren are not included, return false
      if (loadedChildren.length == 0 || filter.loadChildrenIds.some(id => !loadedChildren.includes(id)))
        return false;
    }

    if(filter.hasComputedProperties ){
      if(!entity.hasOwnProperty('ComputedProperties'))
        return false;

      filter.computedPropertiesObject.Properties.forEach(property => {
        if(!entity.ComputedProperties.hasOwnProperty(property))
          return false;
      });
    }
    return true;
  }

  /**
   * checks the cache for the entity,
   * and ensures that the entity in question has the required modifications for the filter
   * */
  private hasEntityForFilter(id: number, requestingFilter: EntityFilter, map = this.entities.value) {
    return !map.has(id) || !this.checkResponseModifications(map.get(id), requestingFilter);
  }

  private getFakePageForIds(ids: number[]): IEntityPage {
    return {
      draw: 0, columnFilters: { }, data: [],
      ids,
      recordsTotal: ids.length,
      recordsFiltered: ids.length,
      propertyCounts: new Map(),
    };
  }

  /**
   * A constructor for a function that
   * Adds Entities from a page to the cache
   * @returns total entities for filter, and A map of the page's entities
   */
  protected appendPageToCache(token: CachedPageToken<T>) {
    return (page: IEntityPage) => {
      this.log('Updating cache from page', token, page, MessageService.VERBOSE);

      const updateCacheFromPage = (pageData: IWarpEntityInputObject[], entityFilter: EntityFilter) => {
        this.updateItemsInCache(
          ...pageData.map(e => this.setResponseModifications(this.constructEntity(e), entityFilter))
        );
      };

      const notifySubscribersForToken = (ids:number[]) => {
        this.log(`Dispatching page ${ids.length}`, token, ids, MessageService.VERBOSE);
        token.dispatch(page as Omit<IEntityPage, 'data' | 'columnFilters'>, this.getEntitiesFromCache(ids));
      }

      this.mutex
      .acquire()
      .then(release => {
        // ...
        try {
          if (page.ids) {
            // are there any ids from page.data that are not in our cache
            const newMap = this.entities.value;
            // an entity exists and is missing, or the one we have doesn't have the requested computed properties or children
            const missingIds: number[] = page.ids.filter(id => id > 0 && this.hasEntityForFilter(id, token.filter, newMap));

            if (missingIds.length > 0) {
              // if so, go get the missing ones
              // add them to the cache

              const newFilter: EntityFilter = EntityFilter.All()
                .only(...missingIds)
                .withResponseModifiers(token.filter);

              this.warpService
                .getPage(this.entityTypeId, 0, missingIds.length, newFilter, false)
                .toPromise()
                .then(missingEntitiesPage => {
                  updateCacheFromPage(missingEntitiesPage.data, newFilter);
                  notifySubscribersForToken(page.ids);
                  release();
                });
            } else {
              //TODO: no change for this token, do we need to re-emit at all?

              // no missing entities, so just notify the subscribers
              notifySubscribersForToken(page.ids);
              release();
            }
          }
          else {
            updateCacheFromPage(page.data, token.filter);
            notifySubscribersForToken(page.data.map(e => e.entityId));
            release();
          }
        } catch {
          release();
        }
      });


      if (page.recordsTotal !== undefined && page.recordsTotal !== null)
        this.meta.totalEntities = page.recordsTotal;

      this.meta.status = 'ready';
    };
  }

  protected getEntitiesFromCache(ids: number[])
  {
    const newMap = this.entities.value;
    const outMap = new Map();
    ids.forEach(entityId => {
      outMap.set(entityId, newMap.get(entityId));
    });

    return outMap;
  }

  public removeItemsFromCache(...objs: T[] | number[]) {
    this.log(`Removing ${objs.length} objects from cache`, objs, MessageService.VERBOSE)
    const newMap = this.entities.value;
    objs.forEach(o => newMap.delete((typeof o === 'number') ? o : o.entityId));
    this.entities.next(newMap);
  }

  protected updateItemsInCache(...objs: T[]): Map<number, T> {
    this.log(`Updating cache ${objs.length}`, objs, MessageService.VERBOSE);
    const newMap = this.entities.value;
    const outMap = new Map();

    objs.forEach(entity => {
      outMap.set(entity.entityId, entity);
      newMap.set(entity.entityId, entity);
    });

    this.entities.next(newMap);
    this.meta.status = 'ready';
    return outMap;
  }

  /** Clears the Cache */
  emptyCache() {
    this.entities.value.clear();
    this.entities.next(new Map());
    this.clearTokens();
  }

  /** For Eager Loading, normally Use `getPage` with an empty filter instead */
  protected loadCache() {
    this.assertEagerLoading();
    this.meta.status = 'loading';
    // TODO: this needs to handle computed properties, childrenProperties, loadFilter, and loadChildren
    return this.warpService
      .getAll(this.entityTypeId)
      .pipe(this.mapToT())
      .toPromise()
      .then(entities => this.updateItemsInCache(...entities))
      .catch(this.errorHandler());
  }

  /** For Eager Loading, Use `getPage` with a paginator instead. */
  protected loadCacheFromFilter(filterString: string, matchAll: boolean = true, otherParams: string = null) {
    this.assertEagerLoading();
    this.meta.status = 'loading';
    return this.warpService
      .getAllWithFilter(this.entityTypeId, filterString, matchAll, otherParams)
      .pipe(this.mapToT())
      .toPromise()
      .then(entities => this.updateItemsInCache(...entities))
      .catch(this.errorHandler());
  }

  /** For Eager Loading, loads the entire cache if it isn't loaded */
  protected ensureCache() {
    this.assertEagerLoading();
    if (this.status === 'error' || this.status === 'not ready') this.loadCache();
  }
  //#endregion

  //#region Get Functions
  /**
   * Get a single Entity
   * @param id The entityId
   * @returns a WarpEntity or undefined
   */
  get(id: number | string): Observable<T>;

  /**
   * Get a page of a list of warpEntities based on a filter
   * @param entityFilter the filter
   * @param page the page number
   */
  get(entityFilter: EntityFilter, page?: number): Observable<Map<number, T>>;

  /** @internal Dispatch the get function to either `getPage` or `getById`. */
  get(idOrFilter: (number | string) | EntityFilter, page?: number): Observable<Map<number, T>> | Observable<T> {
    if (idOrFilter instanceof EntityFilter)
      return this.getPage(idOrFilter.Subset().setPage(page));
    else
      return this.getById(idOrFilter);
  }

  /** Gets a map of entities matching the filter, paged.
   * @param maintainQuery whether to automatically reload the page upon fetch
   */
  getPage(maintainQuery?: boolean): Observable<Map<number, T>>;
  /** Gets a map of entities matching the filter, paged.
   * @param entityFilter filter information, make sure to keep track of page information, default is page 0 for all entities
   */
  getPage(entityFilter: EntityFilter, maintainQuery?: boolean): Observable<Map<number, T>>;
  getPage(entityFilter: EntityFilter | boolean = EntityFilter.None, maintainQuery: boolean = true): Observable<Map<number, T>> {
    if (typeof entityFilter === 'boolean') {
      maintainQuery = entityFilter;
      entityFilter = EntityFilter.None;
    }

    const token = this.getPageToken(entityFilter);
    return maintainQuery ? token.get() : token.pipe(first());
  }

  /** Get an entity by its ID.
   * @returns the entity or undefined
   */
  getById(_id: number | string): Observable<T> {
    const id = (typeof _id === 'string') ? parseInt(_id, 10) : _id;

    return this.getPage(
        (this.loadFilter || EntityFilter.None).Subset().only(id))
      .pipe( map(e => e.get(id)) );
  }

  /** Get Warp Entity Type. */
  getEntityStructure(entityTypeId?: number): Observable<WarpEntityType> {
    return this.entityType;
  }

  getCfimOptions(name: string) {
    return this.entityType.pipe(map(et => et.getCustomFieldChoices(name)));
  }

  /** Eager Loading Only, Otherwise Use `getPage`. */
  getAll(): BehaviorSubject<Map<number, T>> {
    this.ensureCache();
    return this.entities;
  }

  /** Eager Loading Only, Otherwise Use `getPage`. */
  getAllWithFilter(...filterObjs: object[]): Observable<Map<number, T>> {
    // TODO hit api everytime for this
    this.ensureCache();
    return this.entities.pipe(
      filter(entity => {
        for (const filterObj of filterObjs)
          for (const [key, value] of Object.entries(filterObj))
            if (entity[key] !== value) return false;

        return true;
      })
    );
  }

  /**
   * check if the entity of this id exists
   * @param id The entityId
   * @returns exist or not
   */
  async checkExisting(id: number | string) {
    const result = await this.warpService.get(id, undefined, this.entityTypeId).toPromise();
    if (result && result.entityTypeId === this.entityTypeId) return true;
    else return false;
  }
  //#endregion

  //#region Tokens
  private choosePageSize(entityFilter: EntityFilter, pageSize?: number) {
    if (pageSize > 0)
      return pageSize;
    else if (entityFilter.pageSize > 0)
      return entityFilter.pageSize;
    else
      return this.meta.pageSize;
  }


  /**
   * Get the tracking token for a specific filter, or create a new one.
   * @returns a new or existing CachedPageToken
   */
  private getQueryToken(entityFilter: EntityFilter, pageSize?: number): CachedQueryToken<T> {
    pageSize = this.choosePageSize(entityFilter, pageSize);

    const id = `${entityFilter.identifier}-${pageSize}`; // identifier does not include page information
    if (!this.cacheTokens.has(id))
      this.cacheTokens.set(id, new CachedQueryToken(this, entityFilter, pageSize));

    return this.cacheTokens.get(id);
  }

 /**
  * Get queryToken with the first page requested
  * @param entityFilter the filter describing the pageset
  */
  initQuery(entityFilter: EntityFilter = EntityFilter.None, overridePageSize: number = null) {
    return this.getPageToken(entityFilter, overridePageSize, 0).query;
  }

  /**
   * Get the tracking token for a specific filter, or create a new one.
   * @returns a new or existing CachedPageToken
   */
  private getPageToken(entityFilter: EntityFilter, pageSize?: number, page = 0): CachedPageToken<T> {
    // this is basically initToken, but only by an entityFilter
    return this.getQueryToken(entityFilter, pageSize).getPageToken(entityFilter.page);
  }


  /** Gets a token for the filter with the page loaded.
   * @deprecated Internal implementation detail, don't use this function outside of cache management
   * @param entityFilter filter information, make sure to keep track of page information, default is page 0 for all entities
   */
  initToken(tokenOrFilter: EntityFilter | CachedPageToken<T> = EntityFilter.None, overridePageSize?: number)
    : CachedPageToken<T> {
    // create a new token or grab an existing one
    let token: CachedPageToken<T> = null;
    this.log('Init token.', token, MessageService.VERBOSE);
    if (!(tokenOrFilter instanceof CachedPageToken)){
      token = this.getPageToken(tokenOrFilter, overridePageSize);
    }else
      token = tokenOrFilter;

    // if the token hasnt been loaded, load it
    if (!token.ready) {
      token.status = 'loading';
      const appendForToken = this.appendPageToCache(token);

      // when the token is a idsOnly one (isDirectAccess), we can skip the initial query for ids, and just use the ids
      if (token.filter.isDirectAccess) {
        // We already have the Ids, so get the data directly.

        this.log('Getting data for token - direct access via ids.', token, MessageService.VERBOSE);
        const ids = token.filter.filteredIds;
        const fakePage = this.getFakePageForIds(ids);

        setTimeout(() => appendForToken(fakePage));
      } else {
        // The token is using a filter, so we need to get the ids, then get the data.
        this.log('Getting data for token.', token, MessageService.VERBOSE);

        // we can get the ids using PostPage, or a customEndpoint
        let getPage: Observable<IEntityPage>;

        if (token.filter.isApiRoute) {
          let customEndpoint: Observable<number[]>;

          if (token.filter.apiMethod === 'POST')
            customEndpoint = this.warpService._post<number[]>(token.filter.apiRoute, token.filter.apiBody);
          else
            customEndpoint = this.warpService._get<number[]>(token.filter.apiRoute);

          getPage = customEndpoint
            .pipe(map(this.getFakePageForIds));
        } else {
          getPage = this.warpService
            .getPage(this.entityTypeId, token.page, overridePageSize || token.pageSize || this.meta.pageSize, token.filter, true) // TODO recursively retrieve all
        }

        getPage.toPromise()
          .then(appendForToken)
          .catch( e => this.log(`Get data for token Error! <${token.filter?.apiRoute || this.entityTypeId}>`, token, e, MessageService.ERROR));
      }

    } else {
      this.log(`Not Getting Data for Token, because it is already ${token.status}`, token, MessageService.VERBOSE);
    }

    return token;
  }

  /** Expire all tokens. To force new API calls. Any subscriptions to old tokens will be completed observables. */
  clearTokens(updates?: SignalRHandlerObject[]) {
    this.log(`Expiring ${this.cacheTokens.size} tokens`, this.cacheTokens, MessageService.VERBOSE);
    const pagesToReload = Array.from(this.cacheTokens.values())
      .flatMap(q => q.clear(updates) )
      .filter(p => !!p);

    const pagesToReloadUnique = Array.from(new Set(pagesToReload));
    pagesToReloadUnique.forEach(p => this.initToken(p));
  }
  //#endregion

  //#region Add, Update, Delete
  update(...items: T[]): Observable<T> {
    const out = new Subject<T>();
    this.warpService
      .getEntityStructure(this.entityTypeId)
      .pipe(first())
      .toPromise()
      .then(type =>
        this.warpService
          .entitySyncUpdate(type, items)
          .toPromise())
      .then(syncResult => {
        syncResult.forEach(we => out.next(this.constructEntity(we)));
        out.complete();
      })
      .catch(this.errorHandler());

    // return the objects that were edited
    return out;
    // return merge(...items.map(i => this.get(i.entityId) ));
  }

  /**
   * Only update a single property of an entity
   * @param item entities to update
   * @param propertyName the property needed to update
   * @returns updated item
   */
  updateSinglePropertyOnly(item: WarpEntity, propertyName: string) {
    const itemUpdated = this.constructEntity(WarpEntity.emptyEntity(item.entityTypeId));
    itemUpdated.entityId = item.entityId;
    itemUpdated.properties[propertyName] = item.properties[propertyName];
    return this.update(itemUpdated);
  }


  /**
   * Create multiple entities
   * @param items a list of entities to create
   * @returns the entity Ids of the newly created entities, in the order they were created
   */
  create(...items: T[]): Observable<number> {
    const out = new Subject<number>();
    this.warpService
      .getEntityStructure(this.entityTypeId)
      .pipe(first())
      .toPromise()
      .then(type =>
        this.warpService
          .create(type, items)
          .toPromise()
          .then(all =>
            all.forEach(we =>
              out.next(we.entityId)
            )
          )
          .catch(this.errorHandler(out))
          .finally(() => out.complete())
      )
      .catch(err => out.error(err));
    // return the objects that were added
    return out;
  }

  delete(...items: Pick<WarpEntity, 'entityId'>[]): Promise<void> {
    const toSend = items.map(obj => ({ type: 'delete', entity: obj }));
    return this.warpService
      .delete(items)
      .catch(this.errorHandler());
  }

  destroy(...items: T[] | number[]): Promise<boolean> {
    const promise = this.warpService.destroy(...items);

    promise.then(success => {
      if (success) this.removeItemsFromCache(...items);
    });

    return promise;
  }
  //#endregion

  //#region Helpers
  /** Throw an error if this cache is not eagerly loaded, should be included in all load-all type operations. */
  protected assertEagerLoading() {
    if (this.meta.loadStyle !== 'eager')
      throw new Error('loadCache is only for eager-loaded caches, either commit to an eagerly loaded cache or use loadPage');
  }

  /** set cache status and log error */// tslint:disable-next-line: no-any
  protected errorHandler(out?: Subject<any>) {
    return (error) => {
      this.meta.status = 'error';
      this.log('Error', error, MessageService.ERROR);
      if (out) out.error(error);
    };
  }

  /** returns a pipe operation to convert an *array* to a map */
  protected mapToT() {
    return map((v: object[]) => v.map(el => this.constructEntity(el)));
  }

  protected constructEntity(obj: Object) {
    return new this.cast(obj);
  }

  //TODO: remove this when we have refactored all of the api .only type queries
  public AddAndCastEntity(obj: IGenericObject) {
    const entity = this.constructEntity(obj);

    if (entity.entityId > 0 && !this.entities.value.has(entity.entityId))
      this.entities.value.set(entity.entityId, entity);

    return entity
  }

  log(...args) {
    this.messageService.add(this.typeName,...args);
  }
  //#endregion
}
