import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class StatisticGroup extends WarpEntity {
  id: string | number;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.StatisticGroup;
  }

  static empty(): StatisticGroup {
    return new StatisticGroup(super.emptyEntity(EntityTypes.StatisticGroup));
  }

}
