import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { environment } from '@ripple/environment';
import { IGenFormlyOptions, CustomFieldInModule } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { AfterSaveEvent } from '@ripple/ui';
import { EntityTypes, TeamType } from '../../_core/models';

@Component({
  selector: 'sagesse-team-type-details',
  templateUrl: './team-type-details.component.html',
  styleUrls: ['./team-type-details.component.scss']
})
export class TeamTypeDetailsComponent extends BasePage implements OnInit {

  teamType: TeamType;
  teamTypeService: WarpEntityServiceCache<TeamType>;

  fieldOrders = [
    'name',
    'teamcontactroletype'
  ];

  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => !this.fieldOrders.includes(cfim.unchangeableName.toLowerCase()),
    styles: {
      name: 'p-sm-12 p-md-12 ui-fluid',
      teamcontactroletype: 'p-sm-12 p-md-12 ui-fluid',
    }
  };

  teamTemplatesTeamAzureAdObjectId = environment['teamTemplatesTeamAzureAdObjectId'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.teamTypeService = this.serviceFactory.get(EntityTypes.TeamType) as WarpEntityServiceCache<TeamType>;
    this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);
      if (isNaN(id)) {
        this.teamType = TeamType.empty();
        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.sub = this.teamTypeService.get(id).subscribe((teamType) => {
          this.teamType = teamType;
          this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
        });
      }
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') this.router.navigate([`../${e.entity.entityId}`], { relativeTo: this.route });
    else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = 'Template Deleted Successfully!';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

}
