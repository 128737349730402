import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';
import { StrategicOutcome } from './StrategicOutcome';

export class IndividualStatisitcReport extends WarpEntity {
  id: string | number;
  actionMenu: any;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.IndividualStatisitcReport;
  }

  static empty(): IndividualStatisitcReport {
    return new IndividualStatisitcReport(super.emptyEntity(EntityTypes.IndividualStatisitcReport));
  }

}
