import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ripple-under-construction-blurb',
  templateUrl: './under-construction-blurb.component.html',
  styleUrls: ['./under-construction-blurb.component.css']
})
export class UnderConstructionBlurbComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
