import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { AfterSaveEvent, BreadcrumbItem } from '@ripple/ui';
import { EntityTypes, Organization } from '../../_core/models';
import { environment } from '@ripple/environment';
import { first } from 'rxjs/operators';
import { OrganizationService } from '../../_core/services';


@Component({
  selector: 'sagesse-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent extends BasePage implements OnInit {

  @ViewChild('contactList') contactList;
  @ViewChild('officeList') officeList;
  @ViewChild('teamList') teamList;

  organization: Organization;
  organizationService: OrganizationService;
  organizationId = -1;
  organizationWithAuxInfo: Organization;

  breadcrumbs:BreadcrumbItem[] = [
    {
      name: "Organizations",
    }]

  // formly form
  fieldOrders = [
    'logo',
    'name',
    'acronyms',
    'phonenumber'
  ];
  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => !this.fieldOrders.includes(cfim.unchangeableName.toLowerCase()),
    styles: {
      logo: 'p-sm-12 p-md-12 ui-fluid',
      name: 'p-sm-12 p-md-12 ui-fluid',
      acronyms: 'p-sm-12 p-md-12 ui-fluid',
      phonenumber: 'p-sm-12 p-md-12 ui-fluid',
    },
    groups: [
      {
        groupName: 'tab',
        groupType: 'tabs',
        groupClasses: 'ui-fluid d-none',
        groupFields: [],
        groupWrapper: [],
        groupWrapperData: {},
        groups: [
          {
            groupName: 'GeneralInfo',
            groupClasses: 'p-grid ui-fluid mt-1',
            groupFields: ['logo', 'name', 'acronyms', 'phonenumber'],
            groupWrapper: [],
            groupWrapperData: {
              label: 'General Info',
              tabId: 'generalInfo',
              headerIcon: 'pi pi-info-circle'
            },
          },
          {
            groupName: 'Contacts',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Contacts',
              tabId: 'contacts',
              headerIcon: 'pi pi-user'
            },
          },
          {
            groupName: 'Teams',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Teams',
              tabId: 'teams',
              headerIcon: 'pi pi-users'
            },
          },
          {
            groupName: 'Offices',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Offices',
              tabId: 'offices',
              headerIcon: 'pi pi-home'
            },
          },
          {
            groupName: 'Tags',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Tags',
              tabId: 'tags',
              headerIcon: 'pi pi-tags'
            },
          },
          {
            groupName: 'Communities',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Communities',
              tabId: 'communities',
              headerIcon: 'pi pi-home'
            },
          },
          {
            groupName: 'Folders/Files',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Folders/Files',
              tabId: 'folders/files',
              headerIcon: 'pi pi-folder'
            },
          },
          {
            groupName: 'Notes',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Notes',
              tabId: 'notes',
              headerIcon: 'pi pi-list'
            },
          },
        ]
      }
    ]
  };

  organizationTeamAzureAdObjectId = environment['organizationsTeamAzureAdObjectId'];
  organizationsTeamChannelAzureAdObjectId = environment['organizationsTeamChannelAzureAdObjectId'];

  tabIndex: number = 0;

  noteScrollTo: number = -1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.organizationService = this.serviceFactory.get(EntityTypes.Organization);
    this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);

      if (isNaN(id)) {
        this.organization = Organization.empty();
        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.organizationId = id;
        this.sub = this.organizationService.get(id).subscribe((organization) => {
          this.organization = organization;
          this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));

          this.organizationService.getOrganizationAuxInfo(id).pipe(first()).toPromise().then((orgAuxInfo) => {
            // Object.assign will add auxInfo to organization in place
            Object.assign(this.organization, orgAuxInfo);
          });

          this.breadcrumbs = [
            { name: "Organizations", url: "/organization" },
            { name: this.organization?.name }
          ];
        });
      }
      
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') {
      this.router.navigate([`../${e.entity.entityId}`], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
    }
    if (e.action === 'delete') {
      const queryParams = Object.assign({}, this.route.snapshot.queryParams);
      queryParams['msg'] = 'Organization Deleted Successfully!';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

  goToWebsite() {
    window.open('http://' + this.organization.website, '_blank');
  }

  ifTabShow(tab) {
    if (this.organization && this.organization.entityId > 0) {
      return true;
    } else {
      if (tab.fieldGroupClassName && tab.fieldGroupClassName.includes('hidden-new-entity')) {
        return false;
      }
      return true;
    }
  }

  noteClick(event) {
    console.log(event, this.tabIndex);
    this.tabIndex = 7;
    this.noteScrollTo = event.entityId;
  }

}
