import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'ccasa-formly-wrapper-panel',
  template: `
    <div class="{{to.wrapperData.divClass}}">
      <div class="{{to.wrapperData.headingStyleClass}}">{{to.wrapperData.label}}</div>
      <ng-container #fieldComponent></ng-container>
    </div>
  `,
})
export class GenericWrapperComponent extends FieldWrapper {
}
