import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InternalCookieService, WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { first } from 'rxjs/operators';
import { ClaresLaw, ClaresLawStatus, EntityTypes, Organization, PrimaryContactRoles } from '../_core/models';
import * as Quill from 'quill';
import quillTable from 'quill-table';
import { ClaresLawService, FormService, OrganizationService } from '../_core/services';
import { environment } from '@ripple/environment';
import { ConfirmationService } from 'primeng/api';
import * as moment from 'moment';

@Component({
  selector: 'sagesse-clares-law-referral-bar',
  templateUrl: './clares-law-referral-bar.component.html',
  styleUrls: ['./clares-law-referral-bar.component.scss']
})
export class ClaresLawReferralBarComponent implements OnInit {

  organizationService: OrganizationService;

  // referral dialog
  referralDialogShow = false;
  loading = false;
  referral: Organization;
  claresLaw: ClaresLaw;
  resultForm: any;
  email: string[];
  subject = 'Clare\'s Law Request';
  body: string;
  infoTable = '';
  infoLink = '';
  editorModule: any;
  intakeName: string;
  sending = false;
  token = '';
  intakeManagers: any[] = [];
  primaryId = -1;

  // sagesse special
  sagesseOrgId = environment['sagesseOrgId'].toString();
  tryValidate = false;

  get sagesseValid() {
    return this.cookieService.getCookie('referralID') !== this.sagesseOrgId ||
      (this.claresLaw.properties.whysagesse === '2538' ||
      this.claresLaw.properties.whysagesse === '2539' && this.claresLaw.properties.whynotnotes);
  }

  claresLawService: ClaresLawService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService,
    public cookieService: InternalCookieService,
    private formService: FormService,
    private confirmationService: ConfirmationService,
  ) {
    this.organizationService = factory.get(EntityTypes.Organization);
    this.claresLawService = factory.get(EntityTypes.ClaresLaw) as ClaresLawService;
  }

  ngOnInit(): void {
  }

  async refer() {
    Quill.register(quillTable.TableCell);
    Quill.register(quillTable.TableRow);
    Quill.register(quillTable.Table);
    Quill.register(quillTable.Contain);
    Quill.register('modules/table', quillTable.TableModule);
    this.editorModule = {
      table: true,
    };
    const referralID = parseInt(this.cookieService.getCookie('referralID'), 10);
    const clareLawID = parseInt(this.cookieService.getCookie('clareLawID'), 10);
    if (!isNaN(referralID) && !isNaN(clareLawID)) {
      this.referralDialogShow = true;
      this.loading = true;
      this.referral = await this.organizationService.get(referralID).pipe(first()).toPromise(); // referral is the organization
      
      const orgAuxInfo = await this.organizationService.getOrganizationAuxInfo(referralID).pipe(first()).toPromise();
      Object.assign(this.referral, orgAuxInfo);
      
      this.claresLaw = await this.claresLawService.get(clareLawID).pipe(first()).toPromise();

      // get the full details of the organization
      const primaryIntake = this.referral.primaryContactRoles.find(role => role.id === PrimaryContactRoles.INTAKEMANAGER);
      if(primaryIntake.contacts && primaryIntake.contacts.length > 0) {
        this.primaryId = primaryIntake.contacts[0].id;
      }
      this.intakeManagers = this.referral.intakeManagers;
      if (primaryIntake) this.intakeName = primaryIntake.name;
      if (this.intakeManagers && this.intakeManagers.length === 1) {
        this.email = this.intakeManagers
          .map(im => im.email);
      }
      this.subject = 'New Clare\'s Law Request: ' + this.claresLaw.referencenumber;
      await this.generateEmailBody();
      console.log('clares Law: ', this.claresLaw);
      this.loading = false;
    }

  }

  async generateEmailBody() {
    const expireTime = 2 * 30 * 24 * 60;// 2 months
    const expireTimeTenDay = 10 * 24 * 60;// 10 days
    this.infoLink = '';
    let tenDaytokenLink = '';
    let formLink = '';
    if (this.claresLaw.forms && this.claresLaw.forms.length > 0) {
      this.resultForm = this.claresLaw.forms[0];
      this.token = await this.formService.getOneTimeToken(expireTime,
        this.resultForm.entityId, this.resultForm.linkedform.id, []).toPromise();
      const otherEntityIds = [this.claresLaw.entityId];
      if (this.claresLaw.clareslawsensitiveinformation) {
        otherEntityIds.push(this.claresLaw.clareslawsensitiveinformation.id);
      }
      tenDaytokenLink = await this.formService.getOneTimeToken(expireTimeTenDay,
        -1, -1, otherEntityIds, false).toPromise();
      this.infoLink = `${environment.host}/claresLawInfo/${this.token}/${this.claresLaw.entityId}`;
      tenDaytokenLink = `${environment.host}/claresLawInfo/${tenDaytokenLink}/${this.claresLaw.entityId}/${this.resultForm.entityId}`;
      formLink = `${environment.host}/form/${this.token}/${this.resultForm.entityId}`;
    }

    this.infoTable = this.claresLaw.infoTable();
    this.body =
      `
      <p>Dear ${this.intakeName},</p>
      <p> </p>
      <p>Please click here to access information for a Clare’s Law referral: <a href="${tenDaytokenLink}">${tenDaytokenLink}</a>. This link will expire after ten days.</p>
      <p> </p>
      <p>Based on demographic information, location, and our assessment, your services are the best fit for this application.
        We require you to initiate contact within three business days (72 hours).
        After you have contacted the client, please fill out the appropriate Referral Completion Form (<a href="${formLink}?attempt=1">Attempt 1</a>, <a href="${formLink}?attempt=2">Attempt 2</a>, <a href="${formLink}?attempt=3">Attempt 3</a>).
        If you have not filled out the Referral Completion Form within three business days, you will receive a follow-up email asking you to do so.
        Once you have filled out the Referral Completion Form, the Clare’s Law Referral process is complete.</p>
      <p> </p>
      <p>Please be advised that all 3 attempts need to be completed within 10 days from when this referral was received</p>
      <p> </p>
      <p>If you feel you are not the best support for this client, have any questions, or have any issues with the link above, email programs@sagesse.org or call 403-234-7337 or 587-801-7337, and choose extension 100.</p>
      <p> </p>
      <p>Thank you,</p>
      <p> </p>
      <p>Sagesse</p>
      `;
  }

  send() {
    this.tryValidate = true;
    if (!this.sagesseValid) return;
    this.sending = true;

    this.claresLaw
      .cfcProperty('clareslawstatus', ClaresLawStatus.REFERRED)
      .linkedProperty('organization', this.referral)
      .property('datereferred', moment().format('YYYY-MM-DD'))
      .property('handofftoken', this.token);
      // .cfcProperty('viewed', 334);

      if (!this.claresLaw.datefirstreferred) {
        this.claresLaw.property('datefirstreferred', moment().format('YYYY-MM-DD'));
      }

    console.log('send referral email for:', this.claresLaw);

    this.claresLawService.update(this.claresLaw).subscribe(entity => {
      this.claresLawService.updateClaresLawInfoViewed(this.claresLaw.entityId, 334).toPromise().then(cl => {
        console.log('update clares law entity as viewed', cl);
        this.claresLaw = cl;
        this.claresLawService.sendReferralEmail(this.subject, this.body, this.email,
          this.resultForm.entityId, this.intakeName, this.claresLaw.referencenumber, this.infoTable, this.infoLink, this.claresLaw.id).subscribe(result => {
            this.sending = false;
            this.exitReferralProcess();
        });
      });
    });
  }

  cancel() {
    this.referralDialogShow = false;
  }

  exit() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to exit referral process?',
      header: 'Confirmation',
      icon: 'pi pi-refresh',
      accept: () => {
        this.exitReferralProcess();
      },
      reject: () => {
      }
    });
  }

  exitReferralProcess() {
    this.cookieService.dropCookie('referenceNumber');
    this.cookieService.dropCookie('clareLawID');
    this.cookieService.dropCookie('referralID');
    this.cookieService.dropCookie('referralName');
  }

  edit = () => this.router.navigate([`/claresLaw/${this.cookieService.getCookie('clareLawID')}`]);

}
