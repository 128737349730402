<span class="button-list" *ngFor="let button of _buttons || []">
    <p-splitButton
      *ngIf="button.items && button.items.length > 0"
      [appendTo]="appendTo"
      [label]="button.label"
      [model]="button.items"
      [icon]="button.icon"
      [disabled]="button.disabled"
      (onClick)="button.command($event)">
    </p-splitButton>
    <button pButton
      *ngIf="!button.items || button.items.length === 0"
      [label]="button.label"
      [icon]="button.icon"
      [disabled]="button.disabled"
      (click)="button.command($event)">
    </button>
  </span>
