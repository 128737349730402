import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';
import { MessageService } from '@ripple/services';
import { IGenericObject } from '@ripple/models';

interface Preset {
  onLabel: string;
  offLabel: string;
  onIcon: string;
  offIcon: string;
  value: any;
}

@Component({
  selector: 'ripple-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// @dynamic
export class FormInputComponent extends RippleFieldGenericComponent implements OnInit {
  protected static availableFields = ['question-input-number-field', 'question-input-maxLength-field'];

  maxDate: Date = new Date(9999, 11, 31, 11, 59, 59, 9999);

  presets: Preset[] = [];

  get mask() {
    return this.to.moduleSettings && this.to.moduleSettings['mask'];
  }

  get leftContent() {
    return this.to.moduleSettings && this.to.moduleSettings['leftcontent'];
  }

  get rightContent() {
    return this.to.moduleSettings && this.to.moduleSettings['rightcontent'];
  }

  get customDateConstraint() {
    if (!this.to.moduleSettings || !this.to.moduleSettings['customdateconstraint'])
      return {minDate: null, maxDate: this.maxDate};
    const constraint = this.to.moduleSettings['customdateconstraint'];
    switch (constraint) {
      case 'noyear':
        const currYear = new Date().getFullYear();
        return {
          minDate: new Date(currYear, 0, 1),
          maxDate: new Date(currYear, 11, 31)
        };
      default:
        return {minDate: null, maxDate: this.maxDate};
    }
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    msg: MessageService
  ) {
    super(msg);
  }

  ngOnInit(): void {
    if (this.viewOnly)
      this.formControl.disable({ emitEvent: false });

    try {
      const presetString: string | IGenericObject[] = this.to.presets || this.to?.moduleSettings?.presets || "[]";
      const presetInput = typeof presetString === 'string' ? JSON.parse(presetString) : presetString;

      this.presets = presetInput.map( p => {
        const preset: Omit<Preset, 'selected'> = {
          onLabel: `${p.onLabel || p.label || p}`,
          offLabel: `${p.offLabel || p.label || p}`,
          onIcon: p.onIcon || null,
          offIcon: p.offIcon || null,
          value: p.value || p,
        };

        return preset;
      });

    } catch (e) {
      this.presets = [];
    }

  }

  change(value: string | number | Date) {
    if (this.to.change)
      this.to.change(this.field, value);
  }

  blur() {
    this.log('mark for check after blur in general input');
    this.formControl.setValue(this.formControl.value);
    this.cdRef.markForCheck();
  }
}
