import { OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
/**
 * Helpers to manage subscriptions
 * all subscriptions must be assigned to the `this.sub` variable and if implements OnDestroy must call `this.clearSubs()`
 */
@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class SubscriptionContainer implements OnDestroy {
  private subscriptions: Subscription[] = [];
  set subs(sub: Subscription[]) { this.addSubscription(...sub); }
  set sub(sub: Subscription) { this.addSubscription(sub); }
  set nextSub(sub: Subscription) { this.addSubscription(sub); }

  addSubscription(...sub: Subscription[]) {
    const subs = sub.filter(s => !!s);
    this.subscriptions.push(...subs);
  }

  clearSubs() {
    this.subscriptions.forEach(sub => {
      if (sub && !sub.closed)
        sub.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.clearSubs();
  }
}
