import { Component, OnInit } from '@angular/core';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { AuthService, InternalCookieService, WarpEntityLogService } from '@ripple/services';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePage } from '@ripple/core';
import { environment } from '../../environments/environment';
import { LayoutSettingsService } from '@ripple/services';

@Component({
  selector: 'sagesse-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BasePage implements OnInit {

  loginSuccess = false;
  showErrorMessage = false;

  errorMessage = '';
  wrongAccount = false;
  
  constructor(
    private broadcastService: BroadcastService,
    private msalService: MsalService,
    private authService: AuthService,
    private route: ActivatedRoute,
    protected router: Router,
    private logService: WarpEntityLogService,
    private layoutSettingsService: LayoutSettingsService,
    private cookieService: InternalCookieService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    // menubar settings
    const layoutSettings = this.layoutSettingsService.getSettings();
    layoutSettings.showMenuBarNotifications = false;
    layoutSettings.showMenuBarPrimaryMenu = false;
    layoutSettings.showMenuBarProfileHelpMenu = false;
    this.layoutSettingsService.setSettings(layoutSettings);

    this.msalService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
      console.log('Login Redirect Success: ', response);
      this.loginSuccess = true;
      // silently get access token
      this.msalService.acquireTokenSilent(environment['accessTokenRequest'])
        .then(accessTokenResponse => {
          console.log('silently get access token after login', accessTokenResponse);
          this.authService.validateLoginWithAzureAdAccount(accessTokenResponse).then(result => {
            console.log('successfully login in ripple', result);
            this.router.navigate(['/']);
          }).catch(e => {
            console.log('user is not in ad', e);
            this.loginSuccess = false;
            this.errorMessage = `Your account is not in the system, please first sign out and then try another one. `;
            this.showErrorMessage = true;
            this.wrongAccount = true;
          });;
        })
        .catch(e => {
          console.log('acquireTokenSilent error', e);
          this.loginSuccess = false;
          this.errorMessage = 'An error has ocurred. Please try to login again.';
          this.showErrorMessage = true;
        });
    });

  }

  login() {
    this.msalService.loginRedirect();
  }

  logout() {
    this.msalService.logout();
  }

  ngOnDestroy(): void {
    this.layoutSettingsService.revertSettings();
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

}
