import { WarpEntity } from '@ripple/models';
import { Contact } from './contact';
import { EntityTypes } from './entity-types';

export class OrganizationContact extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.OrganizationContact;
        if (this.contactEntity) {
            this.contactEntity = new Contact(this.contactEntity);
        }
        if (this.properties.other) {
            this.otherChecked = true;
        } else {
            this.otherChecked = false;
        }
    }

    generateRoleString() {
        const roles = this.properties.contactroles;
        const primaryRoleIds = this.properties.primarycontactroles ?
            this.properties.primarycontactroles.map(c => c.id) : [];
        if (!roles && !this.properties.other) return '';
        let retVal = '';
        if (roles) {
            for (const role of roles) {
                retVal += role.name;
                if (primaryRoleIds.includes(role.id)) retVal += '(primary)';
                retVal += ', ';
              }
        }
        if (this.properties.other) {
            retVal += `other(${this.properties.other}), `;
        }
        return retVal.substring(0, retVal.length - 2);
    }

    get ifEmailOverride() {
        return this.email && this.email !== this.contactEntity.email;
    }

    getEmail() {
        return this.email || this.contactEntity.email;
    }

    getPhoneNumber() {
        return this.phonenumber || this.contactEntity.phonenumber;
    }

    static empty(): OrganizationContact {
        return new OrganizationContact(super.emptyEntity(EntityTypes.OrganizationContact));
    }

}