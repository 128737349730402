import { WarpEntity } from '@ripple/models';
import { Contact, OrganizationContact } from './index';
import { EntityTypes } from './entity-types';

export class TeamContact extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.TeamContact;
        if (this.contactEntity) {
            this.contactEntity = new Contact(this.contactEntity);
        }
        if (this.organizationContactEntity) {
            this.organizationContactEntity = new OrganizationContact(this.organizationContactEntity);
        }
    }

    static empty(): TeamContact {
        return new TeamContact(super.emptyEntity(EntityTypes.TeamContact));
    }

    get personChar () {
        // if (this.contactEntity)
        //     return this.contactEntity.properties['first name'] ?
        //         this.contactEntity.properties['first name'].charAt(0) : '?';
        // else if (this.userEntity)
        //     return this.userEntity.properties['first name'] ?
        //         this.userEntity.properties['first name'].charAt(0) : '?';
        // else if (this.organizationContactEntity)
        //     return this.organizationContactEntity.contactEntity.properties['first name'] ?
        //         this.organizationContactEntity.contactEntity.properties['first name'].charAt(0) : '?';
        return this.firstName ? this.firstName.charAt(0) : '?';
    }

    get displayName() {
        return this.firstName + ' ' + this.lastName; 
    }

    get firstName() {
        if (this.contactEntity)
            return this.contactEntity.properties['first name'];
        else if (this.userEntity)
            return this.userEntity.properties['first name'];
        else if (this.organizationContactEntity)
            return this.organizationContactEntity.contactEntity.properties['first name'];
    }

    get lastName() {
        if (this.contactEntity)
            return this.contactEntity.properties['last name'];
        else if (this.userEntity)
            return this.userEntity.properties['last name'];
        else if (this.organizationContactEntity)
            return this.organizationContactEntity.contactEntity.properties['last name'];
    }

    get email() {
        if (this.contactEntity)
            return this.contactEntity.properties['email'];
        else if (this.userEntity)
            return this.userEntity.properties['email'];
        else if (this.organizationContactEntity)
            return this.organizationContactEntity.properties['email'] ?
                this.organizationContactEntity.properties['email'] :
                this.organizationContactEntity.contactEntity.properties['email'];
    }

    get phoneNumber() {
        if (this.contactEntity)
            return this.contactEntity.properties['phonenumber'];
        else if (this.userEntity)
            return this.userEntity.properties['phonenumber'];
        else if (this.organizationContactEntity)
            return this.organizationContactEntity.properties['phonenumber'] ?
                this.organizationContactEntity.properties['phonenumber'] :
                this.organizationContactEntity.contactEntity.properties['phonenumber'];
    }

}