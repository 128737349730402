import { Injectable } from '@angular/core';
import { AuthService, InternalCookieService, MessageService, RippleApiService } from '@ripple/services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GuestPortalService extends RippleApiService {
  private restURL = '/api/sagesse';

  constructor(
    http: HttpClient,
    messageService: MessageService,
    authService: AuthService,
    private cookieService: InternalCookieService
  ) {
    super(http, messageService, authService);
  }

  public getUserJointTeams(azureAdObjectId: string): Observable<any> {
    return this._get(this.restURL.concat('/userJoinedTeams/', azureAdObjectId));
  }
}
