import { Injectable } from '@angular/core';
import { environment } from '@ripple/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MessageService } from './message.service';
import { MsalService } from '@azure/msal-angular';
import { AuthResponse } from 'msal';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private msalService: MsalService
  ) { }

  async asyncAssertValidToken(token: string) {
    const out = new Subject<boolean>();
    this.http
      .get(`${environment.restEndpointUrl}/api/rippleuser/${token}/`)
      .subscribe(
        (user) => {
          out.next(true);
          out.complete();
        },
        (error: HttpErrorResponse) => {
          if (error.status === 401) {
            out.next(false);
            out.complete();
            this.messageService.add('Auth', 'Token Expired');
          } else {
            out.next(true);
            out.complete();
          }
          if (token.length >= 2000) {
            this.messageService.add(
              'Auth Load User Fail',
              'The server may have url maximum length limit. Go to Registry and update value of UrlSegmentMaxLength.',
              'Path in Registry: Computer\/HKEY_LOCAL_MACHINE\/SYSTEM\/CurrentControlSet\/Services\/HTTP\/Parameters',
              'UrlSegmentMaxLength: 32766 (decimal)',
              'reference: https://stackoverflow.com/questions/29519579/web-api-max-parameter-length',
              error);
          }
        }
      );
    return out.toPromise();
  }

  asyncAssertValidAzureAdToken(token: string) {
    const out = new Subject<boolean | AuthResponse>();
    this.msalService.acquireTokenSilent(environment['accessTokenRequest'])
      .then(accessTokenResponse => {
        this.messageService.add('Auth', 'silently get azure ad token', accessTokenResponse);
        if(accessTokenResponse.accessToken !== token) {
          // update
          this.messageService.add('Auth', 'Azure Ad Token updated');
          out.next(accessTokenResponse);
        } else {
          out.next(true);
        }
        out.complete();
      })
      .catch(error => {
        out.next(false);
        out.complete();
        this.messageService.add('Auth', 'Azure Ad Token Expired');
      });
    return out.toPromise();
  }
}
