import { Component, OnInit } from '@angular/core';
import { SectionWrapper } from '../section-wrapper';
import type { FieldTypeName } from '../../../service-resources/question-type-names';

@Component({
  selector: 'ripple-form-wrapper-panel',
  template: `
    <ng-template #options>
      <div class="fb-panel-controls {{customJson}}">
        <button pButton
          *ngIf="to.isSection"
          type="button"
          icon="pi pi-{{to.isHidden ? 'eye-slash' : 'eye'}}"
          (click)="onHideClick($event)"
        ></button>

        <p-splitButton
          [model]="to.items"
          icon="pi pi-cog"
          (onClick)="onSaveClick($event)"
          appendTo="body"
        ></p-splitButton>
      </div>
    </ng-template>

    <p-panel style="{{to.style}}" styleClass="form-wrapper-panel">
      <p-header>
        <div class="ui-panel-title fb-panel-header">
          <span *ngIf="!to.hideTitle" class="ui-panel-title" style="{{to.headerStyle}}">{{to.title}}</span>
          <ng-container *ngIf="to.hideOptions else options"></ng-container>
        </div>
      </p-header>
      <ng-container #fieldComponent></ng-container>
    </p-panel>
  `,
  styleUrls: ['./form-wrapper-panel.component.scss']
})
export class FormWrapperPanelComponent extends SectionWrapper implements OnInit {
  protected static β_listType: 'black' = 'black';
  protected static β_fieldList: FieldTypeName[] = []; // allow all

  get customJson() {
    return this.to.containsCustomJson ? 'custom-json' : '';
  }

  onSaveClick($event) {
    if (this.to.onSaveClick) this.to.onSaveClick($event);
  }
  onHideClick($event) {
    if (this.to.onHideClick) this.to.isHidden = this.to.onHideClick($event);
  }

  ngOnInit(): void { }
}
