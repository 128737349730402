import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class GraphApiService {

  // to use this service properly (permission), you need to import MsalModule in app.module.ts of your subscriber Angular project like this:
  /*
  MsalModule.forRoot(
    {
      auth: {
        clientId: environment.azureAdClientId,
        authority: environment.azureAdAuthority,
        redirectUri: environment.azureLoginRedirect,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true, // set to true for IE 11
      },
    },
    {
      popUp: false,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
      ],
      unprotectedResources: [],
      protectedResourceMap: [
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        ['https://graph.microsoft.com/v1.0/groups', ['files.readwrite']],
        ['https://graph.microsoft.com/v1.0/drives', ['files.readwrite']],
        ['https://graph.microsoft.com/v1.0/teams', ['files.readwrite']]
      ],
      extraQueryParameters: {}
    }
  ),
  */

  // and add MsalInterceptor to provider of app.module.ts like this:
  /*
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  }
  */
  constructor(
    private http: HttpClient,
    private sanitizer : DomSanitizer
  ) {
  }

  getTeam(teamId) {
    return this.http.get(`https://graph.microsoft.com/v1.0/teams/${teamId}`);
  }

  getChannel(teamId, channelId) {
    return this.http.get(`https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}`);
  }

  async getAvatarFromAzureAd() {
    const result = await this.http.get('https://graph.microsoft.com/v1.0/me/photo/$value', { responseType: 'blob'}).toPromise();
    return this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(result)) as string;
  }

  getDriveItem(driveId, itemId = null) {
    return this.http.get(`https://graph.microsoft.com/v1.0/drives/${driveId}/${ itemId ? `items/${itemId}` : 'root'}`);
  }

  getChildrenOfDriveItem(driveId, itemId = null) {
    return this.http.get(`https://graph.microsoft.com/v1.0/drives/${driveId}/${ itemId ? `items/${itemId}` : 'root'}/children?$expand=thumbnails`);
  }

  getFilesFolderOfChannel(teamId, channelId) {
    return this.http.get(`https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/filesFolder`);
  }

  getGroupDrive(groupId) {
    return this.http.get(`https://graph.microsoft.com/v1.0/groups/${groupId}/drive`);
  }
  
  uploadFile(file, driveId, itemId = null) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const body = reader.result;
        const headers = new HttpHeaders({
          'Content-Type':  'text/plain'
        });
        this.http.put(
          `https://graph.microsoft.com/v1.0/drives/${driveId}/${ itemId ? `items/${itemId}` : 'root'}:/${file.name}:/content`,
          body,
          {
            headers
          }).subscribe(result => {
            resolve(result);
          });
      };
    });
    
  }

  addFolder(folderName, driveId, itemId = null) {
    return this.http.post(
      `https://graph.microsoft.com/v1.0/drives/${driveId}/${ itemId ? `items/${itemId}` : 'root'}/children`,
      {
        name: folderName,
        folder: { },
        '@microsoft.graph.conflictBehavior': 'rename'
      });
  }

  deleteDriveItem(driveId, itemId) {
    return this.http.delete(`https://graph.microsoft.com/v1.0/drives/${driveId}/items/${itemId}`);
  }

  getUploadSession(file, driveId, itemId = null) {
    const endpoint = `https://graph.microsoft.com/v1.0/drives/${driveId}/${ itemId ? `items/${itemId}` : 'root'}:/${file.name}:/createUploadSession`;
    const body = {
      'item': {
        '@microsoft.graph.conflictBehavior': 'rename'
      }
    };
    //const options = new RequestOptions({ headers: headers });
    return this.http.post(endpoint, body);
  }

  async uploadChunks(file, uploadUrl) {
    // Variables for byte stream position
    let position = 0;
    let chunkLength = 320 * 1024;
    console.log('File size', file.size);
    let continueRead = true;
    while (continueRead) {
      let chunk;
      try {
        continueRead = true;
        //Try to read in the chunk
        try {
          let stopB = position + chunkLength;
          console.log('Sending Asynchronous request to read in chunk bytes from position ' + position + ' to end ' + stopB);
          chunk = await this.readFragmentAsync(file, position, stopB);
          if (chunk && chunk.byteLength > 0) {
            continueRead = true;
          } else {
            break;
          }
          console.log('Chunk bytes received', chunk.byteLength);
        } catch (e) {
          console.log('Bytes Received from readFragmentAsync', e);
          break;
        }
        // Try to upload the chunk.
        try {
          let res = await this.uploadChunk(chunk, uploadUrl, position, file.size);
          console.log('response from uploadChunk', res);
          if (res['error']) {
            console.log('error when uploading', res['error']);
            continueRead = false; 
          } else if (res['nextExpectedRanges']) {
            console.log('Continuing', res);
            position = Number(res['nextExpectedRanges'][0].split('-')[0]);
          }
          else {
            console.log('Reached last chunk of file', res);
            continueRead = false; 
          }
          console.log('Position is now ', position);

        } catch (e) {
          console.log('Error occured when calling uploadChunk', e);
          continueRead = false;
        }
      } catch (e) {
        continueRead = false;
      }
    }


  }

  // Reads in the chunck and returns a promise.
  readFragmentAsync(file, startB, stopB) {
    let frag = null;
    const reader = new FileReader();
    console.log('startBytes :' + startB + ' stopBytes :' + stopB);
    const blob = file.slice(startB, stopB);
    reader.readAsArrayBuffer(blob);
    return new Promise((resolve, reject) => {
      reader.onloadend = (event) => {
        if (reader.result instanceof ArrayBuffer) {
          console.log('onloadend called', reader.result.byteLength);
          if (reader.readyState == reader.DONE) {
            frag = reader.result;
            resolve(frag);
          }
        }
      };
    })
  }

  // Upload each chunk using PUT
  uploadChunk(chunk, uploadURL, position, totalLength) {//: Observable<any> {        
    let max = position + chunk.byteLength - 1;
    let contentLength = position + chunk.byteLength;

    console.log(contentLength, chunk.byteLength);

    return new Promise((resolve, reject) => {
      try {
        console.log('Just before making the PUT call to uploadUrl.');
        let crHeader = `bytes ${position}-${max}/${totalLength}`;
        console.log('Content-Range header being set is : ' + crHeader);

        const headers = new HttpHeaders({
          'Content-Type':  'application/octet-stream',
          'Content-Length':  contentLength,
          'Content-Range': crHeader
        });

        this.http.put(
          uploadURL,
          chunk,
          {
            headers
          }).subscribe((result: any) => {
            resolve(result);
          }, err => {
            console.error(err);
            reject(err);
          });

      } catch (e) {
        reject(e);
      }
    });
  }

  getGroupTeamSite(groupId) {
    return this.http.get(`https://graph.microsoft.com/v1.0/groups/${groupId}/sites/root`);
  }

  getDriveItemThumbnails(driveId, itemId) {
    return this.http.get(`https://graph.microsoft.com/v1.0/drives/${driveId}/items/${itemId}/thumbnails`);
  }

  getUserDrive(userId = null) {
    return this.http.get(`https://graph.microsoft.com/v1.0/${ userId ? `users/${userId}` : 'me'}/drive`);
  }

}
