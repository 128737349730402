import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { IGenericObject, EntityNote } from '@ripple/models';
import { environment } from '@ripple/environment';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subscription } from 'rxjs';
import { InputText } from 'primeng/inputtext';


@Component({
  selector: 'ripple-entity-note',
  templateUrl: './entity-note.component.html',
  styleUrls: ['./entity-note.component.scss']
})



export class EntityNoteComponent implements OnChanges {
  @Input() note: EntityNote;
  @Input() noteTypeFlagCustomClass: string;
  @Input() noteTypeDisplayName: string;
  @Input() view: 'small' | 'big' = 'small';
  @Input() maxLength = 1000;
  @Input() showFullNotes = true;
  @Input() allowPin = false;
  @Input() allowTag = false;
  @Output() noteClick = new EventEmitter<EntityNote>();
  @Output() save = new EventEmitter<EntityNote>();
  @Output() delete = new EventEmitter<EntityNote>();
  @Output() context = new EventEmitter<EntityNote>();

  @ViewChild('text') textInput: ElementRef;
  @ViewChild('noteTextArea') noteTextArea: ElementRef;
  @ViewChild('op') contextMenu: OverlayPanel;

  iconClass: string;
  title: string;
  subTitle: string;
  date: Date;
  numOutstandingItems: number;
  statusClass: IGenericObject;
  pillText;

  deleteMode = false;
  editMode = false;
  loading = false;
  origDesc: string;
  onClickSub: Subscription;

  tagEditMode = false;

  get _statusClass() {
    return this.statusClass || { };
  }

  get pill() {
    if (this.pillText !== undefined) return this.pillText;
    else return `${ this.numOutstandingItems } outstanding`;
  }

  get modifyPerm() { return this.note && this.note.canModify; }

  get ifPinned() {
    return this.note.pinned && this.note.pinned.id === 333;
  }

  get tags() {
    return this.note.properties.entitytag || [];
  }

  constructor(private confirmationService: ConfirmationService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.note && this.note) {
      this.iconClass = 'pi-tag';
      this.title     = this.note.description;
      this.subTitle  = `${this.noteTypeDisplayName || this.note.notetype.optionName} on ${this.note.date}`;
      this.pillText  = this.note.user.name;

      if (!this.showFullNotes) {
        const length = this.title.length;
        if (length > 60) {
          this.title = this.title.substring(0, 60) + '...';
        } 
      }
    }
  }

  onNoteClick() {
    this.noteClick.emit(this.note);
    if (this.showFullNotes)
      this.startEdit();
  }

  startEdit() {
    if (this.modifyPerm) {
      this.editMode = true;
      setTimeout(() => {
        if (this.textInput) {
          this.origDesc = this.textInput.nativeElement.value = this.note.description;
          this.textInput.nativeElement.focus();
        }

        if (this.noteTextArea) {
          this.origDesc = this.noteTextArea.nativeElement.value = this.note.description;
          this.noteTextArea.nativeElement.focus();
        }

      }, 10);
    }
  }

  cancelEdit() {
    if (!this.loading) {
      if (this.textInput) {
        this.textInput.nativeElement.value = this.note.description = this.origDesc;
      }

      if (this.noteTextArea) {
        this.noteTextArea.nativeElement.value = this.note.description = this.origDesc;
      }
    }
     
    this.editMode = false;
  }

  confirmDelete() {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${this.subTitle}?`,
      accept: () => {
        this.delete.emit(this.note);
        this.deleteMode = false;
      },
      reject: () => {
        this.deleteMode = false;
      }
    });
  }

  contextClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.contextMenu.toggle($event);
    this.context.emit(this.note);
  }

  closeContextMenu() { this.contextMenu.hide(); }

  _save(value: string) {
    this.loading = true;
    if (value !== this.origDesc) {
      this.origDesc = value;
      this.save.emit(this.note);
    }

    setTimeout(() => {
      this.updateInputTextAfterSave();
    }, 0);
  }

  updateInputTextAfterSave() {
    if (this.textInput)
      this.textInput.nativeElement.blur();
    if (this.noteTextArea) {
      this.noteTextArea.nativeElement.blur();
      this.cancelEdit();
    }
  }

  getNoteTypeStyling() {
    if (!this.note.notetype || environment.noteTypeStyling[this.note.notetype.optionName] === undefined) {
      return environment.noteTypeStyling['Default'];
    }
    return environment.noteTypeStyling[this.note.notetype.optionName];
  }

  pin() {
    this.loading = true;
    this.note.cfcProperty('pinned', this.ifPinned ? 334 : 333);
    this.save.emit(this.note);
    this.updateInputTextAfterSave();
  }

  saveTag() {
    this.loading = true;
    this.save.emit(this.note);
    this.cancelTagEdit();
  }

  cancelTagEdit() {
    if (!this.loading) {
      this.note.reset();
    }
     
    this.tagEditMode = false;
  }
}
