import { Component, Input, OnInit } from '@angular/core';
import { EntityTypes, StrategicOutcome, StrategicOutcomeSubCategory, StrategicOutcomeStatistic, Statistic } from '../../_core/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions, SubscriptionContainer } from '@ripple/models';
import { MenuItem, SortEvent } from 'primeng/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
@Component({
  selector: 'sagesse-strategic-outcome-statistic',
  templateUrl: './strategic-outcome-statistic.component.html',
  styleUrls: ['./strategic-outcome-statistic.component.scss']
})
export class StrategicOutcomeStatisticComponent extends SubscriptionContainer implements OnInit {
  @Input() subcategory: StrategicOutcome;
  strategicOutcomeService: WarpEntityServiceCache<StrategicOutcome>;
  strategicOutcomeSubCatService: WarpEntityServiceCache<StrategicOutcomeSubCategory>;
  strategicOutcomeStatisticService: WarpEntityServiceCache<StrategicOutcomeStatistic>;
  statService: WarpEntityServiceCache<Statistic>;
  stats: Array<StrategicOutcomeStatistic>;
  stat: StrategicOutcomeStatistic;
  toEdit: StrategicOutcomeStatistic;


  generatorOptions: IGenFormlyOptions;

  modalText: string = "";
  showModalFlag: boolean = false;
  loading: boolean = false;
  adding: boolean = false;
  availableStatistics: any[];
  action: string;
  nextSub: any;
  dragedColor: any;
  droped: any;

  rowIndex:any[];


  constructor(
    private factory: WarpEntityCacheFactoryService,
  ) {
    super();
    this.strategicOutcomeService = factory.get(EntityTypes.StrategicOutcome) as WarpEntityServiceCache<StrategicOutcome>;
    this.strategicOutcomeSubCatService = factory.get(EntityTypes.StrategicOutcomeSubCategory) as WarpEntityServiceCache<StrategicOutcomeSubCategory>;
    this.strategicOutcomeStatisticService = factory.get(EntityTypes.StrategicOutcomeStatistic) as WarpEntityServiceCache<StrategicOutcomeStatistic>;
    this.statService = factory.get(EntityTypes.Statistic) as WarpEntityServiceCache<Statistic>;

    this.nextSub = this.statService.initQuery().getPage(0).subscribe(resp => {
      this.availableStatistics = resp;
    })
  }


  ngOnInit(): void {

    this.generatorOptions = {
      hiddenCondition:
        (cfim: CustomFieldInModule) =>
          cfim.key.toLowerCase() === 'linkedstrategicoutcomesubcategory' ||
          cfim.key.toLowerCase() === 'orderindex' ||
      cfim.key.toLowerCase() === 'passedsanitycheck',
      styles: {
        name: 'p-sm-12 p-md-12 p-lg-12 ui-fluid',
      },
      //customFields: [this.availableStatisticsField]
    };

    this.subcategory.actionMenu = Array<MenuItem>();
    this.subcategory.actionMenu.push({
      label: 'Delete',
      icon: 'pi pi-times',
      command: () => this.delete(this.subcategory),
    });



    const filter = EntityFilter.Advanced({ LinkedStrategicOutcomeSubCategory_lid: this.subcategory.id });
    this.nextSub = this.strategicOutcomeStatisticService.initQuery(filter, 9999999).getPage(0).subscribe(resp => {
      this.stats = resp;
      this.stats.forEach(r => {
        r.actionMenu = new Array<MenuItem>();
        r.actionMenu.push({
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => this.delete(r),
        });
      });

      this.sortStats();
      console.log("Stats", resp);
    });
  }


  ngOnChanges() {
    console.log(this.stat);
  }

  showModal(action: string, toEditStat = null) {
    console.log(action);
    switch (action) {
      case 'add':
        this.toEdit = StrategicOutcomeStatistic.empty();
        console.log(this.toEdit)
        this.toEdit.linkedProperty("linkedstrategicoutcomesubcategory", this.subcategory);
        this.toEdit.properties.orderindex = this.stats.length + 1;
        this.modalText = "Add Statistic";
        break;
      case 'edit':
        this.toEdit = toEditStat;
        this.modalText = "Save Statistic";
        break;
      case 'editsubcat':
        this.toEdit = toEditStat;
        this.modalText = "Save Outcome Subcategory";
        this.action = 'editsubcat';
        break;
    }
    console.log(this.toEdit);
    this.showModalFlag = true;
  }

  addStat() {
    this.adding = true;
    if (this.action === 'editsubcat') {
      this.strategicOutcomeSubCatService.update(this.toEdit).toPromise().then((entId) => {
        this.adding = false;
        this.toEdit = null;
        this.showModalFlag = false;
      });
    }
    else {
      this.strategicOutcomeStatisticService.create(this.toEdit).toPromise().then((entId) => {
        this.adding = false;
        this.toEdit = null;
        this.showModalFlag = false;
      });
    }
  }

  delete(stat) {
    stat.deleting = true;
    this.strategicOutcomeStatisticService.delete(stat).then(resp => {
      console.log('Deleted stat', resp);
    });
  }

  reorder(e) {
    if (e.dropIndex === 0) {
      this.stats[e.dropIndex].properties.orderindex = (parseFloat(this.stats[0].properties.orderindex) - 1.00).toPrecision(4);
    }
    else if (e.dropIndex === this.stats.length-1) {
      this.stats[e.dropIndex].properties.orderindex = (parseFloat(this.stats[this.stats.length - 1].properties.orderindex) + 1.00).toPrecision(4);
    }
    else {
      this.stats[e.dropIndex].properties.orderindex = ((this.stats[e.dropIndex + 1].properties.orderindex + this.stats[e.dropIndex - 1].properties.orderindex) / 2.0000000).toPrecision(4);
    }

    this.nextSub = this.statService.update(this.stats[e.dropIndex]).subscribe(resp => {
      console.log('Index Updated', resp)
    });

    this.sortStats();
  }

  sortStats() {
    this.stats = this.stats.sort((a, b) => {
      if (a.properties.orderindex > b.properties.orderindex)
        return 1;
      else if (a.properties.orderindex < b.properties.orderindex)
        return -1;
      else
        return 0;
    });
  }

}


// availableStatisticsField: FormlyFieldConfig = {
//   key: 'linkedstatistic',
//   type: 'multiselect',
//   className: 'p-col-12 ui-fluid',
//   templateOptions: {
//     required: false,
//     options: [],
//     isMultiSelect: true,
//     label: 'Linked Statistics',
//     placeholder: 'Select Linked Stats',
//     multiple: true,
//     filterable: true
//   },
//   expressionProperties: {
//     'templateOptions.options': (model, formState, field?) => {
//       let options = [];

//       if (this.availableStatistics) {
//         options = this.availableStatistics.map(s => ({ label: s.properties.name, value: s.id }));
//         //console.log(model);
//       }

//       return options;
//     }
//   }
// };
