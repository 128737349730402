import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class ClaresLawSensitiveInformation extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.ClaresLawSesitiveInformation;
    }

    static empty(): ClaresLawSensitiveInformation {
        return new ClaresLawSensitiveInformation(super.emptyEntity(EntityTypes.ClaresLawSesitiveInformation));
    }

}