import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class Contact extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.Contact;

        this.resetEmail();
    }

    resetEmail() {
        if (this.properties.email && this.properties.email.includes(`entityid-${this.entityId}--`)) {
            this.properties.uniqueEmail = this.properties.email;
            this.properties.email = this.properties.email.split(`entityid-${this.entityId}--`)[1];
        }
    }

    static empty(): Contact {
        return new Contact(super.emptyEntity(EntityTypes.Contact));
    }

    get personChar () {
        return this.properties['first name'] ?
            this.properties['first name'].charAt(0) : '?';
    }

    get uniqueEmail () {
        return `entityid-${this.entityId}--${this.properties.email}`;
    }

}