import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { FormBuilderService } from '../../form-builder.service';

@Component({
  selector: 'form-builder-form-builder-report',
  templateUrl: './form-builder-report.component.html',
  styleUrls: ['./form-builder-report.component.scss']
})

export class FormBuilderReportComponent implements OnInit {

  @Input() formTypeId: number;
  @Input() overrideDataEndpoint: string;
  @Input() backgroundColor = '#A2CB47';

  sub: Subscription;

  reportData: any;

  loading = true;

  // chart
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    maintainAspectRatio: window.innerHeight > window.innerWidth,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  chartColors = [{
    backgroundColor: this.backgroundColor,
  }];
  public barChartType = 'bar';
  public barChartLegend = true;

  reportFilters = [];

  constructor(
    private formBuilderService: FormBuilderService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.loading = true;
    this.reportData = {};
    this.chartColors = [{
      backgroundColor: this.backgroundColor,
    }];
    this.formBuilderService.getEntityStructure(this.formTypeId).pipe(first()).toPromise().then(entityType => {
      console.log('cfim', entityType.customFieldsInModules);
      for (const cfim of entityType.customFieldsInModules) {
        if ((cfim.cf_dataField === 'customfieldchoiceid' || cfim.cf_dataField === 'linkedentityid') && cfim.cf_type !== 'Hidden') {
          this.reportData[cfim.key] = {
            label: cfim.label,
            options: [],
            total: 0
          };
        }
      }
      console.log('report data', this.reportData);
      if (this.sub) this.sub.unsubscribe();
      let ob = this.formBuilderService.getAll(this.formTypeId);
      if (this.overrideDataEndpoint) ob = this.formBuilderService.getData(this.overrideDataEndpoint);
      this.sub = ob.subscribe(entities => {
        for (const entity of entities) {
          for (const key of Object.keys(this.reportData)) {
            const property = entity.properties[key];
            if (property) {
              for (const data of property) {
                const choice = this.reportData[key].options.find(c => c.id === data.id);
                if (choice) {
                  choice.count += 1;
                } else {
                  const newChoice = Object.assign({}, data);
                  newChoice.count = 1;
                  this.reportData[key].options.push(newChoice);
                }
                this.reportData[key].total += 1;
              }
            }
          }
        }

        this.generateChartData();

        this.loading = false;

      });
    });
    
  }

  generateChartData() {
    for (const key of Object.keys(this.reportData)) {
      const data = this.reportData[key];
      data.barChartLabels = data.options.map(choice => choice.value || choice.optionName);
      data.barChartData  = [
        { data: data.options.map(choice => choice.count), label: 'Count' }
      ];
      console.log('chart result', data.barChartLabels, data.barChartData);
    }
  }

  addOrRemoveFilter(cfim, choice) {
    const index = this.ifInFilter(cfim, choice);
    if (index >= 0) {
      this.reportFilters.splice(index, 1);
    } else {
      this.reportFilters.push({
        cfim,
        choice
      });
    }
  }

  ifInFilter(cfim, choice) {
    return this.reportFilters.findIndex(f => f.cfim.id === cfim.id && f.choice.id === choice.id);
  }

}
