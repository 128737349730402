import { Injectable } from '@angular/core';
import { InternalCookieService, CookieType } from './cookie.service';
import { TokenService } from './token.service';
import { environment } from '@ripple/environment';
import { HttpClient } from '@angular/common/http';
import {
  AsyncSubject,
  BehaviorSubject,
  ReplaySubject,
  Observable
} from 'rxjs';
import { first, map, tap, skip, skipWhile, filter } from 'rxjs/operators';
import { WarpEntity, IWarpEntityInputObject } from '@ripple/models';
import {
  ActivatedRoute,
  NavigationStart,
  Router
} from '@angular/router';
import { MessageService } from './message.service';
import { AuthSessionPayload, AuthSessionResponse } from '@ripple/models';
// import { TaskNotificationService } from '../task-notification/task-notification.service';
import { AuthResponse } from 'msal';
import { MsalService } from '@azure/msal-angular';
import * as moment_ from 'moment';
const moment = moment_;

enum Permission {
  View = 1,
  Modify = 2,
  Notify = 4,
  ChangePermission = 8,
  Create = 16,
  ViewOwn = 32,
  ModifyOwn = 64,
  All = 127
}

type RedirectOptions = {
  redirectUrl?: string;
  redirect?: boolean;
  redirectBack?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _currentUser: ReplaySubject<WarpEntity> = new ReplaySubject<
    WarpEntity
  >(1);
  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _loadedUser: WarpEntity = null;
  private _dispatched = false;
  get loadedUser(): WarpEntity {
    return this._loadedUser;
  }
  public _hideAllContent = false;
  get hideAllContent() {
    return this._hideAllContent && !this.inHandoff;
  }
  set hideAllContent(value: boolean) {
    this._hideAllContent = value;
  }
  private restURL = '/api/account';

  private _isRippleAdmin = false;
  private _isSuperAdmin = false;

  private inHandoff = false;

  constructor(
    private http: HttpClient,
    private cookieService: InternalCookieService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router, // private taskNotification: TaskNotificationService
    private tokenService: TokenService,
    private msalService: MsalService,
  ) {
    this._checkLoginTokenAndUpdateUser();

    router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.inHandoff = event.url.includes('/handoff/');

        if (this.hideAllContent) this.redirectToLogin();
      });
  }

  redirectToExpiredHandoffLink() {
    this._setCurrentUser(null);
    this.messageService.add(
      this.constructor.name,
      `Redirecting to expired handoff link`,
      this.route.snapshot
    );

    this.hideAllContent = false;
    this.router.navigate([`expired-handoff-link`]);
  }

  redirectToLogin({ redirectUrl = '/', redirect = true, redirectBack = false }: RedirectOptions = { }) {
    // TODO: make this a small window that must be closed before this site responds, look at
    /* https://developer.mozilla.org/en-US/docs/Web/API/Window/open */
    // features > modal
    if (!redirect) return;
    if (redirectUrl.startsWith('/login')) redirectUrl = '/';

    const tempRedirectUrl = window.location.pathname;
    if (redirectBack && !tempRedirectUrl.startsWith('/login')) redirectUrl = tempRedirectUrl;

    this._setCurrentUser(null);
    this.messageService.add(
      this.constructor.name,
      `Redirecting to login, return: ${redirectUrl}`,
      this.route.snapshot
    );

    this.hideAllContent = false;

    if (environment.loginPage.includes('aspx')) {
      redirectUrl = `${
        environment.host
      }/transfer/?returnUrl=${encodeURIComponent(redirectUrl)}`;
      window.open(
        `${environment.host}${
          environment.loginPage
        }?ReturnUrl=${encodeURIComponent(redirectUrl)}`,
        '_self'
      );
    } else
      this.router.navigate([`${environment.loginPage}`], {
        queryParams: { ReturnUrl: redirectUrl },
      });
  }

  getLoginRedirect(redirectUrl: string = '/') {
    if (redirectUrl.startsWith('/login')) redirectUrl = '/';
    this.messageService.add(
      this.constructor.name,
      `Redirecting to login, return: ${redirectUrl}`,
      this.route.snapshot
    );
    return this.router.createUrlTree([`${environment.loginPage}`], {
      queryParams: { ReturnUrl: redirectUrl },
    });
  }

  validateLogin(
    payload: AuthSessionPayload
  ): Promise<AuthSessionResponse & { user?: WarpEntity }> {
    return this.http
      .post<AuthSessionResponse>(
        `${environment.restEndpointUrl}/api/v2/sessions`,
        payload
      )
      .toPromise()
      .then((response) =>
        response.token
          ? this._getUserFromAPI(response.token)
              .toPromise()
              .then((user) => ({ ...response, user }))
          : response
      );
  }

  validateLoginWithAzureAdAccount(authResponse: AuthResponse)
    : Promise<AuthSessionResponse & { user?: WarpEntity }> {
    const timeLoggedIn = moment.duration(moment(authResponse.expiresOn).diff(moment())).asMinutes();
    const payload = {
      userName: authResponse.account.userName,
      password: null,
      mfaCode: '',
      mfaToken: null,
      partialToken: null,
      thirdPartyToken: authResponse.accessToken,
      thirdPartyId: authResponse.account.accountIdentifier,
      timeLoggedIn: Math.ceil( timeLoggedIn ).toString()
    };
    return this.validateLogin(payload);
  }

  async checkLogin(options: RedirectOptions = { }): Promise<boolean> {
    if(environment['useAzureAdAuth']) {
      options.redirect = true;
      options.redirectUrl = '/login';
    }

    if (environment['useAzureAdAuth'] && await this._checkAzureAdTokenAndUpdate())
      return true;

    if (await this._checkLoginTokenAndUpdateUserAsync(options.redirectUrl ?? '/'))
      return true;

    console.log('No token found, redirecting to login');
    if (options.redirectUrl?.startsWith('/clinical/handoff/onetimeform/')) {
      // Expired URL, show custom page
      this.redirectToExpiredHandoffLink();
      return false;
    }

    this.redirectToLogin(options);

    return false;
  }

  loginWithToken(token: string | false = this.getToken()) {
    if (!token) return false;
    this._setUserToken(token);
    // this.taskNotification.subscribe(token);
    return this._getUserFromAPI(token);
  }

  getLoggedInUser(): Observable<WarpEntity> {
    this._checkLoginTokenAndUpdateUser(); // on request, dispatch if needed

    return this._currentUser.asObservable();
  }

  /**
   * Only use this for one-offs like api calls
   * or to check is someone is logged in at *this very second*.
   *
   * otherwise use  `getLoggedInUser()`
   */
  getSyncLoggedInUser(): WarpEntity | null {
    return this._loadedUser;
  }

  getToken(redirectUrl: string = '/'): string {
    let token = this.cookieService.getCookie(CookieType.UserToken);

    if (redirectUrl.startsWith('/clinical/handoff/onetimeform/')) {
      token = redirectUrl.replace('/clinical/handoff/onetimeform/', '');
      const slashIndex = token.indexOf('/');
      if (slashIndex > 0) token = token.substring(0, slashIndex);
    }

    return token;
  }

  logout(redirectUrl?: string) {
    // this.taskNotification.unsubscribe(this.cookieService.getCookie(CookieType.UserToken));

    this.logoutNoRedirect(true);
    this.redirectToLogin({ redirectUrl });
  }

  logoutNoRedirect(redirect: boolean = false) {
    if (environment['useAzureAdAuth'])
      this.msalService.logout();
    else
      this.http.post<void>(`${environment.restEndpointUrl}/api/account/logout/${redirect}`, {}).subscribe();

    this._setCurrentUser(null);
  }

  _perm(entityType: number) {
    if (!this._loadedUser)
      return 0;
    if (this._isSuperAdmin)
      return Permission.All;
    return this._loadedUser.entityPermissions[entityType] || 0;
  }

  canCreate(typeId: number) {
    return !!(this._perm(typeId) & Permission.Create);
  }

  canEdit(typeId: number) {
    return !!(this._perm(typeId) & (Permission.Modify | Permission.ModifyOwn));
  }

  canView(typeId: number) {
    return !!(this._perm(typeId) & (Permission.View | Permission.ViewOwn));
  }

  hasHandoffPin(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.restEndpointUrl}/api/account/hashandoffpin`);
  }

  test() {
    return this.http.get(`${environment.restEndpointUrl}/api/account/test`);
  }

  getUsersViewableClientTypes(showClientRelationships: boolean, clientTypes: string[], viewableClientTypesByRole: any): string[] {

    if (clientTypes && clientTypes.length > 1) {
      if (!showClientRelationships)
        clientTypes = clientTypes.filter(x => x.toLowerCase() !== 'contact');

      // determine what the current user can see. Clinical, Volunteer etc...
      if (viewableClientTypesByRole && this.loadedUser) {
        let viewableTypes = new Set();
        this.loadedUser?.roles.forEach(role => {
          const types = viewableClientTypesByRole[role.ID];
          if (types?.length > 0)
            types.forEach(type => viewableTypes.add(type));
        });
        // handle the default case
        const types = viewableClientTypesByRole['default'];
        if (types?.length > 0)
          types.forEach(type => viewableTypes.add(type));

        clientTypes = clientTypes.filter(x => viewableTypes.has(x));
      }
    }
    return clientTypes;
  }


  private _checkLoginTokenAndUpdateUser(redirectUrl: string = '/') {
    if (this._loadedUser) return true;
    if (this.getToken(redirectUrl)) {
      this.tokenService.asyncAssertValidToken(this.getToken()).then((valid) =>
        valid && !this._dispatched ? this.loginWithToken() : undefined
      );
      return true;
    } else return false;
  }

  private async _checkLoginTokenAndUpdateUserAsync(redirectUrl: string = '/') {
    if (this._loadedUser) return true;
    const token = this.getToken(redirectUrl);
    if (token) {
      return await this.tokenService.asyncAssertValidToken(token).then(valid => {
        if (valid && !this._dispatched)
          this.loginWithToken(token);
        return valid;
      }
      );
    } else return false;
  }

  private async _checkAzureAdTokenAndUpdate(): Promise<boolean> {
    const azureTokenValidation = await this.tokenService.asyncAssertValidAzureAdToken(this.getToken());
    if (typeof azureTokenValidation !== 'boolean') {
      const response = await this.validateLoginWithAzureAdAccount(azureTokenValidation)
        .catch(error => {
          this.messageService.add('Auth', 'Login fail', error);
          return false;
        });
      if (!response) return false;
      this.messageService.add('Auth', 'Login again with updated access token', response);
      return true;
    }
    else {
      if (azureTokenValidation && !this._dispatched) {
        this.loginWithToken();
      }
      return azureTokenValidation;
    }
  }

  private _setUserToken(token) {
    this.cookieService.setCookie(CookieType.UserToken, token);
  }

  private _getUserFromAPI(token) {
    this._setUserToken(token);
    const out = new AsyncSubject<WarpEntity>();
    this._dispatched = true;
    this.http
      .get<IWarpEntityInputObject>(
        `${environment.restEndpointUrl}/api/rippleuser/${token}/`
      )
      .pipe(
        first(),
        map((e) => new WarpEntity(e))
      )
      .subscribe((user) => {
        this.messageService.add('Auth', 'Loaded User', user);
        this._setCurrentUser(user, token);
        out.next(user);
        out.complete();
      },
      (err) => {
        this.messageService.add('Auth', 'Load User Fail', err);
        if (token.length >= 2000) {
          this.messageService.add(
            'Auth Load User Fail',
            'The server may have url maximum length limit. Go to Registry and update value of UrlSegmentMaxLength.',
            'Path in Registry: Computer\/HKEY_LOCAL_MACHINE\/SYSTEM\/CurrentControlSet\/Services\/HTTP\/Parameters',
            'UrlSegmentMaxLength: 32766 (decimal)',
            'reference: https://stackoverflow.com/questions/29519579/web-api-max-parameter-length',
            err);
        }
        out.error(err);
      });
    return out;
  }

  private _setCurrentUser(user: WarpEntity, token: string | false = false) {
    this._isRippleAdmin = user && user.isRippleAdmin;
    this._isSuperAdmin = user && user.isSuperAdmin;

    const oldUser = this.getSyncLoggedInUser();
    if (oldUser && !user)
      this.loggedIn.next(false);

    this._loadedUser = user;
    this._currentUser.next(this._loadedUser);

    if (user !== null) {
      if (token)
        this._setUserToken(token);
      this.loggedIn.next(true);
    } else
      this.cookieService.dropCookie(CookieType.UserToken);
  }

  // private async asyncAssertValidToken() {
  //   const out = new Subject<boolean>();
  //   const returnURL = this.route.snapshot.toString();
  //   this.http
  //     .get(`${environment.restEndpointUrl}/api/rippleuser/${this.getToken()}`)
  //     .subscribe(
  //       (user) => {
  //         out.next(true);
  //         out.complete();
  //       },
  //       (error: HttpErrorResponse) => {
  //         if (error.status === 401) {
  //           out.next(false);
  //           out.complete();
  //           this.messageService.add('Auth', 'Token Expired');
  //         } else {
  //           out.next(true);
  //           out.complete();
  //         }
  //       }
  //     );
  //   return out.toPromise();
  // }

  public isSuperAdmin(): Promise<boolean> {
    if (this._isSuperAdmin)
      return Promise.resolve(this._isSuperAdmin);

    return this.http.get<boolean>(`${environment.restEndpointUrl}/api/account/superAdmin`)
      .toPromise()
      .then((isSuperAdmin) => {
        this._isSuperAdmin = isSuperAdmin;
        return isSuperAdmin;
      }).catch(error => {
        console.error(error);
        this._isSuperAdmin = false;
        return false;
      });;
  }

  public isRippleAdmin(): Promise<boolean> {
    if (this._isRippleAdmin)
      return Promise.resolve(this._isRippleAdmin);

    return this.http.get<boolean>(`${environment.restEndpointUrl}/api/account/rippleAdmin`)
      .toPromise()
      .then((isRippleAdmin) => {
        this._isRippleAdmin = isRippleAdmin;
        return isRippleAdmin;
      }).catch(error => {
        console.error(error);
        this._isRippleAdmin = false;
        return false;
      });
  }

  public saveAccount(
    user: WarpEntity,
    password: string,
    handoffEnabled: boolean,
    handoffPin: string,
    enable2FA: boolean
  ): Observable<any> {

    const body = {
      password,
      confirmPassword: password,
      handoffEnabled,
      handoffPin,
      mfa: enable2FA,
    };

    return this.http.post<any>(`${environment.restEndpointUrl}/api/account`, body);

  }
}
