import { Component, OnInit, Input, HostListener, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationStart, ActivationEnd } from '@angular/router';

import { MenuItem } from 'primeng/api';
import { HubService } from 'ngx-signalr-hubservice';
import { filter } from 'rxjs/operators';

import { environment } from '@ripple/environment';
import { HubConnectionService, MenuService, LocalEntityBackupService } from '@ripple/services';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'ripple-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() brandName: string;
  @Input() logoPath: string;
  @Input() logoPrintPath: string;
  @Input() currentView: string;
  @Input() sectionItems: MenuItem[] = [];
  //@Input() hideNotifications: boolean;
  @Input() menuAvatarInitials = '--';
  @Input() menuAvatarImage: string;
  @Input() userMenuItems: MenuItem[] = [];
  @Input() viewAllNotificationLink = '';
  //@Input() disableNavigating: boolean;

  @Input() showPrimaryMenu: boolean;
  @Input() showProfileHelpMenu: boolean;
  @Input() showNotifications: boolean;
  @Input() showSignalRCheck: boolean = true;
  @Input() showSignalRDisconnectTest: boolean = false;
  @Input() showPrivacyButton: boolean = false;

  @Input() staging = false;

  @Input() menuFullAt: 'sm' | 'md' | 'lg' | 'lg2' | 'xl' | 'xxl' | 'xxxl' = 'xxxl';

  @Input() extraNeedHelps: { label: string, icon: string, link: string, newTab: boolean }[] = [];

  get menuItems() {
    return this.menuService.items;
  }

  private hubService: HubService;

  routerSub: Subscription;

  navIsActive: boolean;

  signalRReconnecting = false;
  signalRFirstConnected = false;
  signalRDialogShow = false;

  signalRHasProblem = false;

  constructor(
    private menuService: MenuService,
    private router: Router,
    private elementRef: ElementRef,
    private localBackup: LocalEntityBackupService,
    private hubConnectionService: HubConnectionService
  ) {
    this.currentView = 'Home';
    this.navIsActive = false;
    this.hubConnectionService.reconnectToHubService().then(x => {
      this.hubService = this.hubConnectionService.getHubService();
      this.signalRFirstConnected = true;
      console.log('hub service first connecting: ', this.signalRFirstConnected);
    });

    this.hubConnectionService.signalrConnectionProblem.subscribe(problem => {
      if (problem) {
          if (!this.signalRDialogShow && this.showSignalRCheck) {
              this.openSignalRDialog();
          }
          this.signalRHasProblem = true;
          console.log('signalr connection problem, should try to manually reconnect but will not do that now, as this seems cause the server busy');
          // console.log('signalr connection problem, try to manually reconnect');
          // this.hubConnectionService.reconnectToHubService().then(x => {
          //   this.hubService = this.hubConnectionService.getHubService();
          //   this.signalRFirstConnected = false;
          //   console.log('hub service first connecting: ', this.signalRFirstConnected);
          // });
      } else {
        this.signalRHasProblem = false;
        if (this.signalRDialogShow) {
          this.signalRDialogShow = false;
        }
      }
  });

    // this.router.events.subscribe(event => {
    //   if (event instanceof ActivationEnd && this.signalRFirstConnected && !this.hubService.connected && !this.signalRReconnecting) {

    //     this.signalRReconnecting = true;

    //     this.hubConnectionService.reconnectToHubService().then(success => {
    //       this.hubService = hubConnectionService.getHubService();
    //       if (this.showSignalRCheck) {
    //         this.signalRDialogShow = !success;
    //       }     
    //       this.signalRReconnecting = false;
    //     });
    //   }
    // });
  }

  
  ngOnInit(): void {
    this.routerSub = this.router.events.subscribe((evt) => {
      this.closeNav();
    })
  }
  ngOnDestroy(): void {
    this.routerSub.unsubscribe();
  }

  toggleNav() {
    this.navIsActive = !this.navIsActive;
    this.updateBodyStyle();
  }

  closeNav() {
    this.navIsActive = false;
  }

  updateBodyStyle() {
    if (this.navIsActive)
      document.querySelector('body').style.overflowY = 'hidden';
    else document.querySelector('body').style.overflowY = 'auto';
  }

  openServiceDesk() {
    window.open(this.menuService.getServiceDeskURL(), '_blank');
  }

  downloadHelpFiles() {
    this.localBackup.saveLocalStorage();
  }

  getServiceDeskURL(): string {
    return this.menuService.getServiceDeskURL();
  }

  getUserGuideURL(): string {
    return this.menuService.getUserGuideURL();
  }

  getAdminGuideURL(): string {
    return this.menuService.getAdminGuideURL();
  }

  getHelpDocsURL(): string {
    return this.menuService.getHelpDocsURL();
  }


  openUserGuide() {
    window.open(this.menuService.getUserGuideURL(), '_blank');
  }

  openAdminGuide() {
    window.open(this.menuService.getAdminGuideURL(), '_blank');
  }

  
  openHelpDocs() {
    window.open(this.menuService.getHelpDocsURL(), '_blank');
  }

  getAvatar(): string {
    return this.menuService.getAvatar();
  }

  get printLogo() {
    return this.logoPrintPath ? this.logoPrintPath : this.logoPath;
  }

  openSignalRDialog() {
    setTimeout(() => { 
      if (this.signalRHasProblem) {
        this.signalRDialogShow = true;
      }
    }, 2000);
  }

  closeSignalRDialogAndReload() {
    this.signalRDialogShow = false;
    window.location.reload();
  }

  closeSignalRDialog() {
    this.signalRDialogShow = false;
  }

  disableSignalRForTesting() {
    this.hubConnectionService.disconnectFromHubService();
  }
}
