import { FieldWrapper } from '@ngx-formly/core';
import type { FieldTypeName } from '../../service-resources/question-type-names';

export abstract class SectionWrapper extends FieldWrapper {
  protected static β_listType: 'black' | 'white' = 'black';
  protected static β_fieldList: FieldTypeName[] = [];
  static isTemplateSection = false;

  static isAllowed(field: FieldTypeName, logger?: (..._) => void): boolean {
    const isInList = this.β_fieldList.includes(field);
    const isAllowed = this.β_listType === 'black' ? !isInList : isInList;

    if (logger)
      logger(
        `SectionWrapper.isAllowed: ${field} is ${isAllowed ? 'allowed' : 'not allowed'} in ${this.name} by ${this.β_listType}list`,
        this.β_fieldList);

    return isAllowed;
  }
}
