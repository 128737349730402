import { Component, OnInit } from '@angular/core';
import { DocumentUpload } from '@ripple/models';
import {
  FileService,
  WarpEntityCacheFactoryService,
  WarpEntityServiceCache,
  MessageService,
} from '@ripple/services';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';
import { environment } from '@ripple/environment';
import { first, distinctUntilChanged, pairwise } from 'rxjs/operators';
import { ConfirmationService } from 'primeng/api';
import { isValidGuid } from '@ripple/core';

// TODO: Remove the dependency for angular-file-upload
// Use this one instead or handroll
// https://github.com/valor-software/ng2-file-upload
@Component({
  selector: 'ripple-form-builder-form-file',
  templateUrl: './form-file.component.html',
  styleUrls: ['./form-file.component.scss'],
})
export class FormFileComponent
  extends RippleFieldGenericComponent
  implements OnInit {
  protected static availableFields = [
    'question-file-type',
    'is-multi-files',
    'question-file-permission-field',
  ];

  documentUploadService: WarpEntityServiceCache<DocumentUpload>;
  newFiles: File[] = [];
  activeIndex;
  deletingFile: number;
  uploading = false;
  toBeDeleted: number[] = [];
  ignoreNextChange = false;

  fileUploadCreateEntityReqs = [];
  fileUploadSubjects = [];
  savePromise: Promise<Boolean>[] = [];
  uploadCancelled = false;

  fileUploadStatuses = {};

  get accept() {
    return this.to.fileTypes && this.to.fileTypes.length !== ''
      ? this.to.fileTypes
      : '*';
  }

  get disabled() {
    return this.to.disabled || this.uploading;
  }

  get multi() {
    return this.to.isMultiSelect;
  }

  get ifFileSelected() {
    return (
      this.formControl &&
      this.formControl.value &&
      this.formControl.value instanceof Array &&
      this.formControl.value.length > 0
    );
  }

  get logName() { return `File Upload`; }

  constructor(
    private fileService: FileService,
    warpEntityFactory: WarpEntityCacheFactoryService,
    private confirm: ConfirmationService,
    msg: MessageService
  ) {
    super(msg);
    this.documentUploadService = warpEntityFactory.get(614) as WarpEntityServiceCache<DocumentUpload>;//  1000 //TB removed this default cache size as part of refactoring.  Should be part of WarpEntiyServiceCacheFactoryLOADER if we want it
  }

  ngOnInit(): void {
    this.to.listEntityType = { id: 614, optionName: 'DocumentUpload' };
    this.loadFiles();

    this.log("disabled", this.to.disabled);

    this.nextSub = this.formControl.valueChanges.subscribe((v) => {
      if (v === 'SAVE') {
        this.ignoreNextChange = true;
        this.deleteFiles();
      } else if (this.ignoreNextChange) {
        this.ignoreNextChange = false;
      }
      else {
        this.loadFiles();
      }
    });
  }

  canDisplay(file): boolean {
    const name = file.name;

    return (
      name.endsWith('.png') ||
      name.endsWith('.jpg') ||
      name.endsWith('.jpeg') ||
      name.endsWith('.jfif') ||
      name.endsWith('.pjpeg') ||
      name.endsWith('.pfp') ||
      name.endsWith('.apng') ||
      name.endsWith('.avif') ||
      name.endsWith('.svg') ||
      name.endsWith('.bmp') ||
      name.endsWith('.ico') ||
      name.endsWith('.gif')
    );
  }

  // Since only images can be displayed inline easily, we open other files in new tabs, because of this we want the tab to stay closed
  onTabOpen(e) {
    const file = this.formControl.value[e.index];

    setTimeout(() => {
      if (this.deletingFile !== file.id) {
        if (!this.canDisplay(file)) {
          this.open(file);
          this.activeIndex = -1;
        }
      } else this.activeIndex = -1;
    }, 1);
  }

  open(file) {
    window.open(file.url, '_blank');
  }

  addToBeDeleted(file) {
    this.deletingFile = file.id;
    this.confirm.confirm({
      message: `Are you sure that you want to delete ${file.name}?`,
      accept: () => {
        this.deletingFile = null;
        
        const index = this.formControl.value.indexOf(file);
        if (index > -1) this.formControl.value.splice(index, 1);
        this.model[this.key as string] = [...this.formControl.value];
        this.form.get(this.key as string).setValue([...this.formControl.value]);
    
        this.toBeDeleted.push(file.id);
      },
      reject: () => {
        this.deletingFile = null;
      }
    })
  }

  deleteFiles() {
    this.documentUploadService.delete(
      ...this.toBeDeleted.map((id) => {
        return { entityId: id };
      })
    );
  }

  uploadFile() {
    this.uploading = true;
    this.uploadCancelled = false;

    if (!this.formControl.value) this.formControl.setValue([]);

    if (!this.multi && this.ifFileSelected)
      this.addToBeDeleted(this.formControl.value[0]);

    this.form.markAsDirty();

    // tslint:disable-next-line: forin
    for (let i = this.newFiles.length - 1; i >= 0; i--) {

      //const file = this.newFiles.pop();
      const file = this.newFiles[i];
      const fileIndex = i;

      // create entity
      if (this.multi || i == 0) {
        const documentUpload = DocumentUpload.empty();
        documentUpload.property('name', file.name);
        // console.log('file upload template options', this.to);

        const parentIdOrFunc = this.to.parentId || this.to.entityID;
        const parentId = typeof parentIdOrFunc === 'function' ? parentIdOrFunc() : parentIdOrFunc;

        documentUpload.linkedProperty('ParentID', parentId);
        documentUpload.parentID = parentId;

        this.savePromise.push(
          new Promise((resolve) => {

            this.fileUploadCreateEntityReqs.push(
              this.documentUploadService
                .create(documentUpload)
                .subscribe((entityId) => {

                  if (this.uploadCancelled) {
                    resolve(true);
                    return;
                  }

                  this.fileUploadStatuses[fileIndex + '-' + file.name] = 'uploading';

                  // create file
                  const uploadSubj = this.fileService
                                      .uploadFile(
                                        file,
                                        this.key as string,
                                        this.to.filePermission,
                                        null,
                                        entityId
                                      );
                  this.fileUploadSubjects.push(uploadSubj);
                  
                  uploadSubj.subscribe((guid) => {
                      if (guid && isValidGuid(guid)) {
                        if (!this.multi) this.formControl.setValue([]);
                        const fileObj = { id: entityId, name: file.name };
                        this.generateFileInfo(fileObj, guid);
                        this.fileUploadStatuses[fileIndex + '-' + file.name] = 'success';

                        if (this.formControl && this.formControl.value && this.formControl.value instanceof Array) {
                          this.formControl.value.push(fileObj);
                          this.model[this.key as string] = [...this.formControl.value];
                          this.form.get(this.key as string).setValue([...this.formControl.value]);
                        }
                        resolve(true);
                      } else if (guid === 'cancelled') {
                        resolve(true);
                      }
                    });
                },
                (error) => {
                  console.log('error', error)
                  this.fileUploadStatuses[fileIndex + '-' + file.name] = 'error';
                  resolve(true);
                })
              );
          })
        );
      }
    }

    Promise.all(this.savePromise).then(() => { 
      this.uploading = false;
      this.newFiles = [];
      this.fileUploadStatuses = {};
    });
  }

  loadFiles() {
    if (!this.formControl.value) return;
    for (let file of this.formControl.value) {
      if (!file.url) {
        file.url = '';
        file.thumbnail = '';
        this.documentUploadService
          .get(file.id)
          .pipe(first())
          .subscribe((doc) => {
            if (doc.files.length > 0) this.generateFileInfo(file, doc.files[0]);
          });
      }
    }
  }

  formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 B'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  generateFileInfo(file, guid) {
    file.url = `${environment.restEndpointUrl}/api/blob/${guid}`;
    file.thumbnail = file.url + '?type=thumb';
  }

  cancelUploads() {
    if (!this.uploadCancelled) {
      this.uploadCancelled = true;
      this.fileUploadSubjects.forEach(subj => {
        this.fileService.cancelUpload(subj.value.toString()).subscribe(val => {
          if (val) {
            subj.next('cancelled');
          }
        });
      })
      this.fileUploadSubjects = [];
    }
  }
}
