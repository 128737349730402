import { Component, Input, Output, OnChanges, OnInit, EventEmitter } from '@angular/core';
import { EntityFilter, SubscriptionContainer, WarpEntity } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { Subscription } from 'rxjs';
import { EntityTypes, Organization, OrganizationTag, OrganizationTagCategory } from '../../_core/models';

@Component({
  selector: 'sagesse-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends SubscriptionContainer implements OnInit, OnChanges {

  //@Output() tagsDoneLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() entity: WarpEntity;
  @Input() claresLawOnly = false;
  claresLawOnlyTags = [203492, 203495, 203494, 203487, 203517, 203486, 203518, 203478, 203479, 203480, 203481, 203482, 203483, 203484, 203497,331928,331930];
  claresLawOnlyTagsCategoryNames = ['types of supports', 'other considerations']

  categoryService: WarpEntityServiceCache<OrganizationTagCategory>;
  tagService: WarpEntityServiceCache<OrganizationTag>;

  tagSubs = {};

  categories: OrganizationTagCategory[] = [];
  tags: OrganizationTag[] = [];

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService
  ) {
    super();
    this.categoryService = this.warpEntityFactory.get(EntityTypes.OrganizationTagCategory) as WarpEntityServiceCache<OrganizationTagCategory>;
    this.tagService = this.warpEntityFactory.get(EntityTypes.OrganizationTag) as WarpEntityServiceCache<OrganizationTag>;
  }

  ngOnInit(): void {
    const categoryFilter = EntityFilter.None.orderBy('order', 'asc');
    this.nextSub = this.categoryService.initQuery(categoryFilter, 9999999).getPage(0).subscribe(categories => {
      this.categories = categories.map(c => {
        if (!c.tags) c.tags = [];
        return c;
      });
      this.categories.forEach(category => {
        if (this.tagSubs[category.entityId]) this.tagSubs[category.entityId].unsubscribe();
        const tagFilter = EntityFilter.Advanced({ organizationtagcategory_lid: category.entityId }).orderBy('order', 'asc');
        const tagsSub = this.tagService.initQuery(tagFilter, 9999999).getPage(0).subscribe(tags => {
          category.tags = tags;
          // checkbox needs same reference
          this.updateReference(false);
        });
        this.nextSub = this.tagSubs[category.entityId] = tagsSub;
      });

      //this.tagsDoneLoading.emit(true);
    });
  }

  ngOnChanges() {
    this.updateReference(false);
  }

  selectAll(category) {
    // can do it this way because they have the same reference
    if (!this.entity.properties.organizationtags) this.entity.properties.organizationtags = [];
    const tags = category.tags.filter(t => this.tagShow(t));
    this.entity.properties.organizationtags = [...new Set([...this.entity.properties.organizationtags, ...tags])];
  }

  updateReference(removeNotExist=false) {
    for (const category of this.categories){
      this.updateReferenceForCategory(category);
    }
    if (removeNotExist)
      this.entity.properties.organizationtags = this.entity.properties.organizationtags.filter(t => t instanceof OrganizationTag);
  }

  updateReferenceForCategory(category, removeNotExist=false) {
    if (this.entity && this.entity.properties.organizationtags) {
      this.entity.properties.organizationtags = this.entity.properties.organizationtags
        .map(ot => category.tags.find(t => t.entityId === ot.id) ? category.tags.find(t => t.entityId === ot.id) : ot);
      if (removeNotExist)
        this.entity.properties.organizationtags = this.entity.properties.organizationtags.filter(t => t instanceof OrganizationTag);
    }

  }

  categoryShow(category: OrganizationTagCategory) {
    if (category.tags) {
      for (const tag of category.tags) {
        if (this.tagShow(tag)) {
          return true;
        }
      }
    }
    return false;
  }

  tagShow(tag: OrganizationTag) {
    if (this.claresLawOnly) {
      return this.claresLawOnlyTags.includes(tag.entityId) || this.claresLawOnlyTagsCategoryNames.includes(tag?.linkedProperties?.organizationtagcategory?.name.toLowerCase()) ;
    }
    return true;
  }

  showFaithInputText(category: OrganizationTagCategory) {
    return this.entity && this.entity.entityTypeId === EntityTypes.ClaresLaw && category.name.toLowerCase().includes('faith');
  }


}
