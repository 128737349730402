import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { RadioButton } from 'primeng/radiobutton';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MessageService } from '@ripple/services';

import type { SelectBoxOption } from '../common/select-box.model';
import { SelectBoxFieldComponent } from '../common/select-box-field.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ripple-form-radio-multiple-col',
  templateUrl: './form-radio-multiple-col.component.html',
  styleUrls: ['./form-radio-multiple-col.component.scss']
})
// @dynamic
export class FormRadioMultipleColComponent extends SelectBoxFieldComponent implements OnInit {
  protected static availableFields = ['label-for-options', 'question-options-field', 'question-col-type-field', 'question-horizontal-radio-field'];

  @ViewChildren(RadioButton) listOfRadios!: QueryList<RadioButton>;

  mapOptionSub: Subscription;
  radioOptions$: BehaviorSubject<SelectBoxOption[]> = new BehaviorSubject([]);

  selectedID: number;

  constructor(msg: MessageService) { super(msg); }

  ngOnInit() {
    this.log('form-radio-multiple-col.component.ts ngOnInit()');

    // when the model is pre-loaded
    this.mapOptionToCheckboxOptions();
    this.nextSub = this.formControl.valueChanges.subscribe(v => {
      this.mapOptionToCheckboxOptions();
    });
  }

  mapOptionToCheckboxOptions() {
    this.log('mapOptionToCheckboxOptions');
    this.selectedID = this.selectedSingle?.id;

    if (this.mapOptionSub)
      this.mapOptionSub.unsubscribe();

    if (!(this.to.options instanceof Observable))
      this.radioOptions$.next(this._mapOptions(this.to.options));
    else
      this.nextSub = this.mapOptionSub = this.to.options
        .pipe(map(options => this._mapOptions(options)))
        .subscribe(this.radioOptions$);
  }

  _mapOptions(options: any[]): SelectBoxOption[] {
    const retVal: SelectBoxOption[] = [];

    options.forEach(option => {
      this.optionsExistedInModel(option);

      retVal.push(this.generateSelectBoxOptionFromOptionWithTempId(option, this.selectedID === option.id));
    });

    return retVal;
  }

  optionsExistedInModel(option): boolean {
    this.log('optionsExistedInModel option: ', option);

    const selected = this.selectedSingle;
    delete option.specifytext;

    if (selected && option.id === selected.id) {
      option.specifytext = selected.specifytext;
      return true;
    }

    return false;
  }

  ionRadioChanged(event, radioOptions: SelectBoxOption[]) {
    // console.log('ionRadioChanged radio: ', event);
    let chosenOption: SelectBoxOption = null;
    radioOptions.forEach((radOpt) => {
      // console.log('radOpt.value: ', radOpt);
      if (radOpt.id === event.detail.value) {
        chosenOption = radOpt;
      }
    });
    console.log('ionRadioChanged chosenOption: ', chosenOption);
    if (chosenOption) {
      this.updateModel(chosenOption);
    }
  }

  // fired when the user clicks on the radio button
  radioChanged(event, option: SelectBoxOption) {
    this.formControl.markAsDirty();
    this.updateModel(option);
  }

  updateModel(option: SelectBoxOption) {
    this.log('updateModel', option);
    if (this.model && typeof this.key === 'string') {
      this.model[this.key] = [option];
      this.form.get(this.key).setValue([option]);
      // if model has changed, fire the change event
      this.tryEmitOnChange()
    }
  }

  specifyTextChange(event, option: SelectBoxOption) {
    // specify component did this already, we just need to update the model

    const currOptions = this.radioOptions$.value;
    const ind = currOptions.findIndex(currOption => currOption.id === option.id);
    if (ind >= 0)
      currOptions[ind] = option;

    this.radioOptions$.next(currOptions);

    // Update the model
    this.updateModel(option);
    this.formControl.markAsDirty();

    this.log('option', event, option);
  }
}
