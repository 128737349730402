import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ripple-sticky-note',
  templateUrl: './sticky-note.component.html',
  styleUrls: ['./sticky-note.component.scss']
})
export class StickyNoteComponent implements OnInit {

  @Input() noteList: string[];
  @Input() title: string;
  @Input() bgColor = '#ffc';
  @Input() iconClass: string;

  constructor() { }

  ngOnInit(): void {
  }
}
