import { LoremIpsum } from 'lorem-ipsum';
import * as moment_ from 'moment';
const moment = moment_;

// tslint:disable-next-line: no-any
declare var require: (id: string) => any;

const languageList = require('language-list')();
const randomName = require('node-random-name');

export const lorem = new LoremIpsum();

export const names: (options?: {
  first?: boolean, last?: boolean,
  male?: boolean, female?: boolean,
  gender?: 'male' | 'female',
  // tslint:disable-next-line: no-any
  seed?: any, random?: () => number,
}) => string = randomName;

export const randomInt = (start: number, end: number) => Math.round(start + ( Math.random() * (end - start) ));

export const chooseFrom = (array: (object | string)[]) => array[randomInt(0, array.length - 1)];

export const randomMoment = (startYr= 1950, endYr= 2000) => {
  return moment().set({
    year: randomInt(startYr, endYr),
    month: randomInt(0, 12),
    day: randomInt(0, 28)
  });
};

export const lang = (code: string = null) => (code) ? languageList.getLanguageName(code) : chooseFrom(languageList.getLanguageNames());

const colorList = [
  { name: 'Chartreuse green', code: '#7FFF00'}, { name: 'Chocolate', code: '#7B3F00'}, { name: 'Slate gray', code: '#708090'},
  { name: 'Periwinkle', code: '#CCCCFF'}, { name: 'Persian blue', code: '#1C39BB'}, { name: 'Byzantium', code: '#702963'},
  { name: 'Ultramarine', code: '#3F00FF'}, { name: 'Cobalt blue', code: '#0047AB'}, { name: 'Coffee', code: '#6F4E37'},
  { name: 'Blue-violet', code: '#8A2BE2'}, { name: 'Jade', code: '#00A86B'}, { name: 'Jungle green', code: '#29AB87'},
  { name: 'Lime', code: '#BFFF00'}, { name: 'Magenta', code: '#FF00FF'}, { name: 'Magenta rose', code: '#FF00AF'},
  { name: 'Red-violet', code: '#C71585'}, { name: 'Amaranth', code: '#E52B50'}, { name: 'Amber', code: '#FFBF00'},
  { name: 'Lavender', code: '#B57EDC'}, { name: 'Lemon', code: '#FFF700'}, { name: 'Aquamarine', code: '#7FFFD4'},
  { name: 'Crimson', code: '#DC143C'}, { name: 'Cyan', code: '#00FFFF'}, { name: 'Desert sand', code: '#EDC9Af'},
  { name: 'Orange', code: '#FF6600'}, { name: 'Orange-red', code: '#FF4500'}, { name: 'Orchid', code: '#DA70D6'},
  { name: 'Electric blue', code: '#7DF9FF'}, { name: 'Peach', code: '#FFE5B4'}, { name: 'Pear', code: '#D1E231'},
  { name: 'Carmine', code: '#960018'}, { name: 'Cerise', code: '#DE3163'}, { name: 'Cerulean', code: '#007BA7'},
  { name: 'Pink', code: '#FD6C9E'}, { name: 'Plum', code: '#8E4585'}, { name: 'Prussian blue', code: '#003153'},
  { name: 'Brown', code: '#964B00'}, { name: 'Burgundy', code: '#800020'}, { name: 'Sapphire', code: '#0F52BA'},
  { name: 'Harlequin', code: '#3FFF00'}, { name: 'Indigo', code: '#4B0082'}, { name: 'Ivory', code: '#FFFFF0'},
  { name: 'Maroon', code: '#800000'}, { name: 'Mauve', code: '#E0B0FF'}, { name: 'Navy blue', code: '#000080'},
  { name: 'Amethyst', code: '#9966CC'}, { name: 'Apricot', code: '#FBCEB1'}, { name: 'Olive', code: '#808000'},
  { name: 'Ochre', code: '#CC7722'}, { name: 'Violet', code: '#7F00FF'}, { name: 'Viridian', code: '#40826D'},
  { name: 'Black', code: '#000000'}, { name: 'Blue', code: '#0000FF'}, { name: 'Blue-green', code: '#0095B6'},
  { name: 'Azure', code: '#007FFF'}, { name: 'Baby blue', code: '#89CFF0'}, { name: 'Beige', code: '#F5F5DC'},
  { name: 'Taupe', code: '#483C32'}, { name: 'Teal', code: '#008080'}, { name: 'Turquoise', code: '#40E0D0'},
  { name: 'Purple', code: '#800080'}, { name: 'Raspberry', code: '#E30B5C'}, { name: 'Red', code: '#FF0000'},
  { name: 'Sangria', code: '#92000A'}, { name: 'Emerald', code: '#50C878'}, { name: 'Erin', code: '#00FF3F'},
  { name: 'Gold', code: '#FFD700'}, { name: 'Gray', code: '#808080'}, { name: 'Champagne', code: '#F7E7CE'},
  { name: 'Copper', code: '#B87333'}, { name: 'Coral', code: '#FF7F50'}, { name: 'Lilac', code: '#C8A2C8'},
  { name: 'White', code: '#FFFFFF'}, { name: 'Yellow', code: '#FFFF00'}, { name: 'Green', code: '#00FF00'},
  { name: 'Scarlet', code: '#FF2400'}, { name: 'Silver', code: '#C0C0C0'}, { name: 'Tan', code: '#D2B48C'},
  { name: 'Puce', code: '#CC8899'}, { name: 'Blush', code: '#DE5D83'}, { name: 'Bronze', code: '#CD7F32'},
  { name: 'Rose', code: '#FF007F'}, { name: 'Ruby', code: '#E0115F'}, { name: 'Salmon', code: '#FA8072'},
  { name: 'Spring bud', code: '#A7FC00'}, { name: 'Spring green', code: '#00FF7F'},
];
const animalList = [
  'Canidae', 'Felidae', 'Cat', 'Cattle', 'Dog', 'Donkey', 'Goat', 'Guinea pig', 'Horse', 'Pig', 'Rabbit', 'Fancy rat varieties',
  'laboratory rat strains', 'Sheep breeds', 'Water buffalo breeds', 'Chicken breeds', 'Duck breeds', 'Goose breeds',
  'Pigeon breeds', 'Turkey breeds', 'Aardvark', 'Aardwolf', 'African buffalo', 'African elephant', 'African leopard', 'Albatross',
  'Alligator', 'Alpaca', 'American buffalo (bison)', 'American robin', 'Amphibian', 'Anaconda', 'Angelfish', 'Anglerfish', 'Ant',
  'Anteater', 'Antelope', 'Antlion', 'Ape', 'Aphid', 'Arabian leopard', 'Arctic Fox', 'Arctic Wolf', 'Armadillo', 'Arrow crab',
  'Asp', 'Ass (donkey)', 'Baboon', 'Badger', 'Bald eagle', 'Bandicoot', 'Barnacle', 'Barracuda', 'Basilisk', 'Bass', 'Bat',
  'Beaked whale', 'Bear', 'Beaver', 'Bedbug', 'Bee', 'Beetle', 'Bird', 'Bison', 'Blackbird', 'Black panther', 'Black widow spider',
  'Blue bird', 'Blue jay', 'Blue whale', 'Boa', 'Boar', 'Bobcat', 'Bobolink', 'Bonobo', 'Booby', 'Box jellyfish', 'Bovid', 'Bug',
  'Butterfly', 'Buzzard', 'Camel', 'Canid', 'Cape buffalo', 'Capybara', 'Cardinal', 'Caribou', 'Carp', 'Cat', 'Catshark',
  'Caterpillar', 'Catfish', 'Cattle', 'Centipede', 'Cephalopod', 'Chameleon', 'Cheetah', 'Chickadee', 'Chicken', 'Chimpanzee',
  'Chinchilla', 'Chipmunk', 'Clam', 'Clownfish', 'Cobra', 'Cockroach', 'Cod', 'Condor', 'Constrictor', 'Coral', 'Cougar', 'Cow',
  'Coyote', 'Crab', 'Crane', 'Crane fly', 'Crawdad', 'Crayfish', 'Cricket', 'Crocodile', 'Crow', 'Cuckoo', 'Cicada', 'Damselfly',
  'Deer', 'Dingo', 'Dinosaur', 'Dog', 'Dolphin', 'Donkey', 'Dormouse', 'Dove', 'Dragonfly', 'Dragon', 'Duck', 'Dung beetle', 'Eagle',
  'Earthworm', 'Earwig', 'Echidna', 'Eel', 'Egret', 'Elephant', 'Elephant seal', 'Elk', 'Emu', 'English pointer', 'Ermine', 'Falcon',
  'Ferret', 'Finch', 'Firefly', 'Fish', 'Flamingo', 'Flea', 'Fly', 'Flyingfish', 'Fowl', 'Fox', 'Frog', 'Fruit bat', 'Gamefowl',
  'Galliform', 'Gazelle', 'Gecko', 'Gerbil', 'Giant panda', 'Giant squid', 'Gibbon', 'Gila monster', 'Giraffe', 'Goat', 'Goldfish',
  'Goose', 'Gopher', 'Gorilla', 'Grasshopper', 'Great blue heron', 'Great white shark', 'Grizzly bear', 'Ground shark', 'Ground sloth',
  'Grouse', 'Guan', 'Guanaco', 'Guineafowl', 'Guinea pig', 'Gull', 'Guppy', 'Haddock', 'Halibut', 'Hammerhead shark', 'Hamster', 'Hare',
  'Harrier', 'Hawk', 'Hedgehog', 'Hermit crab', 'Heron', 'Herring', 'Hippopotamus', 'Hookworm', 'Hornet', 'Horse', 'Hoverfly',
  'Hummingbird', 'Humpback whale', 'Hyena', 'Iguana', 'Impala', 'Irukandji jellyfish', 'Jackal', 'Jaguar', 'Jay', 'Jellyfish',
  'Junglefowl', 'Kangaroo', 'Kangaroo mouse', 'Kangaroo rat', 'Kingfisher', 'Kite', 'Kiwi', 'Koala', 'Koi', 'Komodo dragon', 'Krill',
  'Ladybug', 'Lamprey', 'Landfowl', 'Land snail', 'Lark', 'Leech', 'Lemming', 'Lemur', 'Leopard', 'Leopon', 'Limpet', 'Lion', 'Lizard',
  'Llama', 'Lobster', 'Locust', 'Loon', 'Louse', 'Lungfish', 'Lynx', 'Macaw', 'Mackerel', 'Magpie', 'Mammal', 'Manatee', 'Mandrill',
  'Manta ray', 'Marlin', 'Marmoset', 'Marmot', 'Marsupial', 'Marten', 'Mastodon', 'Meadowlark', 'Meerkat', 'Mink', 'Minnow', 'Mite',
  'Mockingbird', 'Mole', 'Mollusk', 'Mongoose', 'Monitor lizard', 'Monkey', 'Moose', 'Mosquito', 'Moth', 'Mountain goat', 'Mouse', 'Mule',
  'Muskox', 'Narwhal', 'Newt', 'New World quail', 'Nightingale', 'Ocelot', 'Octopus', 'Old World quail', 'Opossum', 'Orangutan', 'Orca',
  'Ostrich', 'Otter', 'Owl', 'Ox', 'Panda', 'Panther', 'Panthera hybrid', 'Parakeet', 'Parrot', 'Parrotfish', 'Partridge', 'Peacock',
  'Peafowl', 'Pelican', 'Penguin', 'Perch', 'Peregrine falcon', 'Pheasant', 'Pig', 'Pigeon', 'Pike', 'Pilot whale', 'Pinniped', 'Piranha',
  'Planarian', 'Platypus', 'Polar bear', 'Pony', 'Porcupine', 'Porpoise', 'Portuguese man o\' war', 'Possum', 'Prairie dog', 'Prawn',
  'Praying mantis', 'Primate', 'Ptarmigan', 'Puffin', 'Puma', 'Python', 'Quail', 'Quelea', 'Quokka', 'Rabbit', 'Raccoon', 'Rainbow trout',
  'Rat', 'Rattlesnake', 'Raven', 'Ray (Batoidea)', 'Ray (Rajiformes)', 'Red panda', 'Reindeer', 'Reptile', 'Rhinoceros', 'Right whale',
  'Roadrunner', 'Rodent', 'Rook', 'Rooster', 'Roundworm', 'Saber-toothed cat', 'Sailfish', 'Salamander', 'Salmon', 'Sawfish',
  'Scale insect', 'Scallop', 'Scorpion', 'Seahorse', 'Sea lion', 'Sea slug', 'Sea snail', 'Shark', 'Sheep', 'Shrew', 'Shrimp', 'Silkworm',
  'Silverfish', 'Skink', 'Skunk', 'Sloth', 'Slug', 'Smelt', 'Snail', 'Snake', 'Snipe', 'Snow leopard', 'Sockeye salmon', 'Sole',
  'Sparrow', 'Sperm whale', 'Spider', 'Spider monkey', 'Spoonbill', 'Squid', 'Squirrel', 'Starfish', 'Star-nosed mole', 'Steelhead trout',
  'Stingray', 'Stoat', 'Stork', 'Sturgeon', 'Sugar glider', 'Swallow', 'Swan', 'Swift', 'Swordfish', 'Swordtail', 'Tahr', 'Takin',
  'Tapir', 'Tarantula', 'Tarsier', 'Tasmanian devil', 'Termite', 'Tern', 'Thrush', 'Tick', 'Tiger', 'Tiger shark', 'Tiglon', 'Toad',
  'Tortoise', 'Toucan', 'Trapdoor spider', 'Tree frog', 'Trout', 'Tuna', 'Turkey', 'Turtle', 'Tyrannosaurus', 'Urial', 'Vampire bat',
  'Vampire squid', 'Vicuna', 'Viper', 'Vole', 'Vulture', 'Wallaby', 'Walrus', 'Wasp', 'Warbler', 'Water Boa', 'Water buffalo', 'Weasel',
  'Whale', 'Whippet', 'Whitefish', 'Whooping crane', 'Wildcat', 'Wildebeest', 'Wildfowl', 'Wolf', 'Wolverine', 'Wombat', 'Woodpecker',
  'Worm', 'Wren', 'Xerinae', 'X-ray fish', 'Yak', 'Yellow perch', 'Zebra', 'Zebra finch', 'Alpaca', 'Bali cattle', 'Cat', 'Cattle',
  'Chicken', 'Dog', 'Domestic Bactrian camel', 'Domestic canary', 'Domestic dromedary camel', 'Domestic duck', 'Domestic goat',
  'Domestic goose', 'Domestic guineafowl', 'Domestic hedgehog', 'Domestic pig', 'Domestic pigeon', 'Domestic rabbit', 'Domestic silkmoth',
  'Domestic silver fox', 'Domestic turkey', 'Donkey', 'Fancy mouse', 'Fancy rat', 'Lab rat', 'Ferret', 'Gayal', 'Goldfish', 'Guinea pig',
  'Guppy', 'Horse', 'Koi', 'Llama', 'Ringneck dove', 'Sheep', 'Siamese fighting fish', 'Society finch', 'Water buffalo',
];

export const color = (grab: 'name' | 'code' = 'name', name: string = null) => (colorList[name] ?? chooseFrom(colorList))[grab];
export const animal = (name: string = null) => animalList[name] ?? chooseFrom(animalList);
