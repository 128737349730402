import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilderSaveEvent } from '@ripple/formbuilder';
import { WarpEntity } from '@ripple/models';
import { environment } from '@ripple/environment';
import { ActivatedRoute } from '@angular/router';
import { FilledForm } from '../_core/models';
import { Message } from 'primeng/api';

@Component({
  selector: 'sagesse-clares-law-result',
  templateUrl: './clares-law-result.component.html',
  styleUrls: ['./clares-law-result.component.scss']
})
export class ClaresLawResultComponent implements OnInit {

  @Input() filledForm: FilledForm;
  @Input() formData: WarpEntity;
  @Output('afterSave') afterSave: EventEmitter<FormBuilderSaveEvent> = new EventEmitter<FormBuilderSaveEvent>();
  @Input() viewOnly = false;

  // haveMade: '2030' | '2031';
  // haveMade: '1000325' | '1000324';
  // ifYes: '1000120' | '1000119' | '1000118'; // 2530 | 2531 | 2532
  // other: 1001568

  haveMade: string;
  ifYes: string;

  haveMadePropertyName = 'formbuilder--radio-multiple-col-haveyoumadecontactorattemptedtomakecontactwiththisclareslawapplicant_0';
  ifYesPropertyName = 'formbuilder--radio-multiple-col-ifyesselectfrombelow_0';

  noInterestOurInfo = 'formbuilder--generic-textarea-additionalinformationfornointerestinouragencyservices_0';
  noInterestAnyInfo = 'formbuilder--generic-textarea-additionalinformationfornointerestinanyservices_0';
  noResponseInfo = 'formbuilder--generic-textarea-additionalinformationfornoresponse_0';
  noContactInfo = 'formbuilder--generic-textarea-additionalinformationfornocontact_0';
  otherInfo = 'formbuilder--generic-textarea--additionalinfoforother_0';

  disabled = false;

  isSaving = false;
  isSubmitting = false;

  enviro = environment;

  attempt = -1;
  ifError = false;
  ifValid = true;
  msgs: Message[] = [{severity: 'success', summary: 'Thanks for submitting the form!' }];
  validMsgs: Message[] = [{severity: 'success', summary: 'Valid' }];

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {
      if (queryParams.attempt) {
        const attempt = parseInt(queryParams.attempt, 10);
        if (!isNaN(attempt)) this.attempt = attempt;
      }
      console.log('attempt: ', this.attempt, 'should be: ', this.filledForm.currentAttempt);
      if (this.attempt < 0) {
        this.attempt = this.filledForm.currentAttempt;
      }
      if (this.filledForm.currentAttempt !== this.attempt) {
        this.ifError = true;
        this.msgs = [{severity: 'error', summary: `Wrong attempt link. Please try the link of attempt ${this.filledForm.currentAttempt}. ` }];
      } else {
        this.ifError = false;
        this.initData();
      }
    });
  }

  initData() {
    console.log('result data', this.formData);
    const haveMadeProperty = this.formData.properties[this.haveMadePropertyName];
    this.haveMade = haveMadeProperty && (this.attempt === 1 || this.viewOnly) ? haveMadeProperty[0].id.toString() : null;
    const ifYesProperty = this.formData.properties[this.ifYesPropertyName];
    this.ifYes = ifYesProperty  && (this.attempt === 1 || this.viewOnly) ? ifYesProperty[0].id.toString() : null;
    console.log('wrapped data', this.haveMade, this.ifYes);
  }

  save(action) {
    this.checkValidation();
    if (!this.ifValid) {
      return;
    }
    this.disabled = true;
    if (action === 'save') {
      this.isSaving = true;
    }
    if (action === 'submit') {
      this.isSubmitting = true;
    }
    if (this.haveMade) {
      this.formData.cfcProperty(this.haveMadePropertyName, parseInt(this.haveMade));
    }

    if (this.ifYes) {
      this.formData.cfcProperty(this.ifYesPropertyName, parseInt(this.ifYes));
    }

    this.afterSave.emit({ formData: this.formData, action });
  }

  checkValidation() {
    let invalidField = '';
    this.ifValid = true;
    if (!this.haveMade) {
      this.ifValid = false;
      invalidField = 'Have you made contact or attempted to make contact with this Clare’s Law applicant?';
    } 
    if (this.haveMade === this.enviro['haveMade']['yes']) {
      // if select yes, need to fill in ifYes
      if (!this.ifYes) {
        this.ifValid = false;
        invalidField = 'If yes, please answer below:';
      }
      if (this.ifYes === this.enviro['ifYes']['noInterest'] && !this.formData.properties[this.noInterestOurInfo] ||
      this.ifYes === this.enviro['ifYes']['noInterestAny'] && !this.formData.properties[this.noInterestAnyInfo] ||
      this.ifYes === this.enviro['ifYes']['other'] && !this.formData.properties[this.otherInfo] ||
      this.ifYes === this.enviro['ifYes']['noResponse'] && !this.formData.properties[this.noResponseInfo]) {
        this.ifValid = false;
        invalidField = 'Additional Information';
      }
    } else if (this.haveMade === this.enviro['haveMade']['no'] && !this.formData.properties[this.noContactInfo]) {
      this.ifValid = false;
      invalidField = 'Additional Information';
    }

    if (!this.ifValid) {
      this.validMsgs = [{severity: 'error', summary: `You need to fill in this field: "${invalidField}" ` }];
    }
  }

}
