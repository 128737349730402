import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';
import { MessageService } from '@ripple/services';

//#region shamelessly stolen: https://stackoverflow.com/a/70800591
// get the cursor position from .editor start
function getCursorPosition(parent, node, offset, stat) {
  if (stat.done) return stat;

  let currentNode = null;
  if (parent.childNodes.length === 0)
    stat.pos += parent.textContent.length;
  else
    for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
      currentNode = parent.childNodes[i];
      if (currentNode === node) {
        stat.pos += offset;
        stat.done = true;
        return stat;
      } else
        getCursorPosition(currentNode, node, offset, stat);
    }
  return stat;
}

// find the child node and relative position and set it on range
function setCursorPosition(parent, range, stat) {
  if (stat.done) return range;

  let currentNode = null;
  if (parent.childNodes.length === 0)
    if (parent.textContent.length >= stat.pos) {
      range.setStart(parent, stat.pos);
      stat.done = true;
    } else
      stat.pos = stat.pos - parent.textContent.length;
  else
    for (let i = 0; i < parent.childNodes.length && !stat.done; i++) {
      currentNode = parent.childNodes[i];
      setCursorPosition(currentNode, range, stat);
    }

  return range;
}
//#endregion shamelessly stolen

@Component({
  selector: 'ripple-form-textarea',
  templateUrl: './form-textarea.component.html',
  styleUrls: ['./form-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
// @dynamic
export class FormTextareaComponent extends RippleFieldGenericComponent implements AfterViewInit, OnInit {
  protected static availableFields = ['question-input-number-field', 'question-input-maxLength-field', 'question-input-is-rich-text-editor'];

  get autoResize() {
    return this.to.moduleSettings && this.to.moduleSettings['autoresize'] ? this.to.moduleSettings['autoresize'] === 'true' : false;
  }

  get rows() {
    return this.to.moduleSettings && this.to.moduleSettings['rows'] ? this.to.moduleSettings['rows'] : 5;
  }

  get valueFormatted() {
    let value = this.formControl.value || '';
    if (this.to.formatter)
      value = this.to.formatter(value);

    return value.replace(/\n/g, '<br>');
  }

  @ViewChild('textDiv') textDiv: ElementRef<HTMLDivElement>;

  constructor(
    private cdRef: ChangeDetectorRef,
    msg: MessageService
  ) {
    super(msg);
  }

  ngOnInit(): void {
    // remove old default max length
    // if default set to something other than 10000, then if was a user input
    // and either way, rich text doesn't support max length
    if (this.to.maxLength > 9000 || this.to.enableRichTextEditor) {
      this.to.maxLength = undefined;
      delete this.to.maxLength;
    }

    if (this.viewOnly)
      this.formControl.disable({ emitEvent: false });
  }

  ngAfterViewInit(): void {
    this.log(`with${this.to.customFormatting ? '' : 'out'} custom formatting`, this);

    if (this.textDiv)
      this.textDiv.nativeElement.innerHTML = this.valueFormatted;
  }

  onDivChange($event: InputEvent) {
    const div = $event.target as HTMLDivElement;
    this.log(`textarea onDivChange ${div.innerText} ${this.valueFormatted}`, this, $event, div);

    // get current cursor position
    const sel = window.getSelection();
    const node = sel.focusNode;
    const offset = sel.focusOffset;
    const pos = getCursorPosition(div, node, offset, { pos: 0, done: false });

    if ( div.innerText !== this.valueFormatted )
      this.formControl.setValue(div.innerText, { emitEvent: false });

    div.innerHTML = this.valueFormatted;

    // restore the position
    sel.removeAllRanges();
    const range = setCursorPosition(div, document.createRange(), {  pos: pos.pos, done: false});
    range.collapse(true);
    sel.addRange(range);
  }

  blur() {
    this.log('mark for check after blur in textarea');
    this.cdRef.markForCheck();

    if (this.to.customFormatting)
      this.formControl.setValue(this.formControl.value, { emitEvent: true });
  }

  replaceNewlinesWithBreaks(text: string) {
    return (text || '').replace(/(?:\r\n|\r|\n)/g, '<br/>');
  }
}
