import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';

import { environment } from '@ripple/environment';
import { IGenericObject, WarpEntity } from '@ripple/models';
import { FileService, MessageService } from '../general';

import { AES as aes } from 'crypto-js';
import { Guid } from 'guid-typescript';
import { JSEncrypt } from 'jsencrypt';
import { Decryptor, EncryptedEntityHistory, InfoItem, group, index, insertTime, maxHistory, maxLongTermHistory } from './decryptor';

@Injectable({
  providedIn: 'root'
})
export class LocalEntityBackupService {
  static DEFAULT_BACKUP_INTERVAL = 7500;

  private lastLongTermBackup: number;
  private longTermInterval = 5 /* minutes */;
  private longTermSuffix = '_longTerm';
  private needLongTermBackup;
  private longTermTimer;
  /** how long to keep long term items, in days */
  public trimLongTermAfter = 30;
  public clearLongTermAfter = 180;

  public maxLongTermHistory = maxLongTermHistory; // 2 hours
  public maxHistory = maxHistory;

  private crypto: JSEncrypt;
  private _keySet = false;
  private browserSupportsEstimate = false; // Promise<StorageEstimate>

  constructor(private messageService: MessageService, private http: HttpClient, private file: FileService) {
    this.crypto = new JSEncrypt();
    this.getKeyFromServer();

    this.browserSupportsEstimate = 'storage' in navigator && 'estimate' in navigator.storage;
    this.needLongTermBackup = true; // push any unsaved things from last run
    this.doLongTermBackup();
    this.longTermTimer = setInterval(_ => this.doLongTermBackup(), this.longTermInterval * 60000);

    (window as IGenericObject).dumpLocalStorage = this.saveLocalStorage.bind(this);
    (document as IGenericObject).localEntityBackupService = this;
  }

  public setLongTermBackupInterval(minutes: number) {
    clearInterval(this.longTermTimer);
    this.longTermInterval = minutes;
    this.longTermTimer = setInterval(_ => this.doLongTermBackup(), this.longTermInterval * 60000);
  }

  private identifier(entity: WarpEntity) {
    return entity && entity.offlineGuid;
  }

  // These don't reference `this`
  // so they're fine to be bound to the class like this
  private index = index;
  private group = group;
  private insertTime = insertTime;

  private cutoffDate(days: number) {
    return (new Date()).getTime() - (86400000 * days); // ms in a day * days
  }

  doLongTermBackup() {
    //TODO: on startup, lastLongTermBackup is null, so we arent moving the initial state of everything to longTerm
    //so we might need to do something fancier here,
    //or just accept that long term is skipping things changed within 10 minutes of a crash

    // dont waste time if none were touched
    if (!this.needLongTermBackup) return;

    const thisBackup = (new Date()).getTime();
    // get all that are touched more recently than the last long term backup, and copy into safer spot
    const index = this.index(''); // looks like '_index';
    const numUpdates = Object.keys(localStorage)
      .reduce( (num, key: string) => {
        if (key.endsWith(index)) {
          const id = key.substring(0, key.length - index.length);
          if (this.tryCopyToLongTerm(id))
            num++;
        }
        return num;
      }, 0);

    this.log(`Checked for long term backups since ${new Date(this.lastLongTermBackup)}, performed ${numUpdates}.`);
    this.lastLongTermBackup = thisBackup;
    this.needLongTermBackup = false;
  }

  //#region Store data
  public storeEntity(entity: WarpEntity, encrypted = true) {
    const start = performance.now();

    if (!entity)
      return;

    const identifier = this.identifier(entity);

    if (!this._keySet) {
      this.getKeyFromServer();
      this.log(`Cannot back up entity, Key could not be retrieved from server.`, identifier);
      return;
    }

    const index = this.index(identifier);
    let nextIndex = Number(localStorage.getItem(index));
    // cycle index between 0 and maxHistory
    if (!nextIndex || nextIndex >= this.maxHistory) // nextIndex => (1, maxHistory + 1]
      nextIndex = 0;

    const encryptedObj = this.encrypt(entity);
    const toStore = [
      // store big stuff first
      { i: this.group(identifier, nextIndex), d: JSON.stringify(encryptedObj) },
      { i: index, d: (nextIndex + 1).toString() },
      { i: this.insertTime(identifier), d: (new Date()).toISOString() },
    ];

    this.tryStore(toStore, false, 4).then( (success: boolean) => {
      const end = performance.now();
      this.log(`backing up ${encrypted ? 'encrypted' : 'unencrypted'} entity in ${end - start} ms - ${success ? 'success' : 'failed'}`,
        entity.toString(), { start, end });
    });
  }

  tryStore(data: { i: string, d: string }[], testFirst: boolean, attempts: number = 4, longTerm = false) {
    return new Promise(resolve => {
      if (attempts <= 0)
        return resolve(false);
      else if (attempts <= 1) {
        // if this is the last attempt, make sure it wil work
        // alert('Auto Save Service: Local storage has run out of space.\nA backup file will be downloaded shortly, which you can send to technical support if you find you\'ve lost any data from the last 30 days.\nYou may continue working normally.');
        this.saveLocalStorage();
        localStorage.clear();
      }

      { /* test first (unstable browser compatibility) */
        // if (testFirst && this.browserSupportsEstimate)
        //   return navigator.storage.estimate().then(e => {
        //     const available = e.quota - e.usage;
        //     const needed = data.reduce( (acc, item) => acc + item.d.length + item.i.length, 0);
        //     if (available < needed) {
        //       this.clearOldest(needed - available);
        //       attempts--;
        //     }
        //     resolve(this.tryStore(data, false, attempts, longTerm));
        //   });
      }

      try {
        while (data.length > 0) {
          const element = data[0];
          localStorage.setItem(element.i, element.d);
          if (!longTerm)
            this.needLongTermBackup = true;
          data.shift();
        }
        return resolve(true);
      } catch (e) {
        if (
            e.message === 'Storage Full' || /* Manual */
            e.name === 'QuotaExceededError' || /* not Firefox */
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED' /* Firefox*/
          ) {
            this.clearOldest();
            return resolve(this.tryStore(data, false, attempts - 1, longTerm));
          }
        }
    });
  }

  tryCopyToLongTerm(id) {
    const key = this.index(id);
    const time = new Date(localStorage.getItem(this.insertTime(id))).getTime();
    if (time > this.lastLongTermBackup) {
      // cycle index between 0 and maxLongTermHistory
      const currIndex = (Number(localStorage.getItem(key)) + this.maxHistory - 1) % this.maxHistory;
      let nextIndexLT = Number(localStorage.getItem(key + this.longTermSuffix));
      if (!nextIndexLT || nextIndexLT >= this.maxLongTermHistory) // nextIndexLT => (1, maxHistory + 1]
        nextIndexLT = 0;

      const indxKey = key;
      const timeKey = this.insertTime(id);
      const dataKey = this.group(id, nextIndexLT);
      this.tryStore([
        { i: dataKey + this.longTermSuffix, d: localStorage.getItem(this.group(id, currIndex)) },
        { i: indxKey + this.longTermSuffix, d: (nextIndexLT + 1).toString() },
        { i: timeKey + this.longTermSuffix, d: localStorage.getItem(timeKey) },
      ], this.browserSupportsEstimate, 3, true)
      .then(success => success && this.clearShortTerm(id));
      return true;
    }
    return false;
  }
  //#endregion

  //#region Clear Data
  public clearAfterSave(entity: WarpEntity, encrypted: boolean) {
    const id = this.identifier(entity);
    const info = this.getInfoByKey(id);
    // clear history for this ent, except for most recent long term history
    this.clearShortTerm(id);
    this.clearOldestForEntity(info, true);

    // then regular save
    this.storeEntity(entity, encrypted);
  }

  private clearShortTerm(identifier) {
    localStorage.removeItem(this.index(identifier));
    localStorage.removeItem(this.insertTime(identifier));
    Array.from({ length: this.maxHistory })
      .forEach((_, i) => localStorage.removeItem(this.group(identifier, i)));
  }

  private clearAllOldest(bytes: number = -1) {
    this.log('clearing old Data, all oldest backups.');

    const index = this.index(''); // looks like '_index'
    const longTermTrimDate = this.cutoffDate(this.trimLongTermAfter);
    const longTermClearDate = this.cutoffDate(this.clearLongTermAfter);

    for (const key of Object.keys(localStorage))
      if (key.endsWith(index)) {
        const item = this.getInfoByKey(key, index);

        const wayTooOld = item.time < longTermClearDate;
        if (wayTooOld) {
          this.clearOldestForEntity(item, false, 0);
          this.clearOldestForEntity(item, true, 0);
        } else {
          const tooOld = item.time < longTermTrimDate;
          this.clearOldestForEntity(item, false, tooOld ? 0 : 2);
          if (tooOld)
            this.clearOldestForEntity(item, true, 1);
        }
      }
  }

  /**
   * removes 75% of all entities oldest backups, prioritizing last touched (oldest). \
   * * If something is older than `this.trimLongTermAfter` (30 days), all short term snapshots are deleted,
   * and a single (latest) longTerm snapshot is left.
   * * Iff something is older than `this.clearLongTermAfter` (180 days), all snapshots are deleted
   * * Otherwise (it is newer than 30 days), 1 long term and 2 short term snapshots are left
   * * __If something is in the newest proportion of all entities (by last touched), then it is not affected at all (all snapshots remain)__
   * @param proportion the amount of entities to include in the clear, float between 0 and 1, default 0.75
   */
  public clearOldest(proportion: number = 0.75) {
    proportion = Math.max(Math.min(proportion, 1), 0.25); // between 0.25 and 1
    if (proportion === 1)
      return this.clearAllOldest();

    this.log(`clearing old Data, ${proportion * 100}% of oldest backups.`);

    const longTermTrimDate = this.cutoffDate(this.trimLongTermAfter);
    const longTermClearDate = this.cutoffDate(this.clearLongTermAfter);

    const index = this.index(''); // looks like '_index';
    const storedEntities = Object.keys(localStorage)
      .reduce( (list: (InfoItem[]), key: string) => {
        if (key.endsWith(index))
          list.push(this.getInfoByKey(key, index));
        return list;
      }, [])
      .sort((a, b) => a.time - b.time); // ascending

    storedEntities.slice(0, Math.round(proportion * (storedEntities.length - 1))).forEach( item => {
      const wayTooOld = item.time < longTermClearDate;
      if (wayTooOld) {
        this.clearOldestForEntity(item, false, 0);
        this.clearOldestForEntity(item, true, 0);
      } else {
        const tooOld = item.time < longTermTrimDate;
        this.clearOldestForEntity(item, false, tooOld ? 0 : 2);
        if (tooOld)
          this.clearOldestForEntity(item, true, 1);
      }
    });
  }

  private clearOldestForEntity(item: InfoItem, longTerm: boolean, toKeep = 1) {
    // tslint:disable-next-line: one-variable-per-declaration
    let deleteNum, toDelete, suffix;
    if (longTerm) {
      deleteNum = this.maxLongTermHistory - toKeep;
      toDelete = (Number(item.nextLT) % this.maxLongTermHistory);
      suffix = this.longTermSuffix;
    } else {
      deleteNum = this.maxHistory - toKeep;
      toDelete = (Number(item.next) % this.maxHistory);
      suffix = '';
    }
    // count from next, and loop back to 1 less than current (2 less than next)
    for (let i = 0; i < deleteNum; i++) {
      localStorage.removeItem(`${item.id}_${toDelete}` + suffix);
      toDelete = (toDelete + 1) % this.maxHistory;
    }

    if (toKeep < 1) {
      // if no data left, clear record
      localStorage.removeItem(this.index(item.id) + suffix);
      localStorage.removeItem(this.insertTime(item.id) + suffix);
    }
  }
  //#endregion

  //#region Encryption
  private encrypt(_entity: WarpEntity) {
    const aesPassword = Guid.create().toString();
    const password = this.crypto.encrypt(aesPassword);
    // TODO: add this user's id to this entity, so server can decide whether to respond for future decrypt
    const data = aes.encrypt(JSON.stringify(_entity, _entity.getJSONReplacer()), aesPassword).toString();
    return { password, data, storedAt: (new Date()).toISOString() };
  }

  // key is the server-side private key
  public decrypt(password: string, data: string, privateKey: string) {
    try {
      return new Decryptor(JSEncrypt).decrypt(password, data, privateKey);
    } catch(e) {
      this.log('ERROR: Could not decrypt data, check that the private key is in the right format');
    }
  }

  public organizeLocalStorage(jsonLocalStorage: string | IGenericObject, privateKey?: string): EncryptedEntityHistory[] {
    return new Decryptor(JSEncrypt).decryptBackupFile(jsonLocalStorage, privateKey);
    // get names like this:
    // .flatMap(h => h.history.map( s => s.unencryptedEntity.name).concat(h.longTermHistory.map( s => s.unencryptedEntity.name)) )
    // get objects like this
    // .flatMap(h => h.history.map( s => s.unencryptedEntity).concat(h.longTermHistory.map( s => s.unencryptedEntity)) )
  }

  private getKeyFromServer() {
    this.http
      .get<{ key: string }>(`${environment.restEndpointUrl}/api/encryption/publicKey`)
      .pipe(first())
      .subscribe((data) => {
        this.log('Loaded Public Key', data);
        this.crypto.setKey(data.key);
        this._keySet = true;
      },
      (err) => this.log('Could Not Load Public Key', err));
  }
  //#endregion

  private getInfoByKey(key: string, keySuffix: string = null): InfoItem {
    if (keySuffix === null) {
      key = this.index(key);
      keySuffix = this.index('');
    }

    const id = key.substring(0, key.length - keySuffix.length);
    const index = this.index(id);
    const time = new Date(localStorage.getItem(this.insertTime(id))).getTime();
    const timeLT = new Date(localStorage.getItem(this.insertTime(id) + this.longTermSuffix)).getTime();
    return {
      id, time, timeLT,
      next: localStorage.getItem(index),
      nextLT: localStorage.getItem(index + this.longTermSuffix)
    };
  }

  saveLocalStorage() {
    this.file.dumpToFile(`localStorage${(new Date().toUTCString())}.bckp`, JSON.stringify(localStorage));
  }

  log(...args) {
    this.messageService.add('Local Backup Service', ...args);
  }
}
