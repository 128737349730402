import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ripple-attrib',
  templateUrl: './attrib.component.html',
  styleUrls: ['./attrib.component.scss']
})
export class AttribComponent implements OnInit {

  @Input() showIcon: boolean = true;
  @Input() iconClass: string = 'pi pi-tag';
  @Input() label: string;
  @Input() value: string;
  @Input() subText: string;
  @Input() link: string;
  @Input() fieldBgColor: string;

  @Input() actionButtonIconClass: string;
  @Output() actionButtonClick: EventEmitter<Event> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onActionButtonClick() {
    return this.actionButtonClick.emit();
  }

}
