import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache } from '@ripple/services';
import { ChannelMember, EntityTypes } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ChannelMemberService extends WarpEntityServiceCache<ChannelMember> {

  constructor(injector: Injector) {
    super(injector, ChannelMember, EntityTypes.ChannelMember);
  }

  checkChannelMemberExist(channelId, teamContactId) {
    return this.warpService
      ._get(`/api/sagesse/checkChannelMemberExist/${channelId}/${teamContactId}`, 'check channel Member Exist');
  }
}
