import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService {
  /** lowercase names of currently pressed keys */
  currentKeys = new Set<string>();

  shiftKey = false;
  ctrlKey = false;
  altKey = false;

  constructor() {
    // hack to grab the current modifier keys, in the rare case that they are pressed while the app is being loaded
    document.addEventListener('mousemove', (...e) => console.log('temp3', ...e), { once: true });

    document.addEventListener('keydown', (e: KeyboardEvent) => {
      this.shiftKey = e.shiftKey;
      this.ctrlKey = e.ctrlKey;
      this.altKey = e.altKey;

      this.currentKeys.add(e.key.toLowerCase());
    });

    document.addEventListener('keyup', (e: KeyboardEvent) => {
      this.shiftKey = e.shiftKey;
      this.ctrlKey = e.ctrlKey;
      this.altKey = e.altKey;

      this.currentKeys.delete(e.key.toLowerCase());
    });
  }

}
