import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePage } from '@ripple/core';
import { CookieType, InternalCookieService, LayoutSettingsService, WarpEntityLogService, WarpEntityCacheFactoryService } from '@ripple/services';
import { first } from 'rxjs/operators';
import { ClaresLaw, EntityTypes } from '../../_core/models';
import { ClaresLawService } from '../../_core/services';
import { environment } from '@ripple/environment';
import { Message } from 'primeng/api';

@Component({
  selector: 'sagesse-clares-law-info',
  templateUrl: './clares-law-info.component.html',
  styleUrls: ['./clares-law-info.component.scss']
})
export class ClaresLawInfoComponent extends BasePage implements OnInit {

  claresLaw: ClaresLaw;
  loading = true;
  infoTable = '';
  filledFormId = '';
  formLink = '';
  token = '';
  hasPermission = true;
  msgs: Message[] = [{severity: 'error', summary: 'You do not have permission to view this Clare\'s Law. ' }];

  claresLawService: ClaresLawService;

  constructor(
    private logService: WarpEntityLogService,
    private layoutSettingsService: LayoutSettingsService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: InternalCookieService,
    private serviceFactory: WarpEntityCacheFactoryService,
  ) {
    super(logService);
    this.claresLawService = this.serviceFactory.get(EntityTypes.ClaresLaw) as ClaresLawService;
  }

  ngOnInit(): void {
    const layoutSettings = this.layoutSettingsService.getSettings();
    layoutSettings.showMenuBarNotifications = false;
    layoutSettings.showMenuBarPrimaryMenu = false;
    layoutSettings.showMenuBarProfileHelpMenu = false;
    layoutSettings.showFooterBar = false;
    layoutSettings['showClaresLawReferralBar'] = false;
    this.layoutSettingsService.setSettings(layoutSettings);
    this.route.params.subscribe((params) => {
      if (params.handoffToken) {
        this.token = params.handoffToken;
        this.cookieService.setCookie(CookieType.UserToken, params.handoffToken);
      }
      if (params.filledFormId) {
        this.filledFormId = params.filledFormId;
      }
      const claresLawId = parseInt(params.claresLawId, 10);
      if (!isNaN(claresLawId)) this.loadClaresLaw(claresLawId);
    });
  }

  loadClaresLaw(id) {
    this.claresLawService.checkExisting(id).then(result => {
      if (!result) this.hasPermission = false;
      else {
        this.claresLawService
          .get(id)
          .pipe(first())
          .subscribe((claresLaw) => {
            this.claresLaw = claresLaw;
            this.token = this.claresLaw.handofftoken;
            this.infoTable = this.claresLaw.infoTable();
            if (this.claresLaw.forms && this.claresLaw.forms.length > 0) {
              const resultForm = this.claresLaw.forms[0];
              this.filledFormId = resultForm.entityId;
            }
            this.formLink = `${environment.host}/form/${this.token}/${this.filledFormId}`;
            // this.claresLaw.cfcProperty('viewed', 333);
            // this.claresLawService.update(this.claresLaw).toPromise().then();
            this.claresLawService.updateClaresLawInfoViewed(this.claresLaw.entityId, 333).toPromise().then(cl => {
              console.log('update clares law entity as viewed', cl);
              this.claresLaw = cl;
              this.loading = false;
            });
          });
      }
      
    }).catch(error => {
      this.hasPermission = false;
    });
    
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  goToExternalLink(link) {
    window.open(link, '_blank');
  }

}
