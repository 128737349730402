import { Component, Input, OnInit } from '@angular/core';
import { EntityTypes, StrategicOutcome, StrategicOutcomeSubCategory } from '../../_core/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions, SubscriptionContainer } from '@ripple/models';
import { MenuItem } from 'primeng/api';


@Component({
  selector: 'sagesse-strategic-outcome',
  templateUrl: './strategic-outcome.component.html',
  styleUrls: ['./strategic-outcome.component.scss']
})
export class StrategicOutcomeComponent extends SubscriptionContainer implements OnInit {
  @Input() outcome: StrategicOutcome;
  showAddOutcomeFlag: boolean = false;
  strategicOutcomeService: WarpEntityServiceCache<StrategicOutcome>;
  strategicOutcomeSubCatService: WarpEntityServiceCache<StrategicOutcomeSubCategory>;
  showModalFlag = false;
  adding: boolean;
  subcategory: StrategicOutcomeSubCategory;
  toEdit: any;
  modalText: string = "";
  action: string = "";
  loading: boolean = false;
  subcategories: Array<StrategicOutcomeSubCategory>;


  generatorOptionsSubCat: IGenFormlyOptions = {
    hiddenCondition:
      (cfim: CustomFieldInModule) =>
        cfim.key.toLowerCase() === 'linkedstrategicoutcome',
    styles: {
      name: 'p-sm-12 p-md-12 p-lg-12 ui-fluid',
    }
  };

  generatorOptionsOutcome: IGenFormlyOptions = {
    // hiddenCondition:
    //   (cfim: CustomFieldInModule) =>
    //     cfim.key.toLowerCase() === 'linkedstrategicoutcome',
    styles: {
      name: 'p-sm-12 p-md-12 p-lg-12 ui-fluid',
    }
  };

  generatorOptions: IGenFormlyOptions;



  constructor(
    private factory: WarpEntityCacheFactoryService,
  ) {
    super();
    this.strategicOutcomeService = factory.get(EntityTypes.StrategicOutcome) as WarpEntityServiceCache<StrategicOutcome>;
    this.strategicOutcomeSubCatService = factory.get(EntityTypes.StrategicOutcomeSubCategory) as WarpEntityServiceCache<StrategicOutcomeSubCategory>;
  }

  ngOnInit(): void {
    const filter = EntityFilter.Advanced({ LinkedStrategicOutcome_lid: this.outcome.id });
    this.nextSub = this.strategicOutcomeSubCatService.initQuery(filter, 9999999).getPage(0).subscribe(resp => {
      this.subcategories = resp;
      console.log(resp);
    });

    this.outcome.actionMenu = new Array<MenuItem>();
    this.outcome.actionMenu.push({
      label: 'Delete',
      icon: 'pi pi-times',
      command: () => this.delete(this.outcome),
    });

  }


  showModal(action: string, text, toEditStat = null) {
    console.log(action);
    switch (action) {
      case 'addsubcat':
        this.toEdit = StrategicOutcomeSubCategory.empty().linkedProperty("linkedstrategicoutcome", this.outcome);
        this.generatorOptions = this.generatorOptionsSubCat;
        break;
      case 'editoutcome':
        this.toEdit = this.outcome;
        this.action = 'editoutcome'
        this.generatorOptions = this.generatorOptionsOutcome;
        break;
    }
    this.modalText = text;
    this.showModalFlag = true;
  }

  addOutcome() {
    this.adding = true;
    if (this.action == 'editoutcome') {
      this.strategicOutcomeService.update(this.toEdit).toPromise().then((entId) => {
        this.adding = false;
        this.toEdit = null;
        this.showModalFlag = false;
      });
    } else {
      this.strategicOutcomeSubCatService.create(this.toEdit).toPromise().then((entId) => {
        this.adding = false;
        this.toEdit = null;
        this.showModalFlag = false;
      });
    }
  }

  delete(entity) {
    entity.deleting = true;
    this.strategicOutcomeService.delete(entity).then(resp => {

    });
  }

}
