<div [ngClass]="to.enableAdditionalButton ? 'header' : ''">
  <div [ngClass]="to.enableAdditionalButton ? 'header__heading' : ''">
    <ng-container *ngIf="multi; then multiSelect else singleSelect"></ng-container>

    <ng-container *ngFor="let option of formControl.value || []">
      <ripple-form-specify-text
        *ngIf="option"
        [option]="option"
        [viewOnly]="viewOnly"
        [hidden]="false"
        [showOptionName]="multi && formControl.value.length > 1"
        (optionChanged)="specifyTextChange($event.$event, $event.option)"
      ></ripple-form-specify-text>
    </ng-container>
  </div>

  <div [ngClass]="to.enableAdditionalButton ? 'header__actions' : ''">
    <button [type]="to.type"
            pButton
            [ngClass]="to.class"
            icon="{{to.icon}}"
            iconPos="{{to.iconPos}}"
            (click)="buttonOnClick($event)"
            label="{{to.buttonText}}"
            [style]="to.style"
            *ngIf="to.enableAdditionalButton"
    ></button>
  </div>
</div>

<ng-template #multiSelect>
  <div [ngClass]="{z: showOverlay}">
    <p-multiSelect
      appendTo='body'
      [baseZIndex]="99999999"
      optionLabel="optionName"
      filter="true"
      dataKey="id"
      [options]="selectBoxOptions$ | async"
      [formControl]="formControlMulti"
      [formlyAttributes]="field"
      [readonly]="viewOnly"
      (onFocus)="setOverlay(true);"
      (onPanelShow)="setOverlay(true);"
      (onPanelHide)="setOverlay(false);"
    ></p-multiSelect>
  </div>
</ng-template>

<ng-template #singleSelect>
  <div [ngClass]="{z: showOverlay}">
    <p-dropdown
      appendTo='body'
      [baseZIndex]="99999999"
      optionLabel="optionName"
      filter="true"
      dataKey="id"
      [options]="selectBoxOptions$ | async"
      [placeholder]="to.placeholder"
      [formControl]="formControlSingle"
      [formlyAttributes]="field"
      [readonly]="viewOnly"
      [showClear]="true"
      (onHide)="setOverlay(false); tryEmitOnChange(); formControl.markAsTouched();"
      (onShow)="setOverlay(true);"
    ></p-dropdown>
  </div>
</ng-template>
