import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, AuthService } from '@ripple/services';
import { EntityTypes, Program, StatisticGroup, Statistic, FilledStatistic } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { first, map, switchMap, startWith, tap, distinctUntilChanged } from 'rxjs/operators';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { AgencyStatsService } from '../../_core/services';

@Component({
  selector: 'sagesse-manage-program-stats',
  templateUrl: './manage-program-stats.component.html',
  styleUrls: ['./manage-program-stats.component.scss']
})
export class ManageProgramStatsComponent extends BasePage implements OnInit {
  programService: WarpEntityServiceCache<Program>;
  statisticGroupService: WarpEntityServiceCache<StatisticGroup>;
  statisticService: WarpEntityServiceCache<Statistic>;
  filledStatisticService: WarpEntityServiceCache<FilledStatistic>;

  userStats: Array<Statistic> = [];
  userGroups: Array<StatisticGroup> = [];
  programStats: any[] = null;
  saving = false;
  loading = false;

  // Grab the month and year on load
  month: number = new Date().getMonth() + 1;
  year: number = new Date().getFullYear();

  statsToUpdate: Array<FilledStatistic> = [];

  constructor(
    private logService: WarpEntityLogService,
    private authService: AuthService,
    private agencyService: AgencyStatsService,
    private factory: WarpEntityCacheFactoryService,
  ) {
    super(logService);
    this.programService = factory.get(EntityTypes.Program) as WarpEntityServiceCache<Program>;
    this.statisticGroupService = factory.get(EntityTypes.StatisticGroup) as WarpEntityServiceCache<StatisticGroup>;
    this.statisticService = factory.get(EntityTypes.Statistic) as WarpEntityServiceCache<Statistic>;
    this.filledStatisticService = factory.get(EntityTypes.FilledStatistic) as WarpEntityServiceCache<FilledStatistic>;
  }

  ngOnInit(): void {
    this.getOrCreateStats();
  }

  getOrCreateStats() {
    this.nextSub = this.agencyService.getOrCreateStatsForDateRange(this.month, this.year).subscribe((resp: any) => {
      this.programStats = resp;
      console.log("Agency Stats Programs", this.programStats);
      this.programStats.sort((a,b) => {
        if (a.program.text > b.program.text)
          return 1;
        else
          return -1;
      });
      this.loading = false;
      console.log("Agency Stats Programs Sorted", this.programStats);
    });
  }

  save() {
    this.saving = true;
    this.statsToUpdate.forEach(stat => {
      this.filledStatisticService.update(stat).toPromise().then(resp => {
        this.saving = false;
      });
    });

  }

  statsUpdated(stat) {
    if (!this.statsToUpdate.includes(stat)) {
      this.statsToUpdate.push(stat);
    }
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  monthChanged(event, group) {
    this.loading = true;
    this.month = event.month;
    this.year = event.year;
    this.getOrCreateStats();
  }


}
