import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class FilledStatistic extends WarpEntity {
  id: string | number;
  edited: boolean;
  targetInformation:any;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.FilledStatistic;
  }

  static empty(): FilledStatistic {
    return new FilledStatistic(super.emptyEntity(EntityTypes.FilledStatistic));
  }

}
