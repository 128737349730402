import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EntityFilter, SubscriptionContainer } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { ChannelMember, EntityTypes, TeamContact } from '../../../../_core/models';

@Component({
  selector: 'sagesse-channel-members',
  templateUrl: './channel-members.component.html',
  styleUrls: ['./channel-members.component.scss']
})
export class ChannelMembersComponent extends SubscriptionContainer implements OnInit, OnChanges {

  @Input() teamContact: TeamContact;
  @Input() teamContactId: number;
  filter = EntityFilter.None;
  channelMemberService: WarpEntityServiceCache<ChannelMember>;
  channelMembers: ChannelMember[] = [];
  loading = false;

  constructor(
    private serviceFactory: WarpEntityCacheFactoryService,
  ) {
    super();
    this.channelMemberService = this.serviceFactory.get(EntityTypes.ChannelMember) as WarpEntityServiceCache<ChannelMember>;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.teamContact) {
     this.teamContactId = this.teamContact.entityId;
    }

    this.loading = true;
    this.filter = EntityFilter.Advanced({ teamcontact_lid: this.teamContactId });
    this.nextSub = this.channelMemberService.initQuery(this.filter, 9999999).getPage(0).subscribe(channelMembers => {
      this.channelMembers = channelMembers;
      this.loading = false;
    });
    
  }

  ngOnInit(): void {
  }

}
