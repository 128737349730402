<div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading" *ngIf="programCategory != null "> {{programCategory.label}} Report </h1>
      <div class="header__actions">
        <div class="ui-fluid p-sm-12 p-md-12">
          <label>Report Date Range</label>
          
          <p-calendar [(ngModel)]="dateRange" view="month" dateFormat="mm/yy" selectionMode="range"
            [readonlyInput]="true" inputId="range" class="heading-action-row__heading" (onClose)="changedDatePicker()">
          </p-calendar>

        </div>
      </div>
    </div>
  </div>
  <div class="pane__body">
    <p-progressSpinner *ngIf="loading"> </p-progressSpinner>

    <div *ngIf="!loading">
      <div class="mb-2">
        <button pButton (click)="exportReport()" icon="pi pi-file-excel" label="Export To Excel"></button>
      </div>

      <p-table [value]="reportData" [columns]="cols" [scrollable]="true">
        <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col style="width: 160px;">
            <col *ngFor="let col of cols" style="width:85px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 160px">
              Name
            </th>
            <th *ngFor="let col of cols">
              {{col.header}}
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-category>
          <tr>
            <td  [attr.colspan]="cols.length + 1" class="category">
              {{category.category}}
            </td>
          </tr>
          <ng-container *ngFor="let stat of category.stats">
            <tr>
                <td style="width:160px !important">
                  {{stat.name}}
                </td>
              <td *ngFor="let col of cols">
                {{ (stat.years[col.year] && stat.years[col.year][col.month]) ? stat.years[col.year][col.month].displayedValue : '' }}
              </td>
            </tr>
          </ng-container>

        </ng-template>

      </p-table>
    </div>
  </div>
</div>
