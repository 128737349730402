import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { EntityFilter, SubscriptionContainer, WarpEntity } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { Community, CommunityGroup, EntityTypes } from '../../_core/models';

declare var google: any;

@Component({
  selector: 'sagesse-communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss']
})
export class CommunitiesComponent extends SubscriptionContainer implements OnInit {

  //@Output() communitiesDoneLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() entity: WarpEntity;
  @Input() multiSelect = true;
  communityService: WarpEntityServiceCache<Community>;
  communityGroupService: WarpEntityServiceCache<CommunityGroup>;
  communities: Community[] = [];
  communityGroups: CommunityGroup[] = [];
  communityGroupsSelected: CommunityGroup[] = [];
  communitiesFiltered: Community[] = [];
  communityGroupsFiltered: CommunityGroup[] = [];
  communitySearch = '';
  communityGroupSearch = '';
  filterTimeout;
  groupFilterTimeout;
  
  // map
  gmapOptions = {
    center: {lat: 53.5559165, lng: -113.632804},
    zoom: 5
  };
  gmapOverlays: any[];
  polygons: any[] = [];
  map: any;

  loading = true;

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService
  ) {
    super();
    this.communityService = this.warpEntityFactory.get(EntityTypes.Community) as WarpEntityServiceCache<Community>;
    this.communityGroupService = this.warpEntityFactory.get(EntityTypes.CommunityGroup) as WarpEntityServiceCache<CommunityGroup>;
  }

  ngOnInit(): void {
    const filter = EntityFilter.None.orderBy('name', 'asc');
    this.nextSub = this.communityService.initQuery(filter, 9999999).getPage(0).subscribe(communities => {
      this.communities = communities;
      this.communitiesFiltered = communities;
      if (this.multiSelect) {
        this.filterCommunities();
      }
      
      this.updateReference(true);

      if (this.multiSelect) {
        this.initCommunityGroups();
      }
     
      if (this.communities && this.multiSelect) {
        //this.gmapOverlays = this.communities.map(c => this.commnuityEntityToMapMarker(c, this.ifCommunitySelected(c)));
        this.gmapOverlays = this.communities.filter(c => this.ifCommunitySelected(c)).map(c => this.commnuityEntityToMapMarker(c, false));
      }
      this.loading = false;
      
      //this.communitiesDoneLoading.emit(true);
    });

    if (this.multiSelect) {
      this.nextSub = this.communityGroupService.initQuery(filter).getPage(0).subscribe(communityGroups => {
        this.communityGroups = communityGroups;
        this.communityGroupsFiltered = communityGroups;
        this.initCommunityGroups();
        this.filterCommunityGroups();
      });
    }
    
  }

  initCommunityGroups() {
    this.communityGroupsSelected = [];
    if (this.communityGroups.length === 0 || this.communities.length === 0) return;
    // province wide special
    const provinceWideGroup = this.communityGroups.find(cg => cg.name.toLowerCase() === 'province wide');
    if (provinceWideGroup) {
      for (const community of this.communities) {
        if(community.properties.communitygroup) community.properties.communitygroup.push(provinceWideGroup);
        else community.properties.communitygroup = [provinceWideGroup];
      }
    }
    for (const communityGroup of this.communityGroups) {
      const communitiesInGroupIdStr = this.communities
        .filter(c => c.properties.communitygroup && c.properties.communitygroup.map(cg => cg.id).includes(communityGroup.id))
        .map(c => c.entityId)
        .sort()
        .join(',');
      const communitiesInGroupSelectedIdStr = this.entity.properties.communities ?
        this.entity.properties.communities
          .filter(c => c.properties.communitygroup && c.properties.communitygroup.map(cg => cg.id).includes(communityGroup.id))
          .map(c => c.entityId)
          .sort()
          .join(',')
        : '';
      if (communitiesInGroupIdStr && communitiesInGroupIdStr === communitiesInGroupSelectedIdStr)
        this.communityGroupsSelected = [...this.communityGroupsSelected, communityGroup];
    }
    this.sortCommunityGroups();
    
  }

  updateReference(removeNotExist = false) {
    if (this.entity.properties.communities)

      if (this.entity.properties.communities instanceof Array) {
        this.entity.properties.communities = this.entity.properties.communities
          .map(c => this.communities.find(co => co.entityId === c.id) ? this.communities.find(co => co.entityId === c.id) : c);
        if (removeNotExist)
          this.entity.properties.communities = this.entity.properties.communities.filter(c => c instanceof Community);
      } else {
        if (this.communities.find(co => co.entityId === this.entity.properties.communities.id))
          this.entity.properties.communities = this.communities.find(co => co.entityId === this.entity.properties.communities.id);
        else if (removeNotExist)
          this.entity.properties.communities = null;
      }
  }

  ifCommunitySelected(community) {
    return this.entity.properties.communities &&
      (this.entity.properties.communities instanceof Array && this.entity.properties.communities.map(c => c.id).includes(community.entityId) ||
        !(this.entity.properties.communities instanceof Array) && this.entity.properties.communities.id === community.entityId);
  }

  commnuityEntityToMapMarker = (community: Community, selected = false) => {
    const marker = new google.maps.Marker(
      {
        position: {
          lat: community.latitude,
          lng: community.longitude
        },
        title: community.name,
        selected,
        id: community.entityId,
        icon: selected ? 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png' : 
          {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: '#60418f',
            fillOpacity: 0.6,
            strokeColor: '#00A',
            strokeOpacity: 0.9,
            strokeWeight: 1,
            scale: 2.1
          }
      }
    );
    marker.addListener("click", (event) => {
      
      const communitySelected = this.communities.find(c => c.latitude === event.latLng.lat() && c.longitude === event.latLng.lng());
      this.updateMarkersSelected(communitySelected);

    });
    return marker;
  }

  onMapReady(event) {
    this.map = event.map;
    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      markerOptions: {
        icon:
          "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
      },
      circleOptions: {
        fillColor: "#ffff00",
        fillOpacity: 1,
        strokeWeight: 5,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });
    drawingManager.setMap(this.map);
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        this.polygons.push(event);
        this.checkIfMarkerInBound(event.overlay.getPath().getArray());
      }
    });
  }

  checkIfMarkerInBound (path: any[]) {
    let bound = new google.maps.LatLngBounds();
    path.forEach(point => {
      bound.extend(point);
    });
    for (const c of this.communities) {
      if (bound.contains(new google.maps.LatLng(c.latitude, c.longitude)) &&
        !this.ifCommunitySelected(c)) {
        this.updateMarkersSelected(c);
      }
    }
  }

  deletePolygon() {
    this.polygons.forEach(x => {
      x.overlay.setMap(null);
    });
    this.polygons = [];
  }

  updateMarkersSelected(community: Community, updateCheckbox = true, updateGroup = true) {
    let selected = !this.ifCommunitySelected(community);
    if (!updateCheckbox) selected = this.ifCommunitySelected(community); // from onchange, value has already updated
  
    // update map
    const index = this.gmapOverlays.findIndex(m => m.id === community.entityId); // check reference
    if (index >= 0) {
      const newMarker = this.commnuityEntityToMapMarker(community, false);
      this.gmapOverlays[index] = newMarker;
    }
    else
      this.gmapOverlays.push(this.commnuityEntityToMapMarker(community, false));

 

    // update checkbox
    if (updateCheckbox) {
      if (selected) {
        if (this.entity.properties.communities && !this.entity.properties.communities.map(c => c.id).includes(community.entityId))
          this.entity.properties.communities = [...this.entity.properties.communities, community]; 
        else if (!this.entity.properties.communities)
          this.entity.properties.communities = [community];
      } else {
        this.entity.properties.communities = this.entity.properties.communities.filter(c => c.id !== community.entityId);
      }
    }

    // update groups
    if (updateGroup) {
      if (community.properties.communitygroup) {
        for (const communityGroup of community.properties.communitygroup) {
          const communitiesInGroupIdStr = this.communities
            .filter(c => c.properties.communitygroup && c.properties.communitygroup.map(cg => cg.id).includes(communityGroup.id))
            .map(c => c.entityId)
            .sort()
            .join(',');
          const communitiesInGroupSelectedIdStr = this.entity.properties.communities ?
            this.entity.properties.communities
              .filter(c => c.properties.communitygroup && c.properties.communitygroup.map(cg => cg.id).includes(communityGroup.id))
              .map(c => c.entityId)
              .sort()
              .join(',')
            : '';
          if (communitiesInGroupIdStr === communitiesInGroupSelectedIdStr) {
            const group = this.communityGroups.find(g => g.entityId === communityGroup.id);
            if (!this.communityGroupsSelected.map(c => c.entityId).includes(communityGroup.id)) {
              if (group) this.communityGroupsSelected = [...this.communityGroupsSelected, group];
            }
          } else {
            if (this.communityGroupsSelected.map(c => c.entityId).includes(communityGroup.id))
              this.communityGroupsSelected = this.communityGroupsSelected.filter(c => c.entityId !== communityGroup.id);
          }
        }
        
      }
    }
    this.sortCommunityGroups();
    
    this.sortCommunitites();
  }

  communityGroupSelect(communityGroup) {
    if (this.communityGroupsSelected.map(c => c.entityId).includes(communityGroup.entityId)) {
      // select
      const communitiesInGroup = this.communities
        .filter(c => c.properties.communitygroup && c.properties.communitygroup.map(cg => cg.id).includes(communityGroup.id));
      // remove communities of this group that are already selected
      if (this.entity.properties.communities)
        this.entity.properties.communities = this.entity.properties.communities
          .filter(c => !(c.properties.communitygroup && c.properties.communitygroup.map(cg => cg.id).includes(communityGroup.id)));
      for (const community of communitiesInGroup) {
        this.updateMarkersSelected(community, true, true);
      }
    } else {
      // unselect
      const communitiesRemoved = this.entity.properties.communities
        .filter(c => c.properties.communitygroup && c.properties.communitygroup.map(cg => cg.id).includes(communityGroup.id));
      for (const community of communitiesRemoved) {
        this.updateMarkersSelected(community, true, true);
      }
    }
    this.sortCommunityGroups();
  }

  filterCommunities(now: boolean = false) {
    if (this.filterTimeout) clearTimeout(this.filterTimeout);

    if (now) {
      this.communitiesFiltered = this.communities.filter(c => c.name.toLowerCase().includes(this.communitySearch.toLowerCase()));
      this.sortCommunitites();
    } else {
      const that = this;

      this.filterTimeout = setTimeout(
        () => {
          this.communitiesFiltered = this.communities.filter(c => c.name.toLowerCase().includes(this.communitySearch.toLowerCase()));
          this.sortCommunitites();
        },
        800
      );
    }
  }

  filterCommunityGroups(now: boolean = false) {
    if (this.groupFilterTimeout) clearTimeout(this.groupFilterTimeout);

    if (now) {
      this.communityGroupsFiltered = this.communityGroups.filter(c => c.name.toLowerCase().includes(this.communityGroupSearch.toLowerCase()));
      this.sortCommunityGroups();
    } else {
      const that = this;

      this.groupFilterTimeout = setTimeout(
        () => {
          this.communityGroupsFiltered = this.communityGroups.filter(c => c.name.toLowerCase().includes(this.communityGroupSearch.toLowerCase()));
          this.sortCommunityGroups();
        },
        800
      );
    }
  }

  sortCommunitites() {
    this.communitiesFiltered.sort((a, b) => {
      const aIsChecked = this.entity.properties.communities ? this.entity.properties.communities.findIndex(c => c.id === a.entityId) : -1;
      const bIsChecked = this.entity.properties.communities ? this.entity.properties.communities.findIndex(c => c.id === b.entityId) : -1;
      return bIsChecked - aIsChecked;
    });
  }

  sortCommunityGroups() {
    this.communityGroupsFiltered.sort((a, b) => {
      const aIsChecked = this.communityGroupsSelected ? this.communityGroupsSelected.findIndex(c => c.id === a.entityId) : -1;
      const bIsChecked = this.communityGroupsSelected ? this.communityGroupsSelected.findIndex(c => c.id === b.entityId) : -1;
      return bIsChecked - aIsChecked;
    });
  }

}
