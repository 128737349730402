import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache } from '@ripple/services';
import { Contact, EntityTypes, Team, TeamContact } from '../../models';
import { first, map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends WarpEntityServiceCache<Team> {

  constructor(injector: Injector) {
    super(injector, Team, EntityTypes.Team);
  }

  getAzureAdTeamSync(runTeam = '1', runChannel = '1') {
    return this.warpService
      ._get(`/api/sagesse/AzureAdTeamSync/${runTeam}/${runChannel}`, 'Azure Ad Team Sync');
  }

  getTeamReport(teamId) {
    return this.warpService
      ._get(`/api/sagesse/teamReport/${teamId}`, 'get Team Report');
      // .pipe(map(retVal => retVal.map(d => {
      //   d.teamContact = new TeamContact(d.teamContact);
      //   return d;
      // })));
  }
}
