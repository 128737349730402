import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { WarpEntity } from '@ripple/models';
import { environment } from '@ripple/environment';
import { AuthService } from '../general/auth.service';
import { SecurityPagesService } from '../general/security-pages.service';
import { IUserGuardConfig, USER_GUARD_CONFIG, RippleUserGuardDebugLevel } from '../injection-tokens/user-guard-config.token';

interface redirectOptions{
  redirectUrl?: string;
  redirect?: boolean;
  redirectBack?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RippleUserGuard implements CanActivate {
  private namespace: string = '[RippleUserGuard]';
  public static debugLogLevel: RippleUserGuardDebugLevel = RippleUserGuardDebugLevel.NONE;

  constructor(
    private authService: AuthService,
    private router: Router,
    private securityPagesService: SecurityPagesService,
    @Optional() @Inject(USER_GUARD_CONFIG) private config: IUserGuardConfig,
  ) {
    if (this.config?.userGuardDebugLevel)
      RippleUserGuard.debugLogLevel = this.config.userGuardDebugLevel || RippleUserGuardDebugLevel.NONE;
  }

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const options = {
      redirect: this.config?.redirect ?? true,            // Redirect to login page if not logged in
      redirectBack: this.config?.redirectBack ?? true,    // Come back after the user is logged in
      redirectUrl: this.config?.redirectUrl || state?.url || '/', // Return to this page after login
    };
    return new Promise<boolean>(async (resolve, _) => {
      this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'Starting...');
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, 'canActivate', 'next:', next);
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, 'canActivate', 'state:', state);
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, 'canActivate', 'options:', options);

      this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'Checking if user is on login page...');
      if (['/login', environment.loginPage].some(endpoint => endpoint.includes(state.url) || state.url.includes(endpoint))) {
        this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'User is on login page.');
        resolve(true);
        return true; // Allow access to the login page regardless of any other settings
      } else if ('/permissions-denied'.includes(state.url) || state.url.includes('/permissions-denied')) {
        this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'User is on permissions denied page.');
        resolve(true);
        return true; // Allow access to the permissions-denied page regardless of any other settings
      } else if ('/expired-handoff-link'.includes(state.url) || state.url.includes('/expired-handoff-link')) {
        this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'User is on expired handoff link page.');
        resolve(true);
        return true; // Allow access to the expired-handoff-link page regardless of any other settings
      }
      this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'User not on login page. Checking auth service...');
      const authServiceValidated = await this.authService.checkLogin(options);
      if (!authServiceValidated) {
        this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'AuthService failed validation.');
        resolve(false);
        return false;
      }
      this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'AuthService passed validation. Checking user roles...');
      const userRolesValidated = await this._checkUserRolesForHandoffRoles(state.url, options);
      if (!userRolesValidated) {
        this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'User roles failed validation.');
        resolve(false);
        return false;
      }
      this._debugLog(RippleUserGuardDebugLevel.INFO, 'canActivate', 'User roles passed validation. Allowing access.');
      resolve(true);
      return true;
    });
  }

  private async _checkUserRolesForHandoffRoles(url: string, options: {
    redirectUrl?: string;
    redirect?: boolean;
    redirectBack?: boolean;
  } = {
    redirectUrl: '/',
    redirect: true,
    redirectBack: true,
  }): Promise<boolean> {
    try {
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'url:', url);
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'options:', options);
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'config:', this.config);
      // When config is undefined, or there is no whitelists value, then allow access
      if (!this.config || !this.config?.whitelists || this.config?.whitelists?.default === null)
        return true;

      const user: WarpEntity = await this.authService.getLoggedInUser()
        .pipe(
          filter(u => !!u),
          take(1)
        ).toPromise();
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'Logged in User:', user);
      if (user === null)
        return false;

      // First, check if the user contains a security page that allows them through the guard explicitly
      // Security Pages need to work on an inheritance system, so if the user can access a parent node, they can access all children
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'Checking user security pages:', url, user);
      const canViewSecurityPage = this.securityPagesService.canAccessSecurityPage(url, user);
      if (canViewSecurityPage) {
        this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'User has security page that allows access.');
        return true;
      }

      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'Checking for CCASA Subscriber Edge Case for CSART.');
      // CSART Specific Edge Case for CCASA Subscriber
      if (
        user!.subscriberid === 120 &&
        user.roles.some(role => ['CSART Staff', 'ICM CSART Staff'].includes(role.name))
      ) {
        this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'CCASA Subscriber Edge Case for CSART.');
        // A CSART Staff is only allowed to access urls that start with /csart
        if (url.startsWith('/csart'))
          return true;
      } else {
        this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'Checking Default Whitelist for Entity Type ID.');
        // Check the entity type of the loaded user to see if they are allowed access
        if (!this.config.whitelists.default.entityTypeIDs?.includes(user.entityTypeId) && !Object.keys(this.config.whitelists).some(specialCase => url.startsWith(specialCase))){
          this.redirectToPermissionsDenied(url, options);
          return false;
        }


        // Check if the user contains a whitelisted role
        /*this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'Checking Default Whitelist for Security Role.');
        if (
          user['roles'] &&
          Array.isArray(this.config.whitelists.default.roles) &&
          this.config.whitelists.default.roles.length > 0 &&
          user.roles.some(role => this.config.whitelists.default.roles?.includes((typeof this.config.whitelists.default.roles[0] === 'string') ? role.name : role.ID))
        )
          return true;*/
      }

      // Now we need to check for any whitelists that are specific to the current route
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, '_checkUserRolesForHandoffRoles', 'Checking Page Specific Whitelists for Entity Type ID or Role.');
      for (const [key, whitelist] of Object.entries(this.config.whitelists)) {
        if (key === 'default')
          continue;
        if (
          url.startsWith(key) &&
          (whitelist.entityTypeIDs?.includes(user.entityTypeId) ||
          (
            user['roles'] &&
            Array.isArray(whitelist.roles) && whitelist.roles.length > 0 &&
            user.roles.some(role => whitelist.roles?.includes((typeof whitelist.roles[0] === 'string') ? role.name : role.ID))
          ))
        )
          return true;
      }


      this.redirectToPermissionsDenied(url, options);

      return false;
    } catch (err) {
      this._debugLog(RippleUserGuardDebugLevel.VERBOSE, 'ERROR', err);
      return false;
    }
  }

  private redirectToPermissionsDenied(url: string, options: redirectOptions) {
    if (this.config?.usePermissionsDenied === true) {
      this._debugLog(RippleUserGuardDebugLevel.INFO, '_checkUserRolesForHandoffRoles', 'Sending user to PermissionsDenied component.');
      this.router.navigate(['/permissions-denied', { redirectUrl: options.redirectUrl }]);
    } else {
      this._debugLog(RippleUserGuardDebugLevel.INFO, '_checkUserRolesForHandoffRoles', 'User lacks permissions.');
      alert('Your user does not have permission to access this page.');
      if (options.redirectBack) {
        this._debugLog(RippleUserGuardDebugLevel.INFO, '_checkUserRolesForHandoffRoles', 'Sending user back to previous page.');
        ['', '/'].includes(url) ?
          window.history.go(-2) : // Go back two pages if the current page is the home page since the user guard will create an infinite loop
          window.history.back();
      }
    }
  }

  private redirectToExpiredHandoffLink(url: string, options: redirectOptions) {
    this.router.navigate(['/expired-handoff-link', { redirectUrl: options.redirectUrl }]);
  }

  private _debugLog(level: RippleUserGuardDebugLevel, key: string, ...args: any[]) {
    if (level <= RippleUserGuard.debugLogLevel)
      console.log(this.namespace, key ?? 'No Key Provided', ...args);
  }
}
