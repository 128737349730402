import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: '[snippetFilterEntry]'
})
export class SnippetDataDirective {
  @Input('snippetFilterEntry') exportableTitle: string;
  @Input() exportable: boolean = true;

  constructor(public el: ElementRef<HTMLElement>) {}

  get data() {
    return this.el?.nativeElement?.textContent?.trim();
  }

  get title() {
    return this.exportableTitle || this.data;
  }
}
