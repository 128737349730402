<div class="pane pane--lg-h100">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">
        Organizations
      </h1>
      <div class="header__actions">
      </div>
    </div>
  </div>
  <div class="pane__body">
    <!-- <ripple-entity-list [entityService]="organizationService" [editButton]="edit" [addButton]="add"></ripple-entity-list> -->

    <p-card class="w-100 mb-4 organization-filter">
      <div class="header mb-3">
        <div class="header__heading p-d-flex align-items-baseline">
          <span class="ui-inputgroup">
            <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
            <input type="text" pInputText [(ngModel)]="searchText" placeholder="Organization Search..." 
              (keyup)="filterOrganizations()" (keyup.Enter)="filterOrganizations(true)" />
              <button class="ui-inputgroup-addon" (click)="advancedFilterPanel.toggle($event);"><i class="pi pi-filter"></i> Advanced Options</button>
          </span>
        </div>
        <div class="header__actions">
          <button pButton class="{{ ifSortByDistance === '1' ? 'ui-button-secondary' : '' }}"
            icon="pi pi-sort-amount-up-alt" *ngIf="showSortDistanceButton" label="Sort by Coverage Area" (click)="sortByDistance()">
          </button>
          <button pButton class="{{ ifSortByOfficeDistance === '1' ? 'ui-button-secondary' : '' }}"
            icon="pi pi-sort-amount-up-alt" *ngIf="showSortDistanceButton" label="Sort by Office" (click)="sortByOfficeDistance()">
          </button>
          <button pButton class="{{ ifSortByTagsMatches === '1' ? 'ui-button-secondary' : '' }}"
            icon="pi pi-sort-amount-up" *ngIf="showSortTagMatchButton" label="Sort by # Tag Matches" (click)="sortByTagMatches()">
          </button>
          <button pButton class="ui-button-secondary" icon="pi pi-plus" label="Add New Organization" (click)="add()">
          </button>
        </div>
      </div>

      <div class="filters-used" *ngIf="showFilterCard()">

        <div class="filter" *ngIf="filterEntity.properties.organizationtags && filterEntity.properties.organizationtags.length > 0">
          <h4 class="mb-0">{{filterEntity.properties.organizationtags.length}} tags</h4>
          <p-autoComplete class="tag-chips-container"
            [(ngModel)]="filterEntity.properties.organizationtags"
            field="name" [multiple]="true" readonly="true"
            (onUnselect)="updateFilterQueryParams()">
          </p-autoComplete>
          <div>
            <a href="javascript:;" (click)="clearTagFilter()">x</a>&nbsp;<a href="javascript:;" (click)="clearTagFilter()">Clear All</a>
          </div>
        </div>
        <div class="filter" *ngIf="this.filterEntity.properties.communities">
          <h4 class="mb-0">Community</h4>
          <p><strong>{{this.filterEntity.properties.communities.name}}</strong></p>
        </div>
        <div class="filter" *ngIf="this.filterEntity.properties.postalcode">
          <h4 class="mb-0">Postal Code</h4>
          <p><strong>{{this.filterEntity.properties.postalcode}}</strong></p>
        </div>
        <div class="filter" *ngIf="this.filterEntity.properties.teams">
          <h4 class="mb-0">Teams</h4>
          <p-autoComplete class="tag-chips-container"
            [(ngModel)]="filterEntity.properties.teams"
            field="name" [multiple]="true" readonly="true"
            (onUnselect)="updateFilterQueryParams()">
          </p-autoComplete>
          <div>
            <a href="javascript:;" (click)="clearTeamFilter()">x</a>&nbsp;<a href="javascript:;" (click)="clearTeamFilter()">Clear All</a>
          </div>
        </div>
      </div>

    </p-card>
    
    <!-- <ripple-entity-scroll-view
      #organizations
      *ngIf="!loading"
      class="organization-list"
      [entityService]="organizationService" [filter]="filter" itemSize="310" fullHeight="true">
      <ng-template #container let-organization>
        <sagesse-organization-card [organization]="organization"></sagesse-organization-card>
      </ng-template>
    </ripple-entity-scroll-view> -->
    <ripple-loading-box [loading]="loading" [fullHeight]="true">
      <p-virtualScroller #sv [value]="organizations" scrollHeight="450px" [class.ui-height-100]="true"  [itemSize]="310" [rows]="25"
        [lazy]="true" (onLazyLoad)="lazyLoad($event)" class="organization-list"
        *ngIf="!loading && organizations.length > 0"
      >
        <ng-template let-organization pTemplate="item">
          <sagesse-organization-card [organization]="organization"></sagesse-organization-card>
        </ng-template>
        <ng-template let-entity pTemplate="loadingItem">
          <div class="empty-item bg-white"></div>
        </ng-template>
      </p-virtualScroller>
      <ng-container *ngIf="organizations.length === 0">
        <div class="organization-list-empty">
          <h3 class="mx-2 my-2">No Organization Found</h3>
        </div>
        
      </ng-container>
    </ripple-loading-box>
    
  </div>
</div>

<p-overlayPanel #advancedFilterPanel [appendTo]="'body'" [baseZIndex]="10011">
  <div class="p-grid">
    <div class="p-col-6">
      <div class="mb-4">
        <h3>Community</h3>
        <sagesse-communities [entity]="filterEntity" [multiSelect]="false" #communities></sagesse-communities>
      </div>
      <div class="mb-4">  
        <h3>Postal Code</h3>
        <input
          pInputText type="text"
          class="w-100 mb-1"
          [(ngModel)]="filterEntity.properties.postalcode"
        />
      </div>
    </div>
    <div class="p-col-6">
      <div class="mb-4">
        <h3>Teams</h3>
        <sagesse-partials-teams [entity]="filterEntity" #teams></sagesse-partials-teams>
      </div>
    </div>
    <div class="p-col-12">
      <div class="mb-4">
        <h3>Category Tags</h3>
        <sagesse-tags [entity]="filterEntity" #tags></sagesse-tags>
      </div>
    </div>
  </div>
  <!-- <div class="mb-4">  
    <h3>Clare's Law</h3>
    <p-checkbox
      [(ngModel)]="claresLawOnlyBinary"
      [binary]="true"
    ></p-checkbox>
    <span>&nbsp;Accepts Clare's Law</span>
  </div> -->
  <div class="p-d-flex">
    <p-button icon="pi pi-search" label="Search" class="ml-auto"
      (click)='updateFilterQueryParams();advancedFilterPanel.hide();'></p-button>
    <p-button icon="pi pi-refresh" label="Clear"
      (click)='clearAll();advancedFilterPanel.hide();'></p-button>
  </div>
</p-overlayPanel>
