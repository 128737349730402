import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export enum ProfileType {
    PUBLIC = 859,
    PRIVATE = 860
}

export class Channel extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.Channel;
    }

    static empty(): Channel {
        return new Channel(super.emptyEntity(EntityTypes.Channel));
    }

    get cannotDelete() {
        return this.name === 'General';
    }

}