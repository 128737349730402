<div class="p-grid p-grid-lg-h100 p-grid-layout">
    <div class="p-col-12">
        <div class="pane pane--lg-h100">
            <div class="pane__header pane__header--sticky bg-white">
                <div class="header">
                    <h1 class="header__heading">
                        Organization Tags
                    </h1>
                    <div class="header__actions">
                    </div>
                </div>
            </div>
            <div class="pane__body">
                <ripple-scroll-card class="h-100">
                    <ripple-loading-box [loading]="loading" [fullHeight]="true">
                        <div class="tags-container">
                            <ripple-resizable-layout [defaultLeftWidth]="'40%'" [defaultRightWidth]="'60%'">
                                <div class="left-content h-100">
                                    <ripple-scroll-card class="h-100">
                                        <div class="scroll-card-header">
                                            <div class="header header--margin">
                                                <div class="header__heading">
                                                  <div class="input-icon-overlay w-100">
                                                    <input
                                                      pInputText type="text"
                                                      class="w-100" placeholder="Add a category"
                                                      [(ngModel)]="categoryText"
                                                      (keyup.Enter)="addCategory()"
                                                      [disabled]="categoryAdding"
                                                    />
                                                  </div>
                                                </div>
                                                <div class="header__actions">
                                                    <p-button icon="pi {{categoryAdding ? 'pi-spin pi-spinner' : 'pi-plus'}}" [disabled]="categoryAdding"
                                                        (click)='addCategory()'>
                                                    </p-button>
                                                </div>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="categories.length > 0">
                                            <div *ngFor="let category of categories">
                                                <div class="category-tag-row">
                                                    <div class="category-tag-content">
                                                        <button pButton type="button" label="{{category.properties.name}}" (click)="categorySelect(category)"
                                                            class="w-100 {{categorySelected && categorySelected.entityId === category.entityId ?
                                                            'ui-button-secondary' : ''}}"
                                                            *ngIf="!category.editMode"
                                                        ></button>
                                                        <input
                                                            *ngIf="category.editMode"
                                                            pInputText type="text"
                                                            class="w-100" placeholder="Category Name"
                                                            [(ngModel)]="category.properties.name"
                                                            (keyup.Enter)="edit(category)"
                                                        />
                                                    </div>
                                                    <div class="category-tag-action">
                                                        <button pButton type="button" (click)="edit(category)"
                                                            icon="pi pi-pencil"
                                                        >
                                                        </button>
                                                        <button pButton type="button" (click)="delete(category)"
                                                            icon="pi pi-trash"
                                                        >
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="categories.length <= 0">
                                            <ripple-empty-state iconClass="pi pi-tag" [heading]="'No Category Yet. '"></ripple-empty-state>
                                        </ng-container>
                                        
                                    </ripple-scroll-card>
                                </div>
                                <div class="right-content h-100">
                                    <ripple-loading-box [loading]="tagsLoading" [fullHeight]="true">
                                        <ripple-scroll-card class="h-100" *ngIf="!categorySelected">
                                            <ripple-empty-state iconClass="pi pi-tag" [heading]="'No Category Selected. '"></ripple-empty-state>
                                        </ripple-scroll-card>
                                       
                                        <ripple-scroll-card class="h-100" *ngIf="categorySelected">
                                            <div class="scroll-card-header">
                                                <div class="header header--margin">
                                                    <div class="header__heading">
                                                      <div class="input-icon-overlay w-100">
                                                        <input
                                                          pInputText type="text"
                                                          class="w-100" placeholder="Add a tag"
                                                          [(ngModel)]="tagText"
                                                          (keyup.Enter)="addTag()"
                                                          [disabled]="tagAdding"
                                                        />
                                                      </div>
                                                    </div>
                                                    <div class="header__actions">
                                                        <p-button icon="pi {{tagAdding ? 'pi-spin pi-spinner' : 'pi-plus'}}" [disabled]="tagAdding"
                                                            (click)='addTag()'>
                                                        </p-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <ng-container *ngIf="tags.length > 0">
                                                <div *ngFor="let tag of tags">
                                                    <div class="category-tag-row">
                                                        <div class="category-tag-content">
                                                            <button pButton type="button" label="{{tag.properties.name}}"
                                                                class="w-100"
                                                                *ngIf="!tag.editMode"
                                                            >
                                                            </button>
                                                            <div class="heading-action-row" *ngIf="tag.editMode">
                                                                <div class="heading-action-row__heading">
                                                                    <input
                                                                        pInputText type="text"
                                                                        class="w-100" placeholder="Tag Name"
                                                                        [(ngModel)]="tag.properties.name"
                                                                        (keyup.Enter)="edit(tag)"
                                                                    />
                                                                </div>
                                                                <div class="heading-action-row__actions pl-1 pr-1">
                                                                    <p-checkbox
                                                                        [(ngModel)]="tag.properties.mustmatch"
                                                                        [binary]="true"
                                                                    ></p-checkbox>
                                                                    <span>&nbsp;Must Match?</span>
                                                                </div>
                                                            </div>
                                                            
                                                          
                                                        </div>
                                                        <div class="category-tag-action">
                                                            <button pButton type="button" (click)="edit(tag)"
                                                                icon="pi pi-pencil"
                                                            >
                                                            </button>
                                                            <button pButton type="button" (click)="delete(tag)"
                                                                icon="pi pi-trash"
                                                            >
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="tags.length <= 0">
                                                <ripple-empty-state iconClass="pi pi-tag" [heading]="'No Tag in This Category Yet. '"></ripple-empty-state>
                                            </ng-container>
                                        </ripple-scroll-card>
                                    </ripple-loading-box>
                                </div>
                            </ripple-resizable-layout>
                        </div>
                    </ripple-loading-box>
                </ripple-scroll-card>                
            </div>
        </div>
    </div>
</div>
  
