import { Routes } from '@angular/router';
import { RippleUserGuard } from '@ripple/services';
import { LoginComponent } from '../login/login.component';
import { OrganizationDetailsComponent } from '../organizations/organization-details/organization-details.component';
import { OrganizationsComponent } from '../organizations/organizations.component';
import { ContactsComponent } from '../contacts/contacts.component';
import { ContactDetailsComponent } from '../contacts/contact-details/contact-details.component';
import { ContactRolesComponent } from '../contact-roles/contact-roles.component';
import { ContactRoleDetailsComponent } from '../contact-roles/contact-role-details/contact-role-details.component';
import { OrganizationTagComponent } from '../organization-tag/organization-tag.component';
import { UniversalEditorComponent } from '../universal-editor/universal-editor.component';
import { ClaresLawsComponent } from '../clares-laws/clares-laws.component';
import { ClaresLawDetailsComponent } from '../clares-laws/clares-law-details/clares-law-details.component';
import { FormBuildersComponent } from '../form-builders/form-builders.component';
import { FormBuilderDetailsComponent } from '../form-builders/form-builder-details/form-builder-details.component';
import { FormComponent } from '../form/form.component';
import { TeamTypeComponent } from '../team-type/team-type.component';
import { TeamTypeDetailsComponent } from '../team-type/team-type-details/team-type-details.component';
import { TeamContactRoleTypeComponent } from '../team-contact-role-type/team-contact-role-type.component';
import { TeamContactRoleTypeDetailsComponent } from '../team-contact-role-type/team-contact-role-type-details/team-contact-role-type-details.component';
import { TeamComponent } from '../team/team.component';
import { TeamDetailsComponent } from '../team/team-details/team-details.component';
import { CommunitiesComponent } from '../communities/communities.component';
import { CommunityDetailsComponent } from '../communities/community-details/community-details.component';
import { CommunityGroupsComponent } from '../community-groups/community-groups.component';
import { CommunityGroupDetailsComponent } from '../community-groups/community-group-details/community-group-details.component';
import { ClaresLawInfoComponent } from '../clares-laws/clares-law-info/clares-law-info.component';
import { ChannelDetailsComponent } from '../team/team-details/channel-details/channel-details.component';
import { GuestPortalComponent } from '../guest-portal/guest-portal.component';
import { FormBuilderReportComponent } from '../form-builders/form-builder-report/form-builder-report.component';
import { ClaresLawReportPageComponent } from '../clares-law-report-page/clares-law-report-page.component';
import { EnterStatsComponent } from '../agency-stats/enter-stats/enter-stats.component';
import { ManageProgramStatsComponent } from '../agency-stats/manage-program-stats/manage-program-stats.component';
import { ProgramsComponent } from '../agency-stats/programs/programs.component';
import { ProgramDetailsComponent } from '../agency-stats/program-details/program-details.component';
import { StrategicPlanBuilderComponent } from '../agency-stats/strategic-plan-builder/strategic-plan-builder.component';
import { StrategicPlanViewerComponent } from '../agency-stats/strategic-plan-viewer/strategic-plan-viewer.component';
import { IndividualStatReportComponent } from '../agency-stats/individual-stat-report/individual-stat-report.component';
import { IndividualStatReportViewerComponent } from '../agency-stats/individual-stat-report-viewer/individual-stat-report-viewer.component';
import { EvaluationOutcomeReportComponent } from '../agency-stats/evaluation-outcome-report/evaluation-outcome-report.component';
import { EvaluationOutcomeReportDetailsComponent } from '../agency-stats/evaluation-outcome-report-details/evaluation-outcome-report-details.component';

export const routes: Routes = [
  { path: '', redirectTo: 'organization', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'organization',
    canActivate: [RippleUserGuard],
    component: OrganizationsComponent
  },
  {
    path: 'organization/:entityId',
    canActivate: [RippleUserGuard],
    component: OrganizationDetailsComponent
  },
  {
    path: 'contact',
    canActivate: [RippleUserGuard],
    component: ContactsComponent
  },
  {
    path: 'contact/:entityId',
    canActivate: [RippleUserGuard],
    component: ContactDetailsComponent
  },
  {
    path: 'contactRole',
    canActivate: [RippleUserGuard],
    component: ContactRolesComponent
  },
  {
    path: 'contactRole/:entityId',
    canActivate: [RippleUserGuard],
    component: ContactRoleDetailsComponent
  },
  {
    path: 'organizationTag',
    canActivate: [RippleUserGuard],
    component: OrganizationTagComponent
  },
  {
    path: 'admin',
    canActivate: [RippleUserGuard],
    component: UniversalEditorComponent
  },
  {
    path: 'admin/formbuilder',
    canActivate: [RippleUserGuard],
    component: FormBuildersComponent
  },
  {
    path: 'admin/formbuilder/:formId',
    canActivate: [RippleUserGuard],
    component: FormBuilderDetailsComponent
  },
  {
    path: 'admin/formbuilder/report/:formId',
    canActivate: [RippleUserGuard],
    component: FormBuilderReportComponent
  },
  {
    path: 'claresLaw/:entityId',
    canActivate: [RippleUserGuard],
    component: ClaresLawDetailsComponent
  },
  {
    path: 'claresLaw',
    canActivate: [RippleUserGuard],
    component: ClaresLawsComponent
  },
  {
    path: 'claresLawReport',
    canActivate: [RippleUserGuard],
    component: ClaresLawReportPageComponent
  },
  {
    path: 'claresLaw/:entityId',
    canActivate: [RippleUserGuard],
    component: ClaresLawDetailsComponent
  },
  {
    path: 'form/:handoffToken/:filledFormId',
    component: FormComponent,
  },
  {
    path: 'teamType',
    canActivate: [RippleUserGuard],
    component: TeamTypeComponent
  },
  {
    path: 'teamType/:entityId',
    canActivate: [RippleUserGuard],
    component: TeamTypeDetailsComponent
  },
  {
    path: 'teamContactRoleType',
    canActivate: [RippleUserGuard],
    component: TeamContactRoleTypeComponent
  },
  {
    path: 'teamContactRoleType/:entityId',
    canActivate: [RippleUserGuard],
    component: TeamContactRoleTypeDetailsComponent
  },
  {
    path: 'teamType',
    canActivate: [RippleUserGuard],
    component: TeamTypeComponent
  },
  {
    path: 'teamType/:entityId',
    canActivate: [RippleUserGuard],
    component: TeamTypeDetailsComponent
  },
  {
    path: 'team',
    canActivate: [RippleUserGuard],
    component: TeamComponent
  },
  {
    path: 'team/:entityId',
    canActivate: [RippleUserGuard],
    component: TeamDetailsComponent
  },
  {
    path: 'community',
    canActivate: [RippleUserGuard],
    component: CommunitiesComponent
  },
  {
    path: 'community/:entityId',
    canActivate: [RippleUserGuard],
    component: CommunityDetailsComponent
  },
  {
    path: 'communityGroup',
    canActivate: [RippleUserGuard],
    component: CommunityGroupsComponent
  },
  {
    path: 'communityGroup/:entityId',
    canActivate: [RippleUserGuard],
    component: CommunityGroupDetailsComponent
  },
  {
    path: 'claresLawInfo/:handoffToken/:claresLawId',
    component: ClaresLawInfoComponent,
  },
  {
    path: 'claresLawInfo/:handoffToken/:claresLawId/:filledFormId',
    component: ClaresLawInfoComponent,
  },
  {
    path: 'team/:teamId/channel/:channelId',
    canActivate: [RippleUserGuard],
    component: ChannelDetailsComponent
  },
  {
    path: 'guestPortal/:handoffToken/:entityId',
    component: GuestPortalComponent
  },
  {
    path: 'enterstats',
    canActivate: [RippleUserGuard],
    component: EnterStatsComponent
  },
  {
    path: 'enterstats/:month/:year',
    canActivate: [RippleUserGuard],
    component: EnterStatsComponent
  },
  {
    path: 'manageprogramstats',
    canActivate: [RippleUserGuard],
    component: ManageProgramStatsComponent
  },
  {
    path: 'programs',
    canActivate: [RippleUserGuard],
    component: ProgramsComponent
  },
  {
    path: 'strategicplanbuilder',
    canActivate: [RippleUserGuard],
    component: StrategicPlanBuilderComponent
  },
  {
    path: 'agencystats',
    canActivate: [RippleUserGuard],
    component: EnterStatsComponent
  },
  {
    path: 'program/:entityId',
    canActivate: [RippleUserGuard],
    component: ProgramDetailsComponent
  },
  {
    path: 'strategicplanviewer',
    canActivate: [RippleUserGuard],
    component: StrategicPlanViewerComponent
  },
  {
    path: 'individualstatisticreports',
    canActivate: [RippleUserGuard],
    component: IndividualStatReportComponent
  },
  {
    path: 'individualstatisticreports/:entityId',
    canActivate: [RippleUserGuard],
    component: IndividualStatReportViewerComponent
  },
  {
    path: 'evaluation-outcome-reports',
    canActivate: [RippleUserGuard],
    component: EvaluationOutcomeReportComponent
  },
  {
    path: 'evaluation-outcome-reports/:entityId',
    canActivate: [RippleUserGuard],
    component: EvaluationOutcomeReportDetailsComponent
  },
];
