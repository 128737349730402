import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class StrategicOutcomeSubCategory extends WarpEntity {
  id: string | number;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.StrategicOutcomeSubCategory;
  }

  static empty(): StrategicOutcomeSubCategory {
    return new StrategicOutcomeSubCategory(super.emptyEntity(EntityTypes.StrategicOutcomeSubCategory));
  }

}
