// // Angular
import { /* Injector, */ NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { createCustomElement } from '@angular/elements';

// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

// formly
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';

// PrimeNG
import { AccordionModule       } from 'primeng/accordion';
import { ButtonModule          } from 'primeng/button';
import { CalendarModule        } from 'primeng/calendar';
import { CardModule            } from 'primeng/card';
import { CheckboxModule        } from 'primeng/checkbox';
import { ColorPickerModule     } from 'primeng/colorpicker';
import { DialogModule          } from 'primeng/dialog';
import { DragDropModule        } from 'primeng/dragdrop';
import { DropdownModule        } from 'primeng/dropdown';
import { EditorModule          } from 'primeng/editor';
import { FieldsetModule        } from 'primeng/fieldset';
import { InputMaskModule       } from 'primeng/inputmask';
import { InputNumberModule     } from 'primeng/inputnumber';
import { InputTextareaModule   } from 'primeng/inputtextarea';
import { InputTextModule       } from 'primeng/inputtext';
import { KeyFilterModule       } from 'primeng/keyfilter';
import { MessageModule         } from 'primeng/message';
import { MessagesModule        } from 'primeng/messages';
import { MultiSelectModule     } from 'primeng/multiselect';
import { PanelModule           } from 'primeng/panel';
import { ProgressBarModule     } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule     } from 'primeng/radiobutton';
import { SidebarModule         } from 'primeng/sidebar';
import { SplitButtonModule     } from 'primeng/splitbutton';
import { TableModule           } from 'primeng/table';
import { TabViewModule         } from 'primeng/tabview';
import { ToggleButtonModule    } from 'primeng/togglebutton';
import { TooltipModule         } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { OverlayPanelModule    } from 'primeng/overlaypanel';


// Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilderService   } from './form-builder.service';
import { FormBuilderComponent } from './form-builder.component';

// Partials
import { FormCheckboxMultipleColComponent } from './partials/form-checkbox-multiple-col/form-checkbox-multiple-col.component';
import { FormBuilderSignatureComponent } from './partials/form-signature/form-signature.component';
import { FormRadioMultipleColComponent } from './partials/form-radio-multiple-col/form-radio-multiple-col.component';
import { FormDatetimePickerComponent } from './partials/form-datetime-picker/form-datetime-picker.component';
import { FormDropdownButtonComponent } from './partials/form-dropdown-button/form-dropdown-button.component';
import { RippleFieldGenericComponent } from './partials/ripple-field-generic/ripple-field-generic.component';
import { SelectBoxFieldComponent }  from './partials/common/select-box-field.component';
import { FormBuilderButtonComponent } from './partials/form-button/form-button.component';
import { FormRepeatPanelComponent } from './partials/form-repeat-panel/form-repeat-panel.component';
import { FormSelectEntityComponent } from './partials/form-select-entity/form-select-entity.component';
import { FormDescriptionComponent } from './partials/form-description/form-description.component';
import { FormTextEditorComponent } from './partials/form-text-editor/form-text-editor.component';
import { FormTextareaComponent } from './partials/form-textarea/form-textarea.component';
import { FormSelectComponent } from './partials/form-select/form-select.component';
import { FormInputComponent } from './partials/form-input/form-input.component';
import { FormColorPickerComponent } from './partials/form-color-picker/form-color-picker.component';
import { FormFileComponent } from './partials/form-file/form-file.component';
import { FormMultiselectComponent } from './partials/form-multiselect/form-multiselect.component';
import { FormVirtualSelectEntityComponent } from './partials/form-virtual-select-entity/form-virtual-select-entity.component';

import { FormWrapperFieldComponent } from './partials/wrappers/form-wrapper-field/form-wrapper-field.component';
import { FormWrapperPanelComponent } from './partials/wrappers/form-wrapper-panel/form-wrapper-panel.component';
import { FormWrapperTableComponent } from './partials/wrappers/form-wrapper-table/form-wrapper-table.component';
import { FormWrapperTableRowComponent } from './partials/wrappers/form-wrapper-table-row/form-wrapper-table-row.component';

import { FormBuilderSpecifyTextComponent } from './partials/common/specify-text/specify-text.component';

import { WYSIWYGEditor } from './partials/plain-inputs/wysiwyg-editor/wysiwyg-editor.component';
import {
  VirtualEntitySelectComponent,
  VirtualEntitySelectRemoveEntityDirective
} from './partials/plain-inputs/virtual-entity-select/virtual-entity-select.component';
import { PasswordDirective } from './partials/plain-inputs/password/password';

import { FORMBUILDER_FORMLY_OPTIONS } from './partials';

// Reports
import { FormBuilderReportComponent } from './report/form-builder-report/form-builder-report.component';
import { FormAggregateComponent     } from './report/form-aggregate/form-aggregate.component';
import { SnippetFilterComponent     } from './report/snippet-filter/snippet-filter.component';

// Pipes
import { SafeHTMLPipePipe } from './pipes/safe-htmlpipe.pipe';

// Misc.
import { ngfModule } from 'angular-file';
import { ChartsModule } from 'ng2-charts';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { FormActionModalComponent } from './form-actions/form-action-modal/form-action-modal.component';

@NgModule({
  declarations: [
    FormBuilderComponent,

    WYSIWYGEditor,

    VirtualEntitySelectComponent,
    VirtualEntitySelectRemoveEntityDirective,

    PasswordDirective,

    RippleFieldGenericComponent,
    SelectBoxFieldComponent,

    FormBuilderSpecifyTextComponent,

    FormWrapperFieldComponent,
    FormWrapperPanelComponent,
    FormWrapperTableComponent,
    FormWrapperTableRowComponent,

    FormRepeatPanelComponent,
    FormDescriptionComponent,
    FormTextEditorComponent,

    FormBuilderButtonComponent,

    FormTextareaComponent,
    FormInputComponent,

    FormCheckboxMultipleColComponent,
    FormRadioMultipleColComponent,

    FormDropdownButtonComponent,

    FormSelectEntityComponent,
    FormSelectComponent,

    FormBuilderSignatureComponent,
    FormDatetimePickerComponent,
    FormColorPickerComponent,
    FormFileComponent,
    FormMultiselectComponent,
    FormVirtualSelectEntityComponent,

    SafeHTMLPipePipe,

    FormBuilderReportComponent,
    FormAggregateComponent,
    SnippetFilterComponent,
    FormActionModalComponent,
  ],
  exports: [
    WYSIWYGEditor,
    VirtualEntitySelectComponent,
    VirtualEntitySelectRemoveEntityDirective,

    PasswordDirective,

    FormBuilderComponent,
    SafeHTMLPipePipe,
    FormBuilderReportComponent,
    FormAggregateComponent,
    SnippetFilterComponent
  ],
  imports: [
    // Angular
    NgbModule,
    CommonModule,

    // Material
    FormsModule,
    ReactiveFormsModule,

    // PrimeNG
    SidebarModule,
    ButtonModule,
    DialogModule,
    PanelModule,
    TooltipModule,
    ToggleButtonModule,
    SplitButtonModule,
    RadioButtonModule,
    MultiSelectModule,
    CheckboxModule,
    CalendarModule,
    DropdownModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    EditorModule,
    DragDropModule,
    MessagesModule,
    MessageModule,
    ProgressBarModule,
    AccordionModule,
    KeyFilterModule,
    FieldsetModule,
    CardModule,
    ProgressSpinnerModule,
    ColorPickerModule,
    InputMaskModule,
    TableModule,
    TabViewModule,
    VirtualScrollerModule,
    OverlayPanelModule,

    // General
    PerfectScrollbarModule,
    CanvasWhiteboardModule,
    NgJsonEditorModule,
    ngfModule,

    // Formly
    FormlyPrimeNGModule,
    FormlyModule.forRoot(FORMBUILDER_FORMLY_OPTIONS),
    ChartsModule
  ],
  providers: [
    FormBuilderService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class FormBuilderModule {
  // constructor(private injector: Injector) { }

  // ngDoBootstrap() {
  //   const formbuilderElement = createCustomElement(FormBuilderComponent, { injector: this.injector });
  //   customElements.define('ripple-element-formbuilder', formbuilderElement);
  //  }
}
