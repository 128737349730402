import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache } from '@ripple/services';
import { map } from 'rxjs/operators';
import { EntityTypes, User } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class UserService extends WarpEntityServiceCache<User> {

  constructor(injector: Injector) {
    super(injector, User, EntityTypes.User);
  }

  getActiveUsers() {
    return this.warpService
      ._get(`/api/sagesse/allActiveUsers`, 'Get All Active Users')
      .pipe(map(retVal => retVal.map(d => new User(d))));
  }
}
