<div class="paperwork" [ngClass]="_statusClass">
  <div class="status"></div>
  <i *ngIf="iconClass" class="pi icon {{ iconClass }}"></i>
  <div class="details">
    <div class="title">{{ title }}</div>
    <div class="date sub-text" *ngIf="subTitle && subTitle !== ''">
        <!-- {{ date || subTitle }} -- -->        
        {{ subTitle }}
    </div>
    <div class="date sub-text" *ngIf="sessionDateTime">
        <!-- {{ date || subTitle }} -- -->        
        {{ (sessionDateTime | amTimeAgo) }}
    </div>
  </div>
  <div class="pills">
    <div *ngIf="numOutstandingItems" class="counter pill" [ngClass]="pillClass || _statusClass">{{  pill }}</div>
    <div *ngIf="numOutstandingItems2" class="counter pill" [ngClass]="pillClass2">{{ pill2 }}</div>
    <div *ngIf="numOutstandingItems3" class="counter pill" [ngClass]="pillClass3">{{ pill3 }}</div>
    <div *ngIf="numOutstandingItems4" class="counter pill" [ngClass]="pillClass4">{{ pill4 }}</div>
  </div>
  <div class="flag" *ngIf="flagText">{{ flagText }}</div>
</div>