import { Component, OnInit, Input } from '@angular/core';
import { WarpEntity } from '@ripple/models';

@Component({
  selector: 'ripple-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  // @Input() person: WarpEntity;
  // @Input() size: AvatarSize;

  @Input() image: string;
  @Input() name: string;
  @Input() size: string; /* medium, large */

  constructor() { }

  ngOnInit(): void {
    this.image = this.image ? this.image : 'assets/img/anonymousUser.png';
  }

  getInitials() {
    if (this.name) {
      const initials = this.name.match(/\b\w/g) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }
  }
}
