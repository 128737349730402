import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { AfterSaveEvent } from '@ripple/ui';
import { MenuItem } from 'primeng/api';
import { first } from 'rxjs/operators';
import { Channel, ChannelMember, EntityTypes, Team, TeamContact, TeamType } from 'subscribers/sagesse-srm/src/app/_core/models';
import { TeamContactService } from 'subscribers/sagesse-srm/src/app/_core/services';

@Component({
  selector: 'sagesse-channel-member-details',
  templateUrl: './channel-member-details.component.html',
  styleUrls: ['./channel-member-details.component.scss']
})
export class ChannelMemberDetailsComponent implements OnInit {

  @Input() channelMemberSelected: ChannelMember;
  @Input() teamContactSelected: TeamContact;
  @Input() channel: Channel;
  @Output('afterSave') afterSave: EventEmitter<AfterSaveEvent> = new EventEmitter<AfterSaveEvent>();

  channelMemberService: WarpEntityServiceCache<ChannelMember>;

  // team contact role types
  teamTypeService: WarpEntityServiceCache<TeamType>;
  teamContactRoleTypes = [];

  // buttons
  splitButtons: MenuItem[];
  saving = false;

  constructor(
    private serviceFactory: WarpEntityCacheFactoryService
  ) { }

  ngOnInit(): void {
    this.teamTypeService = this.serviceFactory.get(EntityTypes.TeamType) as WarpEntityServiceCache<TeamType>;
    this.channelMemberService = this.serviceFactory.get(EntityTypes.ChannelMember) as WarpEntityServiceCache<ChannelMember>;
    // team contact role type
    if (this.channel.teamtype) {
      this.teamTypeService.get(this.channel.teamtype.id)
        .pipe(first())
        .toPromise()
        .then(teamType => {
          this.teamContactRoleTypes = teamType.properties.teamcontactroletype || [];
          this.teamContactRoleTypes = this.teamContactRoleTypes.map(r => ({ label: r.name, value: [r]}));
        });
    }

    // init buttons
    if (this.channelMemberSelected.entityId > 0) this.switchSplitButtons('update');
    else this.switchSplitButtons('add');
  }

  switchSplitButtons(key) {
    switch (key) {
      case 'add':
        this.splitButtons = [
          {
            label: 'Add',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-plus',
            disabled: this.saving,
            command: () => this.save(),
          },
        ];
        break;
      case 'update':
        this.splitButtons = [
          {
            label: 'Save',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-check',
            disabled:
              this.saving,
            command: () => this.save(),
            items: [{ label: 'Delete', icon: 'pi pi-trash', disabled: this.saving, command: () => this.delete() }],
          },
        ];
        break;
    }
  }

  updateButtonState() {
    if (!this.channelMemberSelected) return;

    if (this.channelMemberSelected.entityId < 0) {
      this.switchSplitButtons('add');
    } else {
      this.switchSplitButtons('update');
    }
  }

  setSavingProcess(saving: boolean) {
    this.saving = saving;
    this.updateButtonState();
  }

  save() {
    this.setSavingProcess(true);
    if (this.channelMemberSelected.entityId < 0) {
      this.channelMemberService.create(this.channelMemberSelected).pipe(first())
        .toPromise()
        .then(insertId => {
          this.setSavingProcess(false);
          this.channelMemberSelected.entityId = insertId;
          this.afterSave.emit({
            action: 'add',
            entity: this.channelMemberSelected,
          });
        });
    } else {
      this.channelMemberService.update(this.channelMemberSelected).pipe(first())
        .toPromise()
        .then(entity => {
          this.setSavingProcess(false);
          this.afterSave.emit({
            action: 'save',
            entity: this.channelMemberSelected,
          });
        });
    } 
  }
  delete() {
    this.channelMemberService.delete(this.channelMemberSelected).then(result => {
      this.afterSave.emit({
        action: 'delete',
        entity: this.channelMemberSelected
      });
    });
    
  }

}
