import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionWrapperName } from './question-type-names';
import { maxTextLength } from './field-config-field';

interface WrapperOption {
  key: string;
  value: SectionWrapperName;
}

const wrappers: WrapperOption[] = [
  { key: 'Default Panel', value: 'panel' },
  { key: 'Special - Radio List Panel', value: 'table-panel' },
];

const sizeOption = (sz) => ({
    key: `${Math.round(sz / 12 * 100)}%`,
    value: `p-col-12 p-md-${sz}`
  });

const sizes = Array.from({ length: 12 })
  .map((_, i) => sizeOption(12 - i)); // 12 -> 0

export const fieldConfigSection: FormlyFieldConfig[] = [
  {
    className: 'p-field ui-fluid p-d-block',
    key: 'sectionName',
    type: 'input',
    templateOptions: {
      label: 'Sub-section name:',
      maxLength: maxTextLength,
    }
  },
  {
    className: 'p-field ui-fluid p-d-block',
    key: 'className',
    type: 'select',
    templateOptions: {
      label: 'Select the size:',
      options: [
        {
          key: 'Fit All',
          value: 'p-col'
        },
        ...sizes
      ],
      labelProp: 'key',
      valueProp: 'value'
    }
  },
  // {
  //   className: 'p-field ui-fluid p-d-block',
  //   key: 'wrapper',
  //   type: 'select',
  //   templateOptions: {
  //     label: 'Section Type:',
  //     options: wrappers,
  //     labelProp: 'key',
  //     valueProp: 'value'
  //   }
  // }
];
