import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

import { IGenericObject } from '@ripple/models';

import type { FormBuilderComponent } from '../form-builder.component';
import type { FormSection } from './form-section';
import type { FormField } from './form-field-general';

type PopupAction = 'unset' | 'version-delete' |
  'section-delete' | 'section-edit' | 'section-add' |
  'field-delete' | 'field-edit' | 'field-add';

type PopupView = 'sidebar' | 'modal';
// @dynamic
export class FormBuilderPopup {
  formBuilder: FormBuilderComponent;
  form: FormGroup;
  fields: FormlyFieldConfig[];
  options: FormlyFormOptions;
  model: any;
  json: IGenericObject;
  useJson = false;
  requiresRippleApproval = false;

  displayAs: PopupView;
  display: boolean;
  label: string;
  action: string;
  _submit: () => void;
  onchange: ($event) => void;

  private _target: FormField | FormSection;
  targetParent: FormSection | FormBuilderComponent;

  constructor(
    form = null, label: string = null, fields: FormlyFieldConfig[] = null, options: FormlyFormOptions = null,
    submit: () => void = null, onchange: () => void = null,
    action: PopupAction = null, target: FormField | FormSection = null, display = false
  ) {
    this.formBuilder = form;
    this.label = label || '';
    this.fields = fields || [];
    this.options = options || { };
    this._submit = submit || (() => { });
    this.onchange = onchange || (() => { });
    this.action = action;
    this.target = target;
    this.display = display;
    this.form = new FormGroup({ });
    this.model = { };
  }

  get path() {
    type Pathable = FormBuilderComponent | FormSection | FormField;
    let path = '';

    for (let p: Pathable = this.target; p; p = p.parent)
      path = `${p.label} > ${path}`;

    return path;
  }

  submit() {
    if (this.requiresRippleApproval)
      this.formBuilder.requiresRippleApproval = true;

    this._submit();
  }

  get target() { return this._target; }
  set target(t: FormSection | FormField) {
    this._target = t as FormSection || t as FormField || null;
    if (this._target) {
      this.targetParent = this._target.parent;
      this.json = this._target.exportSelf();
    }

    if (this.formBuilder)
      this.formBuilder.logChild(this, `Popup target parent: ${this.targetParent?.name}, ${this._target?.internalID}`, t, this.targetParent);
  }

  get showSidebar() { return this.display && this.displayAs === 'sidebar'; }
  set showSidebar(show: boolean) { this.view(show); }
  get showModal() { return this.display && this.displayAs === 'modal'; }
  set showModal(show: boolean) { this.view(show); }

  show = () => this.view(true);
  hide = () => this.view(false);

  view(_display: boolean): FormBuilderPopup {
    this.display = _display;
    return this;
  }

  static sidebar(action: PopupAction, form: FormBuilderComponent, target = null, updateKeyForCopy = false) {
    return FormBuilderPopup.factory(action, form, 'sidebar', target, updateKeyForCopy);
  }

  static modal(action: PopupAction, form: FormBuilderComponent, target = null) {
    return FormBuilderPopup.factory(action, form, 'modal', target, false);
  }

  static factory(type: PopupAction, form: FormBuilderComponent, displayAs: PopupView = 'modal', target = null, updateKeyForCopy = false) {
    const trySet = (key, val) => out[key] = out[key] || val;
    const setLabelAction = (lab, act  = null) => { trySet('label', lab); trySet('action', act || lab.toLowerCase()); };

    const out = new FormBuilderPopup(form).show();

    if (typeof target === 'string')
      target = out.formBuilder.getSectionByID(target);

    switch (type) {
      case 'section-delete':
        setLabelAction('Delete');
      case 'section-edit':
        setLabelAction('Apply');
        out.target = target;
      case 'section-add':
        setLabelAction('Add');
        if (type == 'section-add' && target)
          out.targetParent = target;
        out.fields = form.sectionFields;
        form.logChild(out, 'SECTION FIELDS: ', out.fields);
        out.options = { };
        out.submit = () => form.submitSectionForm();
        break;

      case 'field-delete':
        setLabelAction('Delete');
      case 'field-edit':
        form.lastSelectedFieldType = (target as FormField).type;
        setLabelAction('Apply');
        out.target = target;
      case 'field-add':
        setLabelAction('Add');
        trySet('targetParent', target);
        [out.fields, out.model] = form.getFieldPopupData(out, (target && target.type) || 'generic' );
        // out.options = form.popup.options.updateInitialValue();
        out.submit = () => form.submitFieldForm(updateKeyForCopy);
        out.onchange = ($event) => form.fieldModelChanged($event);
        break;

      case 'version-delete':
        setLabelAction('Delete');
        out.submit = () => form.deleteVersion(target);
        break;

      default:
        form.logChild(out, 'Invalid Sidebar', type);
        out.display = false;
        break;
    }

    // fill any fields that have values
    if (out.target)
      out.fields.forEach( field => {
        if ((field.key as string) in out.target)
          out.model[field.key as string] = out.target[field.key as string];
      });

    out.displayAs = displayAs;
    return out;
  }

  static get empty() {
    return new FormBuilderPopup();
  }
}
