<div class="pane pane--lg-h100">
    <div class="pane__header pane__header--sticky bg-white">
      <div class="header">
        <h1 class="header__heading">
          Clare's Law
        </h1>
        <div class="header__actions">
        </div>
      </div>
    </div>
    <div class="pane__body">
      <div class="generic-report-button-container">
        <ripple-button-list [buttons]="splitButtons"></ripple-button-list>
      </div>
      <ripple-entity-list [entityService]="claresLawService" [editButton]="edit"
        [filter]="filter" [filterEntity]="filterEntity"></ripple-entity-list>
    </div>
</div>

<p-dialog header="Clare's Law Report"
    [(visible)]="reportShow"
    *ngIf="reportShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-clares-law-report #report (dialogClose)="reportShow=false">
    </sagesse-clares-law-report>
    <p-footer>
      <button pButton class="ui-button-secondary" icon="pi pi-upload" label="Send" (click)="report.send()"
        [disabled]="report.sending || report.loading">
      </button>
    </p-footer>
  
</p-dialog>