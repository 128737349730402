<div class="p-grid my-1">
    <div class="p-col-6 p-d-flex p-align-center">
        <span class="person-char">{{ contact?.personChar }}</span>
        <div>
            <div>{{contact?.displayName}}  {{contact.organization ? 'from ' + contact.organization.name : ''}}</div>
            <div>
                <a href="mailto:{{contact?.email}}" class="email-label">
                    <i class="pi pi-envelope"></i> {{contact?.email}}
                </a>
            </div>
        </div>
    </div>
    <div class="p-col-6 p-d-flex p-align-center" *ngIf="contact && contact.channels">
        <div>
            <a *ngFor="let channel of contact.channels" href="/#/team/{{contact.team.id}}/channel/{{channel.id}}">
                <span class="badge rounded-pill badge--white mr-1 mb-1">
                    {{ channel?.name?.length > 20 ? channel.name.substring(0, 20) + '...' : channel.name }}
                </span>
            </a>
        </div>
    </div>
</div>

<p-dialog header="Channel List"
    [(visible)]="channelListShow"
    *ngIf="channelListShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-channel-members [teamContact]="contact"></sagesse-channel-members>
</p-dialog>



