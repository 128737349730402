<div class="p-grid" *ngIf="entity">
    <ng-container *ngFor="let category of categories">
        <div class="p-sm-12 p-md-6" *ngIf="categoryShow(category)">
            <div class="heading-action-row category-header">
                <h4 class="heading-action-row__heading">
                    {{category.name}}
                </h4>
                <div class="heading-action-row__actions">
                    <a href="javascript:;" (click)="selectAll(category)">Select All</a>
                </div>
            </div>
            <div *ngFor="let tag of category.tags">
                <p-checkbox name="tags" [value]="tag" *ngIf="tagShow(tag)"
                    [(ngModel)]="entity.properties.organizationtags"></p-checkbox>
                <label *ngIf="tagShow(tag)">&nbsp;{{tag.name}}</label>
            </div>
            <div *ngIf="showFaithInputText(category)">
                <div class="ui-widget">
                    <label>
                        Other:
                    </label>
                </div>
                <input
                    pInputText type="text"
                    class="w-100"
                    [(ngModel)]="entity.properties.faith"
                />
            </div>
            
        </div>
    </ng-container>
</div>

