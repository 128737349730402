<div>
  <h2>{{name}}</h2>

  <div *ngIf="stats.length !== 0 && !loading">
    <table style="width:100%" border="1">
      <thead>
        <tr>
          <th width="15%">Name</th>
          <th width="15%">Program</th>
          <th width="15%">Group </th>
          <th width="35%">Instructions</th>
          <th>Quarter {{quarter}} targets</th>
          <th width="20%">
            <div>{{getMonthName(month)}}, {{year}}</div>
            <button class='month-button' (click)="monthChange(+1)" pButton icon="pi pi-chevron-circle-right"></button>
            <button class='month-button' (click)="monthChange(-1)" pButton icon="pi pi-chevron-circle-left"></button>
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let stat of stats">
          <tr [ngClass]="isCarryover(stat) ? 'carryover' : null">
            <td>{{stat.LinkedStat.properties.name}}</td>
            <td>{{stat.LinkedProgram}}</td>
            <td>{{stat.LinkedGroup}}</td>
            <td>
              <textarea style="width:100%; border: 0;" type="text" placeholder="empty" (change)="onChange(stat, 'note')" [(ngModel)]="stat.LinkedStat.properties.notes"> </textarea>
              <!-- {{stat.LinkedStat.properties.notes}} -->
            </td>
            <td>
              <div class="target-info" *ngIf="stat.targetInformation?.targets?.properties?.value">
                {{stat.targetInformation.progress.value != undefined ? stat.targetInformation.progress.value : stat.targetInformation.progress}} of {{stat.targetInformation.targets.properties.value}}
                <div *ngIf="stat.targetInformation.otherStats?.length > 0" class="other-stats">
                  <p>{{stat.targetInformation.otherStats.length}} other stats, mouse over to view them</p>
                  <ul class="other-list">
                    <li *ngFor="let other of stat.targetInformation.otherStats">
                      {{other.name}}
                    </li>
                  </ul>
                </div>
              </div>
            </td>
            <td class="enter-cell" [ngClass]="isCarryover(stat) ? 'carryover' : !passedSanityCheck(stat) ? 'failedcheck' : null" >
              <i *ngIf="!passedSanityCheck(stat)" class="pi pi-exclamation-circle"></i>
              <input type="number" min="0" placeholder="empty" (ngModelChange)="updateTargets(stat,$event)"  (change)="onChange(stat, 'value')"
                [(ngModel)]="stat.properties.value"   [disabled]="disableStatChange"
                [ngClass]="isCarryover(stat) ? 'carryover' : !passedSanityCheck(stat) ?  'failedcheck' : null" />

                <div *ngIf="stat | MonthNote: month as notes"><a href="javascript:;" *ngIf="notes.length > 0; else createNote" (click)="openEditMonthNotes(notes)" >Edit monthly note</a></div>
                <ng-template #createNote><a href="javascript:;" (click)="openNotesModal(stat)">Create monthly note</a></ng-template>
                <div *ngIf="!stat.edited" style="text-align: right;font-style:italic;font-size: 10px;color: #999">{{stat?.LinkedUser && stat.properties.value?.length > 0 ?  stat.LinkedUser : ""}}</div>
              <!-- <span *ngIf="!passedSanityCheck(stat) && !isCarryover(stat)">This Stat is +/- 20% of the previous month or
                year.</span> -->
              <span *ngIf="isCarryover(stat)">Record carryover for this stat here.</span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="stats.length == 0 && !loading">
    <p>There are no stats assigned to you, please contact your supervisior</p>
  </div>
  <div *ngIf="loading">
    <p-progressSpinner></p-progressSpinner>

  </div>
</div>

<p-dialog header="Month Note" [(visible)]="displayMonthNoteModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">

    <div *ngIf="sendingNote">
      <p-progressSpinner></p-progressSpinner>

    </div>

    <ng-container *ngIf="!sendingNote">
    <div class="input-wrapper">
    <textarea class="monthNotes" [(ngModel)] = "monthNote"></textarea>
    </div>
        <p-button icon="pi pi-check" (click)="!editingNote ? submitMonthNote() : editMonthNote() " label="Submit" styleClass="p-button-text"></p-button>
      </ng-container>
</p-dialog>
