<ng-template #filter>
    <div class="filter-container" *ngIf="showFilter">
        <p-accordion>
            <p-accordionTab header="Filter">
                <ripple-entity-filter [warpEntityType]="warpEntityType" [defaultFilter]="defaultFilter"
                    [filterSubject]="filterSubject" [customFields]="customFieldsForFilter" [filterEntity]="filterEntity">
                </ripple-entity-filter>
            </p-accordionTab>
        </p-accordion>
    </div>
</ng-template>

<ng-template #buttons>
    <div class="generic-report-button-container">
        <button *ngIf="addButton && warpEntityType" pButton class="ui-button-secondary" icon="pi pi-plus" label="Add New {{caption || warpEntityTypeName}}" (click)="addButton()"></button>
        <button *ngIf="showCSVExport" pButton class="ui-button-secondary exportCSVButton" icon="pi pi-{{isExporting ? 'spin pi-spinner' : 'file-excel'}}"
            label="Export" (click)="exportCSV()" style=" margin-left: 10px;"></button>
    </div>
</ng-template>

<ng-container *ngIf="filterOnTop; then filter; else buttons"></ng-container>
<ng-container *ngIf="filterOnTop; then buttons; else filter"></ng-container>

<p-table #dt [value]="entities" [columns]="cols" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
    [rows]="rowsPerPageOptions[0]" [showCurrentPageReport]="true" [rowsPerPageOptions]="rowsPerPageOptions" [loading]="loading"
    [paginator]="true" [lazy]="true" (onLazyLoad)="lazyLoad($event)" [totalRecords]="totalEntities"
    [filterDelay]="300" currentPageReportTemplate="{first} - {last} of {totalRecords}" [sortField]="sortField" [sortOrder]="sortOrder"
    [(selection)]="selection" [selectionMode]="selectionMode" (onRowSelect)="rowSelect.emit($event.data)" (onRowUnselect)="rowUnselect.emit($event.data)"
    [globalFilterFields]="globalFilterFields">
    <!-- <ng-template pTemplate="caption">
		<div class="p-d-flex">
			<span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
            </span>
		</div>
	</ng-template> -->
    <ng-template pTemplate="header" let-columns>
        <tr>
            <th *ngFor="let col of columns" pSortableColumn="{{col.field}}">{{col.header}}<p-sortIcon field="{{col.field}}"></p-sortIcon></th>
            <th  *ngIf="editButton || showDeleteButton || actionMenuOverride.length > 0"></th>
        </tr>
        <!-- <tr>
            <th *ngFor="let col of columns">
                <input *ngIf="col.filter" pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'startsWith')" placeholder="Search by {{col.field | titlecase}}" class="ui-column-filter">
            </th>
            <th class="actions-col" *ngIf="editButton || showDeleteButton"></th>
        </tr> -->
    </ng-template>
    <ng-template pTemplate="body" let-entity let-columns="columns">
        <ng-container *ngIf="selectionMode; then selectableRow; else normalRow"></ng-container>

        <ng-template #selectableRow>
            <tr class="ui-selectable-row" [pSelectableRow]="entity" [ngClass]="getCustomClass?getCustomClass(entity):''">
                <ng-container *ngTemplateOutlet="row"></ng-container>
            </tr>
        </ng-template>

        <ng-template #normalRow>
            <tr class="ui-selectable-row" [ngClass]="getCustomClass?getCustomClass(entity):''">
                <ng-container *ngTemplateOutlet="row"></ng-container>
            </tr>
        </ng-template>

        <ng-template #row>
            <td *ngFor="let col of columns">
                <div [innerHTML]="prettyPrint(col, entity)"></div>
            </td>
            <td class="actions-col" *ngIf="(editButton || showDeleteButton) && actionMenuOverride.length == 0">
                <p-splitButton
                    [model]="entity.actionMenu"
                    label="{{ entity.isDeleting ? 'Deleting' : editIsViewButton ? 'View' : 'Edit'}}"
                    icon="pi pi-{{ entity.isDeleting ? 'spin pi-spinner' : editIsViewButton ? 'window-maximize' : 'pencil' }}"
                    [disabled]="entity.isDeleting"
                    (onClick)="invokeEditButton(entity)"
                    appendTo="{{appendTo}}" *ngIf="editButton && showDeleteButton"></p-splitButton>
                <p-splitButton
                    [model]="entity.actionMenu"
                    label="{{ entity.isDeleting ? 'Deleting' : ''}}"
                    icon="pi pi-{{ entity.isDeleting ? 'spin pi-spinner' : 'cog' }}"
                    [disabled]="entity.isDeleting"
                    appendTo="{{appendTo}}" *ngIf="!editButton && showDeleteButton"></p-splitButton>
                <button pButton type="button" icon="pi {{editIsViewButton ? 'pi-window-maximize' : 'pi-pencil'}}" label="{{editIsViewButton ? 'View' : 'Edit'}}" (click)="invokeEditButton(entity)" *ngIf="editButton && !showDeleteButton"></button>
            </td>
            <td class="actions-col" *ngIf="actionMenuOverride.length > 0">
                <p-splitButton
                    [model]="entity.actionMenu"
                    label="{{entity.mainAction.label}}"
                    icon="{{entity.mainAction.icon}}"
                    (onClick)="entity.mainAction.command()"
                    appendTo="{{appendTo}}"
                    [disabled]="entity.mainAction.disabled"
                    *ngIf="entity.actionMenu.length > 0">
                </p-splitButton>
                <button pButton type="button" icon="{{entity.mainAction.icon}}" label="{{entity.mainAction.label}}" [disabled]="entity.mainAction.disabled" (click)="entity.mainAction.command()" *ngIf="entity.actionMenu.length == 0"></button>
            </td>
        </ng-template>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="3" class="empty-message-col">No {{warpEntityTypeNamePlural}} found.</td>
        </tr>
    </ng-template>
</p-table>
