import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ripple-kpi-item',
  templateUrl: './kpi-item.component.html',
  styleUrls: ['./kpi-item.component.scss'],
})
export class KpiItemComponent implements OnInit {
  @Input() title = '';
  @Input() value = 0;
  @Input() prefix = '';
  @Input() postfix = '';
  @Input() previous = 0;
  @Input() moreIsGood = true;
  @Input() padding = false;

  changeInternal = 0;

  get increase() {
    return this.value > this.previous;
  }

  constructor() {}

  ngOnInit() {
    if (this.previous > 0)
      this.changeInternal = Math.abs(
        ((this.value - this.previous) / this.previous) * 100
      );
  }
}
