import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

const checkboxFields = ['mustmatch'];

export class OrganizationTag extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.OrganizationTag;

        // convert dateconfirmed Value to true or false
        for (const field of checkboxFields) {
            if (this.properties[field] && this.properties[field].length > 0)
                if (this.properties[field][0].id === 333) this.properties[field] = true;
                else this.properties[field] = false;
        }

        this.modify_preSave(true, OrganizationTag.organizationTagPreSave);
    }

    static empty(): OrganizationTag {
        return new OrganizationTag(super.emptyEntity(EntityTypes.OrganizationTag));
    }

    static organizationTagPreSave(w: WarpEntity): boolean {
        for (const field of checkboxFields) {
          if (w.properties[field]) w.cfcProperty(field, 333);
          else w.cfcProperty(field, 334);
        }
        return true;
      }

}