// angular
import '@angular/compiler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ripple
import { PartialsModule } from '@ripple/ui';
import { RippleInterceptor } from '@ripple/services';
import { FormBuilderModule } from '@ripple/formbuilder';
import { WarpEntityCacheFactoryLoaderService } from './_core/services'

// azure
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';

// external packcages
import { CookieModule } from 'ngx-cookie';
import { HubService } from 'ngx-signalr-hubservice';
import { MomentModule } from 'ngx-moment';
import * as Sentry from "@sentry/angular";

// primeng
import { ConfirmationService } from 'primeng/api';
import { SplitButtonModule } from 'primeng/splitbutton';
import { CardModule } from 'primeng/card';
import { PanelModule } from 'primeng/panel';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StepsModule } from 'primeng/steps';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { ChipsModule } from 'primeng/chips';
import { TableModule } from 'primeng/table';
import { AccordionModule } from 'primeng/accordion';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { GMapModule } from 'primeng/gmap';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule }from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { BlockUIModule } from 'primeng/blockui';
import { TreeModule } from 'primeng/tree';

import { SidebarModule } from 'primeng/sidebar';
import { MenuModule } from 'primeng/menu';
import { ToolbarModule } from 'primeng/toolbar';
import { DragDropModule } from 'primeng/dragdrop';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PaginatorModule } from 'primeng/paginator';

// formly
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';

// sagesse
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { OrganizationsComponent } from './organizations/organizations.component';
import { OrganizationDetailsComponent } from './organizations/organization-details/organization-details.component';
import { TabsTypeComponent } from './formly/types/tabs-type.component';
import { GenericWrapperComponent } from './formly/wrappers/generic-wrapper.component';
import { OrganizationContactsComponent } from './organizations/organization-details/organization-contacts/organization-contacts.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ContactDetailsComponent } from './contacts/contact-details/contact-details.component';
import { ContactRolesComponent } from './contact-roles/contact-roles.component';
import { ContactRoleDetailsComponent } from './contact-roles/contact-role-details/contact-role-details.component';
import { OfficesComponent } from './organizations/organization-details/offices/offices.component';
import { OrganizationTagComponent } from './organization-tag/organization-tag.component';
import { OrganizationContactDetailsComponent } from './organizations/organization-details/organization-contacts/organization-contact-details/organization-contact-details.component';
import { UniversalEditorComponent } from './universal-editor/universal-editor.component';
import { OrganizationContactCardComponent } from './organizations/organization-details/organization-contacts/organization-contact-card/organization-contact-card.component';
import { TagsComponent } from './partials/tags/tags.component';
import { OrganizationCardComponent } from './organizations/organization-card/organization-card.component';
import { ClaresLawsComponent } from './clares-laws/clares-laws.component';
import { ClaresLawDetailsComponent } from './clares-laws/clares-law-details/clares-law-details.component';
import { ClaresLawReferralBarComponent } from './clares-law-referral-bar/clares-law-referral-bar.component';
import { FormBuildersComponent } from './form-builders/form-builders.component';
import { FormBuilderDetailsComponent } from './form-builders/form-builder-details/form-builder-details.component';
import { FormComponent } from './form/form.component';
import { CommunitiesComponent } from './partials/communities/communities.component';
import { TeamTypeComponent } from './team-type/team-type.component';
import { TeamTypeDetailsComponent } from './team-type/team-type-details/team-type-details.component';
import { TeamContactRoleTypeComponent } from './team-contact-role-type/team-contact-role-type.component';
import { TeamContactRoleTypeDetailsComponent } from './team-contact-role-type/team-contact-role-type-details/team-contact-role-type-details.component';
import { TeamComponent } from './team/team.component';
import { TeamDetailsComponent } from './team/team-details/team-details.component';
import { TeamContactComponent } from './team/team-details/team-contact/team-contact.component';
import { ChannelMemberDetailsComponent } from './team/team-details/channel-details/channel-member-details/channel-member-details.component';
import { TeamContactCardComponent } from './team/team-details/team-contact/team-contact-card/team-contact-card.component';
import { CommunitiesComponent as CommunityListComponent } from './communities/communities.component';
import { CommunityDetailsComponent } from './communities/community-details/community-details.component';
import { CommunityGroupsComponent } from './community-groups/community-groups.component';
import { CommunityGroupDetailsComponent } from './community-groups/community-group-details/community-group-details.component';
import { ClaresLawInfoComponent } from './clares-laws/clares-law-info/clares-law-info.component';
import { ChannelDetailsComponent } from './team/team-details/channel-details/channel-details.component';
import { TeamOrganizationsComponent } from './team/team-details/team-organizations/team-organizations.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { GuestPortalComponent } from './guest-portal/guest-portal.component';
import { ClaresLawResultComponent } from './clares-law-result/clares-law-result.component';
import { Router } from '@angular/router';
import { ContactOrganizationCardComponent } from './contacts/contact-details/contact-organization-card/contact-organization-card.component';
import { ClaresLawReportComponent } from './clares-laws/clares-law-report/clares-law-report.component';
import { ChannelMembersComponent } from './team/team-details/channel-details/channel-members/channel-members.component';
import { TeamsComponent } from './partials/teams/teams.component';
import { FormBuilderReportComponent } from './form-builders/form-builder-report/form-builder-report.component';
import { ClaresLawReportPageComponent } from './clares-law-report-page/clares-law-report-page.component';
import { EnterStatsComponent } from './agency-stats/enter-stats/enter-stats.component';
import { ProgramsComponent } from './agency-stats/programs/programs.component';
import { StrategicPlanBuilderComponent } from './agency-stats/strategic-plan-builder/strategic-plan-builder.component';
import { ProgramDetailsComponent } from './agency-stats/program-details/program-details.component';
import { SatisticGroupComponent } from './agency-stats/satistic-group/satistic-group.component';
import { EnterStatsTableComponent, MonthNotePipe } from './agency-stats/enter-stats-table/enter-stats-table.component';
import { StrategicPlanViewerComponent } from './agency-stats/strategic-plan-viewer/strategic-plan-viewer.component';
import { StrategicOutcomeComponent } from './agency-stats/strategic-outcome/strategic-outcome.component';
import { StrategicOutcomeStatisticComponent } from './agency-stats/strategic-outcome-statistic/strategic-outcome-statistic.component';
import { ManageProgramStatsComponent } from './agency-stats/manage-program-stats/manage-program-stats.component';
import { IndividualStatReportComponent } from './agency-stats/individual-stat-report/individual-stat-report.component';
import { IndividualStatReportViewerComponent } from './agency-stats/individual-stat-report-viewer/individual-stat-report-viewer.component';
import { EvaluationOutcomeReportComponent } from './agency-stats/evaluation-outcome-report/evaluation-outcome-report.component';
import { EvaluationOutcomeReportDetailsComponent } from './agency-stats/evaluation-outcome-report-details/evaluation-outcome-report-details.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OrganizationsComponent,
    OrganizationDetailsComponent,
    TabsTypeComponent,
    GenericWrapperComponent,
    OrganizationContactsComponent,
    ContactsComponent,
    ContactDetailsComponent,
    ContactRolesComponent,
    ContactRoleDetailsComponent,
    OfficesComponent,
    OrganizationTagComponent,
    OrganizationContactDetailsComponent,
    UniversalEditorComponent,
    OrganizationContactCardComponent,
    TagsComponent,
    OrganizationCardComponent,
    ClaresLawsComponent,
    ClaresLawDetailsComponent,
    ClaresLawReferralBarComponent,
    FormBuildersComponent,
    FormBuilderDetailsComponent,
    FormComponent,
    CommunitiesComponent,
    TeamTypeComponent,
    TeamTypeDetailsComponent,
    TeamContactRoleTypeComponent,
    TeamContactRoleTypeDetailsComponent,
    TeamComponent,
    TeamDetailsComponent,
    TeamContactComponent,
    ChannelMemberDetailsComponent,
    TeamContactCardComponent,
    CommunityListComponent,
    CommunityDetailsComponent,
    CommunityGroupsComponent,
    CommunityGroupDetailsComponent,
    ClaresLawInfoComponent,
    ChannelDetailsComponent,
    TeamOrganizationsComponent,
    BreadcrumbComponent,
    GuestPortalComponent,
    ClaresLawResultComponent,
    ContactOrganizationCardComponent,
    ClaresLawReportComponent,
    ChannelMembersComponent,
    TeamsComponent,
    FormBuilderReportComponent,
    ClaresLawReportPageComponent,
    EnterStatsComponent,
    ProgramsComponent,
    StrategicPlanBuilderComponent,
    ProgramDetailsComponent,
    SatisticGroupComponent,
    EnterStatsTableComponent,
    StrategicPlanViewerComponent,
    StrategicOutcomeComponent,
    StrategicOutcomeStatisticComponent,
    ManageProgramStatsComponent,
    IndividualStatReportComponent,
    IndividualStatReportViewerComponent,
    MonthNotePipe,
    EvaluationOutcomeReportComponent,
    EvaluationOutcomeReportDetailsComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    PartialsModule,
    FormBuilderModule,
    CookieModule.forRoot(),
    MomentModule,
    MsalModule.forRoot(
      {
        auth: {
          clientId: environment.azureAdClientId,
          authority: environment.azureAdAuthority,
          redirectUri: environment.azureLoginRedirect,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: true, // set to true for IE 11
        },
      },
      {
        popUp: false,
        consentScopes: [
          'user.read',
          'openid',
          'profile',
        ],
        unprotectedResources: [],
        protectedResourceMap: [
          ['https://graph.microsoft.com/v1.0/me', ['user.read']],
          ['https://graph.microsoft.com/v1.0/groups', ['files.readwrite', 'sites.read.all']],
          ['https://graph.microsoft.com/v1.0/drives', ['files.readwrite']],
          ['https://graph.microsoft.com/v1.0/teams', ['files.readwrite', 'teamsettings.read.all']]
        ],
        extraQueryParameters: {}
      }
    ),
    SplitButtonModule,
    CardModule,
    PanelModule,
    // Formly
    FormlyModule.forRoot({
      // put the wrapper in here
      wrappers: [
        {
          name: 'generic-wrapper',
          component: GenericWrapperComponent,
        }
      ],
      types: [
        {
          name: 'tabs',
          component: TabsTypeComponent
        },
      ],
    }),
    FormlyPrimeNGModule,
    TabViewModule,
    DialogModule,
    InputTextModule,
    DropdownModule,
    InputNumberModule,
    CheckboxModule,
    MultiSelectModule,
    OverlayPanelModule,
    StepsModule,
    AutoCompleteModule,
    EditorModule,
    ChipsModule,
    TableModule,
    AccordionModule,
    MessagesModule,
    MessageModule,
    ConfirmDialogModule,
    GMapModule,
    VirtualScrollerModule,
    BreadcrumbModule,
    FileUploadModule,
    ToastModule,
    RadioButtonModule,
    CalendarModule,
    BlockUIModule,
    TreeModule,
    MenuModule,
    ToolbarModule,
    DragDropModule,
    ProgressSpinnerModule,
    PaginatorModule
  ],
  providers: [
    HubService,
    ConfirmationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RippleInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    WarpEntityCacheFactoryLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: WarpEntityCacheFactoryLoaderService) => () => {return ds.load()},
      deps: [WarpEntityCacheFactoryLoaderService],
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
