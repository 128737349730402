import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contact, ContactRole, EntityTypes, Office, Organization, OrganizationContact } from '../../../../../app/_core/models';
import { OrganizationContactService } from '../../../../../app/_core/services';
import * as moment from 'moment';
import { IGenFormlyOptions, CustomFieldInModule, EntityFilter, SubscriptionContainer } from '@ripple/models';
import { GenericWarpEntityService, WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { MenuItem, MessageService } from 'primeng/api';
import { forkJoin, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { AfterSaveEvent } from '@ripple/ui';
import { environment } from '@ripple/environment';

@Component({
  selector: 'sagesse-organization-contact-details',
  templateUrl: './organization-contact-details.component.html',
  styleUrls: ['./organization-contact-details.component.scss'],
  providers: [ MessageService ]
})
export class OrganizationContactDetailsComponent extends SubscriptionContainer implements OnInit {

  @Input() organizationContactSelected: OrganizationContact;
  @Input() from: 'contact' | 'organization' = 'organization';
  @Output('afterSave') afterSave: EventEmitter<AfterSaveEvent> = new EventEmitter<AfterSaveEvent>();
  
  // contact
  createNewContact = false;
  contacts = [];
  contactService: WarpEntityServiceCache<Contact>;
  // new contact
  newContact = Contact.empty().property('datecreated', moment().format('YYYY-MM-DD'));
  // formly form
  newContactFieldOrders = [
    'first name',
    'last name',
    'pronouns',
    'phonenumber',
    'email'
  ];
  newContactGeneratorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => !this.newContactFieldOrders.includes(cfim.unchangeableName.toLowerCase()),
    styles: {
      'first name': 'p-sm-12 p-md-12 ui-fluid',
      'last name': 'p-sm-12 p-md-12 ui-fluid',
      pronouns: 'p-sm-12 p-md-12 ui-fluid',
      phonenumber: 'p-sm-12 p-md-12 ui-fluid',
      email: 'p-sm-12 p-md-12 ui-fluid'
    },
  };

  // contact roles
  contactRoleService: WarpEntityServiceCache<ContactRole>;
  contactRoles: ContactRole[];

  // offices
  officeService: WarpEntityServiceCache<Office>;
  offices = [];

  // email & phone override
  emailOverride = false;
  phoneOverride = false;

  // organization
  organizations = [];
  organizationSub: Subscription;
  organizationService: WarpEntityServiceCache<Organization>;

  organizationsOrContactsLoading = false;

  get currentEmail () {
    return this.createNewContact ? this.newContact.properties.email : 
      (this.organizationContactSelected.entityId > 0 ? this.organizationContactSelected.contactEntity.properties.email :
      (this.organizationContactSelected.properties.contact && this.organizationContactSelected.properties.contact[0] ?
      this.organizationContactSelected.properties.contact[0].properties?.email : null));
  }

  get currentPhoneNumber () {
    return this.createNewContact ? this.newContact.properties.phonenumber : 
      (this.organizationContactSelected.entityId > 0 ? this.organizationContactSelected.contactEntity.properties.phonenumber :
        (this.organizationContactSelected.properties.contact && this.organizationContactSelected.properties.contact[0] ?
          this.organizationContactSelected.properties.contact[0].properties?.phonenumber : null));
  }

  // buttons
  splitButtons: MenuItem[];

  saving = false;

  guestPortalUrl = '';

  organizationContactService: OrganizationContactService;

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService,
    private entityService: GenericWarpEntityService,
    private messageService: MessageService
  ) {
    super();
    this.contactRoleService = this.warpEntityFactory.get(EntityTypes.ContactRole) as WarpEntityServiceCache<ContactRole>;
    this.officeService = this.warpEntityFactory.get(EntityTypes.Office) as WarpEntityServiceCache<Office>;
    this.contactService = this.warpEntityFactory.get(EntityTypes.Contact) as WarpEntityServiceCache<Contact>;
    this.organizationService = this.warpEntityFactory.get(EntityTypes.Organization) as WarpEntityServiceCache<Organization>;
    this.organizationContactService = this.warpEntityFactory.get(EntityTypes.OrganizationContact) as OrganizationContactService;
  }

  ngOnInit(): void {
    console.log('organization contact selected', this.organizationContactSelected);

    if (this.organizationContactSelected && this.organizationContactSelected.handofftoken) {
      this.guestPortalUrl = environment.host + '/guestPortal/' + this.organizationContactSelected.handofftoken + '/' + this.organizationContactSelected.entityId;
    }

    if (this.from === 'organization') {
      const orgId = this.organizationContactSelected.organization ? this.organizationContactSelected.organization.id : -1;
      this.organizationsOrContactsLoading = true;
      this.setEmptyMessage();
      // contact
      this.organizationContactService.getSelectedOrganizationContacts(orgId)
        .toPromise()
        .then(selectedContactIds => {
          this.nextSub = this.contactService.initQuery(EntityFilter.None, 9999999).getPage(0).subscribe(contacts => {
            this.contacts = contacts
              .filter(c => !selectedContactIds.includes(c.entityId))
              .map(c => ({ label: c.name, value: [c]}));
            this.organizationsOrContactsLoading = false;
            this.setEmptyMessage();
          });
          
        });
      
      this.organizationOnChange({ value: [{ id: orgId }] });
    }

    if (this.from === 'contact') {
      const contactId = this.organizationContactSelected.contact ? this.organizationContactSelected.contact.id : -1;
      this.organizationsOrContactsLoading = true;
      this.setEmptyMessage();
      // organization
      this.organizationContactService.getSelectedContactOrganizations(contactId)
        .toPromise()
        .then(selectedOrganizationIds => {
          this.nextSub = this.organizationService.initQuery(EntityFilter.None, 9999999).getPage(0).subscribe(organizations => {
            this.organizations = organizations
              .filter(c => !selectedOrganizationIds.includes(c.entityId))
              .map(c => ({ label: c.name, value: [c]}));
            this.organizationsOrContactsLoading = false;
            this.setEmptyMessage();
          });
        });  
    }

    // contact roles
    const roleFilter = EntityFilter.None.orderBy('isprimary', 'desc');
    this.nextSub = this.contactRoleService.initQuery(roleFilter, 9999999).getPage(0).subscribe(contactRoles => {
      this.contactRoles = contactRoles;
      // update contact roles in organizationContactSelected so that they will have same reference with contactRoles
      if (this.organizationContactSelected.properties.contactroles)
        this.organizationContactSelected.properties.contactroles = this.organizationContactSelected.properties.contactroles
          .map(cr => this.contactRoles.find(c => c.entityId === cr.id));
      if (this.organizationContactSelected.properties.primarycontactroles)
        this.organizationContactSelected.properties.primarycontactroles = this.organizationContactSelected.properties.primarycontactroles
          .map(cr => this.contactRoles.find(c => c.entityId === cr.id));
    });

    // email & phone override
    this.emailOverride = this.organizationContactSelected.email ? true : false;
    this.phoneOverride = this.organizationContactSelected.phonenumber ? true : false;

    // init buttons
    if (this.organizationContactSelected.entityId > 0) this.switchSplitButtons('update');
    else this.switchSplitButtons('add');
  }

  clearOverrideField(field, checked) {
    if (!checked)
      this.organizationContactSelected.properties[field] = '';
  }

  // splitbuttons
  switchSplitButtons(key) {
    switch (key) {
      case 'add':
        this.splitButtons = [
          {
            label: 'Add',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-plus',
            disabled: this.saving,
            command: () => this.save(),
          },
        ];
        break;
      case 'update':
        this.splitButtons = [
          {
            label: 'Save',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-check',
            disabled:
              this.saving,
            command: () => this.save(),
            items: [{ label: 'Remove', icon: 'pi pi-trash', disabled: this.saving, command: () => this.delete() }],
          },
        ];
        break;
    }
  }

  updateButtonState() {
    if (!this.organizationContactSelected) return;

    if (this.organizationContactSelected.entityId < 0) {
      this.switchSplitButtons('add');
    } else {
      this.switchSplitButtons('update');
    }
  }

  setSavingProcess(saving: boolean) {
    this.saving = saving;
    this.updateButtonState();
  }

  save() {
    this.setSavingProcess(true);
    if (this.createNewContact || !this.organizationContactSelected.properties.contact) this.saveWithNewContact();
    else {
      if (this.organizationContactSelected.entityId < 0) {
        this.organizationContactService.create(this.organizationContactSelected).pipe(first())
          .toPromise()
          .then(insertId => {
            this.setSavingProcess(false);
            this.organizationContactSelected.entityId = insertId;
            this.afterSave.emit({
              action: 'add',
              entity: this.organizationContactSelected,
            });
          });
      } else {
        this.organizationContactService.update(this.organizationContactSelected).pipe(first())
          .toPromise()
          .then(entity => {
            this.setSavingProcess(false);
            this.afterSave.emit({
              action: 'save',
              entity: this.organizationContactSelected,
            });
          });
      } 
    }
  }

  saveWithNewContact() {
    if (!this.newContact.properties['first name'] && !this.newContact.properties['last name'])
      this.newContact
      .property('first name', this.organizationContactSelected.organization.name + ' staff')
      .property('last name', this.organizationContactSelected.organization.name + ' staff');
    this.organizationContactSelected.linkedProperty('contact', this.newContact);
    forkJoin([
      this.entityService.getEntityStructure(EntityTypes.Contact).pipe(first()),
      this.entityService.getEntityStructure(EntityTypes.OrganizationContact).pipe(first()),
    ])
      .toPromise()
      .then(([contactType, organizationContactType]) => {
        this.entityService
          .syncWithChildren(
            { type: contactType, entity: this.newContact },
            { type: organizationContactType, entity: this.organizationContactSelected }
          )
          .subscribe((entities) => {
            this.setSavingProcess(false);
            this.afterSave.emit({
              action: 'save',
              entity: entities[0].entityTypeId === EntityTypes.OrganizationContact ? entities[0] : entities[1],
            });
          });
      });
  }

  delete() {
    this.organizationContactService.delete(this.organizationContactSelected)
      .then();
    this.afterSave.emit({
      action: 'delete',
      entity: this.organizationContactSelected
    });
  }

  organizationOnChange(event) {
    if (event.value && event.value.length > 0) {
      const orgId = event.value[0].id;
      // offices
      const officeFilter = EntityFilter.Advanced({ organization_lid: orgId });
      if (this.organizationSub) this.organizationSub.unsubscribe();
      this.nextSub = this.organizationSub = this.officeService.initQuery(officeFilter).getPage(0).subscribe(offices => {
        this.offices = offices;
      });
    }
  }

  copyGuestPortalUrl() {
    const urlTextElement = document.getElementById('guestPortalUrl') as HTMLInputElement;
    urlTextElement.select();
    urlTextElement.setSelectionRange(0, 99999);
    //TODO: take a look at this: https://stackoverflow.com/a/30810322, and put it in a @ripple/services service
    document.execCommand('copy');
    this.messageService.clear();
    this.messageService.add({
      key: 'bc',
      severity: 'success',
      summary: 'Copied!'
    });
  }

  setEmptyMessage() {
    const emptyMessageElement = document.querySelector('.ui-dropdown-empty-message');
    if (emptyMessageElement)
      if (this.organizationsOrContactsLoading) emptyMessageElement.innerHTML = '<i class="pi pi-spinner pi-spin"></i>';
      else emptyMessageElement.innerHTML = 'No results found';
  }

}
