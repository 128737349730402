import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { EntityFilter, WarpEntity } from '@ripple/models';
import { WarpEntityLogService, WarpEntityCacheFactoryService, GenericWarpEntityService, WarpEntityServiceCache, LayoutSettingsService, InternalCookieService, CookieType } from '@ripple/services';
import { Contact, EntityTypes, OrganizationContact, Team, TeamContact } from '../_core/models';
import { GuestPortalService } from '../_core/services';

@Component({
  selector: 'sagesse-guest-portal',
  templateUrl: './guest-portal.component.html',
  styleUrls: ['./guest-portal.component.scss']
})
export class GuestPortalComponent extends BasePage implements OnInit {

  contact: WarpEntity;
  teams: any[] = [];
  loading = true;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    private entityService: GenericWarpEntityService,
    private layoutSettingsService: LayoutSettingsService,
    private cookieService: InternalCookieService,
    private guestPortalService: GuestPortalService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    const layoutSettings = this.layoutSettingsService.getSettings();
    layoutSettings.showMenuBarNotifications = false;
    layoutSettings.showMenuBarPrimaryMenu = false;
    layoutSettings.showMenuBarProfileHelpMenu = false;
    layoutSettings.showFooterBar = false;
    layoutSettings['showClaresLawReferralBar'] = false;
    this.layoutSettingsService.setSettings(layoutSettings);
    this.route.params.subscribe((params) => {
      if (params.handoffToken) {
        this.cookieService.setCookie(CookieType.UserToken, params.handoffToken);
      } 
      const id = parseInt(params.entityId, 10);
      if (!isNaN(id)) {
        this.sub = this.entityService.get(id).subscribe((contact) => {
          console.log('Contact or organization contact', contact);
          this.contact = contact;
          if (contact.azureadobjectid) {
            this.guestPortalService.getUserJointTeams(contact.azureadobjectid).subscribe(result => {
              console.log('Teams', result);
              this.teams = result;
              this.loading = false;
            })
          }
        });
      }
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

}
