export function titlecase(value: string) {
  return value.split(/(\s)/g).map(word => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()).join('');
}


/**
 * Generates a number range
 * @param end end (exclusive)
 */
export function range(end: number): number[];

/**
 * Generates a number range
 * @param start start (inclusive)
 * @param end end (exclusive)
 * @param step step
 */
// tslint:disable-next-line: unified-signatures
export function range(start: number, end?: number, step?: number): number[];

export function range(start: number, end?: number, step: number = 1): number[] {
  const i: number[] = [];
  for (const num of rangeItr(start, end, step)) i.push(num);
  return i;
}

export function* rangeItr(start: number, end?: number, step: number = 1) {
  // for the overload
  if (!end) {
    end = start;
    start = 0;
  }
  if (end < start && step > 0)
    step = -step;

  let itr = 0;
  for (let i = start; i < end; i += step, itr++) yield i;
  return itr;
}


// tslint:disable-next-line: no-any
export function stopWatch<T>(caller: any, logFn: (..._: any[]) => any, func: (..._: any[]) => T, ...args: any[] ): T {
  const start = new Date().getTime();
  const ret = func.call(caller, ...args);
  const duration = new Date().getTime() - start;
  logFn.call(caller, `stopwatch calling ${func.name} took ${duration}ms`, duration);
  return ret;
}

export function isValidGuid(guid: string) {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(guid);
}