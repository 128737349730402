<div class="p-grid ui-fluid">
    <!-- contact -->
    <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="from === 'organization'">
        <div class="ui-widget">
            <label>
                Contact
                <a href="javascript:;" (click)="createNewContact = !createNewContact"
                    *ngIf="organizationContactSelected.entityId <= 0">
                    {{createNewContact ? 'Or, choose an existing contact' : 'Or, create a new contact'}}
                </a>
            </label>
        </div>
        <p-dropdown
            *ngIf="organizationContactSelected.entityId <= 0 && !createNewContact"
            appendTo='body'
            [baseZIndex]="99999999"
            filter="true"
            [options]="contacts"
            [(ngModel)]="organizationContactSelected.properties.contact"
            placeholder="Select a Contact"
            (onShow)="setEmptyMessage()"
        ></p-dropdown>
        <ripple-entity-details *ngIf="createNewContact"
            [entity]="newContact" [hideButtons]="true"
            [generatorOptions]="newContactGeneratorOptions" [fieldOrders]="newContactFieldOrders">
        </ripple-entity-details>
        <label *ngIf="organizationContactSelected.entityId > 0">
            {{organizationContactSelected?.contact?.name}}
        </label>
    </div>

    <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="from === 'contact'">
        <div class="ui-widget">
            <label>
                Organization
            </label>
        </div>
        <p-dropdown
            *ngIf="organizationContactSelected.entityId <= 0"
            appendTo='body'
            [baseZIndex]="99999999"
            filter="true"
            [options]="organizations"
            [(ngModel)]="organizationContactSelected.properties.organization"
            placeholder="Select an Organization"
            (onChange)="organizationOnChange($event)"
            (onShow)="setEmptyMessage()"
        ></p-dropdown>
        <label *ngIf="organizationContactSelected.entityId > 0">
            {{organizationContactSelected?.organization?.name}}
        </label>
    </div>

    <!-- contact roles -->
    <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
            <label>
                Roles
            </label>
        </div>
        <div class="p-grid" *ngFor="let contactRole of contactRoles">
            <div class="p-sm-6">
                <p-checkbox name="contactRole" [value]="contactRole"
                    [(ngModel)]="organizationContactSelected.properties.contactroles"></p-checkbox>
                <label>&nbsp;{{contactRole.name}}</label>
            </div>
            <div class="p-sm-6">
                <p-checkbox name="primaryContactRole" [value]="contactRole"
                    *ngIf="contactRole.isprimary && contactRole.isprimary.id === 333"
                    [(ngModel)]="organizationContactSelected.properties.primarycontactroles"></p-checkbox>
                <label *ngIf="contactRole.isprimary && contactRole.isprimary.id === 333">&nbsp;Primary?</label>
            </div>
        </div>
        <div class="p-grid">
            <div class="p-sm-6">
                <p-checkbox [(ngModel)]="organizationContactSelected.otherChecked" [binary]="true"></p-checkbox>
                <label>&nbsp;Other</label>
                <input type="text" class="w-100" pInputText
                    *ngIf="organizationContactSelected.otherChecked"
                    placeholder="Other" 
                    [(ngModel)]="organizationContactSelected.properties.other" />
            </div>
        </div>
    </div>

    <!-- department -->
    <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
            <label>
                Department
            </label>
        </div>
        <input
            pInputText type="text"
            class="w-100"
            [(ngModel)]="organizationContactSelected.properties.department"
        />
    </div>

    <!-- offices -->
    <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
            <label>
                Offices
            </label>
        </div>
        <p-multiSelect
            appendTo='body'
            [baseZIndex]="99999999"
            optionLabel="name"
            filter="true"
            dataKey="id"
            [options]="offices"
            [(ngModel)]="organizationContactSelected.properties.offices"
        >
        </p-multiSelect>
    </div>

    <!-- email & phone override -->
    <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
            <label>
                Email
            </label>
        </div>
        <div>
            <label>
                {{currentEmail}}
            </label>
            &nbsp;
            <p-checkbox [(ngModel)]="emailOverride" binary="true" inputId="binary"
                (onChange)="clearOverrideField('email', emailOverride)"></p-checkbox>
            <label for="binary">&nbsp;Override?</label>
        </div>
        <div *ngIf="emailOverride">
            <input
                pInputText type="text"
                class="w-100"
                [(ngModel)]="organizationContactSelected.properties.email"
            />
        </div>
        
    </div>

    <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
            <label>
                Phone
            </label>
        </div>
        <div>
            <label>
                {{currentPhoneNumber}}
            </label>
            &nbsp;
            <p-checkbox [(ngModel)]="phoneOverride" binary="true" inputId="binary"
                (onChange)="clearOverrideField('phonenumber', phoneOverride)"></p-checkbox>
            <label for="binary">&nbsp;Override?</label>
        </div>
        <div *ngIf="phoneOverride">
            <input
                pInputText type="text"
                class="w-100"
                [(ngModel)]="organizationContactSelected.properties.phonenumber"
            />
        </div>
    </div>

    <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="false">
        <div class="ui-widget">
            <label>
                Guest Portal URL
            </label>
        </div>
        <div class="heading-action-row">
            <input pInputText id="guestPortalUrl" type="text"
                class="heading-action-row__heading w-100" [(ngModel)]="guestPortalUrl" readonly />
            <button pButton class="heading-action-row__action" icon="pi pi-copy"
                (click)="copyGuestPortalUrl()"></button>
        </div>
    </div>
</div>

<p-toast position="bottom-center" key="bc"></p-toast>
