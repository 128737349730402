import { Component, HostListener, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Checkbox } from 'primeng/checkbox';
import { FormControl } from '@angular/forms';

import type { SelectBoxOption } from '../common/select-box.model';
import { SelectBoxFieldComponent } from '../common/select-box-field.component';

@Component({
  selector: 'ripple-form-checkbox-multiple-col',
  templateUrl: './form-checkbox-multiple-col.component.html',
  styleUrls: ['./form-checkbox-multiple-col.component.scss']
})

export class FormCheckboxMultipleColComponent extends SelectBoxFieldComponent implements OnInit {
  protected static availableFields = ['label-for-options', 'question-options-field', 'question-col-type-field', 'question-horizontal-radio-field' ];

  @ViewChildren(Checkbox) listOfCheckboxes!: QueryList<Checkbox>;

  ngOnInit() {
    // when the model is pre-loaded
    this.mapOptionToSelectBoxOptions();
    this.nextSub = this.formControl.valueChanges.subscribe(v => {
      this.mapOptionToSelectBoxOptions();
    });
  }

  checkBoxChanged(event, option: SelectBoxOption) {
    option.selected = event.checked;
    this.updateModel(option);
  }

  updateModel(option: SelectBoxOption) {
    this.log('updateModel', option);

    if (this.model && typeof this.key === 'string') {
      let model_value = this.model[this.key] || [];

      if(!(model_value instanceof Array))
        model_value = [model_value];

      // Remove all elements in model_value that match option.id
      const index = model_value.findIndex(element => element.id === option.id);
      if (index >= 0)
        model_value.splice(index, 1);

      // Add option to model_value if option is selected
      if (option.selected)
        model_value.push(option);

      // Update the model
      this.model[this.key] = [...model_value];
      this.formControl.setValue([...model_value]);
      this.formControl.markAsDirty();
      this.tryEmitOnChange();
    }
  }

  specifyTextChange(event, option: SelectBoxOption) {
    // specify component did this already, we just need to update the model

    // Update the model
    const currOptions = this.selectBoxOptions$.value;
    const ind = currOptions.findIndex(currOption => currOption.id === option.id);
    if(ind >= 0) {
      currOptions[ind] = option;
      this.selectBoxOptions$.next(currOptions);
    }

    // Update the model
    this.updateModel(option);

    this.log('specify text changed', event, option);
  }
}
