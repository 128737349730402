import { Guid } from 'guid-typescript';
import { ReplaySubject, BehaviorSubject, forkJoin, Observable, from, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { MessageService } from '../../general';
import type { WarpEntityServiceCache } from '../warp-entity-service-cache';

import { WarpEntity, EntityFilter, SubscriptionContainer, IEntityPage, SignalRHandlerObject } from '@ripple/models';

export type LoadStyle = 'lazy' | 'eager' | 'eager-update' | string;

/** Cache Tokens are used to track previous requests, and store reference to the result */
export class CachedPageToken<T extends WarpEntity> {
  tokenGuid: Guid;
  created: Date;
  updated: Date;

  status: 'created' | 'loading' | 'stale' | 'complete';

  pageSize: number;
  filter: EntityFilter;
  _page = 0;

  readonly totalForFilter: BehaviorSubject<number>;
  readonly filterPropertyCounts: BehaviorSubject<Map<string, number>>;

  private observable: ReplaySubject< Map<number, T> >;

  //  get page() { return this.filter.page; }
  public get page() { return this._page; }
  public get query() { return this.parent; }
  public get hasListeners() { return this.observable.observers.length > 0; }

  get() {
    if (this.status === 'stale') this.parent.warn('Accessing Stale Data!', this.filter);
    return this.observable.asObservable();
  }

  get subscribe() {
    // tslint:disable-next-line: deprecation <- one of the overloads is depreciated
    return this.observable.subscribe.bind(this.observable);
  }
  get pipe() {
    return this.observable.pipe.bind(this.observable);
  }
  get once() {
    // tslint:disable-next-line: deprecation <- one of the overloads is depreciated
    return this.observable.pipe(take(1)).subscribe.bind(this.observable);
  }

  constructor(private parent: CachedQueryToken<T>, entityFilter: EntityFilter, page: number) {
    this.tokenGuid = Guid.create();
    this.created = this.updated = new Date();
    this.filter = entityFilter;
    this.observable = new ReplaySubject(1);
    this.totalForFilter = new BehaviorSubject(null);
    this.filterPropertyCounts = new BehaviorSubject(null);
    this.pageSize = parent.pageSize;
    this._page = page;
  }

  get ready() { return this.status === 'loading' || this.status === 'complete'; }

  dispatch(pageInfo: Omit<IEntityPage, 'data' | 'columnFilters'>, p: Map<number, T>) {
    this.status = 'complete';
    if (this.totalForFilter.value !== pageInfo.recordsFiltered)
      this.totalForFilter.next(pageInfo.recordsFiltered);

    if(this.filterPropertyCounts.value !== pageInfo.propertyCounts){
      this.filterPropertyCounts.next(pageInfo.propertyCounts);
    }

    this.observable.next(p);
    this.parent.log(`dispatching token`, p, MessageService.VERBOSE);
  }

  clear() {
    this.status = 'stale';
  }

  compatible(entityFilter: EntityFilter) {
    return (
      entityFilter.page === this.filter.page &&
      entityFilter.identifier === this.filter.identifier
      );
  }

  get peers() {
    return this.parent.iterator();
  }

}

/** Cache Tokens are used to track previous requests, and store reference to the result */
export class CachedQueryToken<T extends WarpEntity> extends SubscriptionContainer implements Iterable<CachedPageToken<T>> {
  tokenGuid: Guid;
  created: Date;
  updated: Date;

  status: 'incomplete' | 'complete';

  filter: EntityFilter;
  readonly totalForFilter: BehaviorSubject<number>;
  readonly filterPropertyCounts: BehaviorSubject<Map<string, number>>;

  protected pages: Map<number, CachedPageToken<T>>;

  pageSize: number;

  [Symbol.iterator] = this.iterator;

  constructor(private parent: WarpEntityServiceCache<T>, entityFilter: EntityFilter, pageSize: number) {
    super();
    this.pages = new Map();
    this.tokenGuid = Guid.create();
    this.created = this.updated = new Date();
    this.filter = entityFilter;
    this.totalForFilter = new BehaviorSubject(null); // this is so the loading things will show some flashing boxes
    this.filterPropertyCounts = new BehaviorSubject(null);
    this.pageSize = pageSize;
  }

  get hasListeners() {
    for (const [f, token] of this.pages)
      if (token.hasListeners) return true;

    return false;
  }

  /**
   * Gets a full page with a page size up to cache.settings.pageSize (default)
   * @param page the page number to load
   * @returns A list of Entities
   */
  getFullPage(page: number): Observable<T[]> {
    return this.getPageToken(page).get().pipe(map( m => [...m.values()]));
  }

  /**
   * Gets a smaller-than-default page with a page size up to cache.settings.pageSize
   * @param page the page number to load
   * @param pageSize the size of a micro-page
   * @returns A list of Entities
   */
  getMicroPage(first: number, pSize: number): Observable<T[]> {
    const cachedPageSize = this.pageSize;
    if (cachedPageSize < pSize)
      throw new Error('A micro-page must be smaller than the loaded pages');

    const pageNum = first / cachedPageSize;
    const pageStart = first % cachedPageSize;
    const pageEnd = pageStart + pSize;
    const remainder = pageEnd - cachedPageSize;
    const p1 = this.getPageToken(pageNum);

    if (remainder > 0) {
      // This section spans two pages (boundary)
      const p2 = this.getPageToken(pageNum + 1);
      return combineLatest([p1.get(), p2.get()]).pipe(
        map( ([m1, m2]) => [...m1.values()].slice(pageStart).concat([...m2.values()].slice(0, remainder)))
      );
    } else
      // this section spans a single page
      return p1.get()
        .pipe(map( m => [...m.values()].slice(pageStart, pageEnd)));

  }

  /**
   * Gets a page with a page size up to cache.settings.pageSize (default)
   * @param page the page number to load
   * @param pageSize the size of a micro-page
   * @returns A list of Entities
   */
  getPage(page: number, pSize?: number): Observable<T[]> {
    if (isNaN(page) || !isFinite(page) || page < 0) return from([]);

    return pSize ? this.getMicroPage(pSize * page, pSize) : this.getFullPage(page);
  }

  /**
   * Gets a full page Token with a page size up to cache.settings.pageSize (default)
   * @param page the page number to load
   */
  getPageToken(page: number) {
    page = Math.floor(page);
    if (!this.pages.has(page))
      this.pages.set(page, new CachedPageToken(this, this.filter.setPage(page), page));

    const pageToken = this.pages.get(page);
    this.subscribeToTotal(pageToken);

    return this.initPageToken(pageToken);
  }

  private subscribeToTotal(pageToken: CachedPageToken<T>) {
    //TODO: only subscribe to the total for the first page we try to load
    this.sub = pageToken.totalForFilter.subscribe( t => {
      if(pageToken.filter.hasStatCounts){
        this.sub = pageToken.filterPropertyCounts.subscribe( fpc => {
          if (t !== null && t !== this.totalForFilter.value)
            this.totalForFilter.next(t);
          if (fpc !== null && fpc !== this.filterPropertyCounts.value){
            this.filterPropertyCounts.next(fpc);
          }
        });
      }else{
        if (t !== null && t !== this.totalForFilter.value)
          this.totalForFilter.next(t);
      }
    });
  }

  private initPageToken(token: CachedPageToken<T>) {
    // tslint:disable-next-line: deprecation
    return this.parent.initToken(token);
  }

  /**
   * Clears the cache
   * @returns the pages that need to be reloaded
   */
  clear(updates?: SignalRHandlerObject[]) {
    // this is for the clearCache() call. It will clear all pages, and force a reload for things with listeners.
    const clearAll = updates === undefined;

    // the filter may be unaffected by the signalR update, so check and skip if possible
    if (!(clearAll || this.filter.filterWillChangeFromSignalRUpdate(updates)))
      return;

    const toRemove: number[] = [];
    for (const [n, pageToken] of this.pages) {
      pageToken.clear();
      this.parent.log(`Cleared pageToken ${n}. ${pageToken.hasListeners ? 'Will' : 'Won\'t'} reload.`, pageToken, MessageService.VERBOSE);
      // if a page has no listeners, it is safe to remove.
      if (!pageToken.hasListeners)
        toRemove.push(n);
    }

    // This is where old queries are discarded
    toRemove.forEach( n => this.pages.delete(n));

    // stop listening to changes to totals for these old pages
    this.clearSubs();
    this.pages.forEach( token => this.subscribeToTotal(token));

    // return this to whoever called it, and they are responsible to refetch
    return [...this.pages.values()];
  }

  compatible(entityFilter: EntityFilter) {
    return entityFilter.identifier === this.filter.identifier;
  }

  *iterator() {
    const numPages = () => Math.ceil( this.totalForFilter.value / this.parent.settings.pageSize );
    let i = 0;
    while (i < numPages()) yield this.getPageToken(i++);

    return numPages();
  }

  *entityIterator(pageSize: number) {
    pageSize = pageSize || this.parent.settings.pageSize;
    const numPages = () => Math.ceil( this.totalForFilter.value / pageSize );
    let i = 0;
    while (i < numPages()) yield this.getPage(i++);

    return numPages();
  }

  warn(...messages) {
    this.parent.messageService.warn(...messages);
  }

  log(...messages) {
    this.parent.log(...messages);
  }
}

/** Information about the cache and its status */
export class CacheMetaData {
  // Info
  status: 'loading' | 'ready' | 'complete' | 'not ready' | 'error';
  private current: number;
  private total?: number;

  // Settings
  loadStyle: LoadStyle;
  pageSize: number;

  // Setters
  set currentEntities(v: number) { this.current = v, this.checkCompleteness(); }
  set totalEntities(v: number) { this.total = v, this.checkCompleteness(); }
  set newEntities(v: number) {
    if (this.loadStyle === 'eager')this.current += 1;
    if (this.total) this.total += 1;
    this.checkCompleteness();
  }

  constructor( settings?: Partial<Pick<CacheMetaData, 'loadStyle' | 'pageSize'>> ) {
    settings = settings || { };
    this.currentEntities = 0;
    this.loadStyle = settings.loadStyle || 'lazy';
    this.pageSize = settings.pageSize || 500;
    this.status = 'not ready';
  }

  private checkCompleteness() { if (this.total === this.current) this.status = 'complete'; }
}
