<div class="pane" *ngIf="!organization">
    <div class="pane__header pane__header--sticky bg-white">
      <div class="header">
        <h1 class="header__heading">
          Teams
        </h1>
        <div class="header__actions">
        </div>
      </div>
    </div>
    <div class="pane__body">
      <div class="generic-report-button-container" *ngIf="!organization">
        <ripple-button-list [buttons]="splitButtons"></ripple-button-list>
      </div>
      
      <ripple-entity-list #teamList [entityService]="teamService" [filter]="filter" [editButton]="edit"></ripple-entity-list>
    </div>
</div>

<div class="h-100" *ngIf="organization">
  <ripple-loading-box [loading]="!organization">
    <p-table class="ui-table--generic-report" [value]="organization.teams">
      <ng-template pTemplate="header">
          <tr>
              <th>Name</th>
              <th></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-team>
          <tr>
              <td>
                {{team.name}}
              </td>
              <td class="actions-col">
                <button pButton icon="pi pi-pencil" (click)="edit(team.id)" label="Edit"></button>
              </td>
          </tr>
      </ng-template>
    </p-table>
  </ripple-loading-box>
</div>
