<p-virtualScroller #sv [value]="entities" [scrollHeight]="height" [itemSize]="itemSize" [rows]="rows"
  [lazy]="true" (onLazyLoad)="lazyLoad($event)" [class.ui-height-100]="fullHeight">
  <ng-template let-entity pTemplate="item">
    <ng-container *ngIf="entity.id === -2"><p style="padding: 10px;">No results found...</p></ng-container>
    <ng-container *ngIf="entity.id === -1"><p style="padding: 10px;">Loading Results...</p></ng-container>
    <ng-container *ngIf="entity.id !== -1 && entity.id !== -2" [ngTemplateOutlet]="container ? container : default" [ngTemplateOutletContext]="{ $implicit: entity }"></ng-container>  
  </ng-template>
  <ng-template let-entity pTemplate="loadingItem">
    <div class="empty-item"></div>
  </ng-template>
</p-virtualScroller>

<ng-template let-entity #default>
  <div style="width: 100%; text-align: center;">{{entity.name}}</div>
</ng-template>
