import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { WarpEntity, SignalREntityMessage } from '@ripple/models';
import { environment } from '@ripple/environment';

import { MessageService } from '../general/message.service';
import { concatMap } from 'rxjs/operators';
import { Guid } from 'guid-typescript';

interface FocusDescriptor {
  id: number;
  index?: number;
  type: number;
  ancestorTypes: number[];
  successfullyIgnored: number[];
  unfocusedEntities: FocusDescriptor[];
}

export class FocusSession {
  public identity: Guid;
  private entities: Map<string, WarpEntity>;
  // tslint:disable-next-line: no-any
  constructor(private caller: any, private service: EntityFocusService) {
    this.identity = Guid.create();
    this.entities = new Map();
    this.service.log(`Creating Session ${this.identity.toString()} for ${this.caller.constructor.name}`, this);
  }
  on(entity: WarpEntity, ancestors: number[]) {
    const key = this.key(entity);
    this.service.log(`Setting Focus for Session ${key}`, this);
    // unfocus old ones, then focus this one, or just leave this one if its already here
    if (this.entities.has(key)) {
      if (this.entities.size === 1)
        return;

      this.service.unfocusEntity(...[...this.entities.entries()].map(([k, e]) => k !== key && e).filter( v => v ));
    } else {
      if (this.entities.size > 0) {
        this.service.unfocusEntity(...this.entities.values());
        this.entities.clear();
      }
      this.entities.set(key, entity);
      this.service.focusEntity(entity, ancestors);
      this.service.enableSession(this.identity);
    }
  }
  /** Only use this if you are focusing multiples (this should be fairly uncommon) */
  add(entity: WarpEntity, ancestors: number[]) {
    const key = this.key(entity);
    this.service.log(`Adding Focus for Session ${key}`, this);
    this.entities.set(key, entity);
    this.service.focusEntity(entity, ancestors);
    this.service.enableSession(this.identity);
  }
  off(entity?: WarpEntity) {
    if (entity) {
      const key = this.key(entity);
      this.service.log(`Removing Focus for Session ${key}`, this);
      if (this.entities.has(key)) {
        this.service.unfocusEntity(entity);
        this.entities.delete(key);
      }
    } else {
      this.service.log(`Removing All Focus for Session`, this);
      this.service.unfocusEntity(...this.entities.values());
      this.entities.clear();
    }
  }
  end() {
    this.service.log(`Ending   Session ${this.identity.toString()} for ${this.caller.constructor.name}`, this);
    this.service.unfocusEntity(...this.entities.values());
    this.entities.clear();
    this.service.endSession(this.identity);
  }
  private key(entity: WarpEntity) {
    return `${this.identity.toString()}_${entity.guaranteedId}`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EntityFocusService {
  // when focusing on an entity, we should ignore signalR updates to entityTypes in the ancestor list
  private focusedEntities: FocusDescriptor[] = [];
  private activeSessions: Map<Guid, FocusSession> = new Map();
  private inactiveSessions: Map<Guid, FocusSession> = new Map();
  private ignoredTypes = new Set<number>();
  private typesIgnoreFocus: Set<number>;
  private _halted = false;

  // a map in js maintains the order of insertion, like a fifo queue
  private signalRMessageQueue = new Map<number, SignalREntityMessage>();

  private signalREmitter: Subject<SignalREntityMessage[]>;

  constructor(private messageService: MessageService) {
    this.typesIgnoreFocus = new Set(environment.entityTypesIgnoreFocus);
    this.signalREmitter = new Subject();
    // tslint:disable-next-line: no-any
    (document as any).entityFocusService = this;
  }

  get isActive() { return !this._halted && this.anyFocused(); }
  get onMessages() { return this.signalREmitter.asObservable(); }
  get onMessage() { return this.signalREmitter.pipe( concatMap( list => list) ); }

  public halt(halt: boolean = true) {
    this._halted = halt;
  }

  public anyFocused(): boolean {
    return (this.focusedEntities.length > 0 || this.ignoredTypes.size > 0);
  }

  /**
   * Queues the message if needed, and returns true if successful, false if it should be sent immediately
   */
  public queueMessage(message: SignalREntityMessage): boolean {
    if (!this.ignoredTypes.has(message.entityTypeId)) {
      this.log(`Not Queuing signalR message [${message.entityId}]`, message);
      return false;
    }

    /** Otherwise queue a message */
    // this only keeps the latest action on an entity, so multiple collapse to only one, and updates are replaced by later deletes etc.
    this.signalRMessageQueue.set(message.entityId, message);
    this.log(`Queued signalR message [${message.entityId}]`, message);
    return true;
  }

  private emptyMessageQueue(clearAll = true) {
    if (this.signalRMessageQueue.size === 0) {
      this.log(`No Messages received while focused`, { before: [], after: [] });
      return;
    }

    //TODO: think about if there is a huge backlog here
    const keepAny = !clearAll && (this.focusedEntities.length > 0 || this.ignoredTypes.size > 0);

    const before = [...this.signalRMessageQueue.values()];
    const cleared = before.filter(
      msg => (!keepAny || !this.ignoredTypes.has(msg.entityTypeId)) && this.signalRMessageQueue.delete(msg.entityId) );

    this.signalREmitter.next(cleared);

    this.log(`Empty Message Queue [emitted ${cleared.length} item(s)]`, { before, after: [...this.signalRMessageQueue.values()] });
    return cleared;
  }

  /**
   * returns an object to help with managing focused entities
   * @param caller simply send your  `this`  pointer
   */
  // tslint:disable-next-line: no-any
  public createSession(caller: any) {
    const session = new FocusSession(caller, this);
    this.activeSessions.set(session.identity, session);
    return session;
  }

  /** @internal this is called by `FocusSession.end()` */
  endSession(guid: Guid) {
    if (this.activeSessions.has(guid)) {
      const session = this.activeSessions.get(guid);
      this.inactiveSessions.set(guid, session);
      this.activeSessions.delete(guid);
    }
  }

  /** @internal this is called by `FocusSession.on()` */
  enableSession(guid: Guid) {
    if (!this.activeSessions.has(guid) && this.inactiveSessions.has(guid)) {
      const session = this.inactiveSessions.get(guid);
      this.activeSessions.set(guid, session);
      this.inactiveSessions.delete(guid);
    }
  }

/**
 * Prevents signalR update to all possible ancestors of this entity (prevents the entire entityType from getting updates)
 * @param entity The warpEntity to focus on
 * @param ancestorTypes the entity type ids that should be ignored.
 */
  public focusEntity(entity: WarpEntity, ancestorTypes = []) {
    this.log(`Focus entity ${entity?.toString()}`, entity, ancestorTypes);
    if (entity?.entityId > 0)
      this._focusEntity(entity.entityId, entity.entityTypeId, ancestorTypes.filter(t => !!t));
  }

  private _focusEntity(id: number, type: number, ancestorTypes = []) {
    const unfocusedEntities = [];
    const successfullyIgnored = ancestorTypes.filter(
      /* if this type is ignorable and its not already ignored by a previous focus, then ignore and store */
      t => !this.typesIgnoreFocus.has(t) && !this.ignoredTypes.has(t) && !!this.ignoredTypes.add(t) );

    const oldIndex = this.focusedEntities.findIndex( e => e.id === id);
    if (oldIndex < 0) {
      this.log(`Internal focus entity ${id}`, { id, type, ancestorTypes });
      return this.focusedEntities.push({
        id, type, ancestorTypes,
        successfullyIgnored,
        unfocusedEntities,
      });
    }
    else // this exists already, so ignore this and make a note
      this.log(`Entity Already Focused ${id}`,
        { id, type, previousAncestorTypes: this.focusedEntities[oldIndex].ancestorTypes, ancestorTypes });
  }

  /**
   * stop focusing on an entity and let though
   * @param entity the entity to stop focusing
   */
  public unfocusEntity(...entity: WarpEntity[]) {
    if (this._unfocusEntity(...entity))
      // clear queue, but requeue any that are still in ignored types
      this.emptyMessageQueue(false);
  }

  private _unfocusEntity(...entities: WarpEntity[]): boolean {
    const ids = entities.map( e => e.entityId);

    // find earliest focus
    const i = this.focusedEntities.findIndex( e => ids.includes(e.id));

    if (i < 0) return false;

    this.log(`Unfocus entities [${ids.join(', ')}]`, ...entities);

    // if its the last one it's easy
    if (i === this.focusedEntities.length - 1) {
      this.focusedEntities.pop().successfullyIgnored.forEach( t => this.ignoredTypes.delete(t) );
      return true;
    }

    // splice will truncate focusedEntities, and place the upper half into this array, which we walk backwards and remove from ignore
    const entitiesAfter = this.focusedEntities.splice(i)
      .reduceRight( (_1, desc, _2, after) => {
        // here we unfocus all after the first one we're un-focusing, later we refocus the others
        desc.successfullyIgnored.forEach( t => this.ignoredTypes.delete(t));
        return after;
      }, [] as FocusDescriptor[]);

    // the first element of the back half is guaranteed to be in unfocus list
    const firstRemoved = entitiesAfter.shift(); // focusedEntities[i] is the first element of this list

    // put the ones after back, in order
    if (ids.length === 1)
      entitiesAfter.forEach(desc => this._focusEntity(desc.id, desc.type, desc.ancestorTypes));
    else // if extras were unfocused, we make sure not to include them in the refocus
      entitiesAfter.forEach(desc => ids.includes(desc.id) && this._focusEntity(desc.id, desc.type, desc.ancestorTypes));

    return true;
  }

  public log(title: string, ...args) {
    this.messageService.add('Entity Focus', title, ...args);
  }
}
