import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MessageService } from '../general';
import {
  Hub,
  HubService,
  HubWrapper,
  HubSubscription,
} from 'ngx-signalr-hubservice';
import { HubConnectionService, SignalRState } from './hub-connection.service';

/** @deprecated Upgrading to signalR 2.4.2 solves the issue that this was detecting */
@Injectable({
  providedIn: 'root',
})
@Hub({ hubName: 'ConnectionHub' })
export class SignalrConnectionCheckerService {
  private hubWrapper: HubWrapper;

  // private hubID = environment.mainSubscriberId ? environment.mainSubscriberId.toString() : '999';
  private hubID = Math.floor(Math.random() * 1000000000);
  private count = 0;
  private pongs = [];
  private errorCount = 0;
  private firstSignalRPing = true;

  public onMessageReceived = new EventEmitter<string>();
  public firstConnected = true;

  private errorLimit = 3;
  public pingTimer = null;

  constructor(
    private signalRConnectionService: HubConnectionService,
    private msg: MessageService
  ) {
    this.log('Initialization');
    this.signalRConnectionService.connectionChanged.subscribe(
      async (connected) => {
        if (connected && this.firstConnected) {
          this.hubWrapper = this.signalRConnectionService
            .getHubService()
            .register(this);
          this.firstConnected = false;
          try {
            await this.subscribe().toPromise();
          } catch (error) {
            this.signalRConnectionService.AlertPingProblem(false);
          }
          this.sendMessage('signalrConnected');
          this.SendPing();
        }
      });
    this.signalRConnectionService.signalrReconnected.subscribe(
      async (connected) => {
        if (connected) {
          this.log('Connection Hub Wrapper', this.hubWrapper.hub.connection.id);
          try {
            await this.subscribe().toPromise();
          } catch (error) {
            this.signalRConnectionService.AlertPingProblem(false);
          }
          this.log('Connection Hub Wrapper', MessageService.VERBOSE, this.hubWrapper);
          this.SendPing();
        }
      });
  }

  public SendPing() {
    if (this.signalRConnectionService.signalRConnectionState !== SignalRState.disconnected) {
      const num = this.count;
      // don't allow more then one at a time.
      clearTimeout(this.pingTimer);
      this.pingTimer = setTimeout(
        () => this.CheckPongReceived(num),
        this.errorCount > 0 ? 10000 : 70000);
      this.log('ping', MessageService.VERBOSE, this.count);
      this.sendMessage('ping' + this.count++);
    }
  }

  CheckPongReceived(idNumber) {
    if (!this.pongs.includes(idNumber)) {
      this.errorCount++;
      if (this.errorCount > this.errorLimit) {
        if (this.errorCount === this.errorLimit + 2) {
          const audio = new Audio();
          audio.src = 'assets/audio/NewMessage.mp3';
          audio.load();
          audio.play();
        }
        this.log(`Issue! No response for ping ${idNumber}`, idNumber, this.pongs, MessageService.ERROR);
        this.signalRConnectionService.AlertPingProblem(true);
      }
    } else {
      this.signalRConnectionService.AlertPingProblem(false);
      this.errorCount = 0;
    }
    this.SendPing();
  }

  UpdatePongList(message) {
    if (message.indexOf('ping') === 0) {
      const num = message.substring(4);
      this.pongs.push(+num);
    }
  }

  // Client => Server the function name is the same as the name of the function in the c# hub
  public sendMessage(message: string): Observable<string> {
    return this.hubWrapper
      .invoke<boolean>('sendMessage', this.hubID, message)
      .pipe(map(_ => message));
  }

  // Client => Server the function name is the same as the name of the function in the c# hub
  public subscribe(): Observable<string> {
    return this.hubWrapper.invoke<boolean>('subscribe', this.hubID)
      .pipe(map(_ => this.hubID.toString()));
  }

  public unSubscribe(): Observable<string> {
    return this.hubWrapper.invoke<boolean>('unSubscribe', this.hubID)
      .pipe(map(_ => this.hubID.toString()));
  }

  // Server => Client
  @HubSubscription()
  private receiveMessage(message: string) {
    // tslint:disable-next-line:max-line-length
    this.log('Received Message', MessageService.VERBOSE, message);
    this.UpdatePongList(message);
    // ensure that this update is for the domain we are looking at. Ignore others.
    // tslint:disable-next-line:max-line-length
    this.onMessageReceived.emit(message);
  }

  private log(...args) {
    this.msg.add('SignalR Connection', ...args);
  }
}
