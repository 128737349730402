import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache } from '@ripple/services';
import { EntityTypes,OrganizationContact } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class OrganizationContactService extends WarpEntityServiceCache<OrganizationContact> {

  constructor(injector: Injector) {
    super(injector, OrganizationContact, EntityTypes.OrganizationContact);
  }

  getSelectedOrganizationContacts(orgId) {
    return this.warpService
      ._get(`/api/sagesse/selectedOrganizationContacts/${orgId}`, 'Get Selected Organization Contacts Ids');
  }

  getSelectedContactOrganizations(contactId) {
    return this.warpService
      ._get(`/api/sagesse/selectedContactOrganizations/${contactId}`, 'Get Selected Contact Organizations Ids');
  }
}
