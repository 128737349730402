
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';

@Injectable({
  providedIn: 'root',
})
export class FormBuilderReportService extends RippleApiService {
  private restURL = '/api/report';

  constructor(http: HttpClient, authService: AuthService, messageService: MessageService) {
    super(http, messageService, authService);
  }

  public getReport(dateCompletedRange = [], clients = [], workers = [], statuses = [], formFilters = [], extraFilters = [], includeContactForms = false): Promise<any> {
    const body = {
      dateCompletedRange,
      clients,
      workers,
      statuses,
      formFilters,
      extraFilters,
      includeContactForms,
    }

   return this._post(this.restURL.concat('/entitytype/636'), body).toPromise();
  }
}

