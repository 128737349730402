import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache } from '@ripple/services';
import { Channel, EntityTypes } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class ChannelService extends WarpEntityServiceCache<Channel> {

  constructor(injector: Injector) {
    super(injector, Channel, EntityTypes.Channel);
  }

  checkChannelExceed(teamId, privacyId) {
    return this.warpService
      ._get(`/api/sagesse/checkChannelExceed/${teamId}/${privacyId}`, 'check channel Exceed');
  }
}
