import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';
import { TeamContact } from './teamContact';

export class ChannelMember extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.ChannelMember;
        if (this.teamContactEntity) {
            this.teamContactEntity = new TeamContact(this.teamContactEntity);
        }
    }

    static empty(): ChannelMember {
        return new ChannelMember(super.emptyEntity(EntityTypes.ChannelMember));
    } 

}