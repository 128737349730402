import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService, WarpEntityLogService } from '@ripple/services';
import { BasePage } from '@ripple/core'

@Component({
  selector: 'ripple-settoken',
  templateUrl: './auth.component.html',
})
export class ExternalAuthComponent extends BasePage implements OnInit {

  tokenGuid: string;

  get componentName(): string {
    return 'ExternalAuthComponent';
  }

  constructor(
    logService: WarpEntityLogService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { super(logService);  }

  ngOnInit() {
      this.tokenGuid = this.route.snapshot.paramMap.get('tokenGuid');
      if (this.tokenGuid) {
        this.authService.loginWithToken(this.tokenGuid);

        const returnUrl = this.route.snapshot.queryParams.returnUrl || '';
        this.router.navigate([returnUrl]);
      } else
        this.router.navigate(['/']);
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }
}

