import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { EntityTypes, TeamContactRoleType } from '../_core/models';

@Component({
  selector: 'sagesse-team-contact-role-type',
  templateUrl: './team-contact-role-type.component.html',
  styleUrls: ['./team-contact-role-type.component.scss']
})
export class TeamContactRoleTypeComponent extends BasePage implements OnInit {

  teamContactRoleTypeService: WarpEntityServiceCache<TeamContactRoleType>;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService
  ) {
    super(logService);
    this.teamContactRoleTypeService = factory.get(EntityTypes.TeamContactRoleType) as WarpEntityServiceCache<TeamContactRoleType>;
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (tt: TeamContactRoleType) => this.router.navigate([`${tt.entityId}`], { relativeTo: this.route });
  add = () => this.router.navigate(['add'], { relativeTo: this.route });

}
