import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
// tslint:disable: no-any

import { environment } from '@ripple/environment';

import { AuthService, MessageService } from '../general';

@Injectable({
  providedIn: 'root'
})
export abstract class RippleApiService {
  protected baseUrl: string = environment.restEndpointUrl;

  protected checkingLogin = false;

  constructor(
    private http: HttpClient,
    protected messageService: MessageService,
    protected authService: AuthService,
  ) { }

  public _get<T = any>(relativeUrl: string, action: string = 'Get', options?: any): Observable<T> {
    return this.http.get<T>(this.baseUrl + relativeUrl, options).pipe(
      tap(response => this.log(action, relativeUrl, response)),
      catchError(this.handleError(action, relativeUrl, []))
    );
  }

  public _post<T = any>(relativeUrl: string, payload: any, action: string = 'Post', options?: any): Observable<T> {
    return this.http.post<T>(this.baseUrl + relativeUrl, payload, options).pipe(
      tap(response => this.log(action, relativeUrl, payload, response)),
      catchError(this.handleError(action, relativeUrl, []))
    );
  }

  public _delete<T = any>(relativeUrl: string, action: string = 'Delete', options?: any): Observable<T> {
    return this.http.delete<T>(this.baseUrl + relativeUrl, options).pipe(
      tap(response => this.log(action, relativeUrl)),
      catchError(this.handleError(action, relativeUrl, []))
    );
  }

  protected log(action, relativeUrl, ...messages: any[]) {
    this.messageService.add('Entity Service', `${action} [${relativeUrl}]`, action, relativeUrl, ...messages);
  }

  private handleError<T>(action = 'Operation', url?: string, result?: T): (err: any, caught: Observable<any>) => Observable<T> {
    return (error: any, caught: Observable<any>): Observable<T> => {
      switch (error.status) {
        case 401:
          if (environment['useAzureAdAuth']) {
            if (!this.checkingLogin) {
              this.checkingLogin = true;
              this.authService.checkLogin({ redirectBack: true }).then(() => {
                this.checkingLogin = false;
              });
            }
          } else
            this.authService.redirectToLogin({ redirectBack: true });
          break;
        case 403:
          alert('Permission Denied: You do not have the appropriate permissions to perform this action. If you feel you should, please contact technical support.');
          break;
        case 400: // we don't actually handle these
        case 500:
          if (error?.error?.ExceptionMessage.indexOf('Current user does not have the appropriate permissions to update this item') === 0)
              alert(error?.error?.ExceptionMessage + '. If you feel you should, please contact technical support.');
          else if (error?.error?.ExceptionMessage?.indexOf('Permission Denied') === 0)
              // tslint:disable-next-line:prefer-template
              alert('Error: ' + error.error.ExceptionMessage + '. Your login does not have the appropriate permissions to update this item. If you feel you should, please contact technical support.');
          else
              alert('Server Error: Please try again later or contact technical support if the error persists. ');
          break;
        case 0:
          if (isSafari()) {
            this.messageService.add(`Entity Service Simulate Chrome Cancel on Safari ${error.status}`, action, url, error);
            return; // ignore error on Safari :)
          }
          alert('Network Connection Error: Please check your Internet connection. ');
          break;
        default:
          alert('Unknown Error: Please try again later or contact technical support if the error persists. ');
      }

      this.messageService.add(`Entity Service Error ${error.status}`, action, url, error);
      // KG 2021-11-08: reintroducing error propagation, because if one of these fail, subscriber should know about it
      return throwError(error);
    };
  }
}

function isSafari() {
  return navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0; 
}
