<p-messages [(value)]="msgs" styleClass="mb-4"></p-messages>
<p-progressBar
  mode="indeterminate"
  [hidden]="!saving"
  styleClass="mb-4"
></p-progressBar>

<!-- -------------------------------------------------- Form -------------------------------------------------- -->
<div class="form-options" *ngIf="!userUse">
  <div class="header">
    <div class="header__heading">
      <input
        class="titleEntry"
        type="text"
        size="30"
        pInputText
        [(ngModel)]="name"
        *ngIf="!userUse"
      />
      <span class="titleEntry" *ngIf="userUse" [textContent]="name"></span>

      <span
        class="pill pill--primary"
        *ngIf="currentVersion && currentVersion.isLatest"
        >Latest</span
      >
      <span
        class="pill pill--warning"
        *ngIf="currentVersion && !currentVersion.isPublished"
        >Draft</span
      >
      <span
        class="pill pill--success"
        *ngIf="currentVersion && currentVersion.isPublished"
        >Published</span
      >
    </div>
    <div class="header__actions">
      <ng-container *ngIf="!userUse">
        <p-toggleButton
          *ngIf="isRippleAdmin || requiresRippleApproval"
          [disabled]="!isRippleAdmin"
          [(ngModel)]="requiresRippleApproval"
          [onIcon]="'pi pi-exclamation-circle'"
          [onLabel]="'Requires Approval To Publish'"
          [offIcon]="overrideNeedApproval ? 'pi pi-exclamation-triangle' :  'pi pi-check-circle'"
          offLabel="Does Not Require Approval To Publish"
          [pTooltip]="publishToolTip"
          tooltipPosition="bottom"
          tooltipStyleClass="ui-tooltip-require-ripple-approval"
        ></p-toggleButton>

        <div class="ui-fluid permission-select">
          <div class="p-field">
            <label>Shown Under</label>
            <p-multiSelect
              [options]="parentOptions"
              [(ngModel)]="permissions"
              appendTo="body"
              (onChange)="toPublish = false; activeChanges = true"
            ></p-multiSelect>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- <button pButton type="button" class="ui-button-rounded ui-button-info infoBtn"
  *ngIf="currentVersion && currentVersion.isLatest" label="Latest">
  </button>
  <button pButton type="button" class="ui-button-rounded ui-button-danger infoBtn"
  label="Draft">
  </button> -->
</div>

<form [formGroup]="form" [ngClass]="{admin: isRippleAdmin}" autocomplete="off">
  <div *ngIf="!ready" style="padding-left: calc(50% - 50px)">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <formly-form
    [form]="form"
    [fields]="fields"
    [model]="model"
    [options]="options"
  >
  </formly-form>
  <div
    class="button-nav button-nav--well button-nav--justify-end"
    *ngIf="!hideControls && userUse && !viewOnly && (!hideSubmit || canSwitch)"
  >
    <span *ngIf="!hideSubmit && !viewOnly && action !== 'view' && form?.valid === false" class="pill pill--danger">
      <p style="font-size: 1.4em">Missing Required Field(s)</p>
    </span>
    <ng-container *ngIf="!canSwitch && !hideSubmit">
      <button
        type="button"
        [disabled]="disableSave"
        (click)="submitForm('save')"
        pButton
        label="Save For Later"
        icon="pi pi-save"
      >
        <i *ngIf="isSaving" class="pi pi-spin pi-spinner"></i>
      </button>
      <button
        type="button"
        [disabled]="disableSubmit"
        (click)="submitForm('submit')"
        pButton
        label="Save & Submit"
        icon="pi pi-check"
      >
        <i *ngIf="isSubmitting" class="pi pi-spin pi-spinner"></i>
      </button>
    </ng-container>
    <button
      *ngIf="canSwitch && !hideControls"
      type="button"
      [disabled]="!form.valid"
      (click)="switchViewMode()"
      pButton
      label="Builder"
      icon="pi pi-eye"
    ></button>
  </div>
</form>
<!-- ------------------------------------------------ End Form ------------------------------------------------ -->

<!-- ------------------------------------------------ Controls ------------------------------------------------ -->
<div
  class="p-flex p-flex-column p-flex-lg-row w-100 p-my-3 p-jc-center p-ai-center"
  *ngIf="builderControls && !hideControls" >

  <!-- Add a section  -->
  <button
    type="button"
    pButton
    class="p-lg-2 p-col-8"
    (click)="openAddSection()"
    icon="pi pi-plus"
    label="Add a section"
  ></button>

  <!-- Reorder sections -->
  <button
    type="button"
    pButton
    class="p-lg-2 p-col-8"
    (click)="openMoveSections()"
    icon="pi pi-sort"
    label="Reorder sections"
  ></button>

  <!-- Show JSON -->
  <button
    type="button"
    pButton
    class="p-lg-2 p-col-8"
    (click)="showJson()"
    *ngIf="development"
    icon="pi pi-info-circle"
    label="Show JSON"
  ></button>

  <!-- Preview -->
  <button
    type="button"
    pButton
    class="p-lg-2 p-col-8"
    (click)="switchViewMode()"
    icon="pi pi-refresh"
    label="Preview"
  ></button>

  <!-- Versions -->
  <button
    type="button"
    pButton
    class="p-lg-1 p-col-8"
    (click)="showVersions()"
    icon="pi pi-list"
    label="Versions"
  ></button>

  <!-- Publish | Save -->
  <button
    type="button"
    pButton
    [class]="'p-lg-1 p-col-8'"
    (click)="saveFormBuilt(toPublish)"
    [disabled]="saving || (toPublish && requiresRippleApproval && !isRippleAdmin) || shownUnderNotSelected()"
    icon="pi pi-{{ saving ? 'spin pi-cog' : 'check' }}"
    [label]="toPublish ? 'Publish' : 'Save'"
  ></button>
</div>
<!-- ---------------------------------------------- End Controls ---------------------------------------------- -->

<!-- ------------------------------------------------- Popups ------------------------------------------------- -->
<div *ngIf="builderControls">
  <!-- ---------------------------------------------- Versions ------------------------------------------------ -->
  <!-- Version Sidebar -->
  <p-sidebar
    class="version-sidebar"
    header="Options"
    [(visible)]="versionsPopup"
    position="left"
    data-backdrop="static"
  >
    <div style="overflow-y: auto; width: 100%">
      <ng-container *ngFor="let version of versions">
        <p-card
          styleClass="{{ version.ID === currentVersion.id ? 'selected' : '' }}"
          style="cursor: pointer"
          header="{{ version.Version }} | {{ version.Created | date }}"
          (click)="copyVersionMode ? this.showCopyDialog(version) : this.loadVersion(version)"
          >
          <div class="ui-card-subtitle">
            {{version.FrontendTemplate ? version.FrontendTemplate.name : ''}}
            <span class="pill pill--primary" *ngIf="version.IsLatestVersion">Latest</span>
            <span class="pill pill--success" *ngIf="version.IsPublished">Published</span>
          </div>
          <div class="version-actions" *ngIf="!copyVersionMode">
            <button pButton
              (click)="this.loadVersion(version); $event.stopPropagation()"
              class="ui-button-secondary" icon="pi pi-pencil"
              pTooltip="Edit Version"
              style="margin-top: 5%;">
            </button>

            <button pButton
              (click)="this.showCopyDialog(version); $event.stopPropagation()"
              class="ui-button-warning" icon="pi pi-copy"
              pTooltip="Copy Version To Another Form"
              style="margin-top: 5%;">
            </button>

            <button pButton
              (click)="this.openDeleteVersion(version); $event.stopPropagation()"
              class="ui-button-danger" icon="pi pi-trash"
              pTooltip="Delete Version"
              style="margin-top: 5%;">
            </button>
          </div>
        </p-card>
      </ng-container>
    </div>
  </p-sidebar>

  <!-- --------------------------------------------- End Versions --------------------------------------------- -->

  <!-- -------------------------------------------- Popup Controls -------------------------------------------- -->
  <p-sidebar
    header="Options"
    appendTo = "body"
    [(visible)]="popup.showSidebar"
    position="right"
    dismissible="false"
    *ngIf="builderControls"
    styleClass="ui-sidebar--formbuilder"
  >
    <label class="path">{{popup.path}}</label>
    <form class="sidebar-form" [formGroup]="popup.form" (ngSubmit)="popup.submit()">
      <formly-form
        [form]="popup.form"
        [fields]="popup.fields"
        [options]="popup.options"
        [model]="popup.model"
        (modelChange)="popup.onchange($event)"
      >
      </formly-form>
    </form>

    <div class="button-nav mt-3">
      <button
        *ngIf="isRippleAdmin"
        pButton
        type="button"
        icon="pi pi-palette"
        label="Json"
        (click)="popupEditJson = true"
        [disabled]="popupEditJson"
      ></button>

      <button
        type="button"
        pButton
        icon="pi pi-check"
        (click)="submit(popup)"
        [label]="popup.label"
        [disabled]="isAddDisabled()"
      ></button>
      <button
        [hidden]="hideDeleteBtn()"
        [disabled]="popupEditJson"
        id="deleteBtn"
        type="button"
        class="ui-button-warning"
        pButton
        icon="pi pi-trash"
        label="Delete"
        (click)="openDeleteModal(popup.target, popup.targetParent)"
      ></button>
    </div>
    <div class="sidebar-json" *ngIf="isRippleAdmin">
      <span>Only ripple admins see the following:</span>
      <pre>{{ popup.model | json }}</pre>
    </div>
  </p-sidebar>

  <!-- Json Popup -->
  <p-dialog
    *ngIf="isRippleAdmin"
    header="Edit Json"
    styleClass="ui-dialog--jsoneditor"
    [(visible)]="popupEditJson"
    [style]="{width: '50vw'}"
    [baseZIndex]="9999999"
    appendTo="body"
    (onShow)="popup.form.disable()"
    (onHide)="popup.form.enable()"
  >
    <json-editor
      #jsonEditor
      [debug]="development"
      [options]="popupJsonEditorOptions"
      [data]="popup.json">
    </json-editor>
    <p-footer>
      <button
        pButton
        type="button"
        icon="pi pi-check"
        (click)="updatePopupModelJson(popup, jsonEditor)"
        label="Apply"
        [disabled]="disableSave"
      ></button>
      <div class="handle">
        <i class="pi pi-sort-down"></i>
      </div>
    </p-footer>
  </p-dialog>

  <!-- Delete Confirmation -->
  <p-dialog
    header="Delete Confirmation"
    [(visible)]="popup.showModal"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    *ngIf="builderControls"
  >
    Are you sure you want to {{ popup.action }} this {{ type(popup.target) }}?
    <p-footer>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        (click)="submit(popup)"
        label="Yes"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-times"
        (click)="popup.showModal = false"
        label="No"
        class="ui-button-secondary"
      ></button>
    </p-footer>
  </p-dialog>

  <!-- Copy Confirmation -->
  <p-dialog
    header="Copy Confirmation"
    [(visible)]="copyDialogPopup"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    *ngIf="builderControls && copyTarget"
  >
    <h2>Copy version {{copyTarget.Version}} of this form to:</h2>
    <input
      class="titleEntry with-border w-100"
      type="text"
      size="30"
      pInputText
      [(ngModel)]="copyTarget.FrontendTemplate.name"
    />
    <br/><br/>
    <h2>Status: {{copyStatus.text}}</h2>
    <p-footer>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        (click)="copySelectedVersion()"
        [disabled] = "copyStatus.copying || copyStatus.copyFinished"
        [class]="copyStatus.copyError ? 'ui-button-danger' : 'ui-button-success'"
        [label]="copyStatus.copyError ? 'Retry' : 'Copy'"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-times"
        (click)="copyDialogPopup = false"
        [disabled] = "copyStatus.copying"
        label="Cancel"
        class="ui-button-secondary"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-times"
        (click)="goToNewForm(copyStatus.copiedFormID)"
        *ngIf = "copyStatus.copyFinished"
        label="Go to the new Form"
        class="ui-button-success"
      ></button>
    </p-footer>
  </p-dialog>

  <!-- Warn On Save Without Name -->
  <p-dialog
    header="Attempting to Save a Form Without a Name."
    [(visible)]="showCorrection"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    *ngIf="builderControls"
  >
    You must specify a name for this form.
    <input
      class="titleEntry with-border"
      type="text"
      size="30"
      pInputText
      [(ngModel)]="name"
    />
    <p-footer>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        (click)="saveFormBuilt(toPublish)"
        label="Yes"
      ></button>
    </p-footer>
  </p-dialog>

  <!-- Warn User that Formbuilder Contains Duplicate Keys -->
  <p-dialog
    header="Warning: Duplicate Keys Detected"
    [focusOnShow]="false"
    (onShow)="fixDuplicateKeysButton.focus()"
    [(visible)]="duplicateKeysFound"
    [style]="{ width: '50vw' }"
    [modal]="true"
    [baseZIndex]="10000"
    *ngIf="builderControls"
  >
    Due to a problem in older versions the formbuilder, it appears that your form has been generated with conflicting keys.
    <br/>
    You can choose to fix it, or to leave it for someone else to look at.
    <br/>
    <br/>
    If you chose to leave it, you may experience problems with the form.
    <p-footer>
      <button
        type="button"
        pButton
        icon="pi pi-times"
        (click)="duplicateKeysFound = false"
        label="Leave it"
      ></button>
      <button
        #fixDuplicateKeysButton
        type="button"
        pButton autofocus
        icon="pi pi-check"
        (click)="fixDuplicateKeys()"
        label="Fix it (recommended)"
      ></button>
    </p-footer>
  </p-dialog>
  <!-- ------------------------------------------ End Popup Controls ------------------------------------------ -->

  <!-- ----------------------------------------- Drag & Drop Controls ----------------------------------------- -->
  <!-- Move Controls -->
  <p-dialog
    header="Move {{ targetMoveComponentDisplay }}"
    [(visible)]="displayMoveComponents"
    [style]="{ width: '50vw' }"
    [baseZIndex]="10000"
    *ngIf="builderControls"
  >
    <ol class="move-list" style="margin: 0; margin-top: 1em">
      <li
        *ngFor="let component of dragAndDropArrays"
        style="content: null; float: left; width: 100%; margin-bottom: 1em"
        pDraggable="components"
        pDroppable="components"
        (onDragStart)="dragStart($event, component)"
        (onDrop)="drop($event, component)"
        (onDragEnd)="dragEnd($event)"
      >
        <div style="float: left">
          {{ component.internalType }} - {{ component.name }}
        </div>
      </li>
    </ol>
    <p-footer>
      <button
        type="button"
        pButton
        icon="pi pi-check"
        (click)="submitMoveComponents()"
        label="Yes"
      ></button>
      <button
        type="button"
        pButton
        icon="pi pi-times"
        (click)="displayMoveComponents = false"
        label="No"
        class="ui-button-secondary"
      ></button>
    </p-footer>
  </p-dialog>

  <!-- Copy Controls -->
  <p-dialog header="Copy Fields" [(visible)]="displayCopyFields" [style]="{ width: '50vw' }" [baseZIndex]="10000"
    *ngIf="builderControls">
    <ol class="move-list" style="margin: 0; margin-top: 1em">
      <li *ngFor="let component of fieldCopyOptions" style="content: null; float: left; width: 100%; margin-bottom: 1em">
        <div style="float: left">
          {{ component.internalType }} - {{ component.name }}
        </div>
        <button type="button" pButton icon="pi pi-plus" (click)="copyField(component)" [disabled]="component.internalType !== 'field'" label="Copy" style="float: right;"
          class="ui-button-secondary"></button>
      </li>
    </ol>


    <p-footer>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      (click)="displayCopyFields = false"
      label="Exit"
    ></button>
  </p-footer>
  </p-dialog>
  <!-- --------------------------------------- End Drag & Drop Controls --------------------------------------- -->


  <!-- --------------------------------------- End FormActions --------------------------------------- -->

  <form-builder-form-action-modal
    #formActionModal
    [formActionsV2]="formActionsV2"
    [builderControls]="builderControls"
    [formID]="id"
    [fields]="fields"
    [sections]="sections"
    (onSave)="saveFormActions($event)"
  ></form-builder-form-action-modal>


  <!-- --------------------------------------- End FormActions  --------------------------------------- -->


</div> <!-- End of Builder Controls -->

<!-- -------------------------------------------- Incoming Changes -------------------------------------------- -->
<p-dialog
  [(visible)]="incomingChangesDialogPopup"
  [style]="{ width: '50vw' }"
  [baseZIndex]="10000"
  appendTo="body"
  modal="true"
>
  <p-header>
    This has been modified.
  </p-header>
  This form has been modified by someone else and new values are available. Would you like to discard your changes or keep working and overwrite theirs?
  <p-footer>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      icon="pi pi-trash"
      label="Discard my changes"
      (click)="discardChanges()"
      style="margin: 0;"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      icon="pi pi-times"
      label="Keep my changes"
      (click)="keepChanges()"
      style="margin: 0;"
    ></button>
  </p-footer>
</p-dialog>
<p-dialog
  [(visible)]="showReSignConfirmationDialog"
  [style]="{ width: '33vw' }"
  [baseZIndex]="10001"
  appendTo="body"
  modal="true"
>
  <p-header>
    Submit Confirmation
  </p-header>
  This form has already been signed. Would you like to re-sign it, or keep the original signature?
  <p-footer>
    <button
      pButton
      type="button"
      class="ui-button-primary, mr-2"
      icon="pi pi-pencil"
      label="Re-Sign & Submit"
      (click)="makeSignatureDecisionAndSubmit(true)"
      style="margin: 0;"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      icon="pi pi-check"
      label="Keep Original Signature & Submit"
      (click)="makeSignatureDecisionAndSubmit(false)"
      style="margin: 0;"
    ></button>
  </p-footer>
</p-dialog>
<!-- ----------------------------------------------- End Popups ----------------------------------------------- -->

<!-- ----------------------------------------------- Debug Tools ---------------------------------------------- -->
<div *ngIf="development">
  <div class="mt-5" *ngIf="development">
    <button
      type="button"
      pButton
      class="btn btn-primary"
      (click)="debugTools = true"
      style="width: 100%"
    >
      Debug Tools
    </button>
  </div>

  <div class="p-grid">
    <button
      class="corner"
      type="button"
      [disabled]="!form.valid"
      (click)="development = !development"
      pButton
      [label]="development ? 'Dev Mode' : 'User Mode'"
    ></button>
  </div>

  <p-sidebar
    *ngIf="development"
    position="right"
    [(visible)]="debugTools"
    [baseZIndex]="10000"
    [style]="{ width: '80%' }"
  >
    <h1 style="font-weight: normal">Debug Tools</h1>
    <button
      type="button"
      pButton
      class="btn btn-primary"
      (click)="debugTools = false"
      icon="pi pi-arrow-left"
    ></button>
    <p-accordion [style]="{ height: '75vh' }">
      <p-accordionTab header="SaveData">
        <pre
          style="
            max-height: 75vh;
            overflow-y: scroll;
            border-radius: 3px;
            background-color: lightgray;
            padding: 5px;
            width: 100%;
          "
          >{{ getCurrentFormBuilderData() | json }}</pre
        >
      </p-accordionTab>
      <p-accordionTab header="Form Model">
        <pre
          style="
            max-height: 75vh;
            overflow-y: scroll;
            border-radius: 3px;
            background-color: lightgray;
            padding: 5px;
            width: 100%;
          "
          >{{ popup.model | json }}</pre
        >
      </p-accordionTab>
      <p-accordionTab header="Ripple">
        <pre
          style="
            max-height: 75vh;
            overflow-y: scroll;
            border-radius: 3px;
            background-color: lightgray;
            padding: 5px;
            width: 100%;
          "
          >{{ modelAsRippleProperties(model) | json }}</pre
        >
      </p-accordionTab>
    </p-accordion>
  </p-sidebar>
</div>
<!-- --------------------------------------------- End Debug Tools -------------------------------------------- -->
