import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import {
  WarpEntityCacheFactoryService,
  WarpEntityServiceCache,
  CachedQueryToken,
  MessageService,
  WarpEntityLogService,
  ExportService,
} from '@ripple/services';
import { WarpEntity, WarpEntityType, EntityFilter, FilterOperator, } from '@ripple/models';
import { BasePage } from '@ripple/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { forkJoin } from 'rxjs';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DomSanitizer } from '@angular/platform-browser';
import { first, map } from 'rxjs/operators';




export interface RippleColumn {
  order: number;
  header: string;
  field: string;
  specificDisplayType: string;
  customRenderer?(w: WarpEntity): string;
  exportRenderer?(w: WarpEntity): any;
}

export interface ListOptions {
  cols: RippleColumn[]; // only show these columns
  extraCols: RippleColumn[]; // include / overwrite specific columns
  excludeCols: string[]; // exclude columns by unchangeable name (lowercase)
  filter?: boolean;
}

export interface MenuItemOverrideWrapper{
  menuitem: MenuItem;
  disabledFunc?: (we: WarpEntity) => boolean;
  labelFunc?: (we: WarpEntity) => string;
}

type LoadTransformFunction = (entities: WarpEntity[]) => WarpEntity[];
@Component({
  selector: 'ripple-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
})
export class EntityListComponent extends BasePage implements OnInit, OnChanges {
  private currentPageSubscription: Subscription;

  get componentName(): string {
    return 'EntityListComponent';
  }

  loading: boolean;
  firstLazyLoad = true;

  @Input() hideHeader = false;
  @Input() appendTo = 'body';
  @Input() tableName: string = "";

  @Input() options: ListOptions;
  @Input() rowsPerPageOptions: number[] = [10, 25, 50];

  @Input() warpID;
  @Input() warpEntityType: WarpEntityType;
  @Input() entityService: WarpEntityServiceCache<WarpEntity>;
  @Input() allowPageSizeOverwrite = true;

  @Input() loadTransform: LoadTransformFunction = null;

  @Input() caption: string;
  /**
   * If set, will override the default action menu (delete button) with the provided menu items
   * The first item in the array will be the primary action
   * Any command functions should accept a WarpEntity as a parameter (note: MenuItem commands can take an event?:any parameter, so this is a bit of a hack)
   */
  @Input() actionMenuOverride: MenuItemOverrideWrapper[] = [];
  @Input() addButton: () => void;
  @Input() editButton: true | ((we: WarpEntity) => void);
  @Output() editButtonClicked: EventEmitter<WarpEntity> = new EventEmitter<WarpEntity>();
  @Input() editIsViewButton: boolean = false; // Edit and view buttons are essentially the same. If set to true, this just changes the label to 'View' instead of 'Edit'
  @Input() showDeleteButton = true;
  MainActionOverride: MenuItemOverrideWrapper;
  SubActionOverride: MenuItemOverrideWrapper[] = [];

  selection: WarpEntity = undefined;
  @Input() selectionMode = undefined;
  @Input() getCustomClass: (item: any) => string; // allows the caller to pass in a function that will return a custom class for each row
  @Output() rowSelect: EventEmitter<WarpEntity> = new EventEmitter<WarpEntity>();
  @Output() rowUnselect: EventEmitter<WarpEntity> = new EventEmitter<WarpEntity>();

  @ViewChild('dt') primetable;

  totalEntities = 0;
  statCounts: Map<string, number>;
  entities: WarpEntity[] = [];
  queryToken: CachedQueryToken<WarpEntity>;
  pagesizeOverride: number = this.rowsPerPageOptions[0];
  cols: RippleColumn[];
  isExporting = false;


  // filter
  @Input() sortField: string;
  @Input() sortOrder: number = 1;
  @Input() filterEntity;
  @Input() showFilter = true;
  @Input() filterOnTop = true;
  @Input() showCSVExport = false;
  @Input() afterCounts: () => void; // Callback function to run after property counts (if present) are loaded

  @Input() filter: EntityFilter;
  @Input() customFieldsForFilter: FormlyFieldConfig[];
  defaultFilter: EntityFilter;
  filterSubject: Subject<EntityFilter> = new Subject<EntityFilter>();

  queryTokenVersion = 0;

  get globalFilterFields() {
    return this.cols ? this.cols.map(c => c.field) : [];
  }

  constructor(
    protected serviceFactory: WarpEntityCacheFactoryService,
    protected messageService: MessageService,
    private confirmationService: ConfirmationService,
    logService: WarpEntityLogService,
    private sanitizer: DomSanitizer,
    private warpEntityFactory: WarpEntityCacheFactoryService,
    public exportService: ExportService
  ) {
    super(logService);
    this.nextSub = this.filterSubject.subscribe((filter) => {
      this.filter = filter;
      this.filterTable();
    });
  }

  get warpEntityTypeName() {
    return this.warpEntityType ? this.warpEntityType.name : 'All';
  }

  get warpEntityTypeNamePlural() {
    const name = this.warpEntityTypeName;
    return name.endsWith('s') ? name : name + 's';
  }

  ngOnInit(): void {
    this.loading = true;
    this.getActions();
    const weId = this.warpEntityType ? this.warpEntityType.id : this.warpID;

    this.entityService = this.entityService || this.serviceFactory.get(weId);

    if (this.warpEntityType) this.initTable();
    else
      this.sub = this.entityService
        .getEntityStructure()
        .subscribe((entityType) => {
          this.warpEntityType = entityType;
          this.initTable();
          this.filterTable();
        });

    // initialize the cache for this query
    this.filterTable();

    this.nextSub = this.currentPageSubscription = this.queryToken
      .getPage(0, this.rowsPerPageOptions[0])
      .subscribe((entities) => {
        this.entities = entities;
      });

      if (this.filter) this.defaultFilter = this.filter.Subset();
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.filter) {
      this.filterTable();
    }
  }

  ngAfterViewInit() {
    this.filterTable();
  }

  getActions(){
    if(this.actionMenuOverride.length > 0){
      this.MainActionOverride = this.actionMenuOverride[0];
      this.SubActionOverride = this.actionMenuOverride.slice(1);
    }
  }

  filterTable() {
    // if (this.queryToken && this.queryToken.filter.identifier !== this.filter.identifier) this.queryToken.totalForFilter.complete();
    this.queryTokenVersion ++;
    const localVersion = this.queryTokenVersion;
    this.queryToken = this.entityService.initQuery(this.filter, this.allowPageSizeOverwrite ? this.pagesizeOverride : undefined);
    if (this.sub) this.sub.unsubscribe();

    this.sub = this.queryToken.totalForFilter.subscribe((total) => {
      console.log(`get total for filter: local version: ${localVersion}, global version: ${this.queryTokenVersion}, page size: ${this.queryToken.pageSize}`);
      const checkPageSizeCorrect = this.allowPageSizeOverwrite ? this.pagesizeOverride == this.queryToken.pageSize : true;
      if (localVersion === this.queryTokenVersion && checkPageSizeCorrect) {
        if (total < 0) total = 0;
        this.totalEntities = total;
        this.messageService.add(
          `EntityList<${this.warpID}>`,
          'Total Entities: ' + this.totalEntities
        );
      }
    });

    if(this.filter && this.filter.hasStatCounts && localVersion === this.queryTokenVersion) {
      this.queryToken.filterPropertyCounts.subscribe((counts) => {
        if(counts !== null) {
          this.statCounts = counts;
          this.afterCounts();
        }
      });
    }

    this.messageService.add(
      `EntityList<${this.warpID}>`,
      'Init & filter',
      this.queryToken
    );

    if (this.primetable) {
      this.primetable.reset();
      this.primetable.sortField = this.sortField;
      this.primetable.sortOrder = this.sortOrder;
      this.primetable.sortSingle();
    }
  }

  initTable() {
    if (this.options && this.options.cols) this.cols = this.options.cols;
    else
      this.cols = this.warpEntityType.customFieldsInModules
        .filter((cf) => cf.displayInReports)
        .map((cf) => {
          return {
            order: cf.colOrder,
            header:
              cf.moduleSettings.modulelabel ||
              cf.moduleSettings.customlabel ||
              cf.label,
            field: cf.unchangeableName.toLowerCase(),
            specificDisplayType: cf.moduleSettings.displaytype,
            // customRenderer: (entity) => {
            //   console.log('custom renderer:', cf, entity);
            //   return entity.properties[cf.unchangeableName.toLowerCase()];
            // }
          };
        });

    if (this.options) {
      if (this.options.extraCols) this.cols.unshift(...this.options.extraCols);
      if (this.options.excludeCols)
        this.cols.filter((col) => this.options.excludeCols.includes(col.field));
    }
    this.cols.sort((col1, col2) => col1.order - col2.order);

    this.loading = false;
  }

  /**
   * Sets the page size override and refreshes the table. Assuming the page size override is enabled, the query token will be updated to use the new page size.
   * @param size The new page size
   */
  setOverridePageSize(size: number) {
    this.pagesizeOverride = size;
    this.filterTable();
  }

  lazyLoad(event: LazyLoadEvent) {
    this.loading = true;

    if(this.allowPageSizeOverwrite && event.rows != this.pagesizeOverride){
      this.setOverridePageSize(event.rows);
    }

    if (!event.sortField) {
      event.sortField = this.sortField;
      event.sortOrder = this.sortOrder;
      this.firstLazyLoad = false;
    }

    // sort
    if (event.sortField) {
      const sortField = event.sortField;
      const orderStr = event.sortOrder > 0 ? 'asc' : 'desc';
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;
      const sortedFilter = this.filter
        ? this.filter.Subset()
        : EntityFilter.None;
      sortedFilter.orderBy(sortField, orderStr);
      this.queryToken = this.entityService.initQuery(sortedFilter, this.allowPageSizeOverwrite ? this.pagesizeOverride : undefined);
    }

    const cachedPageSize = this.allowPageSizeOverwrite ? this.pagesizeOverride : this.entityService.settings.pageSize;
    const pSize = event.rows === cachedPageSize ? undefined : event.rows;

    this.entities = [];
    if (this.currentPageSubscription)
      this.currentPageSubscription.unsubscribe();
    let page$ = this.queryToken.getPage(event.first / event.rows, pSize);
    if (this.loadTransform) {
      page$ = page$.pipe(map((entities) => this.loadTransform(entities)));
    }
    this.nextSub = this.currentPageSubscription = page$.subscribe((entities) => {
        this.entities = entities;
        if (this.showDeleteButton || this.MainActionOverride) {
          this.entities.forEach((e) => {
            e.actionMenu = new Array<MenuItem>();
            if (this.showDeleteButton) {
              e.actionMenu.push({
                disabled: e.cannotDelete,
                label: 'Delete',
                icon: 'pi pi-times',
                command: () => this.delete(e),
              });
            }
            if(this.MainActionOverride){
              e.mainAction = {
                disabled: this.MainActionOverride.disabledFunc ? this.MainActionOverride.disabledFunc(e) : this.MainActionOverride.menuitem.disabled,
                label: this.MainActionOverride.labelFunc ? this.MainActionOverride.labelFunc(e) : this.MainActionOverride.menuitem.label,
                icon: this.MainActionOverride.menuitem.icon,
                command: () => this.MainActionOverride.menuitem.command(e),
              };
              if(this.SubActionOverride){
                this.SubActionOverride.forEach((item) => {
                  e.actionMenu.push({
                    disabled: item.disabledFunc ? item.disabledFunc(e) : item.menuitem.disabled,
                    label: item.labelFunc ? item.labelFunc(e) : item.menuitem.label,
                    icon: item.menuitem.icon,
                    command: () => item.menuitem.command(e),
                  });
                });
              }
            }
          });
        }
        this.loading = false;
      });
  }

  /** should not be needed any more. Now just using innerhtml to handle link */
  // isLink(col: RippleColumn, entity: WarpEntity) {
  //   if (this.prettyPrint(col, entity).indexOf('<a href="') >= 0)
  //       return true;
  //   else
  //       return false;
  // }

  // getLinkUrl(col: RippleColumn, entity: WarpEntity) {
  //   if (this.isLink(col, entity)) {
  //       const temp = this.prettyPrint(col, entity).substring(this.prettyPrint(col, entity).indexOf('<a href="') + 9);
  //       return temp.substring(0, temp.indexOf('"'));
  //   } else
  //       return this.prettyPrint(col, entity);
  // }

  // getPreLinkText(col: RippleColumn, entity: WarpEntity) {
  //   if (this.isLink(col, entity)) {
  //       const temp = this.prettyPrint(col, entity);
  //       return temp.substring(0, this.prettyPrint(col, entity).indexOf('<a href="'));
  //   }
  //   return '';
  // }

  // getPostLinkText(col: RippleColumn, entity: WarpEntity) {
  //   if (this.isLink(col, entity)) {
  //       const temp = this.prettyPrint(col, entity);
  //       return temp.substring(temp.indexOf('</a>') + 4);
  //   }
  //   return '';
  // }

  // getLinkText(col: RippleColumn, entity: WarpEntity) {
  //   if (this.isLink(col, entity)) {
  //       const temp = this.prettyPrint(col, entity).substring(this.prettyPrint(col, entity).indexOf('>') + 1);
  //       return temp.substring(0, temp.indexOf('</a>'));
  //   }
  // }

  invokeEditButton(entity: WarpEntity) {
    this.editButtonClicked.emit(entity);

    if (this.editButton && this.editButton !== true)
      this.editButton(entity);
  }

  prettyPrint(col: RippleColumn, entity: WarpEntity, isExport = false) {
    //this.messageService.add(this.constructor.name, col, entity);
    if(col.exportRenderer && isExport){
      return col.exportRenderer(entity);
    }
    if (col.customRenderer)
      return col.customRenderer(entity);

    const value = entity[col.field] || entity.properties[col.field];

    if (col.specificDisplayType)
      switch (col.specificDisplayType.toLowerCase()) {
        case 'color':
          return this.sanitizer.bypassSecurityTrustHtml(`<i class="color-dot" style="background-color: ${value}"></i>`);
        case 'yn': // yes / no => ✓ / ✗
          return this.sanitizer.bypassSecurityTrustHtml(`<span>${value ? '&#10003;' : '&#10007;'}</span>`);
        default:
          return `Invalid display type: ${col.specificDisplayType}\n\n[${value}]`;
      }

    if (value instanceof Array)
      return value.map(field => this.prettyPrintField(field)).join(', ');

    return this.prettyPrintField(value);
  }

  prettyPrintField(field) {
    return field ?
      field.name || field.optionName || field
      : '';
  }

  async exportCSV() {
    this.isExporting = true;
    const rows = [];
    const tempFilter = this.filter ? this.filter : EntityFilter.None;
    let observables: Observable<any>[] = [];

    const query = this.entityService.initQuery(tempFilter)
    const numPages = Math.ceil(this.totalEntities / query.pageSize);

    for (let i = 0; i < numPages; i++) {
      const ob = query.getPage(i);
      observables.push(ob.pipe(first()));
    }
    this.nextSub = forkJoin(observables).subscribe(dataArray => {
      for (const entities of dataArray) {
        for (const element of entities) {
          const row = {
          };
          this.cols.forEach(col => {
            row[col.header] = this.prettyPrint(col, element, true);
          });
          rows.push(row);
        }
      }
      this.loading = false;
      let fileName = this.caption || this.warpEntityTypeName;
      while(fileName.includes(' ')) {
        fileName = fileName.replace(' ', '_');
      }
      fileName = fileName + '_export';
      this.exportService.exportXLSX(rows, fileName);
      this.isExporting = false;

    });
  }

  /**
   * Gets the export CSV data that would be used to create the CSV file
   * @returns A promise that resolves to an array of objects, where each object represents a row in the table
   */
  async getExportData(){
    this.isExporting = true;
    const rows = [];
    const tempFilter = this.filter ? this.filter : EntityFilter.None;
    let observables: Observable<any>[] = [];

    for (let i = 0; i < (this.totalEntities / this.entityService.pageSize) + 1; i++) {
      const ob = this.entityService.initQuery(tempFilter).getPage(i);
      observables.push(ob.pipe(first()));
    }
    return forkJoin(observables).toPromise().then(dataArray => {
      for (const entities of dataArray) {
        for (const element of entities) {
          const row = {
          };
          this.cols.forEach(col => {
            row[col.header] = this.prettyPrint(col, element, true);
          });
          rows.push(row);
        }
      }
      this.loading = false;
      this.isExporting = false;

      return rows;
    });
  }

  delete(entity: WarpEntity) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete this ${this.caption || this.warpEntityTypeName
        }?`,
      accept: () => {
        entity.isDeleting = true;
        this.entityService.delete(entity).then((_) => {
          const i = this.entities.indexOf(entity);
          this.entities.splice(i, 1);
          this.entities = this.entities.splice(0, this.entities.length); // refresh view
        });
      },
    });
  }
}
