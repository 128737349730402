<div class="pane">
    <div class="pane__header pane__header--sticky bg-white">
      <div class="header">
        <h1 class="header__heading">
          Community Groups
        </h1>
        <div class="header__actions">
        </div>
      </div>
    </div>
    <div class="pane__body">
        <ripple-entity-list [entityService]="communityGroupService" [editButton]="edit" [addButton]="add" [showFilter]="false"></ripple-entity-list>
    </div>
</div>