<div class="pane">
    <div class="pane__header pane__header--sticky bg-white">
      <div class="header">
        <h1 class="header__heading">
          Contacts
        </h1>
        <div class="header__actions">
        </div>
      </div>
    </div>
    <div class="pane__body">
      <div class="filter-container">
        <p-accordion>
          <p-accordionTab header="Filter">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="ui-widget">
                  <label>
                      First Name
                  </label>
                </div>
                <input
                    pInputText type="text"
                    class="w-100"
                    [(ngModel)]="firstName"
                />
              </div>
              <div class="p-col-6">
                <div class="ui-widget">
                  <label>
                      Last Name
                  </label>
                </div>
                <input
                    pInputText type="text"
                    class="w-100"
                    [(ngModel)]="lastName"
                />
              </div>
              <div class="p-col-6">
                <div class="ui-widget">
                  <label>
                      Email
                  </label>
                </div>
                <input
                    pInputText type="text"
                    class="w-100"
                    [(ngModel)]="email"
                />
              </div>
              <div class="p-col-6">
                <div class="ui-widget">
                  <label>
                      Phone Number
                  </label>
                </div>
                <input
                    pInputText type="text"
                    class="w-100"
                    [(ngModel)]="phoneNumber"
                />
              </div>
            </div>
            <div class="button-nav button-nav--justify-end">
              <ripple-button-list [buttons]="splitButtons"></ripple-button-list>
            </div>
          </p-accordionTab>
        </p-accordion>
      </div>
      <div class="generic-report-button-container">
        <button pButton class="ui-button-secondary" icon="pi pi-plus" label="Add New Contact" (click)="add()"></button>
      </div>
      <p-table #dt [value]="entities" [columns]="cols" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
          [rows]="rowsPerPageOptions[0]" [showCurrentPageReport]="true" [rowsPerPageOptions]="rowsPerPageOptions" [loading]="loading"
          [paginator]="true" [lazy]="true" (onLazyLoad)="lazyLoad($event)" [totalRecords]="totalEntities"
          [filterDelay]="300" currentPageReportTemplate="{first} - {last} of {totalRecords}">
          <ng-template pTemplate="header" let-columns>
              <tr>
                  <th *ngFor="let col of columns">
                      {{col.header}}
                  </th>
                  <th></th>
              </tr>
              <!-- <tr>
                  <th *ngFor="let col of columns">
                      <input *ngIf="col.filter" pInputText type="text" (input)="dt.filter($event.target.value, col.field, 'startsWith')" placeholder="Search by {{col.field | titlecase}}" class="ui-column-filter">
                  </th>
                  <th class="actions-col" *ngIf="editButton || showDeleteButton"></th>
              </tr> -->
          </ng-template>
          <ng-template pTemplate="body" let-entity let-columns="columns">
              <tr class="ui-selectable-row">
                  <td *ngFor="let col of columns">
                    {{entity[col.field] ?
                        entity[col.field].name || entity[col.field].optionName || entity[col.field]
                        : ''}}
                  </td>
                  <td class="actions-col">
                      <p-splitButton
                          [model]="entity.actionMenu"
                          label="{{ entity.isDeleting ? 'Deleting' : 'Edit'}}"
                          icon="pi pi-{{ entity.isDeleting ? 'spin pi-spinner' : 'pencil' }}"
                          [disabled]="entity.isDeleting"
                          (onClick)="edit(entity)"
                          appendTo="body"></p-splitButton>
                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="3" class="empty-message-col">No contact found.</td>
              </tr>
          </ng-template>
      </p-table>
    </div>
</div>
