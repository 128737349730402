import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MFAStatusEnum, MFAStatus, QRCodeSetup } from '@ripple/services';

@Component({
  selector: 'ripple-mfa',
  template: `
    <span *ngIf="!success()">
      <p>
        <b>1.</b> Download the <a [href]="authURL">Google Authenticator</a> app.
      </p>
      <p>
        <b>2.</b> Using the app, scan the QR code provided below or manually
        type in the setup code.
      </p>
      <ng-container *ngIf="setup !== null">
        <img [src]="setup.imageURL" />
        <div runat="server" id="dMfaManualSetupGroup" class="form-group">
          <label>{{ setup.accountName }} {{ setup.manualKey }} </label>
        </div>
      </ng-container>
      <div class="form-group">
        <p class="input-label" *ngIf="incorrectToken()">
          <b style="color: red">Error:</b> Incorrect code, please try again
        </p>
        <label class="control-label"
          ><b>3.</b> Activate your 2-factor authentication by entering the time
          sensitive value displayed in the app</label
        >
        <input
          pInputText
          class="text-input"
          type="number"
          id="txtMfaCode"
          placeholder="Enter 2fa code"
          [(ngModel)]="mfaToken"
          style="margin-bottom: 1em"
        />
        <br />
        <p-button
          (onClick)="onCancel.emit()"
          label="Cancel"
          icon="pi {{ cancelling() ? 'pi-spin pi-spinner' : 'pi-times' }}"
          [disabled]="saving()"
          style="padding-right: .5em;"
        ></p-button>
        <p-button
          (onClick)="onSubmit.emit(mfaToken)"
          label="Submit"
          icon="pi {{ saving() ? 'pi-spin pi-spinner' : 'pi-check' }}"
          [disabled]="cancelling()"
        ></p-button>
      </div>
    </span>

    <span *ngIf="success()">
      <h2>MFA Setup Successfully</h2>
      <p style="margin-bottom: 1em">
        You will need to use the token from your Google Authenticator to login
        from now on
      </p>
      <p-button
        (onClick)="onConfirmClick.emit()"
        label="Confirm"
        icon="pi pi-check"
      ></p-button>
    </span>
  `,
})
export class MFAComponent {
  @Input() setup!: QRCodeSetup | null;
  @Input() mfaStatus!: MFAStatusEnum;
  @Output() onConfirmClick = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<number>();

  mfaToken: number = null;
  authURL = 'https://www.google.com/search?q=google+authenticator';

  success() {
    return this.mfaStatus === MFAStatus.Success;
  }

  cancelling() {
    return this.mfaStatus === MFAStatus.Cancelling;
  }

  saving() {
    return this.mfaStatus === MFAStatus.Saving;
  }

  incorrectToken() {
    return this.mfaStatus === MFAStatus.IncorrectToken;
  }
}
