<div *ngIf="!manualEntry" [ngClass]="to.enableAdditionalButton ? 'header' : ''">
  <div [ngClass]="to.enableAdditionalButton ? 'header__heading' : ''">
    <ng-container *ngIf="multi; then multiSelect else singleSelect"></ng-container>
  </div>

  <div [ngClass]="to.enableAdditionalButton ? 'header__actions' : ''">
    <button [type]="to.type"
            pButton
            [ngClass]="to.class"
            icon="{{to.icon ? to.icon : 'pi pi-plus'}}"
            iconPos="{{to.iconPos}}"
            (click)="buttonOnClick($event)"
            label="{{to.buttonText}}"
            [style]="to.style"
            *ngIf="to.enableAdditionalButton"
            [disabled]="to.disabled"
    ></button>
  </div>
</div>


<ng-template #singleSelect>
  <div [ngClass]="{z: showOverlay}">
    <input
      #search pInputText *ngIf="forceLookUp"
      class="w-100 ui-dropdown--custom-search-input"
      id='clientSearch' type="text" autocomplete="off" placeholder="Search (min. 3 characters)..."
      (keyup)="filterClients(search.value)" (keyup.Enter)="filterClients(search.value, true)"
      [(ngModel)]="searchString" [disabled]="to.disabled"
    />

    <p-dropdown #PSelect [hidden]="forceLookUp && (!actualOptions || actualOptions.length === 0)" ngStyle
      [appendTo]='appendTo'
      [baseZIndex]="99999999"
      [optionLabel]='labelName'
      [filter]="!forceLookUp"
      dataKey="id"
      [options]="actualOptions"
      [placeholder]="to.placeholder"
      [formControl]="dropdownFormControl"
      [formlyAttributes]="field"
      [readonly]="viewOnly"
      [group]="!!groupBy"
      [showClear]="true"
      (onFocus)="setOverlay(true);"
      (onHide)="setOverlay(false);tryEmitOnChange(); formControl.markAsTouched();"
      (onShow)="setEmptyMessage();"
      [styleClass]="forceLookUp ? 'ui-dropdown--custom-search-select' : ''"
      (ngModelChange)="modelChanged($event)">
      <ng-template let-option pTemplate="item">
        <div [ngClass]="{'strikethrough-option': to.isStrikethrough ? to.isStrikethrough(option) : false}">
          {{ option.label }}
        </div>
      </ng-template>
    </p-dropdown>

    <i *ngIf="optionsLoading && !to.disabled" class="pi pi-spinner pi-spin loading-spinner"></i>
  </div>
</ng-template>

<ng-template #multiSelect>
  <div [ngClass]="{z: showOverlay}">
    <input
      #search pInputText *ngIf="forceLookUp"
      class="w-100 ui-dropdown--custom-search-input"
      id='clientSearch' type="text" autocomplete="off" placeholder="Search (min. 3 characters)..."
      (keyup)="filterClients(search.value)" (keyup.Enter)="filterClients(search.value, true)"
      [(ngModel)]="searchString" [disabled]="to.disabled"
    />
    <p-multiSelect  #PSelect [hidden]="forceLookUp && (!actualOptions || actualOptions.length === 0)"
        [appendTo]='appendTo'
        [baseZIndex]="baseZIndex"
        [filter]="!forceLookUp"
        optionLabel="name"
        dataKey="id"
        [options]="actualOptions"
        [formControl]="formControl"
        [formlyAttributes]="field"
        [readonly]="viewOnly"
        [showToggleAll]="false"
        (onFocus)="setEmptyMessage();setOverlay(true);"
        (onPanelShow)="setEmptyMessage();setOverlay(true);"
        (onPanelHide)="setOverlay(false);"
        [styleClass]="forceLookUp ? 'ui-dropdown--custom-search-select' : ''"
        (ngModelChange)="modelChanged($event)"
        [dropdownIcon]="optionsLoading ? 'pi pi-spinner pi-spin' : 'pi pi-chevron-down'"
        >
        <ng-template let-option pTemplate="item">
          <div [ngClass]="{'strikethrough-option': to.isStrikethrough ? to.isStrikethrough(option) : false}">
            {{ option.label }}
          </div>
        </ng-template>
    </p-multiSelect>

  </div>
</ng-template>



<div *ngIf="manualEntry && !viewOnly">
  <input
    #me
    pInputText
    type="number"
    maxLength="8"
    style="width: 100%;"
    pKeyFilter="int"
    (keyup)="manual(me.value)"
    [value]="formControl.value && formControl.value.length ? formControl.value[0].id : -1"
  />
</div>

<div *ngIf="manualEntry && viewOnly">{{formControl.value}}</div>
