import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { EntityTypes, EvaluationDBProgramCategory } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { AgencyStatsService } from '../../_core/services';
import { NullTemplateVisitor } from '@angular/compiler';
import { MenuItem } from 'primeng';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { template } from 'lodash';

@Component({
  selector: 'sagesse-evaluation-outcome-report',
  templateUrl: './evaluation-outcome-report.component.html',
  styleUrls: ['./evaluation-outcome-report.component.scss']
})
export class EvaluationOutcomeReportComponent extends BasePage implements OnInit {
  evaluationDBProgramCategoryService: WarpEntityServiceCache<EvaluationDBProgramCategory>;
  
  loading: boolean = false;
  programCategories: EvaluationDBProgramCategory[] = [];
  reportsLoading: boolean = true;

  constructor(
    private logService: WarpEntityLogService,
    private factory: WarpEntityCacheFactoryService,
    private agencyStatsService: AgencyStatsService,
    private router: Router,
  ) {
    super(logService);
    this.evaluationDBProgramCategoryService = factory.get(EntityTypes.EvaluationDBProgramCategory) as WarpEntityServiceCache<EvaluationDBProgramCategory>;
  }


  ngOnInit(): void {

    this.nextSub = this.evaluationDBProgramCategoryService
      .initQuery(EntityFilter.None, 1000000)
      .getPage(0)
      .subscribe((result) => {
        this.programCategories = result;

        this.reportsLoading = false;
      });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  view(id) {
    this.router.navigateByUrl('evaluation-outcome-reports/' + id.toString());
  }
}
