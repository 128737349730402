import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class MonthNote extends WarpEntity {
  id: string | number;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.MonthNote;
  }

  static empty() {
    return new MonthNote(super.emptyEntity(EntityTypes.MonthNote));
  }

}
