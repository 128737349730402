<div class="w-100 h-100 py-3 px-3 p-grid">
    <div class="p-col">
        <p-card class="w-100 h-100">

            <div class="organization">

                <div class="organization__filter-pills">
                    <span class="pill" *ngIf="showNumTagMatches"># Matched Tags: {{organization.numTagMatches}}</span>
                    <span class="pill" *ngIf="showDistance">Coverage Area: {{organization.distance > 40074 ? 'Infinity' : organization.distance }} km</span>
                    <span class="pill" *ngIf="showDistance">Office: {{organization.officeDistance > 40074 ? 'Infinity' : organization.officeDistance }} km</span>
                    <!-- if the distance is longer than the length of equator (40074), it is infinity -->
                </div>

                <div class="p-grid px-5 w-100 h-100 align-items-center">
                    <div class="p-md-7 p-sm-12">
                        <div class="p-d-flex">
                            <img [src]="organization?.logo" *ngIf="organization && organization.logo"
                                class="img-thumbnail rounded organization-logo-thumbnail">
                            <span *ngIf="organization && !organization.logo"
                                class="img-thumbnail rounded organization-logo-thumbnail default-logo text-center">
                                {{organization?.name}}
                            </span>
                            <div class="ml-2">
                                <a href="/#/organization/{{organization?.entityId}}" (click)="edit()"><h3>{{organization?.name}}</h3></a>
                                <div class="mb-3">
                                    <span>
                                        <a href="http://{{organization?.website}}" target="_blank">
                                            <i class="pi pi-external-link"></i>&nbsp;http://{{ organization?.website?.length > 20 ? organization.website.substring(0, 20) + '...' : organization.website }}                                       
                                        </a>
                                    </span>
                                    &nbsp;
                                    <span>
                                        <a href="tel:{{organization?.phonenumber}}">
                                            <i class="fas fa-phone"></i>&nbsp;{{organization?.phonenumber}}
                                        </a>
                                    </span>
                                    &nbsp;
                                    <span>
                                        <a href="https://www.google.com/maps/search/{{generateHeadOfficeLocationForGoolgeMap()}}" target="_blank">
                                            <i class="fas fa-map-marker-alt"></i>&nbsp;{{generateHeadOfficeLocation()}}
                                        </a>
                                    </span> 
                                </div>
                                <div *ngIf="organization && organization.properties.organizationtags">
                                    <span *ngFor="let tag of organization?.properties.organizationtags"
                                        class="badge rounded-pill {{tagsFilter.includes(tag.id) ? 'badge--primary' : 'badge--white'}} mr-1 mb-1">
                                        {{tag.name}}
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div class="p-md-5 p-sm-12" *ngIf="organization && organization.primaryContactRoles">
                        <h4><strong>Primary Contacts</strong></h4>
                        <div class="p-grid contact-text" *ngFor="let role of organization.primaryContactRoles">
                            <div class="p-col-4">
                                <i class="{{environment.primaryContactRoleIcons[role.id]}}"></i> {{role.name}}
                            </div>
                            <div class="p-col-4">
                                <div *ngFor="let contact of role.contacts">
                                    {{contact.name}}
                                </div>
                            </div>
                            <div class="p-col-4">
                                <div *ngFor="let contact of role.contacts">
                                    <a href="tel:{{contact.phoneNumber}}"><i class="fas fa-phone"></i></a>
                                    <a href="mailto:{{contact.email}}" class="ml-3"><i class="pi pi-envelope"></i></a>
                                    <a href="javascript:;" (click)="viewContact(contact.id)" class="ml-3">View</a>
                                </div>
                            </div>           
                        </div>
                    </div>
                    
                    <div class="horizontal-line"></div>
        
                    <div class="p-md-12 p-sm-12 d-flex" *ngIf="organization">
                        <div class="flex-fill">
                            {{organization.numOfNotes}} Notes <br/>
                            <a href="javascript:;" (click)="edit()">View</a>
                        </div>
                        <div class="flex-fill">
                            {{organization.numOfContacts}} Contacts  <br/>
                            <a href="javascript:;" (click)="edit()">View</a>
                        </div>
                    </div>

                    
                </div>
            </div>
        </p-card>
    </div>
    <div class="p-md-1 p-sm-12 p-d-flex justify-content-center align-items-center"
        *ngIf="cookieService.getCookie('referenceNumber')">
        <div>
            <button pButton class="ui-button-secondary" icon="pi pi-check" label="Select" (click)="referralSelect()">
            </button>
        </div>
    </div>
</div>


