<div class="pane">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
            <h1 class="header__heading">
                Contact Role
            </h1>
            <div class="header__actions">
                <button pButton label="Back" icon="pi pi-arrow-left" (click)="back()"></button>
            </div>
        </div>
    </div>
    <div class="pane__body">
        <p-panel styleClass="ui-panel--has-footer">
            <p-header>
                Contact Role Details
            </p-header>
            <div class="p-grid p-justify-center">
                <div class="p-col-12 p-md-5">
                    <ripple-entity-details [entity]="contactRole" [hideButtons]="true"
                        [generatorOptions]="generatorOptions"
                        (afterSave)="afterSave($event)" #ed></ripple-entity-details>
                </div>
            </div>
           
            <p-footer>
              <ripple-button-list [buttons]="ed.splitButtons"></ripple-button-list>
            </p-footer>
          </p-panel>
        
    </div>
</div>