import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { CachedQueryToken, WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { LazyLoadEvent, MenuItem, ConfirmationService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { Contact, EntityTypes } from '../_core/models';
import { ContactService } from '../_core/services';

@Component({
  selector: 'sagesse-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent extends BasePage implements OnInit {

  entities: Contact[] = [];
  queryToken: CachedQueryToken<Contact>;
  cols = [
    {
      order: 0,
      header: 'First Name',
      field: 'first name',
      specificDisplayType: null
    },
    {
      order: 1,
      header: 'Last Name',
      field: 'last name',
      specificDisplayType: null
    },
    {
      order: 2,
      header: 'Email',
      field: 'email',
      specificDisplayType: null
    },
    {
      order: 3,
      header: 'Phone Number',
      field: 'phonenumber',
      specificDisplayType: null
    },
    {
      order: 4,
      header: 'Date Created',
      field: 'datecreated',
      specificDisplayType: null
    },
    {
      order: 5,
      header: 'Pronouns',
      field: 'pronouns',
      specificDisplayType: null
    }
  ];
  rowsPerPageOptions: number[] = [10, 25, 50];
  loading = false;
  contactPageSubs = { };
  totalEntities = 0;
  @ViewChild('dt') primetable;

  // filter
  firstName = '';
  lastName = '';
  email = '';
  phoneNumber = '';
  splitButtons: MenuItem[] = [
    {
      label: 'Search',
      icon: 'pi pi-search',
      command: () => {if (this.primetable) this.primetable.reset();},
    },
  ];

  public contactService: ContactService;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService,
    private confirmationService: ConfirmationService,
  ) {
    super(logService);
    this.contactService = factory.get(EntityTypes.Contact) as ContactService;
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (co: Contact) => this.router.navigate([`${co.entityId}`], { relativeTo: this.route });
  add = () => this.router.navigate(['add'], { relativeTo: this.route });

  lazyLoad(event) { 
    this.loading = true;
    if (this.contactPageSubs[event.first]) this.contactPageSubs[event.first].unsubscribe();
    this.nextSub = this.contactPageSubs[event.first] = this.contactService.contactPage(
      this.firstName.trim(), this.lastName.trim(), this.email.trim(), this.phoneNumber.trim(),event.first, event.rows)
      .subscribe(result => {
        this.totalEntities = result.total;
        let obs = result.data.map(id => this.contactService.get(id).pipe(first()));
        if (obs.length > 0) {
          forkJoin(obs).subscribe(entities => {
            this.entities = entities as Contact[];
            if (this.entities) {
              this.entities.forEach((e) => {
                e.actionMenu = new Array<MenuItem>();
                e.actionMenu.push({
                  label: 'Delete',
                  icon: 'pi pi-times',
                  command: () => this.delete(e),
                });
              });
            }
            this.loading = false;
          });
        } else {
          this.entities = [];
          this.loading = false;
        }
        
      });
  }

  delete(entity: Contact) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete this contact?`,
      accept: () => {
        entity.isDeleting = true;
        this.contactService.delete(entity).then((_) => {
          const i = this.entities.indexOf(entity);
          this.entities.splice(i, 1);
          this.entities = this.entities.splice(0, this.entities.length); // refresh view
        });
      },
    });
  }

}
