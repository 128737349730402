<div class="p-grid h-100">
    <div class="p-col-6 h-100">
        <ripple-scroll-card class="h-100">
            <div class="scroll-card-header">
                <div class="header header--pad header--bordered">
                    <h3 class="header__heading">Organizations</h3>
                    <div class="header__actions">
                        <input type="text" class="w-100" pInputText placeholder="Organization Search..." 
                            [(ngModel)]="organizationSearch"
                            (keyup)="filterOrganizations()" (keyup.Enter)="filterOrganizations(true)" />
                    </div>
                </div>
            </div>
            <!--special buttons-->
            <button class="r-btn r-btn--list-item px-4 py-1
                {{!organizationSelected && special === 'Team Member' ?
                    'r-btn--icon-primary' : ''}}"
                style="height: auto;"
                (click)="viewSpecial('Team Member')">
                <div class="p-d-flex p-align-center w-100">
                    <span><i class="pi pi-users"></i>&nbsp;All Team Members</span>
                    <div class="ml-auto">
                        <span class="person-char">{{ teamContactIdsSelected?.length }}</span>
                    </div>
                </div>
            </button>
            <button class="r-btn r-btn--list-item px-4 py-1
                {{!organizationSelected && special === 'User' ?
                    'r-btn--icon-primary' : ''}}"
                style="height: auto;"
                (click)="viewSpecial('User')">
                <div class="p-d-flex p-align-center w-100">
                    <span><i class="pi pi-users"></i>&nbsp;Sagesse Staff/Volunteers</span>
                    <div class="ml-auto">
                        <span class="person-char">{{ ifSelectAllUsers ? 'ALL' : userIdsSelected?.length }}</span>
                    </div>
                </div>
            </button>
            <button class="r-btn r-btn--list-item px-4 py-1
                {{!organizationSelected && special === 'Contact' ?
                    'r-btn--icon-primary' : ''}}"
                style="height: auto;"
                (click)="viewSpecial('Contact')">
                <div class="p-d-flex p-align-center w-100">
                    <span><i class="pi pi-users"></i>&nbsp;All Contacts</span>
                    <div class="ml-auto">
                        <span class="person-char">{{ contactIdsSelected?.length }}</span>
                    </div>
                </div>
            </button>

            <hr style="background-color: #ccc" />

            <ripple-loading-box [loading]="organizationLoading">
                <ng-container *ngFor="let org of organizationsFiltered">
                    <button class="r-btn r-btn--list-item px-4 py-1
                        {{organizationSelected && organizationSelected.entityId === org.entityId ?
                            'r-btn--icon-primary' : ''}}"
                        style="height: auto;"
                        (click)="organizationSelect(org)">
                        <div class="p-d-flex p-align-center w-100">
                            <span><i class="pi pi-sitemap"></i>&nbsp;{{org.name}}&nbsp;
                                <span class="badge rounded-pill mr-1 mb-1
                                {{organizationSelected && organizationSelected.entityId === org.entityId ?
                                    'badge--gray700' : 'badge--white'}}"
                                    *ngIf="ifNonMember(org)">
                                    Non-Member
                                </span>
                            </span>
                            <div class="ml-auto">
                                <span class="person-char">{{ org.numContacts }}</span>
                            </div>
                        </div>
                    </button>
                </ng-container>
            </ripple-loading-box>
        </ripple-scroll-card>
    </div>
    <div class="p-col-6 h-100">
        <ripple-scroll-card class="h-100">
            <div class="scroll-card-header">
                <div class="header header--pad header--bordered">
                    <div class="header__heading p-d-flex p-align-center">
                        <h3 style="margin-bottom: 0;">{{contactListTitle}}</h3>
                        <span class="p-d-flex p-align-center ml-1" *ngIf="!organizationSelected && special === 'User'">
                            <p-checkbox name="users" binary="true" [(ngModel)]="team.properties.selectallusers" (onChange)="selectAllUsers($event)">
                            </p-checkbox>
                            <label style="margin-bottom: 0;" for="binary">&nbsp;Select All?</label>
                        </span>
                    </div>
                    <div class="header__actions">
                        <input type="text" class="w-100" pInputText
                            placeholder="{{ organizationSelected ? 'Contact' : special }} Search..." 
                            [(ngModel)]="contactSearchTerms"
                            (keyup)="filterContacts()" (keyup.Enter)="filterContacts(true)" />
                    </div>
                </div>
            </div>
            <!-- <button pButton *ngIf="!organizationSelected"
                class="ui-button-secondary mb-3" icon="pi pi-plus" label="Add Contact to Team" (click)="addContactOrUser()">
            </button>
            <button pButton *ngIf="!organizationSelected"
                class="ui-button-secondary mb-3 ml-2" icon="pi pi-plus" label="Add User to Team" (click)="addContactOrUser('user')">
            </button> -->
            <ng-container *ngIf="!organizationSelected && special === 'Team Member'">
                <ripple-loading-box [loading]="pageLoading">
                    <ng-container *ngFor="let contact of contacts">
                        <div class="heading-action-row">
                            <div class="heading-action-row__heading">
                                <div class="p-grid w-100">
                                    <div class="p-col-9">
                                        <sagesse-team-contact-card [contact]="contact"></sagesse-team-contact-card>
                                    </div>
                                    <div class="p-col-3 p-d-flex align-items-center">
                                        <span class="badge rounded-pill badge--white mr-1 mb-1"
                                            *ngIf="ifNonMember(contact)">
                                            Non-Member
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="heading-action-row__actions">
                                <button pButton icon="pi pi-trash" (click)="addOrRemovePerson(contact, {checked: false})">
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </ripple-loading-box>
            </ng-container>
            <ng-container *ngIf="!organizationSelected && special === 'User'">
                <ripple-loading-box [loading]="usersLoading">
                    <ng-container *ngFor="let user of usersFiltered">
                        <div class="p-grid w-100 py-1 align-items-center">
                            <div class="p-col-2 text-center">
                                <p-checkbox name="users" [value]="user.entityId" (onChange)="addOrRemovePerson(user, $event)"
                                    class="organization-contact-checkbox" [(ngModel)]="userIdsSelected" [disabled]="ifSelectAllUsers">
                                </p-checkbox>
                            </div>
                            <div class="p-col-5 p-d-flex p-align-center">
                                <span class="person-char">{{ user.personChar }}</span>
                                {{user.properties['first name']}} {{user.properties['last name']}}
                            </div>
                            <div class="p-col-5">
                                <div class="email-label">
                                    <i class="pi pi-envelope"></i> {{user.email}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ripple-loading-box>
            </ng-container>
            <ng-container *ngIf="(!organizationSelected && special === 'Contact') || organizationSelected">
                <ripple-loading-box [loading]="pageLoading">
                    <ng-container *ngFor="let contact of contacts">
                        <div class="p-grid w-100 my-1 align-items-center">
                            <div class="p-col-2 text-center">
                                <p-checkbox name="contacts" [value]="contact.contactEntity ? contact.contactEntity.entityId : contact.entityId" (onChange)="addOrRemovePerson(contact, $event)"
                                    class="organization-contact-checkbox" [(ngModel)]="contactIdsSelected">
                                </p-checkbox>
                            </div>
                            <div class="p-col-5 p-d-flex p-align-center">
                                <span class="person-char">
                                    {{ contact.contactEntity ? contact.contactEntity.personChar : contact.personChat }}
                                </span>

                                {{ contact.contactEntity ? contact.contactEntity.properties['first name'] : contact.properties['first name'] }}
                                {{ contact.contactEntity ? contact.contactEntity.properties['last name'] : contact.properties['last name'] }}
                            </div>
                            <div class="p-col-5">
                                <div class="email-label">
                                    <i class="pi pi-envelope"></i> {{ contact.contactEntity ? contact.contactEntity.email : contact.email }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ripple-loading-box>
            </ng-container>
            
            <!-- No Pagination on Users -->
            <div class="scroll-card-footer" *ngIf="special === 'Contact' || special === 'Team Member' || organizationSelected">
                <p-paginator #myPaginator (onPageChange)="loadAndPaginate($event)" [rows]="pageSize" [totalRecords]="pageTotal"></p-paginator>
            </div>
        </ripple-scroll-card>
    </div>
</div>

<!-- <p-dialog header="Contact for {{team?.properties.name}}"
    [(visible)]="contactDialogShow"
    *ngIf="contactDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-team-contact-details
        [teamContactSelected]="teamContactSelected"
        [team]="team"
        (afterSave)="contactDialogShow = false"
        #contactDetails>
    </sagesse-team-contact-details>
    
    <p-footer>
        <ripple-button-list [buttons]="contactDetails.splitButtons"></ripple-button-list>
    </p-footer>
</p-dialog> -->

<p-dialog header="{{addingOrDeleting}}"
    [(visible)]="addingOrDeletingDialogShow"
    *ngIf="addingOrDeletingDialogShow"
    contentStyleClass="h-100"
    [baseZIndex]="10000"
    [closable]="false"
    [modal]="true">
    <ripple-loading-box [loading]="addingOrDeletingDialogShow"></ripple-loading-box>
    <h3>{{addingOrDeleting}}...</h3>
</p-dialog>

<p-dialog header="saving"
    [(visible)]="selectAllUsersDialogShow"
    *ngIf="selectAllUsersDialogShow"
    contentStyleClass="h-100"
    [baseZIndex]="10000"
    [closable]="false"
    [modal]="true">
    <ripple-loading-box [loading]="selectAllUsersDialogShow"></ripple-loading-box>
    <h3>Saving...</h3>
</p-dialog>

<p-dialog header="Duplicate"
    [(visible)]="duplicateShow"
    *ngIf="duplicateShow"
    contentStyleClass="h-100"
    [baseZIndex]="10000"
    [modal]="true">
    <h3>This person is already added to this team. If you cannot see that, try to refresh the page. </h3>
</p-dialog>

<!-- <p-dialog header="{{contactSelected?.name}}: Pick an Organization"
    [(visible)]="contactDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000"
    (onHide)="contactSelected = null">
    <ripple-loading-box [loading]="teamContactsLoading || organizationContactsLoading" *ngIf="contactDialogShow">
        <ng-container *ngFor="let contact of organizationContactsFiltered">
            <div class="p-grid align-items-center">
                <div class="p-col-2 text-center">
                    <p-checkbox name="organizationContacts" [value]="contact.entityId" (onChange)="addOrRemovePerson(contact, $event)"
                        class="organization-contact-checkbox" [(ngModel)]="organizationContactIdsSelected">
                    </p-checkbox>
                </div>
                <div class="p-col-10">
                    <sagesse-contact-organization-card [organizationContact]="contact"></sagesse-contact-organization-card>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="organizationContactsFiltered && organizationContactsFiltered.length == 0">
            This contact does not have a linked organization. Do you still want to add this contact to the team?
            <div class="text-right mt-2">
                <button pButton icon="pi pi-check" label="Yes" (click)="addOrRemovePerson(contactSelected, {checked: true}, false);contactDialogShow = false;">
                </button>
                <button pButton icon="pi pi-check" label="No" (click)="contactDialogShow = false">
                </button>
            </div>
        </ng-container>
    </ripple-loading-box>
</p-dialog> -->