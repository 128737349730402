import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';
import { FilledForm } from './filled-form';

export enum ClaresLawStatus {
    NEW = 2006,
    REFERRED = 2007,
    COMPLETE = 2008,
    REFERRALREFUSED = 2542
}

const considerationTags = ['Seniors/Elders', 'Indigenous', 'Newcomer/Immigrant/Refugees', 'Affluent domestic violence', 'Sexual Violence',
'2SLGBTQ+', 'Sexual Exploitation', 'Faith-based - Catholic', 'Faith-based - Muslim', 'Faith-based - Jewish', 'Faith-based - Christian',
'Faith-based - Hindu', 'Faith-based - Buddhist', 'Faith-based - Sikh'];

export class ClaresLaw extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.ClaresLaw;
        if (this.forms && this.forms instanceof Array)
            this.forms = this.forms.map(f => new FilledForm(f));
        
        // why sagesse
        if (this.properties.whysagesse && this.properties.whysagesse instanceof Array) {
            this.properties.whysagesse = this.properties.whysagesse[0].id.toString();
        }
        this.modify_preSave(true, ClaresLaw.claresLawPreSave);
    }

    static claresLawPreSave(w: WarpEntity): boolean {
        if (w.properties.whysagesse && typeof w.properties.whysagesse === 'string') {
            const cfcId = parseInt(w.properties.whysagesse);
            if (!isNaN(cfcId)) {
                w.cfcProperty('whysagesse', parseInt(w.properties.whysagesse));
            }
        }
        return true;
    }

    containTag(name) {
        return this.properties.organizationtags &&
            this.properties.organizationtags.findIndex(t => t.name === name) >= 0;
    }

    static empty(): ClaresLaw {
        return new ClaresLaw(super.emptyEntity(EntityTypes.ClaresLaw));
    }

    infoTable() {
        const sensitiveInfoEntity = this.sensitiveInfoEntity ? new WarpEntity(this.sensitiveInfoEntity) : null;
        //let considerationStr = considerationTags.map(tag => this.containTag(tag) ? `<li>${tag}</li>` : '').join(' ');
        let considerationStr = ``;
        if (this.properties.organizationtags) {
            considerationStr = this.properties.organizationtags.map(tag => `<li>${tag.name}</li>`).join(' ');
            considerationStr = `
                <ul style="list-style-position: inside;">
                    ${considerationStr}
                </ul>
                `;
        }
        
        return `
            <table table_id="client_info" border="1" style="width: 100%;">
                <tr row_id="date_application_received">
                <td table_id="client_info" row_id="date_application_received" cell_id="field">
                    <p>Date Application Received:</p>
                </td>
                <td table_id="client_info" row_id="date_application_received" cell_id="info">
                    <p> ${this.receiveddate}</p>
                </td>
                </tr>
                <tr row_id="applicant_id">
                <td table_id="client_info" row_id="applicant_id" cell_id="field">
                    <p>Clare’s Law File #:</p>
                </td>
                <td table_id="client_info" row_id="applicant_id" cell_id="info">
                    <p> ${this.referencenumber}</p>
                </td>
                </tr>
                <tr row_id="applicant_first_name">
                <td table_id="client_info" row_id="applicant_first_name" cell_id="field">
                    <p>First Name:</p>
                </td>
                <td table_id="client_info" row_id="applicant_first_name" cell_id="info">
                    <p> ${sensitiveInfoEntity ? sensitiveInfoEntity['first name'] : this['first name']}</p>
                </td>
                </tr>
                <tr row_id="applicant_last_name">
                <td table_id="client_info" row_id="applicant_last_name" cell_id="field">
                    <p>Last Name:</p>
                </td>
                <td table_id="client_info" row_id="applicant_last_name" cell_id="info">
                    <p> ${sensitiveInfoEntity ? sensitiveInfoEntity['last name'] : this['last name']}</p>
                </td>
                </tr>
                <tr row_id="applicant_birth">
                <td table_id="client_info" row_id="applicant_birth" cell_id="field">
                    <p>Date of Birth:</p>
                </td>
                <td table_id="client_info" row_id="applicant_birth" cell_id="info">
                    <p> ${this['birth date']}</p>
                </td>
                </tr>
                <tr row_id="applicant_gender">
                <td table_id="client_info" row_id="applicant_gender" cell_id="field">
                    <p>Gender Identity:</p>
                </td>
                <td table_id="client_info" row_id="applicant_gender" cell_id="info">
                    <p> ${this['gender']?.optionName}</p>
                </td>
                </tr>
                <tr row_id="applicant_phrase">
                <td table_id="client_info" row_id="applicant_phrase" cell_id="field">
                    <p>Verification (safe) Word/Phrase:</p>
                </td>
                <td table_id="client_info" row_id="applicant_phrase" cell_id="info">
                    <p> ${this['phrase']}</p>
                </td>
                </tr>
                <tr row_id="considerations">
                <td table_id="client_info" row_id="considerations" cell_id="field">
                    <p>Relevant Considerations</p>
                </td>
                <td table_id="client_info" row_id="considerations" cell_id="info">
                    <p>CHECK to any of following:</p>
                    ${considerationStr}
                </td>
                </tr>
                <tr row_id="city">
                <td table_id="client_info" row_id="city" cell_id="field">
                    <p>City/Town:</p>
                </td>
                <td table_id="client_info" row_id="city" cell_id="info">
                    <p> ${this.city}</p>
                </td>
                </tr>
                <tr row_id="applicant_contact_info">
                <td table_id="client_info" row_id="applicant_contact_info" cell_id="field">
                    <p>Preferred Safe Method(s) of Contact:</p>
                </td>
                <td table_id="client_info" row_id="applicant_contact_info" cell_id="info">
                    <p> ${sensitiveInfoEntity ? sensitiveInfoEntity.contactinformation : this.contactinformation}</p>
                </td>
                </tr>
                <tr row_id="applicant_contact_time">
                <td table_id="client_info" row_id="applicant_contact_time" cell_id="field">
                    <p>Preferred Contact Time:</p>
                </td>
                <td table_id="client_info" row_id="applicant_contact_time" cell_id="info">
                    <p> ${this.contacttime}</p>
                </td>
                </tr>
                <tr row_id="applicant_interpreter_required">
                <td table_id="client_info" row_id="applicant_interpreter_required" cell_id="field">
                    <p>Interpreter Required:</p>
                </td>
                <td table_id="client_info" row_id="applicant_interpreter_required" cell_id="info">
                    <p> ${this.interpreterrequired ? this.interpreterrequired.optionName : ''}
                    ${this.interpreterrequired && this.interpreterrequired.id === 333 && this.interpreterrequired.specifytext ?
                        ' - ' + this.interpreterrequired.specifytext : ''}</p>
                </td>
                </tr>
                <tr row_id="currently_receiving_supports">
                <td table_id="client_info" row_id="currently_receiving_supports" cell_id="field">
                    <p>Currently Receiving Supports:</p>
                </td>
                <td table_id="client_info" row_id="currently_receiving_supports" cell_id="info">
                    <p> ${this.currentlyreceivingsupports ? this.currentlyreceivingsupports.optionName : ''}
                    ${this.currentlyreceivingsupports && this.currentlyreceivingsupports.id === 333 && this.currentlyreceivingsupports.specifytext ?
                        ' - ' + this.currentlyreceivingsupports.specifytext : ''}</p>
                </td>
                </tr>
                <tr row_id="IfContinueReceivingSupport">
                <td table_id="client_info" row_id="IfContinueReceivingSupport" cell_id="field">
                    <p>Would you like to continue receiving support from this organization:</p>
                </td>
                <td table_id="client_info" row_id="IfContinueReceivingSupport" cell_id="info">
                    <p> ${this.currentlyreceivingsupports && this.currentlyreceivingsupports.id === 333 ? this.ifcontinuereceivingsupport?.optionName : ''}</p>
                </td>
                </tr>
                <tr row_id="PastOrCurrentRelationship">
                <td table_id="client_info" row_id="PastOrCurrentRelationship" cell_id="field">
                    <p>Past or Current Relationship:</p>
                </td>
                <td table_id="client_info" row_id="PastOrCurrentRelationship" cell_id="info">
                    <p> ${this.pastorcurrentrelationship ? this.pastorcurrentrelationship.optionName : ''}</p>
                </td>
                </tr>
                <tr row_id="CurrentlyResidewithPOD">
                <td table_id="client_info" row_id="CurrentlyResidewithPOD" cell_id="field">
                    <p>Currently Reside with POD:</p>
                </td>
                <td table_id="client_info" row_id="CurrentlyResidewithPOD" cell_id="info">
                    <p> ${this.currentlyresidewithpod ? this.currentlyresidewithpod.optionName : ''}</p>
                </td>
                </tr>
                <tr row_id="Children">
                <td table_id="client_info" row_id="Children" cell_id="field">
                    <p>Children:</p>
                </td>
                <td table_id="client_info" row_id="Children" cell_id="info">
                    <p> ${this.children ? this.children.optionName : ''}</p>
                </td>
                </tr>
                <tr row_id="LevelOfConcern">
                <td table_id="client_info" row_id="LevelOfConcern" cell_id="field">
                    <p>Level of Concern:</p>
                </td>
                <td table_id="client_info" row_id="LevelOfConcern" cell_id="info">
                    <p> ${this.levelofconcern}</p>
                </td>
                </tr> 
                <tr row_id="notes">
                <td table_id="client_info" row_id="notes" cell_id="field">
                    <p>Notes</p>
                </td>
                <td table_id="client_info" row_id="notes" cell_id="info">
                    <p> ${this.notes}</p>
                </td>
                </tr>
            </table>`;
    }

}