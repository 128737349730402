import { SignalRHandlerObject } from '../interfaces/signalr-messages';
import { CustomFieldInModule } from './custom-field-in-module';

type FilterValue = boolean | string | number | Date | string[] | number[] | Date[];

export interface BackendPagingOptions {
  PageIndex: number;
  PageSize: number;
  OrderByColumn: string;
  Descending: boolean;
  RelevancyWeights : {
    [key: string]: number;
  }
  computedProperties: {
    [key: string]: ComputedPropertyEquation;
  }
  computedPropertyChildrenSets: ComputedPropertyChildrenSet[];

  EntityTypeId: number;
  SearchTerm: string;
  FilterSearchDate: boolean;
  FilterSearchDateTime: boolean;
  AdditionalEntityTypes: string;
  parentId?: number;

  // AdvancedFilterString: string;
  AdvancedFilterStringIntersect: string;
  AdvancedFilterStringUnion: string;
  ExcludeAdvancedFilterString: string;
  ExcludeAdvancedFilterStringUnion: string;

  LinkedPropertiesFilters: LinkedBackendPagingOptions[];
  matchAnyLinkedPropertyFilters: boolean;

  ReflexLinkedPropertiesFilters: LinkedBackendPagingOptions[];
  matchAnyReflexiveLinkedPropertyFilters: boolean;


  statCountRequests: statCountRequest[];
  includeChildrenProperties: {[key: string]: string[]};

  excludeEntitiesFromPreviousPage?: boolean;

  AllMustMatch: boolean;
  DontShowAllRecordsUpAndDownTree: boolean;
  sendOnlyIds: boolean;

  AllowIds?: number[]; // only include entities in this list of ids
  BlockIds?: number[]; // don't include entities in this list
}

export enum FilterOperator {
  Like = '=%',
  EqualTo = '=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqualTo = '>=',
  LessThanOrEqualTo = '<=',
  InList = '[]',
  NotEqualTo = '!=',
  InListChild = '[c]',
  InListParent = '[p]',
  NullCoalesce = '??',
  Between = '<->'
}

export enum AggregateOperator {
  Max = 'MAX',
  Min = 'MIN',
  Count = 'COUNT',
  Average = 'AVG',
  Sum = 'SUM',
}

export enum ComputedPropertyOperator {
  Add = '+',
  Subtract = '-',
  Multiply = '*',
  Divide = '/',
  DateDifference = 'd-d',
  Max = 'MAX',
  Min = 'MIN',
  Count = 'COUNT',
  Average = 'AVG',
  Sum = 'SUM',
  Case = 'CASE',
}

export enum CaseConditionOperator {
  Equals = '=',
  NotEquals = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqualTo = '>=',
  LessThanOrEqualTo = '<=',
  Like = '%=',
  InList = '[]',
  Between = '<->',
  NotNull = '!NULL',
  And = '&&',
  Or = '||'
}
export interface FilterToken {
  key: string;
  value: FilterValue;
  operator: FilterOperator;
}

// tslint:disable-next-line: no-any
function instanceOfFilterToken(object: any): object is FilterToken {
  return 'key' in object && 'value' in object && 'operator' in object;
}

export type FilterInput = Map<string, FilterValue> | { [key: string]: FilterValue } | FilterToken | FilterToken[];

/** Stores information about an entity query */
// @dynamic
export class EntityFilter {
  private static empty = new Map();

  private constructor(
    private _page: number = 0,
    private matchAll = false,
    private sendMinimum = false,
    private parentId: number = null,
    private _pageSize: number = null,
    private _entityIds: number[] = [],                                                     // shallow
    private _allowIds: number[] = [],                                                      // shallow
    private _blockIds: number[] = [],                                                      // shallow
    private _loadChildren: number[] = [],                                                  // shallow
    private _orderBy: OrderObject = new OrderObject(),                                     // shallow
    private simpleStr: string = '',
    private excludeStr: string = '',
    private excludeUnionStr: string = '',
    private advancedStr: string = '',
    private advancedUnionStr: string = '',
    private _linkedPropertiesFilters: LinkedPropertyFilter[] = [],                         // shallow
    private _matchAnyLinkedPropertyFilters: boolean = false,
    private _reflexiveLinkedPropertiesFilters: LinkedPropertyFilter[] = [],                // shallow
    private _matchAnyReflexiveLinkedPropertyFilters: boolean = false,
    private _statCountRequests: statCountRequest[] = [],                                   // shallow
    private computedProperties: ComputedPropertyRequests = new ComputedPropertyRequests(), // shallow
    private _includeChildrenProperties: { [key: string]: string[] } = null,                // shallow
    private _excludeEntitiesFromPreviousPage: boolean = true,
    public apiRoute: string = '',
    public apiBody: {} = {},
    public apiMethod: 'GET' | 'POST' = 'POST',
  ) {
    // KG: moved property defs to the constructor, so we can see the defaults more easily
  }

  /** this will be a shallow clone. for a deep clone, use the Subset function */
  public Clone(): EntityFilter {
    return new EntityFilter(
      this._page,
      this.matchAll,
      this.sendMinimum,
      this.parentId,
      this._pageSize,
      this._entityIds,
      this._allowIds,
      this._blockIds,
      this._loadChildren,
      this._orderBy,
      this.simpleStr,
      this.excludeStr,
      this.excludeUnionStr,
      this.advancedStr,
      this.advancedUnionStr,
      this._linkedPropertiesFilters, // Note that this will be a shallow copy
      this._matchAnyLinkedPropertyFilters,
      this._reflexiveLinkedPropertiesFilters, // also a shallow copy
      this._matchAnyReflexiveLinkedPropertyFilters,
      this._statCountRequests, // also also a shallow copy
      this.computedProperties,
      this._includeChildrenProperties,
      this._excludeEntitiesFromPreviousPage,
      this.apiRoute,
      this.apiBody,
      this.apiMethod
    );
  }

  /**
   * converts one or many filter tokens to a string
   */
  private urlMap(_tokens: FilterToken[]): string {
    return _tokens
      .sort()
      .map((token) => {
        let val = token.value instanceof Array ? token.value : [token.value];
        if (token.operator === FilterOperator.Like)
          val = val.map(v => `%${v}%`); // KG: don't need to http escape for POST calls

        const op = token.operator === FilterOperator.Like ? '=' : token.operator;

        if (token.key === 'created')
          token.key = 'filterbycreated';
        if (token.key === 'updated')
          token.key = 'filterbylastupdated';

        let joinchar = "|";
        // Backend expects a ~ between date ranges, not sure what else may expect this
        if(token.operator === FilterOperator.Between && val instanceof Array && val[0] instanceof Date && val[1] instanceof Date){
          joinchar = "~";
        }
        return `${token.key}${op}${val.join(joinchar)}`.replace(',', ',,');
      })
      .join(',');
  }

  /**
   * Appends a filter to a specific filter region (non-destructive)
   * @param name the filter region `simpleStr | excludeStr | excludeUnionStr | advancedStr | advancedUnionStr`
   * @param f the filter input
   */
  protected appendStr(name: string, f: FilterInput | string) {
    // tslint:disable-next-line: curly
    if (this[name]) {
      // SimpleStr is individual params, the others are comma separated
      //   Simple:    var=x&var2=y
      //   Advanced:  advancedFilter=var=x,var2=y
      if (name === 'simpleStr') this[name] += '&';
      else this[name] += ',';
    }

    if (typeof f === 'string') {
      this[name] += f;
      return this;
    }

    // convert to tokens
    let tokens: FilterToken[] = f as FilterToken[];
    if (!(tokens instanceof Array) && instanceOfFilterToken(f))
      tokens = [f];
    else if (!(tokens instanceof Array)) {
      const entries = f instanceof Map ? [...f.entries()] : Object.entries(f);
      tokens = entries.map(([key, value]) => ({ key, value, operator: FilterOperator.EqualTo }));
    }

    this[name] += this.urlMap(tokens);
    return this;
  }

  protected trimforQueryString(s: string) {
    s = s.replace(/[^&\?,]+=&|undefined/, '').replace(/&&+/, '&');
    if (s.startsWith('&')) return s.substr(1);
    if (s.endsWith('&')) return s.substr(0, s.length - 2);
    return s;
  }

  //#region General filter info
  public get identifier(): string {
    //TODO: need to include children prop filters
    return this.querystring + "-" + this.apiRoute + "-" + this.apiMethod + "-" + JSON.stringify(this.apiBody);
  }

  get page() {
    return this._page;
  }

  get pageSize() {
    return this._pageSize;
  }

  get paging() {
    const showIf = (name: string) => this[name] ? `${name}=${this[name]}` : '';
    const showArray = (name: string) => this[name] ? `${name.replace('_', '')}=${this[name].join(',')}` : '';
    return this.trimforQueryString(
      `${this.simpleStr}&${this._orderBy}` +
      `&${showIf('sendMinimum')}&${showIf('matchAll')}&${showIf('parentId')}` +
      `&${showArray('_loadChildren')}`
      );
  }

  /** This is only used for comparing entity filters to each other */
  get simple() {
    const showArray = (name: string) => this[name] ? `${name.replace('_', '')}=${this[name].join(',')}` : '';
    return this.trimforQueryString(`${this.paging}&${showArray('_entityIds')}&${showArray('_allowIds')}&${showArray('_blockIds')}`
      );
  }

  get advanced() {
    return this.trimforQueryString(`advancedFilter=${this.advancedStr}&advancedFilterUnion=${this.advancedUnionStr}`);
  }

  get exclude() {
    return this.trimforQueryString('excludeAdvancedFilter=' + this.excludeStr + '&excludeAdvancedFilterUnion=' + this.excludeUnionStr);
  }

  get linked(){
    let linkedstring = '';
    if(this._linkedPropertiesFilters){
      for(let i = 0; i < this._linkedPropertiesFilters.length; i++){
        linkedstring += '[' + this._linkedPropertiesFilters[i].filter.querystring + '],';
      }
    }
    let reflexivelinkedstring = '';
    if(this._reflexiveLinkedPropertiesFilters){
      for(let i = 0; i < this._reflexiveLinkedPropertiesFilters.length; i++){
        reflexivelinkedstring += '[' + this._reflexiveLinkedPropertiesFilters[i].filter.querystring + '],';
      }
    }
    return `linkedProperties=${linkedstring}&reflexiveLinkedProperties=${reflexivelinkedstring}`;
  }

  get statcountsstring(){
    let statcountsstring = '';
    if(this._statCountRequests){
      for(let i = 0; i < this._statCountRequests.length; i++){
        // if the filter is defined, add it to the string
        let pagingoptionsstring = '';
        if(this._statCountRequests[i].filter){
          pagingoptionsstring = this._statCountRequests[i].filter.pagingOptions.AdvancedFilterStringIntersect + '&';
          pagingoptionsstring += this._statCountRequests[i].filter.pagingOptions.AdvancedFilterStringUnion + '&';
          pagingoptionsstring += this._statCountRequests[i].filter.pagingOptions.ExcludeAdvancedFilterString;
        }
        statcountsstring += this._statCountRequests[i].name + pagingoptionsstring + ',';
      }
    }
    return `statCounts=${statcountsstring}`;
  }

  get computedPropertiesString(){
    return this.trimforQueryString(this.computedProperties.toString());
  }

  get hasStatCounts() {
    return this._statCountRequests.length > 0;
  }

  get hasComputedProperties() {
    return this.computedProperties && this.computedProperties.Properties.size > 0;
  }

  get hasLoadChildren() {
    return this._loadChildren && this._loadChildren.length > 0;
  }

  get loadChildrenIds() {
    return this._loadChildren;
  }

  get hasIncludeChildProperties() {
    return this._includeChildrenProperties && Object.keys(this._includeChildrenProperties).length > 0;
  }

  get includeChildrenPropertiesObj() {
    return this._includeChildrenProperties;
  }

  get computedPropertiesObject(){
    return this.computedProperties;
  }

  get parent() {
    return this.parentId;
  }

  get isDirectAccess() {
    return this._entityIds && this._entityIds.length > 0;
  }

  get isApiRoute() {
    return !!this.apiRoute;
  }

  /** if this is not directAccess, this will return null */
  get filteredIds() {
    return this.isDirectAccess ? this._entityIds : null;
  }

  public filterWillChangeFromSignalRUpdate(updates: SignalRHandlerObject[]) {
    // if I am direct access, we only need to refetch if any of my ids are in the updates.
    if (this.isDirectAccess)
      return updates.some(update => this._entityIds.includes(update.entityId));

    // all queries that use a filter may change order or included entities, on any change for the entity type.
    // eg. searching for clients named JOHN:
    //  - if any client changes, they may be john now, or may no longer be john (we dont know, so can't cache.)
    // TODO: add which properties are changed in the signalR message, and check if they are relevant to this filter here
    return true;
  }

  /**
   * Generates the api querystring for the entire filter
   */
  get querystring() {
    let qs = [
      this.simple,
      this.advanced,
      this.exclude,
      this.linked,
      this.statcountsstring,
      this.computedPropertiesString,
    ].join('&');

    qs = this.trimforQueryString(qs);
    return qs;
  }

  // get apiUrl() {
  //   return this.apiURL;
  // }

  // get apiBody() {
  //   return this.apiURL;
  // }

  get backendPagingOptions() {
    const lazyList = (list) => list && list.length ? list : undefined;
    const orderBy = this._orderBy.all[this._orderBy.all.length - 1] || { name: null, direction: 'asc' };
    const pagingOptions: BackendPagingOptions = {
      PageIndex:                       this._page,
      PageSize:                        this._pageSize,
      AllMustMatch:                    this.matchAll,
      OrderByColumn:                   orderBy.name,
      Descending:                      orderBy.direction === 'desc',
      RelevancyWeights :               orderBy.relevancyWeights,
      FilterSearchDate:                this.getFlagFromSimple('filterSearchDate'),
      FilterSearchDateTime:            this.getFlagFromSimple('filterSearchDateTime'),
      DontShowAllRecordsUpAndDownTree: this.getFlagFromSimple('dontShowAllRecordsUpAndDownTree'),
      SearchTerm:                      this.getFromSimple('search.value'),
      AdditionalEntityTypes:           this.getFromSimple('additionalEntityTypes'),
      parentId:                        this.parentId,
      AdvancedFilterStringIntersect:   this.advancedStr,
      AdvancedFilterStringUnion:       this.advancedUnionStr,
      ExcludeAdvancedFilterString:     this.excludeStr,
      ExcludeAdvancedFilterStringUnion:this.excludeUnionStr,
      EntityTypeId:                    999999,
      AllowIds:                        lazyList(this._allowIds),
      BlockIds:                        lazyList(this._blockIds),
      LinkedPropertiesFilters:         this._linkedPropertiesFilters.map(lpf => lpf.backendPagingOptions),
      matchAnyLinkedPropertyFilters:   this._matchAnyLinkedPropertyFilters,
      ReflexLinkedPropertiesFilters:   this._reflexiveLinkedPropertiesFilters.map(lpf => lpf.backendPagingOptions),
      matchAnyReflexiveLinkedPropertyFilters: this._matchAnyReflexiveLinkedPropertyFilters,
      statCountRequests:               this._statCountRequests,
      computedProperties:              this.computedProperties.convertMapToObj(),
      computedPropertyChildrenSets:    this.computedProperties.ChildrenSets.map(cs => ({
        EntityTypeId: cs.EntityTypeId,
        Label: cs.Label,
        Filter: cs.Filter,
        LinkedProperty: cs.LinkedProperty,
        // strip off the entityFilterObject
      } as ComputedPropertyChildrenSet)),
      includeChildrenProperties:       this._includeChildrenProperties,

      excludeEntitiesFromPreviousPage: this._excludeEntitiesFromPreviousPage,
      // this changes the format of the data response, so its better if this is handled elsewhere
      sendOnlyIds:                     false,
    };

    return {
      sendMinimum: this.sendMinimum,
      entityIds: this._entityIds.flatMap( v => v),
      pagingOptions
    };
  }

  getFromSimple(name: string): string {
    const match = new RegExp(`${name}=(.+?)(?:&|$)`).exec(this.simpleStr);
    return match && match[1];
  }

  getFlagFromSimple(name: string): boolean {
    const match = this.getFromSimple(name);
    return !!match && match[1].toLowerCase() === 'true';
  }

  /**
   * Requests the count of a stat from the API while filtering
   * Useful for getting those counts while paging, since the frontend can't tabulate data it doesn't have
   * Note that one of subsetFilter and uniqueProperty must be defined, otherwise this function will do nothing
   * If only subsetFilter is defined, the stat will be the size of the resulting subset
   * If only uniqueProperty is defined, the stat will be the number of unique values of that property
   * If both subsetFilter and uniqueProperty are defined, the stat will be the number of unique values of that property in the resulting subset
   *
   * Note that these counts will be included in the response data, but the frontend objects managing that response data may not be aware of them
   * @param statName The label you want to give the stat. This will be the key in the response data. No whitespace allowed
   * @param subsetFilter The filter to be applied before counting. Useful for getting counts of a subset of the data (ie. only clients with a specific name)
   * @param uniqueProperty the unchangeable name of the property to get the unique count of. If it is a linked property and you wish to count by id, use the format 'linkedProperty_lid' (ie. 'clients_lid')
   */
  requestStatCount(statName: string, subsetFilter?: EntityFilter, uniqueProperty?: string, aggregateOperator: AggregateOperator = AggregateOperator.Count): EntityFilter {
    // if both subsetFilter and uniqueProperty are undefined, do nothing
    if(!subsetFilter && !uniqueProperty) return;

    // otherwise, add the stat request to the map
    // note that the entityTypeId will be assumed by the backend to be the same as the main filter
    let tempPagingOptions = subsetFilter ? subsetFilter.backendPagingOptions : null;
    if(tempPagingOptions){
      tempPagingOptions.pagingOptions.PageSize = 100000;
    }

    // remove whitespace from statName
    statName = statName.replace(/\s/g, '');
    let statCountRequest: statCountRequest = {name: statName, filter: subsetFilter? tempPagingOptions : null, uniquePropertyName: uniqueProperty, aggregateOperator: aggregateOperator || AggregateOperator.Count} as statCountRequest;
    this._statCountRequests.push(statCountRequest);

    return this;
  }

  /**
   * Adds a computed property to the filter. Do use this property through the filter, use the label you provide here appended with '_cp'
   * eg. if you add a computed property with label 'myComputedProperty', you can access it through the filter with 'myComputedProperty_cp'
   * @param label The label of the computed property, which gets appended with '_cp'. This will be the key to use throughout the filter
   * @param equation The equation to calculate the computed property. This is an equation object, which can be created with the ComputedPropertyEquation class
   * @param childsets A list of childsets that any aggregate functions in the equation will use. If a childset has already been added in another computed property, you do not need to add it again
   */
  addComputedProperty(label: string, equation: ComputedPropertyEquation, childsets: ComputedPropertyChildrenSet[] = []): EntityFilter {
    this.computedProperties.addProperty(label + "_cp", equation);
    childsets.forEach(childset => {
      this.computedProperties.addChildSet(childset);
    });
    return this;
  }

  /**
   * Sets the computed properties of the filter. This will overwrite any computed properties that have already been set
   * @param obj A computed property requests object, likely from another filter
   */
  setComputedProperties(obj: ComputedPropertyRequests){
    this.computedProperties = obj;
  }

  /**
   * Creates a computed property equation object, which can be used to create computed properties\
   * TODO: A nice to have would be a tokenizer/interpreter for equations, so that you could just pass in a string and it would parse it into an equation object
   * @param leftSide The left side of the equation. This can be a string (property name), a number (constant), or another equation. For aggregate functions, this must be a childset
   * @param rightSide The right side of the equation. This can be a string (property name), a number (constant), or another equation. For aggregate functions, this must be a property name or empty string in the case of COUNT
   * @param operator The operator to use in the equation.
   * @param operatorOption An optional operator option. This is only used for date difference at the moment, and can be 'day', 'week', 'month', or 'year'
   * @param defaultValue An optional default value to use if the equation results in null. Can either be a property name or a constant
   * @returns A computed property equation object
   */
  public static createComputedPropertyEquation(
    leftSide: string | number | ComputedPropertyOperand | ComputedPropertyEquation | ComputedPropertyEquation[],
    rightSide: string | number | ComputedPropertyOperand | ComputedPropertyEquation | ComputedPropertyOperand[],
    operator: ComputedPropertyOperator | CaseConditionOperator,
    operatorOption?: string,
    defaultValue?: string | number,
    overrideDefaultValueOperandType?: "property" | "constant" | "date" | "equation" | "set" | "case",
  ): ComputedPropertyEquation{
    let defaultOperand: ComputedPropertyOperand = null;
    if(defaultValue !== undefined && defaultValue !== null){
      if(typeof defaultValue === "string"){
        defaultOperand = this.createComputedPropertyOperand(overrideDefaultValueOperandType || "property", {stringData: defaultValue});
      }else{
        defaultOperand = this.createComputedPropertyOperand(overrideDefaultValueOperandType || "constant", {numberData: defaultValue});
      }
    }
    // denote if operator is AGGREGATE function in backend
    let isAggregate = [
      ComputedPropertyOperator.Count, ComputedPropertyOperator.Average, ComputedPropertyOperator.Sum,
      ComputedPropertyOperator.Max, ComputedPropertyOperator.Min
    ].some(o => operator === o);
    if(isAggregate){
      const leftOperand = this.createComputedPropertyOperand("set", {stringData: leftSide as string});
      const rightOperand = this.createComputedPropertyOperand("property", {stringData: rightSide as string});
      return new ComputedPropertyEquation(leftOperand, rightOperand, operator, operatorOption, defaultOperand);
    }else if (operator === ComputedPropertyOperator.Case){
      if (!Array.isArray(leftSide) || !Array.isArray(rightSide))
        throw new Error("Case operator requires an array of strings for left side, and an array of operands for the right side");
      let leftOperand: ComputedPropertyOperand = this.createComputedPropertyOperand("case", {caseConditionData: leftSide});
      let rightOperand: ComputedPropertyOperand = this.createComputedPropertyOperand("case", {caseResultData: rightSide});
      return new ComputedPropertyEquation(leftOperand, rightOperand, operator, operatorOption, defaultOperand);
    }else{
      let leftOperand: ComputedPropertyOperand;
      let rightOperand: ComputedPropertyOperand;

      if (leftSide instanceof ComputedPropertyOperand) {
        leftOperand = leftSide;
      }else if (leftSide instanceof ComputedPropertyEquation) {
        leftOperand = this.createComputedPropertyOperand("equation", {equationData: leftSide as ComputedPropertyEquation});
      }else if(typeof leftSide === "string"){
        leftOperand = this.createComputedPropertyOperand("property", {stringData: leftSide});
      }else if(typeof leftSide === "number"){
        leftOperand = this.createComputedPropertyOperand("constant", {numberData: leftSide});
      }else{
        throw new Error("Invalid left side operand detected.");
      }

      if (rightSide instanceof ComputedPropertyOperand) {
        rightOperand = rightSide;
      }else if (rightSide instanceof ComputedPropertyEquation) {
        rightOperand = this.createComputedPropertyOperand("equation", {equationData: rightSide as ComputedPropertyEquation});
      }else if(typeof rightSide === "string"){
        rightOperand = this.createComputedPropertyOperand("property", {stringData: rightSide});
      }else if(typeof rightSide === "number"){
        rightOperand = this.createComputedPropertyOperand("constant", {numberData: rightSide});
      }else{
        throw new Error("Invalid right side operand detected.");
      }

      return new ComputedPropertyEquation(leftOperand, rightOperand, operator, operatorOption, defaultOperand);
    }
  }

  public static createComputedPropertyOperand(
    operandType: "property" | "constant" | "date" | "equation" | "set" | "case",
    operandProperties: {
      stringData?: string,
      numberData?: number,
      equationData?: ComputedPropertyEquation,
      caseConditionData?: ComputedPropertyEquation[],
      caseResultData?: ComputedPropertyOperand[]
    } = {
      stringData: undefined, numberData: undefined, equationData: undefined, caseConditionData: undefined, caseResultData: undefined
    }
  ): ComputedPropertyOperand {
    return new ComputedPropertyOperand(operandType, operandProperties.stringData, operandProperties.numberData, operandProperties.equationData, operandProperties.caseConditionData, operandProperties.caseResultData);
  }

  /**
   * Creates a computed property children set, which can be used to create computed properties
   * @param label The label of the children set
   * @param entityTypeId The entity type id of the children
   * @param filter The filter to apply to the children. Use EntityFilter.None or EntityFIlter.All() to get all children
   * @param LinkedProperty The unchangeable name of the linked property (of the child) that links the children to the parent. eg. a clientfile's 'clients' property
   * @returns The computed property children set
   */
  public static createChildSet(label: string, entityTypeId: number, filter: EntityFilter, LinkedProperty: string){
    return new ComputedPropertyChildrenSet(entityTypeId, label, filter, LinkedProperty);
  }

  //#endregion

  //#region Chaining
  /**
   * For Chaining, oder by a property name or created / updated
   * @param name 'created' | 'updated' | cfim.unchangeablename
   * @param direction default ascending
   */
  orderBy(name: string, direction: 'asc' | 'desc' = 'asc', relevancyWeights?: {
    [key: string]: number
  }) {
    this._orderBy.add({ name, direction, relevancyWeights });
    return this;
  }

  /**
   * For Chaining, sets the page of the filter
   * @param page the page number
   */
  setPage(page: number | undefined) {
    if (typeof page !== 'undefined') this._page = page;
    return this;
  }

  /**
   * For Chaining, sets how many entities to get in each page
   * @param num the number of entities to get (per page), 0 resets to default
   */
  top(num: number) {
    this._pageSize = num ? num : undefined;
    return this;
  }

  /**
   * For Chaining, set whether to only get entity ids and text from API
   * @param sendMinimum defaults to set to only send IDs
   */
  idsOnly(sendMinimum: boolean = true) {
    this.sendMinimum = sendMinimum;
    return this;
  }

  // TODO: Convert to private, and only allow static calls, due to no chaining
  /**
   * Get specific entities by id
   * NOTE: Does not work with chaining, directly gets ids
   * @param ids ids of entities to be loaded
   */
   only(...ids: number[]) {
    if (!this._entityIds) this._entityIds = [];
    if (ids.length == 0)
      ids.push(-1); //we are trying specifically to get nothing, so get nothing.
    this._entityIds.push(...ids);
    return this;
  }

  /**
   * For Chaining, only include entities in the filter from this list
   * @param ids ids of entities to be queried
   */
  in(...ids: number[]) {
    if (!this._allowIds) this._allowIds = [];
      this._allowIds.push(...ids);
    return this;
  }

  /**
   * For Chaining, do not include any of these entities in the filter
   * @param ids ids of entities to be excluded
   */
  except(...ids: number[]) {
    if (!this._blockIds) this._blockIds = [];
      this._blockIds.push(...ids);
    return this;
  }

  /**
   * For Chaining, load children data of specific entity types
   * @param ids Entity Type Ids of children to be loaded
   */
  loadChildren(...ids: number[]) {
    if (!this._loadChildren) this._loadChildren = [];
    this._loadChildren.push(...ids);
    return this;
  }

  /**
   * Load properties of the direct children of the entity into the linked properties.
   * @param propertyMap a map of unchangeable names to property names, with the ability to specify the type returned, if you know it.
   * @returns the entity filter for chaining
   */
  includePropertiesFromChildren(propertyMap: {[key: string]: string[]}) {
    if (!this._includeChildrenProperties) this._includeChildrenProperties = {};
    for (const [unchangeableName, properties] of Object.entries(propertyMap)) {
      const key = unchangeableName.toLowerCase();
      if (key in this._includeChildrenProperties)
        this._includeChildrenProperties[key] = Array.from(new Set(this._includeChildrenProperties[key].concat(properties.map(p => p.toLowerCase()))));
      else
        this._includeChildrenProperties[key] = properties.map(p => p.toLowerCase());
    }
    return this;
  }

  /**
   * For Chaining, gets entities related to the parent id
   * @param id the parent id
   */
  withParent(id: number) {
    this.parentId = id;
    return this;
  }

  /**
   * Include a single entity from the previous page in the current page.
   * @returns The entity filter for chaining.
   */
  includeLastEntityFromPreviousPage(): EntityFilter {
    this._excludeEntitiesFromPreviousPage = false;
    return this;
  }

  /**
   * For filtering by entity name or first name
   * @param value name or First Name starts with value
   */
  Simple(value: string) {
    const simple = `search.value=${value}`;
    return this.appendStr('simpleStr', simple);
  }

  /**
   * for complicated queries, advanced filter is a 'match all' query
   */
  Advanced(...advanced: FilterToken[]): EntityFilter;
  Advanced(advanced: FilterToken | FilterToken[] | { [key: string]: FilterValue } | Map<string, FilterValue>): EntityFilter;
  Advanced(advanced: FilterInput) {
    return this.appendStr('advancedStr', advanced);
  }

  /**
   * for complicated queries, advanced filter is a 'match any' query
   */
  AdvancedUnion(...advancedUnion: FilterToken[]): EntityFilter;
  AdvancedUnion(advancedUnion: FilterToken | FilterToken[] | { [key: string]: FilterValue } | Map<string, FilterValue>): EntityFilter;
  AdvancedUnion(advancedUnion: FilterInput) {
    return this.appendStr('advancedUnionStr', advancedUnion);
  }

  /**
   * for complicated queries, advanced filter is a 'do not match all' query
   */
  Exclude(...exclude: FilterToken[]): EntityFilter;
  Exclude(exclude: FilterToken | FilterToken[] | { [key: string]: FilterValue } | Map<string, FilterValue>): EntityFilter;
  Exclude(exclude: FilterInput) {
    return this.appendStr('excludeStr', exclude);
  }

  /**
   * for complicated queries, advanced filter is a 'do not match any' query
   */
  ExcludeUnion(...excludeUnion: FilterToken[]): EntityFilter;
  ExcludeUnion(excludeUnion: FilterToken | FilterToken[] | { [key: string]: FilterValue } | Map<string, FilterValue>): EntityFilter;
  ExcludeUnion(excludeUnion: FilterInput) {
    return this.appendStr('excludeUnionStr', excludeUnion);
  }

  /**
   * for complicated queries, advanced filter is a 'linked_property.id in <filter_results_subquery>' query
   * eg. current filter is for clientfiles and you want ones that belong to 'Clinical' clients: LinkedProperty('clients', 627, EntityFilter.Advanced({'ccasaclienttype': 'Clinical'})
   * @param propertyName the unchangeable name of the linked property
   * @param entityTypeID the entity type id of the subfilter's entity type
   * @param filter the entity filter to be applied to the linked property
   * @param exclusion whether to exclude the results of the linked property filter (as in, 'linked_property.id NOT IN <filter_results_subquery>')
   * @returns This entity filter, for chaining
   */
  LinkedProperty(propertyName: string, entityTypeID: number,  filter: EntityFilter, exclusion: boolean = false): EntityFilter {
    if(!this._linkedPropertiesFilters)
      this._linkedPropertiesFilters = [];

    if (this._linkedPropertiesFilters.some(f => f.propertyName === propertyName && f.entityTypeId === entityTypeID)) {
      this._linkedPropertiesFilters = this._linkedPropertiesFilters.map(f => f.propertyName === propertyName && f.entityTypeId === entityTypeID ?
        new LinkedPropertyFilter(f.propertyName, f.entityTypeId, filter.Rebase(f.filter).idsOnly(), f.exclusion)
        : f)
    }
    else
      this._linkedPropertiesFilters.push(new LinkedPropertyFilter(propertyName, entityTypeID, filter.idsOnly(), exclusion));
    return this;
  }


  /**
   * for complicated queries, advanced filter is a 'id in <filter_results_subquery>.linked_property.id' query
   * eg. current filter is for clients and you want ones that have open clientfiles: ReflexiveLinkedProperty('clientfiles', 643, EntityFilter.Advanced({'clientfilestatus': 'Open'})
   * @param propertyName the unchangeable name of the linked property
   * @param entityTypeID the entity type id of the subfilter's entity type
   * @param filter the entity filter to get the linked property from
   * @param exclusion whether to exclude the results of the linked property filter (as in, 'id NOT IN <filter_results_subquery>.linked_property.id')
   */
  ReflexiveLinkedProperty(propertyName: string, entityTypeID: number,  filter: EntityFilter, exclusion: boolean = false): EntityFilter {
    if(!this._reflexiveLinkedPropertiesFilters)
      this._reflexiveLinkedPropertiesFilters = [];

    if (this._reflexiveLinkedPropertiesFilters.some(f => f.propertyName === propertyName && f.entityTypeId === entityTypeID)) {
      this._reflexiveLinkedPropertiesFilters = this._reflexiveLinkedPropertiesFilters.map(f => f.propertyName === propertyName && f.entityTypeId === entityTypeID ?
        new LinkedPropertyFilter(f.propertyName, f.entityTypeId, filter.Rebase(f.filter).idsOnly(), f.exclusion)
        : f)
    }
    else
      this._reflexiveLinkedPropertiesFilters.push(new LinkedPropertyFilter(propertyName, entityTypeID, filter.idsOnly(), exclusion));
    return this;
  }


  matchAnyReflexiveLinkedPropertyFilter() {
    this._matchAnyReflexiveLinkedPropertyFilters = true;
    return this;
  }
  matchAnyLinkedPropertyFilter() {
    this._matchAnyLinkedPropertyFilters = true;
    return this;
  }

  /**
   * for custom querystring parameters.
   *
   * *Try to use utility functions instead.*
   */
  Custom(...custom: FilterToken[]): EntityFilter;
  Custom(custom: FilterToken | FilterToken[] | { [key: string]: FilterValue } | Map<string, FilterValue>): EntityFilter;
  Custom(custom: FilterInput) {
    return this.appendStr('simpleStr', custom);
  }

  /**
   * creates a new chain (prevents accidental editing of the old filter)
   */
  Subset() {
    return EntityFilter.Merge(EntityFilter.None, this);
  }

  /**
   * creates a __new__ chain (prevents accidental editing of the old filter). this function can take a parameter which will merge the two filters
   * @param filter this will be a filter to use as the base of the subset. If not provided, it will be a pure copy of this filter
   */
  Rebase(base?: EntityFilter) {
    const out = base ? base.Subset() : EntityFilter.None
    return EntityFilter.Merge(out, this);
  }

  /**
   * this applies the input filter on top of the current filter
   * @param filter the filter to apply on top of `this`
   */
  Apply(filter: EntityFilter) {
    return EntityFilter.Merge(this, filter);
  }

  /**
   * creates a __new__ chain (prevents accidental editing of the old filter). this function can take a parameter which will merge the two filters
   * @param filter this will be a filter to use as the base of the subset. If not provided, it will be a pure copy of this filter
   */
  static Merge(base: EntityFilter, filter: EntityFilter): EntityFilter {
    // if of doesn't exist, EntityFilter.None will create a brand new empty filter,
    // either way it will be fully initialized... so we can just merge our values into them
    const defaults = EntityFilter.None;

    if (!base)
      base = EntityFilter.None;

    if (!filter)
      return EntityFilter.None;

    const queryStrings  = [ 'simpleStr', 'advancedStr', 'advancedUnionStr', 'excludeStr', 'excludeUnionStr'];

    // these will need to be cloned, the rest are primitives and strings
    const numberArrays = [ '_entityIds', '_allowIds', '_blockIds', '_loadChildren' ];
    const objectArrays = [
      '_linkedPropertiesFilters',          // LinkedPropertyFilter[]
      '_reflexiveLinkedPropertiesFilters', // LinkedPropertyFilter[]
      '_statCountRequests',                // statCountRequest[]
    ];
    const deepKeys = [
      ...numberArrays,
      '_orderBy',                          // OrderObject
      'computedProperties',                // ComputedPropertyRequests
      ...objectArrays,
      '_includeChildrenProperties',        // Record<string, string[]>
    ];

    // TODO: this should account for default values on the incoming filter,
    // TODO: currently it will overwrite primitives on base (booleans, numbers, strings)
    for (const [key, value] of Object.entries(filter)) {
      if (queryStrings.includes(key)){
        if(base[key]){
          base[key] += ','; // add a comma to separate the query strings
        }
        base[key] += value;
      }else if (!deepKeys.includes(key)){
          base[key] = value;
      }
    }

    // cloneable
    base._orderBy.merge(filter._orderBy);
    base.computedProperties = base.computedProperties.merge(filter.computedProperties);

    if (filter._includeChildrenProperties)
      base._includeChildrenProperties = Object.assign({}, filter._includeChildrenProperties, base._includeChildrenProperties);

    // primitive arrays
    numberArrays.forEach( key => {
      if (filter[key])
        base[key].push(...filter[key]);
    });

    // shallow-ish should be fine... no one is modifying these, only adding/removing them, right?
    objectArrays.forEach( key => {
      if (filter[key])
        base[key].push(...filter[key]);
    });

    return base;
  }

  /**
   * copies everything from the other filter that affects the features of the response.
   * eg. loadChildren, computedProperties, and child Properties
   * @returns this filter, with the response modifiers from the other filter
   * */
  withResponseModifiers(other: EntityFilter) {
    // append everything that modifies the actual response (Json format, features, etc.)
    if(other.hasLoadChildren)
      this.loadChildren(...other.loadChildrenIds);

    if(other.hasIncludeChildProperties)
      this.includePropertiesFromChildren(other.includeChildrenPropertiesObj);

    if(other.hasComputedProperties)
      this.setComputedProperties(other.computedPropertiesObject);

    return this;
  }

    /**
   * Uses the route to get a list of ids from the API, instead of regular WarpEntityServiceCache#getPage
   * NOTE: This will not work with chaining, its intended to be used as a standalone filter
   *
   * @param route Backend route to hit for the ids
   * @param body Body sent in the request
   * @param method Request method (POST or GET)
   * @returns EntityFilter, won't work with chaining
   */
  private fromAPI(route: string, body = {}, method: 'GET' | 'POST' = 'POST'): EntityFilter {
    this.apiRoute = route.startsWith('/') ? route : '/' + route;
    this.apiBody = body;
    this.apiMethod = method;
    return this;
  }

  //#endregion

  //#region Initializers
  static get None() {
    return new EntityFilter();
  }
  static All() {
    return EntityFilter.None;
  }
  static Custom(filterObj: FilterInput) {
    return EntityFilter.None.Custom(filterObj);
  }
  static Simple(value: string) {
    return EntityFilter.None.Simple(value);
  }
  static Advanced(filterObj: FilterInput) {
    return EntityFilter.None.Advanced(filterObj);
  }
  static AdvancedUnion(filterObj: FilterInput) {
    return EntityFilter.None.AdvancedUnion(filterObj);
  }
  static Exclude(filterObj: FilterInput) {
    return EntityFilter.None.Exclude(filterObj);
  }
  static LinkedProperty(propertyName: string, entityTypeID: number, filter: EntityFilter) {
    return EntityFilter.None.LinkedProperty(propertyName, entityTypeID, filter);
  }
  static ReflexiveLinkedProperty(propertyName: string, entityTypeID: number, filter: EntityFilter) {
    return EntityFilter.None.ReflexiveLinkedProperty(propertyName, entityTypeID, filter);
  }

    /**
   * Uses the route to get a list of ids from the API, instead of regular WarpEntityServiceCache#getPage
   * NOTE: This will not work with chaining, its intended to be used as a standalone filter
   *
   * @param route Backend route to hit for the ids
   * @param body Body sent in the request
   * @param method Request method (POST or GET)
   * @returns EntityFilter, won't work with chaining
   */
  static FromAPI(route: string, body = {}, method: 'GET' | 'POST' = 'POST'): EntityFilter {
    return EntityFilter.None.fromAPI(route, body, method);
  }

  static FromModuleSettings(moduleSettings: { [key: string]: string }, customFieldsInModules: CustomFieldInModule[]): EntityFilter | ((model) => EntityFilter) {
    /**
     * moduleSettings look like:
     * entityfilter_advanced_[unchName] = 'value'
     * entityfilter_exclude_[unchName] = '>=value'
     * entityfilter_union_[unchName] = '=%value'
     * entityfilter_orderby_[unchName] = 'asc' | 'desc'
     *
     * value can have dynamic parts
     * ${name[_lid|_cfcid]} -> for checking a value in the model
     * &{function expression} -> executes function expression with (model) as an argument
     * %{predefined_flag} -> check `dynamicModuleSettingFlags()`
     */
    const firstMatch = (key: string, matcher: { [Symbol.match](pattern: string): RegExpMatchArray }) => {
      const match = key.match(matcher);
      return match && match.length ? match[1] : null;
    };

    const ef = EntityFilter.None;
    type ExtendedFilterToken = FilterToken & { tokens: RegExpMatchArray, method: string };
    const dynamicFilters: ExtendedFilterToken[] = [];

    for (const mod_method_key in moduleSettings)
      if (mod_method_key.startsWith('entityfilter_')) {
        const method_key = mod_method_key.replace('entityfilter_', '');
        const method: string = firstMatch(method_key, /^([a-z]+?)_/);
        if (method && method === 'orderby') {
          const key = method_key.replace(method + '_', '');
          ef.orderBy(key, moduleSettings[mod_method_key] as ('asc' | 'desc') );
        } else if (method) {
          const value: string = moduleSettings[mod_method_key].replace(/^[<>=]=?/, '');
          const key = method_key.replace(method + '_', '');
          let op = firstMatch(moduleSettings[mod_method_key], /^([<>=]=?)/) || '=';
          if (op === '==') op = '=';

          const dynamicValueElements = value.match(/[\$|\&|\%]{.*}/g);
          const searchTerm: ExtendedFilterToken = { key, value, operator: op as FilterOperator, tokens: dynamicValueElements, method};

          if (dynamicValueElements === null)
            switch (method) {
              case 'advanced': ef.Advanced(searchTerm); break;
              case 'exclude': ef.Exclude(searchTerm); break;
              case 'union': ef.AdvancedUnion(searchTerm); break;
            }
          else
            dynamicFilters.push(searchTerm);
        }
      }

    if (dynamicFilters.length === 0)
      return ef;

    return (model) => {
      const dyn_ef = ef.Subset();
      dynamicFilters.forEach(filterToken => {
        // value would look like: "a value with ${dynamic} &{variables} like ${this.property_lid} or &{new Date()}"
        let query = `${filterToken.value}`;
        // tokens would look like: ["${dynamic}", "&{variables}", "${this.property_lid}", "&{new Date()}]
        filterToken.tokens.forEach(token => {
          let unch = token.substring(2, token.length - 1);
          let value = [];
          if (token.startsWith('$')) {
            unch = unch.replace(/^this./, '');
            const cfim = customFieldsInModules.find(_cfim => _cfim.unchangeableName.toLowerCase() === unch.replace(/_((l|cfc)id|specifyText)$/, ''));
            value = model[cfim.unchangeableName.toLowerCase()];
            if (!value) value = [];
            if (!(value instanceof Array)) value = [value];

            value = value.map(val => {
              if (/_(l|cfc)id$/.test(unch)) return val.id;
              if (/_specifyText$/.test(unch)) return val.specifyText;
              return val.name || val.optionName || val.specifyText || val;
            });
          } else if (token.startsWith('%'))
            // %{flag} must be defined in dynamicModuleSettingFlags
            value = this.dynamicModuleSettingFlags(unch);
          else
            try {
              // &{x} where x is a function with argument (model: WarpEntity.properties)
              const evaluate_raw = Function('model', unch);
              value = evaluate_raw(model) || [];
              if (!(value instanceof Array)) value = [value];
            } catch (error) {
              console.warn(`Misconfigured module setting for entityType`);
            }
          query = query.replace(token, value.join('|'));
        });

        // create a copy of filterToken to avoid mutating the original
        const searchTerm: FilterToken = {
          key: filterToken.key.replace(/_specifyText$/, ''),
          value: query,
          operator: filterToken.operator
        };

        switch (filterToken.method) {
          case 'advanced': dyn_ef.Advanced(searchTerm); break;
          case 'exclude': dyn_ef.Exclude(searchTerm); break;
          case 'union': dyn_ef.AdvancedUnion(searchTerm); break;
        }
      });
      return dyn_ef;
    };
  }

  static dynamicModuleSettingFlags(flag: string) {
    switch (flag.toLowerCase()) {
      case 'today': return [this.DateFromToday(0)];
      case 'tomorrow': return [this.DateFromToday(1)];
      case 'yesterday': return [this.DateFromToday(-1)];
      default: return [];
    }
  }

  private static DateFromToday(days) {
    const result = new Date();
    result.setDate(result.getDate() + days);
    return result.toLocaleDateString('en-ZA');
  }

  //#endregion
}

/**
 * Wrapper object to describe an entity filter for a linked property
 * A linked property filter is an entity filter to be 'recursively' (for lack of a better term) applied to a linked property
 * For example, a linked property filter could be used to filter the linked property 'client' of a clientfile to only show clients with a specific name
 * Specifically, filter A (this filter) will check if the linked property's id is in the list of ids returned by filter B (the linked property filter)
 * In the case of a reflexive linked property filter, filter A will check if its id is in the list of linkedEntity ids returned by filter B
 * @param propertyName the unchangeable name of the linked property
 * @param entityTypeID the entity type id of the linked property
 * @param filter the entity filter to be applied to the linked property
 */
class LinkedPropertyFilter{
  propertyName: string;
  filter: EntityFilter;
  entityTypeId: number;
  exclusion: boolean;
  constructor(propertyName: string, entityTypeId: number, filter: EntityFilter, exclusion: boolean = false) {
    this.propertyName = propertyName;
    this.filter = filter;
    this.entityTypeId = entityTypeId;
    this.exclusion = exclusion;
  }

  get backendPagingOptions(): LinkedBackendPagingOptions {
    // Add the entityTypeId to the filter since it's not tied to a service cache
    let tempFilter = this.filter.backendPagingOptions;
    tempFilter.pagingOptions.EntityTypeId = this.entityTypeId;
    tempFilter.pagingOptions.PageSize = 100000;
    return {
      propertyName: this.propertyName,
      exclusion: this.exclusion,
      filter: tempFilter
    };
  }
}
class LinkedBackendPagingOptions {
  propertyName: string;
  exclusion: boolean;
  filter: any; // {sendMinimum: boolean, entityIds: number[], pagingOptions: BackendPagingOptions}
}

/**
 * Stores information about a stat count request
 * Has a filter to define a subset and/or a uniqueProperty to define a property to count
 */
class statCountRequest {
  name: string;
  filter: any; // {sendMinimum: boolean, entityIds: number[], pagingOptions: BackendPagingOptions}
  uniquePropertyName: string;
  aggregateOperator?: AggregateOperator;
}

/**
 * Object that stores information for computed properties.
 * It's own class so that it can store a unified list of children sets, rather than redefine them for each computed property
 * @param ChildrenSets a list of children sets to be used by the computed properties
 * @param Properties a map of computed property names to their equations
 */
class ComputedPropertyRequests {
  ChildrenSets: ComputedPropertyChildrenSet[] = [];
  Properties: Map<string, ComputedPropertyEquation> = new Map();

  addChildrenSet(EntityTypeId: number, Label: string, Filter: EntityFilter, LinkedProperty: string){
    this.ChildrenSets.push(new ComputedPropertyChildrenSet(EntityTypeId, Label, Filter, LinkedProperty));
  }

  addChildSet(ChildrenSet: ComputedPropertyChildrenSet){
    this.ChildrenSets.push(ChildrenSet);
  }

  addProperty(PropertyName: string, Equation: ComputedPropertyEquation){
    this.Properties.set(PropertyName, Equation);
  }

  convertMapToObj(){
    let out = {};
    this.Properties.forEach((value, key) => {
      out[key] = value;
    });
    return out;
  }

  clone() {
    let out = new ComputedPropertyRequests();
    out.ChildrenSets = this.ChildrenSets.slice();
    const newProperties: Map<string, ComputedPropertyEquation> = new Map();
    this.Properties.forEach((value, key) => {
      newProperties.set(key, value);
    });
    out.Properties = newProperties;
    return out;
  }

  /** You probably want to clone first */
  merge(other: ComputedPropertyRequests) {
    this.ChildrenSets.push(...other.ChildrenSets);

    other.Properties.forEach((value, key) => {
      this.Properties.set(key, value);
    });

    return this;
  }

  toString() {
    return [
      `cp_cs=[${this.ChildrenSets.map(cs => `${cs.EntityTypeId}|${cs.Label}|${cs.LinkedProperty}|${cs.filter.identifier.replace('&', ':')}`).join(',')}]`,
      `cp_p=[${Array.from(this.Properties).map(([key, value]) => `${key}|${value}`).join(',')}]`
    ].join('&');
  }
}

/**
 * Labelled set of children for a computed property
 * eg. A client's sessions, or a client's clientfiles
 * @param EntityTypeId the entity type id of the children
 * @param Label the name of the set
 * @param Filter the filter to be applied to the children
 * @param LinkedProperty the unchangeable name of the children's linked property that links back to the parent. eg. 'clients' for sessions to refer to which client they belong to
 */
class ComputedPropertyChildrenSet{
  EntityTypeId: number;
  Label: string;
  filter: EntityFilter;
  LinkedProperty: string;

  constructor(EntityTypeId: number, Label: string, Filter: EntityFilter, LinkedProperty: string){
    this.EntityTypeId = EntityTypeId;
    this.Label = Label;
    this.filter = Filter;
    this.LinkedProperty = LinkedProperty;
  }

  get Filter(): EntityFilter['backendPagingOptions'] {
    const opts = this.filter.backendPagingOptions;
    opts.pagingOptions.PageSize = 100000;
    opts.pagingOptions.EntityTypeId = this.EntityTypeId;
    return opts;
  }
}

/**
 * A single-operation equation or aggregation used to get a value for a computed property
 * Note that operands can be equations themselves, allowing for nested equations to create more advanced arithmetic
 * @param LeftOperand the left operand of the equation. In the case of an aggregate function, this is the set to be aggregated on
 * @param RightOperand the right operand of the equation. In the case of an aggregate function, this is the property to be aggregated
 * @param Operator the operator to be used in the equation
 * @param OperatorOption an optional string to be used as an option for the operator. eg. Whether to use year/month/day for the date difference operator
 */
class ComputedPropertyEquation{
  LeftOperand: ComputedPropertyOperand;
  RightOperand: ComputedPropertyOperand;
  Operator: ComputedPropertyOperator | CaseConditionOperator;
  OperatorOption: string;
  DefaultValue: ComputedPropertyOperand;
  constructor(
    LeftOperand: ComputedPropertyOperand,
    RightOperand: ComputedPropertyOperand,
    Operator: ComputedPropertyOperator | CaseConditionOperator,
    OperatorOption: string = "",
    DefaultValue: ComputedPropertyOperand = null
  ) {
    this.LeftOperand = LeftOperand;
    this.RightOperand = RightOperand;
    this.Operator = Operator;
    this.OperatorOption = OperatorOption;
    this.DefaultValue = DefaultValue;
  }
}

/**
 * A single operand of a computed property equation
 * Since operands can be property names, set labels, constants, or equations themselves, this class is used as a catch-all
 * Note that a type is required to be specified, but only one of the data fields will be used
 * @param operandType the type of the operand
 * @param stringData the string data of the operand. Used for property names and set labels
 * @param numberData the number data of the operand. Used for constants
 * @param equationData the equation data of the operand. Used for equations
 * @param caseConditionData the case condition data of the operand. Used for case conditions
 * @param caseResultData the case result data of the operand. Used for case results (the value to return if the case condition is true)
 */
class ComputedPropertyOperand {
  operandType: "property" | "constant" | "date" | "equation" | "set" | "case";
  stringData: string;
  numberData: number;
  equationData: ComputedPropertyEquation;
  caseConditionData: ComputedPropertyEquation[];
  caseResultData: ComputedPropertyOperand[];

  constructor(
    operandType: "property" | "constant" | "date" | "equation" | "set" | "case",
    stringData?: string,
    numberData?: number,
    equationData?: ComputedPropertyEquation,
    caseConditionData?: ComputedPropertyEquation[],
    caseResultData?: ComputedPropertyOperand[],
  ){
    this.operandType = operandType;
    if(typeof stringData !== "undefined"){
      this.stringData = stringData;
    }
    if(typeof numberData !== "undefined"){
      this.numberData = numberData;
    }
    if(typeof equationData !== "undefined"){
      this.equationData = equationData;
    }
    if(typeof caseConditionData !== "undefined"){
      this.caseConditionData = caseConditionData;
    }
    if(typeof caseResultData !== "undefined"){
      this.caseResultData = caseResultData;
    }
  }
}

/**
 * Strores all ordering information
 */
class OrderObject {
  all: { name: string, direction: 'asc' | 'desc', relevancyWeights?: {
    [key: string]: number
  }}[] = [];
  add(...orderInfo: { name: string, direction: 'asc' | 'desc', relevancyWeights?: {
    [key: string]: number
  }}[]) {
    for (const info of orderInfo) {
      const existingOrderInfo = this.all.find(o => o.name === info.name);
      if (existingOrderInfo)
        existingOrderInfo.direction = info.direction;
      else
        this.all.push(info);
    }
  }
  toString() {
    return this.all
      .map((o, i) => `order[${i}].column=${i}&order[${i}].dir=${o.direction}&columns[${i}].data=${o.name}`)
      .join('&');
  }
  clone() {
    const out = new OrderObject();
    out.add(...this.all);
    return out;
  }

  /** you probably want to use clone first */
  merge(other: OrderObject) {
    this.add(...other.all);
    return this;
  }
}

type QueryStringParamName =
  'start' |
  'length' |
  'draw' |
  'matchall' |
  'sendminimum' |

  'entityIDs' |
  'additionalEntityTypes' |

  'advancedFilter' |
  'advancedFilterUnion' |
  'excludeAdvancedFilter' |

  'filterSearchDate' |
  'filterSearchDateTime' |

  'search.value' |
  'search.column' |

  'order[0].column' |
  'order[0].dir' |

  'columnCount' |
  'column[*].data';
