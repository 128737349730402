import { Pipe, PipeTransform } from '@angular/core';
import * as moment_ from 'moment';
const moment = moment_;

type DateInput = string | number | moment.Moment | Date | moment.MomentInputObject;
@Pipe({
  name: 'parseDate'
})
export class DateParsePipe implements PipeTransform {
  transform(value: DateInput, format?: string): Date;
  transform(value: DateInput[], format?: string): Date[];
  transform(value: DateInput | DateInput[], format?: string): Date | Date[] {
    if (value instanceof Array)
      return value.map(s => moment(s, format).toDate());
    else
      return moment(value, format).toDate();
  }
}
