import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as Quill from 'quill';
import quillTable from 'quill-table';
import { ClaresLawService } from '../../_core/services';
import { WarpEntityCacheFactoryService } from '@ripple/services';
import { EntityTypes } from '../../_core/models';

@Component({
  selector: 'sagesse-clares-law-report',
  templateUrl: './clares-law-report.component.html',
  styleUrls: ['./clares-law-report.component.scss']
})
export class ClaresLawReportComponent implements OnInit {

  reportHtml = '';
  email: string[] = ['clares-law@gov.ab.ca', 'sarah.drohan@gov.ab.ca', 'Elizabeth.Murphy@gov.ab.ca', 'Carrie@sagesse.org', 'Asraf.Siddique@gov.ab.ca', 'amy@sagesse.org', 'sarah@sagesse.org'];
  subject = 'Clare\'s Law Monthly Report';
  dateRange: Date[];

  editorModule: any;

  tryValidate = false;
  dateRangeValid = true;

  loading = false;
  sending = false;

  @Input() ifEmailView = true;

  @Output('dialogClose') dialogClose: EventEmitter<boolean> = new EventEmitter<boolean>();

  claresLawService: ClaresLawService;

  constructor(
    private serviceFactory: WarpEntityCacheFactoryService
  ) {
    this.claresLawService = this.serviceFactory.get(EntityTypes.ClaresLaw) as ClaresLawService;
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.dateRange = [firstDay, lastDay];
    this.generateReport();
  }

  ngOnInit(): void {
  }

  generateReport() {
    if (this.dateRange.length == 2 && this.dateRange[0] && this.dateRange[1]) {
      this.dateRangeValid = true;
      this.loading = true;
      Quill.register(quillTable.TableCell);
      Quill.register(quillTable.TableRow);
      Quill.register(quillTable.Table);
      Quill.register(quillTable.Contain);
      Quill.register('modules/table', quillTable.TableModule);
      this.editorModule = {
        table: true,
      };
      this.subject = `Clare's Law Monthly Report: ${this.dateRange[0].toLocaleDateString()} - ${this.dateRange[1].toLocaleDateString()}`;
      this.claresLawService.generateClaresLawMonthlyReport(this.dateRange[0], this.dateRange[1]).subscribe(result => {
        this.loading = false;
        this.reportHtml = result;
      });
    } else {
      this.tryValidate = true;
      this.dateRangeValid = false;
    }
    
  }

  send() {
    this.sending = true;

    this.claresLawService.sendReportEmail(this.subject, this.reportHtml, this.email).subscribe(result => {
        this.sending = false;
        this.dialogClose.emit(true);
    });
      
  }

}
