<div *ngIf="submitting" style="padding: 1%; text-align: center; display: inline">
    <h1>Submitting Form...</h1>
</div>
<div *ngIf="saving" style="padding: 1%; text-align: center; display: inline">
    <h1>Saving Form...</h1>
</div>

<div *ngIf="saved || !hasPermission" style="padding: 1%; text-align: center; display: inline">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
</div>

<ripple-loading-box [loading]="!filledForm || saving || submitting" *ngIf="!saved && hasPermission">
    <ng-container *ngIf="filledForm">
        <div class="pane" style="padding: 1%; max-width: 799px; margin: 0 auto;">
            <div class="pane__header">
                <div class="header header--margin">
                    <h1 class="header__heading">{{ formName() }}</h1>
                    <div class="header__actions">
                    </div>
                </div>
            </div>
            <div class="pane__body">
                <ripple-form-builder
                #fb
                (saveFilledFormData)="submitForm($event)"
                [(formID)]="filledForm.formtype"
                [formData]="filledForm.formData.value"
                [successfulSave]="saveCallback"
                [overrideSave]="true"
                [action]="action"
                [hideAutofillSignatures]="true"
                (formSaved)="formSaved()"
                style="display: none"
                >
                </ripple-form-builder>
            </div>
            <sagesse-clares-law-result [filledForm]="filledForm" [formData]="filledForm.formData.value" (afterSave)="submitForm($event)"></sagesse-clares-law-result>
        </div>
    </ng-container>
</ripple-loading-box>