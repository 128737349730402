import { IFilterableEntityTypeDescriptor } from '@ripple/models';

//TODO: this should be in subscribers folder
export const entityTypes: IFilterableEntityTypeDescriptor[] = [
  {
    id: 583,
    optionName: 'Employees (All)'
  },
  {
    id: 583,
    optionName: 'Staff - Counsellors',
    filterProperties: {
      roles: [
        {
          name : 'Counsellors'
        }
      ]
    }
  },
  {
    id: 583,
    optionName: 'Staff - Educators',
    filterProperties: {
      roles: [
        {
          name : 'Education Staff'
        }
      ]
    }
  },
  {
    id: 627,
    optionName: 'Clients'
  },
  // {
  //   id: 627,
  //   optionName: 'Clients - Filter',
  //   filterProperties: {
  //     name: 'm'
  //   }
  // },
  {
    id: 628,
    optionName: 'Event'
  },
  {
    id: 611,
    optionName: 'Organization'
  },
  {
    id: 72,
    optionName: 'Contacts'
  },
  
];

