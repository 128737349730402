import { HttpClient } from '@angular/common/http';
import { ContentChildren, Directive, ElementRef, Injectable, Input, QueryList } from '@angular/core';
import { AuthService, MessageService, RippleApiService } from '@ripple/services';
import { SnippetFilterComponent } from './snippet-filter.component';

interface ExcelInput {
  filename: string;
  format: string;
  sheets: ExcelInputSheet[];
}

interface ExcelInputSheet {
  sheetName: string;
  rows: (string | number)[][];
}

@Directive({
  selector: '[excelExportable]',
})
export class ExcelExportableDirective {
  @Input() minCellsPerRow: number = 0;
  @Input('excelExportable') title: string;

  @ContentChildren(SnippetFilterComponent, { descendants: true })
  rows: QueryList<SnippetFilterComponent<any, any, any, any>>;

  constructor(private el: ElementRef<HTMLElement>) {}

  get sheetName() {
    return this.el?.nativeElement?.querySelector('h1,h2,h3,h4')?.textContent?.trim();
  }

  public createJson(): ExcelInputSheet {
    const titles = this.rows.first.exportableColumns.map((row) => row.title);
    const rows = this.rows.map(row => row.exportableColumns.map(col => col.data));

    return {
      sheetName: this.title || this.sheetName,
      rows: [titles, ...rows]};
  }
}

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService extends RippleApiService {
  private restURL = '/api/ccasa/precannedreport';

  constructor(
    http: HttpClient,
    authService: AuthService,
    messageService: MessageService
  ) {
    super(http, messageService, authService);
  }
  public downloadExcelFromJson(
    fileName: string,
    format: string,
    exportable: QueryList<ExcelExportableDirective>
  ): Promise<void> {
    const sheets = exportable.map((exportable) => exportable.createJson());
    const url = `${this.restURL}/generateExcelFromJson`;

    const body: ExcelInput = {
      filename: fileName + '.' + format,
      format: format,
      sheets: sheets,
    };

    return this._post(url, body, 'generateExcelFromJson', {
      responseType: 'blob',
    })
      .toPromise()
      .then((response) => {
        const blob = new Blob([response.body || response], {
          type:
            format === 'xlsx'
              ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              : 'application/zip',
        });
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      })
      .catch((error) => {
        console.error('Error generating Excel:', error);
        throw error;
      });
  }
}
