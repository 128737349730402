import { WarpEntity } from '../../models/warp-entity';
// @dynamic
export class Notification extends WarpEntity {
  static entityTypeId = 343;

  entityTypeId = 343;

  static empty(): Notification {
    return new Notification(super.emptyEntity(343));
  }
}
