<!-- Begin MenuBar -->
<nav class="menubar menu-full-at--{{menuFullAt}}">
  <div class="logo_container">
    <div class="menubar__brand">
      <img src="{{ logoPath }}" alt="logo" class="menubar__logo" />
      <img src="{{ printLogo }}" alt="logo" class="menubar__print-logo" />
      <span>{{ brandName }}</span>
      <span *ngIf="staging" class="staging-label">Staging</span>
    </div>
  </div>
  <div
    class="menubar__group menubar__group--afix menubar__group--toggler"
    *ngIf="showPrimaryMenu"
  >
    <button
      class="menubar__toggler menu-toggler-button"
      type="button"
      (click)="toggleNav()"
      [ngClass]="{ 'is-active': navIsActive }"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>

  <div
    class="menubar__group menubar__group--stretch menubar__group--primary"
    *ngIf="showPrimaryMenu"
  >
    <div
      class="menu-bg-overlay"
      [ngClass]="{ 'is-active': navIsActive }"
      (click)="closeNav()"
    ></div>
    <div
      class="menu-wrapper"
      [ngClass]="{ 'is-active': navIsActive }"
      #mainMenuWrapper
    >
      <ul class="menu">
        <!-- Section Items -->
        <li
          class="menu__item popdown"
          ngbDropdown
          display="static"
          *ngIf="sectionItems"
        >
          <a ngbDropdownToggle>
            <i class="menu-icon pi pi-sitemap"></i>
            {{ currentView | titlecase }}
          </a>
          <div class="popdown-menu popdown-menu--primary" ngbDropdownMenu>
            <ul class="submenu">
              <li *ngFor="let si of sectionItems">
                <a
                  [routerLink]="si.routerLink"
                  [queryParams]="si.queryParams"
                  (click)="si.command ? si.command($event) : ''"
                  matRipple
                >
                  <i
                    class="menu-icon"
                    [ngClass]="si.icon ? si.icon : 'pi pi-paperclip'"
                  ></i>
                  {{ si.label }}
                </a>
              </li>
            </ul>
          </div>
        </li>
        <!-- MenuBar Items -->
        <ng-container *ngFor="let item of menuItems">
          <!-- DropDownable Items -->
          <li
            class="menu__item popdown"
            ngbDropdown
            display="static"
            *ngIf="item.items"
          >
            <a ngbDropdownToggle>
              <i *ngIf="item.icon" class="menu-icon {{ item.icon }}"></i>
              {{ item.label }}
            </a>
            <div class="popdown-menu popdown-menu--primary" ngbDropdownMenu>
              <ul class="submenu">
                <li class="first-sub-menu" *ngFor="let i of item.items">
                  <a
                    [routerLink]="i.routerLink"
                    [queryParams]="i.queryParams"
                    (click)="i.command ? i.command($event) : ''"
                  >
                    <i *ngIf="i.icon" class="menu-icon" [ngClass]="i.icon"></i>
                    {{ i.label }}
                  </a>

                    <ul class="second-sub-menu" *ngIf="i.items">
                      <li *ngFor="let j of i.items">
                        <a
                    [routerLink]="j.routerLink"
                    [queryParams]="j.queryParams"
                  >
                    <i *ngIf="j.icon" class="menu-icon" [ngClass]="j.icon"></i>
                    {{ j.label }}
                  </a>
                      </li>
                    </ul>

                </li>
              </ul>
            </div>
          </li>

          <!-- Non-DropDownable Items -->
          <li class="menu__item" *ngIf="!item.items">
            <a
              [routerLink]="item.routerLink"
              [queryParams]="item.queryParams"
              (click)="item.command ? item.command($event) : ''"
            >
              <i *ngIf="item.icon" class="menu-icon" [ngClass]="item.icon"></i>
              {{ item.label }}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div
    class="menubar__group menubar__group--afix menubar__group--secondary"
    *ngIf="showProfileHelpMenu || showNotifications || showSignalRDisconnectTest"
    (click)="closeNav()"
  >
    <ul class="sec-menu">
      <!-- signalr connection -->
      <li
        class="sec-menu__item popdown"
        ngbDropdown
        *ngIf="showSignalRDisconnectTest"
      >
        <mat-icon (click)="disableSignalRForTesting()" class="sync-icon"
          >sync_disabled</mat-icon
        >
      </li>
      <!-- Privacy Mode -->
      <ripple-privacy-button *ngIf="showPrivacyButton"></ripple-privacy-button>
      <!-- Notification -->
      <li class="sec-menu__item popdown" ngbDropdown *ngIf="showNotifications">
        <button
          class="menu-button menu-button--iconned"
          (click)="taskNotifications.toggle($event)"
        >
          <i class="pi pi-bell menu-icon"></i>
          <span
            class="badge"
            *ngIf="taskNotifications.getNumNotifications() > 0"
          >
            {{
              taskNotifications.getNumNotifications() >=
              taskNotifications.MAX_NOTIFICATION
                ? taskNotifications.MAX_NOTIFICATION + '+'
                : taskNotifications.getNumNotifications()
            }}
          </span>
        </button>
        <ripple-task-notification
          #taskNotifications
          [showIcon]="false"
          [viewAllLink]="viewAllNotificationLink"
        ></ripple-task-notification>
      </li>
      <!-- Help Menu -->
      <li
        class="sec-menu__item popdown"
        ngbDropdown
        *ngIf="showProfileHelpMenu"
      >
        <button class="menu-button" ngbDropdownToggle>Need Help?</button>

        <div class="popdown-menu popdown-menu--secondary" ngbDropdownMenu>
          <ul class="submenu">
            <li *ngIf="getServiceDeskURL()">
              <a (click)="openServiceDesk()"
                ><i class="menu-icon pi pi-question-circle matRipple"></i> Help
                Desk</a
              >
            </li>
            <li *ngIf="getHelpDocsURL()"></li>
              <a (click)="openHelpDocs()"
                ><i class="menu-icon pi pi-book matRipple"></i>
                Help Documentation</a
              >
            <li *ngIf="getUserGuideURL()">
              <a (click)="openUserGuide()"
                ><i class="menu-icon pi pi-book matRipple"></i>
                Quick Start Guide</a
              >
             
            <!-- <li>
              <a href="mailto:info@ripplegroup.ca"
                ><i class="menu-icon pi pi-envelope" matRipple></i>
                info@ripplegroup.ca</a
              >
            </li>
            <li>
              <a href="tel:+14032655454"
                ><i class="menu-icon pi pi-mobile" matRipple></i>
                403.265.5454</a
              >
            </li> -->
            <li>
              <a (click)="downloadHelpFiles()"
                ><i class="menu-icon pi pi-download" matRipple></i>
                Download Support Files</a
              >
            </li>
            <li *ngFor="let extra of extraNeedHelps">
              <a [href]="extra.link" [target]="extra.newTab ? '_blank' : '' ">
                <i class="menu-icon {{extra.icon}}" matRipple></i>
                {{extra.label}}
              </a>
            </li>
          </ul>
        </div>
      </li>

      <!-- Account -->
      <li
        class="sec-menu__item popdown"
        ngbDropdown
        *ngIf="showProfileHelpMenu"
      >
        <a class="menu-button" ngbDropdownToggle>
          <div class="menu-avatar">
            <!-- <div class="menu-avatar__initials" *ngIf="!menuAvatarImage">{{menuAvatarInitials}}</div> -->
            <img
              class="menu-avatar__image"
              [src]="getAvatar()"
              alt="user icon"
            />
          </div>
        </a>
        <div class="popdown-menu popdown-menu--secondary" ngbDropdownMenu>
          <ul class="submenu">
            <li *ngFor="let i of userMenuItems">
              <a
                routerLink="{{ i.routerLink }}"
                (click)="i.command()"
                matRipple
              >
                <i *ngIf="i.icon" class="menu-icon" [ngClass]="i.icon"></i>
                {{ i.label }}
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>
<!-- End MenuBar -->
<p-dialog
  [(visible)]="signalRDialogShow"
  header="Lost data connection"
  [closable]="false"
>
  <div>
    <p>
      Please try reloading by clicking the button below. If the problem persists
      please contact technical support.
    </p>
  </div>
  <p-footer>
    <p-button
      icon="pi pi-times"
      label="Dismiss For Now"
      (click)="closeSignalRDialog()"
    ></p-button>
    <p-button
      icon="pi pi-spinner"
      label="Reload"
      (click)="closeSignalRDialogAndReload()"
    ></p-button>
  </p-footer>
</p-dialog>
