<td width="70%"  [style.background-color]="item.filtered?'#c0c0c0':''">{{data}}</td>
<td width="10%" *ngIf="!(item?.filtered)" (click)="UpdateFilter('and')" class="pointer" [style.background-color]="false?'#c0c0c0':''">
    Add Filter
  </td>
<td width="10%" *ngIf="item.filtered" (click)="UpdateFilter('remove')" class="pointer" [style.background-color]="true?'#c0c0c0':''">Remove filter</td>
<td width="10%"  class="results-right">{{item.count}}</td>
<td *ngIf="dataLength > 0" width="*"  class="results-right">{{((item.count/dataLength)*100).toFixed(1)}}%</td>
<td *ngIf="dataLength == 0" width="*" class="results-right">0%</td>



