import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-tabs',
  template: `
  <p-tabView class="{{field.fieldGroupClassName}}">
    <p-tabPanel *ngFor="let tab of field.fieldGroup">
        <ng-template pTemplate = "header">
            <i class="{{tab.templateOptions.wrapperData.headerIcon}}"></i>
            &nbsp;
            <span>{{tab.templateOptions.wrapperData.label}}</span>
        </ng-template>
        <formly-field [field]="tab"></formly-field>
    </p-tabPanel>
  </p-tabView>
`,
})
export class TabsTypeComponent extends FieldType {
}
