<button
  pButton
  #requiredFieldButton
  *ngIf="show && hasMatured()"
  class="ui-button-danger"
  [ngClass]="buttonClass"
  [ngStyle]="buttonStyle"
  (click)="scrollToInvalidField()"
  label="Missing Required Field"
  icon="pi pi-exclamation-triangle">
  <!-- Rory: We may not use [label] to automatically set the label of the button as it removes ui-button-danger class on update, PrimeNG v9 bug -->
</button>
