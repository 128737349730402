import { WarpEntity } from '../warp-entity';
// @dynamic
export class Intermediary extends WarpEntity {
  static entityTypeId = 734;

  entityTypeId = 734;

  static empty(): Intermediary {
    return new Intermediary(super.emptyEntity(734));
  }
}
