import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from '@ripple/environment';
import { WarpEntity } from "@ripple/models";

import { MessageService } from "./message.service";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SecurityPagesService {
  private namespace: string = '[SecurityPagesService]';

  public subscriberSecurityPages: string[] = [];

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
  ) {
    this.httpClient
      .get<string[]>(`${environment.restEndpointUrl}/api/v2/securityPage/siteSecurityPageTree`)
      .pipe(
        tap(pages => this.messageService.add(this.namespace, 'Security Pages', pages)),
      )
      .toPromise()
      .then(pages => {
        this.subscriberSecurityPages = pages.map(pageUrl => pageUrl.endsWith('/') ? pageUrl.slice(0, -1) : pageUrl);
      })
      .catch(error => {
        this.messageService.add(this.namespace, 'Failed to get security pages from server', error, MessageService.ERROR);
      });
  }

  /**
   * Takes a relative URL (like those provided by Angular Activated Route) and returns a list of all URLs in descending order of specificity
   * @param url The relative URL (like those provided by Angular Activated Route) to parse into a tree
   * @returns A list of all URLs in descending order of specificity
   * @example
   * urlPathTree('/a/b/c'); // ['/a/b/c', '/a/b', 'a']
   */
  public urlPathTree(url: string) {
    return url.split('/').reduce((acc, cur) =>
      acc.concat((acc.length > 1) ? `${acc[acc.length - 1]}/${cur}` : `/${cur}`),
      [] as string[]
    ).filter(x => x !== '/').reverse();
  }

  /**
   * Locate the closest existing security page to the provided relative URL for the subscriber
   * @param relativeUrl The relative URL (like those provided by Angular Activated Route) to parse into a tree
   * @returns The security page URL of the closest parent security page
   * @example
   * getClosestSecurityPage('/clinical/dashboard'); // '~/CaseManagement/clinical/dashboard'
   */
  public getClosestSecurityPage(relativeUrl: string) {
    if (relativeUrl.endsWith('/'))
      relativeUrl = relativeUrl.slice(0, -1);

    // First, let's just check for a security page in which matches the relative link without needing a parent search
    let match = this.subscriberSecurityPages.find(sp => sp.endsWith('/') ?
      sp.slice(0, -1).endsWith(relativeUrl)
      : sp.endsWith(relativeUrl));
    if (match)
      return match;

    // Find the best matching security page in descending order of specificity
    let urlTree = this.urlPathTree(relativeUrl);
    let bestMatch = '';
    for (let subpath of urlTree) {
      let match = this.subscriberSecurityPages.find(sp => sp.endsWith('/') ?
        sp.slice(0, -1).endsWith(subpath)
        : sp.endsWith(subpath));
      if (match) {
        bestMatch = match;
        break;
      }
    }

    return bestMatch;
  }

  /**
   * Determines if a user has the necessary security pages to access a given relative URL
   * @param relativeUrl The relative URL (like those provided by Angular Activated Route) to parse into a tree
   * @param userSecurityPages The user warp entity, or a list of security page URLs in which you want to check for access
   * @returns A boolean representing if the user has access to the provided relative URL
   * @example
   * canAccessSecurityPage('/clinical/dashboard', userSecurityPages as string[]); // true
   * @example
   * canAccessSecurityPage('/clinical/dashboard', user as WarpEntity); // true
   */
  public canAccessSecurityPage(relativeUrl: string, userSecurityPages: WarpEntity | string[]) {
    if (relativeUrl.endsWith('/'))
      relativeUrl = relativeUrl.slice(0, -1);

    if (!Array.isArray(userSecurityPages))
      userSecurityPages = (userSecurityPages as WarpEntity).securityPages?.map(sp => sp.url) || [];

    let closestSecurityPage = this.getClosestSecurityPage(relativeUrl);
    if (closestSecurityPage === '') // There is no security page applicable to this URL
      return true;

    return (userSecurityPages as string[]).some(sp => sp.endsWith(closestSecurityPage));
  }
}