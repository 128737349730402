import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { IGenFormlyOptions } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { AfterSaveEvent } from '@ripple/ui';
import { TeamContactRoleType, EntityTypes } from '../../_core/models';

@Component({
  selector: 'sagesse-team-contact-role-type-details',
  templateUrl: './team-contact-role-type-details.component.html',
  styleUrls: ['./team-contact-role-type-details.component.scss']
})
export class TeamContactRoleTypeDetailsComponent extends BasePage implements OnInit {

  teamContactRoleType: TeamContactRoleType;
  teamContactRoleTypeService: WarpEntityServiceCache<TeamContactRoleType>;

  generatorOptions: IGenFormlyOptions = {
    styles: {
      name: 'p-sm-12 p-md-12 ui-fluid',
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.teamContactRoleTypeService = this.serviceFactory.get(EntityTypes.TeamContactRoleType) as WarpEntityServiceCache<TeamContactRoleType>;
    this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);
      if (isNaN(id)) {
        this.teamContactRoleType = TeamContactRoleType.empty();
        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.sub = this.teamContactRoleTypeService.get(id).subscribe((teamContactRoleType) => {
          this.teamContactRoleType = teamContactRoleType;
          this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
        });
      }
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') this.router.navigate([`../${e.entity.entityId}`], { relativeTo: this.route });
    else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = 'Team Contact Role Type Deleted Successfully!';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

}
