<ripple-loading-box [loading]="loading" [fullHeight]="true">
    <div class="p-grid h-100" *ngIf="multiSelect">
        <div class="p-sm-12 p-md-8 h-100">
            <p-gmap [options]="gmapOptions" [overlays]="gmapOverlays"
                [style]="{'width':'100%','height':'100%'}" (onMapReady)="onMapReady($event)" >
            </p-gmap>
        </div>

        <div class="p-sm-12 p-md-4 h-100">
            <p-tabView class="h-100">
                <p-tabPanel>
                    <ng-template pTemplate = "header">
                        Groups
                    </ng-template>
                    <ng-template pTemplate="content">
                        <ripple-scroll-card class="h-100">
                            <div class="scroll-card-header">
                                <div class="header header--pad header--bordered">
                                    <input type="text" class="w-100" pInputText placeholder="Community Group Search..." 
                                        [(ngModel)]="communityGroupSearch"
                                        (keyup)="filterCommunityGroups()" (keyup.Enter)="filterCommunityGroups(true)" />
                                </div>
                            </div>
                            <div *ngFor="let communityGroup of communityGroupsFiltered">
                                <p-checkbox name="communityGroups" [value]="communityGroup"
                                    [(ngModel)]="communityGroupsSelected" (onChange)="communityGroupSelect(communityGroup)"></p-checkbox>
                                <label>&nbsp;{{communityGroup.name}}</label>
                            </div>
                        </ripple-scroll-card>
                    </ng-template>
                </p-tabPanel>
                <p-tabPanel>
                    <ng-template pTemplate = "header">
                        Communities
                    </ng-template>
                    <ng-template pTemplate="content">
                        <ripple-scroll-card class="h-100">
                            <div class="scroll-card-header">
                                <div class="header header--pad header--bordered">
                                    <input type="text" class="w-100" pInputText placeholder="Community Search..." 
                                        [(ngModel)]="communitySearch"
                                        (keyup)="filterCommunities()" (keyup.Enter)="filterCommunities(true)" />
                                </div>
                            </div>
                            <div *ngFor="let community of communitiesFiltered">
                                <p-checkbox name="communities" [value]="community"
                                    [(ngModel)]="entity.properties.communities" (onChange)="updateMarkersSelected(community, false)"></p-checkbox>
                                <label>&nbsp;{{community.name}}</label>
                            </div>
                        </ripple-scroll-card>
                    </ng-template>
                </p-tabPanel>
            </p-tabView>
            
        </div>
    </div>
    <div class="p-grid ui-fluid" *ngIf="!multiSelect">
        <div class="p-sm-12 p-md-12 ui-fluid">
            <p-dropdown
                [baseZIndex]="99999999"
                filter="true"
                [options]="communities"
                [(ngModel)]="entity.properties.communities"
                placeholder="Select a Community"
                optionLabel="name"
            ></p-dropdown>
        </div>
    </div>
</ripple-loading-box>