import { Component, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '@ripple/core';
import { FormBuilderComponent, FormBuilderSaveEvent } from '@ripple/formbuilder';
import { CookieType, GenericWarpEntityService, InternalCookieService, LayoutSettingsService, WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { forkJoin, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { EntityTypes, FilledForm } from '../_core/models';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';

@Component({
  selector: 'sagesse-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent extends BasePage implements OnInit {

  filledFormService: WarpEntityServiceCache<FilledForm>;
  filledForm: FilledForm;
  saveCallback = new Subject<boolean>();
  submitting = false;
  saving = false;
  saved = false;
  action = 'fill';
  hasPermission = true;
  msgs: Message[] = [{severity: 'success', summary: 'Thanks for submitting the form!' }];
  @ViewChild('fb') fb: FormBuilderComponent;

  constructor(
    private logService: WarpEntityLogService,
    private layoutSettingsService: LayoutSettingsService,
    private factory: WarpEntityCacheFactoryService,
    private entityService: GenericWarpEntityService,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: InternalCookieService,
  ) {
    super(logService);
  }

  ngOnInit(): void {
    const layoutSettings = this.layoutSettingsService.getSettings();
    layoutSettings.showMenuBarNotifications = false;
    layoutSettings.showMenuBarPrimaryMenu = false;
    layoutSettings.showMenuBarProfileHelpMenu = false;
    layoutSettings.showFooterBar = false;
    layoutSettings['showClaresLawReferralBar'] = false;
    this.layoutSettingsService.setSettings(layoutSettings);
    this.route.params.subscribe((params) => {
      if (params.handoffToken) {
        this.cookieService.setCookie(CookieType.UserToken, params.handoffToken);
      }
      this.filledFormService = this.factory.get(EntityTypes.FilledForm) as WarpEntityServiceCache<FilledForm>;
      const filledFormId = parseInt(params.filledFormId, 10);
      if (!isNaN(filledFormId)) this.loadForm(filledFormId);
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  formName(): string {
    if (!this.filledForm) return '';

    const entity = this.filledForm.formData.value;
    if (entity)
      return entity.name || entity.entityType ? entity.entityType.name : `Form`;

    return this.filledForm.temporaryListName || `Form`;
  }

  loadForm(id) {
    this.filledFormService.checkExisting(id).then(result => {
      if (!result) this.handleNoPermission();
      this.filledFormService
        .get(id)
        .pipe(first())
        .subscribe((filledForm) => {
          this.factory
            .get(filledForm.formtype)
            .get(filledForm.linkedform.id as number)
            .pipe(first())
            .toPromise()
            .then((f) => {
              filledForm.formData.next(f);
              this.filledForm = filledForm;
              if (this.filledForm.formstatus.optionName === 'Submitted') this.action = 'view';
            });
        });
    })
    .catch(error => {
      this.handleNoPermission();
    });
  }

  handleNoPermission() {
    this.hasPermission = false;
    this.msgs = [{severity: 'error', summary: 'You do not have permission to view this form. ' }];
  }

  submitForm(evt: FormBuilderSaveEvent) {
    const form = evt.formData;
    const action = evt.action;

    const filledForm = this.filledForm;

    if (!filledForm.linkedForm) filledForm.linkedProperty('linkedform', form);

    if (action === 'submit') filledForm.property('datecompleted', moment().format('YYYY-MM-DD'));

    form.linkedProperty('entityselect_filledform', filledForm);

    forkJoin([
      this.entityService.getEntityStructure(form.entityTypeId).pipe(first()),
      this.filledFormService.getEntityStructure().pipe(first()),
    ])
      .toPromise()
      .then(([formType, filledFormType]) => {
        filledForm.cfcProperty(
          'formstatus',
          filledFormType
            .getCustomFieldChoices('formstatus')
            .find((c) => c.value === (action === 'submit' ? 'submitted' : 'draft'))
        );

        this.entityService
          .syncWithChildren({ type: formType, entity: form }, { type: filledFormType, entity: filledForm })
          .subscribe(
            (c) => {
              this.saveCallback.next(true);
            },
            (_) => {
              this.saveCallback.next(false);
            }
          );
      });
  }

  formSaved() {
    // Navigate to Thank You Page or returnURL
    this.saved = true;
  }
}
