import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

import { isObservable, Observable, Subscription } from 'rxjs';

import { MultiSelect } from 'primeng/multiselect';
import { Dropdown } from 'primeng/dropdown';

import { IFilterableEntityTypeDescriptor, WarpEntity, EntityFilter, FilterInput, FilterOperator } from '@ripple/models';
import { MessageService, WarpEntityServiceCache } from '@ripple/services';

import { FormBuilderService } from './../../form-builder.service';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';

interface EntityOption {
  id: number;
  name?: string;
}

@Component({
  selector: 'ripple-form-select-entity',
  templateUrl: './form-select-entity.component.html',
  styleUrls: ['./form-select-entity.component.scss']
})
// @dynamic
export class FormSelectEntityComponent extends RippleFieldGenericComponent implements OnInit {
  protected static availableFields = ['question-entity-list', 'is-multi-select'];
  optionSub: Subscription;
  actualOptions = [];
  manualEntry = false;
  originalOptions = null;

  @ViewChild('PSelect') pSelectDataWindow;

  entityService: WarpEntityServiceCache<WarpEntity>;
  filter: EntityFilter;
  defaultFilter: EntityFilter;
  filterTimeout;
  filterKeys = ['Email', 'First Name', 'Last Name', 'Mnemonic', 'ID', 'Telephone1' ].map((s) => ({ label: s, value: s }));
  selectedKeys = this.filterKeys.map((s) => s.value);
  searchString = '';
  baseZIndex = 99999998;
  dropdownFormControl = new FormControl();

  optionsLoading = false;

  optionsFirstLoad = false;
  showOverlay = false;

  get multi() {
    return this.to.isMultiSelect;
  }

  get groupBy() {
    return this.to.moduleSettings && !this.multi && this.to.moduleSettings['groupby'];
  }

  get labelName() {
    return this.groupBy ? '' : 'name';
  }


  /** if this will only show options when searched in the filter */
  get forceLookUp() {
    return this.to.moduleSettings && this.to.moduleSettings['entityfilter_forcelookup'];
  }
  get forceLookUpMinCharacters() {
    return (this.to.moduleSettings && this.to.moduleSettings['entityfilter_forcelookup_mincharacters']) || 3;
  }

  get logName() { return `${this.field.type} [${this.multi ? 'multi' : 'single'}]`; }

  get appendTo() { return this.forceLookUp && this.multi ? 'search' : 'body'; }

  get getStyle() {
    return this.forceLookUp ? 'border-radius: 0 0 4px 4px;' : '';
  }


  constructor(private formBuilderService: FormBuilderService, msg: MessageService) { super(msg); }

  ngOnInit(): void {

    if (!this.multi) {
      this.dropdownFormControl.setValue(this.formControl.value ? this.formControl.value[0] : undefined);
      this.syncControlOptions(true);

      this.nextSub = this.formControl.valueChanges.subscribe( v => {
          if ((!v && this.dropdownFormControl.value) || (v && v[0] !== this.dropdownFormControl.value))
            this.dropdownFormControl.setValue(v ? v[0] : undefined, { emitEvent: false });

          this.syncControlOptions(true);
      });

      this.nextSub = this.dropdownFormControl.valueChanges.subscribe( v => {
        if (!v && this.formControl.value) {
          this.formControl.setValue([]);
          this.formControl.markAsDirty();
        }
        else if (v && (!this.formControl.value || v !== this.formControl.value[0])) {
          this.formControl.setValue([v]);
          this.formControl.markAsDirty();
        }
      });
    } else
      this.nextSub = this.formControl.valueChanges.subscribe( v => {
        this.log('change', v, this.actualOptions);
        if (this.forceLookUp) {
          // if the items in v aren't included in the actualOptions we have just switched the model.
          let clearIt = false;
          if (v && v instanceof Array && v.length > 0) {
            v.forEach(i => {
              if (!this.actualOptions.some(a => a.id === i.id))
                  clearIt = true;
            });
            if (clearIt) {
              this.actualOptions = [...v];
              this.searchString = '';
              this.hideSelectWindow();
              clearIt = false;
            }
          }
          else if (!v)
            clearIt = true;

          if (clearIt) {
            this.actualOptions = [];
            this.searchString = '';
          }
        }
      });

    this.loadOptions();

  }

  /** Get formControl settings and copy into our own controls */
  syncControlOptions(incoming = true) {
    const [sourceControl, destControls] = incoming
      ? [this.formControl, [this.dropdownFormControl]]
      : [this.dropdownFormControl, [this.formControl]];

    for (const control of destControls) {
      if (sourceControl.disabled && !control.disabled)
        control.disable();

      if (sourceControl.enabled && !control.enabled)
        control.enable();

      if (sourceControl.errors)
        control.setErrors(sourceControl.errors);

      if (sourceControl.touched && !control.touched)
        control.markAsTouched();

      if (sourceControl.dirty && !control.dirty)
        control.markAsDirty();

      //TODO: figure out if we need to handle validators
    }
  }

  setOverlay(val: boolean) {
    this.showOverlay = val;
  }

  loadOptions() {
    if (this.model && !this.optionsFirstLoad) {
      // make a memory save here in case the options need to be refreshed
      this.originalOptions = this.to.options;
      this._loadOptions(this.originalOptions);

      // this is an event when an entity has been added and the options need to be updated
      if (this.to.updateOptions && isObservable(this.to.updateOptions))
        this.nextSub = this.to.updateOptions.subscribe(
          () => this._loadOptions(this.originalOptions));

      this.optionsFirstLoad = true;
    }
  }

  _loadOptions(options: EntityOption[] | Observable<EntityOption[]>) {
    if (isObservable(options))
      return this.setOptionsFromObservable(options);

    if (options instanceof Array && options.length > 0)
      return this.setOptionsFromArray(options);

    const listType = this.to.listEntityType as IFilterableEntityTypeDescriptor;

    if (!listType || listType.id === -1) {
      this.manualEntry = true;
      return;
    }

    // use listType to get the options from a serviceCache
    if (!this.forceLookUp)
      this.setOptionsFromObservable(this.formBuilderService.getEntitiesFromID(listType, this.model));
    else {
      this.defaultFilter = listType.entityFilter instanceof Function ? listType.entityFilter(this.model) : listType.entityFilter;
      if (this.defaultFilter)
        this.defaultFilter.idsOnly();

      // this.constructFilterKey()
      this.entityService = this.formBuilderService.getService(listType, this.model);

      // load in the selected ones to start.
      if (this.formControl?.value)
        this.setOptionsFromArray(this.formControl.value);
    }
  }

  // tslint:disable-next-line: no-any
  setOptionsFromObservable(obs: Observable<any>) {
    this.optionsLoading = true;
    this.setEmptyMessage();
    if (this.optionSub) this.optionSub.unsubscribe();
    this.nextSub = this.optionSub = obs.subscribe(data => {
    // only add in the ones that aren';t already in the data.
    if (this.formControl.value instanceof Array && data instanceof Array) {
        this.formControl.value.forEach(i => {
            if (!data.some(r => r.id === i.id))
                data.push(i);
        });
        this.optionsLoading = false;
    }

    // also need to do this off the bat.
    if (data || this.formControl?.value)
        this.setOptionsFromArray(this.sortArrayBySelected(data, this.formControl.value, 'id'));
    else
      this.optionsLoading = false;
    });
  }

  sortArrayBySelected<T>(items: T[] = [], selected: T[] = [], compareKey: keyof T) {
    items = items || [];
    selected = selected || [];
    if (items.length === 0 || selected.length === 0)
      return items;

    items.sort((a, b) => {
      const aSelected = selected.find((i => i[compareKey] === a[compareKey]));
      const bSelected = selected.find((i => i[compareKey] === b[compareKey]));

      if (aSelected && !bSelected)
        return -1;
      if (!aSelected && bSelected)
        return 1;
      return 0;
    });
    return items;
  }

  /**
   * There are four ways to filter the list of entity (clients in this case) to be picked, each has its own strength/limitations \
   * 1. ModuleSettings from rippleslimplatform, this is handled from the beginning,
   *    the filter process was done on the server and return the result set to the client
   *      - Useful when it's a simplefilter for an EntityType's MIT, however if the EntityType is reused somewhere else, caution is advised
   * 2. Add your own customField for Formly, used in session-time-slot-details.component.ts of CCASA,
   *    this is totally customized, everything is done how you want it
   *      - This is easy to filter the entities however you want,
   *        the downsize is that you need to know how Formly works and it breaks the design/code pattern
   * 3. Filter options based on a list of "valid" entities, the server returns a default set,
   *    the client then filter it one more time on the frontend side
   * 4. Filter options based on a function of filter, the server returns a default set, the client then filter it in here
   *    Option 3 and 4 examples are used in file-view.component.ts, both are similar to each other,
   *    useful when you need something customized or are more familiar with JS than Formly
   * - In some special cases however, 3 is more useful than 4 because 3 can get some
   *   special conditions from the server (filter based on another entity's properties for example)
   * - 3 doesn't make another call to the backend so it's more efficient in a way compared to 4
   * - All options can be mixed and matched, or even all of them together to fit your needs. But please don't add anymore filter :joy:
   */
  // tslint:disable-next-line: no-any
  setOptionsFromArray(data: any[]) {
    this.actualOptions = [];
    if (this.groupBy) {
      for (const value of data) {
        const groupByValue = value[this.groupBy] instanceof Object ?
          value[this.groupBy].name || value[this.groupBy].optionName : value[this.groupBy];
        let optionGroup = this.actualOptions.findIndex( o => o.label === groupByValue);
        if (optionGroup === -1) optionGroup = this.actualOptions.push({ label: groupByValue, items: [] }) - 1;

        this.actualOptions[optionGroup].items.push({
          label: value.name,
          value: {
            id: value.guaranteedId || value.id,
            name: value.name
          }
        });
      }
    }
    else {
      if (this.to.customFilter instanceof Function)
        this.actualOptions = this.to.customFilter(data).map(value => ({ id: value.guaranteedId || value.id, name: value.name }));
      else
        this.actualOptions = data.map(value => ({ id: value.guaranteedId || value.id, name: value.name }));

      if (this.formControl.value && this.formControl.value instanceof Array) {
        let deletedOptions = this.formControl.value.filter(v => this.actualOptions.findIndex(o => o.id === v.id) < 0);
        deletedOptions = deletedOptions.map(d => {
        if (!(d.optionName || d.name).includes(' (Deleted)'))
            d.name += ' (Deleted)';
          return d;
        });
        this.actualOptions = [...this.actualOptions, ...deletedOptions];
      }
    }


    this.to.actualOptions  = this.actualOptions;

    if (this.forceLookUp)
        this.openSelectWindow();

    this.optionsLoading = false;
    this.setEmptyMessage();
  }

  manual(value) {
    this.model[this.key as string] = {
      id: value,
      name: undefined,
    };

    this.form.get(this.key as string).setValue({
      id: value,
      name: undefined,
    });
  }

  setEmptyMessage() {
    const emptyMessageElement = this.multi ? document.querySelector('.ui-multiselect-empty-message') : document.querySelector('.ui-dropdown-empty-message');

    if (emptyMessageElement)
        if (this.optionsLoading)
            emptyMessageElement.innerHTML = '<i class="pi pi-spinner pi-spin"></i>';
        else
            emptyMessageElement.innerHTML = 'No results found';
  }

  buttonOnClick($event) {
    if (this.to.additionalButtonOnClick)
      this.to.additionalButtonOnClick($event);
  }

  // constructFilterKey() {
  //   // TODO: make this dynamic, if we still use it
  //   // needs to be updated for each new entity type(should be changed to something that uses formly)
  //   let keyArr = [];
  //   // organization
  //   if (this.entityService.entityTypeId === 611)
  //     keyArr = ['Name', 'PhoneNumber'];
  //   // client
  //   else if (this.entityService.entityTypeId === 627)
  //     keyArr = ['Email', 'First Name', 'Last Name', 'Mnemonic', 'ID', 'Telephone1' ];
  //   // user
  //   else if (this.entityService.entityTypeId === 2)
  //     keyArr = ['Email', 'First Name', 'Last Name' ];

  //   this.filterKeys = keyArr.map((s) => ({ label: s, value: s }));
  //   // Don't reset  the selected filters
  //   // this.selectedKeys = this.selectedKeys.filter(s => this.filterKeys.findIndex(f => f.value === s) >= 0);
  // }

  testRefresh() {
      this.actualOptions = [...this.actualOptions];
  }

  filterChange(value){
    this.log('Filter Value', value, this.formControl);
  }

  filterClients(valueStr: string, now: boolean = false) {
    if (this.filterTimeout)
        clearTimeout(this.filterTimeout);

    const value = (valueStr || '')
      .split(/\s/)
      .map((v) => v.trim())
      .filter((t) => t && t !== '');

    // count non-space chars
    const valLength = value.reduce( (t, s) => t + s.length, 0);

    if (valLength >= this.forceLookUpMinCharacters) {
      const filters: FilterInput = this.selectedKeys
        // .filter(s => this.filterKeys.findIndex(f => f.value === s) >= 0)
        .map((key) => ({ key, value, operator: FilterOperator.Like }));

      const filter = this.defaultFilter.Subset();

      this.filterTimeout = setTimeout(
        () => {
          this.filter = value && value.length ? filter.AdvancedUnion(filters) : filter;
          this.initQuery();
        },
        now ? 0 : 200
      );
    } else {
      this.filter = this.defaultFilter
        .Subset()
        .AdvancedUnion({
          key: this.filterKeys[0].value,
          value: '--------NONE/WILL/MATCH--------',
          operator: FilterOperator.EqualTo
        });

      this.initQuery();
    }
  }

  resetZIndex() {
    this.baseZIndex = 99999999;
  }

  openSelectWindow() {
    if (this.pSelectDataWindow)
      this.pSelectDataWindow.show();
  }

  hideSelectWindow() {
    if (this.pSelectDataWindow)
      this.pSelectDataWindow.hide();
  }

  initQuery() {
    this.setOptionsFromObservable(this.entityService.initQuery(this.filter).getPage(0));
  }

  get min() {
    return Math.min;
  }

  modelChanged(event) {
    if (event) {
      if (Array.isArray(event)) { // multiselect
        const missingOpts = event.filter((v) => !this.actualOptions.find((o) => o.id === v.id));
        if (missingOpts.length) {
          this.actualOptions.splice(this.actualOptions.length, 0, missingOpts);
          this.to.actualOptions = [...this.actualOptions];
        }
      }
      else if (!this.actualOptions.find(r => r.id === event.id)) {
        this.actualOptions.push({
          id: event.id,
          name: event.name
        });
        this.actualOptions = [...this.actualOptions];
        this.to.actualOptions = [...this.actualOptions];
      }
    }
  }
}
