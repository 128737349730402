import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ripple-marker-lined-block',
  templateUrl: './marker-lined-block.component.html',
  styleUrls: ['./marker-lined-block.component.scss']
})
export class MarkerLinedBlockComponent implements OnInit {

  @Input() markerColor: string;
  @Input() lineColor: string;

  constructor() { }

  ngOnInit(): void {
  }
}
