<p-toast [position]="toastPosition" (onClose)="messageClosed($event)">
  <ng-template let-message pTemplate="message">
    <div class="p-grid" style="align-items: center;">
      <div class="p-col-3">
        <i class="pi pi-exclamation-circle" style="font-size: 3em"></i>
      </div>
      <div [ngClass]="message.data.instant === true ? 'p-col-9' : 'p-col-6'">
        <strong>{{message.summary}}</strong> <br />
        <div innerHtml="{{message.detail}}"></div>
      </div>
      <div class="p-col-3" *ngIf="message.data.instant !== true">
        <p-progressSpinner [hidden]="message.data.inProgress === false" [style]="{width: '50px', height: '50px'}"
          strokeWidth="8" fill="#EEEEEE" animationDuration="1s">
        </p-progressSpinner>
        <i class="pi pi-check" style="font-size: 3em" [hidden]="message.data.complete === false"></i>
      </div>
    </div>
  </ng-template>
</p-toast>
<!-- Here lies the ripple form viewer:
<ripple-form-viewer [formData]="filledFormData" [form]="form"></ripple-form-viewer> -->

<p-overlayPanel #op [baseZIndex]="10000" [dismissable]="true" [showCloseIcon]="false" [styleClass]="'notifications-overlay-panel'" appendTo="body"
  (onShow)="overlayPanelShow = true" (onHide)="overlayPanelShow = false">
  <div class="notifications-panel" *ngIf="overlayPanelShow">
    <div class="notifications-panel__header">
      <h3 class="notifications-panel__heading">
        <i class="pi pi-volume-{{mute ? 'off': 'up'}}" (click)="toggleMute()"></i>
        Notifications
      </h3>
      <a href="javascript:;" (click)="dismissAllMessages()">Clear All</a>
    </div>

    <div class="notifications-panel__body">
        <h3 class="notification-group-heading" *ngIf="listOfInProgressMessages.length > 0">In-Progress</h3>
        <ng-container *ngFor="let message of listOfInProgressMessages">
          <div class="notification">
            <div class="status">
              <p-progressSpinner
                  [style]="{width: '32px', height: '32px'}" strokeWidth="2" fill="#EEEEEE" animationDuration="3s">
                </p-progressSpinner>
                <div [hidden]="!false || message.data.progress || message.data.progress === '0'">
                  {{message.data.progress}}%
                </div>
            </div>
            <div class="summary">
              <h4 class="heading">{{message.summary}}</h4>
              <div class="detail" innerHtml="{{message.detail}}"></div>
              <div class="date">{{ message.data.date | amTimeAgo }}</div>
            </div>
            <div class="action">
              <button class="r-btn r-btn--icon r-btn--icon-default" (click)="dismissMessage(message)"><i class="pi pi-times"></i></button>
            </div>
          </div>
        </ng-container>

        <h3 class="notification-group-heading" *ngIf="listOfInstantMessages.length > 0">Completed</h3>
        <ng-container *ngFor="let message of listOfInstantMessages">
          <div class="notification" [ngClass]="{'notification--failed' : message.data.failed}">
            <div class="status">
              <i class="pi pi-check" [hidden]="message.data.complete === false"></i>
              <i class="pi pi-exclamation-triangle" [hidden]="message.data.failed === false"></i>
            </div>
            <div class="summary">
              <h4 class="heading">{{message.summary}}</h4>
              <div class="detail" innerHtml="{{message.detail}}"></div>
              <div class="date">{{ message.data.date | amTimeAgo }}</div>
            </div>
            <div class="action">
              <button class="r-btn r-btn--icon r-btn--icon-default" (click)="dismissMessage(message)"><i class="pi pi-times"></i></button>
            </div>
          </div>
        </ng-container>
        <!-- <div class="background-container">
          Past-session Notification:
          <div>
            <p-progressSpinner *ngIf="loadingPastNotification"></p-progressSpinner>
          </div>
          <ng-container *ngIf="!loadingPastNotification">
            <div class="p-grid background-alternate align-items-center notification-container"
              *ngFor="let message of listOfPastNotifications">
              <div class="p-col-9">
                <strong>{{message.summary}}</strong> <br />
                {{message.detail}}
                <br />
                <div>
                  {{ message.data.date | amTimeAgo }}
                </div>
              </div>
              <div class="p-col-3">
                <i class="pi pi-check" style="font-size: 3em" [hidden]="message.data.complete === false"></i>
                <i class="pi pi-exclamation-triangle" style="font-size: 3em" [hidden]="message.data.failed === false"></i>
              </div>
            </div>
          </ng-container>
        </div> -->
    </div>
      <div class="notifications-panel__footer">
      <a routerLink="{{viewAllLink}}">View All Notifications</a>
    </div>
  </div>
</p-overlayPanel>

<i class="pi icon pi-bell bell" (click)="op.toggle($event)" *ngIf="showIcon"></i>

<!-- <input type="text" [(ngModel)]="testID" placeholder="type id">
<button type="text" pButton label="Change ID" (click)="changeStatus()"></button> -->
