import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@ripple/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export const MFAStatus = {
  Waiting: 'WAITING',
  Saving: 'SAVING',
  Success: 'SUCCESS',
  Cancelling: 'CANCELLING',
  IncorrectToken: 'INCORRECT_TOKEN',
} as const;

export type QRCodeSetup = {
  imageURL: string;
  accountName: string;
  manualKey: string;
};

export type MFAStatusEnum = (typeof MFAStatus)[keyof typeof MFAStatus];

@Injectable({
  providedIn: 'root',
})
export class MFAService {
  private readonly _status = new BehaviorSubject<MFAStatusEnum>(
    MFAStatus.Waiting
  );
  private readonly _setup = new BehaviorSubject<QRCodeSetup | null>(null);

  constructor(private readonly http: HttpClient) {}

  private readonly baseURL = `${environment.restEndpointUrl}/api/account`;
  public readonly status$ = this._status.asObservable();
  public readonly setup$ = this._setup.asObservable();

  public startMFA(setup: QRCodeSetup) {
    this._setup.next(setup);
    this._status.next(MFAStatus.Waiting);
  }

  public endMFA() {
    this._setup.next(null);
    this._status.next(MFAStatus.Waiting);
  }

  public isMFAEnabled(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseURL}/mfaEnabled`);
  }

  public setupMFA(mfaToken: number): Observable<boolean> {
    const body = {
      mfaToken,
    };

    this._status.next(MFAStatus.Saving);

    return this.http
      .post<boolean>(`${this.baseURL}/setupMFA`, body)
      .pipe(
        tap((valid) => {
          this._status.next(
            valid ? MFAStatus.Success : MFAStatus.IncorrectToken
          );
        })
      )
  }

  public cancelMFASetup(): Observable<boolean> {
    this._status.next(MFAStatus.Cancelling);
    return this.http
      .post<boolean>(`${this.baseURL}/cancelMFA`, {})
      .pipe(tap(() => this.endMFA()))
  }

  public canSetupMFA(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseURL}/mfaSetup`);
  }

  public startMFASetup(): Observable<QRCodeSetup> {
    return this.http
      .post<SetupResponse>(`${this.baseURL}/startMFASetup`, {})
      .pipe(
        map((r) => ({
          accountName: r.lfaManualAccountName,
          imageURL: r.imgMfaQrCode,
          manualKey: r.lMfaManualKey,
        }))
      );
  }
}
type SetupResponse = {
  lfaManualAccountName: string;
  imgMfaQrCode: string;
  lMfaManualKey: string;
};
