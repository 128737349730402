<div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">Strategic Plan Report Viewer </h1>
      <div class="header__actions">
        <!--<div class="ui-fluid p-sm-12 p-md-12">
          <label>Report Date Range</label>
          <p-calendar [(ngModel)]="dateRange" view="month" dateFormat="mm/yy" selectionMode="range"
            [readonlyInput]="true" inputId="range" class="heading-action-row__heading"
          >
          </p-calendar>

          <button label="Get Report" type="button" pButton (click)="changedDatePicker()"
          style="margin-top: 10px;"></button>
        </div>-->
      </div>
    </div>
  </div>
  <div class="pane__body">
    <div class="filter-container">
      <p-accordion>
        <p-accordionTab header="Filter" [selected]="editingFilters">
          <div class="ui-fluid p-grid p-align-stretch vertical-container">
            <div class="ui-fluid p-col-12 p-md-4 p-grid p-dir-col">
              <div class="ui-fluid p-col">
                <label>Report Date Range</label>
                <p-calendar
                  [(ngModel)]="dateRange" view="month" dateFormat="mm/yy" selectionMode="range"
                  [readonlyInput]="true" inputId="range"
                ></p-calendar>
              </div>
              <div class="ui-fluid p-col p-grid p-align-end p-justify-end">
                <p-button class="pr-2" styleClass="ui-button-secondary" icon="pi pi-check" iconPos="left" label="Generate Report" (click)="generateReport()"></p-button>
              </div>
            </div>
            <div class="ui-fluid p-col-12 p-md-8">
              <label>Included Outcomes and Statistics</label>
              <p-tree
                class="w-full" selectionMode="checkbox" filterPlaceholder="Filter"
                [value]="stats" [loading]="statsLoading" [(selection)]="selectedStats" [filter]="true"
              ></p-tree>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </div>

    <div class="mb-2" *ngIf="!loading && firstLoad">
      <button pButton (click)="exportReport()" icon="pi pi-file-excel" label="Export To Excel"></button>
    </div>

    <div *ngIf="!loading && firstLoad">
      <div class="scroller" [style.height]="tableHeight">
      <table>
        <thead>
          <tr>
            <th class="sticky-col">Name</th>
            <th *ngFor="let col of cols">{{col.header}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let outcome of reportData">
            <tr>
              <td class="ouctome-heading sticky-col" [attr.colspan]="getColSpan() + 1">
                {{outcome.name}}
              </td>
            </tr>
            <ng-container *ngFor="let subcat of outcome.subCats">
              <tr>
                <td class="subcat-heading sticky-col" [attr.colspan]="getColSpan() +1">
                  {{subcat.name}}
                </td>
              </tr>
              <ng-container *ngFor="let stat of subcat.stats">
                <tr>
                  <td class="sticky-col">{{stat.name}}</td>
                  <ng-container *ngFor="let col of cols">
                    <td class="number-cell" *ngIf="col.month != null && col.target != true && col.yeartotal != true"
                      [ngClass]="col.highlight ? 'highlight' : sanityCheck(stat, col.year, col.month) ? 'failedcheck'
                                : null" (click)="openModal(stat, col.month, col.year)">
                      {{stat.years[col.year][col.month].value}}
                      <i *ngIf="sanityCheck(stat, col.year, col.month) " class="pi pi-exclamation-circle"></i>
                    <i *ngIf="stat.years[col.year][col.month].hasMonthComments" class="pi pi-comment" style="font-size: 0.85rem"></i>
                    </td>
                    <td *ngIf="col.target == true && targets" class="highlight">
                      <input type="text" placeholder="empty" *ngIf="agecnyStatsAdmin"
                        (change)="onChange(stat.targets[col.quarter.toString() + col.year.toString()])"
                        [(ngModel)]="stat.targets[col.quarter.toString() + col.year.toString()].properties.value" />
                      <ng-container *ngIf="!agecnyStatsAdmin">
                        {{stat.targets[col.quarter.toString() + col.year.toString()].properties.value}}
                      </ng-container>
                    </td>
                  </ng-container>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>

      <!-- <p-table class="stat-table" #dt [columns]="cols" [frozenColumns]="frozenCols" [value]="reportData"
        [scrollable]="true" [scrollHeight]="tableHeight" frozenWidth="200px"> -->
        <!-- <ng-template pTemplate="colgroup">
          <colgroup>
            <col style="width: 160px;">
            <col *ngFor="let col of cols" style="width:85px">
          </colgroup>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th class="sticky-col" >Name</th>
            <th *ngFor="let col of cols">
              {{col.header}}
            </th>
          </tr>
        </ng-template> -->

        <!-- <ng-template pTemplate="colgroup" let-columns>
          <colgroup>
            <col *ngFor="let col of columns" style="width:200px">
          </colgroup>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
          <tr>
            <th *ngFor="let col of columns">
              {{col.header}}
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-outcome let-columns="columns">
          <tr [pSelectableRow]="outcome">
            <td class="ouctome-heading" [attr.colspan]="cols.length + 1">
              {{outcome.name}}
            </td>
          </tr>
          <ng-container *ngFor="let subcat of outcome.subCats">
            <tr>
              <td class="subcat-heading" [attr.colspan]="cols.length + 1">
                {{subcat.name}}
              </td>
            </tr>
            <ng-container *ngFor="let stat of subcat.stats">
              <tr>
                <td class="sticky-col"> {{stat.name}} </td>
                <ng-container *ngFor="let col of columns">
                  <td class="number-cell" *ngIf="col.month != null && col.target != true && col.yeartotal != true"
                    [ngClass]="col.highlight ? 'highlight' : sanityCheck(stat, col.year, col.month) ? 'failedcheck'
                     : null" (click)="openModal(stat, col.month, col.year)">
                    {{stat.years[col.year][col.month]}}
                    <i *ngIf="sanityCheck(stat, col.year, col.month) " class="pi pi-exclamation-circle"></i>
                  </td>
                  <td *ngIf="col.target == true && targets" class="highlight">
                    <input type="text" placeholder="empty" *ngIf="agecnyStatsAdmin"
                      (change)="onChange(stat.targets[col.quarter.toString() + col.year.toString()])"
                      [(ngModel)]="stat.targets[col.quarter.toString() + col.year.toString()].properties.value" />
                    <ng-container *ngIf="!agecnyStatsAdmin">
                      {{stat.targets[col.quarter.toString() + col.year.toString()].properties.value}}
                    </ng-container>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </ng-template>
      </p-table> -->
    </div>
    <p-progressSpinner *ngIf="loading"></p-progressSpinner>
  </div>
  
  <!-- <button *ngIf="firstLoad" pButton (click)="exportReport()" style="margin-top: 20px;" label="Export To Excel"></button> -->

  <p-dialog header="Statistic Details" [(visible)]="statDetailsVisible" [appendTo]="'body'" [modal]="true"
    [draggable]="false" [baseZIndex]="10000" [dismissableMask]="true">
    <div class="modal-content" style="max-width: 1000px !important;">
      <button label="Stat Editor" type="button" pButton (click)="redirectToStatEditor()"
        style="margin-bottom: 10px;"></button>

      <p-table [value]="filledStatistics">
        <ng-template pTemplate="header">
          <tr>
            <th>Name</th>
            <th>Value</th>
            <th >Notes</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-stat>
          <tr>
            <td>{{stat.properties.linkedstatistic}}</td>
            <td>{{stat.properties.value}}
            </td>
            <td> <div *ngFor="let item of stat.monthNotes">
              {{item.properties.notes}}
            </div></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>
</div>
