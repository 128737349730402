import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { EntityTypes, Program, StatisticGroup} from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { first, map, switchMap, startWith, tap, distinctUntilChanged } from 'rxjs/operators';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { AfterSaveEvent } from '@ripple/ui';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'sagesse-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss']
})
export class ProgramDetailsComponent extends BasePage implements OnInit {
  programService: WarpEntityServiceCache<Program>;
  statisticGroupService: WarpEntityServiceCache<StatisticGroup>;
  program: Program;
  loading: boolean = false;
  loadingGroups: boolean = false;
  showGroupModalFlag: boolean = false;
  addingGroup: boolean = false;
  statGroup: StatisticGroup;

  statisticGroups: StatisticGroup[] = [];
  fieldOrders = [];
  generatorOptions: IGenFormlyOptions;
  generatorOptionsGroup: IGenFormlyOptions = {
    hiddenCondition:
      (cfim: CustomFieldInModule) =>
        cfim.key.toLowerCase() === 'linkedprogram',
    styles: {
      name: 'p-sm-12 p-md-12 p-lg-12 ui-fluid',
    }
  };

  constructor(
    private logService: WarpEntityLogService,
    private factory: WarpEntityCacheFactoryService,
    private router: Router,
    private route: ActivatedRoute
    ) {
    super(logService);
    this.programService = factory.get(EntityTypes.Program) as WarpEntityServiceCache<Program>;
    this.statisticGroupService = factory.get(EntityTypes.StatisticGroup) as WarpEntityServiceCache<StatisticGroup>;
   }

   actionMenu = new Array<MenuItem>();



  ngOnInit(): void {
    this.actionMenu.push({
      label: 'Delete',
      icon: 'pi pi-times',
      command: () => this.delete(),
    });

    this.loadingGroups = true;
    // Grabbing Program
    this.nextSub = this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);
      this.nextSub = this.programService.get(id).subscribe((program) => {
        this.program = program;

        // Grabing groups that belong to program
        const filter = EntityFilter.Advanced({ LinkedProgram_lid: this.program.id });
        this.nextSub = this.statisticGroupService.initQuery(filter, 9999999).getPage(0).subscribe(resp => {
          this.statisticGroups = resp;

          this.statisticGroups.sort((a,b) => {
            if (a.properties.name > b.properties.name) return 1;
            else return -1;
          });
          
          this.loadingGroups = false;
        });
      });
    });
  }

  save() {
    this.loading = true;
    this.programService.update(this.program).toPromise().then(resp=> {
      console.log("Program Saved");
      this.loading = false;
    });
  }

  delete() {
    this.loading = true;
    this.programService.delete(this.program).then(resp => {
      this.loading = false;
      this.router.navigateByUrl('programs')
    });
  }


  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  showGroupModal(action:string) {
    this.showGroupModalFlag = true;
    switch (action) {
      case 'add':
        this.statGroup = StatisticGroup.empty().linkedProperty("linkedprogram", this.program);
    }
  }


  addGroup() {
    this.addingGroup = true;
    this.statisticGroupService.create(this.statGroup).toPromise().then((entId) => {
      this.addingGroup = false;
      this.showGroupModalFlag = false;
    });
  }

  handleAfterSave(e: AfterSaveEvent) {
    // switch (e.entity.entityTypeId) {
    //   case EntityTypes.EventFolder:
    //     this.currentNode.data = new EventFolder(e.entity);
    //     this.currentNode.label = e.entity.properties.name;
    //     break;
    //   case EntityTypes.EventTemplate:
    //     this.currentNode.data = new EventTemplate(e.entity);
    //     this.currentNode.label = e.entity.properties.name;
    //     break;
    //   case EntityTypes.EducationEvent:
    //     this.currentNode.data = new EducationEvent(e.entity);
    //     this.currentNode.label = this.currentNode.data.title;
    //     break;
    // }

    //this.afterSave.emit(e);
  }

}
