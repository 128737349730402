import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, AuthService } from '@ripple/services';
import { EntityTypes, Program } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType } from '@ripple/models';
import { first, map, switchMap, startWith, tap, distinctUntilChanged } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { CommunityGroupsComponent } from '../../community-groups/community-groups.component';


@Component({
  selector: 'sagesse-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent extends BasePage implements OnInit {

  programLoading: boolean  = false;
  programs: any[] = [];

  programService: WarpEntityServiceCache<Program>;
  programManager: boolean = false;

  constructor(
    private logService: WarpEntityLogService,
    private factory: WarpEntityCacheFactoryService,
    private router: Router,
    private authService: AuthService,

  ) {
    super(logService);
    this.programService = factory.get(EntityTypes.Program) as WarpEntityServiceCache<Program>;
  }

  ngOnInit(): void {
    this.nextSub = this.authService.getLoggedInUser().subscribe(currentUser => {
      this.isProgramManager(currentUser);
      console.log("Program manager", this.programManager)
    });

    this.nextSub = this.programService
      .initQuery(EntityFilter.None, 1000000)
      .getPage(0)
      .subscribe((programs) => {
        this.programs = programs;

        this.programs.forEach(r => {
          r.actionMenu = new Array<MenuItem>();
          r.actionMenu.push({
            label: 'Delete',
            icon: 'pi pi-times',
            command: () => this.delete(r),
          });
        });
      });
  }


  isProgramManager(currentUser) {
    currentUser.roles.every(role => {
      if (role.name == "Agency Stats Program Manager") {
        this.programManager = true;
        return false;
      }
      return true;
    });
  }

  addProgram() {
    // Might be better to have a modal or something for the name before doing this
    const newProgram = Program.empty();
    this.programService.create(newProgram).toPromise().then((entId) => {
      this.view(entId);
    });
  }

  view(programId) {
    this.router.navigateByUrl('program/' + programId.toString());
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  delete(program) {
    program.deleting = true;
    this.programService.delete(program).then(resp=> {
      console.log('Deleted Program', resp);
    });
  }

}
