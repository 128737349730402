import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';
import { StrategicOutcome } from './StrategicOutcome';

export class StrategicOutcomeStatistic extends WarpEntity {
  id: string | number;
  actionMenu: any;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.StrategicOutcomeStatistic;
  }

  static empty(): StrategicOutcomeStatistic {
    return new StrategicOutcomeStatistic(super.emptyEntity(EntityTypes.StrategicOutcomeStatistic));
  }

}
