import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//#region Primeng
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
//#endregion

//#region Other Dependencies
import { ngfModule } from 'angular-file';
import { CanvasWhiteboardModule } from 'ng2-canvas-whiteboard';
import { MomentModule } from 'ngx-moment';

// Angular Material
import { MatIconModule } from '@angular/material/icon';

// Json Viewer
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { UnderConstructionBlurbComponent } from './under-construction-blurb/under-construction-blurb.component';

// Formly
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyPrimeNGModule } from '@ngx-formly/primeng';
//#endregion

//#region Ripple
import { FORMBUILDER_FORMLY_OPTIONS, FormBuilderModule } from '@ripple/formbuilder';
//#endregion

//#region Partials
import { AttribComponent } from './attrib/attrib.component';
import { ExternalAuthComponent } from './auth/auth.component';
import { BreadcrumbComponent, BreadcrumbItem } from './breadcrumb/breadcrumb.component';
import { ButtonListComponent } from './button-list/button-list.component';
import { DefaultEntityPageComponent } from './default-entity-page/default-entity-page.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { AfterSaveEvent, ButtonActions, EntityDetailsComponent } from './entity-details/entity-details.component';
import { EntityFilterComponent } from './entity-filter/entity-filter.component';
import { EntityListComponent } from './entity-list/entity-list.component';
import { EntityLogsComponent } from './entity-logs/entity-logs.component';
import { EntityNoteComponent } from './entity-notes/entity-note/entity-note.component';
import { EntityNotesComponent } from './entity-notes/entity-notes.component';
import { EntityScrollViewComponent } from './entity-scroll-view/entity-scroll-view.component';
import { TagsComponent } from './entity-tags/tags.component';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { ImporterComponent } from './importer/importer.component';
import { LoadingBoxComponent } from './loading-box/loading-box.component';
import { MarkerLinedBlockComponent } from './marker-lined-block/marker-lined-block.component';
import { MenuComponent } from './menu/menu.component';
import { MsTeamFileExplorerComponent } from './ms-team-file-explorer/ms-team-file-explorer.component';
import { NewItemCardComponent } from './new-item-card/new-item-card.component';
import { PaperworkComponent } from './paperwork/paperwork.component';
import { PrivacyButtonComponent } from './privacy-button/privacy-button.component';
import { PrivacyLabelComponent } from './privacy-label/privacy-label.component';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { ResizableLayoutComponent } from './resizable-layout/resizable-layout.component';
import { ScrollCardComponent } from './scroll-card/scroll-card.component';
import { StickyNoteComponent } from './sticky-note/sticky-note.component';
import { NotificationListComponent } from './task-notification/notification-list/notification-list.component';
import { TaskNotificationComponent } from './task-notification/task-notification.component';
import { AccountComponent } from './users/account/account.component';
import { MFAComponent } from './users/account/mfa.component';
import { AvatarComponent } from './users/avatar/avatar.component';
//#endregion

//#region Reporting
import {
  ExcelExportableDirective,
  KpiItemComponent,
  ReportQuestionsComponent,
  ReportSectionsComponent,
  SnippetDataDirective,
  SnippetFilterComponent,
  SnippetTotalComponent,
  StatCardComponent,
  StatCardsComponent,
} from './reports';
//#endregion

//#region Dev Tools
import { TodoComponent } from './dev-tools/todo/todo.component';
import { EntityDebugComponent } from './entity-debug/entity-debug.component';
import { InvalidFieldsButtonComponent } from './invalid-fields-button/invalid-fields-button.component';
//#endregion


const components = [
  MarkerLinedBlockComponent,
  EntityScrollViewComponent,
  TaskNotificationComponent,
  ProgressSpinnerComponent,
  DefaultEntityPageComponent,
  EntityDetailsComponent,
  EntityDebugComponent,
  NewItemCardComponent,
  EntityNotesComponent,
  EntityNoteComponent,
  EntityListComponent,
  EntityLogsComponent,
  ButtonListComponent,
  StickyNoteComponent,
  ScrollCardComponent,
  LoadingBoxComponent,
  EmptyStateComponent,
  FooterBarComponent,
  PaperworkComponent,
  AccountComponent,
  MFAComponent,
  AvatarComponent,
  AttribComponent,
  MenuComponent,
  NotificationListComponent,
  UnderConstructionBlurbComponent,
  PrivacyButtonComponent,
  PrivacyLabelComponent,

  ExternalAuthComponent,
  EntityFilterComponent,
  ResizableLayoutComponent,
  MsTeamFileExplorerComponent,
  BreadcrumbComponent,
  TagsComponent,
// reports
  StatCardComponent,
  StatCardsComponent,
  SnippetFilterComponent,
  SnippetDataDirective,
  SnippetTotalComponent,
  ExcelExportableDirective,
  ReportQuestionsComponent,
  ReportSectionsComponent,
  KpiItemComponent,

  ImporterComponent,
  TodoComponent,
  InvalidFieldsButtonComponent,
];

// export all those helper models from entity-list
export * from './entity-list/entity-list.component';
export * from './reports';

export {
  AccountComponent,
  AfterSaveEvent,
  AttribComponent,
  AvatarComponent,
  BreadcrumbComponent,
  BreadcrumbItem,
  ButtonActions,
  ButtonListComponent,
  DefaultEntityPageComponent,
  EmptyStateComponent,
  EntityDebugComponent,
  EntityDetailsComponent,
  EntityFilterComponent,
  EntityLogsComponent,
  EntityNoteComponent,
  EntityNotesComponent,
  EntityScrollViewComponent,
  ExternalAuthComponent,
  FooterBarComponent,
  ImporterComponent,
  InvalidFieldsButtonComponent,
  LoadingBoxComponent,
  MarkerLinedBlockComponent,
  MenuComponent,
  MFAComponent,
  MsTeamFileExplorerComponent,
  NewItemCardComponent,
  NgbModule,
  NotificationListComponent,
  PaperworkComponent,
  PrivacyButtonComponent,
  PrivacyLabelComponent,
  ProgressSpinnerComponent,
  ResizableLayoutComponent,
  ScrollCardComponent,
  StickyNoteComponent,
  TagsComponent,
  TaskNotificationComponent,
  TodoComponent,
  UnderConstructionBlurbComponent,
};

@NgModule({
  declarations: [
    ...components,
    PrivacyButtonComponent,
    PrivacyLabelComponent,
    InvalidFieldsButtonComponent
  ],
  exports: [
    ...components
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormBuilderModule,

    // Formly
    ReactiveFormsModule,
    FormlyPrimeNGModule,
    FormlyModule.forRoot(FORMBUILDER_FORMLY_OPTIONS),
    FormsModule,

    // PrimeNG
    VirtualScrollerModule,
    ProgressSpinnerModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    SplitButtonModule,
    InputSwitchModule,
    InputTextModule,
    AccordionModule,
    CalendarModule,
    CheckboxModule,
    MessagesModule,
    MessageModule,
    ToolbarModule,
    ButtonModule,
    DialogModule,
    PanelModule,
    TableModule,
    ToastModule,
    MenuModule,
    CardModule,
    NgbModule,
    DropdownModule,
    FileUploadModule,
    CanvasWhiteboardModule,
    PasswordModule,
    MultiSelectModule,
    SelectButtonModule,
    BreadcrumbModule,
    AutoCompleteModule,
    TooltipModule,

    // other styling
    MomentModule,
    ngfModule,

    // angular material
    MatIconModule,
    InputTextareaModule,
    NgxJsonViewerModule
  ],
  providers: [],
})
export class PartialsModule { }
