<div class="p-grid organization-contact-card">
    <div class="p-col-4 p-d-flex p-align-center">
        <span class="person-char">{{ personChar }}</span>
        <a href="javascript:;" (click)="goToContact()" [innerHTML]="displayName"></a>
    </div>
    <div class="p-col-4" style="align-self: center">
        <div *ngIf="!inTeam">
            <i class="pi pi-user"></i> {{contact.generateRoleString()}}
        </div>
        <div>
            <a href="mailto:{{contact.email || contact.contactEntity.properties.email}}">
                <i class="pi pi-envelope"></i> {{contact.email || contact.contactEntity.properties.email}}
            </a>
        </div>
        <div *ngIf="!inTeam">
            <a href="tel:{{contact.phonenumber || contact.contactEntity.properties.phonenumber}}">
                <i class="fas fa-phone"></i> {{contact.phonenumber || contact.contactEntity.properties.phonenumber}}
            </a>
        </div>
    </div>
    <div class="p-col-4 p-d-flex p-align-center" *ngIf="!inTeam && contact && contact.teamContacts">
        <div>
            <a *ngFor="let teamContact of contact.teamContacts" href="javascript:;" (click)="channelListShow = true;teamContactId = teamContact.id">
                <span class="badge rounded-pill badge--white mr-1 mb-1">
                    {{teamContact?.team?.lengh > 20 ? teamContact.team.substring(0, 20) + '...' : teamContact.team }}
                </span>
            </a>
        </div>
    </div>
</div>

<p-dialog header="Channel List"
    [(visible)]="channelListShow"
    *ngIf="channelListShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000"
    appendTo="body">
    <sagesse-channel-members [teamContactId]="teamContactId"></sagesse-channel-members>
</p-dialog>



