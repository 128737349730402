import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, AuthService } from '@ripple/services';
import { EntityTypes, StrategicOutcome } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';


@Component({
  selector: 'sagesse-strategic-plan-builder',
  templateUrl: './strategic-plan-builder.component.html',
  styleUrls: ['./strategic-plan-builder.component.scss']
})
export class StrategicPlanBuilderComponent extends BasePage implements OnInit {
  showAddOutcomeFlag: boolean = false;
  strategicOutcomeService: WarpEntityServiceCache<StrategicOutcome>;

  outcome: StrategicOutcome;
  addingOutcome: boolean = false;
  strategicOutcomes: Array<StrategicOutcome> = [];

  generatorOptions: IGenFormlyOptions = {
    hiddenCondition:
      (cfim: CustomFieldInModule) =>
        cfim.key.toLowerCase() === 'linkedprogram',
    styles: {
      name: 'p-sm-12 p-md-12 p-lg-12 ui-fluid',
    }
  };
  agecnyStatsAdmin: boolean = false;

  constructor(
    private logService: WarpEntityLogService,
    private factory: WarpEntityCacheFactoryService,
    private authService: AuthService,

  ) {
    super(logService);
    this.strategicOutcomeService = factory.get(EntityTypes.StrategicOutcome) as WarpEntityServiceCache<StrategicOutcome>;
  }

  ngOnInit(): void {
      this.authService.getLoggedInUser().subscribe(currentUser => {
        this.isAgencyStatsAdmin(currentUser);
      });

    this.nextSub = this.strategicOutcomeService.initQuery().getPage(0).subscribe(resp => {
      this.strategicOutcomes = resp;
      this.strategicOutcomes.sort((a,b) => {
        if (a.properties.orderindex > b.properties.orderindex)
          return 1;
        if (a.properties.orderindex < b.properties.orderindex)
          return -1;
        else
          return 0;
      });
      console.log("Strategic Outcomes", resp);
    });
  }

  isAgencyStatsAdmin(currentUser) {
    console.log("Current User", currentUser);
    currentUser.roles.every(role => {
      if (role.name == "Agency Stats Admin") {
        this.agecnyStatsAdmin = true;
        return false;
      }
      return true;
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  showAddOutcome() {
    this.showAddOutcomeFlag = true;
    this.outcome = StrategicOutcome.empty();
  }


  addOutcome() {
    this.addingOutcome = true;
    this.strategicOutcomeService.create(this.outcome).toPromise().then((entId) => {
      this.addingOutcome = false;
      this.showAddOutcomeFlag = false;
    });
  }



}
