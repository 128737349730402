// yoinked (mostly) from https://stackoverflow.com/a/42846277

const { defineProperty, getPrototypeOf } = Object;
const useValue = Symbol();

/**
 * Describes the target provided to class `accessor` field decorators.
 * @template This The `this` type to which the target applies.
 * @template Value The property type for the class `accessor` field.
 */
interface ClassAccessorDecoratorTarget<Value> {
    get(this): Value;
    set?(this, value: Value): void;
}

export interface LazyOptions<Value> {
  /** the result will only be saved if valid returns true for it */
  valid?: (result: Value) => boolean;
  /** default will be returned, but not saved, if valid returns false for any given result */
  default?: Value | typeof useValue;
};

export function Lazy<Value, T>({ valid, default: _default}: LazyOptions<Value> = { }) {
  return function (target: T | ClassAccessorDecoratorTarget<Value>, propertyKey, { get: initializer, enumerable, configurable, set: setter }: PropertyDescriptor & { get?: () => Value } = { }): any {
    const { constructor } = target;

    // if this is not a getter, throw
    if (initializer === undefined) {
      throw `@lazy can't be set as a property \`${propertyKey as any}\` on ${constructor.name} class, use a getter instead!`;
    }

    // if there exists a setter, throw
    if (setter) {
      throw `@lazy can't be annotated with get ${propertyKey as any}() existing a setter on ${constructor.name} class!`;
    }

    // this will replace the getter with a the property it returns
    function set(that, value: Value) {
      // if only one arg is provided, then this is being called as a setter directly,
      if (value === undefined) {
        // so `this` is `that` and value is arg 1
        value = that;
        that = this;
      }

      // if the value does not pass the vald check, don't finalize the value
      if (valid !== undefined && !valid(value))
        return _default === useValue ? value : _default;

      // replace the property getter with a static value, for the next get
      defineProperty(that, propertyKey, {
        enumerable: enumerable,
        configurable: configurable,
        value: value
      });

      // for this call, return the value
      return value;
    }

    return {
      get() {
        // being referenced, not called
        if (this === target) {
          return initializer;
        }

        // being referenced on a subclass
        // note: subclass.prototype.foo when foo exists in superclass nor subclass, this will be called
        if (this.constructor !== constructor && getPrototypeOf(this).constructor === constructor) {
          return initializer;
        }

        return set(this, initializer.call(this));
      },
      set
    };
  }
}

// class TestClass {
//   constructor() {
//   }

//   @Lazy({valid: (result) => result})
//   get isBoolean() {
//     return true;
//   }
// }
