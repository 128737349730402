<div class="file-upload-wrapper">
  <div
    ngfSelect
    [multiple]="multi"
    type="file"
    [(files)]="newFiles"
    [accept]="accept"
    (filesChange)="uploadFile()"
    *ngIf="!viewOnly"
    [fileDropDisabled]="disabled"
    class="form-file-button"
    [ngClass]="disabled ? 'form-file-button--disabled' : ''"
  >
    <i class="pi pi-cloud-upload" *ngIf="!uploading"></i>
    <i class="pi pi-spin pi-spinner" *ngIf="uploading"></i>
    {{ uploading ? 'Uploading...' : 'Upload' }}
  </div>

  <div *ngIf="!ifFileSelected">
    <p>There are currently no files</p>
  </div>
</div>

<p-dialog header="Uploading File(s)"
    *ngIf="uploading"
    [(visible)]="uploading"
    [baseZIndex]="10000"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [closeOnEscape]="false"
    [closable]="false"
    appendTo="body"
    [style]="{ 'width': '640px', 'max-width': '95vw' }"
    styleClass="file-upload-dialog">

    <ul class="file-uploads">
      <li *ngFor="let file of newFiles; let i = index">
        <div class="file-upload file-upload--{{ fileUploadStatuses && fileUploadStatuses[i + '-' + file.name] }} ">
          <i class="pi pi-file icon"></i>
          <div class="file">
            <div class="filename">{{ file.name }}</div>
            <div class="filesize">{{ formatBytes(file.size) }}</div>
          </div>
          <div class="status">
            <span class="uploading">Uploading <i class="pi pi-spin pi-spinner"></i></span>
            <span class="success">Success <i class="pi pi-check-circle"></i></span>
          </div>
        </div>
      </li>
    </ul>

    <p-footer>
      <button
        pButton
        type="button"
        label="{{ uploadCancelled ? 'Cancelling Upload' : 'Cancel Upload' }}"
        [disabled]="uploadCancelled"
        icon="pi pi-times"
        (click)="cancelUploads()"
        class="f-uploading__cancel"></button>
  </p-footer>
</p-dialog>


<ng-container *ngIf="ifFileSelected">
  <span *ngIf="!multi" style="text-align: left">
    <p-progressSpinner *ngIf="!formControl.value[0].url"> </p-progressSpinner>
    <div *ngIf="canDisplay(formControl.value[0])">
      <img
        class="clickable"
        *ngIf="formControl.value[0].url"
        [src]="formControl.value[0].url"
        style="margin-bottom: 10px;max-width: 200px;max-height: 200px;"
      />
    </div>
    <div
      *ngIf="!canDisplay(formControl.value[0])"
      (click)="open(formControl.value[0])"
    >
      <img
        class="clickable"
        *ngIf="formControl.value[0].thumbnail"
        [src]="formControl.value[0].thumbnail"
        style="margin-bottom: 10px; max-width: 100px"
      />
      <h3>{{ formControl.value[0].name }}</h3>
    </div>
  </span>
  <span *ngIf="multi">
    <p-accordion
      class="files-accordion"
      [(activeIndex)]="activeIndex"
      (onOpen)="onTabOpen($event)"
    >
      <p-accordionTab *ngFor="let file of formControl.value">
        <p-header>
          <div class="header">
            <span
              class="header__heading files-accordion__heading"
              [title]="file.name"
            >
              <img style="max-height: 2rem" [src]="file.thumbnail" />
              <span style="margin-left: 1rem;">{{
                file.name
              }}</span>
            </span>
            <div class="header__actions">
              <button
                pButton
                type="button"
                label="Delete"
                icon="pi pi-{{ deletingFile == file.id ? 'spin pi-spinner' : 'times' }}"
                (click)="addToBeDeleted(file)"
                *ngIf="!viewOnly"
              ></button>
            </div>
          </div>
        </p-header>
        <span style="text-align: center">
          <p-progressSpinner *ngIf="!file.url"> </p-progressSpinner>
          <img
            class="clickable"
            *ngIf="file.url"
            [src]="file.url"
            (click)="open(file)"
            style="max-width: 100%"
          />
        </span>
      </p-accordionTab>
    </p-accordion>
  </span>
</ng-container>

<!-- <p-dialog header="File(s) uploading" [(visible)]="uploading" [modal]="true" [style]="{width: '50vw'}" [baseZIndex]="10000"
    [draggable]="false" [resizable]="false">
    <p>Files uploading</p>
        <p-footer>
            <button type="button" pButton icon="pi pi-times" (click)="" label="Cancel File Upload" class="ui-button-secondary"></button>
        </p-footer>
</p-dialog> -->
