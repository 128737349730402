<div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">Individual Statistic Reports</h1>
      <div class="header__actions">
        <div class="ui-fluid p-sm-12 p-md-12">
          <button pButton label="Add Report" icon="pi pi-plus" (click)="openModal('add')"> </button>
        </div>
      </div>
    </div>
  </div>
  <div class="pane__body">
    <p-table #dt [value]="reports" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
      [showCurrentPageReport]="true" [loading]="reportsLoading" [paginator]="false"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
          </th>

          <th class="actions-col"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-report>
        <tr class="ui-selectable-row">
          <td>{{ report.properties.name }}</td>
          <td class="actions-col">
            <p-splitButton [model]="report.actionMenu" label="{{ report.deleting ? 'Deleting' : 'View' }}"
              icon="pi pi-{{report.deleting ? 'spin pi-spinner' : 'window-maximize'}}" [disabled]="report.deleting"
              (onClick)="view(report.id)" appendTo="body">
            </p-splitButton>

          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2" class="empty-message-col">No Reports found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-container  style="min-width: 750px;" *ngIf="report != null">
  <p-dialog header="Add Report" [(visible)]="showAddReport" [appendTo]="'body'" class="modal" [modal]="true"
    [draggable]="false" [baseZIndex]="10000" [dismissableMask]="true">
    <div class="modal-content" style="width: 1000px; height:750px;">
      <ripple-entity-details [entity]="report" [hideButtons]="true" [generatorOptions]="generatorOptions">
      </ripple-entity-details>
        <div class="ui-fluid p-sm-12 p-md-12">
          <label class="filter-label">Individual Statistics</label>
        <p-multiSelect label="Individual Statistics" [(ngModel)]="linkedstatIds" [filter]="true" optionValue="name"
          [options]="linkedStatOptions"> </p-multiSelect>
        </div>

        <div class="ui-fluid p-sm-12 p-md-12">
          <label class="filter-label">Combined Statistics</label>
        <p-multiSelect label="Combined Statistics" [(ngModel)]="linkedStrategicStatIds" [filter]="true" optionValue="name"
          [options]="linkedStrategicStatOptions"> </p-multiSelect>
        </div>


      <button pButton type="button" icon="pi pi-{{loading ? 'spin pi-spinner' : 'plus'}}" [label]="text"
        (click)="updateReport('Add Report')"></button>
      <!-- <ul>
        <li *ngFor="let addedStat of report.multiValuedProperties.linkedstatistic"> {{addedStat.name}}</li>
      </ul> -->
    </div>
  </p-dialog>
</ng-container>
