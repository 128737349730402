import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ccasa-report-sections',
  templateUrl: './report-sections.component.html',
  styleUrls: ['./report-sections.component.scss']
})
export class ReportSectionsComponent implements OnInit {

    @Input() title: string;
    @Input() questions: any;
    @Input() required: boolean;
    @Output() filterTicket: EventEmitter<any> = new EventEmitter();
    showSection: Boolean = true;

  constructor() { }

  ngOnInit() {
      console.log('questions: ' + this.title, this.questions);
      if (this.questions.length === 0)
          this.showSection = false;
  }

  filterLocal(item) {
      console.log('section filter', item);
      this.filterTicket.emit(item);

  }

}
