import { Input, Component, OnChanges, OnDestroy, HostListener, SimpleChanges, isDevMode, ViewChildren, QueryList, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService, AuthService, MessageService } from '@ripple/services';
import { WarpEntity, EntityNote, EntityFilter, EntityTag } from '@ripple/models';
import { combineLatest, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { EntityNoteComponent } from './entity-note/entity-note.component';
import { InputText } from 'primeng/inputtext';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'ripple-entity-notes',
  templateUrl: './entity-notes.component.html',
  styleUrls: ['./entity-notes.component.scss']
})
export class EntityNotesComponent implements OnChanges, OnDestroy {
  noteService: WarpEntityServiceCache<EntityNote>;
  tagService: WarpEntityServiceCache<EntityTag>;
  currentSubscription: Subscription;
  currentTagSubscription: Subscription;

  entityNotes: EntityNote[] = [];
  entityNotesFiltered: EntityNote[] = [];
  entityTags: EntityTag[] = [];
  entityTagsSelected: EntityTag[] = [];
  loading = true;
  adding = false;

  @Input() header: boolean | string = 'Entity Notes';
  @Input() showAdd = true;
  @Input() maxHeight = 'unset';
  @Input() entity: WarpEntity;
  @Input() noteType: string;
  @Input() noteTypeDisplayName: string;
  @Input() noteTypeFlagCustomClass: string;
  @Input() emptyMessage = 'No Notes Yet.';
  @Input() emptyFilteredMessage = 'No Notes Matched.';
  @Input() showNoteTypes: string[];
  @Input() view: 'small' | 'big' = 'small';
  @Input() showFullNotes = true;
  @Input() maxLength = 1000;
  @Input() allowPin = false;
  @Input() allowTag = false;
  @Input() scrollTo: number = -1;

  @Output() noteClick = new EventEmitter<EntityNote>();

  @ViewChildren(EntityNoteComponent) notesViews: QueryList<EntityNoteComponent>;
  @ViewChild('noteText') noteText: ElementRef;
  @ViewChild('noteTextArea') noteTextArea: ElementRef;

  constructor(
    protected serviceFactory: WarpEntityCacheFactoryService,
    private auth: AuthService,
    private msg: MessageService
  ) {
    this.noteService = serviceFactory.get(36);
  }

  ngOnDestroy(): void {
    this._tryUnsubscribe();
    if (this.currentTagSubscription) {
      this.currentTagSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      const oldE: WarpEntity = changes.entity.previousValue;
      const newE: WarpEntity = changes.entity.currentValue;
      if (newE && ( !oldE || oldE.entityId !== newE.entityId) && newE.entityId)
        this.init();
    }
    if (isDevMode() && changes.noteType && this.noteType)
      this.noteService
        .getCfimOptions('notetype')
        .pipe(first())
        .toPromise()
        .then(cfcs => {
          if (cfcs.findIndex(cfc => cfc.optionName === this.noteType) === -1)
            this.msg.warn(this.constructor.name, 'This note type does not exist for this subscriber, check your spelling...');
        });
      
    if (changes.noteType && !this.noteTypeDisplayName) {
      this.noteTypeDisplayName = changes.noteType.currentValue;
    }

    if (changes.allowTag) {
      if (this.allowTag) {
        const filter = EntityFilter
          .Advanced({ entityTypeId: EntityNote.entityTypeId })
          .orderBy('name', 'asc');
        this.tagService = this.serviceFactory.get(EntityTag.entityTypeId);
        if (this.currentTagSubscription) this.currentTagSubscription.unsubscribe();
        this.currentTagSubscription = this.tagService.initQuery(filter, 9999999).getPage(0).subscribe(result => {
          this.entityTags = result;
          console.log('result', this.entityTags);
          // update reference of entitytagsselected
          const selectedIds = this.entityTagsSelected.map(t => t.entityId);
          this.entityTagsSelected = this.entityTags.filter(t => selectedIds.includes(t.entityId));
        });
      }
    }

    if (changes.scrollTo && this.scrollTo > 0) {
      const noteElement = document.getElementById('note-' + this.scrollTo);
      noteElement.scrollIntoView();
    }
  }

  init() {
    if (this.noteText)
      this.noteText.nativeElement.value = '';
    if (this.noteTextArea) {
      this.noteTextArea.nativeElement.value = '';
    }
    this.msg.add(this.constructor.name,
      `Loading [${(this.showNoteTypes || [this.noteType]).join(', ')}] for entity ${this.entity.entityId}`
    );
    this.loading = true;
    // unsubscribe from the last entity's notes
    this._tryUnsubscribe();

    // subscribe to this entity's notes
    this.currentSubscription = this.noteService
      .initQuery(
        EntityFilter.Advanced({
          parentEntity_lid: this.entity.entityId,
          notetype: this.showNoteTypes || this.noteType
        })
        .orderBy('created', 'desc')
      )
      .getPage(0)
      .subscribe( m => {
        this.entityNotes = m || [];
        this.entityNotesFiltered = this.entityNotes;
        this.filterNotes();
        this.loading = false;
      });
  }

  sortNotes(notes) {
    if (!this.allowPin) {
      return notes;
    } else {
      return notes.sort((n1, n2) => {
        const pinned1 = n1.pinned ? n1.pinned.id : 334;
        const pinned2 = n2.pinned ? n2.pinned.id : 334;
        const created1 = new Date(n1.created).getMilliseconds();
        const created2 = new Date(n2.created).getMilliseconds();
        if (pinned1 === pinned2) {
          return created2 - created1;
        } else {
          return pinned1 - pinned2;
        }
      });
    }
  }

  addNote(text: string) {
    if (text.trim() === '') return; // don't save an empty message
    this.adding = true;
    combineLatest([
      this.auth.getLoggedInUser(),
      this.noteService.getCfimOptions('notetype')
    ])
      .pipe(first())
      .toPromise()
      .then( ([user, cfcs]) => {
        const newNote = EntityNote.empty()
          .linkedProperty('user', user)
          .linkedProperty('parententity', this.entity)
          .property('date', moment().format('YYYY-MM-DD'))
          .cfcProperty('notetype', cfcs.find( cfc => cfc.optionName === this.noteType))
          .property('description', text);
        this.noteService
          .create(newNote)
          .toPromise()
          .then( _ => {
            if (this.noteText)
              this.noteText.nativeElement.value = '';
            if (this.noteTextArea)
              this.noteTextArea.nativeElement.value = '';
            this.adding = false;
          });
      });
  }
  updateNote(note: EntityNote) {
    this.noteService.update(note);
  }

  deleteNote(note: EntityNote) {
    this.noteService.delete(note);
  }

  closeAllExcept(note: EntityNote) {
    this.notesViews.forEach(noteView => {
      if (noteView.note.entityId !== note.entityId) noteView.closeContextMenu();
    });
  }

  private _tryUnsubscribe() {
    if (this.currentSubscription && !this.currentSubscription.closed)
      this.currentSubscription.unsubscribe();
  }

  filterNotes() {
    if (this.entityTagsSelected.length === 0) {
      this.entityNotesFiltered = this.entityNotes;
    } else {
      this.entityNotesFiltered = this.entityNotes.filter(n => this.ifNoteFiltered(n));
    }
    this.entityNotesFiltered = this.sortNotes(this.entityNotesFiltered);
  }

  ifNoteFiltered(note: EntityNote) {
    const tagIds = note.properties.entitytag ? note.properties.entitytag.map(t => t.id) : [];
    for (const tag of this.entityTagsSelected) {
      if (tagIds.includes(tag.entityId)) {
        return true;
      }
    }
    return false;
  }
}
