import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';
import { BehaviorSubject } from 'rxjs';

export class FilledForm extends WarpEntity {
  formData: BehaviorSubject<WarpEntity>;
  wasOptional = false;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.FilledForm;
    this.formData = new BehaviorSubject(null);
  }

  static empty(): FilledForm {
    return new FilledForm(super.emptyEntity(EntityTypes.FilledForm));
  }

  get isCompleted() {
    return this.formstatus && this.formstatus.id === 1277;
  }

  get isStarted() {
    return this.isCompleted || (this.formstatus && this.formstatus.id === 1276);
  }
}
