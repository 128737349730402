import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@ripple/environment';
import { CustomFieldInModule, WarpEntityType } from '@ripple/models';
import { ImporterService, TemplateBuilderService, GenericWarpEntityService } from '@ripple/services';
import { FormBuilderImportFieldData } from '@ripple/formbuilder';
import { Router } from '@angular/router';

@Component({
  selector: 'ripple-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.css']
})
export class ImporterComponent implements OnInit {

  @Input() formBuilderLink = '/administration/formbuilder/add';
  @Input() returnUrl = '/administration/importer-report';

  // stage
  // 1: upload file
  // 2: choose entity types
  stage: 1 | 2 = 1;

  // macro
  FORM_RANGE_START = 100000;
  FORM_TYPE_CFIM_ID = 5692;

  entityTypes: { value: number, label: string }[] = [];
  fullEntityTypes = { };
  targetEntityTypeIds: number[] = [];

  sourceResult: any;
  sourceColumns: { label: string, value: string }[] = [];
  sourceOptions: { label: string, value: string }[] = [];
  sourceColumnChoices = {};
  fileName = '';

  importerResults = [];
  importConfirmShow = false;

  // form builder
  formBuilderShow = false;
  sourceColumnsSelected: string[] = [];
  questionTypes = ['Text', 'Text Area', 'Radio', 'Checkbox'].map(q => ({ label: q, value: q }));
  sourceColumnQuestionTypes = { };

  // importer status
  importing = false;

  constructor(
    public importerService: ImporterService,
    public templateBuilderService: TemplateBuilderService,
    public genericWarpEntityservice: GenericWarpEntityService,
    public router: Router
  ) { }

  ngOnInit(): void {
    this.templateBuilderService.getAllEntityTypes().then(result => {
      console.log(result);
      this.entityTypes = result.map(type => ({ label: type.name, value: type.id }));
      console.log("entity Types", this.entityTypes)
    });
  }

  uploadFile(event) {
    const file = event.files[0];
    this.fileName = file.name;
    this.importerService.readXLSX(file).then(result => {
      console.log(result);
      this.sourceResult = result;
      console.log("the source results", this.sourceResult);
      this.sourceColumns = this.sourceResult.headers.map(h => ({ label: h, value: h }));
    });
  }

  async updateTargetEntityTypes(event) {
    const typeId = event.itemValue;
    const index = this.importerResults.findIndex(result => result.entityType && result.entityType.id === typeId);
    if (index >= 0) {
      // remove
      this.importerResults.splice(index, 1);
      console.log('remove type', typeId, this.importerResults);
    } else {
      // add
      if (typeId >= this.FORM_RANGE_START) {
        // form
        // also add filledForm if not included
        if (!this.targetEntityTypeIds.includes(636)) {
          this.targetEntityTypeIds.push(636);
          const filledFormEntityType = await this.getEntityType(636);
          const filledFormImporterResult = await this.addImporterResult(filledFormEntityType);
          filledFormImporterResult.cfimsMapping[this.FORM_TYPE_CFIM_ID].staticValue = typeId;
        }
      }
      const entityType = await this.getEntityType(typeId);
      await this.addImporterResult(entityType);
    }
    this.updateSourceOptions();
  }

  updateSourceOptions() {
    const entityTypesSelected = this.targetEntityTypeIds.map(id => {
      const entityType = this.entityTypes.find(et => et.value === id);
      if (entityType) return { label: '*** Imported ' + entityType.label, value: '***Imported---' + entityType.value };
      else return { label: '', value: '' };
    });
    this.sourceOptions = [...this.sourceColumns, ...entityTypesSelected];
    console.log('these are the source options', this.sourceOptions)
  }

  async addImporterResult(entityType: WarpEntityType) {
    // try to get last mapping first
    const lastMappingEntity = await this.importerService.getLastMapping(entityType.id);

    let importerResult = {
      entityType,
      identifyingInfo: [],
      cfimsMapping: {

      },
      formChoices : []
    };

    let isForm = false;
    if (entityType.frontendTemplate?.FrontendTemplate?.data != undefined) {
      isForm = true;
      importerResult.formChoices = entityType.frontendTemplate.FrontendTemplate.data[0].subData;
    }

    for (const cfim of entityType.customFieldsInModules) {
      importerResult.cfimsMapping[cfim.id] = {
        sourceColumn: ''
      };

      let matchingOption = this.sourceOptions.filter(o => o.label === cfim.label);
      if (matchingOption.length > 0) {
        this.selectSourceColumn(importerResult, cfim, matchingOption[0]);
        importerResult.cfimsMapping[cfim.id].sourceColumn = matchingOption[0].value;
        console.log("Updated column", importerResult.cfimsMapping[cfim.id].sourceColumn)
      }

      if (cfim.cf_dataField === 'customfieldchoiceid') {
        importerResult.cfimsMapping[cfim.id].ifMultiselect = false;
        importerResult.cfimsMapping[cfim.id].cfChoicesMapping = { };

        // Tries to do the mapping for cfims using the labels from the source

        if (isForm && matchingOption.length > 0) {
          // Tries to fill out the cfc mapping using the choice labels and the form option labels
          try {
            const cfChoicesMapping = importerResult.cfimsMapping[cfim.id].cfChoicesMapping;
            const formChoices = this.getFormChoicesForUnchangeableName(importerResult.formChoices, cfim.unchangeableName)
            for (const col of this.sourceColumnChoices[matchingOption[0].value]) {
              let matchingChocie = formChoices.filter(c => c.optionName === col);
              if (matchingChocie.length > 0) {
                cfChoicesMapping[col] = matchingChocie[0];
              }
            }
          } catch (error) {
            console.error("Error finding match", error)
          }
        }
      } else if (cfim.cf_dataField === 'linkedentityid') {
        importerResult.cfimsMapping[cfim.id].linkedEntityInfo = { };
        if (cfim.listEntityTypeID) {
          importerResult.cfimsMapping[cfim.id].linkedEntityInfo.entityTypeId = cfim.listEntityTypeID;
          await this.linkedEntityTypeSelect({ value: cfim.listEntityTypeID });
        }
      }
    }

    if (lastMappingEntity) {
      const lastMapping = JSON.parse(lastMappingEntity.json);
      console.log('getLatestMapping', lastMappingEntity, lastMapping);
      importerResult.identifyingInfo = lastMapping.identifyingInfo.map(i => importerResult.entityType.customFieldsInModules.find(c => c.id === i.id)).filter(i => i);

      for (const cfim of importerResult.entityType.customFieldsInModules) {
        if (lastMapping.cfimsMapping[cfim.id]) {
          importerResult.cfimsMapping[cfim.id] = lastMapping.cfimsMapping[cfim.id];
        }
        const sourceColumn = importerResult.cfimsMapping[cfim.id].sourceColumn;
        if (cfim.cf_dataField === 'customfieldchoiceid') {


          const ifMultiselect = importerResult.cfimsMapping[cfim.id].ifMultiselect;
          this.generateSourceColumnOptions(sourceColumn, ifMultiselect);


        } else if (cfim.cf_dataField === 'linkedentityid') {
          if (importerResult.cfimsMapping[cfim.id].linkedEntityInfo.cfim) {
            importerResult.cfimsMapping[cfim.id].linkedEntityInfo.cfim =
              this.fullEntityTypes[cfim.listEntityTypeID].customFieldsInModules.find(c => c.id === importerResult.cfimsMapping[cfim.id].linkedEntityInfo.cfim.id);
          }
        }
      }
    }

    this.importerResults.push(importerResult);
    console.log('add importResult', entityType, this.importerResults);
    return importerResult;
  }

  async getEntityType(entityTypeId): Promise<WarpEntityType> {
    if (!this.fullEntityTypes[entityTypeId]) {
      this.fullEntityTypes[entityTypeId] = await this.genericWarpEntityservice.getEntityStructure(entityTypeId).toPromise();
    }
    console.log('add entity type', this.fullEntityTypes[entityTypeId]);
    return this.fullEntityTypes[entityTypeId];
  }

  async linkedEntityTypeSelect(event) {
    const typeId = event.value;
    await this.getEntityType(typeId);
  }

  import() {
    for (const result of this.importerResults) {
      result.entityTypeId = result.entityType.id;
    }
    const body = {
      importerResults: this.importerResults,
      data: this.sourceResult.contents
    }
    console.log('start import', body);
    this.importing = true;
    this.importerService.import(body).then(result => {
      console.log(result);
      this.importing = true;
      this.router.navigate([this.returnUrl]);
    });
  }

  selectSourceColumn(importerResult: any, cfim: CustomFieldInModule, event) {
    console.log("select source column fired", event);
    const sourceColumnSelected = event.value;
    if (!sourceColumnSelected) {
      importerResult.cfimsMapping[cfim.id].sourceColumn = '';
    } else {
      if (cfim.cf_dataField === 'customfieldchoiceid') {
        this.generateSourceColumnOptions(sourceColumnSelected, false);
      }
    }
    console.log("Selected Source Column", sourceColumnSelected);
  }

  generateSourceColumnOptions(sourceColumn: string, ifMultiselect = false) {
    if (sourceColumn && !this.sourceColumnChoices[sourceColumn]) {
      this.sourceColumnChoices[sourceColumn] = new Set();
      for (const data of this.sourceResult.contents) {
        if (data[sourceColumn]) {
          const value = data[sourceColumn];
          if (ifMultiselect) {
            const valuesArr = data[sourceColumn].split(',');
            for (const v of valuesArr) {
              this.sourceColumnChoices[sourceColumn].add(v.trim());
            }
          } else {
            this.sourceColumnChoices[sourceColumn].add(value);
          }
        }
      }
    }
    console.log('Source Column Choices', this.sourceColumnChoices)
  }

  ifMultiselectChange(sourceColumn: string, event) {
    console.log('ifMultiselectChange', event);
    this.sourceColumnChoices[sourceColumn] = null;
    this.generateSourceColumnOptions(sourceColumn, event.checked);
  }

  generateFormBuilderTemplate() {
    const formImportDatas: FormBuilderImportFieldData[] = [];
    for (const column of this.sourceColumnsSelected) {
      let options = [];
      if (!this.sourceColumnQuestionTypes[column] || ['Radio', 'Checkbox'].includes(this.sourceColumnQuestionTypes[column])) {
        this.sourceColumnChoices[column] = null;
        this.generateSourceColumnOptions(column, this.sourceColumnQuestionTypes[column] && this.sourceColumnQuestionTypes[column] === 'Checkbox');
        options = Array.from(this.sourceColumnChoices[column].values());
      }
      formImportDatas.push({
        label: column,
        questionType: this.sourceColumnQuestionTypes[column] || 'Radio',
        options
      });
    }
    // add importer ID
    formImportDatas.push({
      label: 'Importer ID',
      questionType: 'Text',
      options: []
    });
    console.log(formImportDatas);
    const title = this.fileName.split('.')[0];
    this.router.navigateByUrl(this.formBuilderLink, { state: { title, datas: formImportDatas } });
  }

  getFormChoicesForUnchangeableName(formChoices:any[], unchangeablename: string) {
    let choices = formChoices.filter(d => d.key.toLowerCase() === unchangeablename.toLowerCase()); //[0].options;
    if(choices.length > 0)
      return choices[0].options;
    else
      return null;
  }

}
