<div class="p-grid ui-fluid clares-law-result-form" *ngIf="!ifError">
  <div *ngIf="!ifValid" style="padding: 1%; text-align: center; display: inline">
    <p-messages [(value)]="validMsgs" [closable]="false"></p-messages>
  </div>
  <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="!viewOnly">
    <h2>Attempt {{attempt}}</h2>
  </div>
    <div class="p-sm-12 p-md-12 ui-fluid">
          Have you attempted to make contact with this Clare’s Law applicant?
        <div class="p-grid">
            <div class="p-sm-12 p-md-6">
                <p-radioButton name="haveMade" value="{{enviro['haveMade']['yes']}}" label="Yes"
                    [(ngModel)]="haveMade" [disabled]="viewOnly">
                </p-radioButton>
            </div>
            <div class="p-sm-12 p-md-6">
                <p-radioButton name="haveMade" value="{{enviro['haveMade']['no']}}" label="No"
                    [(ngModel)]="haveMade" [disabled]="viewOnly">
                </p-radioButton>
            </div>
        </div>
    </div>
    <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="haveMade === enviro['haveMade']['yes']">
        If yes, please answer below:
        <div class="p-grid">
            <div class="p-sm-12">
              <p-radioButton name="ifYes" value="{{enviro['ifYes']['noResponse']}}" [(ngModel)]="ifYes" [disabled]="viewOnly"
                label="Attempted to make contact, but no response was received from this applicant"></p-radioButton>
              <textarea pInputTextarea maxLength="5000" name="additionalInfo" [(ngModel)]="formData.properties[noResponseInfo]"
                placeholder="Additional Information" class="w-100 p-mt-1" [disabled]="viewOnly"
                *ngIf="ifYes === enviro['ifYes']['noResponse']">
              </textarea>
            </div>
            <div class="p-sm-12">
              <p-radioButton name="ifYes" value="{{enviro['ifYes']['success']}}" [(ngModel)]="ifYes" [disabled]="viewOnly"
                label="Successfully contacted applicant and supports/services are being provided to the applicant and/or family"></p-radioButton>
            </div>
            <div class="p-sm-12">
              <p-radioButton name="ifYes" value="{{enviro['ifYes']['noInterest']}}" [(ngModel)]="ifYes" [disabled]="viewOnly"
                label="Contacted the applicant, but they were not interested in our agency services and would like a re-referral" styleClass="p-d-flex"></p-radioButton>
              <textarea pInputTextarea maxLength="5000" name="additionalInfo" [(ngModel)]="formData.properties[noInterestOurInfo]"
                placeholder="Additional Information" class="w-100 p-mt-1" [disabled]="viewOnly"
                *ngIf="ifYes === enviro['ifYes']['noInterest']">
              </textarea>
            </div>
            <div class="p-sm-12">
              <p-radioButton name="ifYes" value="{{enviro['ifYes']['noInterestAny']}}" [(ngModel)]="ifYes" [disabled]="viewOnly"
                label="Contacted the applicant, but they are not interested in ANY services"></p-radioButton>
              <textarea pInputTextarea maxLength="5000" name="additionalInfo" [(ngModel)]="formData.properties[noInterestAnyInfo]"
                placeholder="Additional Information" class="w-100 p-mt-1" [disabled]="viewOnly"
                *ngIf="ifYes === enviro['ifYes']['noInterestAny']">
              </textarea>
            </div>
        
            <div class="p-sm-12">
              <p-radioButton name="ifYes" value="{{enviro['ifYes']['other']}}" [(ngModel)]="ifYes" [disabled]="viewOnly"
                label="Other - Succesfully contacted the applicant"></p-radioButton>
              <textarea pInputTextarea maxLength="5000" name="additionalInfo" [(ngModel)]="formData.properties[otherInfo]"
                placeholder="Additional Information" class="w-100 p-mt-1" [disabled]="viewOnly"
                *ngIf="ifYes === enviro['ifYes']['other']">
              </textarea>
            </div>
        </div>
    </div>
    <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="haveMade === enviro['haveMade']['no']">
        <p>
          Since contact has not been attempted, this Clare’s Law referral is being redirected to another agency.
          No further action is required by you at this time.
          Sagesse will be in contact to discuss the referral process and any barriers that may be impeding support for Clare’s Law applicants.
        </p>
        <textarea pInputTextarea maxLength="5000" name="additionalInfo" [(ngModel)]="formData.properties[noContactInfo]"
          placeholder="Additional Information" class="w-100 p-mt-1" [disabled]="viewOnly">
        </textarea>
    </div>
</div>

<div
    class="button-nav button-nav--well button-nav--justify-end"
    *ngIf="!viewOnly && !ifError"
  >
    <ng-container>
      <!-- <button
        type="button"
        [disabled]="disabled"
        (click)="save('save')"
        pButton
        label="Save For Later"
        icon="pi pi-save"
      > 
        <i *ngIf="isSaving" class="pi pi-spin pi-spinner"></i>
      </button>
    -->
      <button
        type="button"
        [disabled]="disabled"
        (click)="save('submit')"
        pButton
        label="Save & Submit"
        icon="pi pi-check"
      >
        <i *ngIf="isSubmitting" class="pi pi-spin pi-spinner"></i>
      </button>
    </ng-container>
  </div>

<div *ngIf="ifError" style="padding: 1%; text-align: center; display: inline">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
</div>