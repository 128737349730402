import { WarpEntity } from '../warp-entity';

// @dynamic
export class EntityTag extends WarpEntity {
  static entityTypeId = 719;

  entityTypeId = 719;

  static empty(): EntityTag {
    return new EntityTag(super.emptyEntity(719));
  }
}
