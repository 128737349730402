import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class OrganizationTagCategory extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.OrganizationTagCategory;
    }

    static empty(): OrganizationTagCategory {
        return new OrganizationTagCategory(super.emptyEntity(EntityTypes.OrganizationTagCategory));
    }

}