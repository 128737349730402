<p-accordion>
  <p-accordionTab *ngFor="let ent of entitiesArray" [header]="display(ent)">
    <ul class="props">
      <li *ngFor="let f of props(ent)">
        {{f[0]}}
        <code *ngIf="singleLine(f[1])">{{myJson(f[1])}}</code>
        <pre *ngIf="!singleLine(f[1])">{{myJson(f[1])}}</pre>
      </li>
    </ul>
  </p-accordionTab>
</p-accordion>
