<div class="pane">
    <div class="pane__header pane__header--sticky bg-white">
      <div class="header">
        <h1 class="header__heading">
          Contact Roles
        </h1>
        <div class="header__actions">
        </div>
      </div>
    </div>
    <div class="pane__body">
      <h2>
        Primary
      </h2>
      <ripple-entity-list [entityService]="contactRoleService"
        [showFilter]="false" [filter]="primaryFilter" [showDeleteButton]="false">
      </ripple-entity-list>
      <h2 class="mt-2">
        Non-primary
      </h2>
      <ripple-entity-list [entityService]="contactRoleService"
        [filter]="nonPrimaryFilter" [showFilter]="false"
        [editButton]="edit" [addButton]="add"></ripple-entity-list>
    </div>
</div>