import { Component, OnInit, Input, HostBinding,ViewChild, EventEmitter, Output, AfterViewInit } from '@angular/core';

@Component({
  selector: 'ripple-scroll-card',
  templateUrl: './scroll-card.component.html',
  styleUrls: ['./scroll-card.component.scss']
})
export class ScrollCardComponent implements AfterViewInit {

  @HostBinding('class.ripple-scroll-card') containerClass: boolean = true;
  @HostBinding('class.ripple-scroll-card--unset-overflow') @Input() unsetOverflow: boolean = false;
  @HostBinding('class.ripple-scroll-card--no-pad') @Input() noPadding: boolean = false;
  @ViewChild('body') body: any;

  /** buttfer to count as at the top, or at the bottom, in px */
  @Input()
  scrollBuffer = 5; // px

  private _top: boolean;
  private _bottom: boolean;

  @HostBinding('class.scroll-top') get top() {
    return this._top || false;
  }
  @HostBinding('class.scroll-bottom') get bottom() {
    return this._bottom || false;
  }

  @Output() topChange: EventEmitter<boolean> = new EventEmitter();
  @Output() bottomChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngAfterViewInit(): void {
    this.onScroll(this.body?.nativeElement);
  }

  resetScroll(){
    if(this.body) {
      this.body.nativeElement.scrollTop = 0;
    }

    this.onScroll(this.body?.nativeElement);
  }

  onScroll(target: HTMLElement) {
    if (!target)
      return;

    this.setTop(target);
    this.setBottom(target);
  }

  private setTop(target: HTMLElement) {
    const atTop = target.scrollTop < this.scrollBuffer;

    if (this._top !== atTop)
      this.topChange.emit(this._top = atTop);
  }

  private setBottom(target: HTMLElement) {
    const scrollBottom = target.scrollHeight - (target.scrollTop + target.clientHeight);
    const atBottom = scrollBottom < this.scrollBuffer;

    if (this._bottom !== atBottom)
      this.bottomChange.emit(this._bottom = atBottom);
  }
}
