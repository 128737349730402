import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationContact } from '../../../_core/models';

@Component({
  selector: 'sagesse-contact-organization-card',
  templateUrl: './contact-organization-card.component.html',
  styleUrls: ['./contact-organization-card.component.scss']
})
export class ContactOrganizationCardComponent implements OnInit {

  @Input() organizationContact: OrganizationContact;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  goToOrganization(organizationContact) {
    this.router.navigate([`/organization/${organizationContact.organization.id}`]);
  }

}
