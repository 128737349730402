import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePage } from '@ripple/core';
import { EntityFilter, FilterInput, FilterOperator } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { EntityScrollViewComponent } from '@ripple/ui';
import { VirtualScroller } from 'primeng/virtualscroller';
import { forkJoin, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { CommunitiesComponent } from '../partials/communities/communities.component';
import { TagsComponent } from '../partials/tags/tags.component';
import { TeamsComponent } from '../partials/teams/teams.component';
import { Organization, EntityTypes, OrganizationTag, Team } from '../_core/models';
import { OrganizationService } from '../_core/services';


@Component({
  selector: 'sagesse-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent extends BasePage implements OnInit {

  filter = EntityFilter.None;
  filterEntity: Organization = Organization.empty();
  filterTimeout;

  // custom organization list
  organizations: Organization[] = Array.from({ length: 50 });
  //    filter
  searchText = '';
  communityOrPostalCode = '';
  orgTags = '';
  orgTeams = '';
  claresLawOnly = '0';
  claresLawOnlyBinary = false;
  ifSortByDistance = '0';
  ifSortByOfficeDistance = '0';
  ifSortByTagsMatches = '0';
  @ViewChild('sv') primeScroller: VirtualScroller;


  tagService: WarpEntityServiceCache<OrganizationTag>;
  mustMatchTagIds: number[] = [];

  @ViewChild('tags') tags: TagsComponent;
  @ViewChild('communities') communities: CommunitiesComponent;
  @ViewChild('teams') teams: TeamsComponent;

  // tagsLoading = true;
  // communitiesLoading = true;
  // teamsLoading = true;

  loading = true;
  showSortDistanceButton = false;
  showSortTagMatchButton = false;

  orgPageSubs = { };

  public organizationService: OrganizationService;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService,
    
  ) {
    super(logService);
    this.tagService = factory.get(EntityTypes.OrganizationTag) as WarpEntityServiceCache<OrganizationTag>;
    this.organizationService = factory.get(EntityTypes.Organization) as OrganizationService
  }

  async ngOnInit() {
    const tagFilter = EntityFilter.Advanced({ mustmatch: 'Yes' });
    const tags = await this.tagService.initQuery(tagFilter, 9999999).getPage(0).pipe(first()).toPromise();
    this.mustMatchTagIds = tags.map(t => t.entityId);
    this.route.queryParams.subscribe(params => {
      // filter from query params

      // search text
      this.searchText = params.search || '';

      // tags
      this.orgTags = params.tags || '';
      if (params.tags) {
        const tagsFilter = this.orgTags.split(',');
        this.filterEntity.properties.organizationtags = tagsFilter.map(tag => ({ id: parseInt(tag) }));
        if (this.tags) this.tags.updateReference();
        this.showSortTagMatchButton = true;
      } else {
        this.filterEntity.properties.organizationtags = null;
        this.showSortTagMatchButton = false;
      }
      
      // community
      const communityId = parseInt(params.community);
      if (!isNaN(communityId)) {
        this.filterEntity.properties.communities = { id: communityId };

        if (this.communities) this.communities.updateReference();
      } else {
        this.filterEntity.properties.communities = null;
      }

      // postalCode
      this.filterEntity.properties.postalcode = params.postalCode;

      this.communityOrPostalCode = params.postalCode || params.community || '';

      // claresLawOnly
      // if (params.claresLawOnly) this.claresLawOnly = params.claresLawOnly;
      // else this.claresLawOnly = '0';

      // this.claresLawOnlyBinary = this.claresLawOnly === '1';

      // show sort distance button
      if (!isNaN(communityId) || params.postalCode)
        this.showSortDistanceButton = true;
      else this.showSortDistanceButton = false;

      if (params.ifSortByDistance) this.ifSortByDistance = params.ifSortByDistance.toString();
      else this.ifSortByDistance = '0';
      if (params.ifSortByTagsMatches) this.ifSortByTagsMatches = params.ifSortByTagsMatches.toString();
      else this.ifSortByTagsMatches = '0';
      if (params.ifSortByOfficeDistance) this.ifSortByOfficeDistance = params.ifSortByOfficeDistance.toString();
      else this.ifSortByOfficeDistance = '0';

      // team
      this.orgTeams = params.teams || '';
      if (params.teams) {
        const teamsFilter = params.teams.split(',');
        this.filterEntity.properties.teams = teamsFilter.map(team => ({ id: parseInt(team) }));
        if (this.teams) this.teams.updateReference();
      } else {
        this.filterEntity.properties.teams = null;
      }

      this.resetOrganizationList(this);

      this.loading = false;
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (or: Organization) => this.router.navigate([`${or.entityId}`], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });
  add = () => this.router.navigate(['add'], { relativeTo: this.route, queryParams: this.route.snapshot.queryParams });

  filterOrganizations(now: boolean = false) {
    if (this.filterTimeout) clearTimeout(this.filterTimeout);

    if (now) {
      this.updateFilterQueryParams();
    } else {
      const that = this;

      this.filterTimeout = setTimeout(
        () => {
          that.updateFilterQueryParams();
        },
        now ? 0 : 800
      );
    }
  }

  // allFilterEntitiesLoaded(): boolean {
  //   return !this.communitiesLoading && !this.teamsLoading && !this.tagsLoading;
  // }

  // communitiesLoadingComplete(doneLoading: boolean) {
  //   this.communitiesLoading = !doneLoading;
  // }
  // teamsLoadingComplete(doneLoading: boolean) {
  //   this.teamsLoading = !doneLoading;
  // }
  // tagsLoadingComplete(doneLoading: boolean) {
  //   this.tagsLoading = !doneLoading;
  // }

  resetOrganizationList(component) {
    for (const start in component.orgPageSubs) {
      component.orgPageSubs[start].unsubscribe();
    }
    component.organizations.splice(
      0,
      component.organizations.length,
      ...Array.from({ length: component.organizations.length > 0 ? component.organizations.length : 50 }).map((_) => null)
    );
    component.organizations = [...component.organizations];
    if (component.primeScroller) {
      component.primeScroller.clearCache();
      component.primeScroller.scrollToIndex(0);
      component.primeScroller.loadPage(0);
    }
  }

  clearTagFilter() {
    this.filterEntity.properties.organizationtags = null;
    this.updateFilterQueryParams();
  }

  clearTeamFilter() {
    this.filterEntity.properties.teams = null;
    this.updateFilterQueryParams();
  }

  clearAll() {
    this.filterEntity.properties.organizationtags = null;
    this.filterEntity.properties.communities = null;
    this.filterEntity.properties.postalcode = null;
    this.filterEntity.properties.teams = null;
    this.claresLawOnlyBinary = false;
    this.updateFilterQueryParams();
  }

  updateFilterQueryParams() {
    const queryParams = Object.assign({}, this.route.snapshot.queryParams);

    queryParams.search = this.searchText;

    queryParams.tags = this.filterEntity.properties.organizationtags ?
      this.filterEntity.properties.organizationtags.map(tag => tag.id).join(',') : '';
    
    queryParams.community = this.filterEntity.properties.communities && this.filterEntity.properties.communities.id
                              ? this.filterEntity.properties.communities.id : '';

    // queryParams.claresLawOnly = this.claresLawOnlyBinary ? '1' : '0';

    queryParams.postalCode = this.filterEntity.properties.postalcode 
                                ? this.filterEntity.properties.postalcode : '';

    if (!((queryParams.community || queryParams.postalCode) && queryParams.tags)) {
      // set up default sort if only location or tag is determined
      if (queryParams.tags && !queryParams.community && !queryParams.postalCode) this.ifSortByTagsMatches = '1';
      else this.ifSortByTagsMatches = '0';
      if ((queryParams.community || queryParams.postalCode) && !queryParams.tags) {
        if (this.ifSortByDistance === '0' && this.ifSortByOfficeDistance === '0') {
          this.ifSortByDistance = '1';
        }
      }
      else {
        this.ifSortByOfficeDistance = '0';
        this.ifSortByDistance = '0';
      }
    } else {
      // if both location and tag are determined and no sort selected, set up distance as default sort
      if (this.ifSortByDistance === '0' && this.ifSortByOfficeDistance === '0' && this.ifSortByTagsMatches === '0') {
        this.ifSortByDistance = '1';
      }
    }

    queryParams.ifSortByDistance = this.ifSortByDistance;
    queryParams.ifSortByTagsMatches = this.ifSortByTagsMatches;
    queryParams.ifSortByOfficeDistance = this.ifSortByOfficeDistance;

    queryParams.teams = this.filterEntity.properties.teams ?
      this.filterEntity.properties.teams.map(team => team.id).join(',') : '';
  

    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  sortByDistance() {
    this.ifSortByDistance = this.ifSortByDistance === '0' ? '1' : '0';
    if (this.ifSortByDistance === '1') {
      this.ifSortByTagsMatches = '0';
      this.ifSortByOfficeDistance = '0';
    }
    this.updateFilterQueryParams();
  }

  sortByOfficeDistance() {
    this.ifSortByOfficeDistance = this.ifSortByOfficeDistance === '0' ? '1' : '0';
    if (this.ifSortByOfficeDistance === '1') {
      this.ifSortByTagsMatches = '0';
      this.ifSortByDistance = '0';
    }
    this.updateFilterQueryParams();
  }

  sortByTagMatches() {
    this.ifSortByTagsMatches = this.ifSortByTagsMatches === '0' ? '1' : '0';
    if (this.ifSortByTagsMatches === '1') {
      this.ifSortByDistance = '0';
      this.ifSortByOfficeDistance = '0';
    }
    this.updateFilterQueryParams();
  }

  showFilterCard() {
    return this.filterEntity.properties.organizationtags && this.filterEntity.properties.organizationtags.length > 0 ||
      this.filterEntity.properties.communities || this.filterEntity.properties.postalcode || this.filterEntity.properties.teams;
  }

  lazyLoad(event) { 
    if (this.orgPageSubs[event.first]) this.orgPageSubs[event.first].unsubscribe();
    this.orgPageSubs[event.first] = this.organizationService.organizationPage(
      this.searchText, this.communityOrPostalCode, this.orgTags, this.orgTeams,
      event.first, event.rows,
      this.claresLawOnly, this.ifSortByDistance, this.ifSortByTagsMatches, this.ifSortByOfficeDistance)
      .subscribe(result => {
        //console.log('lazy load', result);
        let promises = result.data.map(org =>
          this.organizationService.get(org.id).pipe(first()).toPromise()
            .then(entity => {
              entity.distance = org.distance;
              entity.officeDistance = org.officeDistance;
              entity.numTagMatches = org.numTagMatches;
              return entity;
            }));
        if (this.organizations.length !== result.total) {
          if (this.primeScroller) this.primeScroller.clearCache();
          this.organizations = Array.from({ length: result.total });
        }
        Promise.all(promises).then(entities => {
          Array.prototype.splice.apply(this.organizations, [
            ...[result.first, result.size],
            ...entities
          ]);
          this.organizations = [...this.organizations];
        });    
      });
  }

}
