import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { Community, EntityTypes } from '../_core/models';

@Component({
  selector: 'sagesse-communities-list',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss']
})
export class CommunitiesComponent extends BasePage implements OnInit {

  communityService: WarpEntityServiceCache<Community>;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService
  ) {
    super(logService);
    this.communityService = factory.get(EntityTypes.Community) as WarpEntityServiceCache<Community>;
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (co: Community) => this.router.navigate([`${co.entityId}`], { relativeTo: this.route });
  add = () => this.router.navigate(['add'], { relativeTo: this.route });

}
