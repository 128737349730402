<div class="clares-law-referral-bar">

  <div class="row justify-content-center align-items-center">
    <div class="col d-flex justify-content-center align-items-center text-center">
      <div>
        <label class="mb-0">Clare's Law Reference:</label>
        <a href="javascript:;" (click)="edit()">
          <h2 class="mb-0 text-white">#{{cookieService.getCookie('referenceNumber')}}</h2>
        </a>
      </div>
    </div>
    <div class="col d-flex justify-content-center align-items-center">
      <button pButton label="Refer: {{cookieService.getCookie('referralName')}}"
          (click)="refer()" [disabled]="!this.cookieService.getCookie('referralID')">
        </button>
    </div>
  </div>
  <button pButton class="btn-close-referral" icon="pi pi-times" (click)="exit()"></button>
</div>

<p-dialog header="Refer: {{cookieService.getCookie('referralName')}}"
  [(visible)]="referralDialogShow"
  *ngIf="referralDialogShow"
  [style]="{width: '50vw'}"
  [baseZIndex]="10010"
  appendTo="body">
  <ripple-loading-box [loading]="loading || sending">
    <div class="p-grid ui-fluid">
      <!-- multiple intake special -->
      <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="intakeManagers && intakeManagers.length > 1">
        <div class="ui-widget">
          <label>
            There are multiple intake managers for this organization, please choose which one you'd like to send this request to:
          </label>
        </div>
        <div *ngFor="let intake of intakeManagers">
          <p-checkbox
            [(ngModel)]="email"
            [value]="intake.email"
          ></p-checkbox>
          <label>&nbsp;{{intake.name}} {{intake.department ? '- Department: ' + intake.department : ''}} <span *ngIf="intake.id === primaryId">(Primary)</span></label>
        </div>
      </div>

      <!-- sagesse special -->
      <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="cookieService.getCookie('referralID') === sagesseOrgId && claresLaw">
        <div class="ui-widget">
          <label>
            Why was Sagesse the reffered to agency? *
          </label>
          <p-message severity="error" text="This field is required" *ngIf="tryValidate && !claresLaw.properties.whysagesse"></p-message>
        </div>
        <div class="p-grid">
          <div class="p-sm-12 p-md-12">
              <p-radioButton name="whySagesse" value="2538" label="It was the best choice"
                  [(ngModel)]="claresLaw.properties.whysagesse" required>
              </p-radioButton>
          </div>
          <div class="p-sm-12 p-md-12">
              <p-radioButton name="whySagesse" value="2539" label="Unable to find suitable referral agency"
                  [(ngModel)]="claresLaw.properties.whysagesse" required>
              </p-radioButton>
          </div>
          <div class="p-sm-12 p-md-12" *ngIf="claresLaw.properties.whysagesse === '2539'">
            <textarea pInputTextarea maxLength="5000" name="whyNotNotes" [(ngModel)]="claresLaw.properties.whynotnotes"
              placeholder="Please explain why *" class="w-100" required>
            </textarea>
            <p-message severity="error" text="This field is required" *ngIf="tryValidate && !claresLaw.properties.whynotnotes"></p-message>
          </div>
        </div>
      </div>

      <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
          <label>
            To
          </label>
        </div>
        <p-chips [(ngModel)]="email" [addOnBlur]="true"></p-chips>
      </div>
      <div class="p-sm-12 p-md-12 ui-fluid">
        <div class="ui-widget">
          <label>
            Subject
          </label>
        </div>
        <input
          pInputText type="text"
          [(ngModel)]="subject"
          class="w-100" placeholder="Subject"
        />
      </div>
      <div class="p-sm-12 p-md-12 ui-fluid">
        <p-editor [(ngModel)]="body" [style]="{'height':'20vh'}" #bodyEditor [modules]="editorModule"></p-editor>
      </div>
    </div>
  </ripple-loading-box>
  <div class="p-d-flex justify-content-center w-100">
    <h2 *ngIf="sending">Sending...</h2>
  </div>

  <p-footer>
    <button pButton class="ui-button-secondary" icon="pi pi-upload" label="Send" (click)="send()" [disabled]="sending || loading">
    </button>
    <button pButton icon="pi pi-refresh" label="Cancel" (click)="cancel()" [disabled]="sending || loading">
    </button>
  </p-footer>
</p-dialog>
