import { Component } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntity } from '@ripple/models';
import { GenericWarpEntityService, WarpEntityLogService } from '@ripple/services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'sagesse-universal-editor',
  templateUrl: './universal-editor.component.html',
  styleUrls: ['./universal-editor.component.scss']
})
export class UniversalEditorComponent extends BasePage {

  entity: WarpEntity;
  description: string;

  constructor(
    private logService: WarpEntityLogService,
    private service: GenericWarpEntityService
  ) {
    super(logService);
  }

  status: 'init' | 'loading' | 'loaded' = 'init';
  get loading() {
    return this.status !== 'init';
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

  setEntity(we: WarpEntity) {
    this.status = 'loaded';
    this.entity = we;
    if (we)
      this.service
        .getEntityStructure(we.entityTypeId)
        .pipe(first())
        .toPromise()
        .then( et => this.description = we.entityId !== -1 ? `<${et.name}> ${we.name || we.entityId}` : `Add a New <${et.name}>`);
  }

  entityid(entityId: number): void {
    this.status = 'loading';
    if (entityId)
      this.service
        .get(entityId)
        .pipe(first())
        .toPromise()
        .then( e => {
          this.setEntity(e);
        })
        .catch( e => this.setEntity(null));
  }

  typeid(typeId: number): void {
    this.status = 'loading';
    if (typeId)
      this.setEntity(new WarpEntity(WarpEntity.emptyEntity(typeId)));
  }

}
