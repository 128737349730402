import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityServiceCache, WarpEntityLogService, WarpEntityCacheFactoryService } from '@ripple/services';
import { CommunityGroup, EntityTypes } from '../_core/models';

@Component({
  selector: 'sagesse-community-groups',
  templateUrl: './community-groups.component.html',
  styleUrls: ['./community-groups.component.scss']
})
export class CommunityGroupsComponent extends BasePage implements OnInit {

  communityGroupService: WarpEntityServiceCache<CommunityGroup>;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService
  ) {
    super(logService);
    this.communityGroupService = factory.get(EntityTypes.CommunityGroup) as WarpEntityServiceCache<CommunityGroup>;
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (co: CommunityGroup) => this.router.navigate([`${co.entityId}`], { relativeTo: this.route });
  add = () => this.router.navigate(['add'], { relativeTo: this.route });

}
