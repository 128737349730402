import {
  FormCheckboxMultipleColComponent,
  FormBuilderSignatureComponent,
  FormRadioMultipleColComponent,
  FormDatetimePickerComponent,
  FormSelectEntityComponent,
  FormDescriptionComponent,
  FormTextEditorComponent,
  FormTextareaComponent,
  FormSelectComponent,
  FormInputComponent,
  RippleFieldGenericComponent,
  FormColorPickerComponent,
  FormFileComponent,
  FormMultiselectComponent,
} from '../partials';
import type { FieldTypeName } from '../service-resources/question-type-names';

export function formFieldFactory(fieldTypeName: FieldTypeName) {
  switch (fieldTypeName) {
    case 'checkbox-multiple-col':
      return FormCheckboxMultipleColComponent;
    case 'select-search':
      return FormSelectComponent;
    case 'generic-input':
      return FormInputComponent;
    case 'generic-textarea':
      return FormTextareaComponent;
    case 'text-editor':
      return FormTextEditorComponent;
    case 'radio-multiple-col':
      return FormRadioMultipleColComponent;
    case 'datetime-picker':
      return FormDatetimePickerComponent;
    case 'description-info':
      return FormDescriptionComponent;
    case 'select-entity':
      return FormSelectEntityComponent;
    case 'draw-signature':
      return FormBuilderSignatureComponent;
    case 'color-picker':
      return FormColorPickerComponent;
    case 'generic-file':
      return FormFileComponent;
    case 'multiselect':
      return FormMultiselectComponent;
    case 'generic':
    default:
      return RippleFieldGenericComponent;
  }
}
