/**
 * Vendored from PrimeNG v9.1.3 with mediumRegex and strongRegex options added.
 * 
 * TODO: Replace use once we move to PrimeNG v10+
 */
import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  DoCheck,
  NgZone,
} from "@angular/core";
import { DomHandler } from "primeng/dom";

@Directive({
  selector: "[ripplePassword]",
  host: {
    "[class.ui-inputtext]": "true",
    "[class.ui-corner-all]": "true",
    "[class.ui-state-default]": "true",
    "[class.ui-widget]": "true",
    "[class.ui-state-filled]": "filled",
  },
})
export class PasswordDirective implements OnDestroy, DoCheck {
  @Input() promptLabel: string = "Enter a password";

  @Input() mediumRegex: string =
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})";

  @Input() strongRegex: string = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})";

  @Input() weakLabel: string = "Weak";

  @Input() mediumLabel: string = "Medium";

  @Input() strongLabel: string = "Strong";

  @Input() feedback: boolean = true;

  @Input() set showPassword(show: boolean) {
    this.el.nativeElement.type = show ? "text" : "password";
  }

  panel: HTMLDivElement;

  meter: any;

  info: any;

  filled: boolean;

  mediumCheckRegExp: RegExp;

  strongCheckRegExp: RegExp;

  constructor(public el: ElementRef, public zone: NgZone) {}

  ngDoCheck() {
    this.updateFilledState();
  }

  //To trigger change detection to manage ui-state-filled for material labels when there is no value binding
  @HostListener("input", ["$event"])
  onInput(e) {
    this.updateFilledState();
  }

  updateFilledState() {
    this.filled =
      this.el.nativeElement.value && this.el.nativeElement.value.length;
  }

  createPanel() {
    this.panel = document.createElement("div");
    this.panel.className =
      "ui-password-panel ui-widget ui-state-highlight ui-corner-all";
    this.meter = document.createElement("div");
    this.meter.className = "ui-password-meter";
    this.info = document.createElement("div");
    this.info.className = "ui-password-info";
    this.info.textContent = this.promptLabel;
    this.panel.appendChild(this.meter);
    this.panel.appendChild(this.info);
    this.panel.style.minWidth =
      DomHandler.getOuterWidth(this.el.nativeElement) + "px";
    document.body.appendChild(this.panel);
    this.mediumCheckRegExp = new RegExp(this.mediumRegex);
    this.strongCheckRegExp = new RegExp(this.strongRegex);
  }

  @HostListener("focus", ["$event"])
  onFocus(e) {
    if (this.feedback) {
      if (!this.panel) {
        this.createPanel();
      }

      this.panel.style.zIndex = String(++DomHandler.zindex);
      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          DomHandler.addClass(this.panel, "ui-password-panel-visible");
          DomHandler.removeClass(this.panel, "ui-password-panel-hidden");
        }, 1);
        DomHandler.absolutePosition(this.panel, this.el.nativeElement);
      });
    }
  }

  @HostListener("blur", ["$event"])
  onBlur(e) {
    if (this.feedback) {
      DomHandler.addClass(this.panel, "ui-password-panel-hidden");
      DomHandler.removeClass(this.panel, "ui-password-panel-visible");

      this.zone.runOutsideAngular(() => {
        setTimeout(() => {
          this.ngOnDestroy();
        }, 150);
      });
    }
  }

  @HostListener("keyup", ["$event"])
  onKeyup(e) {
    if (this.feedback) {
      let value = e.target.value,
        label = null,
        meterPos = null;

      if (value.length === 0) {
        label = this.promptLabel;
        meterPos = "0px 0px";
      } else {
        switch (this.testStrength(value)) {
          case 1:
            label = this.weakLabel;
            meterPos = "0px -10px";
            break;
          case 2:
            label = this.mediumLabel;
            meterPos = "0px -20px";
            break;
          case 3:
            label = this.strongLabel;
            meterPos = "0px -30px";
            break;
        }
      }

      this.meter.style.backgroundPosition = meterPos;
      this.info.textContent = label;
    }
  }

  testStrength(str: string) {
    let level = 0;

    if (this.strongCheckRegExp.test(str)) level = 3;
    else if (this.mediumCheckRegExp.test(str)) level = 2;
    else if (str.length) level = 1;

    return level;
  }

  normalize(x, y) {
    let diff = x - y;

    if (diff <= 0) return x / y;
    else return 1 + 0.5 * (x / (x + y / 4));
  }

  get disabled(): boolean {
    return this.el.nativeElement.disabled;
  }

  ngOnDestroy() {
    if (this.panel) {
      document.body.removeChild(this.panel);
      this.panel = null;
      this.meter = null;
      this.info = null;
    }
  }
}
