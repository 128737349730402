import {
  Component,
  Directive,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';

import { WarpEntity } from '@ripple/models';
import {
  WarpEntityCacheFactoryService,
  WarpEntityServiceCache,
  WarpEntityLogService,
  MessageService,
  Revision
} from '@ripple/services';

import * as moment_ from 'moment';
const moment = moment_;
import { SelectItem } from 'primeng/api';
import { first } from 'rxjs/operators';

import {
  CanvasWhiteboardComponent,
  CanvasWhiteboardUpdate,
} from 'ng2-canvas-whiteboard';
import { Calendar } from 'primeng/calendar';
import { RevisionHistorySnapshot } from '@ripple/services';

type RevisionHistorySnapshotView = RevisionHistorySnapshot & {
  open?: boolean
  loadingChanges?: boolean,
  changes?: (Revision & { signature?: any, canvasElement?: HTMLElement })[],
}

interface DailyLog {
  date: string;
  logs: RevisionHistorySnapshotView[];
}


@Component({
  selector: 'ripple-entity-logs',
  templateUrl: './entity-logs.component.html',
  styleUrls: ['./entity-logs.component.scss'],
})
export class EntityLogsComponent implements OnChanges, OnInit {
  private employeeService: WarpEntityServiceCache<WarpEntity>;

  @ViewChildren(CanvasWhiteboardComponent)
  canvasList: QueryList<CanvasWhiteboardComponent>;

  public canvasOptions = {
    saveDataButtonEnabled: false,
    saveDataButtonText: 'Save',
    drawingEnabled: false,

    drawButtonClass: 'drawButtonClass',
    drawButtonText: 'Draw',
    drawButtonEnabled: false,

    clearButtonEnabled: false,
    clearButtonClass: 'clearButtonClass',
    clearButtonText: 'Clear',

    undoButtonText: 'Undo',
    undoButtonEnabled: false,

    redoButtonText: 'Redo',
    redoButtonEnabled: false,

    colorPickerEnabled: false,
    shouldDownloadDrawing: false,
    shapeSelectorEnabled: false,

    lineWidth: 5,
    strokeColor: 'rgb(0,0,0)',
  };

  @Input() entityIds: number[];
  @Input() entityTypeIds: number[];
  @Input() autoSearch: boolean;
  // By supplying childEntityTypes, the component will recursively get all the
  // children entityIDs of the types defined in this array and search using them
  @Input() childEntityTypes: number[];
  @Input() pageSize = 20;
  @Input() optionalTitle = "";
  @Input() showReversionHistoryId = false;
  @Input() actionTypes: string[];

  logs: RevisionHistorySnapshot[];
  dailyLogs: DailyLog[] = [];
  filteredDailyLogs: DailyLog[] = [];
  loading = false;
  noLogs: boolean;
  nextPage: number = 0;
  hasRunOnce: boolean = false;

  // <-------------- Compare Options -------------->
  dateOptions = [
    { label: 'Greater Than', value: 'gt' },
    { label: 'Equals', value: 'equals' },
    { label: 'Less Than', value: 'lt' },
  ];
  dateCompareOption = 'gt';

  // <--------------- Search Options --------------->
  entityTypes: SelectItem[] = [];
  entityTypesSelected: [];

  @Input()
  dateSearch: Date[];

  @ViewChild('searchDatesCalendar', { read: Calendar }) searchDatesCalendar: Calendar;
  ngAfterViewInit() {
    if(this.searchDatesCalendar)
      this.searchDatesCalendar.selectOtherMonths = true;
  }

  /**
   * reactively restricts the user by limiting the date range input to one week from
   * selected date
   */
  updateRange() {
    // this.ngZone.runOutsideAngular(() => {
    //   setTimeout(() => {
    //     if (this.dateSearch[0] != null) {
    //       let updateDate = new Date(this.dateSearch[0]);
    //       this.searchDatesCalendar.minDate = new Date(updateDate);
    //       this.searchDatesCalendar.maxDate = new Date(updateDate.setDate(updateDate.getDate() + 7));
    //     } else {
    //       this.searchDatesCalendar.maxDate = null;
    //       this.searchDatesCalendar.minDate = null;
    //     }

    //     if (this.dateSearch[1] > this.searchDatesCalendar.maxDate) {
    //       this.dateSearch[1] = this.searchDatesCalendar.maxDate;
    //     }
    //   }, 1)
    // })
  }

  /**
   * Resets the range in which user can select dates for logs
   */
  clearRange() {
    // this.searchDatesCalendar.minDate = null;
    // this.searchDatesCalendar.maxDate = null;
  }

  // searchEmployees: SelectItem[] = [];
  usersSelected: number[] = [];

  // <------------------ Filters ------------------>
  entityTypeFilterOptions: SelectItem[];
  entityTypeFilter: [];

  entityFilterOptions: SelectItem[];
  entityFilter: [];

  filterEmployees: SelectItem[];
  userFilter: [];

  dateFilter: Date[];

  // <--------------- Order Options --------------->
  orderItems: SelectItem[] = [
    { label: 'Descending', value: 'DESC' },
    { label: 'Ascending', value: 'ASC' },
  ];
  orderSelection = 'DESC';
  oldOrderSelection = 'DESC';

  constructor(
    private entityLogService: WarpEntityLogService,
    private msg: MessageService,
    private ngZone: NgZone,
    factory: WarpEntityCacheFactoryService
  ) { }

  ngOnInit() {
    if (this.autoSearch) this.getLogs();
  }

  ngOnChanges(changes) {
    if (changes.entityTypeIds) this.updateEntityTypes();
    if (changes.entityIds && this.autoSearch && this.hasRunOnce) this.getLogs();
  }

  updateEntityTypes() {
    this.entityTypes = [];

    this.entityLogService
      .getEntityTypes(this.entityTypeIds)
      .then((entityTypes) => {
        for (const entityType of entityTypes) {
          this.entityTypes.push({
            label: entityType.name,
            value: entityType.id,
          });
        }
      });
  }

  getDescription(log: RevisionHistorySnapshotView) {
    if (log.label) return log.label;

    const desc = `${log.entityName}`;

    switch (log.action) {
      case 'ADD':
        return 'Created ' + desc;
      case 'UPDATE':
        return 'Updated ' + desc;
      case 'DELETE':
        return 'Deleted ' + desc;
      case 'VIEW':
        return 'Viewed ' + desc;
      case 'DESTROY':
        return 'Destroyed ' + desc;
    }
  }

  changeOrder() {
    if (this.orderSelection !== this.oldOrderSelection) {
      this.oldOrderSelection = this.orderSelection;
      for (const dailyLog of this.filteredDailyLogs)
        dailyLog.logs.reverse();

      this.filteredDailyLogs.reverse();
    }
  }

  open(log: RevisionHistorySnapshotView) {
    setTimeout(() => {
      if (log.action === 'UPDATE' && log.open && !log.changes) {
        log.loadingChanges = true;
        this.entityLogService
          .getChanges(log.id, log.entityID)
          .then((changes) => {
            log.changes = [];

            if (changes.changes)
              log.changes.push(...changes.changes);

            if (changes.formChanges) {
              log.changes.push(...changes.formChanges);

            for (const change of log.changes) {
              if (change.change && this.isSignature(change)) {
                log.loadingChanges = true;
                const sigData = typeof change.change === 'string' ? JSON.parse(change.change) : change.change;
                const updates = sigData.map((jsonUpdate) =>
                  CanvasWhiteboardUpdate.deserializeJson(JSON.stringify(jsonUpdate))
                );
                change.signature = updates;

                setTimeout(() => {
                  if (log.open) {
                    const id = this.canvasId(log, change);
                    const element = document.getElementById(id);
                    change.canvasElement = element;
                    element.style.display = 'block';

                    // setTimeout(() => (element.style.display = 'block'), 250);

                    const canvas = this.canvasList.find(c => {
                      const canvasId = c.canvas?.nativeElement?.parentElement?.parentElement?.id;
                      return canvasId && canvasId === id;
                    });

                    if (canvas)
                      canvas.drawUpdates(change.signature);
                  }
                }, 50);
              }
            }
          }

          log.loadingChanges = false;
        });
      }

      // if (log.changes) {
      //   for (const change of log.changes) {
      //     if (change.canvasElement) {
      //       if (log.open) {
      //         setTimeout(
      //           () => (change.canvasElement.style.display = 'block'),
      //           250
      //         );
      //       } else change.canvasElement.style.display = 'none';
      //     }
      //   }
      // }
    }, 20);
  }

  isSignature(change: Revision) {
    return /^formbuilder--(draw-)?signature/.test(change.unchangeableName);
  }

  canvasId(log: RevisionHistorySnapshotView, change: any) {
    return `canvas-${log.id}-${change.unchangeableName}`;
  }

  getLogs(page = 0) {
    this.loading = true;
    this.noLogs = false;

    if (page == 0) {
      this.logs = [];
      this.dailyLogs = [];

      this.filterEmployees = [];
      this.entityTypeFilterOptions = [];
      this.entityFilterOptions = [];
    }

    const pageSize = Math.max(20, this.pageSize);
    this.entityLogService
      .getRevisionHistory({
        entityIds: this.entityIds,
        entityTypeIds: this.entityTypesSelected,
        userIds: this.usersSelected,
        firstDate: this.dateSearch && this.dateSearch[0],
        secondDate: this.dateSearch && this.dateSearch[1],
        childEntityTypes: this.childEntityTypes,
        actionTypes: this.actionTypes,
        page,
        pageSize
      })
      .then((logs) => {
        this.hasRunOnce = true;
        if (!logs.length || logs.length < pageSize)
          this.nextPage = -1;
        else
          this.nextPage = page + 1;

        for (let i = 0; i < logs.length; i++) {
          const log = logs[i];
          const logTime = Date.parse(log.performed);

          if (i < logs.length - 1) {
            // skip this one if it duplicates the next one
            //check if this log is already in there. 
            if (this.logs.find(l => l.id === log.id)) {
              continue;
            }
          
            const nextLog = logs[i + 1];
            if (
              nextLog.entityID === log.entityID &&
              nextLog.performedBy === log.performedBy &&
              nextLog.action === log.action &&
              nextLog.action === 'UPDATE' &&
              Math.abs(Date.parse(nextLog.performed) - logTime) <= 30000 /* next log is within 30s */
            ) {
              //skip this one
              continue;
            }
          }

          if (!this.filterEmployees.find(u => u.value === log.userID))
            this.filterEmployees.push({ label: log.performedBy, value: log.userID });

          if (!this.entityTypeFilterOptions.find(et => et.value == log.entityTypeID))
            this.entityTypeFilterOptions.push({ label: log.entityTypeName, value: log.entityTypeID });

          if (!this.entityFilterOptions.find( e => e.value === log.entityID))
            this.entityFilterOptions.push({ label: `${log.entityName}`, value: log.entityID });

          this.logs.push(log);
          const date = new Date(logTime).toLocaleDateString(); // respect user settings
          let dailyLog = this.dailyLogs.find(dl => dl.date === date);
          if (dailyLog)
            dailyLog.logs.push(log);
          else
            this.dailyLogs.push({ date, logs: [log] });
        }

        this.noLogs = this.logs.length == 0;

        this.filter();
        this.loading = false;
      });
  }

  clearFilter() {
    this.dateCompareOption = 'gt';
    this.entityTypeFilter = [];
    this.entityFilter = [];
    this.userFilter = [];
    this.dateFilter = undefined;

    this.filter();
  }

  filter() {
    this.filteredDailyLogs = [];

    const firstDate = this.dateFilter ? this.dateFilter[0] : null;
    const lastDate = this.dateFilter ? this.dateFilter[1] : null;

    let compareDate = null;
    if (!firstDate || !lastDate)
      compareDate = firstDate ? firstDate : lastDate;

    this.msg.add('Entity Logs Component', `JS Filter`, this.userFilter, this.entityTypeFilter, this.entityFilter);
    for (const dailyLog of this.dailyLogs) {
      const date = dailyLog.date;
      const logs = [];

      // CHECKS IF THE DAILYLOG FAILS TO FIT IN THE DATE RANGE FILTER
      const logDate = new Date(Date.parse(dailyLog.date));
      if (
        (firstDate || lastDate) &&
        ((compareDate &&
          ((this.dateCompareOption === 'gt' && logDate < compareDate) ||
            (this.dateCompareOption === 'lt' && logDate > compareDate) ||
            (this.dateCompareOption === 'equals' &&
              logDate !== compareDate))) ||
          (!compareDate && (logDate < firstDate || logDate > lastDate)))
      ) {
        for (const log of dailyLog.logs) {
          if (log.open && log.changes) {
            for (const change of log.changes) {
              if (change.canvasElement) {
                change.canvasElement.style.display = 'none';
              }
            }
          }
          log.open = false;
        }
        continue;
      }

      for (const log of dailyLog.logs) {
        if ((
            !this.userFilter?.length ||
            this.userFilter.find((item) => item == log.userID)
          ) && (
            !this.entityTypeFilter?.length ||
            this.entityTypeFilter.find((item) => item == log.entityTypeID)
          ) && (
            !this.entityFilter?.length ||
            this.entityFilter.find((item) => item == log.entityID)
          )
        ) {
          logs.push(log);
        } else {
          // on hide, hide the signatures
          if (log.open && log.changes)
            for (const change of log.changes) {
              if (change.canvasElement)
                change.canvasElement.style.display = 'none';
            }

          log.open = false;
        }
      }

      if (logs.length > 0) {
        if (this.orderSelection == 'ASC')
          logs.reverse();
        this.filteredDailyLogs.push({ date: date, logs: logs });
      }
    }

    if (this.orderSelection == 'ASC')
      this.filteredDailyLogs.reverse();
  }
}
