import { Injectable } from '@angular/core';
import { environment } from '@ripple/environment';
// import { CookieService } from 'angular2-cookie/core';
import { CookieService } from 'ngx-cookie';

// tslint:disable-next-line: variable-name
export const CookieType = {
  UserToken: environment.tokenName, // prevent collision with localhost:56696
};

@Injectable({
  providedIn: 'root'
})
export class InternalCookieService {

  constructor(private _cookieService: CookieService) { }

  getBooleanCookie(key: string) {
    const cookie = this.getCookie(key) || '';
    return ['true', 'yes', '1', 1].includes(cookie.toLowerCase())
  }

  getCookie(key: string) {
    if (environment.cookieLocation === 'localStorage')
      return localStorage.getItem(key.toString());

    return this._cookieService.get(key.toString());
  }

  setCookie(key: string, value: string) {
    if (environment.cookieLocation === 'localStorage')
      return localStorage.setItem(key.toString(), value);

    return this._cookieService.put(key.toString(), value);
  }

  dropCookie(key: string) {
    if (environment.cookieLocation === 'localStorage')
      return localStorage.removeItem(key.toString());

    this._cookieService.remove(key.toString());
  }

}
