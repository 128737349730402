<div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header header-container">
      <h1 class="header__heading" style="width:100%">Assigned Stats</h1>
      <div class="header__actions">
        <button pButton type="button" icon="pi pi-{{saving ? 'spin pi-spinner' : 'check'}}" label="Save"
          (click)="save()"></button>
      </div>
    </div>
    <div class="pane__body">

      <div class="card-container" *ngIf="userStats != null">
          <sagesse-enter-stats-table [edited]="edited" [loading]="loading" (updateStats)="statsUpdated($event)" [stats]="userStats" (monthChanged)="monthChanged($event)" [month]="month" [year]="year">
          </sagesse-enter-stats-table>
      </div>
    </div>
  </div>
</div>


<!-- <div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">Enter Stats</h1>
      <div class="header__actions"></div>
    </div>
    <div class="pane__body">

      <div class="card-container" *ngIf="userStats != null">
        <p-card>
          <div class="header">
            <h1 class="header__heading">Assigned Stats</h1>
            <div class="header__actions">
              <button pButton type="button" icon="pi pi-{{saving ? 'spin pi-spinner' : 'check'}}" label="Save"
                (click)="save()"></button>
            </div>
          </div>
          <sagesse-enter-stats-table (updateStats)="statsUpdated($event)" [stats]="userStats"
            (monthChanged)="monthChanged($event)">
          </sagesse-enter-stats-table>
        </p-card>
      </div>
    </div>
  </div>
</div> -->

