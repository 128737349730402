import { Injectable, Injector } from '@angular/core';
import {
  User,
  Organization,
  Contact,
  OrganizationContact,
  ContactRole,
  Office,
  OrganizationTagCategory,
  OrganizationTag,
  ClaresLaw,
  FilledForm,
  Community,
  CommunityGroup,
  Team,
  TeamType,
  TeamContactRoleType,
  TeamContact,
  Channel,
  ChannelMember,
  ClaresLawSensitiveInformation,
  EntityTypes,
  Program,
  StatisticGroup,
  Statistic,
  FilledStatistic,
  StrategicOutcomeSubCategory,
  StrategicOutcomeStatistic,
  IndividualStatisitcReport,
  StrategicOutcomeTarget,
  EvaluationDBProgramCategory,
} from '../../../_core/models';

import { WarpEntityCacheFactoryService  } from '@ripple/services';
import { ChannelMemberService } from './channelMember.service';
import { ChannelService, ClaresLawService, ContactService, OrganizationContactService, OrganizationService, TeamContactService, TeamService, UserService } from '..';
import { StrategicOutcome } from '../../models/StrategicOutcome';
import { MonthNote } from '../../models/MonthNote';

@Injectable({
  providedIn: 'root'
})
export class WarpEntityCacheFactoryLoaderService  {

  constructor(private serviceFactory: WarpEntityCacheFactoryService) {
    }

    load(): Promise<any> {
      this.loadCaches();
      this.loadModels();

      return Promise.resolve();
    }

    loadCaches() {
      //you must add every extended cache here
      this.serviceFactory.registerExtended(EntityTypes.ChannelMember, ChannelMemberService);
      this.serviceFactory.registerExtended(EntityTypes.ClaresLaw, ClaresLawService);
      this.serviceFactory.registerExtended(EntityTypes.Channel, ChannelService);
      this.serviceFactory.registerExtended(EntityTypes.Contact, ContactService);
      this.serviceFactory.registerExtended(EntityTypes.Organization, OrganizationService);
      this.serviceFactory.registerExtended(EntityTypes.OrganizationContact, OrganizationContactService);
      this.serviceFactory.registerExtended(EntityTypes.Team, TeamService);
      this.serviceFactory.registerExtended(EntityTypes.TeamContact, TeamContactService);
      this.serviceFactory.registerExtended(EntityTypes.User, UserService);
    }

    getModelType(entityTypeId: number) {
      //you must add every model defined in EntityTypes here
      switch(entityTypeId) {
        case 2:
          return User;
        case 611:
          return Organization;
        case 72:
          return Contact;
        case 680:
          return OrganizationContact;
        case 681:
          return ContactRole;
        case 8:
          return Office;
        case 682:
          return OrganizationTagCategory;
        case 683:
          return OrganizationTag;
        case 685:
          return ClaresLaw;
        case 636:
          return FilledForm;
        case 144:
          return Community;
        case 686:
          return CommunityGroup;
        case 687:
          return Team;
        case 688:
          return TeamType;
        case 689:
          return TeamContactRoleType;
        case 690:
          return TeamContact;
        case 700:
          return Channel;
        case 701:
          return ChannelMember;
        case 721:
          return ClaresLawSensitiveInformation;
        case 748:
          return Program;
        case 749:
          return StatisticGroup;
        case 750:
          return Statistic;
        case 751:
          return FilledStatistic;
        case 752:
          return StrategicOutcome;
        case 753:
          return StrategicOutcomeSubCategory;
        case 754:
          return StrategicOutcomeStatistic;
        case 755:
          return IndividualStatisitcReport;
        case 756:
          return StrategicOutcomeTarget;
        case 782:
          return MonthNote;
        case 827:
          return EvaluationDBProgramCategory;

        default:
          throw new Error(`No mapping for entityTypeId: ${entityTypeId}, you need to add mapping to ${EntityTypes[entityTypeId]} in getModelType()`);
      }
    }

    loadModels() {
      Object.keys(EntityTypes).forEach(k => {
        let entityTypeId = parseInt(k);
        if(entityTypeId > 0)
          this.serviceFactory.registerModel(entityTypeId, this.getModelType(entityTypeId));
      });
    }
}
