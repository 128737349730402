<p-accordion>
  <p-accordionTab>
    <p-header>
      <span>{{name}}</span>
      <p-splitButton class="right-button" [model]="group.actionMenu" label="Edit" icon="pi pi-pencil"
        (onClick)="showModal('editgroup', 'Edit Group', group)" appendTo="body">
      </p-splitButton>
    </p-header>
    <!-- <button style="width:200px; margin-bottom: 10px;" pButton type="button" icon="pi pi-pencil" label="Edit Group"
    (click)="showModal('editgroup', 'Edit Group', group)"></button> -->
    <!--
    <p-splitButton [model]="group.actionMenu" label="Edit" icon="pi pi-pencil"
       (onClick)="showModal('editgroup', 'Edit Group', group)" appendTo="body">
    </p-splitButton> -->

    <button style="width:200px; margin-bottom: 10px;" pButton type="button" icon="pi pi-plus" label="Add Statistic"
      (click)="showModal('add', 'Add Statistic')"></button>

    <div *ngIf="loadingStats">
      <p-progressSpinner></p-progressSpinner>
    </div>


    <p-table #dt [value]="stats" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
      [showCurrentPageReport]="true" [loading]="loading" [paginator]="false"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
      (onRowReorder)="reorder($event)">
      <ng-template pTemplate="header">
        <tr>
          <th width="10%">Reorder
          </th>
          <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="asignees">Assignees <p-sortIcon field="assignees"></p-sortIcon>
          </th>
          <th pSortableColumn="linkedprogramcategory">Program Categories <p-sortIcon field="linkedprogramcategory"></p-sortIcon>
          </th>
          <th class="actions-col"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-stat let-index="rowIndex">
        <tr class="ui-selectable-row" [pReorderableRow]="index">
          <td width="10%">
            <i class="pi pi-bars" pReorderableRowHandle></i>
          </td>
          <td>{{ stat.properties.name }}</td>
          <td>
            <ng-container *ngIf="stat.properties.employees">
              <ng-container *ngFor="let employee of stat.properties.employees; let last = last">
                {{employee?.name}} <span *ngIf="!last">|</span>
              </ng-container>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="stat.multiValuedProperties && stat.multiValuedProperties.linkedprogramcategory">
              <ng-container  *ngFor="let programCategory of stat.multiValuedProperties.linkedprogramcategory; let first = first">
                {{ first ? programCategory.name : ', ' + programCategory.name }}
              </ng-container>
            </ng-container>
          </td>
          <td class="actions-col">
            <p-splitButton [model]="stat.actionMenu" label="Edit"
              icon="pi pi-{{stat.deleting ? 'spin pi-spinner' : 'pencil'}}" [disabled]="stat.deleting"
              (onClick)="showModal('edit', 'Edit Statistic',  stat)" appendTo="body">
            </p-splitButton>

          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="*" class="empty-message-col">No Stats found.</td>
        </tr>
      </ng-template>
    </p-table>
  </p-accordionTab>
</p-accordion>


<p-dialog [header]="modalText" [(visible)]="showModalFlag" [appendTo]="'body'" [modal]="true" [draggable]="false"
  [baseZIndex]="10000" [dismissableMask]="true">
  <div class="modal-content">
    <ripple-entity-details [entity]="toEdit" [hideButtons]="true" [generatorOptions]="generatorOptions">
    </ripple-entity-details>
    <!-- [disabled]="statName == ''" -->
  </div>
  <p-footer>
    <button pButton type="button" icon="pi pi-{{adding ? 'spin pi-spinner' : modalButtonIcon }}" [label]="modalButtonText"
      (click)="addStat()"></button>
  </p-footer>
</p-dialog>
