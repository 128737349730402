import { Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService } from '@ripple/services';
import { ClaresLaw, Contact, EntityTypes, Organization } from '../../models';
import { map } from 'rxjs/operators';
import * as moment from 'moment';


@Injectable({
  providedIn: 'root'
})
export class ClaresLawService extends WarpEntityServiceCache<ClaresLaw> {

  constructor(injector: Injector) {
    super(injector, ClaresLaw, EntityTypes.ClaresLaw);
  }

  public sendReferralEmail(subject: string, body: string, email: string[], 
    formEntityId: number, intakeName: string, referenceNumber: string, infoTable: string, infoLink: string, claresLawEntityId: string | number): Observable<string> {
    const postBody = {
      subject,
      body,
      email,
      formEntityId,
      intakeName,
      referenceNumber,
      infoTable,
      infoLink,
      claresLawEntityId
    };
    return this.warpService._post('/api/sagesse/sendReferralEmail/', postBody);
  }

  public getClosestCommunity(city: string): Observable<number> {
    return this.warpService._get(`/api/sagesse/closestCommunity/${encodeURI(city)}`);
  }

  public updateClaresLawInfoViewed(claresLawId, viewedCfcId): Observable<ClaresLaw> {
    return this.warpService._get(`/api/sagesse/updateClaresLawInfoViewed/${claresLawId}/${viewedCfcId}`)
      .pipe(map(retVal => new ClaresLaw(retVal)));
  }

  public generateClaresLawMonthlyReport(start: Date, end: Date): Observable<string> {
    const startDate = moment(start).format('YYYY-MM-DD');
    const endDate = moment(end).format('YYYY-MM-DD');
    return this.warpService._get(`/api/sagesse/claresLawMonthlyReport?startDate=${startDate}&endDate=${endDate}`);
  }

  public sendReportEmail(subject: string, body: string, email: string[]): Observable<string> {
    const postBody = {
      subject,
      body,
      email,
    };
    return this.warpService._post('/api/sagesse/sendReportEmail/', postBody);
  }
}
