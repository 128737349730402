import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GraphApiService } from '@ripple/services';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';

@Component({
  selector: 'ripple-ms-team-file-explorer',
  templateUrl: './ms-team-file-explorer.component.html',
  styleUrls: ['./ms-team-file-explorer.component.css'],
})
export class MsTeamFileExplorerComponent implements OnInit {

  @Input() teamId: string;
  @Input() channelId: string;
  @Input() folderId: string;

  driveId: string;

  folderContents: any[] = [];
  loading = true;
  currentFolder: any;

  // Breadcrumb
  breadCrumbItems: MenuItem[] = [];

  @ViewChild('fileUpload') fileUpload: any;

  // folder dialog
  folderDialogShow = false;
  folderName = '';

  fileUploading = false;

  msgs: Message[];

  constructor(
    private graphApiService: GraphApiService,
    private confirmationService: ConfirmationService,
  ) { }

  ngOnInit(): void {
    this.getRootChildren();
  }

  getRootChildren() {
    this.loading = true;
    this.currentFolder = null; // root

    if (this.teamId && this.channelId) {
      // channel
      // get channel files folder
      this.graphApiService.getFilesFolderOfChannel(this.teamId, this.channelId).subscribe(async filesFolder => {
        this.driveId = filesFolder['parentReference']['driveId'];

        let itemId = filesFolder['id'];
        let itemName = filesFolder['name'];

        if (this.folderId) {
          const folder = await this.graphApiService.getDriveItem(this.driveId, this.folderId).toPromise();
          itemId = folder['id'];
          itemName = folder['name'];
        }

        this.currentFolder = { id: itemId, name: itemName };
        this.breadCrumbItems = [{ label: itemName, id: itemId, command: () => this.getRootChildren() }];

        this.graphApiService.getChildrenOfDriveItem(this.driveId, itemId).subscribe(children => {
          this.folderContents = children['value'];
          this.loading = false;
        });
        
      });
    } else if (this.teamId && !this.channelId) {
      // team
      // get team drive
      this.graphApiService.getGroupDrive(this.teamId).subscribe(drive => {
        this.driveId = drive['id'];
        this.breadCrumbItems = [{ label: 'Documents', id: this.teamId, command: () => this.getRootChildren() }];
        this.graphApiService.getChildrenOfDriveItem(this.driveId).subscribe(children => {
          this.folderContents = children['value'];
          this.loading = false;
        });
      })
    }
  }

  getItemChildren(item, updateBreadCrumbItems = true) {
    this.loading = true;
    this.currentFolder = item;
    if (updateBreadCrumbItems) this.breadCrumbItems = [...this.breadCrumbItems,
      { label: item.name, id: item.id, command: () => this.folderNodeSelect(item.id) }];
    this.graphApiService.getChildrenOfDriveItem(this.driveId, item.id).subscribe(result => {
      this.folderContents = result['value'];
      this.loading = false;
    });
  }

  folderNodeSelect(id) {
    const index = this.breadCrumbItems.findIndex(i => i.id === id);
    if (index >= 0) {
      const item = this.breadCrumbItems[index];
      this.breadCrumbItems = this.breadCrumbItems.slice(0, index);
      this.getItemChildren({ id: item.id, name: item.label });
    }
  }

  getLocalTime(timeString) {
    return new Date(timeString).toLocaleString();
  }

  uploadFile(event) {
    // for file < 4MB for now
    // for(let file of event.files) {
    //   this.graphApiService.uploadFile(file, this.driveId, this.currentFolder ? this.currentFolder.id : null).then(result => {
    //     this.refreshFolderContents();
    //     this.fileUpload.clear();
    //   });
    // }
    if (event.files && event.files.length > 0) {
      const file = event.files[0];
      this.fileUploading = true;
      this.graphApiService.getUploadSession(file, this.driveId, this.currentFolder ? this.currentFolder.id : null).subscribe(result => {
        this.graphApiService.uploadChunks(file, result['uploadUrl']).then(result => {
          this.refreshFolderContents();
          this.fileUpload.clear();
          this.fileUploading = false;
        });
      });
    }
   

  }

  addFolder() {
    this.folderDialogShow = false;
    this.graphApiService.addFolder(this.folderName, this.driveId, this.currentFolder ? this.currentFolder.id : null).subscribe(result => {
      const uploadUrl = result['uploadUrl'];
      this.refreshFolderContents();
      this.folderName = '';
    });
  }

  goToSharePoint(item) {
    window.open(item.webUrl, '_blank');
  }

  refreshFolderContents() {
    if (this.currentFolder) {
      this.getItemChildren(this.currentFolder, false);
    } else {
      this.getRootChildren();
    }
  }

  deleteItem(item) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${item.name}?`,
      header: 'Confirmation',
      icon: 'pi pi-check',
      accept: () => {
        this.graphApiService.deleteDriveItem(this.driveId, item.id).subscribe(result => {
          this.refreshFolderContents();
        },
        error => {
          console.log('error in deleting item', error);
          this.msgs = [
            {
              key: 'fileExplorer',
              severity: 'error',
              summary: 'Delete Failed',
              detail: error.error.error.message
            }
          ];
        });
      },
      reject: () => {
      }
    });
    
  }
}
