import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { AfterSaveEvent, BreadcrumbItem } from '@ripple/ui';
import { Contact, EntityTypes, OrganizationContact, TeamContact } from '../../_core/models';
import * as moment from 'moment';
import { WarpEntity, CustomFieldInModule, EntityFilter, IGenFormlyOptions } from '@ripple/models';
import { environment } from '@ripple/environment';
import { MenuItem, MessageService } from 'primeng/api';
import { ContactService, TeamContactService } from '../../_core/services';
import { first } from 'rxjs/operators';

@Component({
  selector: 'sagesse-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
  providers: [ MessageService ]
})
export class ContactDetailsComponent extends BasePage implements OnInit {

  @ViewChild('organizationList') organizationList;

  contact: Contact;
  contactService: ContactService;
  contactId = -1;

  guestPortalUrl = '';

  breadcrumbs:BreadcrumbItem[] = [
    { name: "Contacts",
      url: "/contact"
    }]


  // formly form
  fieldOrders = [
    'first name',
    'last name',
    'pronouns',
    'phonenumber',
    'email'
  ];
  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => !this.fieldOrders.includes(cfim.unchangeableName.toLowerCase()),
    styles: {
      'first name': 'p-sm-12 p-md-12 ui-fluid',
      'last name': 'p-sm-12 p-md-12 ui-fluid',
      pronouns: 'p-sm-12 p-md-12 ui-fluid',
      phonenumber: 'p-sm-12 p-md-12 ui-fluid',
      email: 'p-sm-12 p-md-12 ui-fluid'
    },
    groups: [
      {
        groupName: 'tab',
        groupType: 'tabs',
        groupClasses: 'ui-fluid d-none',
        groupFields: [],
        groupWrapper: [],
        groupWrapperData: {},
        groups: [
          {
            groupName: 'GeneralInfo',
            groupClasses: 'p-grid ui-fluid mt-1',
            groupFields: [ 'first name', 'last name', 'pronouns', 'phonenumber', 'email'],
            groupWrapper: [],
            groupWrapperData: {
              label: 'General Info',
              tabId: 'generalInfo',
              headerIcon: 'pi pi-info-circle'
            },
          },
          {
            groupName: 'Organizations',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Organizations',
              tabId: 'organizations',
              headerIcon: 'pi pi-sitemap'
            },
          },
          {
            groupName: 'Teams',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Teams',
              tabId: 'teams',
              headerIcon: 'pi pi-users'
            },
          },
          {
            groupName: 'Notes',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Notes',
              tabId: 'notes',
              headerIcon: 'pi pi-list'
            },
          },
        ]
      }
    ]
  };

  organizationContactService: WarpEntityServiceCache<OrganizationContact>;
  organizationContactFilter: EntityFilter = EntityFilter.None;

  // organization contact dialog
  contactDialogShow = false;
  organizationContactSelected: OrganizationContact;

  // teams
  teamContacts: TeamContact[] = [];
  teamContactsLoading = true;

  // channels
  channelListShow = false;
  teamContactSelected: TeamContact;

  emailExistsDialogShow = false;

  // custom saving buttons
  splitButtons: MenuItem[];
  saving = false;

  teamContactService: TeamContactService;

  tabIndex: number = 0;

  noteScrollTo: number = -1;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService,
    private messageService: MessageService,
    
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.contactService = this.serviceFactory.get(EntityTypes.Contact) as ContactService;
    this.teamContactService = this.serviceFactory.get(EntityTypes.TeamContact) as TeamContactService;
    this.organizationContactService = this.serviceFactory.get(EntityTypes.OrganizationContact) as WarpEntityServiceCache<OrganizationContact>;

    this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);

      if (isNaN(id)) {
        this.switchSplitButtons('add');
        this.contact = Contact.empty().property('datecreated', moment().format('YYYY-MM-DD'));
        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.switchSplitButtons('update');
        this.contactId = id;
        this.organizationContactFilter = EntityFilter.Advanced({ contact_lid: id});
        this.sub = this.contactService.get(id).subscribe((contact) => {
          this.contact = contact;
          this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));

          this.breadcrumbs = [
            { name: "Contacts", url: "/contact" }, 
            { name: this.contact?.firstname + ' ' + this.contact?.lastname }
          ];

          if (this.contact && this.contact.handofftoken) {
            this.guestPortalUrl = environment.host + '/guestPortal/' + this.contact.handofftoken + '/' + this.contact.entityId;
          }
        });
        this.teamContactService.getContactTeams(this.contactId).subscribe(teamContacts => {
          this.teamContacts = teamContacts;
          this.teamContactsLoading = false;
        });
      }
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') this.router.navigate([`../${e.entity.entityId}`], { relativeTo: this.route });
    else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = 'Contact Deleted Successfully!';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

  goToOrganization(organizationContact) {
    this.router.navigate([`/organization/${organizationContact.organization.id}`]);
  }

  editOrganizationContact = (orContact: OrganizationContact) => {
    this.organizationContactSelected = orContact;
    this.contactDialogShow = true;
  };

  addOrganizationContact = () => {
    this.organizationContactSelected = OrganizationContact.empty().linkedProperty('contact', this.contact.entityId);
    this.contactDialogShow = true;
  };

  copyGuestPortalUrl() {
    const urlTextElement = document.getElementById('guestPortalUrl') as HTMLInputElement;
    urlTextElement.select();
    urlTextElement.setSelectionRange(0, 99999);
    document.execCommand('copy');
    this.messageService.clear();
    this.messageService.add({
      key: 'bc',
      severity: 'success',
      summary: 'Copied!'
    });
  }

  goToTeam(teamContact: TeamContact) {
    const teamId = teamContact.team.id;
    this.router.navigate(['/team/' + teamId], { relativeTo: this.route });
  }

  switchSplitButtons(key) {
    switch (key) {
      case 'add':
        this.splitButtons = [
          {
            label: 'Add',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-plus',
            disabled: this.saving,
            command: () => this.save(),
          },
        ];
        break;
      case 'update':
        this.splitButtons = [
          {
            label: 'Save',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-check',
            disabled:
              this.saving,
            command: () => this.save(),
            items: [{ label: 'Delete', icon: 'pi pi-trash', disabled: this.saving, command: () => this.delete() }],
          },
        ];
        break;
    }
  }

  updateButtonState() {
    if (!this.contact) return;

    if (this.contact.entityId < 0) {
      this.switchSplitButtons('add');
    } else {
      this.switchSplitButtons('update');
    }
  }

  setSavingProcess(saving: boolean) {
    this.saving = saving;
    this.updateButtonState();
  }

  save(forceSave = false) {
    this.setSavingProcess(true);
    this.contactService.checkEmailExist(this.contact.entityId, this.contact.properties.email).subscribe(emailExist => {
      if (emailExist && this.contact.properties.email && !forceSave) {
        this.emailExistsDialogShow = true;
        this.setSavingProcess(false);
      } else {
        if (this.contact.entityId < 0) {
          this.contactService.create(this.contact).pipe(first())
            .toPromise()
            .then(insertId => {
              this.contact.entityId = insertId;
              if (forceSave) {
                this.contact.properties.email = this.contact.uniqueEmail;
                this.contactService.update(this.contact).pipe(first())
                  .toPromise()
                  .then(entity => {
                    this.contact.resetEmail();
                    this.setSavingProcess(false);
                    this.afterSave({
                      action: 'add',
                      entity: this.contact,
                    });
                  });
              } else {
                this.setSavingProcess(false);
                this.afterSave({
                  action: 'add',
                  entity: this.contact,
                });
              }           
            });
        } else {
          if (forceSave) this.contact.properties.email = this.contact.uniqueEmail;
          this.contactService.update(this.contact).pipe(first())
            .toPromise()
            .then(entity => {
              this.contact.resetEmail();
              this.setSavingProcess(false);
              this.afterSave({
                action: 'save',
                entity: this.contact,
              });
            });
        } 
      }
    });
  }
  delete() {
    this.contactService.delete(this.contact).then(result => {
      this.afterSave({
        action: 'delete',
        entity: this.contact
      });
    });
    
  }

  ifTabShow(tab) {
    if (this.contact && this.contact.entityId > 0) {
      return true;
    } else {
      if (tab.fieldGroupClassName && tab.fieldGroupClassName.includes('hidden-new-entity')) {
        return false;
      }
      return true;
    }
  }

  noteClick(event) {
    console.log(event, this.tabIndex);
    this.tabIndex = 3;
    this.noteScrollTo = event.entityId;
  }

}
