import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ripple-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {

  @Input() iconClass = 'pi pi-search';
  @Input() heading = 'Sorry, no items found.';

  constructor() { }

  ngOnInit(): void {
  }
}
