import { Pipe, PipeTransform } from '@angular/core';

type CFIMInput = { name?: string, label?: string, value?: string, optionName?: string, Text?: string };

@Pipe({
  name: 'cfim'
})
export class CFIMPipe implements PipeTransform {
  transform(value: CFIMInput | CFIMInput[]): string {
    if (!(value instanceof Array)) value = [value];
    return value.filter( _ => _ ).map(cfim => cfim.label || cfim.name || cfim.value || cfim.Text || cfim.optionName).join(', ');
  }
}
