<!----------------- AVATAR MODAL ------------------>
<p-dialog
  *ngIf="openAvatarModal"
  header="Avatar"
  [(visible)]="openAvatarModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{
    'min-width': '100vh',
    overflow: 'hidden'
  }"
>
  <div class="p-grid avatar-dialog">
    <!----------------- AVATAR ------------------>
    <img
      style="width: 250px; height: 250px"
      [src]="getAvatar()"
      alt="user icon"
      (click)="openAvatarModal = true"
    />
    <!----------------- UPLOAD AREA ------------------>
    <span style="float: right; margin: 1em">
      <p>Upload a custom avatar</p>
      <div
        ngfSelect
        [multiple]="false"
        type="file"
        [(files)]="files"
        accept="image/*"
        (filesChange)="uploadAvatar()"
        class="form-file-button"
      >
        Browse...
        <i class="pi pi-spin pi-spinner" *ngIf="uploadingAvatar"></i>
      </div>
    </span>
  </div>

  <p-footer>
    <button
      pButton
      label="Done"
      icon="pi pi-{{ uploadingAvatar ? 'spin pi-spinner' : 'check' }}"
      (click)="openAvatarModal = false"
    ></button>
  </p-footer>
</p-dialog>

<!----------------- SIGNATURE MODAL ------------------>
<p-dialog
  header="Edit Signature"
  [(visible)]="openSignatureModal"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '50vw', height: '20vw' }"
  [contentStyle]="{ width: '100%', height: '100%' }"
>
  <canvas-whiteboard
    #canvasWhiteboard
    [options]="canvasOptions"
    [saveDataButtonEnabled]="!viewSignatureOnly"
    (onBatchUpdate)="sendBatchUpdate($event)"
    (onClear)="onCanvasClear()"
    (onSave)="onSaveSignature($event)"
  >
  </canvas-whiteboard>
</p-dialog>

<!----------------- 2FA MODAL ------------------>
<p-dialog
  *ngIf="open2FAModal"
  header="Two-Factor Authentication"
  [(visible)]="open2FAModal"
  [modal]="true"
  [style]="{ width: '750px', minWidth: '750px' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  contentStyleClass="mfa-modal"
  [contentStyle]="{ 'font-size': 'medium' }"
>
  <ripple-mfa
    [setup]="mfa.setup$ | async"
    [mfaStatus]="mfa.status$ | async"
    (onConfirmClick)="closeMFA()"
    (onCancel)="cancelMFA()"
    (onSubmit)="setupMFA($event)"
  ></ripple-mfa>
</p-dialog>

<!----------------- SETTINGS AREA ------------------>
<ripple-loading-box [loading]="loading">
  <div class="p-grid">
    <div class="p-col-12 p-lg-8 p-lg-offset-2">
      <span *ngIf="user">
        <div class="p-d-flex p-mb-3">
          <img
            id="avatar"
            class="menu-avatar__image"
            [src]="getAvatar()"
            alt="user icon"
            (click)="openAvatarModal = true"
          />
        </div>
        <div class="p-grid">
          <!--------------- FIRST NAME FIELD --------------->
          <div class="p-col-12 p-lg-6">
            <label *ngIf="user['first name']" class="input-label"
              >First Name <em>*</em></label
            >
            <label *ngIf="!user['first name']" class="input-label">
              First Name <em>*</em> (<span class="error-text">Error:</span>
              first name required)</label
            >
            <input
              type="text"
              pInputText
              class="text-input"
              [(ngModel)]="user['first name']"
            />
          </div>
          <!--------------- LAST NAME FIELD --------------->
          <div class="p-col-12 p-lg-6">
            <label *ngIf="user['last name']" class="input-label"
              >Last Name <em>*</em></label
            >
            <label *ngIf="!user['last name']" class="input-label">
              Last Name <em>*</em> (<span class="error-text">Error:</span> last
              name required)</label
            >
            <input
              type="text"
              pInputText
              class="text-input"
              [(ngModel)]="user['last name']"
            />
          </div>
        </div>
        <!-- <div class="p-grid">
          <!--------------- PHONE NUMBER FIELD ->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">Phone Number</label>
            <input
              class="text-input"
              type="text"
              pInputText
              [(ngModel)]="user['telephone1']"
            />
          </div>
          <!--------------- CELL PHONE NUMBER FIELD ->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">Cell Phone Number</label>
            <input
              class="text-input"
              type="text"
              pInputText
              [(ngModel)]="user['cell phone']"
            />
          </div>
        </div> -->
        <div class="p-grid">
          <!--------------- EMAIL FIELD --------------->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">Email</label>
            <input
              class="text-input"
              type="text"
              pInputText
              [(ngModel)]="user['email']"
            />
          </div>
          <!---------------  BIRTH DATE FIELD --------------->
          <!-- <div class="p-col-12 p-lg-6">
            <label class="input-label">Birth Date</label>
            <p-calendar
              [(ngModel)]="birthDate"
              dateFormat="yy/mm/dd"
              [readonlyInput]="true"
              [touchUI]="touchUI"
              [monthNavigator]="true"
              [yearNavigator]="true"
              yearRange="{{ minYear + ':' + maxYear }}"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
            ></p-calendar>
          </div> -->
        </div>
        <div class="p-grid">
          <!------------- TITLE FIELD ----------->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">Additional Signature Data</label>
            <input
              class="text-input"
              type="text"
              pInputText
              [(ngModel)]="user['designationtitle']"
            />
          </div>
        </div>
        <div class="p-grid">
          <!------------- LICENSE NUMBER FIELD ----------->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">License Number</label>
            <input
              class="text-input"
              type="text"
              pInputText
              [(ngModel)]="user['licensenumber']"
            />
          </div>
        </div>
        <div class="p-grid">
          <!--------------- PASSWORD FIELD --------------->
          <div class="p-col-12 p-lg-6">
            <label id="password-label" *ngIf="passwordFail"
              >Password (<span class="error-text">Error:</span> Passwords dont
              match)</label
            >
            <label id="password-label" *ngIf="!passwordFail">Password</label>
            <input
              pPassword
              class="password"
              type="password"
              placeholder="Enter new password"
              [(ngModel)]="password"
            />
          </div>
          <!--------------- CONFIRM PASSWORD FIELD --------------->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">Confirm Password</label>
            <input
              pPassword
              class="password"
              type="password"
              placeholder="Confirm new password"
              [(ngModel)]="confirmPassword"
            />
          </div>
        </div>
        <div class="p-grid">
          <!--------------- PIN FIELD --------------->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">
              Handoff Pin
              <ng-container *ngIf="pinError"> (<span class="error-text">Error:</span> {{ pinError }})</ng-container>
            </label>
            <div class="ui-inputgroup" style="height:2.2em;">
              <input
                ripplePassword
                maxlength="16"
                mediumRegex="^[0-9]{5,7}"
                strongRegex="^[0-9]{8,}"
                id="pinInput"
                class="password"
                type="password"
                pattern="[0-9]*"
                inputmode="numeric"
                placeholder="Enter new pin"
                pKeyFilter="int"
                [(ngModel)]="pin"
                [showPassword]="showPin"
              />
              <button
                pButton
                type="button"
                [icon]="showPin ? 'pi pi-eye-slash' : 'pi pi-eye'"
                class="ui-button-primary password"
                (click)="showPin = !showPin">
              </button>
            </div>
          </div>
          <div class="p-col-12 p-lg-6">
            <label class="input-label">Notification Preferences</label>
            <div class="horizontal-checkbox">
              <div *ngFor="let item of notificationPreferences">
                <p-checkbox [value]="item" [label]="item.value" [(ngModel)]="selectedNotificationPreferences" [disabled]="isNotificationPreferenceDisabled(item)">
                </p-checkbox>
              </div>
            </div>
          </div>
          <!--------------- 2FA FIELD --------------->
          <div *ngIf="has2FA$ | async" class="p-col-12 p-lg-6 p-flex p-justify-between p-align-end">
            <label style="float: left">Two Factor Authentication</label>
            <p-inputSwitch
              style="float: right"
              [(ngModel)]="enable2FA"
            ></p-inputSwitch>
          </div>
        </div>
        <div class="p-grid" *ngIf="showSignature">
          <!--------------- SIGNATURE BUTTON --------------->
          <div class="p-col-12 p-lg-6">
            <label class="input-label">Signature</label>
            <button
              pButton
              id="signature"
              label="Signature"
              icon="pi pi-pencil"
              (click)="openSignatureDialog()"
            ></button>
          </div>
          <!--------------- AUTOFILL TOGGLE --------------->
          <div class="p-col-12 p-lg-6 p-flex p-justify-between p-align-end" >
            <label style="float: left">Autofill Signature - <span *ngIf="user['autofillsignature']">On</span><span *ngIf="!user['autofillsignature']">Off</span></label>
            <p-inputSwitch
              [disabled]="isSignatureEmpty"
              style="float: right"
              [(ngModel)]="user['autofillsignature']"
            ></p-inputSwitch>
          </div>
        </div>
        <div class="p-grid" *ngIf="!disableSave">
          <div class="p-col-12">
            <div
              class="button-nav button-nav--well button-nav--justify-end overflow-visible"
            >
              <button
                pButton
                label="Save"
                icon="pi pi-{{ saving ? 'spin pi-spinner' : 'check' }}"
                [disabled]="saveDisabled"
                (click)="save()"
              ></button>
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
</ripple-loading-box>
