import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../general/auth.service';
import { filter, map, take } from 'rxjs/operators';
import { WarpEntity } from '@ripple/models';

/*
for adding page to permission system:

INSERT INTO [dbo].[SecurityPages] (
  [PageUrl],
  [SubscriberID],
  [PageTitle],
  [ShowInNavigation],
  [NavigationSectionID]
)
VALUES
  (
    '~/CCASADashboard/path/to/page',
    120,
    'Page Name',
    '1',
    38
  );

*/

@Injectable({
  providedIn: 'root'
})
export class RipplePageGuard implements CanActivate {

  constructor(private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getLoggedInUser()
      .pipe(
        take(1),
        map(currentUser => !!currentUser)
      );
  }

  async show(menuItem: MenuItem | string ): Promise<boolean> {
    const url = typeof menuItem === 'string' ? menuItem :
      (menuItem.routerLink instanceof Array ? menuItem.routerLink[0] : menuItem.routerLink);
    if (typeof menuItem !== 'string' && menuItem.routerLinkActiveOptions && menuItem.routerLinkActiveOptions.alwaysShown)
      return true;
    return this._checkUserRolesForPermission(url);
  }

  private async _checkUserRolesForPermission(url: string): Promise<boolean> {
    try {
      const user: WarpEntity = await this.authService.getLoggedInUser()
        .pipe(
          filter(u => !!u),
          take(1)
        ).toPromise();
      if (user == null) return false;
      const thisSecurityPage = (user.securityPages || []).find(page => (page.url as string || '').endsWith(url));
      return !!thisSecurityPage;
      // either do a quick api call to ripple for this user and page,
      // or grab all of the roles security page info and cache it
      // user.roles // has the names of each role sor this usr
    } catch (err) {
      return false;
    }
  }
}
