import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class Statistic extends WarpEntity {
  id: string | number;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.Statistic;
  }

  static empty(): Statistic {
    return new Statistic(super.emptyEntity(EntityTypes.Statistic));
  }

}
