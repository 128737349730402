import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { CustomFieldInModule, EntityFilter, IGenFormlyOptions, WarpEntity } from '@ripple/models';
import { WarpEntityServiceCache, WarpEntityLogService, WarpEntityCacheFactoryService, GraphApiService, ExportService } from '@ripple/services';
import { AfterSaveEvent, BreadcrumbItem, EntityDetailsComponent } from '@ripple/ui';
import { ConfirmationService, MenuItem, Message } from 'primeng/api';
import { first } from 'rxjs/operators';
import { environment } from '@ripple/environment';
import { Team, EntityTypes, Channel, ChannelMember, TeamContact, ProfileType } from '../../../_core/models';
import { ChannelMemberService, ChannelService, TeamService } from '../../../_core/services';
import * as moment from 'moment';

@Component({
  selector: 'sagesse-channel-details',
  templateUrl: './channel-details.component.html',
  styleUrls: ['./channel-details.component.scss']
})
export class ChannelDetailsComponent extends BasePage implements OnInit {

  channel: Channel;
  channelService: ChannelService;
  channelMemberService: ChannelMemberService;

  breadcrumbs:BreadcrumbItem[] = [
    { name: "Teams",
      url: "/team"
    }]


  fieldOrders = [
    'name',
    'launchdate',
    'teamstatus',
    'teamtype',
    'profiletype'
  ];

  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => !this.fieldOrders.includes(cfim.unchangeableName.toLowerCase()),
    styles: {
      name: 'p-sm-12 p-md-12 ui-fluid',
      teamstatus: 'p-sm-12 p-md-12 ui-fluid',
      profiletype: 'p-sm-12 p-md-12 ui-fluid',
      teamtype: 'p-sm-12 p-md-12 ui-fluid',
      launchdate: 'p-sm-12 p-md-12 ui-fluid'
    },
    groups: [
      {
        groupName: 'tab',
        groupType: 'tabs',
        groupClasses: 'ui-fluid d-none',
        groupFields: [],
        groupWrapper: [],
        groupWrapperData: {},
        groups: [
          {
            groupName: 'GeneralInfo',
            groupClasses: 'p-grid ui-fluid mt-1',
            groupFields: ['name', 'launchdate', 'teamstatus', 'teamtype', 'profiletype'],
            groupWrapper: [],
            groupWrapperData: {
              label: 'General Info',
              tabId: 'generalInfo',
              headerIcon: 'pi pi-info-circle'
            },
          },
          {
            groupName: 'Members',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity', // hidden-public-channel
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Members',
              tabId: 'members',
              headerIcon: 'pi pi-sitemap'
            },
          },
          {
            groupName: 'Folders/Files',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Folders/Files',
              tabId: 'folders/files',
              headerIcon: 'pi pi-folder'
            },
          }
        ]
      }
    ],
    expressionProperties: (cfim) => {
      if (
        cfim.key.toLowerCase() === 'profiletype' ||
        cfim.key.toLowerCase() === 'name'
      ) {
        return {
          'templateOptions.disabled': (model, formState, field?) => {
            return this.channel && this.channel.entityId > 0;
          },
        };
      } else return {};
    },
  };

  // team
  team: Team;

  // channel member
  teamContactService: WarpEntityServiceCache<TeamContact>;
  teamContactFilter: EntityFilter = EntityFilter.None;
  teamContacts: TeamContact[] = [];
  teamContactsFiltered: TeamContact[] = [];
  channelMemberFilter: EntityFilter = EntityFilter.None;
  channelMembers: ChannelMember[] = [];
  teamContactIdsSelected: number[] = [];
  teamContactSelected: TeamContact;
  channelMemberSelected: ChannelMember;
  teamContactFilterTimeout;
  contactSearch = '';

  addingOrDeletingDialogShow = false;
  addingOrDeleting: 'Adding' | 'Deleting';

  memberdialogShow = false;

  msTeamLink = '';
  sharePointLink = '';

  isExporting = false;

  teamContactLoading = true;
  channelMemberLoading = true;

  tabBlocked = false;
  @ViewChild('ed') ed: EntityDetailsComponent;

  hasPermission = true;
  msgs: Message[] = [{severity: 'error', summary: 'You do not have permission to view this channel. ' }];

  duplicateShow = false;

  teamService: TeamService;

  get ifPublic() {
    return this.channel &&
      (this.channel.profiletype && this.channel.profiletype.id === ProfileType.PUBLIC ||
        this.channel.properties.profiletype &&
        this.channel.properties.profiletype[0] && this.channel.properties.profiletype[0].id === ProfileType.PUBLIC);
  }

  // custom saving buttons
  splitButtons: MenuItem[];
  saving = false;

  channelLimitDialogShow = false;

  get nextHour() {
    const retVal = moment().add(1, 'hours').add(5, 'minutes');
    return retVal.startOf('hour').toDate();
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService,
    private confirmationService: ConfirmationService,
    private graphApiService: GraphApiService,
    private exportService: ExportService
  ) {
    super(logService);
    this.teamContactService = this.serviceFactory.get(EntityTypes.TeamContact) as WarpEntityServiceCache<TeamContact>;
    this.channelService = this.serviceFactory.get(EntityTypes.Channel) as ChannelService;
    this.channelMemberService = this.serviceFactory.get(EntityTypes.ChannelMember) as ChannelMemberService;
    this.teamService = this.serviceFactory.get(EntityTypes.Team) as TeamService;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = parseInt(params.channelId, 10);
      const teamId = parseInt(params.teamId, 10);
      if (isNaN(id)) {
        this.switchSplitButtons('add');
        this.channel = Channel.empty();
        if (!isNaN(teamId)) {
          this.channel.linkedProperty('team', teamId);
        }

        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.switchSplitButtons('update');
        this.channelService.checkExisting(id).then(result => {
          if (!result) this.hasPermission = false;
          else {
            this.sub = this.channelService.get(id).subscribe((channel) => {
              this.channel = channel;
              console.log('get channel', this.channel);
              this.generateBreadcrumb();
              this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
              this.getTeamLink();
            });

            this.channelMemberFilter = EntityFilter.Advanced({ channel_lid: id });
            this.nextSub = this.channelMemberService.initQuery(this.channelMemberFilter, 9999999).getPage(0).subscribe(channelMembers => {
              this.channelMembers = channelMembers;
              this.teamContactIdsSelected = this.channelMembers.map(cm => cm.teamcontact.id);
              this.filterContacts(true);
              this.channelMemberLoading = false; 
            });

            if (!isNaN(teamId)) {
              this.teamContactFilter = EntityFilter.Advanced({ team_lid: teamId });
            }
            this.nextSub = this.teamContactService.initQuery(this.teamContactFilter, 9999999).getPage(0).subscribe(teamContacts => {
              this.teamContacts = teamContacts;
              this.filterContacts(true);
              this.teamContactLoading = false;
            });
          }
        }).catch(error => {
          this.hasPermission = false;
        });

      }

      // get team if team id is a number
      if (!isNaN(teamId)) {
        this.teamService.get(teamId).subscribe(team => {
          this.team = team;
          this.generateBreadcrumb();
          this.getTeamLink();
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.ed) {
      this.ed.saving.subscribe(saving => {
        this.tabBlocked = saving;
      });
    }
  }

  generateBreadcrumb() {
    if (this.team && this.channel) {
      this.breadcrumbs = [
        { name: "Teams", url: "/team" },
        { name: this.team?.name, url: '/team/' + this.team?.id },
        { name: this.channel?.name }
      ];
    }
  }

  getTeamLink() {
    if (this.channel && this.team && this.channel.azureadobjectid && this.team.azureadobjectid) {
      this.graphApiService.getChannel(this.team.azureadobjectid, this.channel.azureadobjectid).subscribe(adChannel => {
        console.log('Get channel from ad', adChannel);
        this.msTeamLink = adChannel['webUrl'];
      });
      this.graphApiService.getFilesFolderOfChannel(this.team.azureadobjectid, this.channel.azureadobjectid).subscribe(filesFolder => {
        console.log('Get channel files folder containing sharepoint link', filesFolder);
        this.sharePointLink = filesFolder['webUrl'];
      })
    }
  }

  

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') {
      const queryParams = {};
      queryParams['msg'] = 'Channel Created Successfully! It may take a few minutes to show up here. ';
      this.router.navigate(['../../'], { relativeTo: this.route, queryParams });
    } else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = 'Channel Deleted Successfully!';
      this.router.navigate(['../../'], { relativeTo: this.route, queryParams });
    }
  }

  ifTabShow(tab) {
    if (this.channel && this.channel.entityId > 0) {
      if (this.channel.profiletype.id === ProfileType.PUBLIC &&
          tab.fieldGroupClassName && tab.fieldGroupClassName.includes('hidden-public-channel')) {
        return false;
      }
      return true;
    } else {
      if (tab.fieldGroupClassName && tab.fieldGroupClassName.includes('hidden-new-entity')) {
        return false;
      }
      return true;
    }
  }

  addOrRemoveContact(teamContact, event) {
    let message = '';
    if (event.checked) {
      message = 'Are you sure you want to add this member to the channel?';
      this.channelMemberSelected = ChannelMember.empty()
        .linkedProperty('teamcontact', teamContact.entityId)
        .linkedProperty('channel', this.channel.entityId);
    }else {
      this.channelMemberSelected = this.findChannelMemberFromTeamContact(teamContact);
      message = 'Are you sure you want to remove this member from the channel?';
    }
    this.confirmationService.confirm({
      message,
      header: 'Confirmation',
      icon: 'pi pi-check',
      accept: () => {
        if (event.checked) {          
          this.addingOrDeleting = 'Adding';
          this.addingOrDeletingDialogShow = true;
          this.channelMemberService.checkChannelMemberExist(this.channel.entityId, teamContact.entityId).subscribe(result => {
            if (result) {
               // is creating duplicate team contact
               this.teamContactIdsSelected = this.teamContactIdsSelected.filter(tc => tc !== teamContact.entityId);
              this.duplicateShow = true;
              this.addingOrDeletingDialogShow = false;
            } else {
              this.channelMemberService.create(this.channelMemberSelected).pipe(first())
                .toPromise()
                .then(insertId => {
                this.channelMemberSelected.entityId = insertId;
                this.addingOrDeletingDialogShow = false;
              });
            }
          });
        } else {
          this.addingOrDeleting = 'Deleting';
          this.addingOrDeletingDialogShow = true;
          this.channelMemberService.delete(this.channelMemberSelected).then(result => {
            this.addingOrDeletingDialogShow = false;
          });
        }
      },
      reject: () => {
        this.teamContactIdsSelected = this.teamContactIdsSelected.filter(tc => tc !== teamContact.entityId);
      }
    });
  }

  sortTeamContacts() {
    this.teamContactsFiltered.sort((a, b) => {
      // check if the team contact is checked
      const aChecked = this.teamContactIdsSelected.find(id => id === a.entityId) ? 1 : 0;
      const bChecked = this.teamContactIdsSelected.find(id => id === b.entityId) ? 1 : 0;
      if (aChecked !== bChecked) return bChecked - aChecked;

      if (aChecked === 1 && bChecked === 1) {
        const aRole = this.getRole(a) ? 1 : 0;
        const bRole = this.getRole(b) ? 1 : 0;
        if (aRole !== bRole) return bRole - aRole;
      }

      // if both team contacts are checked or unchecked, do name compoare
      return a.displayName.localeCompare(b.displayName);
    });
  }

  filterContacts(now: boolean = false) {
    if (this.teamContactFilterTimeout) clearTimeout(this.teamContactFilterTimeout);
    if (now) {
      this.filterContactsInternal(this);
    } else {
      const that = this;

      this.teamContactFilterTimeout = setTimeout(
        () => {
          that.filterContactsInternal(that);
        },
        800
      );
    }
  }

  filterContactsInternal(that) {
    const searchValue = that.contactSearch.toLowerCase();
    that.teamContactsFiltered = that.teamContacts.filter(tc => 
      tc.displayName && tc.displayName.toLowerCase().includes(searchValue) ||
      tc.email && tc.email.toLowerCase().includes(searchValue) ||
      tc.phoneNumber && tc.phoneNumber.toLowerCase().includes(searchValue)
    );
    that.sortTeamContacts();
  }

  openChannelMemberDetails(teamContact) {
    this.channelMemberSelected = this.findChannelMemberFromTeamContact(teamContact);
    if (this.channelMemberSelected) {
      this.teamContactSelected = teamContact;
      this.memberdialogShow = true;
    }
  }

  findChannelMemberFromTeamContact(teamContact) {
    return this.channelMembers
      .find(cm => cm.teamcontact.id === teamContact.entityId);
  }

  getRole(teamContact) {
    const channelMember = this.findChannelMemberFromTeamContact(teamContact);
    if (channelMember) {
      return channelMember.teamcontactroletype ? channelMember.teamcontactroletype.name : '';
    }
    return '';
  }

  goToExternalLink(link) {
    window.open(link, '_blank');
  }

  exportReport() {
    this.isExporting = true;
    this.teamService.getTeamReport(this.team.entityId).subscribe(result => {
      console.log('Report result', result);
      const json = result.map(t => {
        let retVal = {};
        retVal['First Name'] = t.teamContact.firstName;
        retVal['Last Name'] = t.teamContact.lastName;
        retVal['Email'] = t.teamContact.email;
        retVal['Phone Number'] = t.teamContact.phoneNumber;
        retVal['Organization'] = t.organizations;
        delete t['teamContact'];
        delete t['organizations'];
        retVal[this.channel.name] = t[this.channel.name];
        return retVal;
      })
      .filter(r => r[this.channel.name]);
      
      console.log('Report result json', json);
      let fileName = this.channel.name;
      while(fileName.includes(' ')) {
        fileName = fileName.replace(' ', '_');
      }
      fileName = fileName + '_export';
      this.exportService.exportXLSX(json, fileName);
      this.isExporting = false;
    });
  }

  switchSplitButtons(key) {
    switch (key) {
      case 'add':
        this.splitButtons = [
          {
            label: 'Add',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-plus',
            disabled: this.saving,
            command: () => this.save(),
          },
        ];
        break;
      case 'update':
        this.splitButtons = [
          {
            label: 'Save',
            icon: this.saving ? 'pi pi-spinner pi-spin' : 'pi pi-check',
            disabled: this.saving,
            command: () => this.save(),
            items: [{ label: 'Delete', icon: 'pi pi-trash', disabled: this.saving, command: () => this.delete() }],
          },
        ];
        break;
    }
  }

  updateButtonState() {
    if (!this.channel) return;

    if (this.channel.entityId < 0) {
      this.switchSplitButtons('add');
    } else {
      this.switchSplitButtons('update');
    }
  }

  setSavingProcess(saving: boolean) {
    this.saving = saving;
    this.updateButtonState();
  }

  save() {
    // check validation
    if (this.ed && !this.ed.canSaveChanges.value || this.saving || this.ed && !this.ed.isValid) {
      return false;
    }
    this.setSavingProcess(true);
    if (this.channel.entityId < 0) {
      this.channelService.checkChannelExceed(this.team.entityId, this.channel.properties.profiletype[0]?.id).subscribe(result => {
        console.log('check if the number of channel has exceeded', result);
        if (result) {
          this.setSavingProcess(false);
          this.channelLimitDialogShow = true;
        } else {
          this.channelService.create(this.channel)
            .toPromise()
            .then(insertId => {
              console.log('created', insertId);
              this.channel.entityId = insertId;
              this.setSavingProcess(false);
              this.afterSave({
                action: 'add',
                entity: this.channel,
              });      
            });
        }
      });
    } else {
      this.channelService.update(this.channel).pipe(first())
        .toPromise()
        .then(entity => {
          this.setSavingProcess(false);
          this.afterSave({
            action: 'save',
            entity: this.channel,
          });
        });
    } 
    
  }
  delete() {
    this.channelService.delete(this.channel).then(result => {
      this.afterSave({
        action: 'delete',
        entity: this.channel
      });
    });
    
  }

}
