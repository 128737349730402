import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ripple-new-item-card',
  templateUrl: './new-item-card.component.html',
  styleUrls: ['./new-item-card.component.scss']
})
export class NewItemCardComponent implements OnInit {

  @Input() iconClass: string;
  @Input() label: string;

  constructor() { }

  ngOnInit(): void {
  }

}
