import { Component, Input, OnInit } from '@angular/core';
import { WarpEntityLogService } from '@ripple/services';
import { BasePage } from '@ripple/core';

@Component({
  selector: 'ripple-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss'],
})
export class FooterBarComponent extends BasePage implements OnInit {

  get componentName(): string {
    return 'FooterBarComponent';
  }

  @Input() copyrightName = 'Ripple Group Inc.';
  @Input() year = new Date().getFullYear();

  constructor(logService: WarpEntityLogService) {
    super(logService);
  }

  ngOnInit(): void {}

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }
}
