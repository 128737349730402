import { Component, Input, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';

@Component({
    selector: 'ripple-progress-spinner',
    template: `
        <div class="ripple-progress-spinner" [ngStyle]="style" [ngClass]="styleClass"  role="alert" aria-busy="true">
            <label class="ripple-progress-spinner-label" *ngIf="label" [innerHTML]="label"></label>
            <svg class="ripple-progress-spinner-svg" viewBox="0 0 100 100">
              <path #svgPath [attr.fill]="fill" [attr.stroke-width]="strokeWidth" class="ripple-progress-spinner-path" />
            </svg>
        </div>
    `,
    styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent implements OnChanges {
    @Input() style;
    @Input() label: string;
    @Input() styleClass: string;

    @Input() strokeWidth = '0.5rem';
    @Input() fill = 'none';

    @Input() value: number;
    @ViewChild('svgPath', { static: true }) svgPath: ElementRef;

    ngOnChanges(changes: SimpleChanges): void {
      if (changes.value)
        this.svgPath.nativeElement.setAttribute('d', this.describeArc(50, 50, 45, 0, this.value || 0));
    }

    // thanks to https://stackoverflow.com/questions/5736398/how-to-calculate-the-svg-path-for-an-arc-of-a-circle
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
      return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
      };
    }

    describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
      const start = this.polarToCartesian(x, y, radius, endAngle);
      const end = this.polarToCartesian(x, y, radius, startAngle);

      let largeArcFlag = '0';
      if (endAngle >= startAngle)
        largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
      else
        largeArcFlag = (endAngle + 360.0) - startAngle <= 180 ? '0' : '1';

      const d = [
          'M', start.x, start.y,
          'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      ].join(' ');

      return d;
    }
}
