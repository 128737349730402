import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ccasa-stat-card',
  templateUrl: './stat-card.component.html',
  styleUrls: ['./stat-card.component.scss']
})
export class StatCardComponent implements OnInit {

    @Input() label: string;
    @Input() value: string;
    @Input() icon: string;

  constructor() { }

  ngOnInit(): void {
  }

}
