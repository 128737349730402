import { Injectable } from '@angular/core';
import { AuthService, MessageService } from './../general';
import { BehaviorSubject, Subscription } from 'rxjs';
import { HubService } from 'ngx-signalr-hubservice';
import { environment } from '@ripple/environment';
import { WarpEntity } from '@ripple/models';

export enum SignalRState {
  initializing,
  reconnecting,
  connected,
  disconnected,
}

@Injectable({
  providedIn: 'root'
})

export class HubConnectionService {
  public connectionChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signalrReconnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signalrDisconnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signalrConnectionProblem: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public signalrState: BehaviorSubject<SignalRState> = new BehaviorSubject<SignalRState>(SignalRState.initializing);

  signalRConnectionState = SignalRState.disconnected;
  consecutiveDisconnectMessages = 0;

  timeDisconnected = Date.now();
  count = 0;
  public pingConnectionProblem = false;

  public needReconnectWhenDisconnect = false;

  private _hubService: HubService;

  signalrSubscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    public msg: MessageService
  ) {
    this._hubService = new HubService();
    this.connectToHubService().then(success => {
      this.authService.loggedIn.subscribe(loggedInState => {
        if (loggedInState) {
          this.needReconnectWhenDisconnect = true;
          this.reconnectToHubService().then(result => { 
            
          })
            .catch(err => {
              this.log('Error Reconnecting!', MessageService.ERROR, err);
            });
        }
         
        else {
          this.log('Disconnecting...');
          this.needReconnectWhenDisconnect = false;
          this.disconnectFromHubService().then(result => { this.connectionChanged.next(false); })
            .catch(err => {
              this.log('Error Disconnecting!', MessageService.ERROR, err);
            });
        }
      });
    });
  }

  async reconnectToHubService(): Promise<boolean> {
    await this.disconnectFromHubService();

    let tempSub: Subscription = null;
    tempSub = this.getHubService().onConnected(s => {
        this.log('SignalR Connected', s);
        this.signalRConnectionState = SignalRState.connected;
        // this.signalrState.next(this.signalRConnectionState);
        this.connectionChanged.next(true);
        this.signalrConnectionProblem.next(false);
        this.consecutiveDisconnectMessages = 0;

    });
    this.signalrSubscriptions.push(tempSub);
    tempSub = this.getHubService().onDisconnected(s => {
        this.log('SignalR Disconnected', s, this.consecutiveDisconnectMessages);
        this.signalRConnectionState = SignalRState.disconnected;
        // this.signalrState.next(this.signalRConnectionState);
        this.timeDisconnected = Date.now();
        // this.signalrDisconnected.next(true);
        // if (this.consecutiveDisconnectMessages > 0 && this.consecutiveDisconnectMessages % 5 === 0) {
          this.signalrConnectionProblem.next(this.needReconnectWhenDisconnect);
        // }
        this.consecutiveDisconnectMessages++;
    });
    this.signalrSubscriptions.push(tempSub);
    tempSub = this.getHubService().onReconnecting(s => {
        this.log('SignalR Trying to Reconnect automatically', s);
        this.signalRConnectionState = SignalRState.reconnecting;
        // this.signalrState.next(this.signalRConnectionState);
    });
    this.signalrSubscriptions.push(tempSub);
    tempSub = this.getHubService().onReconnected(s => {
        this.log('!!!! SignalR Reconnected !!!!', s);
        this.signalRConnectionState = SignalRState.connected;
        // this.signalrState.next(this.signalRConnectionState);
        this.consecutiveDisconnectMessages = 0;
        this.pingConnectionProblem = false;
        this.signalrConnectionProblem.next(false);
        this.signalrReconnected.next(true);
    });
    this.signalrSubscriptions.push(tempSub);

    return this._hubService.connect({ url: environment.hubUrl, attemptReconnects: true }).toPromise();
  }

  async connectToHubService(): Promise<boolean> {
    return this._hubService.connect({ url: environment.hubUrl, attemptReconnects: true }).toPromise();
  }

  async disconnectFromHubService() {
    if (this._hubService && this._hubService.connected)
      await this._hubService.connection.stop();

    this.signalrSubscriptions.forEach(sub => sub.unsubscribe());
    if (this._hubService) {
      this._hubService = null;
      this._hubService = new HubService();
    }
  }

  getHubService() {
    return this._hubService;
  }

  getHubServiceStatusConnected(): boolean {
    return this._hubService && this._hubService.connected;
  }

  // tslint:disable-next-line: no-any
  getHubServiceConnection(): any {
    return this._hubService.connection;
  }

  AlertPingProblem(error) {
    this.pingConnectionProblem = error;
    if (error) {
        this.log('Ping Problem!!!');
        this.signalrConnectionProblem.next(true);
    }
  }

  private log(...args) {
    // this.msg.add('Hub Connection Service', ...args);
    console.log('Hub Connection Service', ...args);
  }
}
