<p>report-sections works!</p>


<!-- <div *ngIf="questions!.length>0">
    <h3 class="ticket-section-header">{{title}}</h3>
    <div *ngFor="let question of questions; index as i;">
        <app-report-questions [inQuestion]="question"  [required]="required"
            *ngIf="question.isCheckbox" (filterTicket)="filterLocal($event)"></app-report-questions>
        <app-report-questions [inQuestion]="question"  [required]="required"
            *ngIf="question.isRadio" (filterTicket)="filterLocal($event)"></app-report-questions>
        <app-report-questions [inQuestion]="question" [required]="required"
            *ngIf="question.isText"></app-report-questions>
    </div>
</div> -->