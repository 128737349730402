<div class="p-grid mx-3 my-3 w-100">
    <div class="p-col-4 p-d-flex p-align-center">
        <a href="javascript:;" (click)="goToOrganization(organizationContact)">
            {{organizationContact.organization.name}}
        </a> 
    </div>
    <div class="p-col-4 p-d-flex p-align-center" *ngIf="organizationContact && organizationContact.organizationEntity && organizationContact.organizationEntity.teams">
        <div>
            <a *ngFor="let team of organizationContact.organizationEntity.teams" href="/#/team/{{team.id}}">
                <span class="badge rounded-pill badge--white mr-1 mb-1">
                    {{ team?.name?.length > 20 ? team.name.substring(0, 20) + '...' : team.name }}
                </span>
            </a>
        </div>
    </div>
    <div class="p-col-4">
        <div>
            <i class="pi pi-user"></i> {{organizationContact.generateRoleString()}}
        </div>
        <div>
            <a href="mailto:{{organizationContact.getEmail()}}">
                <i class="pi pi-envelope"></i> {{organizationContact.getEmail()}}
            </a>
        </div>
        <div>
            <a href="tel:{{organizationContact.getPhoneNumber()}}">
                <i class="fas fa-phone"></i> {{organizationContact.getPhoneNumber()}}
            </a>
        </div>
    </div>
</div>
