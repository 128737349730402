<div class="pane">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
        <h1 class="header__heading">
            Form Builder
        </h1>
        <div class="header__actions">
            
        </div>
        </div>
    </div>
    <div class="pane__body">
        <div class="filter-container">
            <p-accordion>
                <p-accordionTab header="Filter">
                    <div class="ui-fluid p-grid">
                        <div class="ui-fluid p-sm-12 p-md-6">
                            <label>ID</label>
                            <input pInputText type="text" pKeyFilter="int"
                                (input)="dt.filter($event.target.value, 'id', 'contains')" placeholder="Search by ID"
                                class="ui-column-filter">
                        </div>
                        <div class="ui-fluid p-sm-12 p-md-6">
                            <label>Name</label>
                            <input pInputText type="text"
                                (input)="dt.filter($event.target.value, 'name', 'contains')" placeholder="Search by Name"
                                class="ui-column-filter">
                        </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>

        <div class="generic-report-button-container">
            <button pButton type="button" icon="pi pi-plus" label="Add New Form" class="ui-button-secondary" routerLink="add"></button>
        </div>

        <p-table #dt [value]="forms" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
            [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [filterDelay]="0">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">ID <p-sortIcon field="id"></p-sortIcon></th>
                    <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
                    <th class="actions-col"></th>
                    <th class="actions-col"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-form>
                <tr class="ui-selectable-row">
                    <td>{{form.id}}</td>
                    <td>{{form.name}}</td>
                    <td class="actions-col">
                        <p-splitButton
                            [model]="form.actionMenu"
                            label="Edit"
                            icon="pi pi-pencil"
                            (onClick)="edit(form.id)"
                            appendTo="body"></p-splitButton>
                    </td>
                    <td class="actions-col">
                        <button pButton label="Report" icon="pi pi-chart-bar" (click)="goToReport(form.id)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="3" class="empty-message-col">No Forms found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

