<div class="metric">
  <h3 class="metric__heading">{{ title }}</h3>
  <div class="metric__main-val">{{ prefix }}{{ value }}{{ postfix }}</div>
  <div class="metric__stats">
    <div class="metric__stat metric__stat--left">
      <ng-container *ngIf="changeInternal != 0">
        <div
          *ngIf="increase"
          [ngClass]="{
            metric__value: true,
            'metric__value--bad': !moreIsGood,
            'metric__value--good': moreIsGood
          }"
        >
          <i class="pi pi-arrow-up"></i>
          {{ changeInternal.toFixed(1) }}%
        </div>
        <div
          *ngIf="!increase"
          [ngClass]="{
            metric__value: true,
            'metric__value--bad': moreIsGood,
            'metric__value--good': !moreIsGood
          }"
        >
          <i class="pi pi-arrow-down"></i>
          {{ changeInternal.toFixed(1) }}%
        </div>
        <label class="metric__label">change</label>
      </ng-container>
    </div>
    <div class="metric__stat metric__stat--right">
      <div class="metric__value">{{ prefix }}{{ previous }}{{ postfix }}</div>
      <label class="metric__label">prev. month</label>
    </div>
  </div>
</div>
