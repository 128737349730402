import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  OnDestroy,
  HostListener,
  ChangeDetectorRef,
  QueryList,
  ViewChildren,
  HostBinding
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyForm } from '@ngx-formly/core';
import {
  WarpEntity,
  WarpEntityType,
  IGenFormlyOptions,
  KEY_CODE,
  IGenFormlyGroupSetting,
  IGenericObject,
  FormGroupContainer,
} from '@ripple/models';
import {
  LocalEntityBackupService,
  WarpEntityCacheFactoryService,
  WarpEntityServiceCache,
  EntityFocusService,
  MessageService,
  AuthService,
  FocusSession,
  InternalCookieService,
} from '@ripple/services';
import { first, filter, debounceTime, throttleTime } from 'rxjs/operators';
import {
  Subscription,
  Observable,
  BehaviorSubject,
  merge,
  Subject,
  ReplaySubject,
  combineLatest,
} from 'rxjs';
import { MenuItem, ConfirmationService } from 'primeng/api';
import { singularize } from 'inflect';
import { Guid } from 'guid-typescript';
import { FormBuilderFormlyFormOptions } from '@ripple/formbuilder';

export type ButtonActions =
  | 'revert'
  | 'save'
  | 'add'
  | 'edit'
  | 'delete'
  | 'destroy'
  | 'archive';
export type AfterSaveFailure = 'aborted' | 'failed';

export interface AfterSaveEvent {
  action: ButtonActions | AfterSaveFailure;
  entity: WarpEntity;
}

@Component({
  selector: 'ripple-entity-details',
  templateUrl: './entity-details.component.html',
  styleUrls: ['./entity-details.component.scss'],
})
export class EntityDetailsComponent implements OnChanges, OnDestroy, FormGroupContainer {
  loaded = false;
  switchingEntity = false;
  failed = false;
  triedInit = false;
  generatingFields = false;

  // unique id for this component
  componentGuid = Guid.create().toString();

  changes = new BehaviorSubject<boolean>(false);
  private changeSub: Subscription;
  group = new FormGroup({ });

  public get formGroup() { return this.group; }

  fields: FormlyFieldConfig[] = [
    {
      fieldGroupClassName: 'p-grid ui-fluid',
      fieldGroup: [],
    },
  ];

  @HostBinding('class.touched') get touched() { return this.group.touched; }
  @HostBinding('class.pristine') get pristine() { return this.group.pristine; }
  @HostBinding('class.dirty') get dirty() { return this.group.dirty; }

  @Input() set showChanges(value: boolean) {
    this.options.formState.showChanges = value;
  }

  options: FormBuilderFormlyFormOptions = {
    formState: {
      entityType: new BehaviorSubject<WarpEntityType>(null),
      formDataEntity: new BehaviorSubject<WarpEntity>(null),
      showChanges: false,
    }
  };

  // TODO: Make this private!!
  // Make updateEntity private
  // Make updateFormlyModel private
  // Make a single method called patchProperties
  // patchProperties(propsToSet: IGenericObject, source: "api" | "user" | "init")
  // "api" - would fire on changes, no mark as touched
  // "user" - would fire on changes, mark as dirty
  // "init" - no firing, no mark as touched
  // Implementation note: use formGroup.setValue OR model[key]?
  formlyModel: IGenericObject = null;

  typeName: string;

  saving = new BehaviorSubject<boolean>(false);
  canSaveChanges = new BehaviorSubject<boolean>(false);
  archiveButton: FormlyFieldConfig = null;

  @Input() splitButtons: MenuItem[] = [];
  @Input() buttonAdditions: MenuItem[] = [];
  @Input() hideButtons: boolean;
  @Input() deleteButtonLabel = 'Delete';
  @Input() hasDeletePermissions: boolean = true;

  _splitButtons = new ReplaySubject<MenuItem[]>();
  service: WarpEntityServiceCache<WarpEntity>;
  entityType: ReplaySubject<WarpEntityType> = new ReplaySubject(1);
  entityTypeSub: Subscription;
  userSub: Subscription;
  onEntityChangeSub: Subscription;
  initialized: Subject<boolean>;
  showHiddenFields = false;

  invalidFields: FormlyFieldConfig[] = [];
  lastFieldCheck = new Date();

  /** Backups */
  entityEncryptBackup: Subscription;
  /** the minimum allowed time between backups, in ms */
  @Input()
  backupInterval = LocalEntityBackupService.DEFAULT_BACKUP_INTERVAL;

  @Output()
  afterSave: EventEmitter<AfterSaveEvent> = new EventEmitter<AfterSaveEvent>();

  /** custom fields to override the entityType auto thingy */
  @Input() customFields: FormlyFieldConfig[]; //TODO: this doesnt look like its used, so we should prob just remove it?
  @Input() devTools = false;

  private _formStructure = new Subject<WarpEntityType | FormlyFieldConfig[]>();

  private _entity = new BehaviorSubject<WarpEntity>(undefined);
  @Input('entity') set entity(entity: WarpEntity) {
    this._entity.next(entity);
  }
  get entity(): WarpEntity {
    return this._entity.value;
  }

  @Input() set generatorOptions(generatorOptions: IGenFormlyOptions | Observable<IGenFormlyOptions>) {
    if (generatorOptions) {
      if (this._generatorOptionsSubInit && !this._generatorOptionsSubInit.closed)
        this._generatorOptionsSubInit.unsubscribe();

      if (generatorOptions instanceof Observable)
        this.nextSub = this._generatorOptionsSubInit = generatorOptions.subscribe(this._generatorOptions);
      else
        this._generatorOptions.next(generatorOptions);
    }
  }
  _generatorOptions = new BehaviorSubject<IGenFormlyOptions>(null);
  _generatorOptionsSubInit: Subscription;
  _generatorOptionsSubUse: Subscription;
  @Input() fieldOrders: string[] = [];

  /**
   * this should be __constant__! \
   * if this is set more than once, it's behaviour is undefined...\
   * (eg. entities may be left focused).
   */
  @Input() focusEntity = false;
  @Input() onFocusIgnoreTypes: number[] = [];
  private focusSession: FocusSession;

  // If this is view-only
  @Input() edit = true;
  @Input() destroyButton = false;
  /** Override buttons, emit the action to perform */
  @Input() buttonOverride: Observable<ButtonActions>;
  private _buttonOverrideSub: Subscription;

  @Input() suppressUpdateModelDialog = false;

  destroyText: string;
  destroyDialog = false;
  updateModelDialog = false;
  destroying = false;
  isSuperAdmin = false;
  isRippleAdmin = false;
  @Output()
  destroySuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  validityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  modelUpdateOnUnsavedWork: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  entityChanged: EventEmitter<WarpEntity> = new EventEmitter<WarpEntity>();

  @Output()
  buttonsChanged: EventEmitter<MenuItem[]> = new EventEmitter<MenuItem[]>();

  validityStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  /** Pre-save hook, if returns false, save will be cancelled */
  @Input() preSave: (entity: WarpEntity) => boolean = ( _ => true );

  formsSub: Subscription;
  formlyForm: FormlyForm;
  @ViewChildren('formly') formlyForms: QueryList<FormlyForm>;

  get model() {
    return this.entity ? this.entity.properties : { };
  }

  get isValid() {
    return this.validityStatus.value;
  }

  private get _isValid() {
    return !this.group || (this.group.valid || this.getInvalidFields().length <= 0);
  }

  subscriptions: Subscription[] = [];
  set nextSub(sub: Subscription) {
    this.subscriptions.push(sub);
  }

  constructor(
    private serviceFactory: WarpEntityCacheFactoryService,
    private localBackup: LocalEntityBackupService,
    private entityFocus: EntityFocusService,
    private confirm: ConfirmationService,
    private authService: AuthService,
    private msg: MessageService,
    private cd: ChangeDetectorRef,
    cookieService: InternalCookieService
  ) {
    this.nextSub = combineLatest([
        this._generatorOptions, this._formStructure
      ])
      .pipe(throttleTime(100, undefined, { leading: true, trailing: true }))
      .subscribe(([genOptions, entityType]) => {
        this._initForm(genOptions, entityType);
        this.tryCheckFormlyExpressions();
      });

    this.options.formState.showChanges = cookieService.getBooleanCookie('ShowActiveChanges');

    /*
     TODO: refactor this to use a setter for entity, and put it into an observable
     TODO: then we can combine it with changes to entity type, genOptions, focusEntity(?), etc...
     ! we want to make sure that when a NEW entity is set, we immediately cancel any changes to the model
     ! and in general stop pollution of current entity from previous entity
     ! we also want to delay all change emissions until the entity is fully loaded
     !    eg: when we set a new entity, don't emit onChange [], [...] for virtual-entity-select
     */

    Promise.all([
      this.authService.isRippleAdmin(),
      this.authService.isSuperAdmin()
    ]).then(([rippleAdmin, superAdmin]) => {
      this.isRippleAdmin = rippleAdmin;
      this.isSuperAdmin = superAdmin;

      this.showHiddenFields = rippleAdmin && cookieService.getBooleanCookie('ShowHiddenFields')
      if (superAdmin || rippleAdmin)
        this.updateButtons.next();
    });

    this.initialized = new Subject();
    this.nextSub = this.userSub = this.authService.getLoggedInUser()
      .subscribe(user => {
        if (this.entity)
          this.initService(this.entity.entityTypeId);
      }, err => this.failInit(true), () => {
        this.failInit();
      });

    this.nextSub = this.changeSub = merge(
        this.canSaveChanges,
        this.saving,
        this.validityChange,
        this.updateButtons
      )
      .pipe(
        debounceTime(100),
      )
      .subscribe(() => this.getButtons());

    const logChangesSub = this.changes.subscribe(
      changes => {
        this.log('Changes', { changes }, this)
        this.entityChanged.emit(this.entity)
      });

    this.changeSub.add(logChangesSub);

    // Listen for changes in form validity and update the buttons accordingly
    this.validityChange.next(this._isValid);

    setInterval(() => {
      let newValue =  this._isValid;
      if (this.validityStatus.value !== newValue) {
        this.validityStatus.next(newValue);
        this.validityChange.next(newValue);
      }
    }, 100);

    this.nextSub = this.cdCheck
      .pipe(throttleTime(100))
      .subscribe( () => {

        this.cd.detectChanges();
      })
  }

  ngAfterViewInit(): void {
    // formlyForm only exists when there is an entity
    this.nextSub = this.formsSub = this.formlyForms.changes.subscribe(formlyForms => {
      this.formlyForm = formlyForms.first;
    });
  }

  ngOnDestroy(): void {
    if (this.focusSession)
      this.focusSession.end();

    this.subscriptions.forEach((sub) => {
      if (sub && !sub.closed)
        sub.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.focusEntity)
      this.focusSession = this.focusSession || this.entityFocus.createSession(this);
    else if (this.focusSession)
      this.focusSession.end();

    if (changes.entity && this.entity) {
      if (changes.entity.previousValue)
        changes.entity.previousValue.trackChanges(false);

      if (this.focusEntity)
        new Promise<number[]>( resolve => {
          // make sure we look at any updates coming from parents
          const ignoreTypes = this.onFocusIgnoreTypes || [];
          if (ignoreTypes.length < 1)
            this.nextSub = this.entityType
              .pipe(filter(t => !!t), first())
              .subscribe( t => resolve(t.ancestorEntityTypeIds));
          else
            resolve(ignoreTypes);
        })
        .then( ignoreTypes => this.focusSession.on(this.entity, ignoreTypes) );

      if (this.onEntityChangeSub && !this.onEntityChangeSub.closed)
        this.onEntityChangeSub.unsubscribe();

      this.entity.trackChanges(true);
      this.nextSub = this.onEntityChangeSub = this.entity.onChange.subscribe( weChanges => {
        this.log('Entity Changes', weChanges, MessageService.VERBOSE);
        // TODO: Individual changes on the entity might bypass our incoming change detection?
        this.updateFormlyModel(false /* don't mark as pristine, because only one field was updated */)
      });

      // notify the fields of a new entity
      this.options.formState.formDataEntity.next(this.entity);
      this.setCanDelete(true);
    }

    const user = this.authService.getSyncLoggedInUser();

    if (changes.edit && this.entity && user)
      this.initService(this.entity.entityTypeId);
    else if (changes.entity && user) {
      const oldE: WarpEntity = changes.entity.previousValue;
      const newE: WarpEntity = changes.entity.currentValue;
      this.log(`Entity Change: ${oldE} -> ${newE}`, oldE, newE, MessageService.INFO);
      let updateModel = true;

      // changing the entity, means we should hide the ui until we're ready
      if (oldE?.entityId !== newE?.entityId)
        this.switchingEntity = true;

      //TODO: if we're currently saving, then delay this next part, because its probably our own save coming in

      /**
       * We have 3 types of users to be considered when an update comes
       *  1. The guy who saved this entity
       *  2. The guy who is looking at this entity but hasn't made any changes
       *  3. The guy who is looking at this entity but made some changes <-- the most troublesome
       */
      if (oldE && newE
        && oldE.entityTypeId === newE.entityTypeId
        && oldE.entityId === newE.entityId
        && this.formlyModel
        && (oldE.entityId > 0 || newE.entityId > 0) // we only care about existing entities, newly created ones we always updateFormlyModel (entityId will always be -1)
      // tslint:disable-next-line: curly
      ) {
        // if it's the same entity inputted in and that the formly model has initialized
        if (oldE.updatedECMA !== newE.updatedECMA && this.activeChanges && !oldE.hasEquivalentProperties(newE)) {
          // if it has been updated and user has made changes in the form, for guy #3
          if(this.suppressUpdateModelDialog){
            this.discardChanges();
          }else{
            this.modelUpdateOnUnsavedWork.next(true);
            this.updateModelDialog = true;
            updateModel = false;
            this.log('Incoming Changes - Overwrite Popup', { oldE, newE });
          }
        }
        else if (oldE.updatedECMA === newE.updatedECMA) {
          updateModel = false;
          this.log('Incoming Changes - No Properties Were Changed', { oldE, newE });
        }
        else
          // guy #2 and #1 should have their model updated normally
          this.log('Incoming Changes - Updating Normally', { oldE, newE });
      }

      if (updateModel)
        this.updateFormlyModel(true);

      if (newE && (!oldE || oldE.entityTypeId !== newE.entityTypeId)) {
        this.initService(newE.entityTypeId);
      }
    }

    if (changes.preSave && !this.preSave) this.preSave = (_) => true;

    if (changes.buttonOverride) {
      if (this._buttonOverrideSub) {
        this._buttonOverrideSub.unsubscribe();
        this._buttonOverrideSub = undefined;
      }

      if (this.buttonOverride)
        this.nextSub = this._buttonOverrideSub = this.buttonOverride.subscribe((action) =>
          this.onButtonPress(action)
        );
    }

    this.updateButtons.next();
  }

  private ignoreFormlyUpdates = false;

  // TODO: check if this is used anywhere, clean up, and remove
  updateEntity(model: IGenericObject = null) {
    console.debug(`${this.typeName}`, 'Update entity', this.entity?.duration || this.entity?.length || '--');
    if (this.ignoreFormlyUpdates)
      return;

    if (!model)
      model = this.formlyModel;

    Object.keys(model).forEach(prop => {
      this.entity.properties[prop] = model[prop];
    });
  }

  private cdCheck = new Subject();
  queueChangeDetection() {
    this.cdCheck.next();
  }

  // TODO: Remove this
  SetFormlyModelValue(key, value) {
    this.formlyModel[key] = value;
    this.updateEntity();
    this.queueChangeDetection();
  }

  private updateFormlyModelTimeout: number;
  updateFormlyModel(markAsUntouched: boolean) {
    this.log(`Queuing Update for formly model ${this.entity}`, MessageService.VERBOSE);
    clearTimeout(this.updateFormlyModelTimeout);

    if (this.entity) {
      // if a formly change was queued, then we don't want it to change entity properties, since this call was made first
      this.ignoreFormlyUpdates = true;
      this.updateFormlyModelTimeout = setTimeout(() => {
        this.log(`Updating formly model ${this.entity}`, MessageService.INFO);
        this.formlyModel = this.shallowCopy(this.entity.properties);
        this.ignoreFormlyUpdates = false;
        this.queueChangeDetection();

        if (markAsUntouched) {
          this.group.markAsPristine();
        }
        this.switchingEntity = false;
      });
    } else {
      this.formlyModel = { };
      this.ignoreFormlyUpdates = false;
      this.switchingEntity = false;
    }
  }

  discardChanges() {
    this.resetFormlyModel();
    this.updateFormlyModel(true);
    this.closeUpdateModelDialog();
  }

  closeUpdateModelDialog() {
    this.updateModelDialog = false;
  }

  private shallowCopy(properties: IGenericObject) {
    return JSON.parse(JSON.stringify(properties));
  }

  private initService(typeId: number) {
    this.ignoreFormlyUpdates = true;
    this.triedInit = true;
    this.log('Loading type ' + typeId);
    this.loaded = false;
    this.service = this.serviceFactory.get(this.entity.entityTypeId);
    this.canSaveChanges.next(false);

    if (this.entityTypeSub)
      this.entityTypeSub.unsubscribe();

    //TODO: deprecate the direct input for customFields. genOptions version is better (additive)
    if (this.customFields)
      this._formStructure.next(this.customFields);
    else
      this.nextSub = this.entityTypeSub = this.service
        .getEntityStructure()
        .subscribe((type) => {
          this.options.formState.entityType.next(type);
          this._formStructure.next(type);
        }, err => this.failInit(true), () => this.failInit());
  }

  reloadView() {
    this._generatorOptions.next(this._generatorOptions.value);
  }

  private _trackChange(field: FormlyFieldConfig, value: any[]) {
    //TODO: maybe only update activeChanges if the field is visible? (if it's hidden, it's probably not a user change)
    this.log('tracking changes', field.key, field.formControl.touched, this.activeChanges, ...arguments);

    // forward the change, but this only counts as an active change if the field is touched
    const activeChange = this.activeChanges || field.formControl.touched;
    this.changes.next(activeChange);
  }

  setEntityType(entityType: WarpEntityType) {
    this.log(`Loaded Type ${this.typeName} (${entityType.id})`, entityType);
    this.entityType.next(entityType);
    this.typeName = entityType.name;
  }

  async _initForm(_options: IGenFormlyOptions, entityType: WarpEntityType | FormlyFieldConfig[]) {
    this.triedInit = true;
    this.generatingFields = true;

    const options = { ..._options } as IGenFormlyOptions;

    if (entityType instanceof WarpEntityType) {
      this.setEntityType(entityType);

      if (!options.onChange)
        options.onChange = (_) => this._trackChange.bind(this);
      else {
        const originalFuncConstr = options.onChange;
        options.onChange = (cfim) => {
          const originalFunc = originalFuncConstr(cfim);
          return (field: FormlyFieldConfig, event?) => (this._trackChange(field, event), originalFunc(field, event));
        };
      }
      this.fields[0].fieldGroupClassName = options.groups
        ? ''
        : 'p-grid ui-fluid';

      options.showHiddenFields = options.showHiddenFields || this.showHiddenFields;
      this.fields[0].fieldGroup = await entityType.generateFormlyFields(options, this.entity?.entityId);
    } else {
      // customFields
      this.fields = entityType;
      this.typeName = undefined;
    }

    if (this.fieldOrders?.length > 0) {
      this.fields[0].fieldGroup.forEach( field => {
        const index = this.fieldOrders.indexOf(field.key as string);
        field.templateOptions.order = index > -1 ? index : this.fieldOrders.length + 1;
      });

      this.fields[0].fieldGroup.sort((a, b) => a.templateOptions.order - b.templateOptions.order);
    }

    const isAnonymous = (fc: FormlyFieldConfig): FormlyFieldConfig => {
      if ('isarchived' === ((fc.key as string) || '').toLowerCase()) return fc;
      if (fc.fieldGroup)
        for (const fc2 of fc.fieldGroup) {
          const i = isAnonymous(fc2);
          if (i) return i;
        }
      return null;
    };

    this.archiveButton = this.fields.reduce(
      (isAnon, fc) => isAnon || isAnonymous(fc),
      null
    );

    this.fields[0].fieldGroup = this.generateGroups(options);

    // formly needs the config to be replaced to know to update
    if (this.edit && (this.modifyPerm || this.addPerm))
      this.fields = [...this.fields];
    else {
      const disableFieldsAndChildren = (fc: FormlyFieldConfig) => {
        if (fc.templateOptions) fc.templateOptions.disabled = true;
        if (fc.fieldGroup)
          fc.fieldGroup.forEach((fc2) => disableFieldsAndChildren(fc2));
        return fc;
      };

      this.fields = this.fields.map((fc) => disableFieldsAndChildren(fc));
    }

    this.failed = false;
    this.loaded = true;
    this.triedInit = false;
    this.generatingFields = false;
    this.updateFormlyModel(true);
    this.canSaveChanges.next(true);

    if (this.entityEncryptBackup && !this.entityEncryptBackup.closed)
      this.entityEncryptBackup.unsubscribe();

    this.nextSub = this.entityEncryptBackup = this.group.valueChanges
      .pipe(debounceTime(this.backupInterval))
      .subscribe( weChanges => this.backupEntity() );
  }

  // private assignDefaultValuesToEntity(fc: FormlyFieldConfig) {
  //   const prop = this.entity.properties[fc.key as string];
  //   if (fc.defaultValue && (!prop || prop === ''))
  //     this.entity.properties[fc.key as string] = fc.defaultValue;
  // }

  failInit(override = false) {
    this.log('Failed Init', { failed: this.failed, loaded: this.loaded, override}, this);
    this.failed = override || !this.loaded;
  }

  private generateGroups(options: IGenFormlyOptions | IGenFormlyGroupSetting) {
    let retFields = [];
    if (options && options.groups && options.groups.length > 0)
      options.groups.forEach((groupOption) => {
        const tempFieldGroup = [];
        this.fields[0].fieldGroup.forEach((field) => {
          if (!field.key)
            this.log('GenerateGroups field.key is not defined', field, this.fields);
          if (groupOption.groupFields.includes(field.key.toString()))
            tempFieldGroup.push(field);
        });
        if (groupOption.groups && groupOption.groups.length > 0)
          tempFieldGroup.push(...this.generateGroups(groupOption));

        retFields.push({
          key: '',
          type: groupOption.groupType,
          fieldGroupClassName: groupOption.groupClasses,
          wrappers: [].concat(groupOption.groupWrapper),
          templateOptions: { wrapperData: groupOption.groupWrapperData },
          fieldGroup: tempFieldGroup,
        });
      });
    else
      retFields = this.fields[0].fieldGroup;

    return retFields;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(evt: KeyboardEvent) {
    if (evt.altKey && evt.shiftKey) {
      evt.preventDefault();
      setTimeout(
        (_this) => {
          // tslint:disable-next-line: deprecation
          switch (evt.keyCode) {
            case KEY_CODE.A:
              _this.log('Debug this', this);
              break;
            case KEY_CODE.J:
              _this.log('Debug this.formlyModel', this.formlyModel);
              break;
            case KEY_CODE.M:
              _this.log('Debug this.entity', this.entity);
              break;
            case KEY_CODE.P:
              _this.log('Debug permissions', {
                addPerm: this.addPerm,
                modifyPerm: this.modifyPerm,
                editMode: this.editMode,
                activeChanges: this.activeChanges,
              });
              break;
            case KEY_CODE.B:
              _this.log('Manual Backup');
              this.backupEntity();
              break;
          }
        },
        0,
        this
      );
    }
  }

  get addPerm() {
    return this.entity
      ? this.authService.canCreate(this.entity.entityTypeId)
      : false;
  }
  get modifyPerm() {
    return this.entity && this.entity.canModify;
  }
  get editMode() {
    return this.entity && this.entity.entityId && this.entity.entityId !== -1;
  }
  get activeChanges() {
    return this.changes.value;
  }
  get asyncButtons() {
    return this._splitButtons.asObservable();
  }

  private updateButtons = new Subject<void>();

  getButtons() {
    const saving = this.saving.value;
    const disabled = !this.canSaveChanges.value || saving;

    let splitButton: MenuItem;
    if (this.edit && !this.buttonOverride) {
      if (this.editMode && this.modifyPerm) {
        splitButton = {
          disabled,
          label: 'Save',
          icon: saving ? 'pi pi-spinner pi-spin' : 'pi pi-check',
          command: () => this.onButtonPress('edit'),
          items: [
            {
              disabled: disabled || this.entity.cannotDelete,
              label: this.deleteButtonLabel,
              icon: 'pi pi-trash',
              command: () => this.onButtonPress('delete'),
            },
          ],
        };

        if (this.destroyButton && this.isSuperAdmin)
          splitButton.items.push({
            disabled,
            label: 'Destroy',
            icon: 'pi pi-exclamation-triangle',
            command: () => this.onButtonPress('destroy'),
          });
      }

      if (!this.editMode && this.addPerm)
        splitButton = {
          disabled,
          label: 'Add',
          icon: saving ? 'pi pi-spinner pi-spin' : 'pi pi-plus',
          command: () => this.onButtonPress('add'),
          items: [],
        };

      if (splitButton && !this.isValid) {
        splitButton.command = () => this.group.markAllAsTouched(); // trigger validation
      }

      if (splitButton) {
        if (this.archiveButton)
          splitButton.items.unshift({
            disabled,
            label: 'Archive',
            icon: saving ? 'pi pi-spinner pi-spin' : 'pi pi-lock',
            command: () => this.onButtonPress('archive'),
          });

        if (
          (this.editMode && this.modifyPerm) ||
          (!this.editMode && this.addPerm)
        )
          splitButton.items.push({
            disabled,
            label: 'Cancel',
            icon: 'pi pi-refresh',
            command: () => this.onButtonPress('revert'),
          });

          if (this.isRippleAdmin)
            splitButton.items.push({
              disabled,
              label: this.showHiddenFields ? 'Hide Hidden Fields' : 'Show Hidden Fields',
              icon: 'pi pi-refresh',
              command: () => this.toggleHiddenFields()
            });
      }
    }

    if(this.buttonAdditions && this.buttonAdditions.length > 0) {
      if(splitButton) {
        splitButton.items.push(...this.buttonAdditions);
      } else {
        // set the splitButton to the first buttonAddition
        splitButton = this.buttonAdditions[0];
        // set the items to the rest of the buttonAdditions
        splitButton.items = this.buttonAdditions.slice(1);
      }
    }

    this.splitButtons = splitButton ? [splitButton] : [];
    this._splitButtons.next(this.splitButtons);
    this.buttonsChanged.emit(this.splitButtons);
    return this.splitButtons;
  }

  toggleHiddenFields(val?: boolean) {
    this.showHiddenFields = val === undefined ? !this.showHiddenFields : val;
    this.reloadView();
  }

  onButtonPress(action: ButtonActions) {
    switch (action) {
      case 'save':
        return this.editMode ? this.update() : this.create();
      case 'edit':
        return this.update();
      case 'add':
        return this.create();
      case 'revert':
        return this.cancel();
      case 'delete':
        return this.delete();
      case 'archive':
        return this.archive();
      case 'destroy':
        this.destroyDialog = true;
    }
  }

  _preSave(prePreSave = true) {
    if (!this.isValid || !this.entity)
      return false;

    this.canSaveChanges.next(false);
    this.saving.next(true);

    const extraPreSave = prePreSave && (!this.preSave || this.preSave(this.entity));

    if (!extraPreSave)
      this.afterSave.emit({ action: 'aborted', entity: this.entity });

    // this.handleChildren();

    this.changes.next(false);
    return extraPreSave; // both must allow the save to happen
  }

  // handleChildren() {
  //   this.genericService.handleSaveChildrenEntities(this.entity);
  // }

  updateDescription() {
    this._description = this.getDescription();
  }

  _description = "Unknown";

  private getDescription() {
    if (!this.entity)
      return 'Unknown';

    const specify = this.typeName ? `The ${singularize(this.typeName)}: ` : '';
    return `${specify}'${this.entity.name || this.entity.entityId}'`;
  }

  postSave() {
    this.canSaveChanges.next(true);
    this.saving.next(false);
  }

  cancel() {
    this.resetFormlyModel();
    this.entity.reset();
    // TODO: maybe need to call updateFormlyModel?
    this.afterSave.emit({
      action: 'revert',
      entity: this.entity,
    });
  }

  create() {
    const didPreSave = this._preSave();
    if (didPreSave)
      this.service
        .create(this.entity)
        .pipe(first())
        .toPromise()
        .then((id) => {
          this.entity.entityId = id;
          this.postSave();
          this.resetFormlyModel();
          this.afterSave.emit({
            action: 'add',
            entity: this.entity,
          });
        })
        .catch((e) => {
          this.changes.next(true);
          this.fail('add');
        });
  }

  resetFormlyModel() {
    this.changes.next(false);
    this.group.markAsPristine();
  }

  update() {
    const didPreSave = this._preSave();
    if (didPreSave)
      this.service
        .update(this.entity)
        .toPromise()
        .then((we) => {
          this.entity = we;
          this.postSave();
          this.resetFormlyModel();
          this.afterSave.emit({
            action: 'edit',
            entity: we,
          });
        })
        .catch((e) => {
          this.changes.next(true);
          this.fail('save');
        });
  }

  canDelete = true;
  entityRequiredReason: string;

  setCanDelete(canDelete: boolean, reason: string = '') {
    this.canDelete = canDelete;
    this.entityRequiredReason = reason;
  }

  delete() {
    // if this entity can be deleted, then we confirm, then delete
    // if there is a reason provided, we show that reason
    // otherwise, we just ignore this call

    return new Promise<void>((resolve, reject) => {
      if (!this.canDelete) {
        this.confirm.confirm({
          message: `${this.getDescription()} cannot be deleted. ${this.entityRequiredReason}`,
          accept: () => reject(),
        });
      }
      if(!this.hasDeletePermissions) {
        this.confirm.confirm({
          message: `${this.getDescription()} cannot be deleted from your role. Please ask a Super Admin.`,
          accept: () => reject(),
        });
      } else {
        this.confirm.confirm({
          message: `Are you sure that you want to delete ${this.getDescription()}?`,
          accept: () => {
            this.service
              .delete(this.entity)
              .then((_) => {
                this.postSave();
                this.afterSave.emit({
                  action: 'delete',
                  entity: this.entity,
                });
                this.changes.next(false);
                resolve();
              })
              .catch((e) => {
                this.fail('delete');
                reject();
              });
          },
          reject: () => {
            reject();
          }
        });
      }
    });
  }

  archive() {
    this.confirm.confirm({
      message: `Are you sure that you want to archive ${this.getDescription()}?`,
      accept: () =>
        this.trySetArchived().then((success) => {
          const presave = this._preSave(success);
          if (presave) {
            this.service
              .update(this.entity)
              .toPromise()
              .then((we) => {
                this.postSave();
                this.afterSave.emit({ action: 'archive', entity: we });
              })
              .catch((e) => this.fail('archive'));
            this.changes.next(false);
          }
        }),
    });
  }

  destroyClick() {
    if (this.destroyText === 'DESTROY') this.destroy();
  }

  destroy() {
    this.destroying = true;
    this.service
      .destroy(this.entity)
      .then((success) => {
        this.destroyDialog = false;
        this.destroying = false;
        this.destroyText = '';

        if (success) {
          this.postSave();
          this.afterSave.emit({
            action: 'destroy',
            entity: this.entity,
          });
          this.destroySuccess.emit(true);
        } else this.fail('destroy');
      })
      .catch((e) => this.fail('destroy'));
    this.changes.next(false);
  }

  refresh() {
    // TODO: maybe we could do a smarter refresh here and only reload this component,
    // but a fail is more indicative of a deeper problem, so this might be a better solution anyway
    location.reload();
  }

  backupEntity() {
    //TODO double check this has up to date info, otherwise grab formly model instead
    if (this.entity)
      this.localBackup.storeEntity(this.entity);
  }

  public triggerModelChange() {
    // tslint:disable-next-line: no-any
    if (this.options && (this.options as any)._checkField instanceof Function)
      // tslint:disable-next-line: no-any
      (this.options as any)._checkField({
        fieldGroup: this.fields,
        model: this.model,
        formControl: this.group,
        options: this.options,
      });
  }

  public tryCheckFormlyExpressions() {
    this.log('Checking Expression Changes [Manual].')
    // checkExpressionChange is a private property, so we do a TS workaround here
    // this is bad but the best i can do with this version of formly (v5)
    if (this.formlyForm && typeof (this.formlyForm as any).checkExpressionChange === 'function' )
      (this.formlyForm as any).checkExpressionChange();
  }

  markAllAsTouched() {
    this.group.markAllAsTouched()
  }

  // This is a mess
  private trySetArchived() {
    const out = new Subject<boolean>();
    const resolve = (bool) => (out.next(bool), out.complete());
    try {
      const archiveKey = this.archiveButton.key as string;
      const truthyCFCs = ['yes', 'y'];
      this.entityType
        .pipe(first())
        .toPromise()
        .then((t) => {
          const yesCFC = t
            .getCustomFieldChoices(archiveKey)
            .find((c) => truthyCFCs.includes(c.optionName.toLowerCase()));
          if (yesCFC) this.entity.properties[archiveKey] = [yesCFC];
          else this.entity.properties[archiveKey] = 'True';
          resolve(true);
        });
    } catch (e) {
      resolve(false);
    }
    return out.toPromise();
  }

  private fail(action: ButtonActions | AfterSaveFailure) {
    this.afterSave.emit({ action, entity: this.entity });
  }

  getInvalidFields(fields: FormlyFieldConfig[] = this.fields): FormlyFieldConfig[] {
    if (!fields?.length) return [];

    if (this.lastFieldCheck.getTime() + 100 > new Date().getTime())
      return this.invalidFields;

    let missingFields: FormlyFieldConfig[] = [];
    for (const field of fields) {
      if (!field) continue;

      if (field.fieldGroup && field.fieldGroup.length > 0) {
        // If has a group, check the sub fields
        missingFields.push(...this.getInvalidFields(field.fieldGroup));
      } else if (this.isInvalidField(field)) {
        missingFields.push(field);
      }
    }

    this.lastFieldCheck = new Date();

    return this.invalidFields = missingFields;
  }

  isInvalidField(field: FormlyFieldConfig) {
    return (
      field?.templateOptions?.required === true &&
      !field.templateOptions.hidden &&
      field.templateOptions.disabled !== true &&
      field.formControl?.status === 'INVALID'
    );
  }

  log(...args) {
    this.msg.add(`EntityDetailsComponent <${this.componentGuid}>`, ...args);
  }
}
