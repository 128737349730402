import { Component, Input } from '@angular/core';
import { AuthService } from '@ripple/services';
import { Message } from 'primeng/api';

@Component({
  selector: 'todo',
  template: `
    <p-messages [(value)]="msgs" *ngIf="msgs?.length" [enableService]="false"]></p-messages>
  `,
})
export class TodoComponent {
  detail: string;
  @Input() set message(detail: string) {
    this.detail = detail;

    if (this.msgs && this.msgs.length > 0) {
      this.msgs[0].detail = this.detail;
      this.msgs = [...this.msgs];
    }
  };

  msgs: Message[] = [];

  constructor(authService: AuthService) {
    authService.isRippleAdmin().then(isAdmin => {
      if (isAdmin) {
        this.msgs = [{ severity: 'warn', summary: 'TODO', detail: this.detail }];
      }
    });
  }
}
