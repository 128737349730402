import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { EntityFilter, WarpEntity } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EntityTypes, OrganizationTag, OrganizationTagCategory } from '../_core/models';

@Component({
  selector: 'sagesse-organization-tag',
  templateUrl: './organization-tag.component.html',
  styleUrls: ['./organization-tag.component.scss']
})
export class OrganizationTagComponent extends BasePage implements OnInit {

  categoryService: WarpEntityServiceCache<OrganizationTagCategory>;
  tagService: WarpEntityServiceCache<OrganizationTag>;

  categories: OrganizationTagCategory[] = [];
  categorySelected: OrganizationTagCategory;
  tags: OrganizationTag[] = [];
  tagsSub: Subscription;
  categoryText: string;
  tagText: string;

  loading = true;
  tagsLoading = false;
  categoryAdding = false;
  tagAdding = false;

  constructor(
    private logService: WarpEntityLogService,
    private warpEntityFactory: WarpEntityCacheFactoryService,
    private confirmationService: ConfirmationService,
  ) {
    super(logService);
    this.categoryService = this.warpEntityFactory.get(EntityTypes.OrganizationTagCategory) as WarpEntityServiceCache<OrganizationTagCategory>;
    this.tagService = this.warpEntityFactory.get(EntityTypes.OrganizationTag) as WarpEntityServiceCache<OrganizationTag>;
  }

  ngOnInit(): void {
    const categoryFilter = EntityFilter.None.orderBy('order', 'asc');
    this.nextSub = this.categoryService.initQuery(categoryFilter, 9999999).getPage(0).subscribe(categories => {
      this.categories = categories;
      this.loading = false;
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  categorySelect(category: OrganizationTagCategory) {
    this.categorySelected = category;
    this.tagsLoading = true;
    if (this.tagsSub) this.tagsSub.unsubscribe();
    const tagFilter = EntityFilter.Advanced({ organizationtagcategory_lid: category.entityId }).orderBy('order', 'asc');
    this.nextSub = this.tagsSub = this.tagService.initQuery(tagFilter, 9999999).getPage(0).subscribe(tags => {
      this.tags = tags;
      this.tagsLoading = false;
    })
  }

  addCategory() {
    const order = this.categories.length + 1;
    const newCategory = OrganizationTagCategory.empty()
      .property('name', this.categoryText)
      .property('order', order);
    this.categoryAdding = true;
    this.categoryService.create(newCategory).toPromise().then(result => {
      this.categoryText = '';
      this.categoryAdding = false;
    })
  }

  addTag() {
    const order = this.tags.length + 1;
    const newTag = OrganizationTag.empty()
      .property('name', this.tagText)
      .property('order', order)
      .linkedProperty('organizationtagcategory', this.categorySelected);
    this.tagAdding = true;
    this.tagService.create(newTag).toPromise().then(result => {
      this.tagText = '';
      this.tagAdding = false;
    })
  }

  edit(entity) {
    if (!entity.editMode) {
      entity.editMode = true;
    } else {
      entity.editMode = false;
      if (entity.entityTypeId === EntityTypes.OrganizationTagCategory) {
        this.categoryService.update(entity).toPromise().then();
      } else if (entity.entityTypeId === EntityTypes.OrganizationTag) {
        this.tagService.update(entity).toPromise().then();
      }
    }
  }

  delete(entity) {
    const type = entity.entityTypeId === EntityTypes.OrganizationTagCategory ? 'category' : 'tag';
    this.confirmationService.confirm({
      message: `Are you sure you want to delete this ${type}?`,
      header: 'Confirmation',
      icon: 'pi pi-refresh',
      accept: () => {
        if (entity.entityTypeId === EntityTypes.OrganizationTagCategory) {
          this.categoryService.delete(entity).then();
          this.categories = this.categories.filter(c => c.id !== entity.entityId);
          if (this.categorySelected && this.categorySelected.entityId === entity.entityId) this.categorySelected = null;
        } else if (entity.entityTypeId === EntityTypes.OrganizationTag) {
          this.tagService.delete(entity).then();
          this.tags = this.tags.filter(t => t.id !== entity.entityId);
        }
      },
      reject: () => {
      }
    });
  }

}
