
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, AsyncSubject, Subscription, forkJoin, ReplaySubject, zip, Observable } from 'rxjs';
import { environment } from '@ripple/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IGenericObject, IWarpEntityInputObject } from '@ripple/models';
import { first } from 'rxjs/operators';



@Injectable({
  providedIn: 'root',
})
export class SiteSettingsService implements OnDestroy{

  private settings: IWarpEntityInputObject[] = [];
  private settingsMap: Map<string, IWarpEntityInputObject> = new Map();
  private pubSettingsLoaded = new AsyncSubject<boolean>();
  private privSettingsLoaded = new AsyncSubject<boolean>();
  private subscriptions: Subscription[] = [];

  public constructor(private http: HttpClient) {
    this.http
      .get(`${environment.restEndpointUrl}/api/v2/entityTypes/630`)
      .subscribe(
        (res: IWarpEntityInputObject[]) => {
          this.settings = this.settings.concat(res);
          this.addSettingsToMap(this.settings);
          this.pubSettingsLoaded.next(true);
          this.pubSettingsLoaded.complete();
        });

    this.http
      .get(`${environment.restEndpointUrl}/api/v2/entityTypes/679`)
      .subscribe(
        (res: IWarpEntityInputObject[]) => {
          this.settings = this.settings.concat(res);
          this.addSettingsToMap(this.settings);
          this.privSettingsLoaded.next(true);
          this.privSettingsLoaded.complete();
        });

    
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  private addSettingsToMap(settings: IWarpEntityInputObject[]) {
    settings.forEach( setting => {
      this.settingsMap.set(setting.properties.unchangeablename, setting);
    })
  }


  /**
   * @param unchangeableName the name of the site setting
   * @param defaultValue if the setting does not exist, default on or default off
   * @returns the value of the sie setting with that name, or default if it doesn't exist
   */
  public async GetBoolean(unchangeableName: string, defaultValue = false): Promise<boolean> {
    const retVal = new AsyncSubject<boolean>();

    this.GetValue(unchangeableName).then(res => {
      retVal.next(res ? res.properties.value === 'Yes' : defaultValue);
      retVal.complete();
    });

    return retVal.toPromise();
  }

  public async GetText(unchangeableName: string): Promise<string> {
    const retVal = new AsyncSubject<string>();

    this.GetValue(unchangeableName).then(res => {
      retVal.next(res ? res.properties.value : '');
      retVal.complete();
    });

    return retVal.toPromise();
  }

  public getSettings() {
    const retVal = new AsyncSubject<Map<string, boolean | string>>();

    this.subscriptions.push(
      forkJoin([this.pubSettingsLoaded, this.privSettingsLoaded])
      .subscribe( ([loadedPub, loadedPriv]) => {
        const map = new Map();

        this.settings.forEach(e => {
          map.set(e.properties.unchangeablename, this.guessType(e.properties));
        })

        retVal.next(map);
        retVal.complete();
      }));

    return retVal.toPromise();
  }

  public GetSubscriberSpecificData():Observable<IGenericObject> {
    return this.http.get(`${environment.restEndpointUrl}/api/izzySiteSettings/subscriber-specific-data`);
  }

  private async GetValue(unchangeableName: string): Promise<IWarpEntityInputObject> {
    const retVal = new AsyncSubject<IWarpEntityInputObject>();

    this.subscriptions.push(
      forkJoin([this.pubSettingsLoaded, this.privSettingsLoaded])
      .subscribe( ([loadedPub, loadedPriv]) => {
        if (loadedPub && loadedPriv) {
          let found = false;
          this.settings.forEach(value => {
            if (value && value.properties.unchangeablename === unchangeableName) {
              retVal.next(value);
              found = true;
              retVal.complete();
              return;
            }
          });

          if (!found) {
            retVal.next(null);
            retVal.complete();
          }
        }
      })
    );

    return retVal.toPromise();
  }

  private guessType(properties: IWarpEntityInputObject['properties']): boolean | string {
    const value = properties.value;
    if (!value)
      return value;

    if (value === 'Yes')
      return true;

    if (value === 'No')
      return false;

    return value
  }
}
