/**
 * decryptor.ts
 *
 * A mostly-standalone file for backup file decryption
 *
 * Platform independent. JSEncrypt is different for node and browser
 * so we need to pass in the constructor
 */
import { AES as aes, enc } from 'crypto-js';

interface IGenericObject {
  [key: string]: any;
}

export interface InfoItem {
  id: string;
  time: number;
  timeLT?: number;
  next: string;
  nextLT?: string;
}

export const maxLongTermHistory = 24; // 2 hours
export const maxHistory = 5;

// The only interface needed for JSEncrypt
interface JSEncrypt {
  setPrivateKey(key: string): void;
  decrypt(data: string): string | false;
}

export class Decryptor<JSEncryptOptions> {
  private longTermSuffix = '_longTerm';

  public maxLongTermHistory = maxLongTermHistory; // 2 hours
  public maxHistory = maxHistory;

  constructor(
    private jsEnc: { new (_?: JSEncryptOptions): JSEncrypt } // Constructor for JSEncrypt.
  ) {}

  private index = index;
  private group = group;
  private insertTime = insertTime;

  public decryptBackupFile(
    jsonLocalStorage: string | IGenericObject,
    privateKey?: string
  ) {
    const _localStorage =
      typeof jsonLocalStorage === 'string'
        ? JSON.parse(jsonLocalStorage)
        : jsonLocalStorage;

    const index = this.index(''); // looks like '_index';
    const indexLT = this.index('') + this.longTermSuffix; // looks like '_index_longTerm';
    const storedEntities = Object.keys(_localStorage).reduce(
      (list: Map<string, InfoItem>, key: string) => {
        if (key.endsWith(index) || key.endsWith(indexLT)) {
          const info = this.getStorageInfoByKey(
            _localStorage,
            key,
            key.endsWith(index) ? index : indexLT
          );
          if (!list.has(info.id)) list.set(info.id, info);
        }
        return list;
      },
      new Map<string, InfoItem>()
    ); // descending

    return Array.from(
      storedEntities.values(),
      (info) =>
        new EncryptedEntityHistory(
          info.id,
          new Date(info.time || info.timeLT || new Date()),
          this.collectHistory(
            _localStorage,
            info,
            /* longTerm: */ false,
            privateKey
          ),
          this.collectHistory(
            _localStorage,
            info,
            /* longTerm: */ true,
            privateKey
          )
        )
    );
  }

  private getStorageInfoByKey(
    storage: IGenericObject,
    key: string,
    keySuffix: string | null = null
  ): InfoItem {
    if (keySuffix === null) {
      key = this.index(key);
      keySuffix = this.index('');
    }

    const id = key.substring(0, key.length - keySuffix.length);
    const index = this.index(id);
    const time = new Date(storage[this.insertTime(id)]).getTime();
    const timeLT = new Date(
      storage[this.insertTime(id) + this.longTermSuffix]
    ).getTime();
    return {
      id,
      time,
      timeLT,
      next: storage[index],
      nextLT: storage[index + this.longTermSuffix],
    };
  }

  private collectHistory(
    storage: IGenericObject,
    info: InfoItem,
    longTerm: boolean,
    privateKey?: string
  ) {
    const suffix = longTerm ? this.longTermSuffix : '';
    const max = longTerm ? this.maxLongTermHistory : this.maxHistory;
    const next = longTerm ? info.nextLT : info.next;
    const history: EncryptedEntitySnapshot[] = [];
    for (let i = 0; i < max; i++) {
      const nextIndex = (Number(next) + i) % max;
      const d = storage[this.group(info.id, nextIndex) + suffix];
      if (d) {
        const snapshot = new EncryptedEntitySnapshot(d);
        if (i === max - 1) {
          const dateStr = longTerm ? info.timeLT : info.time;
          snapshot.date = new Date(
            dateStr || info.time || info.timeLT || new Date()
          );
        }
        if (privateKey)
          snapshot.unencryptedData =
            this.decrypt(snapshot.password, snapshot.data, privateKey) || '';

        history.unshift(snapshot);
      }
    }
    return history;
  }

  // key is the server-side private key
  public decrypt(password: string, data: string, privateKey: string) {
    // const encryptedObj = data;
    const crypto = new this.jsEnc();
    crypto.setPrivateKey(privateKey);
    const aesPassword = crypto.decrypt(password) || '';
    return aes.decrypt(data, aesPassword).toString(enc.Utf8);
  }
}

export function group(offlineGuid: string, index: string | number) {
  return `${offlineGuid}_${index}`;
}

export function insertTime(offlineGuid: string) {
  return `${offlineGuid}_lastTouched`;
}

export function index(offlineGuid: string) {
  return `${offlineGuid}_nextIndex`;
}

export class EncryptedEntitySnapshot {
  date?: Date;
  storedAt: Date;
  password: string;
  data: string;
  unencryptedJSON?: string;
  unencryptedEntity?: IGenericObject;
  set unencryptedData(s: string) {
    this.unencryptedJSON = s;
    try {
      this.unencryptedEntity = JSON.parse(s);
    } catch (e) {}
  }
  constructor(data: string) {
    const d = JSON.parse(data);
    this.password = d.password;
    this.data = d.data;
    this.storedAt = new Date(d.storedAt);
  }
}

export class EncryptedEntityHistory {
  constructor(
    // id, guid or offline guid
    public identifier: string,
    public lastTouched: Date,
    public history: EncryptedEntitySnapshot[],
    public longTermHistory: EncryptedEntitySnapshot[]
  ) {}
}
