import type { FieldTypeName } from './question-type-names';

export interface IQuestionTypeDescriptor {
  value: FieldTypeName;
  optionName: string;
}

export const questionTypes: IQuestionTypeDescriptor[] = [
  {
    value: 'select-search',
    optionName: 'Drop down options with search'
  },
  {
    value: 'generic-input',
    optionName: 'Short Answer'
  },
  {
    value: 'generic-textarea',
    optionName: 'Paragraph Answer'
  },
  { //TODO: Unify radio, multi-checkbox, and select-search
    value: 'radio-multiple-col',
    optionName: 'Single choice options'
  },
  {
    value: 'checkbox-multiple-col',
    optionName: 'Multiple choice checkbox'
  },
  {
    value: 'datetime-picker',
    optionName: 'Date Picker'
  },
  {
    value: 'description-info',
    optionName: 'Description Label'
  },
  {
    value: 'select-entity',
    optionName: 'Dropdown options with entities'
  },
  {
    value: 'draw-signature',
    optionName: 'Signature'
  }, {
    value: 'generic-file',
    optionName: 'File Upload'
  }
];
