import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityLogService } from '@ripple/services';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'sagesse-clares-law-report-page',
  templateUrl: './clares-law-report-page.component.html',
  styleUrls: ['./clares-law-report-page.component.scss']
})
export class ClaresLawReportPageComponent extends BasePage implements OnInit {

  ifEmailView = false;

  splitButtons: MenuItem[] = [
    {
      label: 'Send Report Email',
      icon: 'pi pi-envelope',
      command: () => this.ifEmailView = !this.ifEmailView,
      visible: !this.ifEmailView
    },
    {
      label: 'Back',
      icon: 'pi pi-arrow-left',
      command: () => this.ifEmailView = !this.ifEmailView,
      visible: this.ifEmailView
    },
  ];

  constructor(
    private logService: WarpEntityLogService,
  ) {
    super(logService);
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string): string {
    return '';
  }

}
