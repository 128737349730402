import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: '[form-builder-snippet-filter]',
  templateUrl: './snippet-filter.component.html',
  styleUrls: ['./snippet-filter.component.scss']
})
export class SnippetFilterComponent implements OnInit {
    @Input() item: any;
    @Input() unchangeablename: string;
    @Input() label: string;
    @Input() data: any;
    @Input() dataLength: number;
    @Output() filterUpdate: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
      //console.log('Conversation Filter Item', this);
  }

  UpdateFilter(type) {
    console.log('UpdateFilter in question', type);
    const obj = {
                item: this.item,
                data: this.data,
                unchangeablename: this.unchangeablename,
                label: this.label,
                filterType: type
            };
    this.filterUpdate.emit(obj);
}


}
