<p-accordionTab>
  <p-header>
    <span>{{subcategory.properties.name}}</span>
    <p-splitButton class="right-button" [model]="subcategory.actionMenu" label="Edit" icon="pi pi-pencil"
      (onClick)="showModal('editsubcat', subcategory)" appendTo="body">
    </p-splitButton>
  </p-header>

  <button style="width:200px; margin-bottom: 10px;" pButton type="button" icon="pi pi-plus" label="Add Statistic"
    (click)="showModal('add')"></button>

  <p-table #dt [value]="stats" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
    [showCurrentPageReport]="true" [loading]="loading" [paginator]="false" (onRowReorder)="reorder($event)"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0">
    <ng-template pTemplate="header">
      <tr>
        <th width="10%">Reorder
        </th>
        <th >Name
        </th>
        <th >Linked Stats
        </th>
        <th class="actions-col"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-stat
      let-index="rowIndex">
      <tr class="ui-selectable-row" [pReorderableRow]="index">
        <td width="10%">
          <i class="pi pi-bars" pReorderableRowHandle></i>
        </td>
        <td>{{stat.properties.name}}</td>
        <td>{{stat?.linkedProperties?.linkedstatistic?.name}}</td>
        <td class="actions-col">
          <p-splitButton [model]="stat.actionMenu" label="Edit"
            icon="pi pi-{{stat.deleting ? 'spin pi-spinner' : 'pencil'}}" [disabled]="stat.deleting"
            (onClick)="showModal('edit', stat)" appendTo="body">
          </p-splitButton>

        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="*" class="empty-message-col">No Stats found.</td>
      </tr>
    </ng-template>
  </p-table>
</p-accordionTab>



<p-dialog [header]="modalText" [(visible)]="showModalFlag" [appendTo]="'body'" [modal]="true" [draggable]="false"
  [baseZIndex]="10000" [dismissableMask]="true">
  <div class="modal-content">
    <ripple-entity-details [entity]="toEdit" [hideButtons]="true" [generatorOptions]="generatorOptions">
    </ripple-entity-details>
    <button pButton type="button" icon="pi pi-{{adding ? 'spin pi-spinner' : 'plus'}}" [label]="modalText"
      (click)="addStat()"></button>
  </div>
</p-dialog>
