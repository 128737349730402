import { Guid } from 'guid-typescript';
import { IWarpEntityInputObject } from '../api/expando-models';
import { IGenericObject } from '../interfaces/generic';
import { CustomFieldChoice, DataFieldName } from './custom-field-in-module';
import { WarpEntityType } from './entity-type';
import * as moment_ from 'moment';
const moment = moment_;
import { isObservable, observable, Observable, Subject, Subscription } from 'rxjs';

type SimpleProp = string | number | Date | boolean;
type PropType = SimpleProp | CustomFieldChoice | LinkedEntity;
type PropertyType = PropType | PropType[];

export interface PropertyInfo {
  type: DataFieldName;
  multiValued: boolean;
  linkToNewEntity?: boolean;
}

export interface LinkedEntity {
  id: number;
  name?: string;
  properties?: IGenericObject;
}

export type LinkedEntityInput = WarpEntity | number | {
  id: number,
  optionName?: string,
  name?: string,
  Text?: string,
  label?: string
};

export interface DetailedFile {
  guid: string;
  identifier: string;
  mimeType: string;
  fileType: string;
  originalFileName: string;
  extension: string;
}

export class WarpEntityFields {
  entityTypeId: number;

  entityId: number;
  hash: string;
  warpEntityName: string;

  properties: IGenericObject;
  files: string[];
  filesDetailed: DetailedFile[];

  createdECMA: string;
  updatedECMA: string;
  // tslint:disable-next-line: variable-name
  CreatedByUserName: string;
  createdByCurrentUser: boolean;
  canModify: boolean;

  parentIds: number[];
  childIds: number[];
  childEntities?: (WarpEntity | IWarpEntityInputObject)[];
  signedFields?: {
    [key: string]: {
      date: string,
      id: number,
      name: string
    }[]
  };
}

interface WarpEntityChangeEvent {
  key: string;
  oldValue: PropertyType;
  newValue: PropertyType;
}

/**
 * Each entity when loaded will initialize accessors to each property
 * Property Accessors are aliases to entity.properties
 *
 * - linked entities an CFCs are stored internally as arrays,
 * but the accessor will return a single value if there is only element in an array
 * - accessors will only be added to root if the property is present on load
 */
// @dynamic
export class WarpEntity extends WarpEntityFields {
  //#region Additional Properties
  // tslint:disable-next-line: no-any
  [propName: string]: any; // property accessors

  protected onLoad_properties: object;
  /** @internal used for caching information */
  __requestedChildren: number[] = [];
  private _offlineGuid: string;
  private _propertyInfo: { [propName: string]: PropertyInfo };
  private _saveHooks: Map<(w: WarpEntity) => boolean, boolean>;

  protected _change: Subject<WarpEntityChangeEvent>;
  /**
   * fires a {@link WarpEntityChangeEvent} on changes made to properties.
   * * _this is only triggered on pure and indirect accesses to a property_ \
   * eg.
   * ```typescript
   *    entity.cfcProperty('status', 1297); // triggers an event (indirect)
   *    entity.fullName = "new";            // triggers an event (indirect, pure)
   *    entity.properties.fullName = "new"; // does not trigger an event (direct)
   *    entity.organization.id = 182;       // does not trigger an event (impure)
   *   ```
   * * to trigger an event after performing an impure or direct access, make a call to {@link trackChange} \
   * eg:
   * ```typescript
   *    entity.trackChange({
   *        key: 'name',
   *        oldValue: entity.properties.name,
   *        newValue: entity.properties.name = "New Name"
   *    });
   * ```
   */
  public onChange: Observable<WarpEntityChangeEvent>;
  public activeChanges: boolean;
  protected hasChildren: boolean = false;

  /** A list of property names that this entity should include on empty initialization */
  protected extendedProps: string[] = [];
  //#endregion

  //#region Overridable Accessors
  get cannotDelete(): boolean {
    return false;
  }
  //#endregion

  //#region Static Accessors
  get offlineGuid() {
    return this._offlineGuid.toString();
  }
  get guaranteedId(): number | string {
    if (this.entityId && this.entityId > 0) return this.entityId;
    else return this.offlineGuid;
  }

  get dateCreated() {
    return new Date(this.createdECMA);
  }
  set dateCreated(d: Date) {
    this.created = d.toString();
  }
  get dateUpdated() {
    return new Date(this.updatedECMA);
  }
  set dateUpdated(d: Date) {
    this.updated = d.toString();
  }
  get userImg() {
    return this.getFileByIdentifier('userimg');
  }
  set userImg(s) {
    console.log('gdi');
  }
  //#endregion

  //#region Construction
  constructor(obj: IWarpEntityInputObject | WarpEntity, private _trackChanges = false) {
    super();
    let savehooks = [];
    if (obj instanceof WarpEntity) {
      // need to deep clone and strip out the property getters|setters
      savehooks = [...obj._saveHooks.entries()].slice();
      obj = JSON.parse(JSON.stringify(obj, obj.getJSONReplacer()));
    }

    this.fromAny(obj);

    // must have a offlineGuid to save via api
    // offline guid is now a string, because the deep clone would often fail for Guid types
    if (this._offlineGuid) // && /[^0-]/.test(this._offlineGuid.toString()) )
      this._offlineGuid = Guid.parse(this._offlineGuid.toString() ).toString();
    else
      this._offlineGuid = Guid.create().toString(); // create a new one

    this.resetActiveChanges();

    this.onChange = (this._change = new Subject()).asObservable();
    this._change.subscribe( (changes) => this.activeChanges = true );

    this._saveHooks = new Map(savehooks);
  }

  fromAny(obj) {
    if (!obj) return;

    // deep-ish copy
    for (const key of Object.keys(obj))
      if (key.toLowerCase() === 'offlineguid') this._offlineGuid = obj[key];
      else this[key] = obj[key];

    // accessing ID is not safe, it can be overwritten by a property
    this.entityId = this.entityId || (this.id as number);

    // this is what was received from server, useful for change detection maybe
    this.onLoad_properties = this.onLoad_properties || this.properties || { /* Brand new entities can be made with no props */ };

    // these should exist
    this.multiValuedProperties = this.multiValuedProperties || { };
    this.linkedProperties = this.linkedProperties || { };
    this._propertyInfo = this._propertyInfo || { };
    this.properties = { };

    // sometimes the backend misses linked entities in regular properties (missing labels)
    for (const [key, value] of Object.entries(this.linkedProperties) as [string, LinkedEntity][]) {
      if (!this.onLoad_properties[key])
        this.onLoad_properties[key] = value.name || `Unknown [${value.id}]`;
    }

    // load everything from linkedProperties, linkedPropertiesList, and multiValuedProperties into properties, no longer maintain those
    for (const [key, value] of Object.entries(this.onLoad_properties)) {
      {
        // first, make properties reference the originals or objects
        // for ids (make lid and cfcid link to the objects)
        if (key.endsWith('_lid') || key.endsWith('_cfcid')) {
          const saveTo = key.replace(/_(l|cfc)id$/, '');
          Object.defineProperty(this.properties, key, {
            get: () =>
              this.properties[saveTo]
                ? this.properties[saveTo].length === 1
                  ? this.properties[saveTo][0].id
                  : this.properties[saveTo].map(v => v.id)
                : undefined,
            set: this._captureChangesAlias( saveTo, (val: number) => {
              if (!this.properties[saveTo]) this.properties[saveTo] = { };
              this.properties[saveTo].id = val;
              this.properties[saveTo].name = this.properties[
                saveTo
              ].value = undefined;
            }),
            configurable: true,
          });
          continue;
        }

        // for mangled names (in the case that we have unchangeable names with spaces) (so saving works)
        /* reasoning:
          for something like 'First Name'
          - the api sends us 'first name'' and 'firstname'
          - but to save an object, it only accepts 'first name' */
        const spaced = Object.keys(this.onLoad_properties).find((p) =>
          p.includes(' ') && p.replace(' ', '') === key ? p : false
        );
        if (spaced) {
          // Value
          Object.defineProperty(this.properties, key, {
            get: () => this.properties[spaced],
            set: this._captureChangesAlias(spaced, (val: PropertyType) => (this.properties[spaced] = val)),
          });
          // Info
          Object.defineProperty(this._propertyInfo, key, {
            get: () => this._propertyInfo[spaced],
            set: (val: PropertyInfo) => (this._propertyInfo[spaced] = val),
          });
          this._makeRootPropertyAlias(key);
          continue;
        }
      }

      // if this is copying a WarpEntity, this work is already done
      const saved = this._propertyInfo[key];

      this._propertyInfo[key] = saved || {
        type: 'generic',
        multiValued: false,
      };

      // infer the type from its context
      // multivalued props are sent in multiValuedProperties as well as properties
      const multiV = this.multiValuedProperties[key];
      if (multiV) {
        // For Each property[key].id that are the same, combine the specifytext into an array
        let usePropObj: boolean = true,
            propObj: Array<any> = [];
        multiV.forEach((prop) => {
          // We are looking for objects with the same id and value, to combine their specifytext
          // But not all options have these fields
          if ([prop.id, prop.value, prop.specifytext].includes(undefined)) {
            usePropObj = false;
            return;
          }
          // If the id is already in the array, combine the specifytext
          const index = propObj.findIndex((p) => p.id === prop.id && p.value === prop.value);
          if (index >= 0) {
            if (Array.isArray(propObj[index].specifytext))
              propObj[index].specifytext.push(prop.specifytext);
            else
              propObj[index].specifytext = [propObj[index].specifytext, prop.specifytext];
          }
          else
            propObj.push({...prop}); // Otherwise, add the object to the array
        });
        this.properties[key] = usePropObj ? propObj : multiV;
        this._propertyInfo[key].multiValued = true;
      }

      // linked entities are sent in linkedProperties, linkedPropertiesList and properties
      const linked = this.linkedProperties[key];
      if (linked || (saved && saved.type === 'linkedentityid')) {
        /* linked: { id: number, name: string } */
        if (!multiV) this.properties[key] = [linked];
        this._propertyInfo[key].type = 'linkedentityid';
      }

      // TODO: handle EventDateTimeRange
      // this._propertyInfo[key].type = 'eventdatetimerange';

      let cfcid = this.onLoad_properties[key + '_cfcid'];
      let specifytext = this.onLoad_properties[key + '_specifytext'] || '';
      if (cfcid || (saved && saved.type === 'customfieldchoiceid')) {
        cfcid = typeof cfcid === 'number' ? cfcid : parseInt(cfcid, 10);
        specifytext = specifytext === '' ? undefined : specifytext;
        /* linked: { id: number, value: string } */
        if (!multiV)
          this.properties[key] = [
            { id: cfcid, optionName: value, specifytext },
          ];

        // Temporary for formbuilder:
        Object.defineProperty(this.properties[key], 'value', {
          get: () => this.properties[key].optionName,
          set: () => this.properties[key].optionName,
          configurable: true,
        });
        this._propertyInfo[key].type = 'customfieldchoiceid';
      }

      if (
        this._propertyInfo[key].multiValued === false &&
        this._propertyInfo[key].type === 'generic'
      ) {
        const formats = [
          moment.ISO_8601,
          'M/D/YYYY',
          'MM/DD/YYYY',
          'YYYY-MM-DD',
          'YYYY/MM/DD',
        ];

        if (moment(value, formats, true).isValid() && isNaN(value))
          this.properties[key] = moment(value).format('YYYY-MM-DD');
        else
          this.properties[key] = value;
      }

      this._makeRootPropertyAlias(key);
    }
  }

  reset(): this {
    delete this.properties;
    this.properties = { };
    this._propertyInfo = { };
    this.fromAny(this);
    if(this.hasChildren){
      this.regetChildren();
    }
    this.resetActiveChanges();
    return this;
  }

  /**
   * Repopulates children lists (such as after a reset)
   * Note that, since children list population is handled by the classes extending warp entity, this method needs to be overridden to do anything
   */
  protected regetChildren(): void {
    console.warn('regetChildren in warp-entity model called without implementation!');
  }
  //#endregion

  //#region Dynamic Accessors and Change Detection
  _makeRootPropertyAlias(key: string) {
    // make an alias to properties on the object
    // eg. entity.firstname => entity.properties.firstname
    if (this[key] === undefined)
      Object.defineProperty(this, key, {
        get: () => {
          // for easy access to single values
          if (
            this.properties[key] instanceof Array &&
            this.properties[key].length === 1
          )
            return this.properties[key][0];
          else return this.properties[key];
        },
        set: this._captureChangesAlias(key, (val: PropertyType) => {
          this.properties[key] = val;
          this._propertyInfo[key].multiValued = val instanceof Array;
        }),
      });
  }

  resetActiveChanges(): this {
    this.activeChanges = false;
    return this;
  }

  _captureChangesAlias<T>(key: string, set: (v: T) => unknown) {
    return (value?: T) => {
      if (this._trackChanges) {
        const oldValue = this.properties[key];
        const ret = set(value);
        this._change.next({ key, oldValue, newValue: this.properties[key] });
        return ret;
      } else
        return set(value);
    };
  }

  /**
   * Emits a change event, **does not set the value**
   * @param key the key that was changed
   * @param oldValue the value before the change
   * @param newValue the value after the change
   */
  trackChange(key: string, oldValue?: PropertyType, newValue?: PropertyType): this;
  /**
   * Emits a change event, **does not set the value**
   * @param changeEvent the {@link WarpEntityChangeEvent} to be fired
   */
  trackChange(changeEvent: WarpEntityChangeEvent): this;
  trackChange(changeEventOrKey: WarpEntityChangeEvent | string, oldValue?: PropertyType, newValue?: PropertyType): this {
    let changeEvent = changeEventOrKey as WarpEntityChangeEvent;

    if (typeof changeEventOrKey === 'string')
      changeEvent = { key: changeEventOrKey, oldValue, newValue };

    if (!changeEvent.newValue)
      changeEvent.newValue = this.properties[changeEvent.key];

    this._change.next(changeEvent);
    return this;
  }

  trackChanges(enable: boolean): this {
    this._trackChanges = enable;
    return this;
  }

  /** For setting a property that wasn't loaded from api */
  property(name: string, ...values: SimpleProp[]): this {
    name = name.toLowerCase();

    this._captureChangesAlias(name, _ => this.properties[name] = values)();

    this.properties[name] = values;
    this._propertyInfo[name] = this._propertyInfo[name] || {
      type: 'generic',
      multiValued: values.length > 1,
    };

    this._makeRootPropertyAlias(name);
    // You KNOW I'm about that chaining life
    return this;
  }

  /** For setting a property that wasn't loaded from api */
  cfcProperty(
    name: string,
    ...values: (CustomFieldChoice | number)[]
  ): this {
    name = name.toLowerCase();

    this._captureChangesAlias(name, _ =>
      this.properties[name] = values.map((e) => ({
        id: typeof e === 'number' ? e : e.id,
      }))
    )();

    this._propertyInfo[name] = this._propertyInfo[name] || {
      type: 'customfieldchoiceid',
      multiValued: values.length > 1,
    };

    this._makeRootPropertyAlias(name);
    // You KNOW I'm about that chaining life
    return this;
  }

  /** For setting a property that wasn't loaded from api */
  appendLinkedProperty(name: string, ...entities: LinkedEntityInput[]): this {
    name = name.toLowerCase();

    if (this.properties[name])
      entities.forEach( e => {
        const id  = e instanceof WarpEntity ? e.guaranteedId : e;
        if (this.properties[name].findIndex( existing => existing.id === id) < 0)
          this.properties[name].push({
            id: e instanceof WarpEntity ? e.guaranteedId : (typeof e === 'number' ? e : e.id),
            name: typeof e != 'number' ? e.Text || e.name || e.optionName || e.label : undefined,
          });
      });
    else
      this.linkedProperty(name, ...entities);

    if (!this._propertyInfo[name])
      this._propertyInfo[name] = this._propertyInfo[name] || {
        type: 'linkedentityid',
        multiValued: entities.length > 1,
      };

    return this;
  }

  /** For setting a property that wasn't loaded from api */
  linkedProperty(name: string, ...entities: LinkedEntityInput[]): this {
    name = name.toLowerCase();

    this._captureChangesAlias(name, _ =>
      this.properties[name] = entities.filter(e => e !== undefined).map((e) => ({
        id: e instanceof WarpEntity ? e.guaranteedId : (typeof e === 'number' ? e : e.id),
        name: typeof e != 'number' ? e.Text || e.name || e.optionName || e.label : undefined,
      }))
    )();

    this._propertyInfo[name] = this._propertyInfo[name] || {
      type: 'linkedentityid',
      multiValued: entities.length > 1,
    };

    this._makeRootPropertyAlias(name);
    // You KNOW I'm about that chaining life
    return this;
  }

  /** returns an array representing the value or values of a field, empty array means null */
  getMultiProperty<T = LinkedEntity | CustomFieldChoice | SimpleProp>(unchangeableName: string): T[] {
    let val = this.properties[unchangeableName.toLowerCase()] || [];

    if (val instanceof Array)
      return val;
    else
      return [val];
  }

  /** returns the first value of a field, or undefined */
  getSingleProperty<T = LinkedEntity | CustomFieldChoice | SimpleProp>(unchangeableName: string): T {
    let val = this.properties[unchangeableName.toLowerCase()];

    if (val instanceof Array)
      return val[0] || undefined;
    else
      return val || undefined;
  }

  initChildrenList(key: string, entityTypeId: number, entities?: IGenericObject[]): string;
  initChildrenList(key: string, entityTypeId: number, linkBackCfim: string, entities?: IGenericObject[]): string;
  initChildrenList(key: string, entityTypeId: number, _arg1: string | IGenericObject[] = '', _arg2: IGenericObject[] = null): string {
    const linkBackCfim = typeof _arg1 === 'string' ? _arg1 : '';
    const entities = typeof _arg1 === 'string' ? _arg2 : _arg1;

    const _key = WarpEntity.getChildrenKey(key);
    if (this.properties[_key] === undefined)
      this.properties[_key] = { key, entityTypeId, linkBackCfim, entities: entities || [] };
    else
      this.properties[_key] = { key, entityTypeId, linkBackCfim, entities: entities === null ? this.properties[key].entities : entities };

    return _key;
  }
  //#endregion

  //#region Simple Getters for multi-valued properties
  getSingleValueProperty(key: string): SimpleProp | (LinkedEntity | CustomFieldChoice & { specifyText?: string }) {
    const prop = this.properties[key];
    if (prop === undefined) return undefined;
    return Array.isArray(prop) ? prop[0] : prop;
  }

  getSingleLabelForProperty(key: string): string {
    const prop = this.getSingleValueProperty(key) as any;
    if (prop === undefined) return undefined;
    return prop.name || prop.value || prop.optionName || prop.label || prop;
  }


  //#endregion

  //#region Files
  /** Get Information about a specific file, when you know the name eg. 'personImage.jpg' */
  getFileByName(name: string): DetailedFile;
  /** Get Information about a specific file, when you know the name eg. 'personImage.jpg' */
  // tslint:disable-next-line: unified-signatures
  getFileByName(name: string, multiple: false): DetailedFile;
  /** Get Information about a specific file, when you know the name eg. 'personImage.jpg' */
  getFileByName(name: string, multiple: true): DetailedFile[];
  getFileByName(
    name: string,
    multiple: boolean = false
  ): DetailedFile | DetailedFile[] {
    if (multiple)
      return (this.filesDetailed || []).filter(
        (f) => f.originalFileName === name
      );
    else
      return (this.filesDetailed || []).find(
        (f) => f.originalFileName === name
      );
  }

  /** Get Information about , when you know the name eg. 'personImage.jpg' */
  getFileByIdentifier(identifier: string): DetailedFile;
  /** Get Information about , when you know the name eg. 'personImage.jpg' */
  // tslint:disable-next-line: unified-signatures
  getFileByIdentifier(identifier: string, multiple: false): DetailedFile;
  /** Get Information about , when you know the name eg. 'personImage.jpg' */
  getFileByIdentifier(identifier: string, multiple: true): DetailedFile[];
  getFileByIdentifier(identifier: string, multiple: boolean = false) {
    if (multiple)
      return (this.filesDetailed || []).filter(
        (f) => f.identifier === identifier
      );
    else
      return (this.filesDetailed || []).find(
        (f) => f.identifier === identifier
      );
  }

  /** Get Information about a specific file, when you know the name eg. 'personImage.jpg' */
  getFilesOfType(type: string): DetailedFile;
  /** Get Information about a specific file, when you know the name eg. 'personImage.jpg' */
  // tslint:disable-next-line: unified-signatures
  getFilesOfType(type: string, multiple: false): DetailedFile;
  /** Get Information about a specific file, when you know the name eg. 'personImage.jpg' */
  getFilesOfType(type: string, multiple: true): DetailedFile[];
  getFilesOfType(type: string, multiple: boolean = false) {
    if (multiple)
      return (this.filesDetailed || []).filter(
        (f) => f.fileType === type || f.mimeType === type
      );
    else
      return (this.filesDetailed || []).find(
        (f) => f.fileType === type || f.mimeType === type
      );
  }
  //#endregion

  //#region Save and Sync
  /**
   * adds, enables, or disables a pre-save hook
   */
  modify_preSave(enable: boolean, _hook: (w: WarpEntity) => boolean = null) {
    if (!_hook)
      // enable | disable all
      this._saveHooks.forEach((v, k) => this._saveHooks.set(k, enable));
    else this._saveHooks.set(_hook, enable);
  }

  /**
   * Pre-Save Hook
   *
   * executed directly before getSyncObject
   *
   * can be disabled *per entity* with `modify_preSave`
   */
  preSave() {
    return Array(...this._saveHooks.entries())
      .filter((e) => e[1]) // only run enabled
      .reduce((prev, curr) => prev && curr[0](this), true);
  }

  /**
   * Creates an object to save data via ripple's API,
   * > **note:** *This will reset activeChanges*
   * @param entityType if no entityType is provided fields will be saved as
   * properties, cfcs or linked entities based on best guess when they were loaded
   */
  getSyncObject(entityType?: WarpEntityType): object {
    const structure =
      entityType ||
      Object.entries(this._propertyInfo).map(([key, val]) => ({
        key,
        type: val.type,
      }));
    return this._getSyncObjectByStructure(structure);
  }

  _getSyncObjectByStructure(
    structure: WarpEntityType | { key: string; type: DataFieldName }[]
  ) {
    // if preSave fails
    if (!this.preSave()) return null;
    const retObj = {
      entityId: undefined,
      id: undefined,
      entityTypeId: this.entityTypeId,
      multiValuedProperties: { },
      linkedProperties: { },
      linkedPropertiesList: [],
      properties: { },
      ParentID: this.parentID,
    };
    if (
      this.entityId !== undefined &&
      this.entityId !== null &&
      this.entityId !== -1
    )
      retObj.entityId = retObj.id = this.entityId;

    const structArray =
      structure instanceof WarpEntityType
        ? structure.getEssentialCFIMInfo()
        : structure;

    for (const cfim of structArray) {
      const value = this.properties[cfim.key];

      // The following tries to ensure we don't send empty values to the server
      // However, in JS/TS, 0 is falsy, so we need to check for that manually
      // 2024-10-28: When a linked entity list had its last entity removed, it doesn't get cleared due to the value being null. Thus, we added a special case for that
      if (value || value === 0 || value === '0')
        switch (cfim.type) {
          case 'linkedentityid':
           if (!(value instanceof Array))
              retObj.linkedProperties[cfim.key] = { id: value.id };
            else if (value.length > 1)
              retObj.linkedPropertiesList.push(
                ...value
                  .map((v) => ({ unchangeablename: cfim.key, id: v.id }))
                  .filter((v) => v.id)
              );
            else if (value.length > 0)
              retObj.linkedProperties[cfim.key] = { id: value[0].id };
            else
              retObj.linkedProperties[cfim.key] = '';
            break;

          case 'customfieldchoiceid':
            const key_cfcid = cfim.key + '_cfcid';
            const key_specifyText = cfim.key + '_specifytext';
            // tslint:disable-next-line: curly
            if (value instanceof Array) {
              if (value.length > 1) {
                retObj.multiValuedProperties[key_cfcid] = [];
                retObj.multiValuedProperties[key_specifyText] = [];
                value.forEach(choice => {
                  if (Array.isArray(choice.specifytext)) {
                    choice.specifytext.forEach(text => {
                      retObj.multiValuedProperties[key_cfcid].push(choice.id);
                      retObj.multiValuedProperties[key_specifyText].push(text || '');
                    })
                  } else {
                    retObj.multiValuedProperties[key_cfcid].push(choice.id);
                    retObj.multiValuedProperties[key_specifyText].push(choice.specifytext || '');
                  }
                });
              } else if (value.length > 0) {
                if (!Array.isArray(value[0].specifytext)) {
                  retObj.properties[key_cfcid] = value[0].id;
                  retObj.properties[key_specifyText] = value[0].specifytext;
                } else {
                  value[0].specifytext = value[0].specifytext.filter(t => !!t);
                  retObj.multiValuedProperties[key_cfcid] = Array(value[0].specifytext.length).fill(value[0].id);
                  retObj.multiValuedProperties[key_specifyText] = value[0].specifytext;
                }
              } else {
                retObj.properties[key_cfcid] = '';
                retObj.properties[key_specifyText] = '';
              }
            } else {
              retObj.properties[key_cfcid] = value.id;
              retObj.properties[key_specifyText] = value.specifytext;
            }
            break;

          default:
            // tslint:disable-next-line: curly
            if (value instanceof Array) {
              if (value.length === 0)
                retObj.properties[cfim.key] = ''; // This will clear for empty lists
              else
                retObj.multiValuedProperties[cfim.key] = value;
            } else
              retObj.properties[cfim.key] = value;
            break;
        }

      if (value === '' && cfim.type !== 'linkedentityid' &&  cfim.type !== 'customfieldchoiceid')
        retObj.properties[cfim.key] = '';
    }

    this.resetActiveChanges();
    return retObj;
  }
  //#endregion

  //#region Symbols and Helpers
  toString(): string {
    return `WarpEntity<${this.entityTypeId}>${this.entityId}`;
  }


  toJSON() {
    return { ...this, toJSON: undefined, onChange: undefined, _change: undefined };
  }

  getJSONReplacer(): (this: any, key: string, value: any) => any | null {
    return (key, value) => {
      if (typeof value === 'object' && (value instanceof Observable || value instanceof Subscription) )
        return undefined;

      return this.getJSONValue(key, value);
   }
  }

  getJSONValue(key: string, value: any): any | null {
    return value;
  }

  /**
   * Compares the properties of this entity to those of that, or to that itself
   * @param that another warp entity, or the properties of another entity
   * @returns true if every property is __equivalent__
   * @note __equivalent__ means loosely equating empty arrays, objects, & strings, \
   * null / undefined and arrays of one element\
   * eg. `null == undefined == [] == [{}] == {} == ""` and `x == [x]`
   */
  hasEquivalentProperties(that: WarpEntity | IGenericObject, debug = false): boolean {
    const oldEnt = this.properties;
    const newEnt = that instanceof WarpEntity ? that.properties : that;
    // let trueTests = {
    //   "15": 'test', "14": 1, "13": -1, "12": 0, "11": 993209, "10": 9.38, "9": true, "8": false,
    //   "7": ['test'], "6": [1], "5": [-1], "4": [0], "3": [993209], "2": [9.38], "1": [true], "0": [false]};
    // let falseTests = { "6": null, "5": undefined, "4": [null], "3": [undefined], "2": [{}], "1": {}, "0": [] };

    const isProblematic = (val) =>
      (val === undefined || val === null || val === '' || (typeof val === 'object' && !Object.values(val).some( x => !!x)));

    // objects with an id should only contain that id, in case of name changes
    const simplifyIdObject = (val) => val.id ? { id: val.id } : val;

    const jsonFriendly = (obj: object) => {
      const ret = { };
      // here we sort them alphabetically so they will be in the same order if they have the same keys
      Object.keys(obj).sort().forEach( key => {
        let element = obj[key];
        const isArray = Array.isArray(element);
        // replace problematic entries, even if they are in arrays
        if ((isArray && (element.length === 0 || element.every(x => isProblematic(x)))) || (!isArray && isProblematic(element)))
          return /* backend treats empty string as undefined => so we do not define here */;

        if (isArray) // remove any extra problematic entries, and strip object props that aren't id
          element = element.filter( e => !isProblematic(e) ).map( o => simplifyIdObject(o) );
        else if (key.indexOf('formbuilder--datetime-picker-') === 0)
          return; // element = moment(element).format('MM-DD-yyyy'); // missing time here but a good start.
        else
          element = simplifyIdObject(element);

        ret[key] = element;
      });
      return ret;
    };

    const o1 = jsonFriendly(oldEnt);
    const o2 = jsonFriendly(newEnt);

    const result = JSON.stringify(o1) === JSON.stringify(o2);
    if (!result && debug) console.log('Non-Equivalent Properties:', o1, o2, JSON.stringify(o1), JSON.stringify(o2));

    return result;
  }
  //#endregion

  //#region Static
  static from(entities: IWarpEntityInputObject): WarpEntity;
  static from(entities: IWarpEntityInputObject[]): WarpEntity[];
  static from(
    entities: IWarpEntityInputObject | IWarpEntityInputObject[]
  ): WarpEntity | WarpEntity[] {
    if (entities instanceof Array)
      return entities.map((ent) => new WarpEntity(ent));
    else return new WarpEntity(entities);
  }

  static emptyEntity(type: number): IWarpEntityInputObject {
    return {
      entityTypeId: type,
      entityId: -1,
      created: new Date(),
      properties: { },
    };
  }

  static getChildrenKey(key: string): string {
    return `Þ${key}_children`;
  }

  static isChildrenKey(key: string): string | false {
    return key.startsWith('Þ') && key.endsWith('_children') && key.substring(1, key.length - 9);
  }

  static haveEquivalentProperties(oldEnt: WarpEntity, newEnt: WarpEntity) {
    // if   both exactly equal, or exactly one exists, or have equiv props
    return (oldEnt === newEnt || (!oldEnt !== !newEnt) || oldEnt.hasEquivalentProperties(newEnt));
  }
  //#endregion
}
