import { Mutex } from 'async-mutex';
import {
  Component,
  OnInit,
  Input,
  TemplateRef,
  ContentChild,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';

import { VirtualScroller } from 'primeng/virtualscroller';
import { LazyLoadEvent } from 'primeng/api';

import {
  WarpEntityCacheFactoryService,
  WarpEntityServiceCache,
  CachedQueryToken,
  MessageService,
  WarpEntityLogService,
} from '@ripple/services';
import { WarpEntity, WarpEntityType, EntityFilter } from '@ripple/models';
import { BasePage } from '@ripple/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ripple-entity-scroll-view',
  templateUrl: './entity-scroll-view.component.html',
  styleUrls: ['./entity-scroll-view.component.scss'],
})
export class EntityScrollViewComponent
  extends BasePage
  implements OnInit, OnChanges {

    get componentName(): string {
      return 'EntityScrollViewComponent';
    }
  @Input() hideHeader = false;
  @Input() height = '500px';
  @Input() itemSize = 24;
  @Input() rows = 25;
  @HostBinding('class.ui-height-100') @Input() fullHeight = false;

  @Input() filter: EntityFilter;
  @Input() exclude: (number | WarpEntity)[];

  @Input() warpID;
  @Input() warpEntityType: WarpEntityType;
  @Input() entityService: WarpEntityServiceCache<WarpEntity>;

  @Input() caption: string;
  @Input() addButton: () => void;
  @Input() editButton: (we: WarpEntity) => void;

  @ViewChild('sv') primeScroller: VirtualScroller;

  // tslint:disable-next-line: no-any
  @ContentChild('container') container: TemplateRef<any>;

  @Output() changes = new EventEmitter<boolean>(false);

  totalEntities = 0;
  lastTotalEntities = 0;
  entities: WarpEntity[] = [];
  queryToken: CachedQueryToken<WarpEntity>;
  queryTotalSub: Subscription;
  entitySub: Subscription;
  justSearched = false;
  private mutex;

  constructor(
    protected serviceFactory: WarpEntityCacheFactoryService,
    protected messageService: MessageService,
    logService: WarpEntityLogService,
    private cd: ChangeDetectorRef
  ) {
    super(logService);
    this.mutex = new Mutex();
  }

  get warpEntityTypeName() {
    return this.warpEntityType ? this.warpEntityType.name : 'All';
  }

  ngOnInit(): void {
    this.entityService =
      this.entityService || this.serviceFactory.get(this.warpID);
    this.warpID = this.entityService.entityTypeId;

    // initialize the cache for this query
    this.setResultsEmpty();
    this.entities = Array.from({ length: this.rows + 1 });
    this.changes.emit(true);

    // this.log('Init', this.queryToken);
  }

  ngOnChanges(sc: SimpleChanges) {
    if (
      sc.exclude ||
      (sc.filter &&
        this.filter &&
        (!sc.filter.previousValue ||
          sc.filter.previousValue.identifier !== this.filter.identifier ||
          !sc.filter.currentValue ||
          sc.filter.previousValue._page !== sc.filter.currentValue._page))
    ) {
        this.refresh();
    }
  }

  public refresh() {
    this.justSearched = true;
    this.entities = Array.from({ length: this.rows + 1});
    if (this.primeScroller) {
         this.primeScroller.clearCache();
         this.primeScroller.scrollToIndex(0);
         this.primeScroller.loadPage(0);
     } else {
      this.changes.emit(true);
     }
  }


  setResultsEmpty() {
    const ent = new WarpEntity(WarpEntity.emptyEntity(-1));
    ent.id = -2;

    // const entities = { id: -2, entitytypeid: -2};
    // Array.prototype.splice.apply(this.entities, [
    //     ...[0, this.rows],
    //     ...entities,
    //   ]);
    // this.entities = [...this.entities];
    if (this.entities && this.entities.length > 0)
        this.entities =  [ent];
    else
        this.entities.push(ent);

    this.changes.emit(true);
  }

  showLoading() {
    const ent = new WarpEntity(WarpEntity.emptyEntity(-1));
    ent.id = -1;
    // const entities = [{ id: -1, entitytypeid: -1}];
    // Array.prototype.splice.apply(this.entities, [
    //     ...[0, this.rows],
    //     ...entities,
    //   ]);
    // this.entities = [...this.entities];
    if (this.entities && this.entities.length > 0)
        this.entities = [ent];
    else
        this.entities.push(ent);

    this.changes.emit(true);
  }

  lazyLoad(event: LazyLoadEvent) {
    if (this.filter) {
        // this.showLoading();
        this.mutex
        .acquire()
        .then(release => {
          // ...
          try {
            if (this.entitySub)
              this.entitySub.unsubscribe();
            this.queryToken = this.entityService.initQuery(this.filter, event.rows * 1);
            const totalSub = this.nextSub = this.queryToken.totalForFilter.subscribe(data => {
              if (data)
                this.totalEntities = data < 0 ? 0 : data;
            });

            this.nextSub = this.entitySub = this.queryToken.getPage(Math.floor(event.first / (event.rows * 1)), (event.rows * 1))
              .subscribe(entities => {
                if (entities.length === 0 && this.justSearched)
                  this.setResultsEmpty();
                else if (entities.length > 0)
                  this.handlePage(event, entities);
                else {
                  if (this.entities.indexOf(undefined) > 0) {
                    this.entities.length = this.entities.indexOf(undefined); // set the length
                    this.entities = [...this.entities];
                    this.changes.emit(true);
                  }
                }
              this.justSearched = false;
              release();
            });
            this.entitySub.add(totalSub);
          } catch {
            release();
          }});
    }
    else {
        this.setResultsEmpty();
    }
  }

  private handlePage(event: LazyLoadEvent, entities: WarpEntity[]) {
    const currLength = this.entities.indexOf(undefined); // have to use this as sometime event.first is wrong as the scroll was too fast.

    if (entities.length < (event.rows * 1) && this.justSearched) // only page
        this.entities.length = entities.length;
    else if (entities.length < (event.rows * 1)) // last page
        this.entities.length = event.first + entities.length;


    Array.prototype.splice.apply(this.entities, [
      ...[event.first, (event.rows * 1)],
      ...entities,
    ]);

    //TODO: this is inefficent because it will double check every entity immediately following one that should be deleted
    /*
     * TODO - but more important:
     * this will fuck with the pagination, because were changing the length of a list which is created lazily
     * so we will have gaps on later pages
     * THIS SHOULD BE HANDLED IN THE ENTITY FILTER OR CACHE-TOKEN LEVEL
     * */
    if (this.exclude && this.exclude.length > 0)
      for (let i = this.entities.length - 1; i >= 0; i--) {
        const entity = this.entities[i];
        if ( entity && (this.exclude.includes(entity) || this.exclude.includes(entity.entityId)) )
          this.entities.splice(i, 1);
      }
    // if (this.entities.indexOf(undefined) >= 0)
    //     this.entities.length = this.entities.indexOf(undefined) + (event.rows * 1); // set the length

    this.entities = [...this.entities];
    this.changes.emit(true);
    this.messageService.add(
      `EntityScrollView<${this.warpID}>`, `Inital LazyPage ${(event.first / event.rows)}`, this.entities, entities);
  }

  get min() {
    return Math.min;
  }

  initQuery() {
    this.queryToken = this.entityService
      .initQuery(this.filter);

    // if (this.queryTotalSub && !this.queryTotalSub.closed)
    //   this.queryTotalSub.unsubscribe();

    // this.queryTotalSub = this.queryToken.totalForFilter.subscribe((total) => {
    //   if (total < 0) total = 0;
    //   this.totalEntities = total;
    //   if (this.entities.length !== total) {
    //     if (this.primeScroller) this.primeScroller.clearCache();
    //     this.entities = Array.from({ length: total });
    //     this.log(`Total Entities: ${this.totalEntities}`);
    //   }
    // });
  }

  protected log(...args) {
    this.messageService.add(`EntityScrollView<${this.warpID}>`, ...args);
  }
}
