<div class="attrib">
  <i class="attrib__icon" [ngClass]="iconClass" *ngIf="showIcon"></i>
  <div class="attrib__field" [ngClass]="{'attrib__field--with-action-button' : actionButtonIconClass}" [ngStyle]="{'backgroundColor': fieldBgColor ? fieldBgColor : 'var(--gray100)'}">
      <label>{{label}}</label>
      <div *ngIf="!link" class="value" [innerHTML]="value"></div>
      <div *ngIf="subText" class="sub-text" [innerHTML]="subText"></div>

      <a *ngIf="link" class="value" [innerHTML]="value" [href]="link"></a>

      <button class="r-btn r-btn--icon r-btn--icon-default attrib__action-button" (click)="onActionButtonClick()" *ngIf="actionButtonIconClass">
        <i [ngClass]="actionButtonIconClass"></i>
      </button>
  </div>
</div>