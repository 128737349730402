import { Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, Subject } from 'rxjs';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService, CachedPageToken } from '@ripple/services';
import { Contact, EntityTypes, Organization } from '../../models';
import { EntityFilter } from '@ripple/models';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends WarpEntityServiceCache<Organization> {

  constructor(injector: Injector) {
    super(injector, Organization, EntityTypes.Organization);
  }

  public getOrganizationDistance(organizationId: number, communityId: number): Observable<string> {
    const body = {
      organizationId,
      communityId
    };
    return this.warpService._post('/api/sagesse/organizationDistance/', body);
  }

  public getOrganizationDistanceWithPostalCode(organizationId: number, postalCode: string): Observable<string> {
    const body = {
      organizationId,
      postalCode
    };
    return this.warpService._post('/api/sagesse/organizationDistanceWithPostalCode/', body);
  }

  public organizationPage(q: string, c: string, t: string, teamIds: string, first: number, size: number, claresLawOnly = '0', sortByDistance = '0', sortByTagMatches = '0', sortByOfficeDistance = '0'): Observable<any> {
    return this.warpService._get(`/api/sagesse/organization/page?q=${q}&c=${c}&t=${t}&teamIds=${teamIds}&first=${first}&size=${size}&claresLawOnly=${claresLawOnly}&sortByDistance=${sortByDistance}&sortByTagMatches=${sortByTagMatches}&sortByOfficeDistance=${sortByOfficeDistance}`);
  }

  public getTeamOrganizations(teamId, organizationIds: string, selectedOnly = '1'): Observable<any[]> {
    if (!organizationIds) {
      organizationIds = '-1';
    }
    return this.warpService._get(`/api/sagesse/teamOrganizations/${teamId}/${organizationIds}?selectedOnly=${selectedOnly}`);
  }

  public getOrganizationAuxInfo(organizationId: number): Observable<any[]> {
    return this.warpService._get(`/api/sagesse/organization/auxInfo/${organizationId}`);
  }
}
