<div class="ui-widget">
    <label>
        Tags
    </label>
</div>
<p-autoComplete [(ngModel)]="entity?.properties.entitytag" [suggestions]="filteredTags"
    (completeMethod)="search($event)" field="name" [multiple]="true" (keyup.Enter)="search($event, true)" (onBlur)="search($event, true)"
    [style]="{'width':'100%', 'max-width':'100%'}" [appendTo]="appendTo">
</p-autoComplete>
<div *ngIf="adding">
    Creating&nbsp;<i class="pi pi-spinner pi-spin"></i>
</div>