import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { WarpEntityServiceCache} from '@ripple/services';
import { Contact, EntityTypes } from '../../models';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ContactService extends WarpEntityServiceCache<Contact> {

  constructor(injector: Injector) {
    super(injector, Contact, EntityTypes.Contact);
  }

  public contactPage(firstName: string, lastName: string, email: string, phoneNumber: string, first: number, size: number): Observable<any> {
    return this.warpService._get(`/api/sagesse/contact/page?firstname=${firstName}&lastname=${lastName}&email=${email}&phonenumber=${phoneNumber}&first=${first}&size=${size}`);
  }

  public checkEmailExist(contactId: number, email: string) {
    return this.warpService._get(`/api/sagesse/checkEmailExist/${contactId}?email=${email}`);
  }
}
