<ripple-loading-box [loading]="hideAllContent()">
    <div class="app-container srm-container" *ngIf="!hideAllContent()">
        <ripple-menu
            logoPath="assets/img/logos/sagesselogo.png"
            logoPrintPath="assets/img/logos/sagesselogo.png"
            brandName=""
            [staging]="staging"
            [sectionItems]="sections"
            [currentView]="currentView"
            [userMenuItems]="userMenuItems"
            [viewAllNotificationLink]="'/settings/notification'"
            *ngIf="layoutSettings.showMenuBar"
            [showPrimaryMenu]="layoutSettings.showMenuBarPrimaryMenu"
            [showProfileHelpMenu]="layoutSettings.showMenuBarProfileHelpMenu"
            [showNotifications]="false"
            [showSignalRCheck]="false"
            [menuFullAt]="'xxl'"
            [extraNeedHelps]="extraNeedHelps"> 
            <!-- layoutSettings.showMenuBarNotifications -->
        </ripple-menu>
    
        <div [ngClass]="{ 'app-main': layoutSettings.showMenuBar, 'app-main--no-header': !layoutSettings.showMenuBar }">
            <div class="layout-wrapper">
                <div class="layout-main bg-white">
                    <div class="main-container">
                        <div class="router-outlet-container">
                            <p-messages [(value)]="msgs" [enableService]="false"></p-messages>
                            <router-outlet></router-outlet>
                        </div>
                        <div class="referral-bar-wrapper" *ngIf="layoutSettings['showClaresLawReferralBar'] && cookieService.getCookie('referenceNumber')">
                            <sagesse-clares-law-referral-bar></sagesse-clares-law-referral-bar>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <ripple-footer-bar [copyrightName]="'Sagesse'" *ngIf="layoutSettings.showFooterBar">
        </ripple-footer-bar>
    </div>
</ripple-loading-box>
