import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class TeamType extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.TeamType;
    }

    static empty(): TeamType {
        return new TeamType(super.emptyEntity(EntityTypes.TeamType));
    }

}