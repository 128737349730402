import { Component, OnInit } from '@angular/core';
import { PrivacyService } from '@ripple/services';

@Component({
  selector: 'ripple-privacy-button',
  templateUrl: './privacy-button.component.html',
  styleUrls: ['./privacy-button.component.scss']
})
export class PrivacyButtonComponent implements OnInit {

  constructor(public privacyService: PrivacyService) { }

  ngOnInit(): void {
  }

}
