import { inject, InjectionToken } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { SiteSettingsService } from '@ripple/services';


export interface FeatureDescriptor {
  name: string;
  defaultOn?: boolean;
}

export function FeatureGuard(..._features: (string | FeatureDescriptor)[]) {
  const features = _features.map(
    featureDescriptor => typeof featureDescriptor === 'string' ? { name: featureDescriptor, defaultOn: false } : featureDescriptor)

  //TODO: memorize these
  return new InjectionToken<CanActivate>(
    `SpecializedFeatureGuard[${features.map( f => f.name)}]`,
    {
      providedIn: 'root',
      factory: () => {
        const siteSettingsService = inject(SiteSettingsService);
        const router = inject(Router);
        return {
          async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
            const settings = await siteSettingsService.getSettings();

            return features.every(({name, defaultOn = false}) =>
                settings.has(name) ? settings.get(name) : defaultOn
              ) || router.createUrlTree(route.parent.url); // double check this works
          }
        };
      },
    });
}
