import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ripple-resizable-layout',
  templateUrl: './resizable-layout.component.html',
  styleUrls: ['./resizable-layout.component.scss']
})
export class ResizableLayoutComponent implements OnInit, AfterViewInit {

  @Input() defaultLeftWidth = '20%';
  @Input() defaultRightWidth = '80%';

  @ViewChild('resizableSeparator') resizableSeparator: ElementRef;
  @ViewChild('resizableLeft') resizableLeft: ElementRef;
  @ViewChild('resizableRight') resizableRight: ElementRef;

  mouseDown: any;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.dragSeparator(this.resizableSeparator.nativeElement, 'H');
  }

  dragSeparator(element, direction) {
    const left  = this.resizableLeft.nativeElement;
    const right = this.resizableRight.nativeElement;

    const onMouseMove = (e) => {
      const delta = {
        x: e.clientX - this.mouseDown.e.clientX,
        y: e.clientY - this.mouseDown.e.clientY
      };

      if (direction === 'H' ) // Horizontal
      {
      // Prevent negative-sized elements
        delta.x = Math.min(Math.max(delta.x, -this.mouseDown.leftWidth), this.mouseDown.rightWidth);

        element.style.left = (this.mouseDown.offsetLeft + delta.x) + 'px';
        left.style.width = (this.mouseDown.leftWidth + delta.x) + 'px';
        right.style.width = (this.mouseDown.rightWidth - delta.x) + 'px';
      }
    };

    const onMouseDown = (e) => {
      this.mouseDown = {e,
        offsetLeft:  element.offsetLeft,
        offsetTop:   element.offsetTop,
        leftWidth:  left.offsetWidth,
        rightWidth: right.offsetWidth
      };

      document.onmousemove = onMouseMove;
      document.onmouseup = () => {
          document.onmousemove = document.onmouseup = null;
      };
    };

    element.onmousedown = onMouseDown;
  }
}
