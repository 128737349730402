//#region Imports
import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  HostListener,
  OnChanges,
  SimpleChanges,
  KeyValueDiffers,
  KeyValueDiffer,
  ElementRef,
  ViewChild,
  Inject,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

import { FormlyJsonschema } from '@ngx-formly/core/json-schema';

import { Observable, Subscription, Subject, from, combineLatest, ReplaySubject, BehaviorSubject } from 'rxjs';
import { first, debounceTime, filter } from 'rxjs/operators';

import * as moment_ from 'moment';
const moment = moment_;




import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ConfirmationService, Message } from 'primeng/api';

import {
  WarpEntityType,
  IFormBuilderFrontendTemplate,
  KEY_CODE,
  IFilledFieldObject,
  IGenericObject,
  ISectionFrontendTemplate,
  IFieldFrontendTemplate,
  FormGroupContainer,
  Identifiable,
  WarpEntity,
  IRippleFrontendTemplate,
  IFilterableEntityTypeDescriptor,
  IFieldSectionFrontendTemplate,
} from '@ripple/models';

import {
  AuthService,
  MessageService,
  EntityFocusService,
  FocusSession,
  LocalEntityBackupService,
  GenericWarpEntityService,
  InternalCookieService,
} from '@ripple/services';

import { environment } from '@ripple/environment';

import {
  formFieldFactory,
  formSectionFactory,
  FormSection,
  FormField,
  FormBuilderPopup,
} from './lib';

import { FormBuilderService } from './form-builder.service';
import type { ExtendedFormlyFieldConfig, FieldTypeName, IQuestionTypeDescriptor } from './service-resources';
import { FormActionModalComponent } from './form-actions/form-action-modal/form-action-modal.component';
//#endregion Imports

type SectionOrField = ISectionFrontendTemplate | IFieldFrontendTemplate;
type SectionOrFieldOrArray = SectionOrField | SectionOrField[];

export type FormBuilderActionMode = 'view' | 'fill' | 'build';
export type SubmitAction = 'save' | 'submit' | 'copy';
export interface FormBuilderSaveEvent {
  formData: WarpEntity;
  action: SubmitAction;
}
export interface FormBuilderImportFieldData {
  label: string;
  questionType: 'Text' | 'Text Area' | 'Radio' | 'Checkbox';
  options: string[];
}

export interface FormBuilderFormState {
  entityType: BehaviorSubject<WarpEntityType>;
  formDataEntity: BehaviorSubject<WarpEntity>;
  showChanges: boolean;
}

export interface FormBuilderFormlyFormOptions extends FormlyFormOptions {
  formState: FormBuilderFormState;
}

@Component({
  selector: 'ripple-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
})
export class FormBuilderComponent
  implements
    FormGroupContainer,
    OnInit,
    OnDestroy,
    OnChanges /*, AfterContentInit, AfterViewInit*/ {
  //#region Properties
  subs: Subscription[] = [];

  readonly SUB_SECTION_TYPE = 'Sub-section';
  readonly SECTION_TYPE = 'Section';

  // ---------- Inputs ---------- //
  // View
  @Input() action: FormBuilderActionMode = 'fill';
  @Input() draftView = false;
  @Input() theme: string = '';
  @Input() formbuilderDestination = 'administration/formbuilder';

  // Structure
  /** Form EntityTypeID */
  @Input() formID: Subject<number> | number;
  @Input() formEntity: Subject<WarpEntityType>;
  // this is a copy of formEntity, but accessible before ngOnInit is called
  private formStructure: Subject<WarpEntityType>;

  // Customize UI
  @Input() set showChanges(value: boolean) {
    this.options.formState.showChanges = value;
  }

  @Input() parentOptionsFilter: (string | { value: string })[] = [];
  @Input() forceShowValidation: boolean = false;
  private _parentOptionFilter: string[];

  // Data
  @Input() filledFields: Subject<IFilledFieldObject[]>;
  @Input() formData: WarpEntity | Observable<WarpEntity>;
  private _formData: Subject<WarpEntity>;
  private _formDataEntity: WarpEntity;
  private _incomingFormDataEntity: WarpEntity;
  backupInterval = LocalEntityBackupService.DEFAULT_BACKUP_INTERVAL;
  /**
   * enables or disables entity focus. (a focused entity with disable most signalR updates for entityTypes specified)
   */
  @Input() focusEntityOnEdit = true;
  /**
   * specifies which entityTypes will not receive updates while editing
   */
  @Input() onFocusIgnoreTypes: number[] = [];
  private focusSession: FocusSession;

  // ----- Change Detection ----- //
  @Output() workingChanges: EventEmitter<boolean> = new EventEmitter();
  activeChanges = false;

  incomingChangesDialogPopup = false;
  public ignoredIncomingChanges = false;
  activeModelChanges = false;
  @Output() modelChange: EventEmitter<boolean> = new EventEmitter();
  private _modelDiffer: KeyValueDiffer<string, unknown>;
  private _diffCheckInterval;
  @Output() done: EventEmitter<boolean> = new EventEmitter();

  // ------- Capture Save ------- //
  /** to cancel the API call to save */
  @Input() overrideSave = false;
  /** sends the saveData, whether sent to API or not */
  @Output()
  saveFilledFormData: EventEmitter<FormBuilderSaveEvent> = new EventEmitter();
  @Output() formSaved: EventEmitter<boolean> = new EventEmitter();
  /** on successful save, to reset save animations */
  @Input() successfulSave = new Subject<boolean>();
  successfulSaveSubscription: Subscription;
  @Input() hideSubmit = false;
  @Input() hideControls = false;

  /** fires on save or publish */
  @Output() formbuilderSave = new EventEmitter<boolean>();
  /** fires on copy finish */
  @Output() formbuilderCopy = new EventEmitter<number>();

  @Input() copyOverride: boolean = false;
  @Input() overrideDestination: string = null;

  @ViewChild('formActionModal') formActionModal:FormActionModalComponent;



  // --------- Internal --------- //
  /** Form EntityTypeID */
  _formID = 100023;
  /** Form EntityTypeID */
  _formIdObs = new Subject<number>();
  /** Form EntityTypeID */
  _formIdSub: Subscription;
  _formEntity: WarpEntityType;

  _filledFields: IFilledFieldObject[];

  readonly parent = undefined;
  get label() {
    return 'Form Builder';
  }


  defaultName = 'New Form';
  _name = this.defaultName;
  get name() {
    return this._formEntity ? this._formEntity.name : this._name;
  }
  set name(n) {
    if (this._name !== n) {
      this.toPublish = false;
      this.activeChanges = true;
    }

    this._name = n;
    if (this._formEntity) this._formEntity.name = n;
  }
  get id() {
    return this._formEntity ? this._formEntity.id : this._formID;
  }

  get builderControls() {
    return this.development || !this.userUse;
  }

  // -------- Mode Flags -------- //
  userUse = false;
  viewOnly = false;
  canSwitch = false;
  debugTools = false;
  development = false;

  isRippleAdmin = false;
  _requiresRippleApproval = false;
  get requiresRippleApproval() {
    return false;// see comment below
    return this._requiresRippleApproval;
  }
  set requiresRippleApproval(r) {
    // TB: Nov 1, 2023 - I'm removing this requirement.  From a client management perspective, it's become too arduous, on the rare occasion
    // a client does mess it up, we can always go and fix it using the version history

    this._requiresRippleApproval = false;
    // if (this.isRippleAdmin && this._requiresRippleApproval !== r) {
    //   this._requiresRippleApproval = r;
    //   this.toPublish = false;
    //   this.activeChanges = true;
    // }
    // else if (this._requiresRippleApproval && !r) {
    //   // a user is clicking the button to remove the ripple approval requirement,
    //   // here we can give a dialog to show the support email
    // }
  }

  containsCustomJson = false;
  get overrideNeedApproval() {
    return false; // Everyone can publish, see comment in requireRippleApproval
    return this.isRippleAdmin && !this.requiresRippleApproval && this.containsCustomJson;
  }

  /** whether to show or hide the edit tools */
  get hideOptions() {
    return this.userUse; // this.action === 'build';
  }

  readonly rippleSupportEmail = 'support@izzyplatform.ca';
  get publishToolTip() {

    // if (this.overrideNeedApproval)
    //   return 'This form contains advanced features. Ensure they are simple enough to still allow publishing by clients.';
    // if (this.isRippleAdmin)
    //   return 'Ensure all advanced features have been addressed before publishing, or disable ripple approval.';
    // else if (this.requiresRippleApproval)
    //   return `This form contains advanced features. Please contact ${this.rippleSupportEmail} to publish changes.`;
    // else return nothing
    return '';
  }

  // ----- Messages & Flags ----- //
  msgs: Message[] = [];
  ready = false;
  saving = false;
  toPublish = false;
  submitting = false;

  submittingAction: SubmitAction;

  get disableSave() {
    return this.submitting;
  }
  get disableSubmit() {
    return this.submitting || !this.form.valid;
  }
  get isSaving() {
    return this.submitting && this.submittingAction === 'save';
  }
  get isSubmitting() {
    return this.submitting && this.submittingAction === 'submit';
  }

  lastSelectedFieldType = '';

  // ---------- Popups ---------- //
  popup: FormBuilderPopup;
  popupJsonEditorOptions: JsonEditorOptions;

  popupEditJson = false;
  showCorrection = false;
  displayMoveComponents = false;
  displayCopyFields = false;
  closeConfirmActive = false;
  duplicateKeysFound = false;

  // -------- Versioning -------- //
  currentVersion: {
    id: number;
    isLatest: boolean;
    isPublished: boolean;
  };
  versionsPopup = false;
  copyVersionMode = false;
  copyDialogPopup = false;
  copyStatus = {
    copying: false,
    copyFinished: false,
    copyError: false,
    copiedFormID: -1,
    text: 'Ready to copy.'
  };
  copyTarget: IRippleFrontendTemplate = null;
  versions: IRippleFrontendTemplate[] = [];

  // -------- Drag & Drop ------- //
  targetSectionID = '0';
  targetMoveComponent = '';
  targetMoveComponentDisplay = '';
  dragAndDropArrays: Identifiable[] = [];
  fieldCopyOptions: Identifiable[] = [];
  draggedComponent: Identifiable;

  // ------- Static Forms ------- //
  questionDataTypeDict = {};
  sectionFields: FormlyFieldConfig[];
  originalFieldsConfig: ExtendedFormlyFieldConfig[] = [];

  // --------- The Form --------- //
  form = new FormGroup({});
  public get formGroup() { return this.form; }

  fields: FormlyFieldConfig[] = [];
  sections: FormSection[] = [];
  model: IGenericObject = {};
  options: FormBuilderFormlyFormOptions = {
    formState: {
      entityType: new BehaviorSubject<WarpEntityType>(null),
      formDataEntity: new BehaviorSubject<WarpEntity>(null),
      showChanges: false,
    }
  };
  duplicateKeyMap: Map<string, number> = new Map<string, number>();

  formActionsV2: any = {};
  permissions = [];
  parentOptions: { label: string; value: string }[] = [];

  // ------- Autofill Signature ------- //
  signatureKeys: string[] = [];
  doneSignatureCheck = false;
  user: WarpEntity;
  signAndSubmit: boolean;
  alreadySigned: boolean = false;
  @Input() hideAutofillSignatures = false;
  showReSignConfirmationDialog = false;
  hasMadeSignatureDecision = false;
  overrideSignatures = false;

  //#endregion Properties

  constructor(
    private authService: AuthService,
    public messageService: MessageService,
    protected localBackup: LocalEntityBackupService,
    private formlyJsonschema: FormlyJsonschema,
    private service: FormBuilderService,
    private genericWarpEntityService: GenericWarpEntityService,
    private entityFocus: EntityFocusService,
    private differs: KeyValueDiffers,
    private confirm: ConfirmationService,
    public router: Router,
    private elementRef: ElementRef,
    cookieService: InternalCookieService,
  ) {
    const setOrAppend = (name, data) => {
      if (this[name] instanceof Array) this[name].push(data);
      else this[name] = [data];
    };
    const assign = (
      names: string | string[],
      array?: boolean,
      then?: (data) => void
    ) => {
      return (data) => {
        if (!(names instanceof Array)) names = [names];
        names.forEach((name) => setOrAppend(name, data));
        if (then) then(data);
      };
    };

    // TRACK WHEN THE USER CLICKS OUTSIDE OF THE FormBuilderPopup TO PREVENT LOST EDITS/ADDITIONS
    document.onmousedown = (event) => {
      if (this.action === 'build'
        && event.button === 0
        && event.target instanceof Element
        && !this.closeConfirmActive
        && this.popup
        && this.popup.showSidebar
        && /(add|apply)$/i.test(this.popup.action)
        && !event.target.closest('.ui-sidebar--formbuilder, .ui-dropdown-panel, .ui-dialog--jsoneditor, .ui-multiselect-items-wrapper' )
      ) {
        event.stopPropagation();

        if (this.popupEditJson)
          return;

        this.closeConfirmActive = true;

        this.confirm.confirm({
          message:
            'You have unsaved changes in the editing sidebar, are you sure you want to close it?',
          accept: () => {
            this.popup.hide();
            this.closeConfirmActive = false;
          },
          reject: () => {
            this.closeConfirmActive = false;
          },
        });
      }
    };

    this.formStructure = new ReplaySubject(1);
    this._formData = new ReplaySubject(1);
    this.focusSession = this.entityFocus.createSession(this);

    this.options.formState.showChanges = cookieService.getBooleanCookie('ShowActiveChanges')
    this.authService.isRippleAdmin()
      .then((isRippleAdmin) => this.isRippleAdmin = isRippleAdmin);

    this.popupJsonEditorOptions = new JsonEditorOptions();
    this.popupJsonEditorOptions.mode = 'view';
    this.popupJsonEditorOptions.modes = ['code', 'form', 'text', 'tree', 'view'];

    this.subs.push(
      this.workingChanges
        .asObservable()
        .subscribe((activeChange) => (this.activeChanges = activeChange)),

      service.getSectionFields()
        .subscribe(assign(['sectionFields'], true)),

      service.getFieldFields()
        .subscribe(
          assign(['originalFieldsConfig'], true, (el) =>
            this.initFieldConfigByElement(el)
          )
        ),

      service.getQuestionDataTypes()
        .subscribe((t) => (this.questionDataTypeDict[t.type] = t.dataType)),

      this.formStructure.subscribe(this.options.formState.entityType),

      this._formData.subscribe((we) => {
        this.log('Updated FormData', we);
        this.loadEntityData(we);
        this.initModelChecker();
        this.options.formState.formDataEntity.next(we);
      }),
    );

    this.service
      .getFormsParent()
      .pipe(first())
      .toPromise()
      .then((opts) =>
        this.parentOptions = opts.filter(o => (!this._parentOptionFilter || this._parentOptionFilter.includes(o.value) ))
      );

    this.duplicateKeyMap = new Map<string, number>()
  }

  ngOnDestroy(): void {
    this.focusSession.end();
    this.subs.forEach((sub) => sub.unsubscribe());
    if (this._diffCheckInterval)
      clearInterval(this._diffCheckInterval);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.successfulSave && this.successfulSave) {
      if (this.successfulSaveSubscription)
        this.successfulSaveSubscription.unsubscribe();
      this.successfulSaveSubscription = this.successfulSave.subscribe((success) =>
        this.onSubmitForm(success)
      );
    }

    if (changes.parentOptionsFilter && this.parentOptionsFilter) {
      this._parentOptionFilter = this.parentOptionsFilter.map( o => (typeof o !== 'string' && 'value' in o) ? o.value : o);
    }

    if (changes.formData && this.formData) {
      if (this.formData instanceof WarpEntity)
        this._formData.next(this.formData);
      else this.formData.subscribe(this._formData);
    }

    if (changes.formID && this.formID !== changes.formID.previousValue) {
      if (typeof this.formID === 'number')
        this._formIdObs.next(this.formID);
      else if (this.formID instanceof Subject) {
        if (this._formIdSub && !this._formIdSub.closed)
          this._formIdSub.unsubscribe();
        this._formIdSub = this.formID.subscribe(this._formIdObs);
      } else
        this._formIdObs.next(-1);
    }

    if (changes.showChanges)
      this.options.formState.showChanges = this.showChanges;
  }

  //#region Init
  initFieldConfigByElement(el: ExtendedFormlyFieldConfig) {
    // this.log('INIT FIELD CONFIG BY ELEMENT: ', el, MessageService.VERBOSE);
    switch (el.internalID) {
      case 'question-type-field':
        el.templateOptions.options = el.templateOptions.options || [];
        el.templateOptions._options = el.templateOptions._options || [];
        // try assigning this.service.getQuestionData() directly to el.templateOptions.options
        this.subs.push(
          this.service
            .getQuestionData()
            .subscribe((opt) => {
              (el.templateOptions.options as IQuestionTypeDescriptor[]).push(opt);
              (el.templateOptions._options as IQuestionTypeDescriptor[]).push(opt);
            }
            )
        );
        break;
      case 'question-entity-list':
        el.templateOptions.options = el.templateOptions.options || [];
        this.subs.push(
          this.service
            .getEntityList()
            .subscribe((opt) =>
              (el.templateOptions
                .options as IFilterableEntityTypeDescriptor[]).push(opt)
            )
        );
        break;
    }
    return el;
  }

  ngOnInit(): void {
    this.userUse = this.action !== 'build';
    this.viewOnly = this.action === 'view';
    this.popup = FormBuilderPopup.empty;

    if (!this.formID && !this.formEntity) this.initMetadata();

    if (!this.formEntity) this.formEntity = new Subject<WarpEntityType>();

    this._formIdObs.subscribe((formId) => {
      if (typeof (formId) === 'string' && !isNaN(parseInt(formId, 10)))
      formId = parseInt(formId, 10);

      this.ready = false;
      this._formID = formId;
      if (this._formID !== -1)
      this.service
      .getForm(this._formID)
      .pipe(first())
      .toPromise()
      .then(t => this.formEntity.next(t.clone()) ); // KG: remove dependency on lodash (mostly deprecated now)
      else this.initMetadata();
    });

    this.formEntity.subscribe((form) => {
      this.ready = false;
      if (form == null) return this.initMetadata();
      this._formID = form.id;
      this.initFormEntity(form);
      this.toPublish = true;
      this.formStructure.next(form);

      this.duplicateKeyCheck();
    });

    if (this.filledFields)
      this.filledFields.subscribe((fif) => {
        this._filledFields = fif;
        this.fillInPresetValues(this.getCurrentFormBuilderData());
      });

    this.workingChanges.emit(false);

    if (typeof this.formID === 'number') this._formIdObs.next(this.formID);
    else if (typeof this.formID === 'string' && !isNaN(parseInt(this.formID, 10))) {
      this._formIdObs.next(parseInt(this.formID, 10));
    }
    else {
      if (this._formIdSub && !this._formIdSub.closed)
        this._formIdSub.unsubscribe();
      this._formIdSub = this.formID.subscribe(this._formIdObs);
    }

    if (this.userUse)
      this.subs.push(
        this.form.valueChanges
          .pipe(debounceTime(this.backupInterval))
          .subscribe(weChanges => this.backupEntity())
      );

    if (this.focusEntityOnEdit && this.userUse && !this.viewOnly) {
      this.log('Focus Enabled');
      this.subs.push(
        combineLatest([
          this.formStructure.pipe( filter(v => !!v)),
          this._formData.pipe( filter(v => !!v))])
        .subscribe(([structure, data]) => {
          const useTypes = this.onFocusIgnoreTypes && this.onFocusIgnoreTypes.length > 0;
          this.focusSession.on(data, useTypes ? this.onFocusIgnoreTypes : structure.ancestorEntityTypeIds);
        })
      );
    }

    const state = history.state;
    this.log(state);
    if (state && state.title && state.datas) {
      this.generateFormFromImport(state.title, state.datas);
    }
  }


  // ngDoCheck(): void {
  //   // this only checks to see if the model has changed
  //   const mChanges = this._modelDiffer.diff(this.model);
  //   if (mChanges)
  //     this.modelChanged(mChanges);
  // }


  backupEntity() {
    if (this.form.dirty) {
      const toSave = new WarpEntity(this._formDataEntity);
      toSave.properties = this.model;
      this.localBackup.storeEntity(toSave);
    }
  }

  loadVersion(version: IRippleFrontendTemplate) {
    this.initFormEntity(this._formEntity, version);
    this.duplicateKeyCheck();
    this.toPublish = true;
  }

  showCopyDialog(version: IRippleFrontendTemplate) {
    this.copyStatus.copying = false;
    this.copyStatus.copyFinished = false;
    this.copyStatus.copyError = false;
    this.copyStatus.copiedFormID = -1;
    this.copyStatus.text = 'Ready to copy.';
    this.copyTarget = JSON.parse(JSON.stringify(version));
    this.copyTarget.FrontendTemplate.name = this.copyTarget.FrontendTemplate.name + ' - Copy';
    this.copyDialogPopup = true;
  }

  copySelectedVersion() {
    this.copyStatus.copying = true;
    this.copyStatus.text = 'Copying...';
    this.service.saveFormBuilt(this.copyTarget.FrontendTemplate, -1).toPromise().then((value) => {
      if (value && value.ID) {
        this.copyStatus.copying = false;
        this.copyStatus.copyFinished = true;
        this.copyStatus.copyError = false;
        this.copyStatus.copiedFormID = value.ID;
        this.copyStatus.text = 'Copy finished.';
        this.formbuilderCopy.emit(value.ID);
      } else {
        this.copyStatus.copying = false;
        this.copyStatus.copyFinished = false;
        this.copyStatus.copyError = true;
        this.copyStatus.copiedFormID = -1;
        this.copyStatus.text = 'Copy error, please retry or contact administrator.';
      }

    }).catch((error) => {
      this.copyStatus.copying = false;
      this.copyStatus.copyFinished = false;
      this.copyStatus.copyError = true;
      this.copyStatus.copiedFormID = -1;
      this.copyStatus.text = 'Copy error, please retry or contact administrator.';
    });
  }

  goToNewForm(id: number) {
    if (!id || id === -1) {
      return;
    }
    this.log('Formbuilder side', this.copyOverride, this.overrideDestination, MessageService.VERBOSE);
    if (this.copyOverride) {
      window.open(`${environment.host}/${this.overrideDestination}`, '_blank');
    }
    else {
      window.open(`${environment.host}/${this.formbuilderDestination}/${id}`, '_blank');
    }
  }

  initMetadata() {
    // this.popup.model = {
    //   sectionName: 'Form Metadata',
    //   deletable: false,
    //   editable: false,
    //   hidden: true,
    // };
    // this.addASection();

    // this.sections[0].addAField({
    //   key: 'linked_parent',
    //   label: 'linked_parent',
    //   type: 'select-entity',
    //   deletable: false,
    //   hidden: true,
    // });
    this.buildForm(null, new Map<string, number>()); //TODO: this should be an environment variable with an override from ui
  }

  initFormEntity(
    entity: WarpEntityType,
    templateOvrde: IRippleFrontendTemplate = null
  ) {
    this.ready = false;
    this._formEntity = entity;

    let template =
      templateOvrde ||
      (this.userUse || !this.draftView
        ? this._formEntity.frontendTemplate
        : this._formEntity.frontendLatestDraft);

    // RS Feb 10 2021: For forms that have never been submitted before, draftView will be false and it wont properly
    // load the latest draft, thus loading a completely blank page when editing
    // Also fixed bug where the published form is always opened rather than the latest draft
    if (
      (template.ID === -1 || !template.IsLatestVersion) &&
      this._formEntity.frontendLatestDraft.ID > template.ID && !templateOvrde
    )
      template = this._formEntity.frontendLatestDraft;

    // Minh Oct 22 2021: If it's filling the form, force it to be frontEndTemplate even if it's empty
    // since for filling it requires the form to be published to run correctly. This is by design
    if (this.action === 'fill')
      template = this._formEntity.frontendTemplate;

    if (!this._formDataEntity)
      this.fillInPresetValues(this.getCurrentFormBuilderData());

    this.currentVersion = {
      id: template.ID,
      isLatest: template.IsLatestVersion,
      isPublished: template.IsPublished,
    };

    this.buildForm(template.FrontendTemplate, this.getOptionIds(entity));
  }

  duplicateKeyCheck() {
    this.searchForDuplicateKeys()
    .then((duplicates: Map<string, number>) => {
      this.log('Duplicates: ', duplicates);
      // If there are any duplicate keys, alert the user
      this.duplicateKeysFound = duplicates.size > 0;
      this.duplicateKeyMap = duplicates;
      // setTimeout(
      //   () => document.getElementById('fixDuplicateKeysButton').focus(),
      //   100);
    });
  }

  buildForm(
    data: IFormBuilderFrontendTemplate,
    optionIds: Map<string, number>
  ) {

    if (!data)
      data = {
        data: [],
        name: 'New Form',
        requiresApproval: false,
        formActionsV2: {},
        metaParents: 'FilledForm',
        parents: [],
      };

    this._requiresRippleApproval = data.requiresApproval;
    this.formActionsV2 = data.formActionsV2 || {};
    this.permissions = data.parents || [];
    this.sections = [];
    data.data.forEach((sectionData) => {
      if (sectionData.internalType === 'section')
        this.sections.push(
          new FormSection(
            this,
            sectionData,
            optionIds,
          )
        );
    });
    this.updateFields();
    this.ready = true;

    if (!this.doneSignatureCheck)
      if (this.action === 'fill') {
        for (const section of this.sections)
          this.getAutoFillSignatureFields(section).forEach((key) =>
            this.signatureKeys.push(key)
          );

        // LOAD USER:
        this.authService.getLoggedInUser().subscribe((user) => {
          this.user = user;

          this.signAndSubmit =
            user &&
            user['autofillsignature'] &&
            user['ccasa-signaturejson'] &&
            this.signatureKeys.length > 0;

          setTimeout(() => {
            this.setAndCheckAutofillFieldsAsValid();
            setTimeout(() => this.done.emit(true), 500);
          }, 100);
        });
      } else {
        this.signAndSubmit = false;
        setTimeout(() => this.done.emit(true), 1000);
      }

    this.doneSignatureCheck = true;
  }

  notifyAllFieldsToSave(type: string) {
    const controls = this.form.controls;
    if (controls) {

      const fieldKeys = [];
      for (const section of this.sections)
        fieldKeys.push(this.getAllFieldKeysByType(section, type));

      for (const key of fieldKeys) {
        const control = controls[key];
        if (control) {
          const oldValue = control.value as string;

          control.setValue('SAVE');
          control.setValue(oldValue);
        }
      }
    }
  }

  getAllFieldKeysByType(section: FormSection, type: string): string[] {
    const fields = [];

    for (const child of section.children)
      if (
        child instanceof FormField &&
        child.type === type
      )
        fields.push(child.key);
      else if (child instanceof FormSection)
        this.getAllFieldKeysByType(child, type).forEach((key) =>
          fields.push(key)
        );

    return fields;
  }


  setAndCheckAutofillFieldsAsValid() {
    if (this.signAndSubmit || this.hideAutofillSignatures) {
      const controls = this.form.controls;
      if (controls) {
        //const filledKeys = [];
        for (const key of this.signatureKeys) {
          const control = controls[key];
          if (control) {
            const oldValue = control.value as string;

            if (
              this.hideAutofillSignatures ||
              (!oldValue && this.signAndSubmit)
            ) {
              control.setValue('HIDE');
              control.setValue(oldValue);
            }

            if (!oldValue && this.signAndSubmit) {
              // Set field as valid so that the user is able to submit even if the signature is required
              control.setValidators(Validators.nullValidator);
              control.updateValueAndValidity({
                onlySelf: true,
                emitEvent: true,
              });
            } else this.alreadySigned = true;
          }
        }

        // Remove keys that have already been filled
        //for (const key of filledKeys)
          //this.signatureKeys.splice(this.signatureKeys.indexOf(key), 1);

        this.signAndSubmit = this.signatureKeys.length > 0;
        this.form.updateValueAndValidity({ onlySelf: true, emitEvent: true });
      }
    }
  }

  makeSignatureDecisionAndSubmit(overrideSignatures = false) {
    this.hasMadeSignatureDecision = true;
    this.overrideSignatures = overrideSignatures;
    this.submitForm('submit');
  }

  alreadyAutoFilledWithCurrentUsersSignature(formGroup) {
    if (!formGroup) {
      for (const field of this.fields) {
        for (const fieldGroup of field?.fieldGroup) {
          if (!!fieldGroup && !this.alreadyAutoFilledWithCurrentUsersSignature(fieldGroup))
            return false;
        }
      }

      return true;
    }

    if (formGroup.formControl) {
      const controls = (formGroup.formControl as FormGroup).controls;
      if (controls)
        for (const key of this.signatureKeys) {
          const control = controls[key];
          if (control && control.value !== this.user['ccasa-signaturejson'])
            return false;
        }

      if (formGroup.fieldGroup)
        for (const group of formGroup.fieldGroup)
          if (group && !this.alreadyAutoFilledWithCurrentUsersSignature(group))
            return false;
    }

    return true;
  }

  autoFillSignatures(formGroup, overrideSignatures: boolean = false) {
    if (formGroup.formControl) {
      const controls = (formGroup.formControl as FormGroup).controls;
      if (controls)
        for (const key of this.signatureKeys) {
          const control = controls[key];
          if (control && (overrideSignatures || !control.value))
            control.setValue(this.user['ccasa-signaturejson']);
        }

      if (formGroup.fieldGroup)
        for (const group of formGroup.fieldGroup)
          this.autoFillSignatures(group);
    }
  }

  getAutoFillSignatureFields(section: FormSection): string[] {
    const fields = [];

    for (const child of section.children)
      if (
        child instanceof FormField &&
        child.type === 'draw-signature' &&
        child.autoFillSignature
      )
        fields.push(child.key);
      else if (child instanceof FormSection)
        this.getAutoFillSignatureFields(child).forEach((key) =>
          fields.push(key)
        );

    return fields;
  }

  getOptionIds(entity: WarpEntityType) {
    return new Map<string, number>(
      entity.customFieldsInModules
        .reduce((ret, cfim) => ret.concat(...cfim.cf_choices), [])
        .map((cfc) => [cfc.optionName, cfc.id])
    );
  }

  // fill in any automatic values
  fillInPresetValues(data: IFormBuilderFrontendTemplate) {
    this.model = {};
    (this._filledFields || []).forEach((fid) => {
      const key = fid['key'];
      const field = this.getCertainDataField(key, data.data);
      if (field) {
        field.disabled = true;
        this.model[key] = fid['value'];
      }
    });
  }

  // recursively iterate over data object checking for fieldKey
  getCertainDataField(
    fieldKey: string,
    data: SectionOrFieldOrArray
  ): IFieldFrontendTemplate {
    if (data instanceof Array) {
      let ret = null;
      for (const datum of data)
        if ((ret = this.getCertainDataField(fieldKey, datum))) return ret;
    } else if ('subData' in data && data.subData)
      return this.getCertainDataField(fieldKey, data.subData);
    else if ('key' in data && data.key === fieldKey) return data;

    return null;
  }

  getFormlyFieldConfig(
    fieldKey: string,
    config: FormlyFieldConfig | FormlyFieldConfig[]
  ): FormlyFieldConfig {
    if (config instanceof Array) {
      let ret = null;
      for (const datum of config)
        if ((ret = this.getFormlyFieldConfig(fieldKey, datum))) return ret;
    } else if (config.fieldGroup)
      return this.getFormlyFieldConfig(fieldKey, config.fieldGroup);
    else if (config.key === fieldKey) return config;

    return null;
  }
  //#endregion

  // -------------------------------------- SECTION RELATED -------------------------------------- //
  //#region Sections
  getSectionByID(
    sectionID: string,
    sectionList: (FormSection | FormField)[] = this.sections
  ): FormSection {
    for (const section of sectionList) {
      const retVal = section.getById(sectionID) as FormSection;
      if (retVal) return retVal;
    }
    return null;
  }

  openAddSection(parentID: string = null) {
    const parent = parentID ? this.getSectionByID(parentID) : null;
    this.popup = FormBuilderPopup.sidebar('section-add', this, parent);
  }

  openUpdateSection(sectionID: string) {
    const section = this.getSectionByID(sectionID);
    this.popup = FormBuilderPopup.sidebar('section-edit', this, section);
  }

  openDeleteASection(sectionID: string) {
    this.popup = FormBuilderPopup.modal('section-delete', this, sectionID);
  }

  addASection() {
    if (this.popup.targetParent instanceof FormSection) {
      const parent = this.getSectionByID(this.popup.targetParent.internalID);
      if (parent) parent.addSubSection(new FormSection(this, this.popup.model));
    } else this.sections.push(new FormSection(this, this.popup.model));

    this.updateFields();
    this.workingChanges.emit(true);
  }

  updateASection() {
    const section = this.getSectionByID(this.popup.target.internalID);
    this.log('UPDATING SECTION: ', section, MessageService.VERBOSE);
    if (section) section.updateSelf(this.popup.model, this.popup.useJson);
    this.log('UPDATING FIELDS', MessageService.VERBOSE);
    this.updateFields();
    this.workingChanges.emit(true);
  }

  deleteASection() {
    if (this.popup.targetParent instanceof FormSection) {
      const parent = this.getSectionByID(this.popup.targetParent.internalID);
      if (parent) parent.deleteChild(this.popup.target.internalID);
    } else {
      const sectionIndex = this.getIndexFromID(this.popup.target.internalID);
      if (sectionIndex !== -1) this.sections.splice(sectionIndex, 1);
    }
    this.updateFields();
    this.workingChanges.emit(true);
  }

  submitSectionForm() {
    this.log('submitting form ---- ' + this.popup.action, this.popup, MessageService.VERBOSE);
    switch (this.popup.action.toLowerCase()) {
      case 'add':
        this.addASection();
        break;
      case 'edit':
      case 'apply':
        this.updateASection();
        break;
      case 'delete':
        this.deleteASection();
        break;
    }
    this.workingChanges.emit(true);
  }

  openMoveSections() {
    this.dragAndDropArrays = this.getEssentialInfosOfComponents(this.sections);
    this.targetMoveComponent = this.SECTION_TYPE;
    this.targetMoveComponentDisplay = 'Sections';
    this.displayMoveComponents = true;
  }

  openMoveSubSection(sectionID: string) {
    this.targetSectionID = sectionID;
    const theSection = this.getSectionByID(this.targetSectionID, this.sections);
    this.dragAndDropArrays = this.getEssentialInfosOfComponents(
      theSection.children
    );
    this.targetMoveComponent = this.SUB_SECTION_TYPE;
    this.displayMoveComponents = true;
  }
  //#endregion

  // -------------------------------------- FIELD FUNCTIONS -------------------------------------- //
  //#region Fields
  getFieldPopupData(popup: FormBuilderPopup, fieldType: FieldTypeName): [ExtendedFormlyFieldConfig[], IGenericObject] {
    const thisSection = popup.targetParent ? formSectionFactory((popup.targetParent as FormSection).wrapper) : undefined;
    this.log(`Get Field Parent - ${fieldType}`, thisSection, popup, this.popup, MessageService.VERBOSE);

    const thisField = formFieldFactory(fieldType);
    this.log('Get Edit/Add Field Data: ', thisField, MessageService.VERBOSE);

    let [newFieldConfig, newModel] = thisField.getFieldEditOptions(this.originalFieldsConfig);

    // Remove the 'is required' checkbox from the description-info field type (description labels), as it could cause unsubmittable forms
    if (fieldType === 'description-info') {
      newFieldConfig = newFieldConfig.filter( f => f.internalID !== 'question-is-required-field');
    }

    if (popup.target && popup.target.containsCustomJson) {
      newModel = {
        ...newModel,
        ...popup.json
      };
    }

    const oldTypeSelect = this.originalFieldsConfig.find( f => f.key === 'type');
    const newTypeSelect = newFieldConfig.find( f => f.key === 'type');

    if (thisSection)
      newTypeSelect.templateOptions.options =
        (oldTypeSelect.templateOptions._options).filter( opt => thisSection.isAllowed(opt.value, (this.log.bind(this))) );

    return [newFieldConfig, newModel];
  }

  fieldModelChanged(model) {
    this.log('FIELD MODEL: ', model, MessageService.VERBOSE);
    if (this.lastSelectedFieldType !== model.type) {
      this.lastSelectedFieldType = model.type;

      const [newFieldConfig, newModel] = this.getFieldPopupData(this.popup, model.type);

      // Set the label and required options back because they become cleared when creating a new model
      if (model.label) newModel.label = model.label;
      if(model.options && newFieldConfig.filter(o => o.internalID == 'label-for-options').length > 0)
        newModel.options = model.options;
      if (model.required) newModel.required = model.required;

      this.log('NEW MODEL: ', newModel, MessageService.VERBOSE);
      this.log('newFieldConfig: ', newFieldConfig, MessageService.VERBOSE);

      this.popup.model = newModel;
      this.popup.model.type = this.lastSelectedFieldType;
      this.popup.fields = [...newFieldConfig];
    }
  }

  formlyModelChange(event) {
    this.log('formly model change', event, MessageService.VERBOSE);
  }

  getJSON(jsonObj) {
    return JSON.stringify(jsonObj);
  }

  updatePopupModelJson(popup: FormBuilderPopup, editor: JsonEditorComponent | Event) {
    if (editor instanceof Event)
      this.log('update popup editor error', editor);
    else
      try {
        this.log('updating popup editor', editor);
        const json = editor.get(); // this will throw an error if invalid json

        // popup.requiresRippleApproval = true;
        json['containsCustomJson'] = true;
        popup.model = popup.json = json;
        popup.useJson = true;
      } catch (e) { }
  }

  openAddField(sectionID: string) {
    this.popup = FormBuilderPopup.sidebar('field-add', this, sectionID);
  }

  openUpdateField(field: FormField, section: FormSection) {
    this.popup = FormBuilderPopup.sidebar('field-edit', this, field);
  }

  openCopyField(sectionID: string) {
    this.targetSectionID = sectionID;
    const theSection = this.getSectionByID(this.targetSectionID, this.sections);
    this.fieldCopyOptions = this.getEssentialInfosOfComponents(
      theSection.children
    );
    this.displayCopyFields = true;
  }

  copyField(component: Identifiable, toSection: string = null) {
    this.displayCopyFields = false;
    const field = this.getAllFields().filter(f => f.internalID === component.internalID)[0];

    // Should maybe loop through the template instead
    const newOptions = [];
    function optionFormat(value: number, name: any) {
      this.optionName = name;
      this.value = value;
    }
    field.options.forEach(option => {
      newOptions.push(new optionFormat(option.value, option.optionName));
    });

    let model: Partial<IFieldFrontendTemplate> = {
      label: field.label,
      options: newOptions,
      optionalClass: field.optionalClass,
      type: field.type,
      dataType: field.dataType,
      maxLength: field.maxLength,
      description: field.description,
      required: field.required,
      isMultiSelect: field.isMultiSelect,
      fileTypes: field.fileTypes,
      filePermission: field.filePermission,
      autoFillSignature: field.autoFillSignature,
      isSignedField: field.isSignedField,
      isHorizontal: field.isHorizontal,
    };

    const sectionId = toSection ? toSection : this.targetSectionID;
    const theSection = this.getSectionByID(sectionId, this.sections);
    theSection.addAField(model);
    this.updateFields();

    const fieldCopyGroup = theSection.fieldGroups[theSection.fieldGroups.length - 1];
    this.log(fieldCopyGroup.internalID, MessageService.VERBOSE);
    const fieldCopy = this.getAllFields().filter(f => f.internalID === fieldCopyGroup.internalID);

    this.popup = FormBuilderPopup.sidebar('field-edit', this, fieldCopy[0], true);
  }

  openDeleteField(field: FormField, section: FormSection) {
    this.popup = FormBuilderPopup.modal('field-delete', this, field);
  }
  openDeleteModal(
    field: FormField | FormSection,
    section: FormSection | FormBuilderComponent
  ) {
    if (field instanceof FormField && section instanceof FormSection) {
      this.openDeleteField(field, section);
    }
  }
  hideDeleteBtn(): boolean {
    return (
      (this.popup.action && this.popup.action.toLowerCase() !== 'apply') ||
      !(this.popup.target instanceof FormField)
    );
  }

  submitFieldForm(updateKeyForCopy: boolean = false) {
    this.log('SUBMIT FIELD FORM', this.popup, MessageService.VERBOSE);

    if (this.popup.action !== 'delete' && this.popup.useJson) {
      // this._requiresRippleApproval = true;
    }

    switch (this.popup.action.toLowerCase()) {
      case 'edit':
      case 'apply':
        this.popup.model.dataType = this.questionDataTypeDict[
          this.popup.model.type
        ];
        const stripped_key: string = this.popup.model.label
          .toLowerCase()
          .replace(/[^\dA-Z_\-\(\)&]/gi, '') || undefined;

        this.log('This is being passedInto ripple key', updateKeyForCopy, stripped_key, MessageService.VERBOSE);
        let key = FormField.rippleKey(this.popup.model.type, stripped_key, {
          updateKeyForCopy,
          internalID: this.popup.model?.internalID || null
        });
        const keyCount: number = this.countCurrentFieldWithKey(key);
        if (keyCount > 0)
          key = `${key}_${keyCount}`
        this.updateCurrentFieldKey(key);
        if (updateKeyForCopy) {
          this.popup.model.key = key;
        }
        this.log('This is the ripple key returned', key, MessageService.VERBOSE);
        this.getSectionByID(this.popup.targetParent['internalID']).updateChild(
          this.popup.target.internalID,
          this.popup.model
        );
        break;
      case 'delete':
        this.getSectionByID(this.popup.targetParent['internalID']).deleteChild(
          this.popup.target.internalID
        );
        break;
      case 'add':
        this.popup.model.dataType = this.questionDataTypeDict[ this.popup.model.type ];
        if (!this.popup.model.label) this.popup.model.label = 'Question';

        for (const label of
          this.popup.model.label.split(/;;/g).map((o) => o.trim())
        ) {
          const model: Partial<IFieldFrontendTemplate> = {
            ...this.popup.model,
          };

          if (!model.key) {
            const stripped_key: string = label
              .toLowerCase()
              .replace(/[^\dA-Z_\-\(\)&]/gi, '') || undefined;

            const key = FormField.rippleKey(model.type, stripped_key, {
              updateKeyForCopy,
              internalID: this.popup.model?.internalID || null
            });
            model.label = label;
            model.key = `${key}_${this.countCurrentFieldWithKey(key)}`;

            if (!model.optionalClass) model.optionalClass = 'p-col-12';

            let fieldKey = this.getSectionByID(
              this.popup.targetParent['internalID']
            ).addAField(model);

            let fieldKeyCount: number = this.countCurrentFieldWithKey(fieldKey);
            if (fieldKeyCount > 0)
              fieldKey = `${fieldKey}_${fieldKeyCount}`;

            this.popup.target = this.getSectionByID(
              this.popup.targetParent['internalID']
            ).getByKey(fieldKey);

            model.key = fieldKey;
            this.popup.model.key = fieldKey;
          } else {
            //TODO: this should not exist
            this.popup.model.dataType = this.questionDataTypeDict[
              this.popup.model.type
            ];
            const stripped_key: string = this.popup.model.label
              .toLowerCase()
              .replace(/[^\dA-Z_\-\(\)&]/gi, '') || undefined;

            const key = FormField.rippleKey(
              this.popup.model.type,
              stripped_key, {
                updateKeyForCopy,
                internalID: this.popup.model?.internalID || null
              }
            );
            this.updateCurrentFieldKey(key);
            this.getSectionByID(
              this.popup.targetParent['internalID']
            ).updateChild(this.popup.target.internalID, this.popup.model);
          }
        }
        break;
    }
    this.updateFields();
    this.workingChanges.emit(true);
    return this.popup.fields;
  }

  searchForDuplicateKeys(): Promise<Map<string, number>> {
    return new Promise((resolve, reject) => {
      let keyMap: Map<string, number> = new Map<string, number>();
      this.iterateFields((field: FormField) => {
        try {
          keyMap.set(field.key, (keyMap.get(field.key) || 0) + 1)
        } catch (err) {
          return reject(err);
        }
      });
      // Remove all keys that are not duplicates
      for (const [key, value] of keyMap.entries())
        if (value <= 1)
          keyMap.delete(key);

      return resolve(keyMap);
    });
  }

  isAddDisabled() {
    return  this.popup.model.type != 'description-info' && (this.disableSave || !this.popup.form.valid || this.popupEditJson)
  }

  fixDuplicateKeys() {
    // Keep track of which duplicate keys have been fixed using a map
    let keyIndexMap: Map<string, number> = new Map<string, number>();
    for (const key of this.duplicateKeyMap.keys())
      keyIndexMap.set(key, 0);

    // Iterate through the fields and fix the duplicate keys
    this.iterateFields((field: FormField) => {
      try {
        if (this.duplicateKeyMap.has(field.key)) {
          const oldKey: string = field.key;
          const index: number = keyIndexMap.get(field.key) || 0;

          /// tries to avoid adding a suffix to a key that already has one, but it might result in more duplicate keys, eg:
          /// if we have
          ///    [ question1_0, question1_0, question1_1 ],
          /// then we would end up with
          ///    [ question1_0, question1_1, question1_1 ]
          /// so for now it is better to have
          ///    [ question1_0_0, question1_0_1, question1_1 ]

          // const countSuffix = oldKey.match(/_\d{0,3}$/i);
          // let keySinSuffix = oldKey;
          // if (countSuffix)
          //   keySinSuffix = oldKey.slice(0, countSuffix.index);

          field.key = `${field.key}_${index}`;
          keyIndexMap.set(oldKey, index + 1);
          // Show that the keyIndexMap has been updated
          this.log('Updated keyIndexMap:', keyIndexMap);
        }
      } catch (err) {
        this.messageService.warn('Error updating field:', err);
      }
    });
    this.updateFields();
    // Erase the duplicate keys from the map
    this.duplicateKeyMap = new Map<string, number>();
    // Close the dialog
    this.duplicateKeysFound = false;
    return;
  }
  //#endregion

  // ---------------------------------------- DRAG & DROP ---------------------------------------- //
  //#region Drag and Drop
  submitMoveComponents() {
    let targetArray = [];
    let targetJSONArray = [];

    if (this.targetMoveComponent === this.SECTION_TYPE) {
      targetArray = this.sections;
      targetJSONArray = this.fields;
    } else if (this.targetMoveComponent === this.SUB_SECTION_TYPE) {
      const theSection = this.getSectionByID(
        this.targetSectionID,
        this.sections
      );
      targetArray = theSection.children;
      targetJSONArray = theSection.fieldGroups;
    }

    for (
      let destIndex = 0;
      destIndex < this.dragAndDropArrays.length;
      destIndex++
    ) {
      const sourceIndex = this.getIndexFromID(
        this.dragAndDropArrays[destIndex].internalID,
        targetArray
      );
      this.swapArrayElements(targetArray, destIndex, sourceIndex);
      this.swapArrayElements(targetJSONArray, destIndex, sourceIndex);
    }
    this.updateFields();
    this.displayMoveComponents = false;
    this.workingChanges.emit(true);
  }

  // tslint:disable-next-line: no-any     <- this is only allowed because swap is a generic function
  swapArrayElements(array: any[], dest: number, source: number) {
    const tempElement = array[dest];
    array[dest] = array[source];
    array[source] = tempElement;
  }

  dragStart = (event, component: Identifiable) =>
    (this.draggedComponent = component);

  dragEnd = (event) => (this.draggedComponent = null);

  drop(event, destComponent) {
    this.log('DROP EVENT: ', event, MessageService.VERBOSE);
    this.log('DEST COMPONENT: ', destComponent, MessageService.VERBOSE);
    if (this.draggedComponent) {
      const draggedComponentIndex = this.getIndexFromID(
        this.draggedComponent.internalID,
        this.dragAndDropArrays
      );

      this.log('DRAGGED INDEX: ', draggedComponentIndex, MessageService.VERBOSE);

      const destComponentIndex = this.getIndexFromID(
        destComponent.internalID,
        this.dragAndDropArrays
      );

      this.log('DEST INDEX: ', destComponentIndex, MessageService.VERBOSE);

      if (draggedComponentIndex > destComponentIndex) {
        for (let i = draggedComponentIndex; i > destComponentIndex; i--) {
          this.dragAndDropArrays[i] = this.dragAndDropArrays[i - 1];
        }
      } else if (draggedComponentIndex < destComponentIndex) {
        for (let i = draggedComponentIndex; i < destComponentIndex; i++) {
          this.dragAndDropArrays[i] = this.dragAndDropArrays[i + 1];
        }
      }

      // Code for swap, if we ever want this again (maybe while holding down a certain key? or a button is toggled?)
      // const tempComponent = this.dragAndDropArrays[destComponentIndex];
      // this.dragAndDropArrays[destComponentIndex] = this.draggedComponent;
      // this.dragAndDropArrays[draggedComponentIndex] = tempComponent;

      this.dragAndDropArrays[destComponentIndex] = this.draggedComponent;
      this.dragAndDropArrays = [...this.dragAndDropArrays];
    }
  }
  //#endregion

  // ------------------------------------- GENERAL FUNCTIONS ------------------------------------- //
  //#region General
  openDeleteVersion(version: IRippleFrontendTemplate) {
    this.popup = FormBuilderPopup.modal('version-delete', this, version);
  }

  deleteVersion(version: IRippleFrontendTemplate) {
    this.service.removeFormVersion(version).subscribe((_) => {
      const i = this.versions.indexOf(version);
      this.versions.splice(i, 1);
      this.versions = this.versions.splice(0, this.versions.length); // refresh view
    });
  }

  submit(submittable: { submit: () => void; hide: () => void } | SubmitAction) {
    if (typeof submittable === 'string') this.submitForm(submittable);
    else {
      submittable.submit();
      submittable.hide();
    }
  }

  // tslint:disable-next-line: no-any
  type(target: any): string {
    return typeof target;
  }

  updateFields() {
    this.containsCustomJson = false;

    this.fields = this.sections.map((s) => {
      this.containsCustomJson = this.containsCustomJson || s.containsCustomJson;
      return s.getJson();
    });
    // switch theme of fields if needed
    this.switchFieldsToTheme(this.fields, this.theme);
    this.removeRequiredFromLabels(this.fields);
    // force validation messages to show if needed
    if(this.forceShowValidation)
      this.forceValidationMessagesForFields(this.fields);
    this.log('UPDATE FIELDS: ', this.fields, MessageService.VERBOSE);
    this.toPublish = false;

  }

  /**
   * Sets required fields to always show validation messages, even if the user hasn't interacted with them yet
   * Resulting in a more apparent indication of required fields
   * Due to the fact that fields can be nested, this function is recursive
   * @param fields The fields to force validation messages for (likely this.fields or a subset of if from recursive calls)
   */
  forceValidationMessagesForFields(fields: FormlyFieldConfig[]){
    fields.forEach((field) => {
      // check if the field is required
      if (field.templateOptions && field.templateOptions.required) {
        // check if the field has validation
        if (field.validation) {
          // set show to true
          field.validation.show = true;
        } else {
          // create validation object and set show to true
          field.validation = {};
          field.validation.show = true;
        }
      }
      // check if the field has a fieldGroup to recurse on. If so, call this function on it
      if (field.fieldGroup && field.fieldGroup.length > 0) {
        this.forceValidationMessagesForFields(field.fieldGroup);
      }
    });
  }

  switchFieldsToTheme(fields: FormlyFieldConfig[], requestedTheme: string) {
    let selectedTheme = 'default';

    this.log('[Recursive] Switch Fields to Theme', requestedTheme, selectedTheme);

    fields.forEach( field => {
      if (field.templateOptions) {
        field.templateOptions.markIfDirty = this.showChanges && this._formDataEntity?.entityId > 0;
        field.templateOptions.selectedTheme = selectedTheme;
        if (field.type === 'generic-file') {
          field.templateOptions.parentId = () => {
            this.log('Form Data ID requested for document upload.', this._formDataEntity);
            return this._formDataEntity?.entityId;
          }
        }
      } else
        field.templateOptions = { selectedTheme };

      if (field.fieldGroup && field.fieldGroup.length > 0)
        this.switchFieldsToTheme(field.fieldGroup, requestedTheme);
    });
  }

  /**
   * Removes the required property from description labels for older forms
   * Recursive
   * @param fields Fields to check for description labels set to required
   */
  removeRequiredFromLabels(fields: FormlyFieldConfig[]) {
    fields.forEach( field => {
      if (field.templateOptions && field.type == 'description-info') {
        field.templateOptions.required = false;
      }
      if (field.fieldGroup && field.fieldGroup.length > 0) {
        this.removeRequiredFromLabels(field.fieldGroup);
      }
    });
  }

  getIndexFromID(id: string, array: Identifiable[] = this.sections) {
    for (let i = 0; i < array.length; i++)
      if (array[i].internalID === id) return i;

    return -1;
  }

  @HostListener('window:keydown', ['$event'])
  keyEvent(evt: KeyboardEvent) {
    if (evt.altKey && evt.shiftKey) {
      evt.preventDefault();
      setTimeout(
        (_this) => {
          // tslint:disable-next-line: deprecation
          switch (evt.keyCode) {
            case KEY_CODE.J:
              _this.log('Debug Formly Fields', _this.fields);
              break;
            case KEY_CODE.M:
              _this.log('Debug Model', _this.model, _this.modelAsRippleProperties());
              break;
            case KEY_CODE.R:
              _this.log('Debug Ripple Save Data', this.sections, _this.getCurrentFormBuilderData());
              break;
          }
        },
        0,
        this
      );
    }
  }

  showJson() {
    this.log('show json: fields, sections', this.fields, this.sections, MessageService.VERBOSE);
  }


  getEssentialInfo(comp: Identifiable): Identifiable {
    return {
      internalID: comp.internalID,
      internalType: comp.internalType,
      name: comp.name,
      getById: null,
    };
  }

  getEssentialInfosOfComponents(array: Identifiable[]): Identifiable[] {
    return array.map( c => this.getEssentialInfo(c) );
  }

  buildQuestionDataTypeDictionary(array: { type: string; dataType: string }[]) {
    array.forEach(
      (field) => (this.questionDataTypeDict[field.type] = field.dataType)
    );
  }

  iterateFields(callback: (field: FormField) => void) {
    this.sections.forEach((section) => section.iterateFields(callback));
  }

  getAllFields(): FormField[] {
    const allFields: FormField[] = [];
    this.iterateFields((field: FormField) => allFields.push(field));
    return allFields;
  }

  countCurrentFieldWithKey(incKey: string): number {
    let count = 0;
    this.iterateFields(
      (field: FormField) =>
        (count += field.key.startsWith(incKey + '_') ? 1 : 0)
    );
    return count;
  }

  updateCurrentFieldKey(incKey: string): number {
    let count = 0;
    this.log('UPDATING FIELD: ', incKey, MessageService.VERBOSE);
    this.iterateFields((field: FormField) => {
      if (field.key.startsWith(incKey + '_')) {
        field.key = `${incKey}_${count}`;
        count++;
      }
    });
    return count;
  }

  switchViewMode() {
    if (this.activeChanges) {
      this.confirm.confirm({
        message: `Your form has unsaved changes that will be lost if you continue. Would you like to save before previewing your form?`,
        acceptIcon: 'pi pi-save',
        acceptLabel: 'Save Form',
        rejectLabel: 'Preview Form (Lose Changes)',
        accept: () => {
          setTimeout(() => this.saveFormBuilt(this.toPublish), 500);

          //TODO: this should use .pipe(skipWhile(changes => changes), first()) instead of unsubscribe in the handler
          const sub = this.workingChanges.subscribe((changes) => {
            if (!changes) {
              this.userUse = !this.userUse;
              this.canSwitch = true;
              this.rebuildCurrentForm();
              sub.unsubscribe();
            }
          });
        },
      });
    } else {
      this.userUse = !this.userUse;
      this.canSwitch = true;
      this.rebuildCurrentForm();
    }
  }

  getEntitiesFromEntityID(
    entity: IFilterableEntityTypeDescriptor
  ): Observable<WarpEntity[]> {
    if (entity && entity.id)
      return this.service.getEntitiesFromID(entity, this.model);
    else return from([]);
  }

  scrollIntoView(scrollOptions?: ScrollBehavior | ScrollOptions | ScrollIntoViewOptions) {
    this.elementRef.nativeElement.scrollIntoView(scrollOptions);
  }

  canDeactivate(): boolean {
    return this.action === 'view' || (!this.activeChanges && !this.activeModelChanges);
  }
  //#endregion

  // ----------------------------------------- FORM DATA ----------------------------------------- //
  //#region Data
  getCurrentFormFilledInData = () => this.model;

  rebuildCurrentForm() {
    const currentFormData = this.getCurrentFormBuilderData();
    this.fields = [];
    this.sections = [];
    this.buildForm(currentFormData, this.getOptionIds(this._formEntity));
  }

  getCurrentFormBuilderData(): IFormBuilderFrontendTemplate {
    return {
      name: this.name,
      requiresApproval: this.requiresRippleApproval,
      metaParents: 'FilledForm', // TODO: this should be an environment variable with an override from ui
      parents: this.permissions,
      formActionsV2: this.formActionsV2,
      data: this.sections.map((section) => section.exportSelf()),
    };
  }

  shownUnderNotSelected() {
    return this.permissions.length === 0;
  }

  saveFormBuilt(publish: boolean = false) {
    if (publish && this.permissions.length === 0)
    this.confirm.confirm({
      message: `Please select where this form is shown under by using the drop down in the upper right.`,
      accept: () => {

      },
    });
    else {
    if (publish && this.currentVersion && !this.currentVersion.isLatest)
      this.confirm.confirm({
        message: `Are you sure that you want to ${
          publish ? 'publish' : 'save'
        } an old version of this form?`,
        accept: () => {
          this.saveFormBuiltInternal(publish);
        },
      });
    else this.saveFormBuiltInternal(publish);
    }
  }

  saveFormBuiltInternal(publish: boolean = false) {
    this.showCorrection = false;

    if (
      (!this._formID || this._formID === -1) &&
      this.name === this.defaultName
    )
      return !(this.showCorrection = true);

    this.saving = true;
    const saveData: IFormBuilderFrontendTemplate = this.getCurrentFormBuilderData();
    let sub;

    if (publish) {
      this.msgs = [];
      this.msgs.push({
        severity: 'info',
        summary: 'Form Being Published',
        detail: 'Your form is being published.',
      });
      sub = this.service
        .publishFormBuilt(this._formID, this.currentVersion.id)
        .subscribe((data) => {
          this.msgs = [];
          this.versions = [];
          if (data.length === 0) {
            this.msgs.push({
              severity: 'error',
              summary: 'Form Not Published',
              detail: 'An error occured.',
            });
            this.saving = false;
            this.formbuilderSave.emit(false)
          } else {
            this.msgs.push({
              severity: 'success',
              summary: 'Form Published',
              detail:
              'Your form publish request has been received and is being processed.',
            });
            this.saving = false;
            this.toPublish = false;
            this.formbuilderSave.emit(true)
          }
        });
    } else
      sub = this.service
        .saveFormBuilt(saveData, this._formID)
        .subscribe((data) => {
          this.msgs = [];
          this.versions = [];
          if (data.length === 0) {
            this.msgs.push({
              severity: 'error',
              summary: 'Form Not Saved',
              detail: 'An error occured.',
            });
            this.saving = false;
            this.formbuilderSave.emit(false)
          } else {
            this.log('data gotten back from saveForm', data, MessageService.VERBOSE);

            if (data && data.FrontendLatestDraft) {
              if (!this._formEntity) {
                this.fillInPresetValues(this.getCurrentFormBuilderData());

                this.service
                  .getEntityStructure(data.ID)
                  .pipe(first())
                  .toPromise()
                  .then((type) => {
                    this._formEntity = type;
                    this.loadVersion(data.FrontendLatestDraft);
                    this.saveSuccess();
                    this.formbuilderSave.emit(true)
                  });
              } else {
                this.loadVersion(data.FrontendLatestDraft);
                this.saveSuccess();
                this.formbuilderSave.emit(true)
              }
            }
            this.workingChanges.emit(false);
          }

          if (
            data &&
            (data.ID || data.id) &&
            this._formID !== (data.ID || data.id)
          )
            this._formIdObs.next(data.id || data.ID);
        });

    this.subs.push(sub);
    return true;
  }

  saveSuccess() {
    this.msgs.push({
      severity: 'success',
      summary: 'Form Saved',
      detail: 'Your form has been saved.',
    });
    this.saving = false;
    this.toPublish = true;
  }

  submitForm(action: SubmitAction) {
    if (
      action === 'submit' &&
      this.signAndSubmit &&
      this.alreadySigned &&
      !this.hasMadeSignatureDecision &&
      !this.alreadyAutoFilledWithCurrentUsersSignature(null)
    ) {
      this.showReSignConfirmationDialog = true;
      return;
    }

    if (!this.submitting) {
      this.submitting = true;
      this.submittingAction = action;

      // Save file fields
      this.notifyAllFieldsToSave('generic-file');

      // Autofill signatures
      if (action === 'submit' && this.signAndSubmit) {
          for (const field of this.fields)
            for (const fieldGroup of field.fieldGroup)
              this.autoFillSignatures(fieldGroup, this.overrideSignatures);
      }

      this.showReSignConfirmationDialog = false;
      this.hasMadeSignatureDecision = false;
      this.overrideSignatures = false;

      this.log('form-builder save internally', this.model, MessageService.VERBOSE);
      if (this._formDataEntity)
        this._formDataEntity.properties = this.model;

      const formDataEntity =
        this._formDataEntity ||
        new WarpEntity({
          entityId: -1,
          entityTypeId: this._formID,
          dateCreated: new Date(),
          ...this.modelAsRippleProperties(),
        });

      this.modelChange.emit(false);
      this.saveFilledFormData.emit({ formData: formDataEntity, action });

      if (!this.overrideSave)
        this.genericWarpEntityService
          .create(this._formEntity, [formDataEntity])
          .subscribe(
            () => this.successfulSave.next(true),
            () => this.successfulSave.next(false)
          );

      this.modelChange.emit(false);
    }
  }

  onSubmitForm(success: boolean) {
    this.submitting = false;
    this.formSaved.emit(success);
  }

  modelAsRippleProperties(model: IGenericObject = this.model) {
    if (this._formDataEntity) {
      this._formDataEntity.properties = this.model;
      return this._formDataEntity._getSyncObjectByStructure(this._formEntity);
    }
    const rippleSaveObj = {
      properties: { },
      multiValuedProperties: { },
      linkedProperties: { },
    };
    // const optionIds = this.getOptionIds(this._formEntity);
    this.iterateFields((field: FormField) => {
      const val = model[field.key];
      if (val !== undefined) {
        const rippleKey = field.key; // `FormBuilder--${field.type}-${field.key}`.replace(' ', '').toLowerCase();
        rippleSaveObj.properties[rippleKey] = '';
        // tslint:disable-next-line: curly
        if (val instanceof Array) {
          if (field.selectedEntity) {
            rippleSaveObj.linkedProperties[rippleKey] = val[0];
            rippleSaveObj.multiValuedProperties[rippleKey] = val;
          } else if (val.length > 0 && val[0].id !== undefined) {
            // rippleSaveObj.multiValuedProperties[rippleKey + '_cfcid'] = val.map(cfc => cfc.id);
            // rippleSaveObj.multiValuedProperties[rippleKey + '_specifytext'] = val.map(cfc => cfc.specifytext || '');
            // rippleSaveObj.multiValuedProperties[rippleKey ] = val.map(cfc => cfc.optionName);
            rippleSaveObj.multiValuedProperties[rippleKey] = val;
            rippleSaveObj.multiValuedProperties[
              rippleKey + '_specifytext'
            ] = val.map((cfc) => ({ value: cfc.specifytext || '' }));
          } else rippleSaveObj.multiValuedProperties[rippleKey] = val;
          // tslint:disable-next-line: curly
        } else {
          if (field.selectedEntity)
            rippleSaveObj.linkedProperties[rippleKey] = val;
          else if (val && val.id !== undefined) {
            rippleSaveObj.properties[rippleKey + '_cfcid'] = val.id;
            rippleSaveObj.properties[rippleKey + '_specifytext'] =
              val.specifytext || '';
            rippleSaveObj.properties[rippleKey] = val.optionName;
          } else rippleSaveObj.properties[rippleKey] = val;
        }
      }
    });
    return rippleSaveObj;
  }

  showVersions(copyMode = false) {
    if (!this._formID || this._formID === -1)
      this.versions = [];
    else if (
      this.versions.length === 0 ||
      this.versions[0].EntityTypeID !== this._formID
    )
      this.service
        .getFormVersions(this._formID)
        .pipe(first())
        .toPromise()
        .then((versions) => (this.versions = versions || []));

    this.copyVersionMode = copyMode;
    this.versionsPopup = true;
  }

  showCopy() {
    this.showVersions(true);
  }

  showPostSaveActions() {
    this.formActionModal.showModal();
  }


  //#endregion

  // ----------------------------------- MODEL / FILLING FORMS ----------------------------------- //
  //#region Model (Filling Forms)
  initModelChecker() {
    this.log('Init Model Checker');
    this._modelDiffer = this.differs.find(this.model).create();
    this._modelDiffer.diff(this.model);

    if (this._diffCheckInterval)
      clearInterval(this._diffCheckInterval);

    this._diffCheckInterval = setInterval(() => {
      this.log('Checking Model for Changes', MessageService.VERBOSE);
      const diffs = this._modelDiffer.diff(this.model);
      let changed = false;
      if (diffs) {
        ////// -------------------------------------------------------------------------------------------------------------------------
        // used to determine if the change was just us converting the datetime in the form-datetime-picker.component to show up properly.
        diffs.forEachChangedItem( record => {
          changed = this.wasActuallyChanged(record, 'Changed');
        });

        diffs.forEachAddedItem(record => {
          changed = this.wasActuallyChanged(record, 'Added');
        });

        diffs.forEachRemovedItem(record => {
          changed = this.wasActuallyChanged(record, 'Removed');
        });
        ////// -------------------------------------------------------------------------------------------------------------------------
      }
      if (!this.saving && !this.submitting && changed)
        this.modelChanged(true);
    }, 2000);
  }

  wasActuallyChanged(record, area) {
    let changed = false;
    // this.log(area + ' Record', record.key, record.currentValue, record.previousValue);
    // tslint:disable-next-line: curly
    if (record.key.indexOf('formbuilder--datetime-picker-date') === 0) {
      if (typeof(record.currentValue) === typeof(record.previousValue))
        changed = true;
      else {
        if (typeof(record.currentValue) === 'string' && record.previousValue instanceof Date)
          changed = moment(record.currentValue).format('MM-DD-YYYY') !== moment(record.previousValue).format('MM-DD-YYYY');
          // new Date(record.currentValue) !== record.previousValue;
        else if (typeof(record.previousValue) === 'string' && record.currentValue instanceof Date)
          changed = moment(record.currentValue).format('MM-DD-YYYY') !== moment(record.previousValue).format('MM-DD-YYYY');
          // new Date(record.previousValue) !== record.currentValue;
        else
          changed = true;
      }
    } else if (record.key === 'entityselect_filledform')
      changed = !this.deepEqual(record.previousValue, record.currentvalue);
      // record.currentValue
      //   .any(a => !record.previousValue.filter(p => p.id === a.id) || record.previousValue.filter(p => p.id === a.id && p.))
      //   // (record.previousValue[0].id !== record.currentValue[0].id || record.previousValue[0].name !== record.currentValue[0].name);
    else
      changed = true;

    return changed;
  }

   deepEqual(object1, object2) {
    // KG evaluate empty / null / undefined to be equal
    const keys1 = Object.keys(object1 || { });
    const keys2 = Object.keys(object2 || { });
    if (keys1.length !== keys2.length)
      return false;

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = this.isObject(val1) && this.isObject(val2);
      if (
        areObjects && !this.deepEqual(val1, val2) ||
        !areObjects && val1 !== val2
      ) {
        return false;
      }
    }
    return true;
  }
   isObject(object) {
    return object != null && typeof object === 'object';
  }


  loadEntityData(entity: WarpEntity) {
    /** @see EntityDetailsComponent in @ripple/ui */

    const oldE: WarpEntity = this._formDataEntity;
    const newE: WarpEntity = entity;
    let updateModel = true;

    // tslint:disable-next-line: curly
    if (oldE && newE && oldE.entityTypeId === newE.entityTypeId && oldE.entityId === newE.entityId) {
      // if it's the same entity inputted in and we have made changes

      // first find out if we have made changes since last modelCheck (this should be rare)
      if (!this.activeModelChanges && !oldE.hasEquivalentProperties(this.model))
        this.modelChanged(true);

      // if it has been updated and user has made changes in the form, for guy #3
      if (oldE.updatedECMA !== newE.updatedECMA && this.activeModelChanges && !newE.hasEquivalentProperties(this.model)) {
        this.incomingChangesDialogPopup = true;
        updateModel = false;
        this.log('Incoming Changes - Overwrite Popup', { oldE, newE });
      }
      // this is the same entity at the same state as we have already, no change required
      else if (oldE.updatedECMA === newE.updatedECMA) {
        updateModel = false;
        this.log('Incoming Changes - No Properties Were Changed', { oldE, newE });
      }
      // we haven't made any changes yet, or the new entity doesn't have any updated properties
      // OR the changes coming in are the same ones the user has made, so its safe to update the data
      else
        this.log('Incoming Changes - Updating Normally', { oldE, newE });
    }

    if (updateModel) this.updateModel(entity);
    else this._incomingFormDataEntity = entity;
  }

  discardChanges() {
    this.updateModel(this._incomingFormDataEntity);
    this.incomingChangesDialogPopup = false;
  }

  keepChanges() {
    this.incomingChangesDialogPopup = false;
    this.ignoredIncomingChanges = true;
  }

  private updateModel(entity: WarpEntity) {
        this.log('Loading Entity Data', entity);

        this._formDataEntity = entity;
        this.model = { };

        if (entity !== null) {
        // copy properties into model
        for (const key in entity.properties)
        if (Object.prototype.hasOwnProperty.call(entity.properties, key))
            this.model[key] = this._formDataEntity.properties[key];

        this.ignoredIncomingChanges = false;
        this.modelChanged(false);
      }
      else
        this.modelChanged(false);

  }

  modelChanged(isChanged: boolean) {
    // only emit on rising / falling edges
    if (isChanged !== this.activeChanges)
      this.modelChange.emit(isChanged);

    this.activeModelChanges = isChanged;
  }
  //#endregion

  // ------------------------------------------ IMPORTERS ------------------------------------------ //
  //#region Importers
  generateFormFromImport(title: string, datas: FormBuilderImportFieldData[]) {
    this.name = title;
    this.openAddSection();
    this.popup.model = {
      section: title
    };
    this.addASection();
    const section = this.sections[this.sections.length - 1];
    for (const data of datas) {
      this.openAddField(section.internalID);
      switch(data.questionType) {
        case 'Text':
          this.popup.model = {
            dataType: "text",
            label: data.label,
            required: false,
            type: "generic-input"
          };
          break;
        case 'Text Area':
          this.popup.model = {
            dataType: "bigText",
            label: data.label,
            required: false,
            type: "generic-textarea"
          };
          break;
        case 'Checkbox':
          this.popup.model = {
            dataType: "select",
            isHorizontal: false,
            label: data.label,
            options: data.options.map(o => ({ value: o, optionName: o })),
            optionalClass: "p-col-12 p-md-4",
            required: false,
            type: "checkbox-multiple-col"
          };
          break;
        case 'Radio':
        default:
          this.popup.model = {
            dataType: "select",
            isHorizontal: false,
            label: data.label,
            options: data.options.map(o => ({ value: o, optionName: o })),
            optionalClass: "p-col-12 p-md-4",
            required: false,
            type: "radio-multiple-col"
          };
          break;
      }

      this.submitFieldForm();
    }
  }
  //#endregion

  // ------------------------------------------ HELPERS ------------------------------------------ //
  //#region Helpers
  private log(...args) {
    this.messageService.add('FormBuilder', ...args);
  }
  // tslint:disable-next-line: no-any
  public logChild(that: any, ...args) {
    this.messageService.add(`FormBuilder Component - ${that.constructor.name}`, ...args);
  }
  //#endregion


  // ------------------------------------------ FormActionsV2 ------------------------------------------ //

  saveFormActions(val: any) {
    this.formActionsV2 = val;
    this.toPublish = false;
  }

}
