import { Component, Input, Output, EventEmitter } from '@angular/core';

export type SaveStatType = 'count' | 'time' | 'percent';

export interface Countable {
  count?: number;
}

export interface Timed {
  time?: number;
}

@Component({
  selector: '[ccasa-snippet-total]',
  styles: [`
    :host:hover .results-right>i{
      opacity: 1;
    }

    .results-right {
      text-align: right;

      > i {
        float: left;
        color: var(--primary);
        margin-top: 0.25rem;
        opacity: 0;
        transition: opacity 0.33s;
      }
    }
  `],
  template: `
    <td snippetFilterEntry colspan="2" style="font-weight: bold; border-right:0px">Total</td>
    <td snippetFilterEntry="Total Time" class="results-right" *ngIf="showTime">
      <i title="Save total time as stat" *ngIf="canSaveStat('time')" class="pi pi-save" (click)="doSaveStat('time')"></i>
      {{totalTime}}
    </td>
    <td snippetFilterEntry="Total Count" class="results-right">
      <i title="Save total count as stat" *ngIf="canSaveStat('count')" class="pi pi-save" (click)="doSaveStat('count')"></i>
      {{total}}
    </td>
    <td *ngIf="showPercent"></td>
  `,
})
export class SnippetTotalComponent<Data extends Countable & Timed> {
  _data: Data[];
  totalTime: string;
  total: number | string;

  @Input() set data(values: Data[]) {
    console.log('SnippetTotalComponent set data', values);
    this._data = values;
    this.totalTime = this.getTotalTime(values);
    this.total = this.getTotal(values);
    console.log('SnippetTotalComponent set data complete', this.totalTime, this.total);
  }

  @Input() saveStat = false;
  @Input() showTime = false;
  @Input() showPercent = false;
  @Input() showFilter = true;

  @Output() saveClick: EventEmitter<{type: SaveStatType}> = new EventEmitter();

  constructor() { }

  canSaveStat(type: SaveStatType) {
    // rn we don't have any restrictions on saving stats by type, but we might in the future?
    return this.saveStat;
  }

  doSaveStat(type: SaveStatType) {
    this.saveClick.emit({ type });
  }

  formatTime(time: number | string) {
    if (time == null || time == undefined) {
      return '0:00';
    } else {
      const totalMinutes = Number(time);
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes - (hours * 60);
      return hours.toString() + ":" + (minutes < 10 ? "0" + minutes.toString() : minutes.toString());
    }
  }

  getTotal(choiceData: Countable[]) {
    let count = 0;
    let undetermined = false, hasCount = false;
    choiceData.forEach(choice => {
      if (choice.count !== null)
        hasCount = true;
      else
        undetermined = true;

      count += choice.count || 0;
    });

    if (undetermined)
      return hasCount ? `${count}*` : '-';

    return count;
  }
  getTotalTime(choiceData: Timed[]) {
    let count = 0;
    choiceData.forEach(choice => count += (choice.time || 0));
    return this.formatTime(count);
  }
}
