import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class StrategicOutcomeTarget extends WarpEntity {
  id: string | number;

  constructor(obj) {
    super(obj);
    this.entityTypeId = EntityTypes.StrategicOutcomeTarget;
  }

  static empty(): StrategicOutcomeTarget {
    return new StrategicOutcomeTarget(super.emptyEntity(EntityTypes.StrategicOutcomeTarget));
  }

}
