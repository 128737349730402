<!-- <ripple-entity-list [entityService]="organizationContactService"
    [editButton]="edit" [addButton]="add"
    [filter]="organizationContactFilter"
    [showFilter]="false"
>
</ripple-entity-list> -->
<div class="pane pane--lg-h100">
    <div class="pane__header">
        <div class="header">
            <button pButton class="ui-button-secondary mb-3" icon="pi pi-plus" label="Add New Contact" (click)="add()">
            </button>
            <div class="header__actions">
            </div>
        </div>

    </div>
    <div class="pane__body">
        <ripple-entity-scroll-view #contactList fullHeight="true"
            [entityService]="organizationContactService" [filter]="organizationContactFilter" itemSize="80">
            <ng-template #container let-contact>
                <div class="heading-action-row">
                    <div class="heading-action-row__heading">
                        <sagesse-organization-contact-card [contact]="contact"></sagesse-organization-contact-card>
                    </div>
                    <div class="heading-action-row__actions">
                        <button pButton icon="pi pi-pencil" (click)="edit(contact)" class="mx-4">
                        </button>
                    </div>
                </div>
            </ng-template>
        </ripple-entity-scroll-view>
    </div>
</div>



<p-dialog header="Contact for {{organization?.properties.name}}"
    [(visible)]="contactDialogShow"
    *ngIf="contactDialogShow"
    [style]="{width: '50vw'}"
    [baseZIndex]="10000">
    <sagesse-organization-contact-details
        [organizationContactSelected]="organizationContactSelected"
        (afterSave)="contactDialogShow = false"
        #contactDetails>
    </sagesse-organization-contact-details>
    <p-footer>
        <ripple-button-list [buttons]="contactDetails.splitButtons"></ripple-button-list>
    </p-footer>
</p-dialog>
