<div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">Evaluation Outcome Reporting</h1>
      <div class="header__actions">
        <div class="ui-fluid p-sm-12 p-md-12">
          
        </div>
      </div>
    </div>
  </div>
  <div class="pane__body">
    <p-table #dt [value]="programCategories" dataKey="id" styleClass="ui-table--generic-report" [rowHover]="true"
      [showCurrentPageReport]="true" [loading]="reportsLoading" [paginator]="false"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">Program Category <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th class="actions-col"> </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-report>
        <tr class="ui-selectable-row">
          <td>{{ report.label }}</td>
          <td class="actions-col">
            <button pButton label="View" icon="pi pi-window-maximize" (click)="view(report.id)"></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2" class="empty-message-col">No Reports found.</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>