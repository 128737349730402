<div class="login-container">

    <p-card class="login-card">
        <ng-container *ngIf="!loginSuccess">
            <p *ngIf="showErrorMessage" class="text-danger" [innerHTML]="errorMessage"></p>
            <h2>Login</h2>
            <div>
                <button pButton class="ui-button-secondary" icon="pi pi-microsoft" *ngIf="!wrongAccount"
                    label="Login with Microsoft Azure AD Account" (click)="login()">
                </button>
            </div>
            <div>
                <button pButton class="ui-button-secondary mt-3" icon="pi pi-microsoft" 
                    label="Sign Out" (click)="logout()" *ngIf="wrongAccount">
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="loginSuccess">
            <h2>Login Success</h2>
            
            <h4>Redirecting...</h4>
        </ng-container>
        
    </p-card>
</div>
    


