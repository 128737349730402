import type { FieldTypeName } from './question-type-names';

export interface IQuestionDataTypeDescriptor {
  type: FieldTypeName | '';
  dataType: string;
}

export const questionDataTypes: IQuestionDataTypeDescriptor[] = [
  /* {
    type: 'formly-type', // the one in ../partials
    dataType: 'ripple-type', // the one in dbo.CustomFields
  }, */
  {
    type: '',
    dataType: ''
  },
  {
    type: 'select-search',
    dataType: 'select'
  },
  {
    type: 'generic-input',
    dataType: 'text'
  },
  {
    type: 'generic-textarea',
    dataType: 'bigText'
  },
  {
    type: 'radio-multiple-col',
    dataType: 'select'
  },
  {
    type: 'checkbox-multiple-col',
    dataType: 'select'
  },
  {
    type: 'datetime-picker',
    dataType: 'date'
  },
  {
    type: 'description-info',
    dataType: 'text'
  },
  {
    type: 'select-entity',
    dataType: 'EntitySelect'
  },
  {
    type: 'draw-signature',
    dataType: 'bigText'
  },
  {
    type: 'generic-file',
    dataType: 'EntitySelect'
  }
];
