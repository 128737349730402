import { FormlyFieldConfig } from '@ngx-formly/core';
import type { IGenericObject } from '@ripple/models';

export type ExtendedFormlyFieldConfig = FormlyFieldConfig & { internalID: string, internalType: string };

// extract anon functions to own const for compatibility with umd bundles
export const formatDoubleSemicolons = (value: string) => value.replace(/;;/g, '<span class="delimiter">;;</span>');

export const maxTextLength = 500;

export const fieldConfigField: ExtendedFormlyFieldConfig[] = [
  { /** label-for-questions */
    internalID: 'label-for-questions',
    internalType: 'question-label',
    className: 'section-label',
    template: '<h3>Question Details:</h3>',
  },
  { /** question-text-field */
    internalID: 'question-text-field',
    internalType: 'question-data',
    className: 'p-field ui-fluid p-d-block',
    key: 'label',
    type: 'generic-textarea',
    templateOptions: {
      label: 'Please enter the question:',
      maxLength: maxTextLength,
      customFormatting: false,
      formatter: formatDoubleSemicolons,
      required: true,
    },
  },
  { /** question-type-field */
    internalID: 'question-type-field',
    internalType: 'question-data',
    className: 'p-field ui-fluid p-d-block',
    key: 'type',
    type: 'select',
    templateOptions: {
      placeholder: 'Select one',
      label: 'Select the type of question:',
      valueProp: 'value',
      labelProp: 'optionName',
      required: true,
    },
    modelOptions: {
      updateOn: 'change',
    },
  },
  { /** is-multiple */
    internalID: 'is-multi-select',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'isMultiSelect',
    type: 'checkbox',
    templateOptions: {
      label: 'Allow Multiple?',
      description: 'Allow multiple selections',
    },
    defaultValue: false,
  },
  { /** question-date-showtime-field */
    internalID: 'question-date-showtime-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'showTime',
    type: 'checkbox',
    templateOptions: {
      label: 'Time Picker?',
      description: 'Show the time picker as well to pick the time',
    },
  },
  { /** question-input-number-field */
    internalID: 'question-input-number-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'isNumber',
    type: 'checkbox',
    templateOptions: {
      label: 'Number only?',
      description: 'Validate the input to be number only',
    },
  },
  { /** question-is-rich-text-editor */
    internalID: 'question-input-is-rich-text-editor',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'enableRichTextEditor',
    type: 'checkbox',
    templateOptions: {
      label: 'Rich Text Editor?',
      description: 'Enable Rich Text HTML Editor for the question',
    },
    defaultValue: false,
  },
  { /** label-for-options */
    internalID: 'label-for-options',
    internalType: 'question-options',
    className: 'section-label',
    template: '<h3>Options:</h3>',
  },
  { /** question-options-field */
    internalID: 'question-options-field',
    internalType: 'question-options',
    className: 'p-field p-d-block',
    key: 'options',
    type: 'repeat',
    templateOptions: {
      label: 'Options:',
      header: 'Select Options',
      addText: 'Add Another Option',
      addFirstText: 'Add an Option',

      allowReordering: true,
      allowBulkAdd: true,
      bulkAddText: 'Bulk Add Options',
      bulkAddPlaceholder: 'Enter each option on a new line',
      onBulkAddLine: (line: string) => ({ optionName: line }),
    },
    fieldArray: {
      fieldGroupClassName: 'p-grid option-container',
      templateOptions: {
        asPanel: true,
        allowHtml: true,
        header: (_model: any[], i: number, _) => {
          const model: IGenericObject = _model[i];
          const label: string = model?.optionName ?? 'New Option';
          const specifySuffix: string = model.hasSpecifyText ? ` <i style="opacity: 0.7;">(${
              model?.isMultiSpecify ? 'Multi-' : ''
            }Specify${
              model?.specifyIsNumber ? ' Number': ''
            })</i>` : ''
          return label + specifySuffix;
        }
      },
      fieldGroup: [
        {
          className: 'p-field ui-fluid p-col-9 option-name',
          type: 'input',
          key: 'optionName',
          templateOptions: {
            label: 'Option Name',
            required: true,
            maxLength: maxTextLength,
          },
        },
        {
          className: 'p-field ui-fluid p-col-3 option-value',
          type: 'generic-input',
          key: 'value',
          defaultValue: 0,
          templateOptions: {
            label: 'Score Value',
            helpText: 'The value of the option when calculating the form score',
            type: 'number',
            required: false,
          },
        },
        {
          className: 'p-field ui-fluid p-col-4 option-specify',
          type: 'checkbox',
          key: 'hasSpecifyText',
          templateOptions: {
            label: 'Specify?',
          },
        },
        {
          className: 'p-field ui-fluid p-col-6 option-specify-is-number',
          key: 'specifyIsNumber',
          type: 'checkbox',
          templateOptions: {
            label: 'With Number?',
            description: 'only allow the option to be specified with a number.',
          },
          hideExpression: "!model.hasSpecifyText",
          defaultValue: false
        },
        {
          className: 'p-field ui-fluid p-col-4 option-is-multi-specify',
          type: 'checkbox',
          key: 'isMultiSpecify',
          templateOptions: {
            label: 'Allow Multiple?',
            required: true,
          },
          hideExpression: "!model.hasSpecifyText",
          defaultValue: false
        },
        {
          className: 'p-field ui-fluid p-col-8 option-max-specify',
          type: 'input',
          key: 'maxSpecify',
          templateOptions: {
            label: 'Maximum "Specify" Entries allowed',
            type: 'number',
            required: true,
            min: 2,
            max: 10,
          },
          hideExpression: "!(model.hasSpecifyText && model.isMultiSpecify)",
          defaultValue: 2
        },
        {
          className: 'p-field ui-fluid p-col-12 option-specify-label',
          key: 'specifyLabel',
          type: 'input',
          templateOptions: {
            label: 'Custom Specify Label',
            placeholder: 'Type Here...',
            maxLength: maxTextLength,
          },
          hideExpression: "!model.hasSpecifyText",
        },
      ],
    },
  },
  { /** question-horizontal-radio-field */
    internalID: 'question-horizontal-radio-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'isHorizontal',
    type: 'checkbox',
    templateOptions: {
      label: 'Horizontal Radio?',
      description: 'Show question with equal width columns and labels below',
    },
    defaultValue: false,
  },
  { /** question-col-type-field */
    internalID: 'question-col-type-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'optionalClass',
    type: 'select',
    templateOptions: {
      label: 'Select the number of columns:',
      options: [
        {
          key: '1',
          value: 'p-col-12',
        },
        {
          key: '2',
          value: 'p-col-12 p-md-6',
        },
        {
          key: '3',
          value: 'p-col-12 p-md-4',
        },
        {
          key: '4',
          value: 'p-col-12 p-md-3',
        },
        {
          key: '6',
          value: 'p-col-12 p-md-2',
        },
        {
          key: '12',
          value: 'p-col-12 p-md-1',
        },
      ],
      labelProp: 'key',
      valueProp: 'value',
    },
  },
  { /** is-multiple-files */
    internalID: 'is-multi-files',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'isMultiSelect',
    type: 'checkbox',
    templateOptions: {
      label: 'Allow Multiple Files?',
      description: 'Allow multiple file uploads',
    },
    defaultValue: false,
  },
  { /** question-file-type */
    internalID: 'question-file-type',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'fileTypes',
    type: 'select-search',
    templateOptions: {
      isMultiSelect: true,
      label: 'Which files should be allowed:',
      options: [
        {
          optionName: 'Any',
          id: '*'
        },
        {
          optionName: 'Images',
          id: 'image/*',
        },
        {
          optionName: 'Audio',
          id: 'audio/*',
        },
        {
          optionName: 'Video',
          id: 'video/*',
        },
        {
          optionName: 'PDF',
          id: 'application/pdf',
        },
        {
          optionName: 'Excel Sheet',
          id: '.slsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.xml,.xlam,.xla,.xlw,.xlr',
        },
        {
          optionName: 'Word Document',
          id: '.doc,.docx,.dotx',
        },
        {
          optionName: 'Text',
          id: 'text/plain'
        },
        {
          optionName: 'ZIP File',
          id: '.zip,.rar,.tar,.7z,.bz2,.gz'
        },
      ],
    },
  },
  { /** question-file-permission-field */
    internalID: 'question-file-permission-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'filePermission',
    type: 'select',
    templateOptions: {
      label: 'Select the permission level of the files:',
      options: [
        {
          key: 'Private',
          value: 'Private',
        },
        {
          key: 'Public',
          value: 'Public',
        },
        {
          key: 'Public (Internal)',
          value: 'Public-Internal',
        },
      ],
      labelProp: 'key',
      valueProp: 'value',
    },
  },
  { /** question-entity-list */
    internalID: 'question-entity-list',
    internalType: 'question-options',
    className: 'p-field ui-fluid',
    key: 'selectedEntity',
    type: 'select-search',
    templateOptions: {
      label: 'Select the type of entity:',
      options: [],
      placeholder: 'Select one',
      labelProp: 'name',
      valueProp: 'id',
    },
  },
  { /** question-signature-autofill-field */
    internalID: 'question-signature-autofill-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'autoFillSignature',
    type: 'checkbox',
    templateOptions: {
      label: 'Autofill Signature?',
      description: 'The canvas will automatically be filled with the user\'s signature if saved in their account',
    },
  },
  { /** question-input-height-field */
    internalID: 'question-input-height-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'height',
    type: 'input',
    defaultValue: 140,
    templateOptions: {
      type: 'number',
      min: 0,
      label: 'Max Height (in px)',
      description: 'Signature Board Max Height',
    },
  },
  { /** question-description-field */
    internalID: 'question-description-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'description',
    type: 'text-editor',
    templateOptions: {
      required: false,
    },
    expressionProperties: {
      'templateOptions.required': () => false,
    },
  },
  { /** label-for-styling */
    internalID: 'label-for-styling',
    internalType: 'question-label',
    className: 'section-label',
    template: '',
  },
  { /** question-is-required-field */
    internalID: 'question-is-required-field',
    internalType: 'question-options',
    className: 'p-field ui-fluid p-d-block',
    key: 'required',
    type: 'checkbox',
    templateOptions: {
      label: 'Required Field?',
      description: 'Make the field a required field',
    },
    defaultValue: false,
  },
  { /** question-is-signed-field */
  internalID: 'question-is-signed-field',
  internalType: 'question-options',
  className: 'p-field p-d-block',
  key: 'isSignedField',
  type: 'select-search',
  defaultValue: { optionName: 'None', id: 'false' },
  templateOptions: {
    label: 'Show Signer Information:',
    options: [
      {
        optionName: 'None',
        id: 'false'
      },
      {
        optionName: 'Include Date Filled',
        id: 'dateOnly',
      },
      {
        optionName: 'Include Filler Information, and Date Filled',
        id: 'true',
      }
    ],
  },
},
];
