import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'ripple-invalid-fields-button',
  templateUrl: './invalid-fields-button.component.html',
  styleUrls: ['./invalid-fields-button.component.scss'],
})
export class InvalidFieldsButtonComponent implements AfterViewInit, OnChanges {
  @Input() show: boolean;
  @Input() fields: FormlyFieldConfig[];
  @Input() buttonClass: string = '';
  @Input() buttonStyle: string = '';

  @ViewChild('requiredFieldButton') requiredFieldButton: ElementRef;
  buttonSpan;

  invalidFields: FormlyFieldConfig[] = [];
  lastFieldCheck = new Date();
  creationTime = new Date();
  updateInterval = 100;
  matured = false;
  invalidFieldsDoubleChecked = false;

  constructor() {
    setInterval(
      () => this.setInvalidFields(),
      this.updateInterval + 1
    );
  }

  hasMatured() {
    return (
      this.matured ||
      (this.matured = new Date().getTime() - this.creationTime.getTime() > 500)
    );
  }

  ngAfterViewInit(): void {
    if (this.requiredFieldButton?.nativeElement)
      this.buttonSpan = this.requiredFieldButton?.nativeElement.children[1];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.show) {
      setTimeout(() => {
        if (this.requiredFieldButton?.nativeElement)
          this.buttonSpan = this.requiredFieldButton?.nativeElement.children[1];
      });
    }
  }

  scrollToInvalidField() {
    let scrollElement;

    console.log(
      'Missing Fields Button Press, Missing fields:',
      this.invalidFields,
      'All fields:',
      this.fields
    );

    if (this.invalidFields && this.invalidFields.length > 0) {
      const labelsFiltered = [];
      const labels = document.getElementsByTagName('label');

      for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if (label.htmlFor) labelsFiltered.push(label);
      }

      for (const missingField of this.invalidFields) {
        for (const label of labelsFiltered) {
          if (label.htmlFor === missingField.id) {
            this.highlightLabel(label);
            if (!scrollElement) scrollElement = label;
            break;
          }
        }
      }
    }

    if (!scrollElement) {
      let validationMessages = document.getElementsByTagName(
        'formly-validation-message'
      );

      if (validationMessages && validationMessages.length > 0)
        scrollElement = validationMessages[0];
    }

    if (scrollElement) {
      scrollElement.scrollIntoView({
        block: 'center',
        inline: 'start',
        behavior: 'smooth',
      });
      setTimeout(() => {
        scrollElement.scrollIntoView({
          block: 'center',
          inline: 'start',
          behavior: 'smooth',
        });
      });
    }
  }

  setInvalidFields() {
    const missingFields = this.getInvalidFields();
    //this.doubleCheckInvalidFields(missingFields);

    this.invalidFields = missingFields;
    this.lastFieldCheck = new Date();

    if (this.buttonSpan) {
      // Rory: We may not use [label] to automatically set the label of the button as it removes ui-button-danger class on update, PrimeNG v9 bug
      this.buttonSpan.innerText =
        this.invalidFields.length <= 1
          ? 'Missing Required Field'
          : this.invalidFields.length + ' Missing Required Fields';
    }
  }

  getInvalidFields(
    fields: FormlyFieldConfig[] = this.fields
  ): FormlyFieldConfig[] {
    if (!fields?.length) return [];

    if (
      this.lastFieldCheck.getTime() + this.updateInterval >
      new Date().getTime()
    )
      return this.invalidFields;

    let missingFields: FormlyFieldConfig[] = [];
    for (const field of fields) {
      if (!field) continue;

      if (field.fieldGroup && field.fieldGroup.length > 0) {
        // If has a group, check the sub fields
        missingFields.push(...this.getInvalidFields(field.fieldGroup));
      } else if (this.isInvalidField(field)) {
        missingFields.push(field);
      }
    }

    return missingFields;
  }

  doubleCheckInvalidFields(missingFields: FormlyFieldConfig[]) {
    if (this.invalidFields.length !== missingFields.length) {
      this.invalidFieldsDoubleChecked = false;
    } else {
      let hasAllFields = true;
      for (const missingField of missingFields) {
        if (this.invalidFields.find((field) => field.id === missingField.id))
          continue;
        hasAllFields = false;
        break;
      }

      this.invalidFieldsDoubleChecked = hasAllFields;
    }
  }

  isInvalidField(field: FormlyFieldConfig) {
    return (
      field?.templateOptions?.required === true &&
      !field.templateOptions.hidden &&
      field.templateOptions.disabled !== true &&
      field.formControl?.status === 'INVALID'
    );
  }

  getTotalInvalidFields() {
    return this.invalidFields?.length || 0;
  }

  highlightLabel(labelElement: HTMLElement) {
    labelElement.classList.remove('highlight-field-red-animation');
    void labelElement.offsetWidth; // Trigger reflow to restart the animation
    labelElement.classList.add('highlight-field-red-animation');
  }
}
