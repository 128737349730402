import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@ripple/environment';

import { RipplePageGuard } from '../guards/ripple-page.guard';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { GraphApiService } from './graph-api.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  protected menuItems = new BehaviorSubject<MenuItem[]>([]);
  protected guardedMenuItems = new BehaviorSubject<MenuItem[]>([]);
  protected guard = true;
  private defaultAvatar = 'assets/img/anonymousUser.png';
  private avatarURL = null;
  private serviceDeskURL = null;
  private userGuideURL = null;
  private adminGuideURL = null;
  private helpDocsURL = null;

  public setAvatar(guid: string): string {
    if (!guid) this.avatarURL = null;
    else this.avatarURL = `${environment.restEndpointUrl}/api/blob/${guid}`;

    return this.getAvatar();
  }

  public getServiceDeskURL(): string {
    return this.serviceDeskURL;
  }

  public setServiceDeskURL(url: string) {
    this.serviceDeskURL = url;
  }

  public getUserGuideURL(): string {
    return this.userGuideURL;
  }

  public setUserGuideURL(url: string) {
    this.userGuideURL = url;
  }

  public getAdminGuideURL(): string {
    return this.adminGuideURL;
  }

  public setAdminGuideURL(url: string) {
    this.adminGuideURL = url;
  }

  public getHelpDocsURL(): string {
    return this.helpDocsURL;
  }

  public setHelpDocsURL(url: string) {
    this.helpDocsURL = url;
  }


  public getAvatar(): string {
    return this.avatarURL ? this.avatarURL : this.defaultAvatar;
  }

  /** Returns the current menu items */
  get items() {
    return this.guard ? this.guardedMenuItems.value : this.menuItems.value;
  }

  /** to be notified of a menu change */
  get $items() {
    // this is fancy so the menu always reflects the current guard, no matter when it was subscribed
    return zip(this.menuItems, this.guardedMenuItems).pipe(
      map(([unguarded, guarded]) => (this.guard ? guarded : unguarded))
    );
  }

  constructor(
    protected pageGuard: RipplePageGuard,
    protected auth: AuthService,
    private graphApiService: GraphApiService
  ) {
    this.menuItems
      .pipe(
        map((menuItems) =>
          menuItems.filter((menuItem) => this.pageGuard.show(menuItem))
        )
      )
      .subscribe(this.guardedMenuItems);

    this.auth.getLoggedInUser().subscribe((user) => {
      if (user && user.userImg) this.setAvatar(user.userImg.guid);
      else if (environment['useAzureAdAuth']) {
        this.graphApiService.getAvatarFromAzureAd().then(result => {
          this.avatarURL = result;
        });
      }
      else this.setAvatar(null);
      
      this.pushMenu();
    });
  }

  protected pushMenu() {
    this.menuItems.next(this.menuItems.value);
  }

  /** enables or disables the menu guard
   *
   * `(only show menu items the user can access)`
   *
   * `defaults to true`
   */
  enableGuard(enable: boolean = true) {
    this.guard = enable;
    this.pushMenu();
  }

  setMenu(menuItems: MenuItem[]);
  setMenu(...menuItems: MenuItem[]);
  setMenu(firstOrArray: MenuItem[] | MenuItem, ...menuItems: MenuItem[]) {
    menuItems =
      firstOrArray instanceof Array
        ? firstOrArray
        : [firstOrArray, ...menuItems];
    this.menuItems.next(menuItems);
  }

  addItems(menuItems: MenuItem[]);
  addItems(...menuItems: MenuItem[]);
  addItems(firstOrArray: MenuItem[] | MenuItem, ...menuItems: MenuItem[]) {
    menuItems =
      firstOrArray instanceof Array
        ? firstOrArray
        : [firstOrArray, ...menuItems];
    this.menuItems.next(this.menuItems.value.concat(menuItems));
  }

  removeItems(menuItems: MenuItem[]);
  removeItems(...menuItems: MenuItem[]);
  removeItems(firstOrArray: MenuItem[] | MenuItem, ...menuItems: MenuItem[]) {
    menuItems =
      firstOrArray instanceof Array
        ? firstOrArray
        : [firstOrArray, ...menuItems];
    this.menuItems.next(
      this.menuItems.value.filter(
        (has) => menuItems.findIndex((del) => del.label === has.label) !== -1
      )
    );
  }

  clearMenu() {
    this.menuItems.next([]);
  }
}
