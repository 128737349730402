// copied from primeng 9.1.3:
// https://github.com/primefaces/primeng/blob/9.1.3/src/app/components/editor/editor.ts

import {
  Component,
  ElementRef,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ContentChild,
  forwardRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { Header } from 'primeng/api'
import { DomHandler } from 'primeng/dom';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as Quill from "quill";
import { MessageService } from '@ripple/services';

export const EDITOR_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => WYSIWYGEditor),
  multi: true
};

@Component({
  selector: 'ripple-editor',
  template: `
    <div [ngClass]="'ui-widget ui-editor-container ui-corner-all'" [class]="styleClass">
      <div class="ui-editor-toolbar ui-widget-header ui-corner-top" *ngIf="toolbar">
        <ng-content select="p-header"></ng-content>
      </div>
      <div class="ui-editor-toolbar ui-widget-header ui-corner-top" *ngIf="!toolbar">
        <span class="ql-formats">
          <select class="ql-header">
            <option value="1">Heading</option>
            <option value="2">Subheading</option>
            <option selected>Normal</option>
          </select>
          <select class="ql-font">
            <option selected>Sans Serif</option>
            <option value="serif">Serif</option>
            <option value="monospace">Monospace</option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-bold" aria-label="Bold"></button>
          <button class="ql-italic" aria-label="Italic"></button>
          <button class="ql-underline" aria-label="Underline"></button>
        </span>
        <span class="ql-formats">
          <select class="ql-color"></select>
          <select class="ql-background"></select>
        </span>
        <span class="ql-formats">
          <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
          <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
          <select class="ql-align">
            <option selected></option>
            <option value="center"></option>
            <option value="right"></option>
            <option value="justify"></option>
          </select>
        </span>
        <span class="ql-formats">
          <button class="ql-link" aria-label="Insert Link"></button>
          <button class="ql-image" aria-label="Insert Image"></button>
          <button class="ql-code-block" aria-label="Insert Code Block"></button>
        </span>
        <span class="ql-formats">
          <button class="ql-clean" aria-label="Remove Styles"></button>
        </span>
      </div>
      <div class="ui-editor-content" [ngStyle]="style"></div>
    </div>
  `,
  providers: [EDITOR_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.Default
})
export class WYSIWYGEditor implements AfterViewInit,ControlValueAccessor {
  @Output() onTextChange: EventEmitter<any> = new EventEmitter();

  @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();

  @ContentChild(Header) toolbar;

  @Input() logKey: string;

  @Input() style: any;

  @Input() styleClass: string;

  @Input() placeholder: string;

  @Input() formats: string[];

  @Input() modules: any;

  @Input() bounds: any;

  @Input() scrollingContainer: any;

  @Input() debug: string;

  @Output() onInit: EventEmitter<any> = new EventEmitter();

  value: string;

  _readonly: boolean;

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  quill: any;

  constructor(
    public el: ElementRef,
    private messageService: MessageService
  ) {}

  ngAfterViewInit() {
    let editorElement = DomHandler.findSingle(this.el.nativeElement ,'div.ui-editor-content');
    let toolbarElement = DomHandler.findSingle(this.el.nativeElement ,'div.ui-editor-toolbar');
    let defaultModule  = {toolbar: toolbarElement};
    let modules = this.modules ? {...defaultModule, ...this.modules} : defaultModule;

    if (this.value) {
      this.log('setting initial value', this.value);
      (editorElement as HTMLElement).innerHTML = this.value || '';
    }

    this.quill = new Quill(editorElement, {
      modules: modules,
      placeholder: this.placeholder,
      readOnly: this.readonly,
      theme: 'snow',
      formats: this.formats,
      bounds: this.bounds,
      debug: this.debug,
      scrollingContainer: this.scrollingContainer
    });

    this.quill.on('text-change', (delta, oldContents, source) => {
      if (source === 'user') {
        let html = editorElement.children[0].innerHTML;
        let text = this.quill.getText().trim();
        if (html === '<p><br></p>') {
          html = '';
        }

        this.onTextChange.emit({
          htmlValue: html,
          textValue: text,
          delta: delta,
          source: source
        });

        this.log('text-change', {html, text, delta, source});
        this.onModelChange(html);
        this.onModelTouched();
      }
    });

    this.quill.on('selection-change', (range, oldRange, source) => {
      this.onSelectionChange.emit({
        range: range,
        oldRange: oldRange,
        source: source
      });
    });

    this.onInit.emit({
      editor: this.quill
    });
  }

  writeValue(value: any) : void {
    this.value = value;

    if (this.quill) {
      if (value) {
        this.log('write Value', value);

        if (!value.endsWith('<p><br></p>'))
          value += '<p><br></p>';

        let contents = this.quill.clipboard.convert(`<div class='ql-editor' style="white-space: normal;">${value}</div>`);
        let newContents = this.quill.setContents(contents);

        // Rory: Fix <ul> and <ol> being lost on setContents
        if (newContents !== contents) {
          this.quill.root.innerHTML = value || '';
        };
      }
      else {
        this.log('clear Value');
        this.quill.setText('');
      }
    }
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  getQuill() {
    return this.quill;
  }

  @Input() get readonly(): boolean {
    return this._readonly;
  }

  set readonly(val:boolean) {
    this._readonly = val;

    if (this.quill) {
      if (this._readonly)
        this.quill.disable();
      else
        this.quill.enable();
    }
  }

  private get logName() {
    const key = this.logKey ? `<${this.logKey}>` : '';
    return 'WYSIWYGEditor' + key;
  }

  // tslint:disable-next-line: no-any
  log(...args: any[]) {
    this.messageService.log(this.logName, ...args);
  }
}
