import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import Drawflow from 'drawflow'
import { FormSection } from '../../lib/form-section';
import { FormField } from '../../lib/form-field-general';
import { FormBuilderService } from '../../form-builder.service';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
  selector: 'form-builder-form-action-modal',
  templateUrl: './form-action-modal.component.html',
  styleUrls: ['./form-action-modal.component.scss']
})
export class FormActionModalComponent implements OnInit {

  editor:Drawflow|null= null;
  show = false;
  @Input() formActionsV2: any = {};
  @Input() formID: number;
  @Input() fields: FormlyFieldConfig[] = [];
  @Input() sections: FormSection[] = [];
  @Input() builderControls: boolean = false;
  @Output() onSave = new EventEmitter<any>();

  allForms: any[] = [];
  dataSnapShot: any = [];
  dataToImport: any = {};

  constructor(protected formService: FormBuilderService, ) { }

  ngOnInit(): void {
  }

  showModal() {
    this.show = true;
  } 

  onShowFormActionDialog() {
    document.getElementById('drawflow').innerHTML = "";

    this.editor = new Drawflow(document.getElementById('drawflow'));
    this.editor.reroute = true;
    this.editor.curvature = 0.5;
    this.editor.reroute_fix_curvature = true;
    this.editor.reroute_curvature = 0.5;
    this.editor.force_first_input = false;
    this.editor.line_path = 1;
    this.editor.editor_mode = 'edit';
    this.editor.on('nodeDataChanged', (id) => {
      this.nodeDataChanged(id);
    });
    this.editor.on('import', () => {
      this.importCompleted();
    });


    this.dataToImport = {
      "drawflow": {
        "Home": {
          "data": {
           
            "2": {
              "id": 2,
              "name": "saveForm",
              "data": {"savetype":"saveSubmit"},
              "class": "saveForm",
              "html": `
               <div>
                <div class="title-box"><i class="fab fa-telegram-plane"></i>Save Form</div>
                <div class="box">
                  <p>Select Save Type</p>
                  <select df-saveType>
                    <option value="saveSubmit">Save and Submit</option>
                    <option value="allSave">All Save Types</option>
                  </select>
                </div>
              </div>
              `,
              "typenode": false,
              "inputs": {
               
              },
              "outputs": {
                "output_1": {
                  "connections": [
                   
                  ]
                }
              },
              "pos_x": 50,
              "pos_y": 50
            },
          }
        },
      }
    }

    if(this.formActionsV2 && Object.keys(this.formActionsV2).length > 0) //make sure it's not an empty object
    this.dataToImport = this.formActionsV2;

    this.dataSnapShot = this.dataToImport.drawflow.Home.data;
    
    //editor.drawflow = { "name": '' }; // Default example json for the library
    this.editor.start();
    this.editor.import(this.dataToImport);
  }

  resetFormActions() {
    if(confirm("Are you sure you want to reset the form actions?")) {
      this.formActionsV2 = {};
      this.onSave.emit({}); 
      this.onShowFormActionDialog();
    }
  }

  cancelFormActions() {
    this.show = false;
  }

  saveFormActions() {
    console.log(this.editor.export());
    console.log(this.fields);
    this.formActionsV2 = this.editor.export();
    this.onSave.emit(this.formActionsV2); 
    this.show = false;
  }

  drawflowAllowDrop(ev) {
    ev.preventDefault();
  }

  drawflowDrag(ev) {
   
      ev.dataTransfer.setData("node", ev.target.getAttribute('data-node'));
  }

  drawflowDrop(ev) {
    
      ev.preventDefault();
      var data = ev.dataTransfer.getData("node");
      this.addNodeToDrawFlow(data, ev.clientX, ev.clientY);

  }

  async addNodeToDrawFlow(name, pos_x, pos_y) {
    if(this.editor.editor_mode === 'fixed') {
      return false;
    }
    pos_x = pos_x * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().x * ( this.editor.precanvas.clientWidth / (this.editor.precanvas.clientWidth * this.editor.zoom)));
    pos_y = pos_y * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)) - (this.editor.precanvas.getBoundingClientRect().y * ( this.editor.precanvas.clientHeight / (this.editor.precanvas.clientHeight * this.editor.zoom)));


    switch (name) {
     
      case 'saveForm':
        //#region saveForm
        var saveForm = `
          <div>
            <div class="title-box"><i class="fab fa-telegram-plane"></i>Save Form</div>
            <div class="box">
              <p>Trigger on:</p>
              <select df-saveType>
                <option value="saveSubmit">Save and Submit</option>
                <option value="allSave">All Save Types</option>
              </select>
            </div>
          </div>
        `;
        this.editor.addNode('saveForm', 0, 1, pos_x, pos_y, 'saveForm', { }, saveForm, false );
        //#endregion
        break;

         
        case 'createForm':
        //#region createForm
        var createForm = `
          <div>
            <div class="title-box"><i class="fab fa-telegram-plane"></i>Create Form</div>
            <div class="box">
              <p>This will execute when this form is Created</p>
               <input type="hidden" df-create value="1" />
            </div>
          </div>
        `;
        this.editor.addNode('createForm', 0, 1, pos_x, pos_y, 'createForm', { }, createForm, false );
        //#endregion
        break;

        case 'sendEmail':
        //#region sendEmail
        var sendEmail = `
        <div ">
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Send Email </div>
          <div class="box">
            <p>Email Addresses </p>
            <input type="text" df-email placeholder="Email Addresses">
            <p><small> comma separated</small></p>
          <br><br>

            
          </div>
        </div>
        `;
        this.editor.addNode('sendEmail', 1, 0, pos_x, pos_y, 'sendEmail', { }, sendEmail, false );
        //#endregion
        break;

        case 'TSC40SubScorer':
        //#region sendEmail
        var TSC40SubScorer = `
        <div ">
          <div class="title-box"><i class="fab fa-telegram-plane"></i>TSC-40 Sub Scorer </div>
          <div class="box">
            <p>This will calculate the TSC-40 Sub Scores</p>
          </div>
        </div>
        `;
        this.editor.addNode('TSC40SubScorer', 1, 0, pos_x, pos_y, 'TSC40SubScorer', { }, TSC40SubScorer, false );
        //#endregion
        break;
       
        case 'createTask':
        //#region createTask
          const fieldKeysCT = [];
          for (const section of this.sections)
            fieldKeysCT.push(...this.getAllFieldKeysAndLabelsByType(section, 'datetime-picker'));

        var createTask = `
        <div>
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Create Task</div>

          <div class="box">
          <p>You must select a question that is a DateTime.  The Task will be created with a due date of that date, and assigned to the user that submits the form.</p>

          

            <p>Select DateTime Question <p>
              <select  name="question" df-question>
              <option selected="selected">-- Select One--</option>
              `;

              if(fieldKeysCT.length === 0) {
                createTask += `<option value="">No DateTime Questions Found</option>`;
              }

              fieldKeysCT.forEach((field) => {
                createTask += `<option value="${field.key}">${field.label}</option>`;
              });
              
              createTask += `
              
             
            </select>
            <p>Title of New Task</p>
            <input type="text" df-title placeholder="Title">
            

</div>
          </div>
        </div>
        `;
        this.editor.addNode('createTask', 1, 0, pos_x, pos_y, 'createTask', { }, createTask, false );
        //#endregion
        break;

        case 'syncClientDetailsString':
        //#region syncClientDetailsString

        const fieldKeysSDS = [];
          for (const section of this.sections)
            {
              fieldKeysSDS.push(...this.getAllFieldKeysAndLabelsByType(section, 'generic-input'));
              fieldKeysSDS.push(...this.getAllFieldKeysAndLabelsByType(section, 'datetime-picker'));
              fieldKeysSDS.push(...this.getAllFieldKeysAndLabelsByType(section, 'radio-multiple-col'));
              fieldKeysSDS.push(...this.getAllFieldKeysAndLabelsByType(section, 'checkbox-multiple-col'));
              fieldKeysSDS.push(...this.getAllFieldKeysAndLabelsByType(section, 'select-search'));
            }


        var syncClientDetailsString = `
        <div>
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Sync Text Client Details</div>
          <div class="box">
            <p>Select Client Details Field to sync<p>
            <select  name="question" df-demographicField>
              <option selected="selected">-- Select One--</option>
              <option value="DOB">DOB</option>
              <option value="Pronouns">Pronouns</option>
              <option value="Phone1">Phone1</option>
              <option value="Phone2">Phone2</option>
              <option value="Email">Email</option>
              <option value="FirstName">First Name</option>
              <option value="LastName">Last Name</option>
              <option value="Address">Address</option>
              <option value="IdentifiesAs">IdentifiesAs</option>
            </select>

            
            <p>Select Form Question <p>
              <select  name="question" df-formField>
              <option selected="selected">-- Select One--</option>
              `;

              if(fieldKeysSDS.length === 0) {
                syncClientDetailsString += `<option value="">No Applicable Questions Found</option>`;
              }

              fieldKeysSDS.forEach((field) => {
                syncClientDetailsString += `<option value="${field.key}">${field.label}</option>`;
              });
              
              syncClientDetailsString += `
            </select>

            
          </div>
        </div>
        `;
        this.editor.addNode('syncClientDetailsString', 1, 0, pos_x, pos_y, 'syncClientDetailsString', { }, syncClientDetailsString, false );
        //#endregion
        break;

        case 'syncClientDetailsCommunications':
        //#region syncClientDetailsCommunications
        const fieldKeysSDC = [];
          for (const section of this.sections)
            {
              fieldKeysSDC.push(...this.getAllFieldKeysAndLabelsByType(section, 'radio-multiple-col'));
              fieldKeysSDC.push(...this.getAllFieldKeysAndLabelsByType(section, 'checkbox-multiple-col'));
              fieldKeysSDC.push(...this.getAllFieldKeysAndLabelsByType(section, 'select-search'));
            }


        var syncClientDetailsCommunications = `
        <div>
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Sync Contact Demographics</div>
          <div class="box">
           <p>Select Client Details Question to sync<p>
            <select  name="question" df-demographicField>
              <option selected="selected">-- Select One--</option>
              <option value="sendEmail|Yes">Send Email?</option>
              <option value="sendSMS|Yes">Send SMS?</option>
              <option value="preferredMethodOfCommunication|Email">Pref Comm Method: Email</option>
              <option value="preferredMethodOfCommunication|Phone">Pref Comm Method: Phone</option>
              <option value="preferredMethodOfCommunication|SMS">Pref Comm Method: SMS</option>
              <option value="appointmentRemindersEmail|Email">Appt Reminder: Email</option>
              <option value="appointmentRemindersEmail|SMS">Appt Reminder: SMS</option>
              <option value="appointmentRemindersEmail|Both">Appt Reminder: Both</option>
              <option value="appointmentRemindersEmail|None">Appt Reminder: None</option>
            </select>

            <p>Select Form Question <p>
              <select  name="question" df-formField>
              <option selected="selected">-- Select One--</option>
              `;

              if(fieldKeysSDC.length === 0) {
                syncClientDetailsCommunications += `<option value="">No Applicable Questions Found</option>`;
              }

              fieldKeysSDC.forEach((field) => {
                syncClientDetailsCommunications += `<option value="${field.key}">${field.label}</option>`;
              });
              
              syncClientDetailsCommunications += `
            </select>

         <p>Option Label:<p>
             <input type="text" df-option placeholder="Option Label">
             <p><small>The exact option label that matches the question (typically either: Yes, Y, Email, SMS or Phone)</small></p>
             
          </div>
        </div>
        `;
        this.editor.addNode('syncClientDetailsCommunications', 1, 0, pos_x, pos_y, 'syncClientDetailsCommunications', { }, syncClientDetailsCommunications, false );
        //#endregion
        break;

        case 'syncPostEventStatistics':
        //#region syncPostEventStatistics
        const fieldKeysSES = [];
        for (const section of this.sections)
          {
            fieldKeysSES.push(...this.getAllFieldKeysAndLabelsByType(section, 'generic-input'));
            fieldKeysSES.push(...this.getAllFieldKeysAndLabelsByType(section, 'radio-multiple-col'));
            fieldKeysSES.push(...this.getAllFieldKeysAndLabelsByType(section, 'checkbox-multiple-col'));
          }


        var syncPostEventStatistics = `
        <div>
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Sync Post Event Statistics</div>
          <div class="box">
            <p>Select Event Stats Field to sync<p>
            <select  name="question" df-eventstatField>
              <option selected="selected">-- Select One--</option>
              <option value="NumberAttendees"># of Attendees</option>
              <option value="NumberNoShows"># of No Shows</option>
              <option value="NumberExcused"># Excused (Non-Participants)</option>
              <option value="NumberPamphlets"># of Pamphlets</option>
              <option value="NumberPresentations"># of Presentations</option>
              <option value="NumberDisclosures"># of Disclosures</option>
              <option value="NumberClasses"># of Classes</option>
            </select>

            
            <p>Select Form Question <p>
              <select  name="question" df-formField>
              <option selected="selected">-- Select One--</option>
              `;

              if(fieldKeysSES.length === 0) {
                syncPostEventStatistics += `<option value="">No Applicable Questions Found</option>`;
              }

              fieldKeysSES.forEach((field) => {
                syncPostEventStatistics += `<option value="${field.key}">${field.label}</option>`;
              });
              
              syncPostEventStatistics += `
            </select>

            
          </div>
        </div>
        `;
        this.editor.addNode('syncPostEventStatistics', 1, 0, pos_x, pos_y, 'syncPostEventStatistics', { }, syncPostEventStatistics, false );
        //#endregion
        break;

        case 'copyDataFormToForm':
        //#region copyDataFormToForm

        //Get a list of all the forms

        if(this.allForms.length === 0) {
          this.allForms = await this.formService.getForms().toPromise();
        };



        var copyDataFormToForm = `
        <div>
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Copy Data Form-to-Form</div>
          <div class="box">
          <p>One of the forms must be this form type (denoted with ***); the other form will grab the most recent version of that form.</p>
          <br/>
          <p>Source Form</p>
          <select name="sourceForm" df-sourceForm class="sourceForm">
              <option selected="selected">-- Select One--</option>
              `;

              //Show the current form first always
              this.allForms.filter(form => form.id == this.formID).forEach((form) => {
                  copyDataFormToForm += `<option value="${form.id}">***${form.name}***</option>`;
              });
              
              this.allForms.filter(form => form.id != this.formID).forEach((form) => {
                copyDataFormToForm += `<option value="${form.id}">${form.name}</option>`;
              });
              
              copyDataFormToForm += `
          </select>

           <p>Source Question<p>
            <select  name="sourceQuestion" df-sourceQuestion class="sourceQuestion">
              <option selected="selected">-- Select Form First--</option>
            </select>

            <div style="text-align: center">
              <i class="fas fa-arrow-down"></i>&nbsp;
              <i class="fas fa-arrow-down"></i>&nbsp;
              <i class="fas fa-arrow-down"></i>&nbsp;
              <i class="fas fa-arrow-down"></i>
            </div>


            <p>Destination Form</p>
            <select name="destForm" df-destForm class="destForm">
              <option selected="selected">-- Select One--</option>
              `;

            //Show the current form first always
            this.allForms.filter(form => form.id == this.formID).forEach((form) => {
                copyDataFormToForm += `<option value="${form.id}">***${form.name}***</option>`;
             });
            
            this.allForms.filter(form => form.id != this.formID).forEach((form) => {
              copyDataFormToForm += `<option value="${form.id}">${form.name}</option>`;
            });


              copyDataFormToForm += `
          </select>

           <p>Destination Question<p>
            <select  name="destQuestion" df-destQuestion class="destQuestion">
              <option selected="selected">-- Select Form First--</option>
            </select>

        </div>
        `;
        this.editor.addNode('copyDataFormToForm', 1, 0, pos_x, pos_y, 'copyDataFormToForm', { }, copyDataFormToForm, false );
        //#endregion
        break;

        case 'syncOtherForm':
        //#region syncOtherForm
        var syncOtherForm = `
        <div>
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Sync Other Form</div>
          <div class="box">
            <p>Select Form to Sync</p>
            <select  name="form" df-channel>
              <option selected="selected">-- Select One--</option>
              <option value="channel_1">Victimization</option>
              <option value="channel_2">Case Notes</option>
              <option value="channel_3">Court Reminder</option>
              <option value="channel_3">...</option>
            </select>

            <p>Select Source Question <p>
              <select  name="question" df-channel>
              <option selected="selected">-- Select One--</option>
              <option value="channel_1">Phone</option>
              <option value="channel_2">Birthday</option>
              <option value="channel_3">Can Receive Phone Calls</option>
              <option value="channel_4">...</option>
            </select>

            <p>Select Dest Question to sync<p>
            <select  name="question" df-channel>
              <option selected="selected">-- Select One--</option>
              <option value="channel_1">Phone Number</option>
              <option value="channel_2">DOB</option>
              <option value="channel_3">Can Receive Phone Calls</option>
              <option value="channel_4">...</option>
            </select>
           
          </div>
        </div>
        `;
        this.editor.addNode('telegram', 1, 0, pos_x, pos_y, 'syncOtherForm', { "channel": 'channel_3'}, syncOtherForm, false );
        //#endregion
        break;

        case 'createOtherForm':
        //#region createOtherForm
        var createOtherForm = `
        <div>
          <div class="title-box"><i class="fab fa-telegram-plane"></i>Create Other Form</div>
          <div class="box">
            <p>Select Question <p>
              <select  name="question" df-channel>
              <option selected="selected">-- Select One--</option>
              <option value="channel_1">Primary Abuse</option>
              <option value="channel_2">Secondary Abuse</option>
              <option value="channel_3">Requires Follow-up?</option>
              <option value="channel_4">...</option>
            </select>

            <p>Select Answer <p>
              <select name="answer" df-channel>
              <option selected="selected">-- Select One--</option>
              <option value="channel_1">Yes</option>
              <option value="channel_2">No</option>
            </select>

            <p>Select Form to Create</p>
            <select  name="form" df-channel>
              <option selected="selected">-- Select One--</option>
              <option value="channel_1">Victimization</option>
              <option value="channel_2">Case Notes</option>
              <option value="channel_3">Court Reminder</option>
              <option value="channel_3">...</option>
            </select>
          </div>
        </div>
        `;
        this.editor.addNode('telegram', 1, 0, pos_x, pos_y, 'createOtherForm', { "channel": 'channel_3'}, createOtherForm, false );
        //#endregion
        break;

   

      default:
      }
  }

  getAllFieldKeysAndLabelsByType(section: FormSection, type: string): any[] {
    const fields = [];

    for (const child of section.children)
      if (
        child instanceof FormField &&
        child.type === type
      )
        fields.push({key: child.key, label: child.label});
      else if (child instanceof FormSection)
        this.getAllFieldKeysAndLabelsByType(child, type).forEach((obj) =>
          fields.push({key: obj.key, label: obj.label})
        );

    return fields;
  }

  importCompleted(){
    // go through all the nodes and see if we need to retrieve any of the questions using nodeDataChanged
    var nodes = this.dataToImport.drawflow.Home.data;
    for(const property in nodes) {
      let node = nodes[property];
      if(node.name == 'copyDataFormToForm') {
        this.nodeDataChanged(node.id);
        }
    };
  }


  nodeDataChanged(id) {
    var data= this.editor.getNodeFromId(id);
    var name = data.name;
    
    //See what properties have changed
    var oldData = {};
    if(this.dataSnapShot[id])
      oldData = this.dataSnapShot[id];
    else
      this.dataSnapShot[id] = {};

    var changedProperties = [];
    for (var key in data.data) {
      if (data.data[key] !== oldData[key]) {
        changedProperties.push(key);
      }
    }

    this.dataSnapShot[id] = data.data;

    changedProperties.forEach((prop) => {
      this.changedNodeProperty(id, name, prop, data.data)
    });



    

  }

  changedNodeProperty(nodeId, nodeName, propertyName, nodeData) {
    if(nodeName == 'copyDataFormToForm' && (propertyName == 'sourceform' || propertyName == 'destform')) {
      var formId = nodeData[propertyName];
      this.formService.getEntityStructure(formId).pipe(first()).toPromise().then(entityType => {
        var fieldKeys = [];
        for (const cfim of entityType.customFieldsInModules) {
          if (cfim.unchangeableName != 'EntitySelect_FilledForm') {
            fieldKeys.push({value: cfim.unchangeableName, text: cfim.label});
          }
        }

        let questionName = propertyName === 'sourceform' ? 'sourceQuestion' : 'destQuestion';
        let questionData = '';
        if(questionName)
          questionData = nodeData[questionName.toLowerCase()];

        this.replaceSelectOptions(nodeId, questionName, fieldKeys, questionData);
      });

    }
  }



  replaceSelectOptions(id, className, options, currValue) {
    var select = document.querySelector(`#node-${id} .${className}`) as HTMLSelectElement;

    var i, L = select.options.length - 1;
    for(i = L; i >= 0; i--) {
      select.remove(i);
   }

   select.innerHTML = "";
   var opt = document.createElement('option');
   opt.value = "-1";
   opt.innerHTML = "-- Select One--";
   select.appendChild(opt);

    options.forEach((option) => {
      var opt = document.createElement('option');
      opt.value = option.value;
      opt.innerHTML = option.text;
      if(currValue == option.value)
        opt.selected = true;
      select.appendChild(opt);
    });
  }
    



}
