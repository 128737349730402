import { FormGroup } from '@angular/forms';
import { EntityFilter } from '../models/entity-filter';

export interface IFormBuilderChoice {
  value: string;
  optionName: string;
  hasSpecifyText: boolean;
  specifyTextLabel?: string;

  id?: number;
}

export interface IFieldSectionFrontendTemplate {
  internalType: string;
  containsCustomJson?: boolean;

  deletable: boolean;
  editable: boolean;
  hidden: boolean;
}

export interface IFieldFrontendTemplate extends IFieldSectionFrontendTemplate {
  dataType: string;
  label: string;
  toType: string | boolean;
  type: string;
  key: string;
  description: string;
  height: string;
  optionalClass: string;
  options: IFormBuilderChoice[];
  maxLength: number;
  listEntityTypeID: number;
  selectedEntity;
  fileTypes: any[];
  filePermission: string;
  autoFillSignature: boolean;
  isSignedField: { id: string, optionName: string };
  showTime: boolean;
  disabled: boolean;
  required: boolean;
  isMultiSelect: boolean;
  isHorizontal: boolean;
  internalID?: string;
  parentID?: string;
}

export interface ISectionFrontendTemplate extends IFieldSectionFrontendTemplate {
  wrapper: string;
  customRenderIdentifier: string;
  sectionName: string;
  className: string;
  internalID: string;
  subData: (ISectionFrontendTemplate | IFieldFrontendTemplate)[];
}

export interface IFormBuilderFrontendTemplate {
  name: string;
  metaParents: string;
  parents: string[];
  requiresApproval?: boolean;
  formActionsV2: any;
  data: ISectionFrontendTemplate[];
}

export interface IFilledFieldObject {
  key: string;
  // tslint:disable-next-line: no-any
  value: any;
}

export interface IFilterableEntityTypeDescriptor {
  id: number;
  optionName?: string;
  entityFilter?: ((model) => EntityFilter) | EntityFilter;
  filterProperties?: {
    name?: string,
    roles?,
  };
}

export interface FormGroupContainer {
  readonly formGroup: FormGroup;
}


export function isSectionTemplate(template: IFieldFrontendTemplate | ISectionFrontendTemplate): template is ISectionFrontendTemplate {
  return template.internalType !== 'field';
}

export function isFieldTemplate(template: IFieldFrontendTemplate | ISectionFrontendTemplate): template is IFieldFrontendTemplate {
  return template.internalType === 'field';
}
