import { OnDestroy, Directive, isDevMode, HostListener } from '@angular/core';
import { SubscriptionContainer } from '@ripple/models';
import { WarpEntityLogService } from '@ripple/services';

/**
 * Basic functions and helpers to manage subscriptions
 * all subscriptions must be assigned to the `this.sub` variable and OnDestroy (if overrided) must call `this.clearSubs()`
 */
@Directive({ selector: '[rippleBasePage]' })
// tslint:disable-next-line: directive-class-suffix
export class BasePage extends SubscriptionContainer implements OnDestroy {

  isDevMode: boolean = isDevMode();
  ignorePopStateTime: number;
  activeConfirmDeactivateCallback: (boolean: boolean) => void;

  beforeUnloadListener: EventListener;
  popStateListener: EventListener;
  hashChangeListener: EventListener;

  constructor(protected entityLogsService: WarpEntityLogService) {
    super();
    this.logFromUrl.bind(this);
    this.getLogFormat.bind(this);
    this.entityLogsService.setComponent(this);

    // Attempts to refresh/reload browser
    window.addEventListener('beforeunload', this.beforeUnloadListener = (event) => {
      if (!this.canDeactivate()) {
        event.preventDefault();
        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
      }
    });

    // Hits back buttton
    window.addEventListener('popstate', this.popStateListener = (event) => {
      if (this.preventBackCompletely()) {
        window.history.forward();
        return;
      }

      if (Date.now() - this.ignorePopStateTime < 500) {
        return;
      }

      // If we cant de-activate, go forward and ask the user to confirm if they want to leave
      if (!this.canDeactivate()) {
        this.ignorePopStateTime = Date.now();
        window.history.forward();

        // Check if already confirming to go back
        if (!this.activeConfirmDeactivateCallback) {
          this.confirmDeactivate(this.activeConfirmDeactivateCallback = (accept) => {
            this.activeConfirmDeactivateCallback = null;
            if (accept) {
              this.ignorePopStateTime = Date.now();
              window.history.back();
            } else {
              this.ignorePopStateTime = Date.now();
              event.preventDefault();
              window.history.forward();
            }
          });
        }
      }
    });

    if (this.preventBackCompletely()) {
      if (!window.location.href.endsWith('#') && !window.location.href.endsWith('#!')) {
        window.location.href += '#';
      }

      window.setTimeout(() => {
        if (!window.location.href.endsWith('!')) {
          window.location.href += '!';
        }
      }, 1);

      const _hash = '!';
      window.addEventListener('hashchange', this.hashChangeListener = () => {
        if (!this.canDeactivate() && !window.location.hash.endsWith(_hash)) {
          window.location.hash = _hash;
        }
      });
    }
  }

  /**
   * @param url The current url in the route, used for you to parse for the entityIDs accordingly (If your components variables don't suffice)
   *
   * @returns Returns a single or array of entityIDs for this service to log as viewed by the current user
   *
   * NOTE: There a few different cases you should be considering when implementing this method.
   * - If you want to log an entity or entities as viewed return their id(s).
   * - If you want to log something as viewed that doesnt directly tie to an entity, return [] (Ex. "Viewed report page").
   * - If you dont want to log anything, return -1 (This should be a rare case!)
   */
  logFromUrl(url: string): number | number[] {
    console.log(this.constructor.name, ' HAS NOT IMPLEMENTED logFromURL');
    this.entityLogsService.sendBasePageError(this.constructor.name);
    return -1;
  }

  /**
   * @param url The current url in the route, used for you to parse for the entityIDs accordingly (If your components variables don't suffice)
   *
   * @returns  Returns a custom string used by the logs to format this components log information.
   *
   * NOTE: There a few different cases you should be considering when implementing this method.
   * - If you returned an entity or entities in logFromUrl you can reference them in your format by using {index} placeholders. (Ex. "Viewed {0}'s files" where in logFromUrl you might have returned [123])
   * - If you returned an entity but dont need a specific format, you can return '' and it will automatically default to 'Viewed {0}'
   * - If you returned [] in logFromUrl you can just specify a static format that doesnt relate to any entities (Ex. "Viewed report page")
   * - If you dont want to log anything, return '' (This should be a rare case!)
   *
   * Ex. Return "Viewed {0}'s File List"
   * The {0} will automatically be replaced with the first entityID returned from the logFromUrl method, {1} would be the second, and so on
   */
  getLogFormat(url: string): string {
    console.log(this.constructor.name, ' HAS NOT IMPLEMENTED getLogFormat');
    this.entityLogsService.sendBasePageError(this.constructor.name);
    return '';
  }

  ngOnDestroy(): void {
    this.entityLogsService.removeComponent(this);
    this.clearSubs();

    if (this.popStateListener) window.removeEventListener('popstate', this.popStateListener);
    if (this.beforeUnloadListener) window.removeEventListener('beforeunload', this.beforeUnloadListener);
    if (this.hashChangeListener) window.removeEventListener('hashchange', this.hashChangeListener);
  }

  canDeactivate(): boolean {
    return true;
  }

  confirmDeactivate(callback: (boolean: boolean) => void): void {
      callback(confirm('You have unsaved changes, are you sure you want to leave this page?'));
  }

  preventBackCompletely(): boolean {
    return false;
  }
}
