<div class="p-d-flex">
  <ng-container *ngFor="let item of presets">
    <button pButton
      class="preset ui-togglebutton" iconPos="right"
      [class.ui-state-active]="item.value === formControl.value"
      [label]="item.value === formControl.value ? item.onLabel : item.offLabel"
      [icon]="item.value === formControl.value ? item.onIcon : item.offIcon"
      (click)="formControl.setValue($event ? item.value : null); blur(); change(item.value)">
    </button>
  </ng-container>

  <!-- Render "Left Content" Module Setting -->
  <div *ngIf="leftContent" style="flex: 1 0 auto;align-self: center;" [innerHTML]="leftContent"></div>

  <!-- Render Data For User -->
  <ng-template #inputMask>
    <p-inputMask *ngIf="mask" style="width: 100%;flex: 1 1 100%;"
      [type]="to.type" [formControl]="formControl" [formlyAttributes]="field" [mask]="mask"
      (blur)="blur()" autocomplete="off" data-lpignore="true"
    ></p-inputMask>
  </ng-template>

  <ng-template #inputDate>
    <input pInputText *ngIf="to.type === 'date'" style="width: 100%;flex: 1 1 100%" max="{{(maxDate | date:'yyyy-MM-dd') || '2050-01-01'}}"
      [type]="to.type" [maxLength]="to.maxLength" [formControl]="formControl" [formlyAttributes]="field"
      (change)="change($event.target.value)" (blur)="blur()" autocomplete="off" data-lpignore="true"
    />
  </ng-template>

  <ng-template #inputView>
    <div pInputTextarea *ngIf="to.viewOnly" class="ui-widget ui-corner-all ui-state-disabled" style="width: 100%;height:100%;flex: 1 1 100%;opacity: 0.5" contenteditable="false"
      [disabled]="true" [formControl]="formControl" [formlyAttributes]="field"
      (change)="change($event.target.value)" (blur)="blur()" autocomplete="off" data-lpignore="true"
    >{{formControl.value}}</div>
  </ng-template>

  <ng-container *ngIf="!mask; else inputMask">
    <ng-container *ngIf="to.type !== 'date'; else inputDate">
      <input pInputText *ngIf="!to.viewOnly; else inputView" style="width: 100%;flex: 1 1 100%"
        [type]="to.type" [maxLength]="to.maxLength" [formControl]="formControl" [formlyAttributes]="field"
        (change)="change($event.target.value)" (blur)="blur()" autocomplete="off" data-lpignore="true"
      />
    </ng-container>
  </ng-container>

  <!-- Render "Right Content" Module Setting -->
  <div *ngIf="rightContent" style="flex: 1 0 auto;align-self: center;" [innerHTML]="rightContent"></div>
</div>
