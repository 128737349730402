<div class="p-grid" [ngClass]="to.isHorizontal ? 'spacingChange' : ''">
  <ng-container *ngIf="!to.selectedTheme || to.selectedTheme === 'default'">
    <ng-container *ngFor="let option of radioOptions$ | async">
      <div [ngClass]="!to.isHorizontal ? (to.optionalClass ? to.optionalClass : 'p-col p-flex-grow-0 p-flex-basis-auto') : 'spacingChange'">
        <p-radioButton [ngStyle]="{ display: 'flex' }" [styleClass]="to.isHorizontal? 'horizontal' : ''" [name]="to.name" [value]="option.id"
          [label]="option.optionName" [(ngModel)]="selectedID" (ngModelChange)="radioChanged($event, option)"
          [disabled]="viewOnly"></p-radioButton>

        <ng-container *ngTemplateOutlet="specifyTextInput; context: {$implicit: option}"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>


<ng-template #specifyTextInput let-option>
  <ripple-form-specify-text
    *ngIf="option"
    [option]="option"
    [viewOnly]="viewOnly"
    [hidden]="option.id !== selectedID"
    (optionChanged)="specifyTextChange($event.$event, $event.option)"
  ></ripple-form-specify-text>
</ng-template>
