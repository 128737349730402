import { Injectable, EventEmitter, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@ripple/environment';

import { map } from 'rxjs/operators';

import {
  Hub,
  HubService,
  HubWrapper,
  HubSubscription
} from 'ngx-signalr-hubservice';
import { InternalCookieService, CookieType, MessageService, AuthService } from './../general/';
import { HubConnectionService } from '../hubs/hub-connection.service';
import { TaskNotificationMessage, WarpEntity } from '@ripple/models';
import { RippleApiService } from '../ripple-api/ripple-api.service';
import { HttpClient } from '@angular/common/http';

// More information can be found here - https://github.com/steveadoo32/ngx-signalr-hubservice




@Injectable({
  providedIn: 'root'
})
@Hub({ hubName: 'TaskNotificationHub' })
export class TaskNotificationService extends RippleApiService {

  private hubWrapper: HubWrapper;
  private hubService: HubService;
  private currentSubscribeToken: string;

  public onTaskNotificationReceived = new EventEmitter<TaskNotificationMessage>();

  constructor(
    protected injector: Injector,
    private cookieService: InternalCookieService,
    private hubConnectionService: HubConnectionService
  ) {
    super( injector.get(HttpClient), injector.get(MessageService), injector.get(AuthService) );
    const authService = injector.get(AuthService);
    this.hubService = this.hubConnectionService.getHubService();
    this.hubConnectionService.connectionChanged.subscribe(connectionState => {
      if (connectionState) {
        const currentUser = authService.getSyncLoggedInUser();
        if (currentUser) {
          // only subscribe to notification if the user is logged in and have guid
          if (this.hubWrapper)
            this.hubWrapper.unregister();
          this.hubService = this.hubConnectionService.getHubService();
          this.hubWrapper = this.hubConnectionService.getHubService().register(this);
          const token = currentUser.guid;
          if (token) {
            if (this.currentSubscribeToken && token !== this.currentSubscribeToken) {
              // unsubscribe from old subscription if new user is logged in
              this.unsubscribe(this.currentSubscribeToken);
            }
            this.currentSubscribeToken = token;
            this.subscribe(token);
          }
        }
      }
    });
  }

  subscribe(channel: string) {
    if (channel) {
      this.log('Connected - Task Notification - Subscribe');
      this.hubWrapper
        .invoke('subscribe', channel)
        .toPromise()
        .then(_ => this.log('Registered - Task Notification'))
        .catch(err => this.log('Could not Register - Task Notification', err, MessageService.ERROR));
    }
  }

  unsubscribe(token: string) {
    if (token) {
      this.hubService.connect({ url: environment.hubUrl })
        .subscribe(success => {
          this.log('Connected - Task Notification - Unsubscribe', success);
          if (success)
            this.hubWrapper
              .invoke('unsubscribe', token)
              .toPromise()
              .then(_ => this.log('Un-Registered - Task Notification'));
        });
    }
  }

  // Client => Server the function name is the same as the name of the function in the c# hub
  public sendMessage(task: TaskNotificationMessage): Observable<TaskNotificationMessage> {
    return this.hubWrapper
      .invoke<boolean>('sendMessage',
        task.task_id,
        task.taskName,
        task.type,
        task.status,
        task.description,
        task.completion_progress,
        task.creatorName)
      .pipe(map( _ => task ));
  }

  // Server => Client
  @HubSubscription()
  private receiveMessage(task_id: string, taskName: string, type: string, status: string,
                         description: string, completion_progress: string, creatorName: string) {
    const task: TaskNotificationMessage = {
      task_id,
      completion_progress,
      status,
      description,
      creatorName,
      taskName,
      type
    };
    this.log(`Received Notification: ${task.taskName}`, task);
    this.onTaskNotificationReceived.emit(task);
  }

  public sendTestTaskNotification() {
    return super._get(`/api/taskNotification/1111/testNotification`, `Send test task notification`);
  }

  public dismiss(taskId) {
    return super._get(`/api/taskNotification/dismiss/${taskId}`, `Dismiss notification ${taskId}`);
  }

  public dismissAll() {
    return super._get(`/api/taskNotification/dismiss`, `Dismiss all notifications`);
  }

  log(...args: any[]) {
    this.messageService.add('TaskNotificationService', ...args);
  }
}
