import { Injectable } from '@angular/core';
import { AuthService, InternalCookieService, MessageService, RippleApiService } from '@ripple/services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TreeNode } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})

export class AgencyStatsService extends RippleApiService {
  private restURL = '/api/sagesse';

  constructor(
    http: HttpClient,
    messageService: MessageService,
    authService: AuthService,
    private cookieService: InternalCookieService
  ) {
    super(http, messageService, authService);
  }

  public getOrCreateStatsForDateRange(month: string | number, year: string | number): Observable<any> {
    const body = {
      month: month.toString(),
      year: year.toString(),
    }

    return this._post(this.restURL.concat('/getorcreatestatsfordaterange/'), body);
  }

  public getOrCreateStatsAssignedToUser(month: string | number, year: string | number): Observable<any> {
    const body = {
      month: month.toString(),
      year: year.toString(),
    }

    return this._post(this.restURL.concat('/getorcreatestatsassignedtouser/'), body);
  }

  public getStrategicReport(startMonth: number | string, startYear: number | string, endMonth: number | string, endYear: number | string): Observable<any> {
    return this._get(this.restURL.concat('/getstrategicreport/', startMonth.toString(), '/', startYear.toString(), '/', endMonth.toString(), '/' ,endYear.toString()));
  }

  public getStrategicReportV2(
    startMonth: number | string,
    startYear: number | string,
    endMonth: number | string,
    endYear: number | string,
    selectedStats: TreeNode[]
  ): Observable<any> {
    const body = {
      startMonth: startMonth.toString(),
      startYear: startYear.toString(),
      endMonth: endMonth.toString(),
      endYear: endYear.toString(),
      selectedStats: selectedStats,
    };
    return this._post(this.restURL.concat('/v2/getstrategicreport/'), body);
  }

  public getStatisticsTree(): Observable<any> {
    return this._get(this.restURL.concat('/getstatstree/'));
  }

  public getIndividualReport(startMonth: number | string, startYear: number | string, endMonth: number | string, endYear: number | string, reportId: number | string ): Observable<any> {
    return this._get(this.restURL.concat('/getindividualreport/', startMonth.toString(), '/', startYear.toString(), '/', endMonth.toString(), '/', endYear.toString(), '/', reportId.toString()));
  }

  public sanityCheck(id: number | string, value: number | string): Observable<any> {
    return this._get(this.restURL.concat('/sanitycheck/' + id.toString() + '/' + value.toString()));
  }

   public getFilledStatsForStrategicOutcomeStatisitc(id: number | string, month: number | string, year: number | string): Observable<any> {
    return this._get(this.restURL.concat('/getfilledstatsforstrategicoutcomestatisitc/' + id.toString() + '/' + month.toString() +'/' + year.toString()));
  }

  public getEvaluationOutcomeStatistics(startMonth: number | string, startYear: number | string, endMonth: number | string, endYear: number | string, programCategoryId: number | string ): Observable<any> {
    return this._get(this.restURL.concat('/getevaluationoutcomestatistics/', startMonth.toString(), '/', startYear.toString(), '/', endMonth.toString(), '/', endYear.toString(), '/', programCategoryId.toString()));
  }
}
