import { Pipe, PipeTransform } from '@angular/core';
import { MessageService } from '@ripple/services';

@Pipe({
  name: 'log'
})
export class LogPipe implements PipeTransform {
  constructor() { }

  transform(value, logger: { log?, add?} = console) {
    (logger.add ? logger.add : logger.log)(this.constructor.name, value);
    return value;
  }
}
