<div class="p-grid p-grid-lg-h100 p-grid-layout">
  <div class="p-col-12">
    <div class="pane pane--lg-h100">
      <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
          <h1 class="header__heading">{{title}}</h1>
          <div class="header__actions">
            <ng-content select=".actions-container"></ng-content>
          </div>
        </div>
      </div>
      <div class="pane__body">
        <div class="tags-container">
          <ripple-resizable-layout [defaultLeftWidth]="'40%'" [defaultRightWidth]="'60%'" style="height: 100%">

            <div class="left-content">
              <ng-content select=".filter-container"></ng-content>
              <ripple-entity-list
                [entityService]="entityService"
                [filter]="filter"
                [addButton]="showAddButton && add"
                [showDeleteButton]="showDeleteButton"
                [filterOnTop]="false"
                [sortField]="sortField"
                [sortOrder]="sortOrder"
                [showFilter]="showFilter"
                [loadTransform]="loadTransform"
                selectionMode="single"
                (rowSelect)="select($event)"
                (rowUnselect)="select(null)"
              ></ripple-entity-list>
            </div>

            <div class="right-content h-100">
              <ng-container *ngIf="entity; then details; else noEntity"></ng-container>

              <ng-template #details>
                <p-panel styleClass="ui-panel--has-footer">
                  <p-header class="w-100">
                      <span class="ui-panel-title" [innerText]="editTitle"></span>
                      <ripple-button-list [style]="{'float':'right'}" [buttons]="menuButtons"></ripple-button-list>
                  </p-header>

                  <ripple-entity-details
                    [entity]="entity"
                    [generatorOptions]="editGenOptions"
                    [fieldOrders]="editFieldOrders"
                    (afterSave)="afterSave($event)"
                    [hideButtons]="true"
                  ></ripple-entity-details>

                  <ng-content #main></ng-content>

                </p-panel>
              </ng-template>

              <ng-template #noEntity>
                <ripple-empty-state [iconClass]="iconClass" heading="No {{title}} Selected."></ripple-empty-state>
              </ng-template>
            </div>
          </ripple-resizable-layout>
        </div>
      </div>
    </div>
  </div>
</div>
