import { FormlyFieldConfig } from '@ngx-formly/core';
import { first } from 'rxjs/operators';

import { CustomFieldChoice, CustomFieldInModule } from './custom-field-in-module';
import { IRippleFrontendTemplate, ICustomFieldInModuleInputObject, IWarpEntityTypeInputObject, IEntityTypeTree } from '../api/expando-models';
import { IFieldFrontendTemplate, IFilterableEntityTypeDescriptor, IFormBuilderChoice, ISectionFrontendTemplate, isFieldTemplate } from '../interfaces/form-builder';
import { IGenericObject, INameID } from '../interfaces/generic';
import { EntityFilter } from './entity-filter';
import { WarpEntity } from './warp-entity';
import { BehaviorSubject, Observable } from 'rxjs';

const emptyTemplate: IRippleFrontendTemplate = { ID: -1, Version: -1 };

// tslint:disable-next-line: no-any
type FormlyAttributeEvent = (field: FormlyFieldConfig, event?: any) => void;
type HiddenCondition = string | boolean | ((model, formState, field?: FormlyFieldConfig) => boolean);

export enum FilterStrategy {
  /** no filter, returns all customFields */
  All               = 1 << 0,
  /** when customField is flagged as showAsFilter */
  FilterColumns     = 1 << 1,
  /** when custom field is flagged as DisplayInReports */
  ReportColumns     = 1 << 2,
  /** when the customField datatype is 'textdata' */
  TextFields        = 1 << 3,
  /** when the customField type is 'textdata' */
  SingleLineFields  = 1 << 4,
  /** when module setting 'isSearchProperty' is true */
  ModuleSettingFlag = 1 << 5,
  /** marks this filter strategy as a strict AND join, rather than default OR */
  MatchAll          = 1 << 6,
}
type CfimFilterFunction = (cfim: CustomFieldInModule) => boolean;
type FilterStrategyInput = FilterStrategy | CfimFilterFunction | RegExp | string;

export interface IGenFormlyGroupSetting {
  groupName: string;
  groupClasses: string;
  groupFields: string[];
  groupWrapper: string[];
  groupWrapperData: { [key: string]: string };
  groupType?: string;
  groups?: IGenFormlyGroupSetting[];
}
export interface IGenFormlyOptions {
  disabledCondition?: (cfim: CustomFieldInModule) => boolean;
  hiddenCondition?: (cfim: CustomFieldInModule) => HiddenCondition;
  onChange?: (cfim: CustomFieldInModule) => FormlyAttributeEvent;
  hooks?: (cfim: CustomFieldInModule) => FormlyFieldConfig['hooks'];
  exclude?: (cfim: CustomFieldInModule) => boolean;
  templateOptions?: (cfim: CustomFieldInModule) => IGenericObject;
  expressionProperties?: (cfim: CustomFieldInModule) => IGenericObject;
  // TODO: Add better typing
  validators?: (cfim: CustomFieldInModule) => IGenericObject,
  /** Bind the CFIM unchangeable name to a service and entity filter that describes how to fetch its default overrides */
  defaultValueOverrides?: { [key: string]: [any, EntityFilter] };
  customFields?: FormlyFieldConfig[];
  /** lowerUnchangeableName: className */
  styles?: { [lowerUnchangeableName: string]: string };
  groups?: IGenFormlyGroupSetting[];

  showHiddenFields?: boolean;
}

function keyForIGenFormlyOptions(opts: IGenFormlyOptions): string {
  return [
      'disabledCondition',
      'hiddenCondition',
      'onChange',
      'hooks',
      'exclude',
      'templateOptions',
      'expressionProperties',
      'validators',
      'defaultValueOverrides',
      'customFields',
      'styles',
      'groups',
      'showHiddenFields',
    ]
    .map(k => `${k.substring(0,3)}:${opts[k]}`)
    .join('|');
}

export type PublishedCfc = CustomFieldChoice & Partial<IFormBuilderChoice>;

export class WarpEntityType {
  readonly id: number;
  readonly unchangeableName: string;
  readonly customFieldsInModules: CustomFieldInModule[];
  readonly trees: IEntityTypeTree[];
  readonly ancestorEntityTypes: Set<INameID>;
  readonly ancestorEntityTypeIds: number[];

  private _formlyFieldsCached?: Map<string, FormlyFieldConfig[]>;
  private _frontendTemplate?: IRippleFrontendTemplate;
  private _frontendLatestDraft?: IRippleFrontendTemplate;

  iconClass: string;
  name: string;

  get properties(): CustomFieldInModule[] { return this.customFieldsInModules; }
  get frontendTemplate() { return this._frontendTemplate || emptyTemplate; }
  get frontendLatestDraft() { return this._frontendLatestDraft || emptyTemplate; }

  private loggedInUser = new BehaviorSubject<WarpEntity>(undefined);

  constructor(private rippleObj: IWarpEntityTypeInputObject, currentUser: Observable<WarpEntity>) {
    this.id = rippleObj.ID;
    this.unchangeableName = rippleObj.UnchangeableName;
    this.name = rippleObj.Name;
    this.iconClass = rippleObj.IconClass;
    this.trees = rippleObj.Trees || [];
    this.ancestorEntityTypes = new Set(
      this.trees.reduce(
        (m, t) => m.concat(...t.nodes.slice(0, t.nodes.length - 1)), // remove last one because that is this type. eg. x>y, x is ancestor
        [] as INameID[]));
    this.ancestorEntityTypeIds = [...this.ancestorEntityTypes].map( t => t.id );

    this.customFieldsInModules = (rippleObj.CustomFieldsInModules as ICustomFieldInModuleInputObject[] || [])
      .map(obj => new CustomFieldInModule(obj) );

    this._formlyFieldsCached = new Map();

    this._frontendTemplate = rippleObj.FrontendTemplate || undefined;
    this._frontendLatestDraft = rippleObj.FrontendLatestDraft || undefined;

    currentUser.subscribe(this.loggedInUser);
  }

  getEssentialCFIMInfo() {
    return this.customFieldsInModules
      .map( cfim => ({
          key: cfim.unchangeableName.toLowerCase(),
          type: cfim.cf_dataField,
        }));
  }

  private getFilterMethodByStrategy(filterStrategy: FilterStrategy): CfimFilterFunction {
    if (filterStrategy & FilterStrategy.All)
      return;

    const strategyFunctions: {strategy: FilterStrategy, method: CfimFilterFunction}[] = [
      { strategy: FilterStrategy.FilterColumns,     method: cfim => cfim.showAsFilter },
      { strategy: FilterStrategy.ReportColumns,     method: cfim => cfim.displayInReports },
      { strategy: FilterStrategy.TextFields,        method: cfim => cfim.cf_dataField === 'textdata' },
      { strategy: FilterStrategy.SingleLineFields,  method: cfim => cfim.cf_type === 'Singleline' },
      { strategy: FilterStrategy.ModuleSettingFlag, method: cfim => this.isTrue(cfim.moduleSettings['issearchproperty']) },
    ];

    const functionParts = strategyFunctions
      .filter(({ strategy }) => filterStrategy & strategy)
      .map(({ method }) => method);

    if (functionParts.length == 1)
      return functionParts[0];

    const matchMethod = (filterStrategy & FilterStrategy.MatchAll) ? 'every': 'some';
    if ( functionParts.length > 1 )
      return cfim => functionParts[matchMethod]( funcPart => funcPart(cfim) );
  }

  getFilterForStrategy(filterStrategy: FilterStrategyInput): CfimFilterFunction {
    switch (typeof filterStrategy) {
      case 'function':
        return filterStrategy;
      case 'number': // FilterStrategy
        return this.getFilterMethodByStrategy(filterStrategy)
      case 'string':
      case 'object': // Regexp
      default:
        return (cfim: CustomFieldInModule) => !!cfim.unchangeableName.match(filterStrategy);
    }
  }

  /**
   * This will use the first strategy provided which returns any cfims, and return the list of filtered cfims according to that strategy
   * @param filterStrategies the way to filter the cfims, by `RegExp|string` on unchangeableName, custom filter function, or by hardcoded `FilterStrategy` (union available)
   * @note `filterStrategies` will find all matching
   * @returns the cfims matching the query
   * @example ```typescript
   * const cols = entityType.getColumnFilters(FilterStrategy.ModuleSettingFlag, FilterStrategy.FilterColumns | FilterStrategy.TextFields | FilterMethod.MatchAll, FilterStrategy.TextFields)
   * ```
   */
  getColumnFilters(...filterStrategies: FilterStrategyInput[]) {
    // first generate a filterFunc based on each strategy provided
    const filterMethods = filterStrategies.map(strategy => this.getFilterForStrategy(strategy));

    // if all funcs do no filtering, or if there were none provided
    if (filterMethods.every( func => func === undefined ))
      return this.customFieldsInModules;

    // only iterate cfims once, keep track of each filter result
    let cfimsForEachFilter: CustomFieldInModule[][] = [];
    filterMethods.forEach(filterMethod => {
      let result = [];
      cfimsForEachFilter.push(result);
      this.customFieldsInModules.forEach( cfim => {
        if (filterMethod(cfim))
          result.push(cfim);
      });
    });

    // return the first filter result with any values
    return cfimsForEachFilter.find( results => results.length > 0 ) || [];
  }

  getColorCfim() {
    return this.customFieldsInModules.find( cfim => cfim.moduleSettings.displaytype === 'color' );
  }

  *iterateTemplateFields(baseSections: (IFieldFrontendTemplate | ISectionFrontendTemplate)[] = this.frontendTemplate?.FrontendTemplate?.data): IterableIterator<IFieldFrontendTemplate> {
    if (!baseSections)
      return;

    for (const sectionOrField of baseSections)
      if (isFieldTemplate(sectionOrField))
        yield sectionOrField;
      else
        yield * this.iterateTemplateFields(sectionOrField.subData);
  }

  // /** return a dictionary of cfim id (number) to label, from the frontend template if available */
  // getAccurateCfimLabels(ids: true): Map<number, string>;
  // getAccurateCfimLabels(ids?: false): Map<string, string>;

  // getAccurateCfimLabels(ids: boolean = false) {
  //   const cfimIds = new Map<string, number>();
  //   const idLabels = new Map<number | string, string>();
  //   this.customFieldsInModules.forEach(cfim => cfimIds.set(cfim.lowerUnchangeableName, cfim.id));

  //   for (const field of this.iterateTemplateFields()) {
  //     const lowerName = field.key.toLowerCase();
  //     if (cfimIds.has(lowerName)) {
  //       idLabels.set(ids ? cfimIds.get(lowerName) : lowerName, field.label);
  //       if (idLabels.size === cfimIds.size)
  //         break;
  //     }
  //   }

  //   return idLabels;
  // }

  getTemplateFieldForUnchangeableName(unchangeableName: string) {
    unchangeableName = unchangeableName.toLowerCase();

    for (const field of this.iterateTemplateFields()) {
      if (field.key.toLowerCase() === unchangeableName)
        return field;
    }
  }

  getCustomFieldChoices(unchangeableName: string): PublishedCfc[] {
    unchangeableName = unchangeableName.toLowerCase();
    const cfim = this.customFieldsInModules.find( _cfim => _cfim.unchangeableName.toLowerCase() === unchangeableName );
    const templateField = this.getTemplateFieldForUnchangeableName(unchangeableName);
    if (!cfim && !templateField)
      return [];

    if (!templateField)
      return cfim.cf_choices;

    return templateField.options as Required<IFormBuilderChoice>[] /* safe to assume these have Ids, because they are in the published template */;
  }

  async generateFormlyFields(options: IGenFormlyOptions, entityID = -1): Promise<FormlyFieldConfig[]> {
    const _default: FormlyFieldConfig[] = [
      {
        fieldGroupClassName: 'p-grid ui-fluid',
        fieldGroup: []
      }
    ];

    // all options should be optional but they are callable
    ['disabledCondition', 'hiddenCondition', 'onChange', 'exclude'].forEach(prop => {
      options[prop] = options[prop] || (x => undefined);
    });
    ['templateOptions', 'expressionProperties', 'hooks', 'validators'].forEach(prop => {
      options[prop] = options[prop] || (x => ({ }));
    });
    options.styles = options.styles || { };

    // Rory/Keenan 2024/08/29: Disabling the cache for now, due to data pollution with old component
    // references in the options not taking into account captured variables,
    // it would then keep references to old components, and the only
    // way we would be able to fix that is by forcing `this` to be passed into the options

    // detect deep changes in options:
    // const optionKey = keyForIGenFormlyOptions(options);
    // if (this._formlyFieldsCached.has(optionKey))
    //   return this._formlyFieldsCached.get(optionKey);

    const mapFn = this.generateFieldConfig(options as Required<IGenFormlyOptions>, entityID);
    const fieldsPromises = this.customFieldsInModules.map(mapFn)
    const fields = (await Promise.all(fieldsPromises))
      .concat( options.customFields || [] )
      .filter(fc => fc);

    if (fields.length > 0) {
      //this._formlyFieldsCached.set(optionKey, fields);
      return fields;
    }
    return _default;
  }

  private generateFieldConfig(
    genOptions: Required<Omit<IGenFormlyOptions, 'customFields'>>,
    entityID = -1
  ): (cfim: CustomFieldInModule) => Promise<FormlyFieldConfig> {
    return async (cfim: CustomFieldInModule): Promise<FormlyFieldConfig> => {
      cfim.lowerUnchangeableName = cfim.unchangeableName.toLowerCase();
      cfim.keyName = cfim.lowerUnchangeableName;

      if (genOptions.exclude(cfim))
        return null;

      let type: string;
      let listEntityType: IFilterableEntityTypeDescriptor;
      let toType = '';
      let isMultiSelect = false;
      let enableRichTextEditor = false;
      let parentField: string;
      let parentField_exclude = false;
      let options = [];
      let selectionMode = '';
      let validationMessages = [];

      const templateOptions = genOptions.templateOptions(cfim) || { };
      const validators = genOptions.validators(cfim) || { };
      const moduleSettings = cfim.moduleSettings;
      const moduleSetting = (field) => cfim.moduleSettings[field?.toLowerCase()];
      const expressionProperties = { };

      // dont show hidden fields, or ones marked as HideInFrontend
      if (cfim.cf_type?.toUpperCase()?.startsWith('HIDDEN') || this.isTrue(moduleSetting('HideInFrontend'))) {
        // override hidden behaviour with templateOptions, generatorOptions, or moduleSettings
        const shouldShow = templateOptions.forceShow || genOptions.showHiddenFields || this.isTrue(moduleSetting('ShowInFrontend'));

        // favour the show conditions over the hide conditions.
        if (!shouldShow)
          return null;
      }

      let maxLength = parseInt(moduleSetting('MaxLength') || "1000");

      isMultiSelect = this.isTrue(moduleSetting('AllowMultipleWithSelect2'), moduleSetting('AllowMultiple'));

      enableRichTextEditor = this.isTrue(moduleSettings['enablerichtexteditor']);

      let minumumValue = moduleSetting('MinimumValue');
      if(minumumValue){
        templateOptions.min = Number(minumumValue);
        validationMessages.push({min: 'Minimum value is ' + minumumValue});
      }

      let maximumValue = moduleSetting('MaximumValue');
      if(maximumValue){
        templateOptions.max = Number(maximumValue);
        validationMessages.push({max: 'Maximum value is ' + maximumValue});
      }

      let defaultValue: any = moduleSetting('DefaultValue') || null;
      if (genOptions.defaultValueOverrides && genOptions.defaultValueOverrides[cfim?.unchangeableName.toLowerCase()]) {
        const [service, filter] = genOptions.defaultValueOverrides[cfim?.unchangeableName.toLowerCase()];
         // TODO: I would like to one-day remove the need to pass in the service altogether
         // | However, this would require the generic service to be imported into models, which is a circular dependency
        const serviceLocationMap: Map<number, WarpEntity> = await service.getPage(filter).pipe(first()).toPromise();
        defaultValue = Array.from(serviceLocationMap.values())?.map(sl => ({id: sl.id, name: sl.name})) ||
          moduleSetting('DefaultValue') ||
          null;
      }

      const name = cfim ? cfim.cf_dataField : undefined;
      switch (name) {
        case 'linkedentityid':
          type = 'select-entity';
          let entityFilter = (
            templateOptions.overrideEntityListDescriptor &&
            templateOptions.overrideEntityListDescriptor instanceof EntityFilter
          ) ? templateOptions.overrideEntityListDescriptor
            : EntityFilter.FromModuleSettings(moduleSettings, this.customFieldsInModules);

          if (cfim.listEntityTypeID || templateOptions.listEntityType?.id) {
            type = 'virtual-select-entity';
            listEntityType = Object.assign({}, {
              id: cfim.listEntityTypeID,
              entityFilter: entityFilter,
            }, templateOptions.listEntityType);
          }
          else
            listEntityType =  { id: -1 };

          if (this.isTrue(moduleSetting('UseLegacySelect'), templateOptions['useLegacySelect']))
            type = 'select-entity';
          break;
        case 'customfieldchoiceid':
          type = 'select-search';
          const exclude_cfcids = (moduleSetting('Exclude_cfcIds') || '').split(',');
          options = cfim.cf_choices.filter(cfc => !exclude_cfcids.includes(cfc.id.toString()));
          parentField = (moduleSetting('ParentField') || moduleSetting('ParentSelect') || '').toLowerCase();
          if (parentField.startsWith('!')) parentField = parentField.substring(1), parentField_exclude = true;
          // handle cfc parents
          if (parentField !== '')
            expressionProperties['templateOptions.options'] = (model, formState, field?: FormlyFieldConfig) => {
              const currentParentVal = model[parentField] && (model[parentField][0].id || model[parentField].id);
              const optKey = `options_${field.key}`;
              const lastKey = `last_${parentField}`;
              if (!formState[optKey])
                formState[optKey] = cfim.cf_choices.slice();

              if (formState[lastKey] !== currentParentVal) {
                formState[lastKey] = currentParentVal;
                formState[optKey] = cfim.cf_choices.filter( opt =>
                  !currentParentVal || !opt.parentId || opt.parentId === -1
                  || (parentField_exclude ? opt.parentId !== currentParentVal : opt.parentId === currentParentVal)
                );
              }
              return field.templateOptions.options = formState[`options_${field.key}`];
            };

          if (defaultValue) {
            defaultValue = options.find(o =>
              [o.ID, o.id, o.Text, o.optionName]
                .filter( v => !!v)
                .map( v => v.toString())
                .includes(defaultValue));
            defaultValue = [defaultValue];
          }
          break;
        case 'longdata':
        case 'floatdata':
        case 'doubledata':
          type = 'generic-input';
          toType = 'number';
          break;
        case 'bigtext':
          type = 'generic-textarea';
          maxLength = 50000;
          break;
        case 'textdata':
        case 'generic':
          type = 'generic-input';
          break;
        case 'timedata':
          type = 'generic-input';
          toType = 'time';
          break;
        case 'datedata':
        case 'datetimedata':
          type = 'generic-input';
          toType = 'date';
          break;
        case 'eventdatetimerange': // TODO
          type = 'datetime-picker';
          selectionMode = 'range';
          break;
          // console.warn('TODO: in WarpEntityType.generateFieldConfig()', 'event datetime range', 'ignoring field', cfim);
        default:
          return null;
      }

      // file
      if (cfim.listEntityTypeID === 614 ) {
        type = 'generic-file'; // list entity type = document upload
      }

      // if override formly type exists
      if (moduleSetting('OverrideFormlyType'))
        type = moduleSetting('OverrideFormlyType');

      const onChange = genOptions.onChange(cfim) || undefined;

      const expProps = genOptions.expressionProperties(cfim);
      for (const key in expProps)
        if (Object.prototype.hasOwnProperty.call(expProps, key)) expressionProperties[key] =  expProps[key];

      // set the default value

      const hooks: FormlyFieldConfig['hooks'] = genOptions.hooks(cfim) || { };

      if (defaultValue) {
        const oldOnInit = hooks['onInit'];
        hooks['onInit'] = (field: FormlyFieldConfig) => {
          if (oldOnInit)
            oldOnInit(field);
          const control = field.formControl;
          if (!control.value || control.value === '')
            control.setValue(defaultValue);
        };
      }

      // convert validation messages to formly format (objects with key: message)
      let validation = null;
      if(validationMessages.length > 0){
        validation = {messages: {}};
        validationMessages.forEach( vm => {
          validation.messages = {...validation.messages, ...vm};
        });
      }

      return {
        key: cfim.keyName,
        type,
        className: genOptions.styles[cfim.keyName] || 'p-col-12 p-md-6 ui-fluid',
        defaultValue,
        hooks,
        templateOptions: {
          required: cfim.isRequired,
          label: moduleSetting('ModuleLabel') || moduleSetting('CustomLabel') || cfim.label,
          options,
          entityID,
          type: toType,
          maxLength,
          listEntityType,
          moduleSettings,
          isMultiSelect,
          enableRichTextEditor,
          selectionMode,
          placeholder: type === 'select' ? 'Select One' : undefined,
          valueProp: o => (o.ID || o.id), // TODO: try lowercase var name
          labelProp: o => (o.Text || o.optionName),
          onChange, // for native formly inputs
          change: onChange, // for primeNG formly inputs
          disabled: genOptions.disabledCondition(cfim) || this.isTrue(moduleSetting('ReadOnly')) || false,
          filePermission: moduleSetting('Permission'),
          helpText: moduleSetting('HelpText') || undefined,
          ...templateOptions
        },
        expressionProperties,
        hideExpression: genOptions.hiddenCondition(cfim) || false,
        validation,
        validators,
      };
    };
  }

  isTrue(...moduleSettingValues: (string | boolean)[]) {
    return moduleSettingValues.some( val => {
      if (!val)
        return false;

      if (typeof val === 'string') {
        // dynamic values for user state:
        if (val.startsWith('user.') && this.getUserProperty(val.substring(5).split('.')))
          return true;

        val = val.trim().toLowerCase();
      }

      if ([1, true, '1', 'true', 'yes'].includes(val))
        return true;
    });
  }

  getUserProperty(propPath: string[]) {
    return this.getProp(this.loggedInUser.value, propPath);
  }

  getProp(user: Object, propPath: string[]) {
    let prop = user;
    for (let key of propPath) {
      let args = [];

      if (key.endsWith(')')) {
        const match = key.match(/\(([^\)]*)\)$/);
        if (match) {
          args = (match[1] || "").split(',').map(a => a.trim().replace(/['"]/g, ''));
          key = key.substring(0, match.index);
        }
      }

      if (prop && prop.hasOwnProperty(key)) {
        if (prop[key] instanceof Function)
          prop = prop[key](...args);
        else
          prop = prop[key];
      } else
        return undefined;
    }
    return prop;
  }

  public clone(): WarpEntityType {
    return new WarpEntityType({
      ...JSON.parse(JSON.stringify(this.rippleObj)),
      Name: this.name, IconClass: this.iconClass
    }, this.loggedInUser);
  }
}
