import { Injectable } from '@angular/core';
import { AuthService, InternalCookieService, MessageService, RippleApiService } from '@ripple/services';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FormService extends RippleApiService {
  private restURL = '/api/v2/handoff';

  constructor(
    http: HttpClient,
    messageService: MessageService,
    authService: AuthService,
    private cookieService: InternalCookieService
  ) {
    super(http, messageService, authService);
  }

  public getOneTimeToken(timeout: number, filledFormId: number, formEntityId: number, otherEntityIds: number[], entitiesWithPermissionOnly: boolean = true): Observable<string> {
    const url = '';
    const deliveryType = 'SHARE';

    const body = {
      url,
      deliveryType,
      otherEntityIds,
      entitiesWithPermissionOnly
    };

    return this._post(this.restURL.concat('/onetime/', '' + timeout, '/' + filledFormId, '/' + formEntityId), body);
  }

  public removeToken(token: string): Observable<any> {
    return this._get(this.restURL.concat('/removetoken/', token));
  }
}
