import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { EntityFilter } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { ContactRole, EntityTypes } from '../_core/models';

@Component({
  selector: 'sagesse-contact-roles',
  templateUrl: './contact-roles.component.html',
  styleUrls: ['./contact-roles.component.scss']
})
export class ContactRolesComponent extends BasePage implements OnInit {

  contactRoleService: WarpEntityServiceCache<ContactRole>;

  primaryFilter = EntityFilter.Advanced({isPrimary: 'Yes'});
  nonPrimaryFilter = EntityFilter.Exclude({isPrimary: 'Yes'});

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService
  ) {
    super(logService);
    this.contactRoleService = factory.get(EntityTypes.ContactRole) as WarpEntityServiceCache<ContactRole>;
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (co: ContactRole) => this.router.navigate([`${co.entityId}`], { relativeTo: this.route });
  add = () => this.router.navigate(['add'], { relativeTo: this.route });

}
