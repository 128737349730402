import { Injectable, Injector } from '@angular/core';
import { WarpEntityServiceCache } from '@ripple/services';
import { Contact, EntityTypes, TeamContact } from '../../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamContactService extends WarpEntityServiceCache<TeamContact> {

  constructor(injector: Injector) {
    super(injector, TeamContact, EntityTypes.TeamContact);
  }

  getUnselectedTeamContacts(teamId) {
    return this.warpService
      ._get(`/api/sagesse/unselectedTeamContacts/${teamId}`, 'Get Unselected Team Contacts')
      .pipe(map((m) => m.map((o) => new Contact(o))));
  }

  getSelectedTeamMemberIds(teamId) {
    return this.warpService
      ._get(`/api/sagesse/getSelectedTeamMemberIds/${teamId}`, 'Get selected Team Member Ids');
  }

  getContactTeams(contactId) {
    return this.warpService
      ._get(`/api/sagesse/contactTeams/${contactId}`, 'get Contact Teams')
      .pipe(map((m) => m.map((o) => new TeamContact(o))));
  }

  checkTeamMemberExist(teamId, userId, contactId, orgContactId) {
    return this.warpService
      ._get(`/api/sagesse/checkTeamMemberExist/${teamId}/${userId}/${contactId}/${orgContactId}`, 'check Team Member Exist');
  }

  deleteTeamContact(teamId: number, teamContactId: number , userId: number, contactId: number) {
    const body = {
      teamId,
      teamContactId : teamContactId ?? -1,
      userId : userId ?? -1,
      contactId : contactId ?? -1
    };
    return this.warpService
      ._post(`/api/sagesse/deleteTeamContact`, body);
  }
}
