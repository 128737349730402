import { Injectable } from '@angular/core';
import { environment } from '@ripple/environment';
import { InternalCookieService } from './cookie.service';

enum MessageServiceLogLevel {
  VERBOSE = 'VERBOSE',
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}
export class MessageServiceLogLevelObject {
  constructor(public level: MessageServiceLogLevel) { }
}

interface MessageObject {
  t: Date;
  type: string;
  // tslint:disable-next-line: no-any
  formatted: any[];
}

@Injectable({
  providedIn: 'root',
})
export class MessageService {

  public static readonly VERBOSE = new MessageServiceLogLevelObject(MessageServiceLogLevel.VERBOSE);
  public static readonly INFO = new MessageServiceLogLevelObject(MessageServiceLogLevel.INFO);
  public static readonly ERROR = new MessageServiceLogLevelObject(MessageServiceLogLevel.ERROR);
  public static readonly WARNING = new MessageServiceLogLevelObject(MessageServiceLogLevel.WARNING);

  messages: MessageObject[] = [];
  lastDump = 0;
  showAll = false;
  _logToConsole = !environment.production;
  _allowVerbose = false;
  _showTrace = true;
  browserTheme: 'dark' | 'light' = 'dark';

  clickGrouping = true;
  currentClickGroup = 0;
  colorGroups = {
    // https://colordesigner.io/
    dark: [
      '#00000000', // start transparent
      '#0c992f', '#04067a', '#a3370d', '#a50d35', '#036b6d', '#d1720c', '#007c42', '#42008e', '#f81345', '#ac03c6'
    ],
    light: [
      '#00000000', // start transparent
      '#84f9a7', '#ef7081', '#9cf497', '#c2b5f4', '#a0f7e1', '#edbd71', '#f9f895', '#f7b7e4', '#fcb976', '#ef83c9',
    ]
  };
  emojis: Record<MessageServiceLogLevel, string[]> = {
    [MessageServiceLogLevel.INFO]:    [ '△' ], // [ '  ', '🥎', '⚪', '🔴', '🟠', '🟡', '🟢', '🔵', '🟣', '🟤', '⚫' ],
    [MessageServiceLogLevel.VERBOSE]: [ '◬' ], // [ '  ', '💗', '🤍', '❤️', '🧡', '💛', '💚', '💙', '💜', '🤎', '🖤' ],
    [MessageServiceLogLevel.WARNING]: [ '◉' ], // [ '  ', '🕒', '🕓', '🕔', '🕕', '🕖', '🕗', '🕘', '🕙', '🕚', '🕜' ],
    [MessageServiceLogLevel.ERROR]:   [ '⨉' ], // [ '  ', '❌', '⬜', '🟥', '🟧', '🟨', '🟩', '🟦', '🟪', '🟫', '⬛' ],
  }

  docOnClick: (this: Document, ev: MouseEvent) => void;

  constructor(cookieService: InternalCookieService) {
    // tslint:disable-next-line: no-any
    (document as any).messageService = this;

    this.docOnClick = (ev: MouseEvent) => {
      if (!ev.shiftKey) {
        this.currentClickGroup = (this.currentClickGroup + 1) % 11;
        this.log('Message Service', `Grouping Logs by Client Interaction ${this.currentClickGroup}`);
      }
    };

    // do logging based on a cookie, so we can have it on at startup if we need to
    const loggingCookie = cookieService.getCookie('RippleLogging');
    if (loggingCookie === 'true')
      this.enableLogs(true);
    else if (loggingCookie === 'verbose')
      this.enableLogs(MessageService.VERBOSE);
    else if (loggingCookie === 'false') {
      this.enableLogs(false);
      this._logToConsole = false;
      this._allowVerbose = false;
    }

    // do logging based on a cookie, so we can have it on at startup if we need to
    const traceCookie = cookieService.getCookie('RippleLoggingTrace');
    this._showTrace = (traceCookie || '').toLowerCase() !== 'false';

    // devTools color logging
    setTimeout(() => {
      if (this._logToConsole && environment.groupLogsAfterInteraction)
        this.startClickGrouping();
    }, 1000);

    window.matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', event => {
        this.browserTheme = event.matches ? 'dark' : 'light';
      });
  }

  public startClickGrouping() {
    this.clickGrouping = true;
    document.addEventListener("click", this.docOnClick, true);
    this.log('Message Service', 'Grouping Logs by Client Interaction');
  }

  public stopClickGrouping() {
    this.clickGrouping = false;
    document.removeEventListener("click", this.docOnClick, true);
    this.log('Message Service', 'Stopped Grouping Logs by Client Interaction');
  }

  getLevelColor(level: MessageServiceLogLevel) {
    return `color: ${this.colorGroups[this.browserTheme][this.currentClickGroup]}`;
  }
  getColor() {
    return `color: ${this.colorGroups[this.browserTheme][this.currentClickGroup]}`;
  }

  getEmoji(level: MessageServiceLogLevelObject) {
    const section = this.emojis[level.level];
    return section[0]
  }

  private getLevel(messages) {
    return (messages.find( m => m instanceof MessageServiceLogLevelObject) as MessageServiceLogLevelObject || MessageService.INFO);
  }

  enableLogs(enable: boolean | MessageServiceLogLevelObject) {
    if (enable === true || enable === false)
      this.showAll = enable;
    else {
      this._logToConsole = true;
      this._allowVerbose = (enable.level === MessageServiceLogLevel.VERBOSE);
    }
  }

  public showLogs(namespace: string) {
    environment.logFocus.push(namespace);
  }

  public hideLogs(namespace: string) {
    environment.logFocus = environment.logFocus.filter( ns => ns.toLowerCase() !== namespace.toLowerCase());
  }

  private canLog(namespace: string, opts?: string[]) {
    opts = opts || environment.logFocus || [];
    namespace = namespace.toLowerCase();
    return opts.length === 0 || opts.some( allowed => namespace.includes(allowed.toLowerCase()));
  }

  private formattedLog(level: MessageServiceLogLevelObject, ...messages) {
    const first = messages.shift();
    if (this.clickGrouping)
      console.groupCollapsed(`%c■ %c${this.getEmoji(level)} %c${first}: ${messages[0]}`, this.getColor(), this.getLevelColor(level.level), '');
    else
      console.groupCollapsed(`${first}: ${messages[0]}`);
    // tslint:disable-next-line: no-console
    messages.forEach(m => console.log(m));
    // tslint:disable-next-line: no-console
    console.log(new Date());

    if (this._showTrace) // tslint:disable-next-line: no-console
      console.trace();
    console.groupEnd();
  }

  /**
   * Adds a message to the console (or just cache for production)
   * @param namespace the header for this message group
   * @param description the summary, or most valuable information
   * @param data the supporting data
   */
  add(namespace: string, description, ...data);
  /**
   * Adds a message to the console (or just cache for production)
   * @param namespace the header for this message group
   * @param data the supporting data ( the first element will be used as the description)
   */
  add(namespace: string, ...messages);
  /**
   * Adds a message to the console (or just cache for production)
   * @param data the supporting data ( the first element will be used as the header)
   */
  add(...messages) {
    this.log(...messages);
  }

  /**
   * Adds a message to the console (or just cache for production)
   * @param data the supporting data ( the first element will be used as the header)
   * @see MessageService.add
   */
  log(...messages) {
    const level = this.getLevel(messages);

    if (level.level === MessageServiceLogLevel.VERBOSE) {
      if (this._allowVerbose)
        this.formattedLog(level, ...messages);
    } else if (
      this.showAll
      || level.level === MessageServiceLogLevel.ERROR
      || (this._logToConsole && this.canLog(messages[0]))
    )
      this.formattedLog(level, ...messages);

    const formatted = (messages);
    this.messages.push({ t: new Date(), type: level.level, formatted });
  }

  warn(...messages) {
    if (this._logToConsole) console.warn(...messages);

    const formatted = (messages);
    this.messages.push({ t: new Date(), type: MessageService.WARNING.level, formatted });
  }

  dump(printToConsole?: boolean);
  dump(sinceIndex?: number, printToConsole?: boolean);
  dump(namespace: string | string[], printToConsole?: boolean);
  dump(a1: boolean | number | string | string[] = 0, printToConsole = false) {
    // tslint:disable-next-line: no-any
    let messages: MessageObject[];
    if (typeof a1 === 'string')
      messages = this.messages.filter( m => this.canLog(m.formatted[0], [a1] ));
    else if (a1 instanceof Array)
      messages = this.messages.filter( m => this.canLog(m.formatted[0], a1 ));
    else if (typeof a1 === 'number' )
      messages = this.messages.slice(a1);
    else {
      messages = this.messages.slice(this.lastDump);
      this.lastDump = this.messages.length;
      printToConsole = a1;
    }

    if (printToConsole)
      messages.forEach( m => this.formattedLog(this.getLevel(messages), ...m.formatted));

    return messages;
  }

  clear() {
    this.messages = [];
  }

  //#region Static Helper Functions
  // tslint:disable-next-line: no-any
  public static prettyArray(array: any[], length = 5): string {
    if (array)
      return `[${array.slice(0, length)}${array.length > length ? '...' : ''}]`;
    else
      return `${array}`;
  }
  //#endregion
}
