import { Component, OnInit } from '@angular/core';
import { SectionWrapper } from '../section-wrapper';
import type { FieldTypeName } from '../../../service-resources/question-type-names';

@Component({
  selector: 'ripple-form-wrapper-table',
  template: `
    <ng-template #options>
      <div class="fb-panel-controls">
        <button pButton
          *ngIf="to.isSection"
          type="button"
          icon="pi pi-{{to.isHidden ? 'eye-slash' : 'eye'}}"
          (click)="onHideClick($event)"
        ></button>

        <p-splitButton
          [model]="to.items"
          icon="pi pi-cog"
          (onClick)="onSaveClick($event)"
          appendTo="body"
        ></p-splitButton>
      </div>
    </ng-template>

    <ng-template #error>
      There was an error loading this section. Please ensure it is configured correctly.
    </ng-template>

    <ng-template #normal>
      <p-panel style="{{to.style}}" styleClass="form-wrapper-panel">
        <p-header>
          <div class="ui-panel-title fb-panel-header">
            <span *ngIf="!to.hideTitle" class="ui-panel-title" style="{{to.headerStyle}}">{{to.title}}</span>
            <ng-container *ngIf="to.hideOptions else options"></ng-container>
          </div>
        </p-header>
        <table>
          <thead>
            <td>Questions</td>
            <td>Column 2</td>
          </thead>

          <ng-container #fieldComponent></ng-container>
        </table>
      </p-panel>
    </ng-template>

    <ng-container *ngIf="isValid; then normal else error"></ng-container>
  `,
  styleUrls: ['./form-wrapper-table.component.scss']
})
export class FormWrapperTableComponent extends SectionWrapper implements OnInit {
  protected static β_listType: 'white' = 'white';
  protected static β_fieldList: FieldTypeName[] = ['radio-multiple-col'];
  static isTemplateSection = true;

  get isValid() { return true; }

  onSaveClick($event) {
    if (this.to.onSaveClick) this.to.onSaveClick($event);
  }
  onHideClick($event) {
    if (this.to.onHideClick) this.to.isHidden = this.to.onHideClick($event);
  }

  ngOnInit(): void {
    // need to grab the options for
  }
}
