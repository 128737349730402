import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { InternalCookieService, CookieType } from '../general/cookie.service';

@Injectable()
export class RippleInterceptor implements HttpInterceptor {

  constructor(private cookieService: InternalCookieService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authReq = request.clone({
      headers: new HttpHeaders({
        // allow content-type to be overrided
        'Content-Type':  request.headers.get('Content-Type') ? request.headers.get('Content-Type') : 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type,Content-Range',
        'Content-Range': request.headers.get('Content-Range') ? request.headers.get('Content-Range') : '',
        Accept: request.url.includes('api/pdf') ? 'application/pdf' : 'application/json',
        token: this.cookieService.getCookie(CookieType.UserToken) || ''
      })
    });
    return next.handle(authReq);
  }
}
