import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { EntityTypes, TeamType } from '../_core/models';

@Component({
  selector: 'sagesse-team-type',
  templateUrl: './team-type.component.html',
  styleUrls: ['./team-type.component.scss']
})
export class TeamTypeComponent extends BasePage implements OnInit {

  teamTypeService: WarpEntityServiceCache<TeamType>;

  constructor(
    private logService: WarpEntityLogService,
    private router: Router,
    private route: ActivatedRoute,
    factory: WarpEntityCacheFactoryService
  ) {
    super(logService);
    this.teamTypeService = factory.get(EntityTypes.TeamType) as WarpEntityServiceCache<TeamType>;
  }

  ngOnInit(): void {
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit = (tt: TeamType) => this.router.navigate([`${tt.entityId}`], { relativeTo: this.route });
  add = () => this.router.navigate(['add'], { relativeTo: this.route });

}
