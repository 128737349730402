import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'first'
})
export class FirstPipe implements PipeTransform {
  // tslint:disable-next-line: no-any
  transform(value: any | any[] | Map<any, any> | Set<any>): any {
    if (value instanceof Array)
      return value[0];
    if (value instanceof Map || value instanceof Set)
      return value.values().next().value;
    return value;
  }
}
