<div class="pane">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <!-- <h1 class="header__heading">Manage Program Stats</h1> -->
      <h1 class="header__heading">Program Statistics Viewer</h1>
      <div class="header__actions"></div>
      <!-- <button class="month-select" pButton label="Last Month" (click)="monthChanged(-1)"> </button>
      <button class="month-select" pButton label="Next Month" (click)="monthChanged(+1)"> </button> -->
    </div>
    <div class="pane__body">
      <div class="card-container" style="margin-top: 10px;" >
        <p-card>
          <div *ngFor="let item of programStats">
            <h1>{{item.program.properties.name}}</h1>
            <button style="float:right" pButton type="button" icon="pi pi-{{saving ? 'spin pi-spinner' : 'check'}}" label="Save"
              (click)="save()"></button>
            <div *ngFor="let group of item.group | keyvalue" class="mb-4">
              <sagesse-enter-stats-table *ngIf="!loading" [loading]="loading" (updateStats)="statsUpdated($event)" [name]="group.key" [stats]="group.value"
                [showGroup]="false" (monthChanged)="monthChanged($event, group)" [month]="month" [year]="year">
              </sagesse-enter-stats-table>
            </div>
            <p-progressSpinner *ngIf="loading"></p-progressSpinner>
            <hr class="my-4" />
          </div>

          <div *ngIf="programStats?.length == 0">
            <p>No managed programs</p>
          </div>

        </p-card>
      </div>

    </div>
  </div>
</div>
