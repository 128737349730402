<ripple-loading-box [loading]="loading || sending">
    <div class="p-grid ui-fluid">
        <div class="p-sm-12 p-md-12 ui-fluid mb-4">
            <!-- <div class="ui-widget">
                <label>
                    Date Range
                </label>
            </div>
            <div class="heading-action-row">
                <p-calendar [(ngModel)]="dateRange" selectionMode="range" [readonlyInput]="true" inputId="range"
                    class="heading-action-row__heading"
                ></p-calendar>
                <button pButton icon="pi pi-refresh" class="heading-action-row__actions" (click)="generateReport()"></button>
            </div>
            <p-message severity="error" text="Date Range Invalid" *ngIf="tryValidate && !dateRangeValid"></p-message>
             -->

            <label>Date Range</label>
            <div class="p-formgroup-inline">
                <div class="p-field">
                    <p-calendar [(ngModel)]="dateRange" selectionMode="range" [readonlyInput]="true" inputId="range"></p-calendar>
                </div>
                <div class="p-field">
                    <button pButton icon="pi pi-refresh" type="button" class="ui-button-secondary" (click)="generateReport()" label="Generate Report"></button>
                </div>
            </div>
            <p-message severity="error" text="Date Range Invalid" *ngIf="tryValidate && !dateRangeValid"></p-message>
        </div>
        <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="ifEmailView">
            <div class="ui-widget">
                <label>
                    To
                </label>
            </div>
            <p-chips [(ngModel)]="email" [addOnBlur]="true"></p-chips>
        </div>
        <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="ifEmailView">
            <div class="ui-widget">
                <label>
                    Subject
                </label>
            </div>
            <input
                pInputText type="text"
                [(ngModel)]="subject"
                class="w-100" placeholder="Subject"
            />
        </div>
        <div class="p-sm-12 p-md-12 ui-fluid" *ngIf="ifEmailView">
            <p-editor [(ngModel)]="reportHtml" [style]="{'height':'20vh'}" #bodyEditor [modules]="editorModule"></p-editor>
        </div>
        <div class="p-sm-12 p-md-12 ui-fluid clares-law-report-table-container" *ngIf="!ifEmailView">
            <div [innerHTML]="reportHtml"></div>
        </div>
    </div>
</ripple-loading-box>
<div class="p-d-flex justify-content-center w-100">
    <h2 *ngIf="sending">Sending...</h2>
</div>