import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, ExportService, AuthService } from '@ripple/services';
import { EntityTypes, StrategicOutcome, StrategicOutcomeTarget, StrategicOutcomeStatistic, FilledStatistic } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { AgencyStatsService } from '../../_core/services';
import { Router } from '@angular/router';

import { TreeNode } from 'primeng/api';

@Component({
  selector: 'sagesse-strategic-plan-viewer',
  templateUrl: './strategic-plan-viewer.component.html',
  styleUrls: ['./strategic-plan-viewer.component.scss']
})
export class StrategicPlanViewerComponent extends BasePage implements OnInit {
  strategicOutcomeService: WarpEntityServiceCache<StrategicOutcome>;
  strategicOutcomeTargetService: WarpEntityServiceCache<StrategicOutcomeTarget>;
  strategicOutcomeStatisticService: WarpEntityServiceCache<StrategicOutcomeStatistic>;
  // Grab this dynamically at somepoint

  dateRange: Date[] = [new Date(2022, 0), new Date(2022, 11)];
  reportData: any[] = [];
  frozenCols: any[] = [{ field: 'name', header: 'Name'}];
  loading: boolean = false;
  filledStatistics: Array<FilledStatistic> = [];
  statDetailsVisible: boolean = false;
  agecnyStatsAdmin: boolean = false;
  firstLoad = false;

  // @ViewChild('app-main')
  // main: ElementRef<HTMLDivElement>;

  tableHeight:string = '';

  QUARTER_YEARS = { '755': 1, '756': 2, '757': 3, '758': 4, '2948': 5 };

  cols: any[] = [];
  targets: boolean = false;
  modalYear: number;
  modalMonth: number;

  editingFilters: boolean = true;
  statsLoading: boolean = true;
  stats: TreeNode[] = [];
  selectedStats: TreeNode[] = [];

  constructor(
    private logService: WarpEntityLogService,
    private factory: WarpEntityCacheFactoryService,
    private authService: AuthService,
    private agencyStatsService: AgencyStatsService,
    private exportService: ExportService,
    private router: Router
  ) {
    super(logService);
    this.strategicOutcomeService = factory.get(EntityTypes.StrategicOutcome) as WarpEntityServiceCache<StrategicOutcome>;
    this.strategicOutcomeStatisticService = factory.get(EntityTypes.StrategicOutcomeStatistic) as WarpEntityServiceCache<StrategicOutcomeStatistic>;
    this.strategicOutcomeTargetService = factory.get(EntityTypes.StrategicOutcomeTarget) as WarpEntityServiceCache<StrategicOutcomeTarget>;
  }

  changedDatePicker() {
    if (this.dateRange.length == 2 && this.dateRange[0] && this.dateRange[1]) {
      this.getReport();
    }
  }

  ngOnInit(): void {
    this.authService.getLoggedInUser().subscribe(currentUser => {
      this.isAgencyStatsAdmin(currentUser);
    });

    // Get strategic outcome statistics tree to build checkboxes
    this.agencyStatsService.getStatisticsTree().subscribe(statTree => {
      this.stats = statTree;
      this.statsLoading = false;
    });
  }

  isAgencyStatsAdmin(currentUser)
  {
    currentUser.roles.every(role => {
      if (role.name == 'Agency Stats Admin') {
        this.agecnyStatsAdmin = true;
        return false;
      }
      return true;
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  sumMonths(months: number) {
    Object.values(months).reduce((a, b) => a + b, 0)
  }

  getReport() {
    this.loading = true;

    this.cols = []
    this.showTargets();

    let currYear = this.dateRange[0].getFullYear();
    let startYear = currYear;
    let endYear = this.dateRange[1].getFullYear();
    let startMonth = this.dateRange[0].getMonth() + 1;
    let currMonth = this.dateRange[0].getMonth() + 1;
    let endMonth = this.dateRange[1].getMonth() + 1;

    if (this.isCarryoverTimeline())
      this.cols.push({ year: currYear, field: 'carryover', header: 'Carryover', month: 'carryover' });

    while (currYear <= endYear) {
      // Start and end month matter
      if (currYear === startYear && currYear === endYear) {
        this.populateColsForYear(currYear, startMonth, endMonth);
      } else if (currYear == startYear) { // Start Month Matters
        this.populateColsForYear(currYear, startMonth, 12);
      } else if (currYear == endYear) { // End Month Matters
        this.populateColsForYear(currYear, 1, endMonth);
      } else { // Use all 12 Months
        this.populateColsForYear(currYear, 1, 12);
      }
      currYear++;
    }

    if (startMonth === 1) {
      startMonth = 12;
      startYear--;
    }
    this.nextSub = this.agencyStatsService.getStrategicReport(startMonth, startYear, this.dateRange[1].getMonth() + 1, this.dateRange[1].getFullYear()).subscribe(resp => {
      console.log('Report Returned', resp);
      // Use report data to build out report
      this.reportData = resp;
      this.loading = false;
      this.firstLoad = true;
      this.fixHeights();
    });
  }

  generateReport() {
    if (this.dateRange.length !== 2 || !this.dateRange[0] || !this.dateRange[1])
      return;

    this.loading = true;
    this.editingFilters = false;
    this.cols = [];
    this.showTargets();

    // Get the date range for the report
    let currYear = this.dateRange[0].getFullYear();
    let startYear = currYear;
    let endYear = this.dateRange[1].getFullYear();
    let startMonth = this.dateRange[0].getMonth() + 1;
    let currMonth = this.dateRange[0].getMonth() + 1;
    let endMonth = this.dateRange[1].getMonth() + 1;

    if (this.isCarryoverTimeline())
      this.cols.push({ year: currYear, field: 'carryover', header: 'Carryover', month: 'carryover' });

    while (currYear <= endYear) {
      // Start and end month matter
      if (currYear === startYear && currYear === endYear)
        this.populateColsForYear(currYear, startMonth, endMonth);
      else if (currYear == startYear) // Start Month Matters
        this.populateColsForYear(currYear, startMonth, 12);
      else if (currYear == endYear) // End Month Matters
        this.populateColsForYear(currYear, 1, endMonth);
      else // Use all 12 Months
        this.populateColsForYear(currYear, 1, 12);
      currYear++;
    }
    if (startMonth === 1) {
      startMonth = 12;
      startYear--;
    }

    let selectedStats: TreeNode[] = this.selectedStats.filter(stat => stat.type === '754');

    if (this.selectedStats.length === 0) {
      selectedStats = [];
      this.stats.map(outcome => {
        outcome.children?.map(subcat => {
          subcat.children?.forEach(stat => {
            selectedStats.push(stat);
          });
        });
      });
    }

    selectedStats = selectedStats.map((stat: TreeNode) => {
      return {
        key: stat.key,
        label: stat.label,
        type: stat.type,
        data: stat.data,
        parent: stat.parent ? {
          key: stat.parent.key,
          label: stat.parent.label,
          type: stat.parent.type,
          data: stat.parent.data,
          parent: stat.parent.parent ? {
            key: stat.parent.parent.key,
            label: stat.parent.parent.label,
            type: stat.parent.parent.type,
            data: stat.parent.parent.data,
            parent: undefined,
          }: undefined,
        } : undefined,
      }
    })

    this.nextSub = this.agencyStatsService.getStrategicReportV2(
      startMonth,
      startYear,
      this.dateRange[1].getMonth() + 1,
      this.dateRange[1].getFullYear(),
      selectedStats,
    ).subscribe(resp => {
      // Use report data to build out report
      this.reportData = resp;
      this.loading = false;
      this.firstLoad = true;
      this.fixHeights();
    });
  }

  populateColsForYear(currYear, startMonth, endMonth) {
    let currMonth = startMonth;
    while (currMonth <= endMonth) {
      this.cols.push({ year: currYear, field: this.getMonthName(currMonth), header: this.getMonthName(currMonth).slice(0, 3) + '-' + currYear.toString(), month: currMonth.toString() });
      if (currMonth % 3 === 0) {
        let quarter = 'Q' + (currMonth / 3).toString();
        let ytd = 'ytd' + (currMonth / 3).toString();
        this.cols.push({ year: currYear, field: quarter.toLowerCase(), header: quarter + '-' + currYear.toString(), month: quarter.toLowerCase(), highlight: true });
        if (this.targets) {
          let target = (currMonth / 3).toString();
          this.cols.push({ year: currYear, field: 'target' + target, header: target + '/4 Target', month: 'target' + target, highlight: true, target: true, quarter: target });
          if (currMonth === 12) {
            this.cols.push({ year: currYear, field: 'target' + 5, header: 'Year Target', month: 'target' + 5, highlight: true, target: true, quarter: 5 });
          }
        }
        if (currMonth !== 12)
          this.cols.push({ year: currYear, field: ytd.toLowerCase(), header: 'YTD', month: ytd, highlight: true });
        else {
          this.cols.push({ year: currYear, field: ytd.toLowerCase(), header: 'Year Total', month: ytd, highlight: true });
        }
      }
      currMonth++;
    }
  }

  formatDate(date: Date) {
    return (date.getMonth() + 1).toString() + ',' + date.getFullYear().toString();
  }

  // ToDO = need to check if it is a year as well
  showTargets() {
    if ((this.dateRange[0].getMonth()) % 3 === 0)
      this.targets = true;
     else
      this.targets = false;
  }

  getMonthName(monthNum) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if (monthNum >= 0 && monthNum <= 12)
      return months[monthNum-1];

    return 'N/A';
  }

  onChange(target: any) {
    this.nextSub = this.strategicOutcomeTargetService.update(target).subscribe(resp => {
      console.log('target', resp);
    });
  }

  exportReport() {
    let rows = [];
    this.reportData.forEach(outcome => {
      rows.push({Name: outcome.name});
      outcome.subCats.forEach(subcat=> {
        rows.push({Name: subcat.name});
        subcat.stats.forEach(stat => {
          rows.push(this.GenerateRowForStat(stat));
        });
      });
    });

    this.exportService.exportXLSX(rows, 'Report');
   }

  isCarryoverTimeline() {
    if ((this.dateRange[0].getMonth() === 0 && this.dateRange[1].getMonth() === 11) || (this.dateRange[0].getMonth() === 3 && this.dateRange[1].getMonth() === 2))
      return true;
    else
      return false;
  }

  GenerateRowForStat(stat) {
    let toRet = {Name: stat.name}
    this.cols.forEach(col => {
      if (col.target == true)
        toRet[col.header] = stat.targets[col.quarter.toString() + col.year.toString()].properties.value;
      else {
        const value = stat.years[col.year][col.month].value;
        toRet[col.header] = value !== null && value !== undefined ? value : 0;
      }
    });
    return toRet;
  }

  sanityCheck(stat, year, month) {
    try {
      let value = stat.years[year][month];
      let lastMonthValue = 0;
      if (value !== 0) {
        if (month !== 1)
          lastMonthValue = stat.years[year][month - 1];
        else
          lastMonthValue = stat.years[year -1 ][12];
        if (lastMonthValue !== 0) {
          let diff = (Math.abs(value - lastMonthValue) / value);
          //console.log(diff, diff > 0.200)
          if (diff > 0.200)
            return true;
        }
      }
    } catch(err) {
      return false;
    }
  }

  openModal(stat, month, year) {
    console.log(stat);
    this.modalMonth = month;
    this.modalYear = year;
    if (!(month.toLowerCase().includes('q') || month.toLowerCase().includes('y'))) {
      this.nextSub = this.agencyStatsService.getFilledStatsForStrategicOutcomeStatisitc(stat.id, month, year).subscribe(resp => {
        this.statDetailsVisible = true;
        this.filledStatistics = resp;
      })
    }
  }

  redirectToStatEditor(){
    this.router.navigate(['enterstats', this.modalMonth.toString(), this.modalYear.toString()]);
    console.log('Stat Editor')
  }

  @HostListener('window:resize', ['$event'])
  fixHeights($evt = null) {
    this.tableHeight = (window.innerHeight - 242.16 - 57.16 - 57.16).toString() + 'px';
    console.log(this.tableHeight);
  }

  @HostListener('window:resize', ['$event'])
  getColSpan($evt = null) {
    let tableWidth = (window.innerWidth - 48);
    let colNumber = ((tableWidth - 250) /100 ) + 1;
    return colNumber;
  }
}
