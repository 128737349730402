<div class="p-grid" [ngClass]="to.isHorizontal ? 'spacingChange' : ''">
  <div [ngClass]="!to.isHorizontal ? to.optionalClass : 'spacingChange'" *ngFor="let option of selectBoxOptions$ | async">
    <p-checkbox
      [style]="{'display': 'inline-block'}"
      [ngClass]="to.isHorizontal ? 'horizontal' : '' "
      [name]="to.name"
      [value]="option.id"
      [label]="option.optionName"
      [(ngModel)]="option.selected"
      [binary]="true"
      [readonly]="viewOnly"
      (onChange)="checkBoxChanged($event, option)"
    ></p-checkbox>

    <ng-container *ngTemplateOutlet="specifyTextInput; context: {$implicit: option}"></ng-container>
  </div>
</div>


<ng-template #specifyTextInput let-option>
  <ripple-form-specify-text
    *ngIf="option"
    [option]="option"
    [viewOnly]="viewOnly"
    [hidden]="!option.selected"
    (optionChanged)="specifyTextChange($event.$event, $event.option)"
  ></ripple-form-specify-text>
</ng-template>
