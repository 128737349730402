<input *ngIf="!to.showTime" data-lpignore="true" autocomplete="off"
  pInputText style="width: 100%;flex: 1 1 100%" type="date" max="9999-12-31"
  [maxLength]="to.maxLength" [formControl]="formControl" [formlyAttributes]="field"
  (change)="change($event.target.value)" (blur)="blur()"
/>

<p-calendar *ngIf="to.showTime" [minDate]="to.minDate" [maxDate]="to.maxDate"
  [showTime]="to.showTime" showButtonBar="true" [showIcon]="true"
  [formControl]="formControl" [formlyAttributes]="field"
  [readonlyInput]="viewOnly"
  [yearNavigator]="true" yearRange="1930:2030"
  [selectionMode]="to.selectionMode ? to.selectionMode : 'single'">
</p-calendar>
