<h2>Universal Entity Editor</h2>

<div class="p-grid ui-fluid">
  <div class="p-col-6">
    To Edit An Existing Entity: <br />
    Entity ID:
    <p-inputNumber mode="decimal" [useGrouping]="false" #entId class="p-col-6"></p-inputNumber>
    <button pButton label="GO" (click)="entityid(entId.value)"></button>
  </div>
  <div class="p-col-6">
    To Create A New Entity: <br />
    EntityType ID:
    <p-inputNumber mode="decimal" [useGrouping]="false" #type class="p-col-6"></p-inputNumber>
    <button pButton label="GO" (click)="typeid(type.value)"></button>
  </div>

  <div class="p-col-12">
    <h2>{{description}}</h2>
  </div>
  <div class="p-col-12" *ngIf="loading">
      <!-- <ripple-entity-debug [entity]="entityType"></ripple-entity-debug> -->

      <ripple-entity-debug [entity]="entity"></ripple-entity-debug>

      <ripple-entity-details [entity]="entity"></ripple-entity-details>
  </div>
</div>