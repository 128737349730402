import { FormlyFieldConfig } from '@ngx-formly/core';
import { Guid } from 'guid-typescript';
import { Observable, Subject } from 'rxjs';

import type {
  IFilterableEntityTypeDescriptor,
  IUpdateable,
  Identifiable,
  IFieldFrontendTemplate,
  WarpEntity,
  IGenericObject,
  IFormBuilderChoice,
} from '@ripple/models';

import type { ExtendedFormlyFieldConfig, FieldTypeName, FieldWrapperName, PrimeNgFieldTypeName } from '../service-resources';
import type { FormBuilderComponent } from '../form-builder.component';
import type { FormSection } from './form-section';

// @dynamic
export class FormField implements IUpdateable, Identifiable {
  onDestroy$ = new Subject<void>();

  internalID: string = Guid.create().toString();
  internalType: 'field' = 'field';
  containsCustomJson = false;

  fieldGroupClassName = '';
  className = '';
  // key = 'templateOptions.label';
  key = undefined;
  type: FieldTypeName | PrimeNgFieldTypeName = 'input';
  label = 'Sample';
  toType = '';
  maxLength = 0;
  dataType = '';
  height = '';
  wrapper: FieldWrapperName = 'ripple-form-field';

  deletable: boolean;
  editable: boolean;
  disabled: boolean;
  hidden: boolean;

  listEntityTypeID = -1;
  selectedEntity: IFilterableEntityTypeDescriptor;
  _templateOptions: FormlyFieldConfig['templateOptions'] = { };

  fileTypes: any[];
  filePermission: string;

  // tslint:disable-next-line: no-any
  options: any[] = [];
  _optionIds: Map<string, number>;

  isSectionChild = false;
  isSubSectionChild = false;
  parentID: string;
  optionalClass: string;
  isHorizontal = false;
  description: string;
  showTime = false;
  required = false;
  isMultiSelect = false;
  enableRichTextEditor = false;
  isNumber = false;
  autoFillSignature = false;
  isSignedField: {
    id: string;
    optionName: string;
  };

  get viewOnly() {
    return this.parent.viewOnly;
  }

  get hideOptions() {
    return this.parent.hideOptions;
  }

  constructor(
    protected parentSection: FormSection = null,
    data: Partial<IFieldFrontendTemplate> = { },
    optionIds: Map<string, number>,
  ) {
    this.parentID = this.parentSection.internalID;
    this.deletable = true;
    this.editable = true;
    this.disabled = false;
    this.isSectionChild = true;
    this._optionIds = optionIds,

    this.updateSelf(data);
  }

  get parent() { return this.parentSection; }

  getById(id: string): FormField | null {
    return (this.internalID === id) ? this : null;
  }

  setKey(key: string) {
    this.key = key || undefined; // this.key = 'templateOptions.label';
  }

  resetSelf() {
    this.selectedEntity = null;
    this.options = [];
    this.type = 'input';
    this.toType = '';
    this.maxLength = 0;
    this.dataType = '';
    this.height = '';
    this.required = false;
    this.showTime = false;
    this.containsCustomJson = false;

    this.deletable = true;
    this.editable = true;
    this.disabled = false;
  }

  updateSelf(data: Partial<FormField> | Partial<IFieldFrontendTemplate>) {
    // if data.selectedEntity is a one length array, make it object
    if (data.selectedEntity instanceof Array && data.selectedEntity.length === 1) data.selectedEntity = data.selectedEntity[0];

    this.resetSelf();

    this.getPropertyNames(true)
      .forEach((propName) => this[propName] = propName in data ? data[propName] : this[propName]);

    this.parentID = this.parentSection.internalID;
    this.listEntityTypeID = data.selectedEntity ? data.selectedEntity.id : -1;

    if(this.isNumber)
      this.toType = 'number';

    if (this.options && this.options.length && this._optionIds.size >= 1)
      this.options = this.options.map( o => {
        if (o.optionName !== undefined && o.id === undefined)
          return { ...o, id: this._optionIds.get(o.optionName) };
        else
          return o;
      });

    this.ensureV2Key();
    this.syncWrapper();

    return this;
  }

  private syncWrapper() {
    // determine which wrapper to use
    switch (this.parent.wrapper) {
      case 'table-panel':
        return this.wrapper = 'table-row';

      case 'panel':
      default:
        return this.wrapper = 'ripple-form-field';
    }
  }

  private getPropertyNames(allowCircularReferences: boolean = false) {
    const oldKeys = [
      'options', 'optionalClass', 'key', 'type', 'label', 'showTime', 'maxLength',
      'toType', 'description', 'required', 'isMultiSelect', 'enableRichTextEditor', 'disabled', 'dataType',
      'selectedEntity', 'fileTypes', 'filePermission', 'autoFillSignature', 'isSignedField', 'height',
      'parentSection', 'deletable', 'editable', 'hidden', 'isHorizontal'
    ];

    return Array.from(new Set(
      Object.keys(this)
      .concat(oldKeys)
      .filter( key => {
        // these things have/are back refs, that we don't want to copy
        return allowCircularReferences || !(this[key] instanceof Subject || key === 'parentSection');
      })
    ));
  }

  ensureV2Key() {
    const newKey = FormField.rippleKey(this.type, this.key);
    if (newKey !== this.key) {
      this.log(`Updating key '${this.key}' => '${newKey}'`);
      this.key = newKey;
    }
  }

  ensureV3Key() {
    // TODO: IZZY-252 FormBuilder Key change suggestion
    const newKey = FormField.rippleKey(this.type, this.key, {internalID: this.internalID});
    if (newKey !== this.key) {
      this.log(`Updating key '${this.key}' => '${newKey}'`);
      this.key = newKey;
    }
  }

  exportSelf(data: Partial<IFieldFrontendTemplate> = { }): Readonly<IFieldFrontendTemplate> {
    data.internalType = this.internalType;

    this.ensureV2Key();

    // this.copyWithoutCircularReferences(this, data, new WeakSet([this]));
    this.getPropertyNames()
      .forEach(key => data[key] = this[key]);

    // when testing while building, options will have selected/specify text added, so we have to remove them before saving the template
    if (data.options && data.options.length)
      data.options = data.options.map( o => {
        const scrubbed = { ...o } as IGenericObject;
        delete scrubbed.selected;
        delete scrubbed.specifytext;
        return scrubbed as IFormBuilderChoice;
      });

    // KG: 2020-05-20: Removed the toType cast to boolean here, it doesn't align with how the variable is actually used (like a string)
    // data.toType = this.toType;

    return data as IFieldFrontendTemplate;
  }

  copyWithoutCircularReferences(source: any, target: any, seen: WeakSet<any>) {
    for (const key of Object.keys(source)) {
      const value = source[key];
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value))
          continue;

        seen.add(value);
      }
      target[key] = value;
    }
  }

  getFormBuilder(): FormBuilderComponent {
      return this.parentSection.getFormBuilder();
  }

  get name() {
    return this.label ? this.label : 'Placeholder Name';
  }

  getEntityFromID(entity: IFilterableEntityTypeDescriptor): Observable<WarpEntity[]> {
    return this.getFormBuilder().getEntitiesFromEntityID(entity);
  }

  getJson(excludeFunctions: boolean = false) {
    const retVal: ExtendedFormlyFieldConfig = {
      internalID: this.internalID,
      internalType: this.internalType,
      fieldGroupClassName: 'p-grid ui-fluid',
      className: 'p-col-12',
      fieldGroup: []
    };

    let fieldGroup: FormlyFieldConfig[];

    fieldGroup = [
      {
        hideExpression: () => this.hideOptions && this.hidden,
        type: this.type,
        className: 'p-col-12',
        key: this.key,
        wrappers: [ this.wrapper || 'ripple-form-field'],
        hooks: {
          // onInit: (field) => {
          //   const form = field.formControl;
          //   field.formControl.valueChanges.pipe(
          //     takeUntil(this.onDestroy$),
          //     tap(val => {
          //       console.warn(field, val)
          //     }),
          //   ).subscribe();
          // }
        },
        templateOptions: {
          description: this.description,
          name: this.key, // name is using the key to ensure it has a unique name just like key
          listEntityType: this.selectedEntity,
          options: this.type === 'select-entity' ? this.getEntityFromID(this.selectedEntity) : this.options,
          fileTypes: this.type === 'generic-file' ? this.fileTypes.map(obj => obj.id).join(',') : null,
          filePermission: this.filePermission ? this.filePermission : '',
          autoFillSignature: this.autoFillSignature,
          isSignedField: this.isSignedField instanceof Array ? this.isSignedField[0] : this.isSignedField,
          optionalClass: this.optionalClass,
          label: this.name,
          height: this.height ? this.height + 'px' : undefined,
          width: '100%',
          required: this.required,
          viewOnly: this.viewOnly,
          isMultiSelect: this.isMultiSelect,
          enableRichTextEditor: this.enableRichTextEditor,
          showTime: this.showTime,
          type: this.toType, // not to be confused with normal type, for example type = input, toType = number <input type="number">
          maxLength: this.maxLength || null,
          disabled: this.disabled,
          isHorizontal: this.isHorizontal,
          onClick: ($event) => { },
          items: [],
          valueChange: ({ value }) => this.getFormBuilder().options?.updateInitialValue(),
          change: (field, $event) => {
            // console.log('onchange for select-entity', field, $event);
            field.form.patchValue(field.model);
            if (field.type == 'select-entity') {
              //model[field.key] = $event.value;
            }
          },
          ...this._templateOptions
        },

        expressionProperties: {
          'templateOptions.required': '!!field.templateOptions.required',
        },
      }
    ];

    // const deleteButton = {
    //   const deleteButton = this.deletable ? [{
    //       label: 'Delete', icon: 'pi pi-fw pi-times',
    //       command: () => formBuilder.openDeleteField(this, this.parentSection) }] : [];
    // }

    if (this.editable && !this.hideOptions) {
      const formBuilder = this.getFormBuilder();
      fieldGroup[0].className = 'p-md-11';

      const editControls = (!this.editable) ? [] : [
        // {
        //   label: 'Edit JSON', icon: 'pi pi-fw pi-palette',
        //   command: () => formBuilder.openUpdateJson(this.parentSection, this)
        // },
        {
          label: 'Edit Field', icon: 'pi pi-fw pi-refresh',
          command: () => formBuilder.openUpdateField(this, this.parentSection)
        },
        {
          label: 'Copy Field', icon: 'pi pi-fw pi-plus',
          command: () => formBuilder.copyField(formBuilder.getEssentialInfo(this), this.parentSection.internalID)
        }
      ];

      const deleteButton = (!this.deletable) ? [] : [{
        label: 'Delete', icon: 'pi pi-fw pi-times',
        command: () => formBuilder.openDeleteField(this, this.parentSection)
      }];

      const customJson = this.containsCustomJson ? 'custom-json ' : '';
      const editButton = {
        type: 'button',
        label: '',
        className: `${customJson} p-md-1 field-options`,

        templateOptions: {
          onClick: ($event) => formBuilder.openUpdateField(this, this.parentSection),
          icon: 'pi pi-cog',
          iconPos: 'center',
          buttonText: '',
          style: {
            display: 'inline-block'
          },
          items: [
            ...editControls,
            ...deleteButton
          ]
        },
      };
      fieldGroup.push(editButton);
    }

    retVal.fieldGroup = fieldGroup;

    return retVal;
  }

  log(...args) {
    this.getFormBuilder().logChild(this, ...args);
  }

  static rippleKey(type: string, key: string, options: { updateKeyForCopy?: boolean, internalID?: string } = {
    updateKeyForCopy: false,
    internalID: undefined
  }) {
    // Max length is actually 128, but we add an index after, so we leave room for that
    const maxLength = 120;
    const { updateKeyForCopy, internalID } = options;

    const v2Prefix = `FormBuilder--${type}-`.toLowerCase().replace(' ', '');

    // TODO: For the IZZY-252 V3 change suggestion
    /*
      // const cleanedInternalID: string = (internalID) ?
      // `FormBuilder--${type}-${internalID.replace(/[-\s]/g, '')}`.toLowerCase().replace(' ', '') :
      // undefined;
    */

    // Put in security measures to ensure that the key is not-blank, ever
    // Even if the chosen default key is not great, it's better than nothing
    if (!key || key === '')
      key = 'defaultValue';

    if (!key.startsWith(v2Prefix) || updateKeyForCopy)
    {
      let newKey: string = `${v2Prefix}-${key}`.toLowerCase().replace(' ', '');
      if (newKey.length > maxLength)
        newKey = newKey.substring(0, maxLength);

      return newKey;
    }
    return key;
  }
}
