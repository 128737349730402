import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  WarpEntity,
  WarpEntityType,
  EntityFilter,
  IGenFormlyOptions,
  FilterOperator,
  DataFieldName,
} from '@ripple/models';
import { MessageService } from '@ripple/services';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { FieldTypeName } from '@ripple/formbuilder';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'ripple-entity-filter',
  templateUrl: './entity-filter.component.html',
  styleUrls: ['./entity-filter.component.css'],
})
export class EntityFilterComponent implements OnInit, OnChanges {
  @Input() filterEntity: WarpEntity;
  @Input() warpEntityType: WarpEntityType;
  @Input() defaultFilter: EntityFilter;
  @Input() filterSubject: Subject<EntityFilter>;
  @Input() overrideGeneratorOptions: IGenFormlyOptions;
  @Input() overrideFieldOrders: string[];
  @Input() customFields: FormlyFieldConfig[];
  generatorOptions: IGenFormlyOptions;
  fieldOrders: string[];

  splitButtons: MenuItem[] = [
    {
      label: 'Search',
      icon: 'pi pi-search',
      command: () => this.generateEntityFilter(),
    },
  ];

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.initFilter();
  }

  ngOnChanges(sc: SimpleChanges) {
    if (sc.warpEntityType && this.warpEntityType) {
      if (
        !this.filterEntity ||
        this.filterEntity.entityTypeId != this.warpEntityType.id
      ) {
        this.filterEntity = new WarpEntity(
          WarpEntity.emptyEntity(this.warpEntityType.id)
        );
      } else {
        this.generateEntityFilter();
      }
    }
  }

  initFilter() {
    if (this.overrideGeneratorOptions)
      this.generatorOptions = this.overrideGeneratorOptions;
    else
      this.generatorOptions = {
        exclude: (cfim) => !cfim.showAsFilter || !cfim.displayInReports,
        expressionProperties: (cfim) => {
          if (
            cfim.cf_dataField &&
            (cfim.cf_dataField === 'linkedentityid' ||
              cfim.cf_dataField === 'customfieldchoiceid')
          ) {
            return {
              'templateOptions.isMultiSelect': (model, formState, field?) =>
                true,
            };
          } else if (
            cfim.cf_dataField &&
            (cfim.cf_dataField === 'datedata' ||
              cfim.cf_dataField === 'datetimedata')
          ) {
            return {
              type: (model, formState, field?) => 'datetime-picker',
              'templateOptions.selectionMode': (model, formState, field?) =>
                'range',
            };
          } else return {};
        },
        customFields: this.customFields,
      };

    if (this.overrideFieldOrders) this.fieldOrders = this.overrideFieldOrders;
    else this.fieldOrders = [];
  }

  generateEntityFilter() {
    const filter = this.defaultFilter
      ? this.defaultFilter.Subset()
      : EntityFilter.None;
    this.messageService.add('original filter', filter.querystring);
    console.log('CREATING FILTER FROM ', this.filterEntity);
    for (const [key, value] of Object.entries(this.filterEntity.properties)) {
      console.log(key, ': ', value);
      if (value) {
        const cfim = this.warpEntityType.customFieldsInModules.find(
          (c) => c.unchangeableName.toLowerCase() === key
        );
        let cf_dataField: DataFieldName | FieldTypeName = cfim
          ? cfim.cf_dataField
          : undefined;
        if (!cf_dataField) {
          // check this.customFields
          const cfInCustomFields = this.customFields.find((c) => c.key === key);
          if (cfInCustomFields)
            cf_dataField = cfInCustomFields.type as FieldTypeName;
        }
        switch (cf_dataField) {
          case 'linkedentityid':
          case 'select-entity':
            if (value instanceof Array && value.length > 1)
              filter.AdvancedUnion(
                value.map((v) => ({
                  key: `${key}_lid`,
                  operator: FilterOperator.EqualTo,
                  value: v.id,
                }))
              );
            else if (value instanceof Array && value.length === 1)
              filter.Advanced({
                key: `${key}_lid`,
                operator: FilterOperator.EqualTo,
                value: value[0].id,
              });
            break;
          case 'customfieldchoiceid':
          case 'select-search':
            if (value instanceof Array && value.length > 1)
              filter.AdvancedUnion(
                value.map((v) => ({
                  key,
                  operator: FilterOperator.EqualTo,
                  value: v.optionName,
                }))
              );
            else if (value instanceof Array && value.length === 1)
              filter.Advanced({
                key,
                operator: FilterOperator.EqualTo,
                value: value[0].optionName,
              });
            break;
          case 'datedata':
          case 'datetimedata':
          case 'eventdatetimerange':
          case 'datetime-picker':
            if (value instanceof Array && value.length === 2) {
              filter.Advanced({
                key,
                operator: FilterOperator.GreaterThanOrEqualTo,
                value: moment(value[0]).format('YYYYMMDD HH:mm:SS'),
              });
              if (value[1])
                filter.Advanced({
                  key,
                  operator: FilterOperator.LessThanOrEqualTo,
                  value: moment(value[1]).format('YYYYMMDD HH:mm:SS'),
                });
            }
            break;
          case 'longdata':
          case 'floatdata':
          case 'doubledata':
              filter.Advanced({
                key,
                operator: FilterOperator.EqualTo,
                value,
              });
              break;
          default:
            filter.Advanced({
              key,
              operator: FilterOperator.Like,
              value,
            });
            break;
        }
      }
    }
    this.messageService.add('entity filter', filter.querystring);
    this.filterSubject.next(filter);
  }
}
