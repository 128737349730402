// Configuration token for the ripple user guard service
import { InjectionToken } from '@angular/core';

export const USER_GUARD_CONFIG = new InjectionToken<IUserGuardConfig>('USER_GUARD_CONFIG');


export enum RippleUserGuardDebugLevel {
  NONE = 0,
  INFO = 1,
  VERBOSE = 2,
};

type EntityTypeID = number;
type Whitelist = {
  /** The specific entity types that can access a page. eg. Employees<2>, Users<583>, or FormHandoffUsers<675> */
  entityTypeIDs?: EntityTypeID[],
  /** Allow specific roles to view pages */
  roles?: (number | string)[],
};

export interface IUserGuardConfig {
  userGuardDebugLevel?: RippleUserGuardDebugLevel,
  /** Redirect the user to a PermissionsDenied page if they don't have permissions, instead of sending them back where they came from. */
  usePermissionsDenied?: boolean,
  /** Redirect to login page if not logged in */
  redirect?: boolean,
  /** Come back after the user is logged in */
  redirectBack?: boolean,
  /** Return to this page after login if no redirect URL is specified */
  redirectUrl?: string,
  /** explicitly grant access to each list based on route */
  whitelists?: {
    default: Whitelist,
    [key: string]: Whitelist,
  }
}

export class UserGuardConfig implements IUserGuardConfig {
  userGuardDebugLevel: RippleUserGuardDebugLevel;
  usePermissionsDenied: boolean;
  redirect: boolean;
  redirectBack: boolean;
  redirectUrl: string;

  whitelists: {
    default: Whitelist | null,
    [key: string]: Whitelist | null,
  };

  public constructor(config?: IUserGuardConfig) {
    this.assign(config);
  }

  public assign(config?: IUserGuardConfig) {
    if (config) {
      this.userGuardDebugLevel = config.userGuardDebugLevel;
      this.usePermissionsDenied = config.usePermissionsDenied;
      this.redirect = config.redirect;
      this.redirectBack = config.redirectBack;
      this.redirectUrl = config.redirectUrl;
      this.whitelists = config.whitelists;
    }
  }
}
