import { environment } from '@ripple/environment';
import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class Organization extends WarpEntity {
    id?: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.Organization;
        if (this.logo) this.logo = `${environment.restEndpointUrl}/api/blob/${this.logo}`;
        if (this.website) {
            // remove http:// or https:// if exists
            this.website = this.website.replace('http://').replace('https://');
        }
    }

    static empty(): Organization {
        return new Organization(super.emptyEntity(EntityTypes.Organization));
    }
}