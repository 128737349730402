<div class="header" *ngIf="header !== false">
  <h1 class="header__heading">{{header}}</h1>
</div>
<div class="header header--margin" *ngIf="showAdd && view === 'small'">
  <div class="header__heading">
    <div class="input-icon-overlay w-100">
      <input
        #noteText pInputText type="text"
        class="w-100" placeholder="Add a {{noteTypeDisplayName}}"
        (keyup.Enter)="addNote(noteText.value);"
        [disabled]="adding"
      />
    </div>
  </div>
  <div class="header__actions">
    <p-button icon="pi {{adding ? 'pi-spin pi-spinner' : 'pi-plus'}}" [disabled]="adding" (click)='addNote(noteText.value)'></p-button>
  </div>
</div>
<div *ngIf="showAdd && view === 'big'">
  <div>
    <textarea pInputTextarea #noteTextArea [maxLength]="maxLength"
      class="w-100" placeholder="Add a {{noteTypeDisplayName}}"
      [rows]="5" [autoResize]="true"  [disabled]="adding"></textarea>
  </div>
  <div class="d-flex mb-3">
    <p-button icon="pi {{adding ? 'pi-spin pi-spinner' : 'pi-plus'}}" class="ml-auto"
      [disabled]="adding" label="Add" (click)='addNote(noteTextArea.value)'></p-button>
  </div>
</div>
<div class="mb-3" *ngIf="allowTag">
  <p-accordion>
    <p-accordionTab header="Tag Filter">
      <div class="p-grid">
        <div class="p-col-12 p-md-6" *ngFor="let tag of entityTags">
          <p-checkbox name="entityTags" [value]="tag"
              [(ngModel)]="entityTagsSelected" (onChange)="filterNotes()"></p-checkbox>
          <label>&nbsp;{{tag.name}}</label>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>


<ripple-loading-box [loading]="loading">
  <ul class="entity-note-list" *ngIf="entityNotes.length > 0">
    <li *ngFor="let note of entityNotesFiltered">
      <ripple-entity-note id="note-{{note.entityId}}" class="{{ note.entityId === scrollTo ? 'note-selected' : '' }}"
        [noteTypeFlagCustomClass]="noteTypeFlagCustomClass" [note]="note" [noteTypeDisplayName]="noteTypeDisplayName"
        [view]="view" [maxLength]="maxLength" [showFullNotes]="showFullNotes" [allowPin]="allowPin" [allowTag]="allowTag"
        (save)="updateNote($event)" (delete)="deleteNote($event);" (context)="closeAllExcept($event)" (noteClick)="noteClick.emit($event)">
      </ripple-entity-note>
    </li>
  </ul>
  <ripple-empty-state *ngIf="entityNotes.length > 0 && entityNotesFiltered.length === 0" iconClass="pi pi-tag" [heading]="emptyFilteredMessage"></ripple-empty-state>
  <ripple-empty-state *ngIf="entityNotes.length === 0" iconClass="pi pi-tag" [heading]="emptyMessage"></ripple-empty-state>
</ripple-loading-box>