import { RippleFieldGenericComponent } from './ripple-field-generic/ripple-field-generic.component';
import { SelectBoxFieldComponent } from './common/select-box-field.component';

import { FormCheckboxMultipleColComponent } from './form-checkbox-multiple-col/form-checkbox-multiple-col.component';
import { FormBuilderSignatureComponent } from './form-signature/form-signature.component';
import { FormRadioMultipleColComponent } from './form-radio-multiple-col/form-radio-multiple-col.component';
import { FormDatetimePickerComponent } from './form-datetime-picker/form-datetime-picker.component';
import { FormDropdownButtonComponent } from './form-dropdown-button/form-dropdown-button.component';
import { FormRepeatPanelComponent } from './form-repeat-panel/form-repeat-panel.component';
import { FormBuilderButtonComponent } from './form-button/form-button.component';
import { FormSelectEntityComponent } from './form-select-entity/form-select-entity.component';
import { FormDescriptionComponent } from './form-description/form-description.component';
import { FormTextEditorComponent } from './form-text-editor/form-text-editor.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormColorPickerComponent } from './form-color-picker/form-color-picker.component';
import { FormFileComponent } from './form-file/form-file.component';
import { FormMultiselectComponent } from './form-multiselect/form-multiselect.component';
import { FormVirtualSelectEntityComponent } from './form-virtual-select-entity/form-virtual-select-entity.component';

import { FormWrapperFieldComponent } from './wrappers/form-wrapper-field/form-wrapper-field.component';
import { FormWrapperPanelComponent } from './wrappers/form-wrapper-panel/form-wrapper-panel.component';
import { FormWrapperTableComponent } from './wrappers/form-wrapper-table/form-wrapper-table.component';
import { FormWrapperTableRowComponent } from './wrappers/form-wrapper-table-row/form-wrapper-table-row.component';

import { WYSIWYGEditor } from './plain-inputs/wysiwyg-editor/wysiwyg-editor.component';
import { VirtualEntitySelectComponent, VirtualEntitySelectRemoveEntityDirective, TemplateInputItem } from './plain-inputs/virtual-entity-select/virtual-entity-select.component';
import { PasswordDirective } from './plain-inputs/password/password';

export {
  RippleFieldGenericComponent,
  SelectBoxFieldComponent,

  FormCheckboxMultipleColComponent,
  FormBuilderSignatureComponent,
  FormRadioMultipleColComponent,
  FormDatetimePickerComponent,
  FormDropdownButtonComponent,
  FormRepeatPanelComponent,
  FormBuilderButtonComponent,
  FormSelectEntityComponent,
  FormDescriptionComponent,
  FormTextEditorComponent,
  FormTextareaComponent,
  FormSelectComponent,
  FormInputComponent,
  FormColorPickerComponent,
  FormFileComponent,
  FormMultiselectComponent,
  FormVirtualSelectEntityComponent,

  FormWrapperFieldComponent,
  FormWrapperPanelComponent,
  FormWrapperTableComponent,
  FormWrapperTableRowComponent,

  WYSIWYGEditor,
  VirtualEntitySelectComponent,
  VirtualEntitySelectRemoveEntityDirective,

  PasswordDirective,

  TemplateInputItem
};


export const FORMBUILDER_FORMLY_OPTIONS = {
  validationMessages: [
    { name: 'required', message: 'This field is required.' },
    { name: 'minlength', message: 'This field is too short.' },
  ],
  types: [
    { /** button */
      name: 'button',
      component: FormBuilderButtonComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          icon: 'pi pi-check',
          type: 'button',
          iconPos: 'right',
          class: '',
        },
      },
    },
    { /** repeat */
      name: 'repeat',
      component: FormRepeatPanelComponent,
    },
    { /** radio-multiple-col */
      name: 'radio-multiple-col',
      component: FormRadioMultipleColComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          class: 'p-col-12 p-md-3',
          // valueProp: 'id',
          // labelProp: 'optionName',
        },
      },
    },
    { /** drop-down-button */
      name: 'drop-down-button',
      component: FormDropdownButtonComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          items: [],
        },
      },
    },
    { /** checkbox-multiple-col */
      name: 'checkbox-multiple-col',
      component: FormCheckboxMultipleColComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          class: 'p-col-12 p-md-3',
        },
      },
    },
    { /** datetime-picker */
      name: 'datetime-picker',
      component: FormDatetimePickerComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          selectionMode: '',
          showTime: 'false',
        },
      },
    },
    { /** select-search */
      name: 'select-search',
      component: FormSelectComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          valueProp: 'id',
          labelProp: 'optionName',
          placeholder: 'Select one',
        },
      },
    },
    { /** generic-input */
      name: 'generic-input',
      component: FormInputComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          maxLength: 1000,
        },
      },
    },
    { /** generic-textarea */
      name: 'generic-textarea',
      component: FormTextareaComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: { },
      },
    },
    { /** description-info */
      name: 'description-info',
      component: FormDescriptionComponent,
      defaultOptions: {
        templateOptions: {
          hideTitle: true,
          label: 'Description Title',
          description: 'Sample text in here',
          required: false,
        },
      },
    },
    { /** text-editor */
      name: 'text-editor',
      component: FormTextEditorComponent,
      defaultOptions: { },
    },
    { /** select-entity */
      name: 'select-entity',
      component: FormSelectEntityComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: {
          valueProp: 'id',
          labelProp: 'name',
          placeholder: 'Select one'
        }
      },
    },
    { /** virtual-select-entity */
      name: 'virtual-select-entity',
      component: FormVirtualSelectEntityComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: {
        templateOptions: { }
      },
    },
    { /** draw-signature */
      name: 'draw-signature',
      component: FormBuilderSignatureComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: { },
    },
    { /** color-picker */
      name: 'color-picker',
      component: FormColorPickerComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: { },
    },
    { /** generic-file */
      name: 'generic-file',
      component: FormFileComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: { },
    },
    { /** multiselect */
      name: 'multiselect',
      component: FormMultiselectComponent,
      wrappers: ['ripple-form-field'],
      defaultOptions: { },
    },
  ],
  wrappers: [
    {
      name: 'ripple-form-field', component: FormWrapperFieldComponent
    },
    {
      name: 'panel', component: FormWrapperPanelComponent
    },
    {
      name: 'table-panel', component: FormWrapperTableComponent
    },
    {
      name: 'table-row', component: FormWrapperTableRowComponent
    },
  ],
};
