<div class="pane pane--lg-h100" *ngIf="hasPermission">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
            <div class="header__heading">
                <ripple-breadcrumb [items]="breadcrumbs"></ripple-breadcrumb>
            </div>
            <div class="header__actions">
                <button pButton label="Open in SharePoint" icon="pi pi-sharepoint" (click)="goToExternalLink(sharePointLink)"></button>
                <button pButton label="Open in Teams" icon="pi pi-teams" (click)="goToExternalLink(msTeamLink)"></button>
                <button pButton label="Export Member Directory" icon="pi pi-{{isExporting ? 'spin pi-spinner' : 'file-excel'}}"
                    [disabled]="isExporting"
                    (click)="exportReport()"></button>
                <ripple-button-list [buttons]="ed.splitButtons"></ripple-button-list>
                <button pButton label="Back" icon="pi pi-arrow-left" (click)="back()"></button>
            </div>
        </div>
    </div>
    <div class="pane__body">
        <div class="p-grid h-100">
            <div class="p-col h-100">
                <p-blockUI [blocked]="tabBlocked" [styleClass]="'block-area'">
                    <div class="p-d-flex h-100">
                        <i class="pi pi-spin pi-spinner p-mx-auto p-my-auto" style="font-size: 3rem"></i>
                    </div>
                </p-blockUI>
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-sitemap"></i> Details</h3>
                      </div>
                    </div>
                    <ripple-entity-details [entity]="team" [hideButtons]="true"
                        [generatorOptions]="generatorOptions" [fieldOrders]="fieldOrders"
                        (afterSave)="afterSave($event)" #ed></ripple-entity-details>
                    <p-tabView *ngIf="ed.loaded" class="h-100">
                        <ng-container *ngFor="let tab of ed.fields[0].fieldGroup[0].fieldGroup">
                            <p-tabPanel *ngIf="ifTabShow(tab)" [cache]="false">
                                
                                <!--header-->
                                <ng-template pTemplate = "header">
                                    <i class="{{tab.templateOptions.wrapperData.headerIcon}}"></i>
                                    &nbsp;
                                    <span>{{tab.templateOptions.wrapperData.label}}</span>
                                </ng-template>
                
                                <ng-template pTemplate="content">
                                    <ripple-scroll-card class="team-tab-scroll-card">
                                        <!--basic form-->
                                        <formly-form [fields]="[tab]" [model]="team.properties"></formly-form>
                    
                                        <!--custom form part-->
                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'generalInfo'">
                                            <ripple-tags [entity]="team"></ripple-tags>
                                        </div>
                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'members'">
                                            <sagesse-team-contact [team]="team"></sagesse-team-contact>
                                        </div>
                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'organizations'">
                                            <sagesse-team-organizations [team]="team"></sagesse-team-organizations>
                                        </div>

                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'folders/files'">
                                            <ripple-ms-team-file-explorer [teamId]="team.azureadobjectid"></ripple-ms-team-file-explorer>
                                        </div>

                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'channels'">
                                            <ripple-entity-list #channelList
                                                [entityService]="channelService" [filter]="channelFilter"
                                                [showFilter]="false" [options]="channelListOptions"
                                                [editButton]="editChannel" [addButton]="addChannel" [showCSVExport]="true"></ripple-entity-list>
                                        </div>
                                    </ripple-scroll-card>
                                </ng-template>
                            </p-tabPanel>
                        </ng-container>
                    </p-tabView>
                </ripple-scroll-card>
               
            </div>
            <div class="p-md-3 p-sm-12 h-100" *ngIf="team && team.entityId > 0">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-tag"></i> Notes</h3>
                      </div>
                    </div>
                    <ripple-entity-notes [header]="false" [entity]="team" noteType="Team Note"
                        [showNoteTypes]="['Team Note']">
                    </ripple-entity-notes>
                </ripple-scroll-card>
            </div>
        </div>
        
    </div>
</div>

<div *ngIf="!hasPermission" style="padding: 1%; text-align: center; display: inline">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
</div>