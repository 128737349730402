<div class="pane pane--lg-h100">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
            <div class="header__heading">
                <ripple-breadcrumb [items]="breadcrumbs"></ripple-breadcrumb>
            </div>
            <div class="header__actions">
                <ripple-button-list [buttons]="ed.splitButtons"></ripple-button-list>
                <button pButton label="Back" icon="pi pi-arrow-left" (click)="back()"></button>
            </div>
        </div>
    </div>
    <div class="pane__body">
        <div class="p-grid h-100">
            <div class="p-col h-100">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-sitemap"></i> Details</h3>
                      </div>
                    </div>
                    <ripple-entity-details [entity]="organization" [hideButtons]="true"
                        [generatorOptions]="generatorOptions" [fieldOrders]="fieldOrders"
                        (afterSave)="afterSave($event)" #ed></ripple-entity-details>
                    <p-tabView *ngIf="ed.loaded" class="h-100" [(activeIndex)]="tabIndex">
                        <ng-container *ngFor="let tab of ed.fields[0].fieldGroup[0].fieldGroup">
                            <p-tabPanel *ngIf="ifTabShow(tab)">
                                <!--header-->
                                <ng-template pTemplate = "header">
                                    <i class="{{tab.templateOptions.wrapperData.headerIcon}}"></i>
                                    &nbsp;
                                    <span>{{tab.templateOptions.wrapperData.label}}</span>
                                    <span class="badge badge--primary ml-1"
                                        *ngIf="tab.templateOptions.wrapperData.tabId === 'contacts'">
                                        {{contactList?.contactList?.totalEntities || organization.numOfContacts}}
                                    </span>
                                    <span class="badge badge--primary ml-1"
                                        *ngIf="tab.templateOptions.wrapperData.tabId === 'teams'">
                                        {{teamList?.teamList?.totalEntities || organization.numOfTeams}}
                                    </span>
                                    <span class="badge badge--primary ml-1"
                                        *ngIf="tab.templateOptions.wrapperData.tabId === 'offices'">
                                        {{officeList?.officeList?.totalEntities || organization.numOfOffices}}
                                    </span>
                                    <span class="badge badge--primary ml-1"
                                        *ngIf="tab.templateOptions.wrapperData.tabId === 'tags'">
                                        {{organization.properties.organizationtags?.length || 0}}
                                    </span>
                                    <span class="badge badge--primary ml-1"
                                        *ngIf="tab.templateOptions.wrapperData.tabId === 'communities'">
                                        {{organization.properties.communities?.length || 0}}
                                    </span>
                                </ng-template>
                
                                <ng-template pTemplate="content">
                                    <ripple-scroll-card class="organization-tab-scroll-card">
                                        <!--basic form-->
                                        <formly-form [fields]="[tab]" [model]="organization.properties"></formly-form>
                    
                                        <!--custom form part-->
                                        <div class="custom-field" *ngIf="tab.templateOptions.wrapperData.tabId === 'generalInfo'">
                                            <div class="ui-widget">
                                                <label>
                                                    Website
                                                </label>
                                            </div>
                                            <div class="p-d-flex">
                                                <div style="flex: 1 0 auto;align-self: center;">
                                                    http://
                                                </div>
                                                <input 
                                                    pInputText
                                                    [(ngModel)]="organization.properties.website"
                                                    style="width: 100%;flex: 1 1 100%;"
                                                />
                                                <div style="flex: 1 0 auto;align-self: center;">
                                                    <button pButton icon="pi pi-external-link" (click)="goToWebsite()"></button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="h-100" *ngIf="tab.templateOptions.wrapperData.tabId === 'contacts'">
                                            <sagesse-organization-contacts #contactList [organization]="organization">
                                            </sagesse-organization-contacts>
                                        </div>
                    
                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'offices'">
                                            <sagesse-offices #officeList [organization]="organization"></sagesse-offices>
                                        </div>


                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'teams'">
                                            <sagesse-team #teamList [organization]="organization"></sagesse-team>
                                        </div>

                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'tags'">
                                            <sagesse-tags [entity]="organization"></sagesse-tags>
                                        </div>

                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'communities'" class="h-100">
                                            <sagesse-communities [entity]="organization"></sagesse-communities>
                                        </div>

                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'folders/files'" class="h-100">
                                            <ripple-ms-team-file-explorer *ngIf="organization.azureadobjectid"
                                                [teamId]="organizationTeamAzureAdObjectId" [channelId]="organizationsTeamChannelAzureAdObjectId"
                                                [folderId]="organization.azureadobjectid">
                                            </ripple-ms-team-file-explorer>
                                        </div>
                                        <div *ngIf="tab.templateOptions.wrapperData.tabId === 'notes'">
                                            <ripple-entity-notes [header]="false" [entity]="organization"
                                                noteType="Organization Note" [showNoteTypes]="['Organization Note']"
                                                [view]="'big'" [allowPin]="true" [allowTag]="true" [maxLength]="5000" [scrollTo]="noteScrollTo">
                                            </ripple-entity-notes>
                                        </div>
                                    </ripple-scroll-card>
                                </ng-template>
                            </p-tabPanel>
                        </ng-container>
                    </p-tabView>
                </ripple-scroll-card>
               
            </div>
            <div class="p-md-3 p-sm-12 h-100" *ngIf="organization && organization.entityId > 0">
                <ripple-scroll-card class="h-100">
                    <div class="scroll-card-header">
                      <div class="header header--pad header--bordered header--gray">
                        <h3 class="header__heading"><i class="pi pi-list"></i> Notes</h3>
                      </div>
                    </div>
                    <ripple-entity-notes [header]="false" [entity]="organization" noteType="Organization Note" [showNoteTypes]="['Organization Note']"
                        [showFullNotes]="false" (noteClick)="noteClick($event)" [allowPin]="true" [allowTag]="true" [maxLength]="1000">
                    </ripple-entity-notes>
                </ripple-scroll-card>
            </div>
        </div>
        
    </div>
</div>
  
  