import { IWarpEntityInputObject, ICustomFieldInModuleInputObject } from '../api/expando-models';

export type DataFieldName = 'textdata' | 'bigtext' | 'generic' |
                            'longdata' | 'floatdata' | 'doubledata' |
                            'linkedentityid' | 'customfieldchoiceid' |
                            'datedata' | 'timedata' | 'datetimedata' | 'eventdatetimerange';

export interface CustomFieldChoice {
  id: number;
  value: string;
  optionName?: string;
  parentId?: number;
}

export class CustomFieldInModule {
  readonly id: number;
  readonly unchangeableName: string;

  get key(): string { return this.unchangeableName; }

  readonly label: string;
  readonly listEntityTypeID?: number;

  readonly cf_id: number;
  readonly cf_type: string;
  readonly cf_identifier: string;
  readonly cf_dataField: DataFieldName;
  readonly cf_choices: CustomFieldChoice[];

  readonly moduleSettings: { [key: string]: string};
  readonly isRequired: boolean;
  readonly displayInReports: boolean;
  readonly showAsFilter: boolean;
  readonly colOrder: number;

  files: File[];

  lowerUnchangeableName?: string;
  keyName?: string;

  constructor( rippleObj: ICustomFieldInModuleInputObject ) {
    this.id = rippleObj.ID;
    this.unchangeableName = rippleObj.unchangeableName;
    this.lowerUnchangeableName = rippleObj.unchangeableName.toLowerCase();

    this.label = rippleObj.label;
    this.listEntityTypeID = rippleObj.ListEntityTypeID;

    this.moduleSettings = { };
    if (rippleObj.moduleSettings)
      for (const [k, v] of Object.entries(rippleObj.moduleSettings))
        this.moduleSettings[k.toLowerCase()] = v;


    if (rippleObj.CustomField) {
      this.cf_id = rippleObj.CustomField.ID;
      this.cf_type = rippleObj.CustomField.Type;
      this.cf_identifier = rippleObj.CustomField.ItemIdentifier;
      this.cf_dataField = rippleObj.CustomField.DataField ? rippleObj.CustomField.DataField.toLowerCase() as DataFieldName : undefined;
      const choices = rippleObj.CustomField.CustomFieldChoices || [];
      this.cf_choices = choices.map( cfc => ({
        id: cfc.ID,
        value: cfc.Value,
        optionName: cfc.Tmp_Text || cfc.Text,
        parentId: cfc.ParentID || undefined
      }));
      // this.cf_choices.length
    }

    if (rippleObj.relTemplateModulesToCustomFields && rippleObj.relTemplateModulesToCustomFields.length > 0) {
      const rel = rippleObj.relTemplateModulesToCustomFields[0];
      this.isRequired = rel.isRequired;
      this.displayInReports = rel.DisplayInReports;
      this.showAsFilter = rel.showAsFilter;
      this.colOrder = rel.colOrder;
    }

    this.files = rippleObj.files;
  }
}
