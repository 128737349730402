<div class="resizable-layout-container">
    <div #resizableLeft class="resizable-left" [style]="{width: defaultLeftWidth}">
        <ng-content select=".left-content">
        </ng-content>
    </div>
    <div #resizableSeparator class="resizable-separator" ></div>
    <div #resizableRight class="resizable-right" [style]="{width: defaultRightWidth}">
        <ng-content select=".right-content">
        </ng-content>
    </div>
</div>
