import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { WarpEntity } from '@ripple/models';

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (_, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value))
        return '<Cyclic Reference>';
      seen.add(value);
    }
    return value;
  };
};

@Component({
  selector: 'ripple-entity-debug',
  templateUrl: './entity-debug.component.html',
  styleUrls: ['./entity-debug.component.scss']
})
export class EntityDebugComponent implements OnChanges {
  // tslint:disable-next-line: no-input-rename
  @Input() entities: Map<number, WarpEntity> | WarpEntity[];
  @Input() entity: WarpEntity;

  entitiesArray: WarpEntity[];

  get header() {
    if (!this.entity) return 'Not Loaded.';

    const name =  this.entity.name || 'No Name';
    const id = this.entity.guaranteedId || this.entity.id;
    return `${name} - ${id}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && this.entity)
      this.entitiesArray = [this.entity];

    if (changes.entities && this.entities) {
      if (this.entities instanceof Map)
        this.entitiesArray = [...this.entities.values()];
      else
        this.entitiesArray = this.entities;

      if (this.entity)
        this.entitiesArray.unshift(this.entity);
    }
  }

  props(ent: WarpEntity) {
    try {
      return Object.entries(ent);
    } catch (e) {
      return [['Error Loading Properties', e]];
    }
  }

  singleLine(obj) {
    if (!obj) return true;
    if (obj.length === 0 || obj.length === 1) return true;
    try {
      const str = JSON.stringify(obj, getCircularReplacer(), 4);
      const m = str.match(/[\n\r].+/);
      if (m) return false;
      else return true;
    } catch (e) {
      return false;
    }
  }
  myJson(obj) {
    return JSON.stringify(obj, getCircularReplacer(), 4);
  }

  display(ent) {
    return `${ent.entityType ? ent.entityType.name : 'Undefined'} ${ent.id} ${ent.name}`;
  }

}
