import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityLogService } from '@ripple/services';

@Component({
  selector: 'sagesse-form-builder-report',
  templateUrl: './form-builder-report.component.html',
  styleUrls: ['./form-builder-report.component.scss']
})
export class FormBuilderReportComponent extends BasePage implements OnInit {

  formId: number;

  constructor(
    private logService: WarpEntityLogService,
    private route: ActivatedRoute,
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.formId = parseInt(params.formId, 10);
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

}
