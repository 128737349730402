<ripple-scroll-card class="new-item-card" [unsetOverflow]="true">
  <div class="new-item">
    <div class="new-item__icons">
      <i class="new-item__icon" [ngClass]="iconClass"></i>
      <i class="pi pi-plus new-item__add-icon" ></i>
    </div>
    <div class="new-item__label">
      {{label}}
    </div>
  </div>
</ripple-scroll-card>