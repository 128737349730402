import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PaginatorModule, Paginator } from 'primeng/paginator';
import { WarpEntity, EntityFilter, FilterOperator, SubscriptionContainer } from '@ripple/models';
import { WarpEntityServiceCache, WarpEntityCacheFactoryService, GenericWarpEntityService, CachedQueryToken } from '@ripple/services';
import { Contact, EntityTypes, Organization, OrganizationContact, Team, TeamContact, User } from '../../../_core/models';
import { environment } from '@ripple/environment';
import { ConfirmationService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { OrganizationService, TeamContactService, TeamService, UserService } from '../../../_core/services';
import { VirtualScroller } from 'primeng/virtualscroller';

@Component({
  selector: 'sagesse-team-contact',
  templateUrl: './team-contact.component.html',
  styleUrls: ['./team-contact.component.scss']
})
export class TeamContactComponent extends SubscriptionContainer implements OnInit {

  @Input() team: Team;
  @ViewChild('myPaginator', {static: false}) paginator: Paginator;

  // Subscriptions
  teamContactsSub: Subscription;
  usersSub: Subscription;
  contactsSub: Subscription;

  selectedSub: Subscription;
  gettingSelected: boolean = false;

  deleteSub: Subscription;

  // services
  organizationService: OrganizationService;
  userService: UserService;
  teamService: TeamService;
  teamContactCacheService: WarpEntityServiceCache<TeamContact>;
  teamContactService: TeamContactService;
  organizationContactService: WarpEntityServiceCache<OrganizationContact>;

  // organization
  organizations: any[] = [];
  organizationsFiltered: any[] = [];
  organizationSearch = '';
  organizationFilterTimeout;
  organizationSelected: any;
  organizationLoading = true;

  // contact
  teamContactFilter: EntityFilter = EntityFilter.None;
  organizationContactFilter: EntityFilter = EntityFilter.None;
  organizationContacts: OrganizationContact[] = [];
  organizationContactsFiltered: OrganizationContact[] = [];
  organizationContactSub: Subscription;
  teamContactSelected: TeamContact;
  contactDialogShow = false;
  teamContacts: TeamContact[] = [];
  teamContactsFiltered: TeamContact[] = [];
  teamContactsLoading = true;
  organizationContactsLoading = true;
  contactSelected: Contact;
  
  contactSearchTerms = '';
  contactFilterTimeout;
  
  pageFilter: EntityFilter = EntityFilter.None;
  pageLoading = true;
  pageSub: Subscription;
  queryTokenSub: Subscription;
  queryToken: CachedQueryToken<Contact | TeamContact | OrganizationContact>;

  first = 0;
  pageSize = 25;
  pageTotal = 0;
  currPage = 0;
  
  // organizationContactIdsSelected: number[] = [];
  teamContactIdsSelected: number[] = [];
  contactIdsSelected: number[] = [];
  userIdsSelected: number[] = [];

  contacts: (Contact | TeamContact | OrganizationContact)[] = [];

  // speical organization
  userDialogShow = false;
  special: 'Team Member' | 'User' | 'Contact' = 'Team Member';
  contactService: WarpEntityServiceCache<Contact>;
  users: User[] = [];
  usersFiltered: User[] = [];
  allOrganizationContacts: OrganizationContact[] = [];
  allContacts: (Contact | OrganizationContact)[] = [];
  contactsFiltered: (Contact | OrganizationContact)[] = [];
  usersLoading = true;
  contactsLoading = true;
  allOrganizationContactsLoading = true;

  addingOrDeletingDialogShow = false;
  addingOrDeleting: 'Adding' | 'Deleting';

  selectAllUsersDialogShow = false;

  EntityTypes = EntityTypes;

  duplicateShow = false;

  get ifSelectAllUsers() {
    return this.team && this.team.properties && this.team.properties.selectallusers;
  }

  get contactListTitle() {
    if (!this.organizationSelected && this.special === 'User') {
      return 'Sagesse Staff/Volunteers';
    } else {
      return this.organizationSelected ? this.organizationSelected.name : 'All ' + this.special + 's';
    }
  }

  constructor(
    private warpEntityFactory: WarpEntityCacheFactoryService,
    private confirmationService: ConfirmationService
  ) {
    super();
    this.organizationContactService = this.warpEntityFactory.get(EntityTypes.OrganizationContact) as WarpEntityServiceCache<OrganizationContact>;
    this.contactService = this.warpEntityFactory.get(EntityTypes.Contact) as WarpEntityServiceCache<Contact>;
    this.teamContactCacheService = this.warpEntityFactory.get(EntityTypes.TeamContact) as WarpEntityServiceCache<TeamContact>;
    this.teamContactService = this.warpEntityFactory.get(EntityTypes.TeamContact) as TeamContactService;
    this.organizationService = this.warpEntityFactory.get(EntityTypes.Organization) as OrganizationService;
    this.userService = this.warpEntityFactory.get(EntityTypes.User) as UserService;
    this.teamService = this.warpEntityFactory.get(EntityTypes.Team) as TeamService;
  }

  ngOnDestroy(): void {
    if (this.teamContactsSub) this.teamContactsSub.unsubscribe();
    if (this.usersSub) this.usersSub.unsubscribe();
    if (this.contactsSub) this.contactsSub.unsubscribe();
    if (this.selectedSub) this.selectedSub.unsubscribe();
    if (this.deleteSub) this.deleteSub.unsubscribe();
  }

  ngOnInit(): void {
    this.getSelectedTeamContacts();
    this.getTeamOrganizations();
    this.viewSpecial();
  }

  // mergeAllContacts() {
  //   const organizationContactsWithEmailOverride = this.allOrganizationContacts.filter(oc => oc.ifEmailOverride);
  //   this.allContacts = [...this.contacts, ...organizationContactsWithEmailOverride];
  //   this.contactsFiltered = this.allContacts;
  // }

  getSelectedTeamContacts() {
    if (this.selectedSub) this.selectedSub.unsubscribe();

    this.selectedSub = this.teamContactService.getSelectedTeamMemberIds(this.team.entityId).subscribe( data => {
      this.teamContactIdsSelected = data.allTeamContactIds;
      this.contactIdsSelected = data.contactIds;
      this.userIdsSelected = data.employeeIds;
    });
  }

  loadAndPaginate(event) {

    let page = (event && event.page) ?? 0;
    this.currPage = page;
    
    const searchValue = this.contactSearchTerms.toLowerCase();
    const searchValFilter = EntityFilter.AdvancedUnion(
      [`first name`, `last name`, `email`]
        .map(key => ({
          key,
          operator: FilterOperator.Like,
          value: searchValue
        }))
    );

    if (this.organizationSelected) {
      let filter = EntityFilter.Advanced({ organization_lid: this.organizationSelected.entityId }).orderBy('contact', 'asc');
      
      if (searchValue.length > 0) {
        filter.LinkedProperty('contact', EntityTypes.Contact, searchValFilter);
      }
      this.queryToken = this.organizationContactService.initQuery(filter, this.pageSize);
    }
    else if (this.special === 'Contact') { // Regular contacts
      let filter = EntityFilter.None.orderBy('first name', 'asc');
      if (searchValue.length > 0) {
        filter = searchValFilter.Subset().orderBy('first name', 'asc')
      }
      this.queryToken = this.contactService.initQuery(filter, this.pageSize);
    }
    else { // team Contact
      let filter = EntityFilter.Advanced({ team_lid: this.team.entityId}).orderBy('contact', 'asc');
                    
      if (searchValue.length > 0) {
        filter.LinkedProperty('contact', EntityTypes.Contact, searchValFilter)
              .LinkedProperty('employees', EntityTypes.User, searchValFilter)
              .matchAnyLinkedPropertyFilter();
      }
      
      this.queryToken = this.teamContactCacheService.initQuery(filter, this.pageSize);
    }

    if (this.queryTokenSub) this.queryTokenSub.unsubscribe();
    this.queryTokenSub = this.queryToken.totalForFilter.subscribe((total) => {
      this.pageTotal = total;
    });

    this.contacts = [];
    if (this.pageSub) this.pageSub.unsubscribe();

    this.pageLoading = true;
    this.pageSub = this.queryToken.getPage(page).subscribe(contacts => {
      console.log('got contacts', contacts);
      this.contacts = contacts;
      this.pageLoading = false;
    });
  }

  loadTeamUsers() {
    if (this.usersSub) this.usersSub.unsubscribe();

    this.usersSub = this.userService.getActiveUsers().subscribe(users => {
      this.users = users;
      this.usersFiltered = users;
      this.filterContacts(true);
      this.usersLoading = false;
    });
  }

  getTeamOrganizations() {
    if (this.team && this.team.properties &&
      (this.team.properties.organization instanceof Array || this.team.properties.nonmemberorganization instanceof Array)) {
      let allOrganizations = [];
      if (this.team.properties.organization instanceof Array) {
        allOrganizations = [...allOrganizations, ...this.team.properties.organization];
      }
      if (this.team.properties.nonmemberorganization instanceof Array) {
        allOrganizations = [...allOrganizations, ...this.team.properties.nonmemberorganization];
      }
      const organizationIds = allOrganizations.map(o => o.id).join(',');
      this.organizationService.getTeamOrganizations(this.team.entityId, organizationIds).subscribe(organizations => {
        this.organizations = organizations;
        this.filterOrganizations();
        this.organizationLoading = false;
      });
    } else {
      this.organizationLoading = false;
      this.organizations = [];
      this.filterOrganizations();
    }
  }

  filterOrganizations(now: boolean = false) {
    if (this.organizationFilterTimeout) clearTimeout(this.organizationFilterTimeout);

    if (now) {
      this.filterOrganizationsInternal(this);
    } else {
      const that = this;

      this.organizationFilterTimeout = setTimeout(
        () => {
          that.filterOrganizationsInternal(that)
        },
        now ? 0 : 800
      );
    }
  }

  filterOrganizationsInternal(that) {
    const searchValue = that.organizationSearch.toLowerCase();
    
    that.organizationsFiltered = that.organizations.filter(o =>
      o.name && o.name.toLowerCase().includes(searchValue) ||
      o.website && o.website.toLowerCase().includes(searchValue) ||
      o.acronyms && o.acronyms.toLowerCase().includes(searchValue));
  }

  filterContacts(now: boolean = false) {
    if (this.contactFilterTimeout) clearTimeout(this.contactFilterTimeout);
    if (now) {
      console.log('filtering now!');
      this.filterContactsInternal(this);
    } else {
      const that = this;
      this.contactFilterTimeout = setTimeout(
        () => {
          console.log('filtering after timeout!');
          that.filterContactsInternal(that);
        },
        800
      );
    }
  }

  filterContactsInternal(that) {
    const searchValue = that.contactSearchTerms.toLowerCase();
    
    if (this.special !== 'User') {
      this.loadAndPaginate(null);
    } else {
      that.usersFiltered = that.users.filter(u =>
        u.properties['first name'] && u.properties['first name'].toLowerCase().includes(searchValue) ||
        u.properties['last name'] && u.properties['last name'].toLowerCase().includes(searchValue) ||
        u.email && u.email.toLowerCase().includes(searchValue) ||
        u.phoneNumber && u.phoneNumber.toLowerCase().includes(searchValue)
      );
      that.sortUsers();
      //console.log('sort users');
    }
  }

  organizationSelect(organization) {
    this.organizationSelected = organization;
    this.contactSearchTerms = '';
    this.loadAndPaginate(null);
  }

  // updateOrganizationContactsSelectedIfNoEmailOverride() {
  //   for (const organizationContact of this.organizationContacts) {
  //     if (this.contactIdsSelected.includes(organizationContact.contact.id) &&
  //       !organizationContact.ifEmailOverride &&
  //       !this.organizationContactIdsSelected.includes(organizationContact.entityId)) {
  //         this.organizationContactIdsSelected.push(organizationContact.entityId);
  //       }
  //   }
  //   this.sortOrganizationContacts();
  // }

  sortOrganizationContacts() {
    this.organizationContactsFiltered.sort((a, b) => {
      // check if the organization contact is checked
      const aChecked = this.contactIdsSelected.find(id => a.contact && id === a.contact.id) ? 1 : 0;
      const bChecked = this.contactIdsSelected.find(id => b.contact && id === b.contact.id) ? 1 : 0;
      if (aChecked !== bChecked) return bChecked - aChecked;

      // if both organization contacts are checked or unchecked, do name compoare
      const aName = a.contactEntity.properties['first name'] + ' ' + a.contactEntity.properties['last name'];
      const bName = b.contactEntity.properties['first name'] + ' ' + b.contactEntity.properties['last name'];

      // put empty name to the bottom
      const aNull = aName === ' ' ? 1 : 0;
      const bNull = bName === ' ' ? 1 : 0;
      if (aNull !== bNull) return aNull - bNull;

      return aName.localeCompare(bName);
    });
  }

  sortUsers() {
    this.usersFiltered.sort((a, b) => {
      // check if the organization contact is checked
      const aChecked = this.userIdsSelected.find(id => id === a.entityId) ? 1 : 0;
      const bChecked = this.userIdsSelected.find(id => id === b.entityId) ? 1 : 0;
      if (aChecked !== bChecked) return bChecked - aChecked;

      const aName = a.properties['first name'] + ' ' + a.properties['last name'];
      const bName = b.properties['first name'] + ' ' + b.properties['last name'];

      // put empty name to the bottom
      const aNull = aName === ' ' ? 1 : 0;
      const bNull = bName === ' ' ? 1 : 0;
      if (aNull !== bNull) return aNull - bNull;

      return aName.localeCompare(bName);
    });
  }

  sortContacts() {
    this.contactsFiltered.sort((a, b) => {
      // check if the organization contact is checked
      const aChecked = this.contactIdsSelected.find(id => id === a.entityId) ? 1 : 0;
      const bChecked = this.contactIdsSelected.find(id => id === b.entityId) ? 1 : 0;
      if (aChecked !== bChecked) return bChecked - aChecked;

      const aName = a instanceof Contact ?
        a.properties['first name'] + ' ' + a.properties['last name'] :
        a.contactEntity.properties['first name'] + ' ' + a.contactEntity.properties['last name'];
      const bName = b instanceof Contact ?
        b.properties['first name'] + ' ' + b.properties['last name'] :
        b.contactEntity.properties['first name'] + ' ' + b.contactEntity.properties['last name'];

      // put empty name to the bottom
      const aNull = aName === ' ' ? 1 : 0;
      const bNull = bName === ' ' ? 1 : 0;
      if (aNull !== bNull) return aNull - bNull;

      return aName.localeCompare(bName);
    });
  }

  sortTeamContacts() {
    this.teamContactsFiltered.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    })
  }

  addContactOrUser(type = 'contact') {
    this.teamContactSelected = TeamContact.empty()
      .linkedProperty('team', this.team.entityId);
      if (type === 'contact')
        this.contactDialogShow = true;
      else if (type === 'user')
        this.userDialogShow = true;
  }

  viewSpecial(special: 'Team Member' | 'User' | 'Contact' = 'Team Member') {
    this.contactSearchTerms = '';
    this.organizationSelected = null;
    this.special = special;

    if (special === 'User')
      this.loadTeamUsers();
    else
      this.loadAndPaginate(null);
    
    if (this.paginator)
      this.paginator.changePageToFirst(null);
  }

  addOrRemovePerson(person, event, showConfirmDialog = true) {
    let message = '';
    if (event.checked) {
      // if (person instanceof Contact && !this.contactDialogShow) {
      //   this.contactSelected = person;
      //   // remove this contact from contact ids selected first
      //   this.contactIdsSelected = this.contactIdsSelected.filter(oc => oc !== person.entityId);
      //   this.contactDialogShow = true;
      //   this.organizationContactFilter = EntityFilter.Advanced({ contact_lid: person.entityId });
      //   this.reloadOrganizationContacts();
      //   return;
      // }
      message = 'Are you sure you want to add this person to the team?';
      this.teamContactSelected = TeamContact.empty()
        .linkedProperty('team', this.team.entityId);
      if (person instanceof OrganizationContact) {
        // if (person.ifEmailOverride) {
        //   this.teamContactSelected.linkedProperty('organizationcontact', person)
        //     .linkedProperty('organization', person.organization.id);
        // } else {
        //   this.teamContactSelected.linkedProperty('contact', person.contact.id);
        // }
        this.teamContactSelected.linkedProperty('contact', person.contact.id);
      } else if (person instanceof User) {
        this.teamContactSelected.linkedProperty('employees', person);
      } else if (person instanceof Contact) {
        if (!this.contactIdsSelected.includes(person.entityId)) {
          this.contactIdsSelected.push(person.entityId);
          this.contactIdsSelected = [...this.contactIdsSelected];
        }
        this.teamContactSelected.linkedProperty('contact', person);
      }

    } else {
      message = 'Are you sure you want to remove this person from the team?';
    }

    if (showConfirmDialog) {
      this.confirmationService.confirm({
        message,
        header: 'Confirmation',
        icon: 'pi pi-check',
        accept: () => {
          this.addOrRemovePersonInternal(event.checked, person);
        },
        reject: () => {
          //this.addOrRemoveRejectInternal(event.checked, person);
        }
      });
    } else {
      this.addOrRemovePersonInternal(event.checked, person);
    }

  }

  addOrRemoveRejectInternal(checked, person) {
    if (checked) {
      if (person instanceof OrganizationContact) {
        // this.organizationContactIdsSelected = this.organizationContactIdsSelected.filter(oc => oc !== person.entityId);
        this.contactIdsSelected = this.contactIdsSelected.filter(oc => oc !== person.contact.id);
      } else if (person instanceof User) {
        this.userIdsSelected = this.userIdsSelected.filter(oc => oc !== person.entityId);
      } else if (person instanceof Contact) {
        this.contactIdsSelected = this.contactIdsSelected.filter(oc => oc !== person.entityId);
      }
    } else {
      if (person instanceof OrganizationContact) {
        // if (this.organizationSelected) {
        //   this.organizationContactIdsSelected.push(person.entityId);
        //   this.organizationContactIdsSelected = [...this.organizationContactIdsSelected];
        // } else {
        //   this.contactIdsSelected.push(person.entityId);
        //   this.contactIdsSelected = [...this.contactIdsSelected];
        // }
        this.contactIdsSelected.push(person.contact.id);
        this.contactIdsSelected = [...this.contactIdsSelected];
      } else if (person instanceof User) {
        this.userIdsSelected.push(person.entityId);
        this.userIdsSelected = [...this.userIdsSelected];
      } else if (person instanceof Contact) {
        this.contactIdsSelected.push(person.entityId);
        this.contactIdsSelected = [...this.contactIdsSelected];
      }
    }
  }

  addOrRemovePersonInternal(checked, person) {

    if (checked) {
      this.addingOrDeleting = 'Adding';
      this.addingOrDeletingDialogShow = true;

      const userId = person instanceof User ? person.entityId : -1;
      let contactId = person instanceof Contact ? person.entityId : -1;
      contactId = person instanceof OrganizationContact ? person.contact.id : contactId;

      this.teamContactService.checkTeamMemberExist(this.team.entityId, userId, contactId, -1).subscribe(result => {
        if (result) {
          // is creating duplicate team contact
          this.addOrRemoveRejectInternal(checked, person);
          this.duplicateShow = true;
          this.addingOrDeletingDialogShow = false;
        } else {
          this.teamContactService.create(this.teamContactSelected).pipe(first())
            .toPromise()
            .then(async insertId => {
            this.teamContactSelected.entityId = insertId;
            
            this.teamContactIdsSelected.push(insertId);

            if (person instanceof OrganizationContact) {
              const nonMemberIds = this.team.properties.nonmemberorganization ?
                this.team.properties.nonmemberorganization.map(o => o.id) : [];
              if (this.team.properties.organization) {
                const ids = this.team.properties.organization.map(o => o.id);

                if (!ids.includes(person.organization.id) && !nonMemberIds.includes(person.organization.id)) {
                  this.team.properties.organization.push(person.organization);
                }
              } else {
                if (!nonMemberIds.includes(person.organization.id))
                  this.team.properties.organization = [person.organization];
              }
              const result = await this.teamService.updateSinglePropertyOnly(this.team, 'organization').toPromise();
            }
            this.getTeamOrganizations();
            this.addingOrDeletingDialogShow = false;
          });
        }

      });


    } else {

      const teamContactId = person instanceof TeamContact ? person.entityId : -1;
      const userId = person instanceof User ? person.entityId : -1;
      
      let contactId = person instanceof Contact ? person.entityId : -1;
      contactId = person instanceof OrganizationContact ? person.contact.id : contactId;

      this.addingOrDeleting = 'Deleting';
      this.addingOrDeletingDialogShow = true;
      
      if (this.deleteSub) this.deleteSub.unsubscribe();
      this.deleteSub = this.teamContactService.deleteTeamContact(this.team.entityId, teamContactId, userId, contactId).subscribe(result => {
        this.getSelectedTeamContacts();
        this.getTeamOrganizations();

        this.loadAndPaginate({ page: this.currPage });

        this.addingOrDeletingDialogShow = false;
      });
    }
  }

  selectAllUsers(event) {
    this.confirmationService.confirm({
      message: event.checked ? 'Are you sure you want to select all Sagesse Staff/Volunteers for this team?' : 'Are you sure you do not want to select all Sagesse Staff/Volunteers for this team any more?',
      header: 'Confirmation',
      icon: 'pi pi-check',
      accept: () => {
        this.selectAllUsersDialogShow = true;
        this.teamService.updateSinglePropertyOnly(this.team, 'selectallusers').subscribe(result => {
          this.selectAllUsersDialogShow = false;
        });
        // this.teamService.updateSelectAllUsersOnly(this.team).subscribe(result => {
        //   this.selectAllUsersDialogShow = false;
        // });
      },
      reject: () => {
        this.team.properties.selectallusers = !this.team.properties.selectallusers;
      }
    });
  }

  ifNonMember(orgOrTeamContact) {
    if (this.team.properties.nonmemberorganization && this.team.properties.nonmemberorganization instanceof Array) {
      const nonMemberIds = this.team.properties.nonmemberorganization.map(o => o.id);
      if (orgOrTeamContact instanceof Organization)
        return nonMemberIds.includes(orgOrTeamContact.id);
      else if (orgOrTeamContact instanceof TeamContact && orgOrTeamContact.organization)
        return nonMemberIds.includes(orgOrTeamContact.organization.id);
      else if (orgOrTeamContact instanceof TeamContact && orgOrTeamContact.contactEntity) {
        const noOverrideOrgIds = orgOrTeamContact.contactEntity.noOverrideOrgIds
          .filter(id => this.team.properties.organization && this.team.properties.organization.find(o => o.id === id));
        const teamOrgIds = noOverrideOrgIds.filter(id => !nonMemberIds.includes(id));
        const teamNonMemberOrgIds = noOverrideOrgIds.filter(id => nonMemberIds.includes(id));
        return teamNonMemberOrgIds.length > 0 && teamOrgIds.length == 0;
      }
    }
    return false;
  }
}
