<div style="margin-top: 13px;">
  <p-card>
    <div class="header">
      <h2>
        {{outcome.properties.name}}
      </h2>
      <div class="right-button">
        <p-splitButton [model]="outcome.actionMenu" label="Edit" icon="pi pi-pencil"
          (onClick)="showModal('editoutcome', 'Edit Strategic Outcome', outcome)" appendTo="body">
        </p-splitButton>
      </div>
    </div>

    <button style="width:200px; margin-bottom: 10px;" pButton type="button" icon="pi pi-plus" label="Add Sub Category"
      (click)="showModal('addsubcat', 'Add Sub-Category')"></button>
    <p-accordion *ngFor="let subcat of subcategories">

        <sagesse-strategic-outcome-statistic [subcategory]="subcat"> </sagesse-strategic-outcome-statistic>

    </p-accordion>

  </p-card>
</div>

<p-dialog [header]="modalText" [(visible)]="showModalFlag" [appendTo]="'body'" [modal]="true" [draggable]="false"
  [baseZIndex]="10000" [dismissableMask]="true">
  <div class="modal-content">
    <ripple-entity-details [entity]="toEdit" [hideButtons]="true" [generatorOptions]="generatorOptions">
    </ripple-entity-details>
    <button pButton type="button" icon="pi pi-{{adding ? 'spin pi-spinner' : 'plus'}}" [label]="modalText"
      (click)="addOutcome()"></button>
    <!-- [disabled]="statName == ''" -->
  </div>
</p-dialog>
