<div class="pane" *ngIf="agecnyStatsAdmin">
  <div class="pane__header pane__header--sticky bg-white">
    <div class="header">
      <h1 class="header__heading">Strategic Plan Builder </h1>
      <div class="header__actions">
        <!-- <p-splitButton [model]="actionMenu" label="Save" icon="pi pi-{{loading ? 'spin pi-spinner' : 'check'}}"
          (onClick)="save()" appendTo="body">
        </p-splitButton> -->
        <button pButton icon="pi pi-plus" label="Add Strategic Outcome" (click)="showAddOutcome()"> </button>
      </div>
    </div>
  </div>
  <div class="pane__body">
    <div *ngFor="let strategicOutcome of strategicOutcomes">
      <sagesse-strategic-outcome [outcome]="strategicOutcome"> </sagesse-strategic-outcome>
    </div>
  </div>
</div>

<div *ngIf="!agecnyStatsAdmin">
  <p>Must be an admin to access strategic plan builder.</p>
</div>



<p-dialog header="Add Strategic Outcome" [(visible)]="showAddOutcomeFlag" [appendTo]="'body'" class="group-modal" [modal]="true"
  [draggable]="false" [baseZIndex]="10000" [dismissableMask]="true">
  <div class="modal-content" style="width: 350px;">
    <ripple-entity-details [entity]="outcome" [hideButtons]="true" [generatorOptions]="generatorOptions">
    </ripple-entity-details>
    <button pButton type="button" icon="pi pi-{{addingOutcome ? 'spin pi-spinner' : 'plus'}}" label="Add Outcome"
      (click)="addOutcome()"></button>
  </div>
</p-dialog>
