import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService, LayoutSettingsService, LayoutSetting, MenuService, InternalCookieService, GraphApiService } from '@ripple/services';
import { MenuItem, Message } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@ripple/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sagesse-srm';
  location: Location;

  userMenuItems: MenuItem[] = [
    { command: () => this.authService.logout(window.location.pathname), label: 'Log Out', icon: 'pi pi-sign-out' },
  ];
  sections: MenuItem[] = null;
  currentView = 'Home';
  menuItems: MenuItem[] = [
    {
      icon: 'pi pi-sitemap',
      label: 'Organizations',
      routerLink: '/organization'
    },
    {
      icon: 'pi pi-user',
      label: 'Contacts',
      routerLink: '/contact'
    },
    {
      icon: 'pi pi-users',
      label: 'Teams',
      routerLink: '/team'
    },
    {
      icon: 'pi pi-briefcase',
      label: 'Clare\'s Law',
      routerLink: '/claresLaw',
      items: [
        {
          icon: 'pi pi-briefcase',
          label: 'Clare\'s Law',
          routerLink: '/claresLaw'
        },
        {
          icon: 'pi pi-briefcase',
          label: 'Clare\'s Law Report',
          routerLink: '/claresLawReport'
        }
      ]
    },
    {
      icon: 'pi pi-globe',
      label: 'Admin',
      routerLink: '/admin',
      items: [
        {
          icon: 'pi pi-id-card',
          label: 'Contact Roles',
          routerLink: '/contactRole',
        },
        {
          icon: 'pi pi-map-marker',
          label: 'Communities',
          routerLink: '/community',
        },
        {
          icon: 'pi pi-map-marker',
          label: 'Community Groups',
          routerLink: '/communityGroup',
        },
        {
          icon: 'pi pi-tags',
          label: 'Tags',
          routerLink: '/organizationTag',
        },
        {
          icon: 'pi pi-palette',
          label: 'Form builder',
          routerLink: '/admin/formbuilder',
        },
        {
          icon: 'pi pi-id-card',
          label: 'Templates',
          routerLink: '/teamType',
        },
        {
          icon: 'pi pi-id-card',
          label: 'Team Contact Role Types',
          routerLink: '/teamContactRoleType',
        },
      ]
    },
    {
      icon: 'pi pi-folder',
      label: 'Agency Stats',
      routerLink: '/admin',
      items: [
        {
          icon: 'pi pi-check-square',
          label: 'Enter Stats',
          routerLink: '/enterstats'
        },
        {
          icon: 'pi pi-check-square',
          label: 'Program Statistics Viewer', // Previously: Manage Program Stats
          routerLink: '/manageprogramstats'
        },
        {
          icon: 'pi pi-file-o',
          label: 'Strategic Plan Viewer',
          routerLink: '/strategicplanviewer'
        },
        {
          icon: 'pi pi-folder-open',
          label: 'Program Builder', // Previously: Programs
          routerLink: '/programs'
        },
        {
          icon: 'pi pi-folder-open',
          label: 'Strategic Plan Builder',
          routerLink: '/strategicplanbuilder'
        },
        {
          icon: 'pi pi-file-o',
          label: 'Individual Statistics Report',
          routerLink: '/individualstatisticreports'
        },
        {
          icon: 'pi pi-file-o',
          label: 'Evaluation Outcome Reports',
          routerLink: '/evaluation-outcome-reports'
        },
      ]
    }
  ];

  layoutSettingSubject: BehaviorSubject<LayoutSetting>;
  layoutSettings: LayoutSetting;

  staging = environment['staging'];

  msgs: Message[];

  extraNeedHelps: { label: string, icon: string, link: string, newTab: boolean }[] = [];

  constructor(
    private authService: AuthService,
    public layoutSettingsService: LayoutSettingsService,
    protected menuService: MenuService,
    public cookieService: InternalCookieService,
    public graphApiService: GraphApiService,
    public route: ActivatedRoute,
  ) {
    this.layoutSettingSubject = this.layoutSettingsService.getSettingsSubject();
    this.layoutSettingSubject.subscribe(layoutSettings => {
      this.layoutSettings = layoutSettings;
    });
    const layoutSettings = this.layoutSettingsService.getSettings();
    layoutSettings['showClaresLawReferralBar'] = true;
    this.layoutSettingsService.setSettings(layoutSettings);
    menuService.setServiceDeskURL("https://ripplegroupcanada.atlassian.net/servicedesk/customer/portal/3");
    this.menuService.setMenu(this.menuItems);
    // this.graphApiService.getUserDrive().subscribe(result => {
    //   this.userMenuItems = [
    //     { command: () => this.goToExternalLink(result['webUrl']), label: 'Open OneDrive', icon: 'pi pi-external-link' },
    //     ...this.userMenuItems];
    // });

    this.route.queryParams.subscribe((params) => {
      if (params.msg) {
        this.msgs = [
          { severity:'success', summary:'Success', detail:params.msg }
        ];
      } else {
        this.msgs = [];
      }
    });

    this.extraNeedHelps = [
      {
        label: 'SRM Manual',
        link: '/assets/pdfs/SRM%20Manual.pdf',
        icon: 'pi pi-file',
        newTab: true
      }
    ];
  }

  ngOnInit() {
    if (!window.location.host.includes('localhost')) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

  public hideAllContent(): boolean {
    return this.authService.hideAllContent;
  }

  goToExternalLink(link) {
    window.open(link, '_blank');
  }

}
