import { Component } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';

@Component({
  selector: 'ripple-form-multiselect',
  template: `
    <p-multiSelect
        [options]="to.options"
        [formControl]="formControl"
        [formlyAttributes]="field"
        appendTo='body'
        [baseZIndex]="99999999"
        filter="true"
        [readonly]="viewOnly"
    >
    </p-multiSelect>
  `,
})
export class FormMultiselectComponent extends RippleFieldGenericComponent {
}
