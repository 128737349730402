import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { environment } from '@ripple/environment';
import { IWarpEntityInputObject, WarpEntity } from '@ripple/models';

@Injectable({
  providedIn: 'root',
})
export class ImporterService {

  public constructor(
    public http: HttpClient,
  ) {
  }

  readXLSX(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const arrayBuffer = reader.result as ArrayBuffer;
          const data = new Uint8Array(arrayBuffer);
          const arr = new Array();
          for(let i = 0; i < data.length; i++) arr[i] = String.fromCharCode(data[i]);
          const bstr = arr.join('');
          const workbook = XLSX.read(bstr, { type:"binary", cellDates: true });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const result = {
            headers: this.getHeaderRow(sheet),
            contents: XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
          };
          resolve(result);
        } catch(error) {
          reject(error);
        }  
      };
      reader.readAsArrayBuffer(file);
    });
   
  }

  getHeaderRow(sheet) {
    const headers = [];
    const range = XLSX.utils.decode_range(sheet['!ref']);
    let C, R = range.s.r; /* start in the first row */
    /* walk every column in the range */
    for(C = range.s.c; C <= range.e.c; C++) {
        var cell = sheet[XLSX.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

        var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
        if(cell && cell.t) hdr = XLSX.utils.format_cell(cell);

        headers.push(hdr);
    }
    return headers;
  }

  public import(body): Promise<any> {
    return this.http.post(environment.restEndpointUrl + '/api/importer', body).toPromise();
  }

  public getLastMapping(entityTypeID): Promise<WarpEntity> {
    return this.http.get<IWarpEntityInputObject>(environment.restEndpointUrl + '/api/importer/getLastMapping/' + entityTypeID)
      .toPromise().then(result => result ? new WarpEntity(result) : null);
  }
}
