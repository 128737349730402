import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasePage } from '@ripple/core';
import { FormBuilderComponent, FormBuilderService } from '@ripple/formbuilder';
import { WarpEntityLogService } from '@ripple/services';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'sagesse-form-builder-details',
  templateUrl: './form-builder-details.component.html',
  styleUrls: ['./form-builder-details.component.scss']
})
export class FormBuilderDetailsComponent extends BasePage implements OnInit {
  showControlText = false;
  formid = new BehaviorSubject<number>(-1);
  @ViewChild(FormBuilderComponent) fb: FormBuilderComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formService: FormBuilderService,
    private confirm: ConfirmationService,
    private logService: WarpEntityLogService,
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const id = parseInt(params.formId, 10);
      this.formid.next(isNaN(id) ? -1 : id);

      setTimeout(() => this.fb.formEntity.toPromise().then(type => this.logService.logView([], `Opened ${type.name} Form for editing (FormBuilder)`)), 500);
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  canDeactivate() {
    return !this.fb || !this.fb.activeChanges;
  }

  remove() {
    this.confirm.confirm({
      message: 'Are You sure you want to Delete this form?',
      accept: () => this.formService.deleteForm(this.fb._formID).subscribe(_ => this.router.navigate(['..'], { relativeTo: this.route }))
    });
  }

}
