import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

export class CommunityGroup extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.CommunityGroup;
    }

    static empty(): CommunityGroup {
        return new CommunityGroup(super.emptyEntity(EntityTypes.CommunityGroup));
    }

}