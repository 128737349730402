import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Observable,
  ReplaySubject,
  AsyncSubject,
  forkJoin,
  from,
} from 'rxjs';
import { AuthService, MessageService } from '../general';
import {
  WarpEntityType,
  WarpEntity,
  IEntityPage,
  IWarpEntityInputObject,
  EntityFilter,
  SignalRAction,
  IGenericObject,
  EntityNote,
} from '@ripple/models';
import { RippleApiService } from '../ripple-api/ripple-api.service';
import { Guid } from 'guid-typescript';
import { map, first, exhaustMap, retryWhen } from 'rxjs/operators';
import {
  Hub,
  HubService,
  HubWrapper,
  HubSubscription,
} from 'ngx-signalr-hubservice';
import { environment } from '@ripple/environment';
import { HubConnectionService } from '../hubs/hub-connection.service';
import { genericRetryStrategy } from '../utilities';

interface MultiSyncObject {
    sync?: 'post' | 'put' | 'patch' | 'delete';
    type?: WarpEntityType;
    entity: WarpEntity;
}

@Injectable({
  providedIn: 'root',
})
@Hub({ hubName: 'EntityStructureHub' })
export class GenericWarpEntityService extends RippleApiService {
  protected serviceName = 'GenericWarpEntityService';

  public signalRConnected = new AsyncSubject<boolean>();
  private hubWrapper: HubWrapper;
  private hubService: HubService;

  private _entityTypeStore = new Map<number, ReplaySubject<WarpEntityType>>();
  private any = 9999; // any entity type

  constructor(
    protected injector: Injector,
    hubConnectionService: HubConnectionService
  ) {
    super(
      injector.get(HttpClient),
      injector.get(MessageService),
      injector.get(AuthService)
    );
    this.hubService = hubConnectionService.getHubService();
    this.log('Starting');
    // this.hubConnectionService.connectToHubService().then(success => {
    //     if (success) {
    //       this.log('Connected - Stucture', success);
    //       this.hubWrapper = this.hubService.register(this);
    //       this.subscribeToServer();
    //     }
    //   }).catch( err => {
    //     console.error(err);
    //   });
    hubConnectionService.connectionChanged.subscribe((connectionState) => {
      this.log( 'Connected - Structure', connectionState );
      if (connectionState) {
        if (this.hubWrapper)
          this.hubWrapper.unregister();
        this.hubWrapper = hubConnectionService.getHubService().register(this);
        this.subscribeToServer();
      }
    });
  }

  private subscribeToServer() {
    this.hubWrapper
      .invoke('subscribe', environment.subscriberId)
      .pipe(
        retryWhen(genericRetryStrategy({
          maxRetryAttempts: 10,
          scalingDuration: 2000,
          errorString: `Error Subscribing to Entity Structure Hub`,
          log: (...a) => this.log(...a)
        }))
      )
      .toPromise()
      .then(() => {
        this.log('Registered - Structure');
        this.signalRConnected.next(true);
        this.signalRConnected.complete();
      })
      .catch( err => this.log('Error on Subscribe to Structure', err));
  }

  @HubSubscription('receiveMessage')
  private handleEntityTypeUpdate(entityTypeId: string, message: string) {
    const id = parseInt(entityTypeId, 10);
    const action = message as SignalRAction;
    this.log( 'UPDATED - Stucture', entityTypeId );
    if (this._entityTypeStore.has(id))
      super
        ._get(
          `/api/formbuilder/${entityTypeId}`,
          `Get Entity Structure after update ${entityTypeId}`
        )
        .pipe(
          first(),
          map((ent) => new WarpEntityType(ent, this.authService.getLoggedInUser()))
        )
        .subscribe(this._entityTypeStore.get(id));
  }

  protected mapToWE() {
    return map((v: IWarpEntityInputObject[]) =>
      v.map((el) => new WarpEntity(el))
    );
  }

  /**
   * Gets all entities of type
   * @param entityTypeId Entity Type ID
   */
  getAll(entityTypeId: number): Observable<WarpEntity[]> {
    return super
      ._get(`/api/v2/entityTypes/${entityTypeId}/?loadChildren=-1`, `GetAll ${entityTypeId}`)
      .pipe(this.mapToWE());
  }

  /**
   * Get all entities of a type, that satisfy a filter
   * @param entityTypeId Entity Type ID
   * @param filter querystring representation of a filter
   * @param matchAll to use AND or OR logic for the filter
   * @param otherParams custom querystring parameters
   */
  // tslint:disable-next-line: no-shadowed-variable
  getAllWithFilter(
    entityTypeId: number,
    filter: string,
    matchAll: boolean = true,
    otherParams: string = null
  ): Observable<WarpEntity[]> {
    otherParams = otherParams || `&${otherParams}`;
    return super
      ._get(
        `/api/v2/entityTypes/${entityTypeId}/?matchAll=${matchAll}&advancedFilter=${filter}${otherParams}`,
        `GetAllFiltered ${entityTypeId}`
      )
      .pipe(this.mapToWE());
  }

  /**
   * Get an entity of any type by it's entity ID
   * @param entityID Entity ID
   */
  get(entityID: number | string, entityFilter?: EntityFilter, entityTypeId?: number): Observable<WarpEntity> {
    /*    entityID is a Guid    */
    let queryString = typeof entityID === 'string' ? '' : `?${entityFilter ? entityFilter.simple  : 'loadChildren=-1' }`; // by guid
    if (queryString.indexOf('loadChildren') === -1)
    if (queryString.indexOf('?') === -1)
      queryString +=  '?loadChildren=-1';
      else
      queryString +=  '&loadChildren=-1';
    return super
      ._get(
        `/api/v2/entityTypes/${entityTypeId || this.any}/${entityID}/${queryString}`,
        `Get ${entityID}`
      )
      .pipe(map((w) => new WarpEntity(w)));
  }

  /**
   * Get a Subset of entities of type matching a filter.
   * Each page will also include the last entity of the previous page
   * @param entityTypeId Entity Type ID
   * @param page Page Number
   * @param pageSize Size of the Page
   * @param filter use this one
   * @param sendOnlyIds get an array of ids, instead of entities with labels
   */
  getPage(
    entityTypeId: number,
    page: number,
    pageSize: number,
    filter: EntityFilter,
    sendOnlyIds?: boolean,
  ): Observable<IEntityPage> {
    const options = filter.backendPagingOptions;
    options.pagingOptions.EntityTypeId = entityTypeId;
    options.pagingOptions.PageIndex = page;
    options.pagingOptions.PageSize = pageSize;

    if (sendOnlyIds !== undefined)
      options.pagingOptions.sendOnlyIds = sendOnlyIds || false;

    return super._post(
      `/api/v2/entityTypes/page/${entityTypeId}/?${filter.paging}`, options,
      `Get<post> ${entityTypeId} Page ${page}`
    );
  }

  /** @deprecated Use getEntityStructure instead. */
  getStructure(
    subscriberId: number,
    entityTypeId: number,
    templateId: number
  ): Observable<object> {
    const reqObj = { subscriberId, entityTypeId, templateId };
    return super._post(
      '/api/TemplateBuilder/TemplateBuilder/LoadTemplate',
      reqObj,
      `GetStructure ${entityTypeId}`
    );
  }

  /**
   * Gets an entity type with all custom field information, and frontend templates if they exist
   * @param entityTypeId Entity Type ID
   */
  getEntityStructure(entityTypeId: number): Observable<WarpEntityType> {
    if (!this._entityTypeStore.has(entityTypeId)) {
      const rs = new ReplaySubject<WarpEntityType>(1);
      this._entityTypeStore.set(entityTypeId, rs);
      super
        ._get(
          `/api/formbuilder/${entityTypeId}`,
          `Get Entity Structure ${entityTypeId}`
        )
        .pipe(
          first(),
          map((ent) => new WarpEntityType(ent, this.authService.getLoggedInUser()))
        )
        .subscribe(rs);
    }

    return this._entityTypeStore.get(entityTypeId);
  }

  /** Updates existing entities
   * @param type WarpEntityType for ensuring all new properties are saved
   * @param items WarpEntity compatible object array, call will fail if entityId is not included in every item
   */
  entitySyncUpdate(
    type: WarpEntityType,
    items: (WarpEntity | object)[],
    overwrite?: boolean
  ): Observable<WarpEntity[]>;
  /** Updates existing entities
   * @param items WarpEntity compatible object or array, call will fail if entityId is not included in every item
   */
  entitySyncUpdate(
    items: (WarpEntity | object) | (WarpEntity | object)[],
    overwrite?: boolean
  ): Observable<WarpEntity[]>;
  entitySyncUpdate(
    typeOrItems:
      | WarpEntityType
      | (WarpEntity | object)
      | (WarpEntity | object)[],
    items?: (WarpEntity | object)[] | boolean,
    overwrite?: boolean
  ): Observable<WarpEntity[]> {
    if (typeof items === 'boolean') overwrite = items;
    if (!items || typeof items === 'boolean') items = [];
    const type =
      typeOrItems instanceof WarpEntityType ? typeOrItems : undefined;
    if (typeOrItems instanceof Array) items = typeOrItems;
    else if (!(typeOrItems instanceof WarpEntityType)) items = [typeOrItems];

    if (overwrite === undefined) overwrite = false;

    const toSend = items
      .map((obj: WarpEntity | object) => ({
        type: overwrite ? 'put' : 'patch',
        entity: obj instanceof WarpEntity ? obj.getSyncObject(type) : obj,
      }))
      .filter((o) => o.entity);
    return super
      ._post('/api/v2/entitySync/', toSend, 'Update')
      .pipe(this.mapToWE(), first());
  }

  /** Creates new entities
   * @param items WarpEntity array
   */
  create(type: WarpEntityType, items: WarpEntity[]): Observable<WarpEntity[]>;
  /** Creates new entities
   * @param items WarpEntity or array
   */
  create(items?: WarpEntity | WarpEntity[]): Observable<WarpEntity[]>;
  create(
    typeOrItems: WarpEntityType | WarpEntity | WarpEntity[],
    items?: WarpEntity[]
  ): Observable<WarpEntity[]> {
    if (!items) items = [];
    const type =
      typeOrItems instanceof WarpEntityType ? typeOrItems : undefined;
    if (typeOrItems instanceof Array) items = typeOrItems;
    else if (typeOrItems instanceof WarpEntity) items = [typeOrItems];

    const toSend = items
      .map((obj) => ({
        type: 'post',
        entity: {
          offlineguid: obj.offlineGuid || Guid.create().toString(),
          ...obj.getSyncObject(type),
        },
      }))
      .filter((o) => o.entity);
    return super
      ._post('/api/v2/entitySync/', toSend, 'Create')
      .pipe(this.mapToWE());
  }

  /**
   * EntitySync for multiple entities of different types, with possibly different actions
   *
   * sync operation will default to `post` (create) unless the object has an id, then op will be `patch`
   *
   * `sync` can be any of `post` | `put` | `patch` | `delete`
   *  - `post` - create an object
   *  - `put` - update an object and delete any old data not included in this request
   *  - `patch` - update only the included properties of an object, leaving old data
   *  - `delete` - sets the deleted flag in DB
   * @param items array of update objects containing `sync` (operation), `type` (entityType), and `entity` (data)
   */
  syncWithChildren(
    ...items: MultiSyncObject[]
  ) {
    const getChildren = items
      .filter( o => o.entity )
      .map( item => this.handleSaveChildrenEntities(item.entity)
        .pipe(first(), map( children => {
          this.log(`Children for entity to be saved ${item.entity}, [${children.length}]`, item, children);
          return { children, parent: item };
        }) ) );

    this.log(`Syncing ${getChildren.length} entities with children`, getChildren);

    if (getChildren.length === 0)
      return from([[] as WarpEntity[]]);

    return forkJoin(getChildren).pipe(
      first(),
      map( itemsWithChildren => {
        const all = itemsWithChildren.reduce( (_all, item) => {
          _all.children.splice(_all.children.length, 0, ...item.children);
          _all.parents.push(item.parent);
          return _all;
        }, { children: [] as MultiSyncObject[], parents: [] as MultiSyncObject[] });
        return all.children.concat(all.parents);
      }),
      exhaustMap( toSend => this._syncWithChildren(...toSend) ));
  }

  private _syncWithChildren(
    ...items: MultiSyncObject[]
  ) {
    const toSend = items
      .filter( o => o && o.entity)
      .map((obj) => ({
        type:
          obj.sync ||
          (typeof obj.entity.guaranteedId === 'number' ? 'patch' : 'post'),
        entity: {
          offlineguid: obj.entity.offlineGuid || Guid.create().toString(),
          ...obj.entity.getSyncObject(obj.type),
        },
      }));

    if (toSend.length === 0)
      return from([[] as WarpEntity[]]);

    return super
      ._post('/api/v2/entitySync/', toSend, 'Sync Multiple')
      .pipe(first(), this.mapToWE());
  }

  /**
   * Removes existing entities
   * @param items objects containing ids to be deleted, call will fail if `entityId` or `id` is not included in every item
   */
  delete(...items: (WarpEntity | object)[]): Promise<void>;
  delete(items: (WarpEntity | object)[]): Promise<void>;
  delete(items: (WarpEntity | object)[]): Promise<void> {
    const toSend = items
      .map((obj) => ({
        type: 'delete',
        entity: obj instanceof WarpEntity ? { id: obj.entityId } : obj,
      }))
      .filter((o) => o.entity);
    return super
      ._post('/api/v2/entitySync/', toSend, 'Delete')
      .pipe(first())
      .toPromise();
  }

  destroy(...items: (WarpEntity | number)[]): Promise<boolean> {
    const entities = [];
    for (const item of items)
      if (item instanceof WarpEntity)
        entities.push(item.entityId);
      else
        entities.push(item);

    const toSend = { entities };
    return super._post('/api/destroy/ids/', toSend)
      .pipe(first())
      .toPromise();
  }

  // tslint:disable-next-line: no-any
  protected log(...args: any[]) {
    this.messageService.add(this.serviceName, ...args);
  }

  /**
   * Automatically check for and save connected entities that have been initialized with `WarpEntity.initChildrenList(...)`
   *
   * Note: this saves and links the children to the parent,
   * but only links the parent to the children locally, **the parent must be saved after this call**
   */
  private handleSaveChildrenEntities(parentEntity: WarpEntity): Observable<MultiSyncObject[]> {
    const getEntityWithProps = (entityTypeId: number, ent: { properties: IGenericObject, id?: number, entityId?: number} ) => {
      const entity = WarpEntity.emptyEntity(entityTypeId);

      Object.keys(ent.properties).forEach(prop => entity.properties[prop] = ent.properties[prop]);
      entity.id = ent.id || ent.entityId;
      entity.entityId = ent.entityId || ent.id;
      return new WarpEntity(entity, false);
    };

    this.log(`Checking for Child Entities for ${parentEntity}`, parentEntity, /*MessageService.VERBOSE*/);

    type ChildEntitiesObject = { key: string, entityTypeId: number, linkBackCfim?: string, entities: WarpEntity[] };

    const childProps = Object.keys(parentEntity.properties)
      .filter( k => WarpEntity.isChildrenKey(k))
      .map( k => parentEntity.properties[k] as ChildEntitiesObject)
      .filter( ({ entityTypeId, entities }) => (entities && entities.length && entityTypeId) )
      .map( childrenObject => {
        this.log(`Getting children Type ${parentEntity}, ${childrenObject.key}`, childrenObject, /*MessageService.VERBOSE*/);
        return this.getEntityStructure(childrenObject.entityTypeId).pipe(first(), map( type => ({ type, ...childrenObject})));
      });

    if (!childProps.length)
      return from([[] as MultiSyncObject[]]);

    return forkJoin(childProps)
      .pipe(
        map( childrenWithTypes => {
          this.log(`Child Entities With Types for ${parentEntity} [${childrenWithTypes.length}]`,
            childrenWithTypes, /*MessageService.VERBOSE*/);

          const children = childrenWithTypes.reduce(
            (out, { type, key, entityTypeId, linkBackCfim, entities }) => {
              this.log(`Child Entities for ${parentEntity}, ${key} [${entities.length}]`, entities, /*MessageService.VERBOSE*/);

              // convert and link children
              const childrenToSave = entities.map((child: WarpEntity | { properties: IGenericObject }) => {
                const entity = child instanceof WarpEntity ? child : getEntityWithProps(entityTypeId, child);

                if (linkBackCfim)
                  entity.appendLinkedProperty(linkBackCfim, parentEntity.entityId); // don't remove other parents

                return { type, entity };
              }) as { type: WarpEntityType, entity: WarpEntity }[];

              // make sure these are in the parent entity's properties
              parentEntity.linkedProperty(key, ...childrenToSave.map(o => o.entity) );

              // return the sync objects in one big array
              return out.concat(childrenToSave);
            }, [] as MultiSyncObject[]);

          this.log(
            `Child Entities to Save for ${parentEntity} [${childrenWithTypes.length}] => [${children.length}]`,
            childrenWithTypes,
            children);

          return children;
        })
      );
  }
}
//     childProps
//       .forEach((childrenObj) => {
//         // get entityType for children,
//         const { key, entityTypeId, linkBackCfim, entities } = childrenObj;

//         this.log(`has children entities ${childrenObj.key}`, childrenObj, (!entities || !entities.length || !entityTypeId));

//         if (!entities || !entities.length || !entityTypeId)
//           return;

//         this.getEntityStructure(entityTypeId)
//           .pipe(first(), map(type => {
//             // convert and link children
//             const childrenToSave = entities.map(child => {
//               const entity = getEntityWithProps(entityTypeId, child.properties);

//               if (linkBackCfim)
//                 entity.appendLinkedProperty(linkBackCfim, parentEntity.entityId); // don't remove other parents

//               return { type, entity };
//             }) as { type: WarpEntityType, entity: WarpEntity }[];

//             // make sure these are in the parent entity's properties
//             parentEntity.appendLinkedProperty(key, ...childrenToSave.map(o => o.entity) );

//             // the children getting created before the parent is saved means the offlineGuid isn't going to work
//             // make a system for them to be saved together

//             // save
//             this.syncWithChildren(...childrenToSave)
//               .toPromise()
//               .then( savedEntities => {
//                 this.log(`Saved ${savedEntities.length} children entities`, savedEntities);
//               });
//           }))
//           .toPromise()
//           .then( )
//           .catch( err => {
//             this.log(`Error saving children entities: ${err}`, err, MessageService.ERROR);
//           });
//       });
//   }
// }
