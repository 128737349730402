
import { Component, OnInit, Input } from '@angular/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { EntityTypes, Program, StatisticGroup, Statistic } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions, SubscriptionContainer } from '@ripple/models';
import { first, map, switchMap, startWith, tap, distinctUntilChanged } from 'rxjs/operators';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';




@Component({
  selector: 'sagesse-satistic-group',
  templateUrl: './satistic-group.component.html',
  styleUrls: ['./satistic-group.component.scss']
})
export class SatisticGroupComponent extends SubscriptionContainer implements OnInit {
  programService: WarpEntityServiceCache<Program>;
  statisticGroupService: WarpEntityServiceCache<StatisticGroup>;
  statisticService: WarpEntityServiceCache<Statistic>;

  @Input() group: StatisticGroup;
  name: string;
  stats: Statistic[] = [];
  loading: boolean = false;
  showModalFlag: boolean = false;
  adding: boolean = false;
  toEdit: any;
  modalText: string = "";
  modalButtonIcon: string = "plus";
  modalButtonText: string = "Save";
  action: string = "";
  loadingStats: boolean = false;

  generatorOptions: IGenFormlyOptions ;


  generatorOptionsStat: IGenFormlyOptions = {
    hiddenCondition:
      (cfim: CustomFieldInModule) =>
        cfim.key.toLowerCase() === 'linkedstatisticgroup' ||
        cfim.key.toLowerCase() === 'orderindex',
  };

  generatorOptionsGroup: IGenFormlyOptions = {
    hiddenCondition:
      (cfim: CustomFieldInModule) =>
        cfim.key.toLowerCase() === 'linkedprogram',
    styles: {
      name: 'p-sm-12 p-md-12 p-lg-12 ui-fluid'
    }
  };


  constructor(
    private factory: WarpEntityCacheFactoryService,
  ) {
    super();
    this.programService = factory.get(EntityTypes.Program) as WarpEntityServiceCache<Program>;
    this.statisticGroupService = factory.get(EntityTypes.StatisticGroup) as WarpEntityServiceCache<StatisticGroup>;
    this.statisticService = factory.get(EntityTypes.Statistic) as WarpEntityServiceCache<Statistic>;


  }

  ngOnInit(): void {
    if (this.group != null) {
      console.log("group worked", this.group)
    }
    this.name = this.group.property("name").toString();

    this.group.actionMenu = new Array<MenuItem>();
    this.group.actionMenu.push({
      label: 'Delete',
      icon: 'pi pi-times',
      command: () => this.delete(this.group),
    });

    this.loadingStats = true;
    const filter = EntityFilter.Advanced({ LinkedStatisticGroup_lid: this.group.id });
    this.nextSub = this.statisticService.initQuery(filter, 9999999).getPage(0).subscribe(resp => {
      this.stats = resp;

      this.stats.forEach(r => {
        r.actionMenu = new Array<MenuItem>();
        r.actionMenu.push({
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => this.delete(r),
        });
      });

      this.sortStats();
      this.loadingStats = false;

    });
  }

  showModal(action: string, text, toEditStat = null) {
    console.log(action);
    switch (action) {
      case 'add':
        this.toEdit = Statistic.empty().linkedProperty("linkedstatisticgroup", this.group);
        this.modalText = text;
        this.modalButtonIcon = 'plus';
        this.modalButtonText = "Add Statistic";
        this.generatorOptions = this.generatorOptionsStat;
        break;
      case 'edit':
        this.toEdit = toEditStat;
        this.modalText = text;
        this.modalButtonIcon = 'save';
        this.modalButtonText = "Save Statistic";
        this.generatorOptions = this.generatorOptionsStat;
        break;
      case 'editgroup':
        this.toEdit = toEditStat;
        this.modalText = text;
        this.modalButtonIcon = 'save';
        this.modalButtonText = "Save Group";
        this.generatorOptions = this.generatorOptionsGroup;
        this.action = 'group';
        break;
    }
    console.log("this is the toedit", this.toEdit);
    this.showModalFlag = true;
  }

  addStat() {
    this.adding = true;
    if (this.action === "group") {
      this.statisticGroupService.update(this.toEdit).toPromise().then((entId) => {
        this.adding = false;
        this.toEdit = null;
        this.showModalFlag = false;
      });
    } else {
      this.statisticService.create(this.toEdit).toPromise().then((entId) => {
        this.adding = false;
        this.toEdit = null;
        this.showModalFlag = false;
      });
    }
  }

  delete(toEdit) {
    toEdit.deleting = true;
    this.programService.delete(toEdit).then(resp => {
      console.log('Deleted toEdit', resp);
    });
  }

  reorder(e) {
    if (e.dropIndex === 0) {
      this.stats[e.dropIndex].properties.orderindex = (parseFloat(this.stats[0].properties.orderindex) - 1.00).toPrecision(4);
    }
    else if (e.dropIndex === this.stats.length - 1) {
      this.stats[e.dropIndex].properties.orderindex = (parseFloat(this.stats[this.stats.length - 1].properties.orderindex) + 1.00).toPrecision(4);
    }
    else {
      this.stats[e.dropIndex].properties.orderindex = ((this.stats[e.dropIndex + 1].properties.orderindex + this.stats[e.dropIndex - 1].properties.orderindex) / 2.0000000).toPrecision(4);
    }

    this.nextSub = this.statisticService.update(this.stats[e.dropIndex]).subscribe(resp => {
      console.log('Index Updated', resp)
    });

    this.sortStats();
  }

  sortStats() {
    this.stats = this.stats.sort((a, b) => {
      if (a.properties.orderindex > b.properties.orderindex)
        return 1;
      else if (a.properties.orderindex < b.properties.orderindex)
        return -1;
      else
        return 0;
    });
  }

}
