<ripple-scroll-card class="h-100">
    <div class="scroll-card-header">
        <div class="header header--pad header--bordered">
            <div class="header__heading">
                <p-breadcrumb [model]="breadCrumbItems"></p-breadcrumb>
            </div>
            
            <div class="header__actions">
                <button pButton
                    class="ui-button-secondary" icon="pi pi-plus" label="New Folder" (click)="folderDialogShow = true">
                </button>
                <p-fileUpload #fileUpload mode="basic" name="upload[]" chooseIcon="pi pi-cloud-upload"
                    styleClass="ui-button-secondary file-upload-buttton"
                    customUpload="true" (uploadHandler)="uploadFile($event)"
                    (onUpload)="uploadFile($event)" [auto]="true" chooseLabel="Upload"></p-fileUpload>
            </div>
        </div>
    </div>
    <ripple-loading-box [loading]="loading" [fullHeight]="true">
        <p-messages [(value)]="msgs" [enableService]="false"></p-messages>
        <p-table [value]="folderContents" class="folder-items-table">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width: 2rem; text-align: center;"><i class="pi pi-file"></i></th>
                    <th>Name</th>
                    <th>Modified</th>
                    <th>Modified By</th>
                    <th style="text-align: center;">SharePoint Link</th>
                    <th style="text-align: center;">Delete</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
                <tr>
                    <td style="width: 2rem; text-align: center;">
                        <!-- <i *ngIf="!item.thumbnails || item.thumbnails && item.thumbnails.length == 0"
                            class="{{ item.folder ? 'pi pi-folder' : 'pi pi-file' }}"></i>
                        <span *ngIf="item.thumbnails && item.thumbnails.length > 0">
                            <img src="{{item.thumbnails[0].small.url}}" />
                        </span> -->
                        <i class="{{ item.folder ? 'pi pi-folder' : 'pi pi-file' }}"></i>
                    </td>
                    <td>
                        <a href="javascript:;" (click)="item.folder ? getItemChildren(item) : goToSharePoint(item)">{{item.name}}</a></td>
                    <td>{{getLocalTime(item.lastModifiedDateTime)}}</td>
                    <td>{{item.lastModifiedBy?.user?.displayName}}</td>
                    <td style="text-align: center;">
                        <button pButton icon="pi pi-external-link" (click)="goToSharePoint(item)"></button>
                    </td>
                    <td style="text-align: center;">
                        <button pButton icon="pi pi-trash" (click)="deleteItem(item)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6" class="empty-message-col">No Items.</td>
                </tr>
            </ng-template>
        </p-table>
    </ripple-loading-box>
</ripple-scroll-card>

<p-dialog header="Create a New Folder"
    [(visible)]="folderDialogShow"
    *ngIf="folderDialogShow"
    [style]="{width: '50vw'}"
    contentStyleClass="h-100"
    [baseZIndex]="10000">
    <input type="text" class="w-100" pInputText placeholder="Enter your folder name" [(ngModel)]="folderName"/>
    <p-footer>
        <button pButton
            class="ui-button-secondary" icon="pi pi-plus" label="Create" (click)="addFolder()">
        </button>
    </p-footer>
</p-dialog>

<p-dialog header="Uploading..."
    [(visible)]="fileUploading"
    *ngIf="fileUploading"
    contentStyleClass="h-100"
    [baseZIndex]="10000">
    <p-progressSpinner></p-progressSpinner>
    <h3>File Uploading...</h3>
</p-dialog>

