import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LayoutSetting {
  showMenuBar: boolean;
  showMenuBarPrimaryMenu: boolean;
  showMenuBarProfileHelpMenu: boolean;
  showMenuBarNotifications: boolean;
  showFooterBar: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LayoutSettingsService implements OnDestroy {

  protected layoutSetting = new BehaviorSubject<LayoutSetting>({
      showMenuBar: true,
      showMenuBarPrimaryMenu: true,
      showMenuBarProfileHelpMenu: true,
      showMenuBarNotifications: true,
      showFooterBar: true,
    }
  );

  ngOnDestroy() {
    this.revertSettings();
  }

  constructor() {
  }

  setSettings(setting: Partial<LayoutSetting>) {
    this.layoutSetting.next({
      ...this.layoutSetting.value,
      ...setting
    });
  }

  getSettings(): LayoutSetting {
    return this.layoutSetting.value;
  }

  getSettingsSubject(): BehaviorSubject<LayoutSetting> {
    return this.layoutSetting;
  }

  revertSettings() {
    this.layoutSetting.next({
      showMenuBar: true,
      showMenuBarPrimaryMenu: true,
      showMenuBarProfileHelpMenu: true,
      showMenuBarNotifications: true,
      showFooterBar: true
    });
  }
}
