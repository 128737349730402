import { AfterContentInit, AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { EntityFilter, IFilterableEntityTypeDescriptor } from '@ripple/models';
import { MessageService } from '@ripple/services';

import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';
import { TemplateInputItem, VirtualEntitySelectComponent } from '../plain-inputs/virtual-entity-select/virtual-entity-select.component';
import { throttleTime } from 'rxjs/operators';

@Component({
  selector: 'ripple-form-virtual-select-entity',
  styleUrls: ['./form-virtual-select-entity.component.scss'],
  template: `
    <ng-container *ngIf="!to.moduleSettings.manualentry; else elseTemplate">
      <ripple-virtual-entity-select
        #entitySelect
        [formControl]="formControl"
        (selectedChange)="emitChangeAfterTouched($event)"
        [itemSize]="to.moduleSettings.itemheight"
        [listDescriptor]="this.listDescriptor"
        [virtualPageSize]="virtualPageSize"
        [filterable]="filterable"
        [requireFilterMinCharacters]="forceLookUpMinCharacters"
        [requireFilter]="forceLookUp"
        [required]="to.required"
        [automaticSearchProperties]="true"
        [searchProperties]="searchProperties"
        [multiSelect]="this.to.isMultiSelect"
        [useFullEntities]="false"
        [requiredEntities]="requiredEntities"
        [disabledEntities]="disabledEntities"
        [logKey]="key"
        [forceDisabled]="to.disabled"
        [sortOptionsAlphabetical]="true"
      ></ripple-virtual-entity-select>
      <div class="button-wrapper">
        <!-- button for adding new entities, only present if template option is true !-->
        <button [type]="to.type"
          pButton
          *ngIf="to && to.enableAdditionalButton"
          [label]="to.buttonText"
          [icon]="to.icon ? to.icon : 'pi pi-plus'"
          [iconPos]="to.iconPos"
          [ngClass]="to.class"
          [style]="to.style"
          [disabled]="to.disableButton === true || (to.disabled && (to.disableButton === undefined || to.disableButton === true))"
          [pTooltip]="to.tooltip"
          [tooltipPosition]="to.tooltipPosition || 'top'"
          (click)="buttonOnClick($event)"
        ></button>
      </div>

    </ng-container>
    <ng-template #elseTemplate>
      <input
        pInputText
        type="number"
        maxLength="8"
        style="width: 100%;"
        pKeyFilter="int"
        [disabled]="viewOnly"
        [(ngModel)]="manualEntryValue"
      />
    </ng-template>
  `
})
// @dynamic
export class FormVirtualSelectEntityComponent extends RippleFieldGenericComponent implements OnInit, AfterViewInit, AfterContentInit {
  protected static availableFields = ['question-entity-list', 'is-multi-select'];
  // value completely handled by formControl
  @ViewChild(VirtualEntitySelectComponent) entitySelect: VirtualEntitySelectComponent;

  _manualEntryValue: string;
  get manualEntryValue() {
    const val = this.formControl.value;
    if (!val)
      return undefined;
    if (val instanceof Array)
      return val.map(v => v.id).join(',');

    return val && val.id;
  }

  set manualEntryValue(val: string) {
    if (this.to.isMultiSelect) {
      const ids = val.split(',');
      this.formControl.setValue(ids.map(id => ({ id })));
    } else
      this.formControl.setValue([{ id: val }]);
  }

  modelChangedSub: Subscription;

  _listDescriptor: IFilterableEntityTypeDescriptor;
  get listDescriptor() {
    if(this.to.overrideEntityListDescriptor && this.to.overrideEntityListDescriptor instanceof EntityFilter && this.to.listEntityType){
      return {id: this.to.listEntityType.id, entityFilter: this.to.overrideEntityListDescriptor};
    }
    return this._listDescriptor || this.to.listEntityType;
  }

  get filterable() {
    return this.to.moduleSettings.filterable !== false;
  }
  get searchProperties() {
    return this.to.moduleSettings['entityfilter_searchby'];
  }
  get forceLookUp() {
    if (this.to.forceLookup === false || this.to.forceLookup === true)
      return this.to.forceLookup;

    return this.to.moduleSettings['entityfilter_forcelookup'];
  }
  get forceLookUpMinCharacters() {
    return this.to.moduleSettings['entityfilter_forcelookup_mincharacters'];
  }

  get virtualPageSize() {
    return this.to.moduleSettings['entityfilter_virtualpagesize'] || 25;
  }

  get manualListValue() {
    const value = this.formControl.value || { id: undefined };
    return value instanceof Array ? value.map(v => v.id).join(',') : value.id;
  }

  get requiredEntities(): number[] | Observable<number[]> {
    return this.to.requiredEntities || undefined;
  }

  get disabledEntities(): number[] | Observable<number[]> {
    return this.to.disabledEntities || undefined;
  }

  get logName() { return `${this.field.type} [${this.to.isMultiSelect ? 'multi' : 'single'}]`; }

  private loadedItemTemplate = new BehaviorSubject<TemplateRef<TemplateInputItem>>(undefined);
  private loadingItemTemplate = new BehaviorSubject<TemplateRef<TemplateInputItem>>(undefined);
  private selectedItemTemplate = new BehaviorSubject<TemplateRef<TemplateInputItem>>(undefined);

  private loadedItemSub: Subscription;
  private loadingItemSub: Subscription;
  private selectedItemSub: Subscription;

  constructor(msg: MessageService, private element: ElementRef) { super(msg); }

  ngAfterContentInit(): void { }

  ngAfterViewInit(): void {
    this.nextSub = this.loadedItemTemplate.subscribe(tRef => {
      if (this.entitySelect && tRef) {
        this.entitySelect.loadedItem = tRef;
        this.entitySelect.renderChanges();
      }
    });

    this.nextSub = this.loadingItemTemplate.subscribe(tRef => {
      if (this.entitySelect && tRef) {
        this.entitySelect.loadingItem = tRef;
        this.entitySelect.renderChanges();
      }
    });

    this.nextSub = this.selectedItemTemplate.subscribe(tRef => {
      if (this.entitySelect && tRef) {
        this.entitySelect.selectedItem = tRef;
        this.entitySelect.renderChanges();
      }
    });

    // quick hack to get up to date list descriptor for first page
    this.element.nativeElement.addEventListener('click', () => this.updateListDescriptor(), { once: true });
  }

  ngOnInit(): void {
    if (this.modelChangedSub)
      this.modelChangedSub.unsubscribe();

    if (this.to && this.to.listEntityType) {
      this.modelChangedSub = this.form.valueChanges
        .pipe(throttleTime(300, undefined, { leading: false, trailing: true }))
        .subscribe( value => this.updateListDescriptor() );

      this.updateListDescriptor();
    }

    this._updateTemplates();
  }

  _updateTemplates() {
    this.log('Update Templates');
    if (this.to.loadedItem) {
      if (this.loadedItemSub && !this.loadedItemSub.closed)
        this.loadedItemSub.unsubscribe();

      if (this.to.loadedItem instanceof Observable)
        this.loadedItemSub = this.to.loadedItem.subscribe(this.loadedItemTemplate);
      else if (this.to.loadedItem instanceof Function)
        this.loadedItemTemplate.next(this.to.loadedItem(this.model));
      else
        this.loadedItemTemplate.next(this.to.loadedItem);
    }

    if (this.to.loadingItem) {
      if (this.loadingItemSub && !this.loadingItemSub.closed)
        this.loadingItemSub.unsubscribe();

      if (this.to.loadingItem instanceof Observable)
        this.loadingItemSub = this.to.loadingItem.subscribe(this.loadingItemTemplate);
      else if (this.to.loadingItem instanceof Function)
        this.loadingItemTemplate.next(this.to.loadingItem(this.model));
      else
        this.loadingItemTemplate.next(this.to.loadingItem);
    }

    if (this.to.selectedItem) {
      if (this.selectedItemSub && !this.selectedItemSub.closed)
        this.selectedItemSub.unsubscribe();

      if (this.to.selectedItem instanceof Observable)
        this.selectedItemSub = this.to.selectedItem.subscribe(this.selectedItemTemplate);
      else if (this.to.selectedItem instanceof Function)
        this.selectedItemTemplate.next(this.to.selectedItem(this.model));
      else
        this.selectedItemTemplate.next(this.to.selectedItem);
    }
  }

  /* gets click function from template options and runs that when the button for adding entities is pressed.
  Add button is only present if the template option is enabled */
  buttonOnClick($event) {
    if (this.to.additionalButtonOnClick)
      this.to.additionalButtonOnClick($event);
  }

  updateListDescriptor() {
    // hack, idk why this is necessary
    // TODO: it seems like model and form.value become out of sync at some point...
    // TODO: not sure if the form excludes disabled fields??
    const model = Object.assign({}, this.model, this.form.value);

    this.log('Update List Descriptor', model);
    const descriptor = this.to.listEntityType as IFilterableEntityTypeDescriptor;

    if (descriptor.entityFilter instanceof Function)
      this._listDescriptor = {
        ...descriptor,
        entityFilter: descriptor.entityFilter(model)
      };
    else if (descriptor.entityFilter instanceof EntityFilter)
      this._listDescriptor = {
        ...descriptor,
        entityFilter: descriptor.entityFilter
      };
    else if (this._listDescriptor)
      // favour the templateOptions reference if its compatible
      this._listDescriptor = undefined;
  }

  emitChangeAfterTouched(event) {
    this.to.actualOptions = event;
    this.tryEmitOnChange();
  }
}
