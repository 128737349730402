<!-- <button pButton type="button" (click)="privacyService.togglePrivacyMode()" label="{{privacyService.isPrivacyModeEnabled() ? 'Disable Privacy Mode' : 'Enable Privacy Mode'}}"></button> -->

<button
    class="menu-button menu-button--iconned"
    (click)="privacyService.togglePrivacyMode()"
    pTooltip="{{privacyService.isPrivacyModeEnabled() ? 'Disable Privacy Mode' : 'Enable Privacy Mode'}}"
    tooltipPosition="bottom"
    tooltipZIndex="99999999"
>
    <i class="menu-icon fa fa-{{privacyService.isPrivacyModeEnabled() ? 'eye-slash' : 'eye'}}"></i>
    <span
    class="badge"
    >
    {{privacyService.isPrivacyModeEnabled() ? 'On' : 'Off'}}
    </span>

</button>