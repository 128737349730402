import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { WarpEntityServiceCache, WarpEntityLogService, WarpEntityCacheFactoryService } from '@ripple/services';
import { AfterSaveEvent } from '@ripple/ui';
import { Community, EntityTypes } from '../../_core/models';

@Component({
  selector: 'sagesse-community-details',
  templateUrl: './community-details.component.html',
  styleUrls: ['./community-details.component.scss']
})
export class CommunityDetailsComponent extends BasePage implements OnInit {

  community: Community;
  communityService: WarpEntityServiceCache<Community>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.communityService = this.serviceFactory.get(EntityTypes.Community) as WarpEntityServiceCache<Community>;
    this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);
      if (isNaN(id)) {
        this.community = Community.empty();
        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.sub = this.communityService.get(id).subscribe((community) => {
          this.community = community;
          this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
        });
      }
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') this.router.navigate([`../${e.entity.entityId}`], { relativeTo: this.route });
    else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = 'Community Deleted Successfully!';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

}
