import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache, ExportService } from '@ripple/services';
import { EntityTypes, EvaluationDBProgramCategory, IndividualStatisitcReport, StrategicOutcome } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { AgencyStatsService } from '../../_core/services';
import { NullTemplateVisitor } from '@angular/compiler';
import { MenuItem } from 'primeng';
import { ActivatedRoute, Route, Router } from '@angular/router';
import * as moment_ from 'moment';
const moment = moment_;

@Component({
  selector: 'sagesse-evaluation-outcome-report-details',
  templateUrl: './evaluation-outcome-report-details.component.html',
  styleUrls: ['./evaluation-outcome-report-details.component.scss']
})
export class EvaluationOutcomeReportDetailsComponent extends BasePage implements  OnInit {
  programCategoryService: WarpEntityServiceCache<EvaluationDBProgramCategory>;
  programCategory: EvaluationDBProgramCategory;
  dateRange: Date[] = [new Date(2022, 0), new Date(2022, 11)];
  reportData:  any[] = [];
  cols: any[] = [];
  loading: boolean = false;

  constructor(
    private logService: WarpEntityLogService,
    private factory: WarpEntityCacheFactoryService,
    private agencyStatsService: AgencyStatsService,
    private route: ActivatedRoute,
    private exportService: ExportService,
  ) {
    super(logService);
    this.programCategoryService = factory.get(EntityTypes.EvaluationDBProgramCategory) as WarpEntityServiceCache<EvaluationDBProgramCategory>;
  }

  ngOnInit(): void {
    
    const currYear = moment().year();
    this.dateRange = [new Date(currYear, 0), new Date(currYear, 11)];

    this.nextSub = this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);
      this.programCategoryService.get(id).subscribe((result) => {
        this.programCategory = result;
        this.getReport();
      });
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  changedDatePicker() {
    if (this.dateRange.length == 2 && this.dateRange[0] && this.dateRange[1]) {
        this.getReport();
    }
  }

  getReport() {
    this.cols = [];
    this.loading = true;

    let currYear = this.dateRange[0].getFullYear();
    let startYear = currYear;
    let endYear = this.dateRange[1].getFullYear();
    let startMonth = this.dateRange[0].getMonth() + 1;
    let currMonth = this.dateRange[0].getMonth() + 1;
    let endMonth = this.dateRange[1].getMonth() + 1;

    while (currYear <= endYear) {
      // Start and end month matter
      if (currYear === startYear && currYear === endYear) {
        this.populateColsForYear(currYear, startMonth, endMonth);
      } else if (currYear == startYear) { // Start Month Matters
        this.populateColsForYear(currYear, startMonth, 12);
      } else if (currYear == endYear) { // End Month Matters
        this.populateColsForYear(currYear, 1, endMonth);
      } else { // Use all 12 Months
        this.populateColsForYear(currYear, 1, 12);
      }
      currYear++;
    }

    this.nextSub = this.agencyStatsService.getEvaluationOutcomeStatistics(this.dateRange[0].getMonth() + 1, this.dateRange[0].getFullYear(), this.dateRange[1].getMonth() + 1, this.dateRange[1].getFullYear(), this.programCategory.id).subscribe(resp => {
      const sortedData = resp.sort((a, b) => {
        return (a.orderImportance - b.orderImportance);
      });

      this.reportData = [];
      sortedData.forEach(row => {
        if (this.reportData.filter(r => r.category === row.category).length == 0) {
          this.reportData.push({category: row.category, stats:[row]});
        } else {
          this.reportData.filter(r => r.category === row.category)[0].stats.push(row);
        }
      })

      this.loading = false;
    });
  }

  exportReport() {
    let rows = [];
    console.log(this.reportData);
    this.reportData.forEach(outcome => {
      rows.push( { Name: outcome.category } );
        outcome.stats.forEach(stat => {
          rows.push(this.GenerateRowForStat(stat));
        });
    });

    this.exportService.exportXLSX(rows, 'Report');
  }

  GenerateRowForStat(stat: any) {
    let toRet = { Name: stat.name }
    this.cols.forEach(col => {
      const value = stat.years[col.year][col.month].value;
      toRet[col.header] = value !== null && value !== undefined ? value : 0;
    });
    return toRet;
  }

  populateColsForYear(currYear, startMonth, endMonth) {
    let currMonth = startMonth;
    while (currMonth <= endMonth) {
      this.cols.push({ year: currYear, field: this.getMonthName(currMonth), header: this.getMonthName(currMonth).slice(0, 3) + "-" + currYear.toString(), month: currMonth.toString() });
      if (currMonth % 3 === 0) {
        let quarter = "Q" + (currMonth / 3).toString();
        let ytd = "ytd" + (currMonth / 3).toString();
        this.cols.push({ year: currYear, field: quarter.toLowerCase(), header: quarter + "-" + currYear.toString(), month: quarter.toLowerCase(), highlight: true });
        if (currMonth != 12)
          this.cols.push({ year: currYear, field: ytd.toLowerCase(), header: "YTD", month: ytd, highlight: true });
        else
          this.cols.push({ year: currYear, field: ytd.toLowerCase(), header: "Year Total", month: ytd, highlight: true });
      }
      currMonth++;
    }

    // console.log("currYear: " + currYear + ", startMonth: " +  startMonth + ", endMonth: " + endMonth);
    // console.log("columns:", this.cols)
    // console.log("data:", this.reportData)
  }

  getMonthName(monthNum) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    if (monthNum >= 0 && monthNum <= 12)
      return months[monthNum-1];

    return 'N/A';
  }


}
