import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import type { Countable, SaveStatType } from './snippet-total.directive';
import { SnippetDataDirective } from './snippet-data.directive';

export type Filterable = { selected?: boolean, filtered: boolean, wasFiltered: boolean };

export type SippetFilterEmission<Item extends Countable, Data, Cfim, FilledForm> = {
  item: Item,
  data: Data,
  unchangeablename: string,
  label: string,
  cfim: Cfim,
  filledForm: FilledForm,
  $event: MouseEvent,
};

export type SnippetFilterEvent<Item extends Countable, Data, Cfim, FilledForm> = {
  filterType: 'and' | 'remove',
} & SippetFilterEmission<Item, Data, Cfim, FilledForm>;

export type SnippetStatEvent<Item extends Countable, Data, Cfim, FilledForm> = {
  total: false,
  type: SaveStatType,
} & SippetFilterEmission<Item, Data, Cfim, FilledForm>;

@Component({
  selector: '[ccasa-snippet-filter]',
  styles: [`
    .mat-standard-chip {
      padding: 0px 5px;
    }

    td {
      padding-left: 5px;
      padding-right: 5px;

      &.filter-button {
        width: 8rem;
      }
    }

    :host:hover .results-right>i {
      opacity: 1;
    }

    .results-right {
      text-align: right;

      > i {
        float: left;
        color: var(--primary);
        margin-top: 0.25rem;
        opacity: 0;
        transition: opacity 0.33s;
      }
    }

    .pointer {
        cursor: pointer;
    }
  `],
  template: `
    <ng-template #skip><td style="border-left: none"></td></ng-template>

    <td snippetFilterEntry="Data" width="*" style="border-right: none" class="label" [pTooltip]="item?.tooltip" tooltipPosition="bottom">
      {{data}}
      <i *ngIf="item?.tooltip" class="pi pi-info-circle"></i>
    </td>

    <td *ngIf="showFilter === true; else skip" (click)="UpdateFilter($event)" class="filter-button pointer no-select" >
      {{item.filtered ? 'Remove' : 'Add'}} Filter
    </td>

    <td snippetFilterEntry="Time" *ngIf="showTime" width="10%" class="time results-right">
      <i title="Save time as stat" *ngIf="canSaveStat('time')" class="pi pi-save" (click)="doSaveStat($event, 'time')"></i>
      {{formatTime(item?.time)}}
    </td>

    <td snippetFilterEntry="Count"  width="10%" class="result results-right">
      <i title="Save count as stat" *ngIf="canSaveStat('count')" class="pi pi-save" (click)="doSaveStat($event, 'count')"></i>
      {{ placeholder || item.count }}
    </td>

    <td snippetFilterEntry="Total Percentage" *ngIf="dataLength > 0" width="10%" class="percentage results-right">
      <i title="Save percent as stat" *ngIf="canSaveStat('percent')" class="pi pi-save" (click)="doSaveStat($event, 'percent')"></i>
      {{ placeholder || ((100* item.count / dataLength).toFixed(1) + '%') }}
    </td>

    <td snippetFilterEntry="Total Percentage" *ngIf="dataLength == 0" width="10%" class="percentage results-right">0%</td>
  `
})
export class SnippetFilterComponent<Item extends Countable & Partial<Filterable>, Data, Cfim, FilledForm> {
  placeholder = '';

  _item: Item;
  get item() { return this._item; }
  @Input() set item(value: Item) {
    this._item = value;

    if (!value || value.count === null)
      this.placeholder = '-';
  }

  @Input() unchangeablename: string;
  @Input() label: string;
  @Input() data: Data;
  @Input() dataLength: number;
  @Input() cfim: Cfim;
  @Input() filledForm: FilledForm;
  @Input() saveStat: boolean = false;
  @Input() showTime: boolean = false;
  @Input() showFilter: boolean | 'skip' = true;
  @Input() allowMultiple: boolean = false;

  @Input() @HostBinding('class.alt')
  allowAltMethods: boolean | ('shift'|'ctrl'|'alt')[] = false;

  @Output() filterUpdate: EventEmitter<SnippetFilterEvent<Item, Data, Cfim, FilledForm>> = new EventEmitter();
  @Output() statUpdate: EventEmitter<SnippetStatEvent<Item, Data, Cfim, FilledForm>> = new EventEmitter();

  @HostBinding('class.selected') get selected() { return this.item.selected; }
  @HostBinding('class.filtered') get filtered() { return this.item.filtered; }
  @HostBinding('class.was-filtered') get wasFiltered() { return this.item.wasFiltered; }

  @ViewChildren(SnippetDataDirective) cols: QueryList<SnippetDataDirective>;

  get exportableColumns(){
    return this.cols.filter(c => c.exportable);
  }

  constructor(public cdRef: ChangeDetectorRef) {}

  UpdateFilter($event: MouseEvent) {
    const type = this.item.filtered ? 'remove' : 'and';
    console.log('UpdateFilter in question', type);

    const obj = {
                item: this.item,
                data: this.data,
                unchangeablename: this.unchangeablename,
                label: this.label,
                filterType: type as any,
                cfim: this.cfim,
                filledForm: this.filledForm,
                $event
            };
    this.filterUpdate.emit(obj);
  }

  canSaveStat(type: SaveStatType ) {
    return this.saveStat;
  }

  doSaveStat($event: MouseEvent, type: SaveStatType) {
    const obj = {
      item: this.item,
      data: this.data,
      unchangeablename: this.unchangeablename,
      label: this.label,
      cfim: this.cfim,
      filledForm: this.filledForm,
      total: false as false,
      type: type,
      $event
    };
    this.statUpdate.emit(obj);
  }

  formatTime(time: string) {
    if (time == null) {
      return "0:00";
    } else {
      let hours = Math.floor(parseInt(time) / 60)
      let minutes = parseInt(time) - (hours * 60);
      return hours.toString() + ":" + (minutes < 10 ? "0" + minutes.toString() : minutes.toString());
    }
  }
}
