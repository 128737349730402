// subscriber specific names to use for ripple entity types

export enum EntityTypes {
  Default = 0,

  User = 2,
  Organization = 611,
  Contact = 72,
  OrganizationContact = 680,
  ContactRole = 681,
  Office = 8,
  OrganizationTagCategory = 682,
  OrganizationTag = 683,
  ClaresLaw = 685,
  FilledForm = 636,
  Community = 144,
  CommunityGroup = 686,
  Team = 687,
  TeamType = 688,
  TeamContactRoleType = 689,
  TeamContact = 690,
  Channel = 700,
  ChannelMember = 701,
  ClaresLawSesitiveInformation = 721,

  Program = 748,
  StatisticGroup = 749,
  Statistic = 750,
  FilledStatistic = 751,

  StrategicOutcome = 752,
  StrategicOutcomeSubCategory = 753,
  StrategicOutcomeStatistic = 754,

  IndividualStatisitcReport = 755,
  StrategicOutcomeTarget = 756,
  MonthNote = 782,

  EvaluationDBProgramCategory = 827,

}
