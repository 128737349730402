import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  AuthService,
  MenuService,
  FileService,
  GenericWarpEntityService,
  MFAService,
  WarpEntityLogService,
  WarpEntityCacheFactoryService,
  WarpEntityServiceCache,
  SiteSettingsService,
} from '@ripple/services';
import { WarpEntity } from '@ripple/models';
import { BasePage, isValidGuid } from '@ripple/core';
import {
  CanvasWhiteboardComponent,
  CanvasWhiteboardUpdate,
} from 'ng2-canvas-whiteboard';
import { combineLatest, forkJoin } from 'rxjs';
import { shareReplay, switchMap, tap } from 'rxjs/operators';

type Option = {id: number, value: string}

@Component({
  selector: 'ripple-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent extends BasePage implements OnInit, AfterViewInit {
  get componentName(): string {
    return 'AccountComponent';
  }
  @Input() disableSave = false;
  @Input() showSignature = true;
  @Input() viewSignatureOnly = false;

  @ViewChild('canvasWhiteboard') canvasWhiteboard: CanvasWhiteboardComponent;

  private userService: WarpEntityServiceCache<WarpEntity>;
  paymentSystem = false;

  height = '500px';

  signatureData = '';
  canvasUpdates: CanvasWhiteboardUpdate[] = [];

  canvasOptions = {
    saveDataButtonText: 'Save',
    drawingEnabled: true,

    shapeSelectorEnabled: false,
    showShapeSelector: false,

    drawButtonClass: 'drawButtonClass',
    drawButtonText: 'Draw',

    clearButtonClass: 'clearButtonClass',
    clearButtonText: 'Clear',

    undoButtonText: 'Undo',
    undoButtonEnabled: false,

    redoButtonText: 'Redo',
    redoButtonEnabled: false,

    colorPickerEnabled: false,
    shouldDownloadDrawing: false,

    lineWidth: 5,
    strokeColor: 'rgb(0,0,0)',
  };

  // account settings
  loading = true;
  saving = false;
  openAvatarModal = false;
  open2FAModal = false;
  openSignatureModal = false;
  uploadingAvatar = false;
  enable2FA = false;
  user: WarpEntity;
  progress: number;
  minYear = '2020';
  maxYear = '1920';
  touchUI = screen.width < 922;
  files: File[];

  private _password = '';
  private _confirmPassword = '';
  private _pin: string;
  passwordFail = false;
  pinError = '';
  pinFail = false;
  showPin = false;

  notificationPreferences: Option[] = []
  selectedNotificationPreferences: Option[];
  private _birthDate: Date;
  get birthDate() {
    return this._birthDate;
  }

  set birthDate(value: Date) {
    this._birthDate = value;

    this.user['birth date'] = value
      .getFullYear()
      .toString()
      .concat(
        '/',
        '' + (value.getMonth() + 1),
        '/',
        value.getDate().toString()
      );
  }

  get pin() {
    return this._pin;
  }

  set pin(value: string) {
    this._pin = value;
    this.pinError = '';
    if (this._pin) {
      if (!/^\d+$/.test(this._pin)) {
        this.pinError = 'PIN should only have numeric characters';
      } else if (this._pin.length > 16) {
        this.pinError = 'PIN should have a maximum length of 16 digits';
      }
    }
  }

  get saveDisabled() {
    return this.hasError || this.saving;
  }

  get password() {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
    this.passwordFail = this._password !== this._confirmPassword;
  }

  get confirmPassword() {
    return this._confirmPassword;
  }

  get isSignatureEmpty() {
    return this.signatureData === '';
  }

  set confirmPassword(value: string) {
    this._confirmPassword = value;
    this.passwordFail = this._password !== this._confirmPassword;
  }

  constructor(
    public mfa: MFAService,
    private authService: AuthService,
    private menuService: MenuService,
    private fileService: FileService,
    private entityService: GenericWarpEntityService,
    private serviceFactory: WarpEntityCacheFactoryService,
    private siteSettingService: SiteSettingsService,
    logService: WarpEntityLogService,
  ) {
    super(logService);

    this.maxYear = '' + new Date().getFullYear();
    this.minYear = '' + (new Date().getFullYear() - 100);
  }

  has2FA$ = this.mfa.canSetupMFA().pipe(shareReplay(1));

  public ngOnInit() {
    this.userService = this.serviceFactory.get(583);
    this.siteSettingService.GetBoolean('feature_show_payment_system').then((value) => {
      this.paymentSystem = value;
    });
  }

  public ngAfterViewInit() {
    const getLoggedInUser$ = this.authService.getLoggedInUser();
    const mfaEnabled$ = getLoggedInUser$.pipe(switchMap(() => this.mfa.isMFAEnabled()));
    const getNotificationPreferences$ = this.userService.getCfimOptions('NotificationPreference');

    combineLatest([getLoggedInUser$, mfaEnabled$, getNotificationPreferences$]).subscribe(([user, enable2FA, options]) => {
      this.user = user;
      this.enable2FA = enable2FA;

      // Date is always one day off unless you replace '-' with '/'
      if (user['birth date'])
        this.birthDate = new Date(
          Date.parse(user['birth date'].replace(/-/g, '/'))
        );

      this.signatureData = user['ccasa-signaturejson'];
      if (this.signatureData) {
        const sigData =
          typeof this.signatureData === 'string'
            ? JSON.parse(this.signatureData)
            : this.signatureData;
        const updates = sigData.map((jsonUpdate) =>
          CanvasWhiteboardUpdate.deserializeJson(JSON.stringify(jsonUpdate))
        );
        this.canvasUpdates = updates;
        this.canvasWhiteboard.drawUpdates(updates);
      }

      // If signautre if empty, don't allow autofill signature to be enabled
      this.checkAutoFill();
      this.notificationPreferences = options.map((option) => ({
        id: option.id,
        value: option.value,
      }));
      this.selectedNotificationPreferences = this.notificationPreferences.filter(option =>
        Array.isArray(this.user['notificationpreference']) && this.user['notificationpreference'].some(pref => pref.id === option.id)
        || this.user['notificationpreference']?.id === option.id
      );
      // ICM Notfication System is always checked
      if (!this.selectedNotificationPreferences.find(pref => pref.id === 3195))
        this.selectedNotificationPreferences.push(this.notificationPreferences.find(pref => pref.id === 3195));

      this.loading = false;
    });
    this.canvasWhiteboard.setDrawingEnabled(!this.viewSignatureOnly);
  }

  checkAutoFill() {
    console.log('this is the user', this.user)
    if (this.isSignatureEmpty)
      this.user['autofillsignature'] = false;
  }

  openSignatureDialog() {
    this.openSignatureModal = true;

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1);
  }

  getAvatar(): string {
    return this.menuService.getAvatar();
  }

  uploadAvatar() {
    if (this.files && this.files.length > 0) {
      this.uploadingAvatar = true;
      const file = this.files[this.files.length - 1];

      this.fileService
        .uploadFile(file, 'userimg', 'Public')
        .subscribe((guid) => {
          if (guid && isValidGuid(guid))
            this.menuService.setAvatar(guid);
          this.uploadingAvatar = false;
        });
    }
  }

  onSaveSignature(event) {
    this.openSignatureModal = false;

    this.signatureData = JSON.stringify(this.canvasUpdates);
    this.user['ccasa-signaturejson'] = this.signatureData;

    this.canvasWhiteboard.generateCanvasData((data) => {
      //console.log('data', data);
      this.user['ccasa-signaturebase64'] = data.toString();

      this.saving = true;
      this.entityService.entitySyncUpdate(this.user, true).subscribe(() => (this.saving = false));
      this.checkAutoFill();
    }, 'image/png', 0.5);
  }

  onCanvasClear() {
    this.canvasUpdates = [];
    this.signatureData = '';
    this.checkAutoFill();
  }

  sendBatchUpdate(event) {
    event.forEach((drawn) => this.canvasUpdates.push(drawn));
    this.signatureData = JSON.stringify(this.canvasUpdates);
  }

  get hasError() {
    return this.passwordFail || !!this.pinError;
  }

  save() {
    if (this.saveDisabled) return;

    this.saving = true;

    if (this.user['notificationpreference']) {
      this.user['notificationpreference'] =
        this.selectedNotificationPreferences;
    } else {
      this.user.cfcProperty(
        'notificationpreference',
        ...this.selectedNotificationPreferences.map((pref) => pref.id)
      );
    }

    const overwrite = true;
    const handoffEnabled = true;
    forkJoin([
      this.entityService.entitySyncUpdate(this.user, overwrite),
      this.authService
        .saveAccount(
          this.user,
          this.password,
          handoffEnabled,
          this.pin,
          this.enable2FA
        )
        .pipe(
          tap((res) => {
            this.password = '';
            this.confirmPassword = '';
            this.pin = '';

            if (res.imgMfaQrCode) {
              this.mfa.startMFA({
                imageURL: res.imgMfaQrCode,
                accountName: res.lMfaManualAccountName,
                manualKey: res.lMfaManualKey,
              });
              this.open2FAModal = true;
            }
          })
        ),
    ]).subscribe(() => {
      this.saving = false;
    });
  }

  isNotificationPreferenceDisabled(item: Option) {
    if (item.id === 3195) // don't let user change ICM Notfication System
      return true;
    return false;
  }

  closeMFA() {
    this.mfa.endMFA();
    this.open2FAModal = false;
  }

  setupMFA($event: number) {
    this.mfa.setupMFA($event).toPromise();
  }

  cancelMFA() {
    this.mfa
      .cancelMFASetup()
      .toPromise()
      .finally(() => {
        this.open2FAModal = false;
        this.enable2FA = false;
      });
  }
}
