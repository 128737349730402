import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { CustomFieldInModule, EntityFilter, IGenFormlyOptions } from '@ripple/models';
import { WarpEntityServiceCache, WarpEntityLogService, WarpEntityCacheFactoryService, GraphApiService, ExportService } from '@ripple/services';
import { AfterSaveEvent, BreadcrumbItem, EntityDetailsComponent } from '@ripple/ui';
import { Message } from 'primeng/api';
import { first } from 'rxjs/operators';
import { Team, EntityTypes, Channel } from '../../_core/models';
import { TeamService } from '../../_core/services';

@Component({
  selector: 'sagesse-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent extends BasePage implements OnInit, AfterViewInit {

  team: Team;

  breadcrumbs:BreadcrumbItem[] = [
    { name: "Teams",
      url: "/team"
    }
  ];

  fieldOrders = [
    'name',
    'launchdate',
    'teamstatus',
    'employees',
    'organization',
    'selectallusers'
  ];

  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => !this.fieldOrders.includes(cfim.unchangeableName.toLowerCase()),
    styles: {
      name: 'p-sm-12 p-md-12 ui-fluid',
      teamtype: 'p-sm-12 p-md-12 ui-fluid',
      launchdate: 'p-sm-12 p-md-12 ui-fluid',
      teamstatus: 'p-sm-12 p-md-12 ui-fluid',
      employees: 'p-sm-12 p-md-12 ui-fluid',
      organization: 'p-sm-12 p-md-12 ui-fluid',
    },
    groups: [
      {
        groupName: 'tab',
        groupType: 'tabs',
        groupClasses: 'ui-fluid d-none',
        groupFields: [],
        groupWrapper: [],
        groupWrapperData: {},
        groups: [
          {
            groupName: 'GeneralInfo',
            groupClasses: 'p-grid ui-fluid mt-1',
            groupFields: ['name', 'launchdate', 'teamstatus'],
            groupWrapper: [],
            groupWrapperData: {
              label: 'General Info',
              tabId: 'generalInfo',
              headerIcon: 'pi pi-info-circle'
            },
          },
          {
            groupName: 'Organizations',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Organizations',
              tabId: 'organizations',
              headerIcon: 'pi pi-sitemap'
            },
          },
          {
            groupName: 'Members',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Members',
              tabId: 'members',
              headerIcon: 'pi pi-users'
            },
          },
          {
            groupName: 'Folders/Files',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Folders/Files',
              tabId: 'folders/files',
              headerIcon: 'pi pi-folder'
            },
          },
          {
            groupName: 'Channels',
            groupClasses: 'p-grid ui-fluid mt-1 hidden-new-entity',
            groupFields: [],
            groupWrapper: [],
            groupWrapperData: {
              label: 'Channels',
              tabId: 'channels',
              headerIcon: 'pi pi-users'
            },
          }
        ]
      }
    ],
    expressionProperties: (cfim) => {
      if (
        cfim.key.toLowerCase() === 'name'
      ) {
        return {
          'templateOptions.disabled': (model, formState, field?) => {
            return this.team && this.team.entityId > 0;
          },
        };
      } else return {};
    },
  };

  // channels
  channelService: WarpEntityServiceCache<Channel>;
  channelFilter: EntityFilter = EntityFilter.None;
  channelListOptions = {
    cols: null,
    extraCols: [{
      order: 5,
      header: 'Num of Members',
      field: 'numOfMembers',
      specificDisplayType: ''
    }],
    excludeCols: []

  };

  sharePointLink = '';
  msTeamLink = '';

  isExporting = false;

  tabBlocked = false;

  hasPermission = true;
  msgs: Message[] = [{severity: 'error', summary: 'You do not have permission to view this team. ' }];

  @ViewChild('ed') ed: EntityDetailsComponent;

  public teamService: TeamService;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService,
    private graphApiService: GraphApiService,
    private exportService: ExportService
  ) {
    super(logService);
    this.teamService = this.serviceFactory.get(EntityTypes.Team) as TeamService;
  }

  ngOnInit(): void {
    this.channelService = this.serviceFactory.get(EntityTypes.Channel) as WarpEntityServiceCache<Channel>;
    this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);
      if (isNaN(id)) {
        this.team = Team.empty();
        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.teamService.checkExisting(id).then(result => {
          if (!result) this.hasPermission = false;
          else {
            this.channelFilter = EntityFilter.Advanced({ team_lid: id });
            this.teamService.get(id).pipe(first()).toPromise().then((team) => {
              this.team = team;
              this.breadcrumbs = [
                { name: "Teams", url: "/team" },
                { name: this.team?.name }
              ];

              this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
              if (this.team.azureadobjectid) {
                this.graphApiService.getTeam(this.team.azureadobjectid).subscribe(adTeam => {
                  console.log('Get team from ad', adTeam);
                  this.msTeamLink = adTeam['webUrl'];
                });
                this.graphApiService.getGroupTeamSite(this.team.azureadobjectid).subscribe(site => {
                  console.log('Access a group team site', site);
                  this.sharePointLink = site['webUrl'];
                });
              }
            });
          }
        }).catch(error => {
          this.hasPermission = false;
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.ed) {
      this.ed.saving.subscribe(saving => {
        this.tabBlocked = saving;
      });
    }
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') {
      const queryParams = {};
      queryParams['msg'] = 'Team Created Successfully! It may take a few minutes to show up here. ';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
    else if (e.action === 'save' || e.action === 'edit') {
      this.team = new Team(e.entity);
    }
    else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = 'Team Deleted Successfully!';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

  ifTabShow(tab) {
    if (this.team && this.team.entityId > 0) {
      return true;
    } else {
      if (tab.fieldGroupClassName && tab.fieldGroupClassName.includes('hidden-new-entity')) {
        return false;
      }
      return true;
    }
  }

  editChannel = (channel: Channel) => this.router.navigate([`channel/${channel.entityId}`], { relativeTo: this.route });
  addChannel = () => this.router.navigate(['channel/add'], { relativeTo: this.route });

  goToExternalLink(link) {
    window.open(link, '_blank');
  }

  exportReport() {
    this.isExporting = true;
    this.teamService.getTeamReport(this.team.entityId).subscribe(result => {
      console.log('Report result', result);
      const json = result.map(t => {
        let retVal = {};
        retVal['First Name'] = t.teamContact.firstName;
        retVal['Last Name'] = t.teamContact.lastName;
        retVal['Email'] = t.teamContact.email;
        retVal['Phone Number'] = t.teamContact.phoneNumber;
        retVal['Organization'] = t.organizations;
        retVal['Non-Member'] = t.nonMember ? 'Non-Member' : '';
        delete t['teamContact'];
        delete t['organizations'];
        delete t['nonMember'];
        retVal = { ...retVal, ...t };
        return retVal;
      });
      console.log('Report result json', json);
      let fileName = this.team.name;
      while(fileName.includes(' ')) {
        fileName = fileName.replace(' ', '_');
      }
      fileName = fileName + '_export';
      this.exportService.exportXLSX(json, fileName);
      this.isExporting = false;
    });
  }

}
