import { Component, OnInit } from '@angular/core';
import { BasePage } from '@ripple/core';
import { WarpEntityCacheFactoryService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';
import { EntityTypes, IndividualStatisitcReport, Statistic, StrategicOutcome, StrategicOutcomeStatistic } from '../../_core/models';
import { WarpEntity, EntityFilter, FilterOperator, WarpEntityType, CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { AgencyStatsService } from '../../_core/services';
import { NullTemplateVisitor } from '@angular/compiler';
import { MenuItem } from 'primeng';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { template } from 'lodash';

@Component({
  selector: 'sagesse-individual-stat-report',
  templateUrl: './individual-stat-report.component.html',
  styleUrls: ['./individual-stat-report.component.scss']
})
export class IndividualStatReportComponent extends BasePage implements OnInit {
  statisticService: WarpEntityServiceCache<Statistic>;
  individualStatReportService: WarpEntityServiceCache<IndividualStatisitcReport>;
  strategicOutcomeStatisticService: WarpEntityServiceCache<StrategicOutcomeStatistic>;

  report: IndividualStatisitcReport = null;
  showAddReport: boolean = false;
  loading: boolean = false;
  reports: IndividualStatisitcReport[] = [];
  reportsLoading: boolean = true;
  avalibleStats: any[] =[];

  linkedStatOptions: any[] = [];
  linkedStrategicStatOptions: any[] = [];

  // linkedStatisticField: FormlyFieldConfig = {
  //   key: 'linkedstatistic',
  //   type: 'multiselect',
  //   className: 'p-col-12 ui-fluid',
  //   templateOptions: {
  //     required: false,
  //     options: [],
  //     isMultiSelect: true,
  //     label: 'Indvidual Statistics ',
  //     placeholder: 'Select Individual Stats',
  //     multiple: true,
  //     filterable: true,
  //   },
  //   expressionProperties: {
  //     'templateOptions.options': (model, formState, field?) => {

  //       let options:any = [];
  //       if (this.avalibleStats) {
  //         //options = this.avalibleStats.map((af) => ({ label: af.properties.linkedstatisticgroup[0].name + " -> " + af.name, value: af.id, unchangeablename: 'linkedstatistic', id: af.id, name: af.name }));
  //         options = this.avalibleStats.map((af) => ({ label: af.properties.linkedstatisticgroup[0].name +" -> " + af.name, value: af.id, name: af.name, id: af.id }))
  //       }

  //       return options;
  //     },
  //   },
  // };

  generatorOptions: IGenFormlyOptions = {
    styles: {
      name: 'p-sm-12 p-md-12 p-lg-12 ui-fluid',
    },
    hiddenCondition: (cfim) => {
      if (cfim.key.toLowerCase() === 'linkedstrategicoutcomestatisitc' || cfim.key.toLowerCase() === 'linkedstatistic')
      return true;

    },
     //customFields: [this.linkedStatisticField],
  };
  text: string = "Add Report";
  linkedstatIds: any[] = [];
  linkedStrategicStatIds: any[] =[];

  constructor(
    private logService: WarpEntityLogService,
    private factory: WarpEntityCacheFactoryService,
    private agencyStatsService: AgencyStatsService,
    private router: Router,
  ) {
    super(logService);
    this.individualStatReportService = factory.get(EntityTypes.IndividualStatisitcReport) as WarpEntityServiceCache<IndividualStatisitcReport>;
    this.statisticService = factory.get(EntityTypes.Statistic) as WarpEntityServiceCache<Statistic>;
    this.strategicOutcomeStatisticService = factory.get(EntityTypes.StrategicOutcomeStatistic) as WarpEntityServiceCache<StrategicOutcomeStatistic>;



  }

  getAvalibleStats(){
    const filter = EntityFilter.None;
    this.linkedstatIds = [];
    this.linkedStrategicStatIds = [];

    try {
    this.report.properties?.linkedstatistic.forEach(stat => {
      this.linkedstatIds.push(stat.id);
    });
    this.report.properties?.linkedstrategicoutcomestatisitc.forEach(stat => {
      this.linkedStrategicStatIds.push(stat.id);
    });
  } catch (error){}

    this.nextSub = this.statisticService.initQuery(filter, 9999999).getPage(0).subscribe(resp => {
     
      this.linkedStatOptions = [];
      for (const stat of resp) {
        try {
          let label: string;
          if (Array.isArray(stat.properties?.linkedstatisticgroup)) {
            label = stat.properties.linkedstatisticgroup[0].name + " -> " + stat.name;
          } else {
            label = stat.properties.linkedstatisticgroup.name + " -> " + stat.name;
          }
          this.linkedStatOptions.push({ 
            label: label, 
            value: stat.id, 
            name: stat.name, 
            id: stat.id 
          });
        } catch (_) {}
      }


   //   this.linkedStatOptions = resp.map((af) => ({ label: af.properties.linkedstatisticgroup[0].name + " -> " + af.name, value: af.id, name: af.name, id: af.id }))
    })

    this.nextSub = this.strategicOutcomeStatisticService.initQuery(filter, 9999999).getPage(0).subscribe(resp => {
      this.linkedStrategicStatOptions = resp.map((af) => ({ label: af.properties?.linkedstrategicoutcomesubcategory[0]?.name + " -> " + af.properties?.name, value: af.id, name: af.properties?.name, id: af.id }))
    })
  }


  ngOnInit(): void {


    this.nextSub = this.individualStatReportService
      .initQuery(EntityFilter.None, 1000000)
      .getPage(0)
      .subscribe((reports) => {
        this.reports = reports;

        this.reports.forEach(r => {
          r.actionMenu = new Array<MenuItem>();
          r.actionMenu.push({
            label: 'Delete',
            icon: 'pi pi-times',
            command: () => this.delete(r),
          });
          r.actionMenu.push({
            label: 'Edit',
            icon: 'pi pi-pencil',
            command: () => this.edit(r),
          });
        });

        this.reportsLoading = false;
      });
  }

  updateReportStats(event) {
    console.log("Update reports stats", this.linkedstatIds);
  }

  openModal(action) {
    this.showAddReport = true;
    console.log("This is the action", action)
    switch (action.toString()) {
      case "add":
        this.text = "Add Report";
        this.report = IndividualStatisitcReport.empty();
        this.report.property("name", "");
      case "edit":
        this.text = "Save Report";
      break;
    }
    this.getAvalibleStats();

  }


  updateReport(action) {
    this.loading = true;

    let tempLinkedStat: any[] = [];
    this.linkedstatIds.forEach(id => {
      tempLinkedStat.push({name: '', id: id})
    });
    if (tempLinkedStat.length > 0)
      this.report.properties.linkedstatistic = tempLinkedStat;

    let tempLinkedStrategicStat: any[] = [];
    this.linkedStrategicStatIds.forEach(id => {
      tempLinkedStrategicStat.push({ name: '', id: id })
    });
    if (tempLinkedStrategicStat.length > 0)
      this.report.properties.linkedstrategicoutcomestatisitc = tempLinkedStrategicStat;

    switch(action){
      case "Save Report":
        this.individualStatReportService.update(this.report).toPromise().then(resp => {
          this.loading = false;
        });
      case "Add Report":
        this.individualStatReportService.create(this.report).subscribe(resp => {
          this.loading = false;
        });
        break;
    }
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  edit(report) {
    this.report = report;
    console.log(report)

    this.openModal('edit');
  }

  delete(report) {
    report.deleteing = true;
    this.individualStatReportService.delete(report).then(resp => {
      this.reports = this.reports.filter(r => r.id !== report.id);
      report.deleteing = false;
    })
  }

  view(id) {
    this.router.navigateByUrl('individualstatisticreports/' + id.toString());
  }

}
