import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MessageService } from '@ripple/services';

import type { SelectBoxOption } from '../common/select-box.model';
import { SelectBoxFieldComponent, valueAsArray } from '../common/select-box-field.component';
import { merge } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Component({
  selector: 'ripple-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
})
// @dynamic
export class FormSelectComponent extends SelectBoxFieldComponent implements OnInit, OnDestroy {
  protected static availableFields = SelectBoxFieldComponent.commonSelectBoxFields.concat([
    'label-for-options',
    'question-options-field',
    'is-multi-select',
  ]);

  showOverlay = false

  formControlMulti = new FormControl();
  formControlSingle = new FormControl();

  get allControls() {
    return [this.formControlMulti, this.formControlSingle];
  }

  get logName() { return `EntitySelect [${this.multi ? 'multi' : 'single'}]`; }

  constructor(msg: MessageService) { super(msg); }

  ngOnInit(): void {
    this.log('form-select.component.ts ngOnInit()');

    // when an outside value changes, update our controls
    this.nextSub = this.formControl.valueChanges
      .pipe(
        startWith(this.formControl.value), // also run now
        map(valueAsArray) // convert to array if not already
      )
      .subscribe((externalValue) => {
        // this will update the select box options to hold their specify text
        this.mapOptionToSelectBoxOptions(externalValue);
        this.syncControls(externalValue)
      });

    // when an inside value changes, send it to formControl.
    this.nextSub = merge(this.formControlMulti.valueChanges, this.formControlSingle.valueChanges)
      .pipe(map(valueAsArray))
      .subscribe(internalValue => {
        this.formControl.markAsDirty();
        this.formControl.setValue(internalValue);
      });

    // old code, this handles deleted values. not used often, but prob should be updated
    if (this.formControl.value && this.formControl.value instanceof Array && this.to.options instanceof Array) {
      // check if values exist in the list
      const options = this.to.options;
      let deletedOptions = this.formControl.value.filter(v => options.findIndex(o => o.id === v.id) < 0);
      deletedOptions = deletedOptions.map(d => {
        if (!d.optionName.includes(' (Deleted)'))
          d.optionName += ' (Deleted)';
        return d;
      });
      this.to.options = [...this.to.options, ...deletedOptions];
    }
  }

  syncControls(externalValue: SelectBoxOption[]) {
    this.syncControlValue(externalValue);
    this.syncControlOptions();
  }

  syncControlValue(externalValue: SelectBoxOption[]) {
    // this will handle nulls and arrays and singles for us
    externalValue = valueAsArray(externalValue);

    if (this.multi)
      this.formControlMulti.setValue(externalValue, { emitEvent: false });
    else
      this.formControlSingle.setValue(externalValue[0], { emitEvent: false });
  }

  /** Get formControl settings and copy into our own controls */
  syncControlOptions() {
    for (const control of this.allControls) {
      if (this.formControl.disabled && !control.disabled)
        control.disable();

      if (this.formControl.enabled && !control.enabled)
        control.enable();

      if (this.formControl.errors)
        control.setErrors(this.formControl.errors);

      if (this.formControl.touched && !control.touched)
        control.markAsTouched();

      if (this.formControl.dirty && !control.dirty)
        control.markAsDirty();

      //TODO: figure out if we need to handle validators
    }
  }

  get multi() {
    return !!(
      (this.to.moduleSettings
        ? this.to.moduleSettings['allowmultiple'] === 'true'
        : false) || this.to.isMultiSelect
    );
  }

  setOverlay(show: boolean){
    this.showOverlay = show
  }

  specifyTextChange(event, option: SelectBoxOption) {
    // specify component did this already, we just need to update the model
    this.log('option', event, option);

    // The option received may be an updated copy of the one in formControl
    // so replace the option in the selected values with the one we just got
    const updatedValue = valueAsArray(this.formControl.value).map(currentOption => {
      if (currentOption.id === option.id)
        return option;

      return currentOption;
    });

    // update the formly model
    if (this.model && typeof this.key === 'string')
      this.model[this.key] = updatedValue;

    this.formControl.setValue(updatedValue);
    this.formControl.markAsDirty();
  }

  buttonOnClick($event) {
    if (this.to.additionalButtonOnClick)
      this.to.additionalButtonOnClick($event);
  }
}
