import { ValuesIterator } from '@ripple/models';
import { Observable, UnaryFunction, pipe, throwError, timer } from 'rxjs';
import { mergeMap, finalize, filter, map, withLatestFrom } from 'rxjs/operators';


/** only allows values to be emitted when the latest value emitted from `onOffObservable` is truthy */
export function onlyWhile<T>(onOffObservable: Observable<boolean>): UnaryFunction<Observable<T>, Observable<T>>;
/** only allows values to be emitted when the predicate is truthy for the latest value emitted from `onOffObservable` */
export function onlyWhile<T, F>(onOffObservable: Observable<F>, predicate: (item: F) => boolean): UnaryFunction<Observable<T>, Observable<T>>;

export function onlyWhile<T, F>(onOffObservable: Observable<F>, predicate: (item: F) => boolean = (item) => !!item) {
	return pipe(
		withLatestFrom<T, Observable<F>>(onOffObservable),
		filter(([_, onOff]) => predicate(onOff)),
		map(([value, _]) => value)
	);
}


export function castArray<T>() {
	return pipe(map((v: ValuesIterator<T>) => Array.from(v.values())));
}


export const genericRetryStrategy = ({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = [],
  errorString,
  log = console.log
}: {
  maxRetryAttempts?: number,
  scalingDuration?: number,
  excludedStatusCodes?: number[]
  errorString?: string,
  log?: (...args: any[]) => void
} = { }) => (attempts: Observable<any>) => {
  const errorStrings = errorString ? [errorString] : [];
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if ( retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error.status))
        return throwError(error);

      log( ...[...errorStrings, `Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`, error] );

      // retry after 1s, 2s, etc...
      return timer(retryAttempt * scalingDuration);
    }),
    finalize(() => log('Done Retrying.'))
  );
};
