import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache, TaskNotificationService, AuthService, InternalCookieService } from '@ripple/services';
import { EntityFilter,
  TaskNotificationMessage, MessageType, Notification, OngoingMessageStatus } from '@ripple/models';
import { MessageService, Message } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { first } from 'rxjs/operators';

@Component({
  selector: 'ripple-task-notification',
  templateUrl: './task-notification.component.html',
  styleUrls: ['./task-notification.component.scss'],
  providers: [MessageService ]
})
export class TaskNotificationComponent implements OnInit, OnDestroy {
  private testID = '';

  notificationService: WarpEntityServiceCache<Notification>;

  taskNotificationSubscription: Subscription;
  authServiceSubscription: Subscription;

  listOfInProgressMessages: Message[] = [];
  listOfInstantMessages: Message[] = [];

  loadingPastNotification = true;

  @Input() showIcon = true;
  @Input() toastPosition = 'top-right';
  @Input() viewAllLink = '';
  @ViewChild('op') primeOverlayPanel: OverlayPanel;

  MAX_NOTIFICATION = 30;

  overlayPanelShow = false;

  mute = false;

  constructor(
    private taskNotificationService: TaskNotificationService,
    authService: AuthService,
    private primengMessageService: MessageService,
    warpEntityFactory: WarpEntityCacheFactoryService,
    private cookieService: InternalCookieService
  ) {
    this.notificationService = warpEntityFactory.get(343) as WarpEntityServiceCache<Notification>;
    this.authServiceSubscription = authService.getLoggedInUser().subscribe(user => {
      if (user)
        this.notificationService
          .initQuery(EntityFilter
            .Advanced({
              EmployeeID_lid: user.entityId,
              TaskNotificationReadStatus: 'No'
            })
            .orderBy('created', 'desc'))
          .getPage(0, this.MAX_NOTIFICATION)
          .pipe(first())
          .subscribe(notifications => this.initPastNotificationList(notifications));
      else this.initPastNotificationList([]);
    });
    this.taskNotificationSubscription = this.taskNotificationService.onTaskNotificationReceived
      .subscribe((message: TaskNotificationMessage) => this.handleTaskNotificationData(message));
  }

  toggleMute() {
    this.mute = !this.mute;
    this.cookieService.setCookie('muteNotifications', this.mute ? 'true' : 'false');
  }

  ngOnInit(): void {
    this.mute = this.cookieService.getBooleanCookie('muteNotifications');
  }


  ngOnDestroy(): void {
    this.taskNotificationSubscription.unsubscribe();
    this.authServiceSubscription.unsubscribe();
  }

  initPastNotificationList(list: Notification[]) {

    console.log('notifications', list);

    list.forEach(notification => {
      const newMessage: Message = {};
      newMessage.severity = 'info';
      newMessage.detail = notification.properties['tasknotificationmessage'];
      newMessage.summary = notification.properties['notificationtitle'];
      newMessage.life = 30000;
      newMessage.data = {
        instant: notification.properties['tasknotificationtype'] === MessageType.Instant,
        inProgress: notification.properties['tasknotificationstatus'] === OngoingMessageStatus.InProgress,
        complete: notification.properties['tasknotificationstatus'] === OngoingMessageStatus.Complete,
        failed: notification.properties['tasknotificationstatus'] === OngoingMessageStatus.Failed,
        id: notification.properties['taskid'],
        read: notification.properties['tasknotificationreadstatus'] ?
                notification.properties['tasknotificationreadstatus'][0]?.optionName === 'Yes' : false,
        date: notification.createdECMA
      };
      if (newMessage.data.instant === false
        && newMessage.data.inProgress === true)
        this.listOfInProgressMessages.push(newMessage);
      else
        this.listOfInstantMessages.push(newMessage);

    });
    // console.log('past Notification: ', this.listOfPastNotifications);
    console.log('this.listOfInProgressMessages: ', this.listOfInProgressMessages);
    this.loadingPastNotification = false;
  }

  taskAlreadyFinishedOrFailed(list: Notification[], id: string): boolean {
    let retVal = false;
    list.forEach(notification => {
      if ((notification.properties['taskid'] === id
        && (notification.properties['tasknotificationstatus'] === OngoingMessageStatus.Complete
          || notification.properties['tasknotificationstatus'] === OngoingMessageStatus.Failed)
      ) || Date.parse(notification.updatedECMA) < Date.parse('2020-07-17T15:58:16.677')) {
        retVal = true;
      }
    });
    return retVal;
  }


  handleTaskNotificationData(message: TaskNotificationMessage) {
    console.log('message received: ', message);
    if (message.status !== 'on-going' && message.completion_progress !== '0') this.playAudio();
    const newMessage: Message = this.sortTaskNotificationMessages(message);
    // {
    //   severity: 'info',
    //   summary: message.taskName,
    //   detail: message.description,
    //   life: 300000,
    // };
    // this.primengMessageService.add(newMessage);
  }

  sortTaskNotificationMessages(incomingMsg: TaskNotificationMessage): Message {

    let retVal: Message = {
      severity: 'info',
      summary: incomingMsg.taskName,
      detail: incomingMsg.description,
      life: 500000,
      data: {
        inProgress: false,
        complete: false,
        failed: false,
        instant: true,
        progress: '',
        id: incomingMsg.task_id,
        date: new Date()
      }
    };

    let foundAlreadyInProgress = false;

    if (incomingMsg.type === MessageType.Task) {
      // if it's a task, check if the in-progress task already exists, if yes, update the status
      const existingMsg = this.listOfInProgressMessages.find(m => m.data.id === retVal.data.id);
      if (existingMsg) {
        retVal = existingMsg;
        foundAlreadyInProgress = true;
      }
      this.updateStatusForInProgress(retVal, incomingMsg);
      retVal.data.instant = false;
      if (!foundAlreadyInProgress) {
        if (retVal.data.inProgress === true)
          this.listOfInProgressMessages.unshift(retVal);
        else
          this.listOfInstantMessages.unshift(retVal);
        this.primengMessageService.add(retVal);
      }
    } else {
      this.primengMessageService.add(retVal);
    }

    this.testID = incomingMsg.task_id;
    // console.log('message converted: ', retVal);
    return retVal;
  }

  messageClosed(event) {
    // console.log('message closed: ', event);
  }


  // test-------------------
  changeStatus(id: string = this.testID) {
    this.listOfInProgressMessages.forEach((message, index, list) => {
      if (message.data.id === id) {
        message.data.complete = !message.data.complete;
        message.data.inProgress = !message.data.inProgress;
        list.splice(index, 1);
        message.data.instant = false;
        this.listOfInstantMessages.unshift(message);
      }
      // console.log('changed', this.listOfInProgressMessages);
    });
  }

  updateStatusForInProgress(msg: Message, statusUpdateMessage: TaskNotificationMessage) {
    msg.data.complete = statusUpdateMessage.status === OngoingMessageStatus.Complete;
    msg.data.inProgress = statusUpdateMessage.status === OngoingMessageStatus.InProgress;
    msg.data.failed = statusUpdateMessage.status === OngoingMessageStatus.Failed;
    msg.summary = statusUpdateMessage.taskName;
    msg.detail = statusUpdateMessage.description;
    msg.data.progress = statusUpdateMessage.completion_progress;
    this.listOfInProgressMessages.forEach((message, index, list) => {
      if (message.data.id === msg.data.id) {
        if (msg.data.complete || msg.data.failed) {
          list.splice(index, 1);
          this.listOfInstantMessages.unshift(message);
        }
      }
      // console.log('changed', this.listOfInProgressMessages);
    });
  }

  toggle(event: MouseEvent) {
    this.primeOverlayPanel.toggle(event);
  }

  getNumNotifications() {
    // write something to show the number of unread notifications
    return this.listOfInstantMessages.length + this.listOfInProgressMessages.length;
  }

  dismissAllMessages() {
    this.listOfInProgressMessages = [];
    this.listOfInstantMessages = [];
    this.taskNotificationService.dismissAll().subscribe(result => {
      console.log('all notifications are updated as read!', result);
    });
  }

  dismissMessage(msg: Message) {
    if (msg.data.inProgress) {
      const indexOfInProgressMessages = this.listOfInProgressMessages.findIndex(m => m.data.id === msg.data.id);
      this.listOfInProgressMessages.splice(indexOfInProgressMessages, 1);
    } else {
      const indexOfInstantMessages = this.listOfInstantMessages.findIndex(m => m.data.id === msg.data.id);
      this.listOfInstantMessages.splice(indexOfInstantMessages, 1);
    }
    this.taskNotificationService.dismiss(msg.data.id).subscribe(result => {
      console.log('notification is updated as read!', result);
    });
  }

  playAudio() {
    console.log(`Playing New message sound. ${this.mute ? '[Muted]' : ''}`);
    if (this.mute)
      return;
    const audio = new Audio();
    audio.src = 'assets/audio/NewMessage.mp3';
    audio.load();
    audio.play();
  }
}
