<p>report-questions works!</p>

<!-- <div class="form-group" [id]="'question-' + question?.cfimId" *ngIf="question.isCheckbox">
    <h4>{{question?.label}}</h4>
    <table border="1" class="results-table">
        <ng-container *ngFor="let choice of question?.checkBoxChoice; index as i;">
            <tr *ngIf="choice.customField.type === 'Checkbox'">
                <td width="70%"  *ngIf="choice.filtered" [style.background-color]="choice.filtered?'#c0c0c0':''"><strong>{{choice.label}}</strong></td>
                <td width="70%" *ngIf="!choice.filtered">{{choice.label}}</td>
                <td width="10%" (click)="UpdateFilter(choice)" [style.background-color]="choice.filtered?'#c0c0c0':''">{{(choice.filtered?'Remove Filter': 'Add Filter')}}</td>
                <td width="10%" class="results-right">{{choice.result}}</td>
                <td width="*" ></td>
            </tr>
        </ng-container>
        <tr style="background-color: #e0e0e0">
            <td colspan="2" >Total</td>
            <td class="results-right">{{total}}</td>
            <td ></td>
        </tr>
    </table>
</div>

<div class="form-group" [id]="'question-' + question?.cfimId" *ngIf="question.isRadio">
    <h4>{{question?.label}}</h4>
    <table border="1">
        <ng-container *ngFor="let choice of question?.customFieldChoices; index as i;">
            <tr>
                <td width="70%"  *ngIf="choice.filtered" [style.background-color]="choice.filtered?'#c0c0c0':''"><strong>{{choice.Text}}</strong></td>
                <td width="70%"  *ngIf="!choice.filtered">{{choice.Text}}</td>
                <td width="10%"  (click)="UpdateFilter(choice)" [style.background-color]="choice.filtered?'#c0c0c0':''">{{(choice.filtered?'Remove Filter': 'Add Filter')}}</td>
                <td width="10%" class="results-right">{{choice.result}}</td>
                <td width="*" class="results-right">{{(choice.percentage && choice.percentage !== "" ?choice.percentage:"0.0")}}%</td>
            </tr>
        </ng-container>
        <tr style="background-color: #e0e0e0">
            <td colspan="2" >Total</td>
            <td class="results-right">{{total}}</td>
            <td class="results-right">{{totalPercent.toFixed(1)}}%</td>
        </tr>
    </table>
</div> -->
