import { Injectable, Injector, OnDestroy } from "@angular/core";
import { Observable, Subscriber, Subscription, fromEvent } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { MessageService } from "./message.service";

export type ActionType   = 'CREATED' | 'DESTROYED' | 'MESSAGE';

interface EncodedMessage<T extends {}> {
  name: string,
  source: string,
  destination: string,
  action: ActionType,
  data: T,
};

export class CrossSiteCommunicationChannel<T extends {}> {
  constructor(
    public name: string,        // Channel name
    public source: string,      // Message origin
    public destination: string, // Message target
    private messageService: MessageService,
  ) {
    // Signal that the channel has been created
    const encodedMessage: EncodedMessage<T> = {
      name: this.name,
      source: this.source,
      destination: this.destination,
      action: 'CREATED',
      data: {} as T,
    };
    this.postMessage(encodedMessage);
  };

  public log(message: string, ...data: any[]) {
    this.messageService.add(`CrossSiteCommunication<${this.name}>`, message, ...data);
  };

  private get isInIFrame(): boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  };

  private postMessage(message: EncodedMessage<T>) {
    // Determine if we are in an iFrame
    if (this.isInIFrame) {
      // Send the message to the parent window
      window.parent.postMessage(message, this.destination);
    } else if (this.destination === window.location.origin) {
      // You can only send messages actively to someone on the same origin as yourself
      try {
        window.postMessage(message, this.destination);
      } catch (err) {
        // When on localhost, you can't actively ping a child window
        this.log(`Error sending encoded message to ${this.destination}: ${err}`);
      }
    }
  }

  public destroy() {
    // Signal that the channel has been destroyed
    const encodedMessage: EncodedMessage<T> = {
      name: this.name,
      source: this.source,
      destination: this.destination,
      action: 'DESTROYED',
      data: {} as T,
    };
    this.postMessage(encodedMessage);
  };

  public next(message: T) {
    // Build a message with appropriate headers
    const encodedMessage: EncodedMessage<T> = {
      name: this.name,
      source: this.source,
      destination: this.destination,
      action: 'MESSAGE',
      data: message,
    };
    this.postMessage(encodedMessage);
  };

  public subscribe(next?: (value: T) => void, error?: (error: any) => void, complete?: () => void): Subscription {
    return new Observable<T>((observer: Subscriber<T>) => {
      const listener = (event: MessageEvent) => {
        // Don't accept messages from those who aren't the target
        if (!event.origin.startsWith(this.destination))
          return;
        // Attempt to convert the message data to a CrossSiteCommunicationMessage
        const message = event.data as EncodedMessage<T>;
        // The event source should be the destination, and vice-versa
        if (message?.source !== this.destination || message?.destination !== this.source)
          return;
        // The event name should be the channel name, and we only listen for message types
        if (message?.name !== this.name || message?.action !== 'MESSAGE')
          return;
        observer.next(message.data as T);
      };
      const listener$ = fromEvent(window, 'message')
        .pipe(distinctUntilChanged())
        .subscribe(listener);
      return () => listener$.unsubscribe();
    }).subscribe(next, error, complete);
  };
};

@Injectable({
  providedIn: 'root',
})
export class CrossSiteCommunicationService implements OnDestroy {
  private targetOrigin: string;
  private channelSubscription: Subscription;

  public channels: { [name: string]: CrossSiteCommunicationChannel<any> } = {};

  constructor(
    private messageService: MessageService,
    private injector: Injector,
  ) {
    // if we are on local development, set the site urls to localhost
    this.targetOrigin = (window.location.origin.includes('localhost')) ?
      'http://localhost:4200' :
      window.location.origin;

    // Listen for messages from other windows to create channels
    const listener = (event: MessageEvent) => {
      // Don't accept messages from those who aren't the target
      if (!event.origin.startsWith(this.targetOrigin))
        return;
      // Attempt to convert the message data to a CrossSiteCommunicationMessage
      const message = event.data as EncodedMessage<any>;
      // The event source should be the destination, and vice-versa
      if (message?.source !== this.targetOrigin || message?.destination !== window.location.origin)
        return;
      // The event data should be completely empty
      if (Object.keys(message?.data).length > 0)
        return;
      // Only handle CREATED and DESTROYED messages
      switch (message.action) {
        case 'CREATED':
          // Create a channel with the given name
          const channel = this.createChannel(message.name);
          this.channels[message.name] = channel;
          break;
        case 'DESTROYED':
          // Destroy the channel with the given name
          this.closeChannel(message.name);
          break;
        default:
          break; // We don't care about other message types
      }
    };
    this.channelSubscription = fromEvent(window, 'message')
      .pipe(distinctUntilChanged())
      .subscribe(listener);
  };

  ngOnDestroy() {
    this.channelSubscription && this.channelSubscription.unsubscribe();
    Object.keys(this.channels).forEach(name => {
      this.channels[name].destroy();
      delete this.channels[name];
    });
  };

  public getChannel<T extends {}>(name: string): CrossSiteCommunicationChannel<T> {
    if (this.channels[name])
      return this.channels[name] as CrossSiteCommunicationChannel<T>;
    else {
      const channel = this.createChannel<T>(name);
      this.channels[name] = channel;
      return channel as CrossSiteCommunicationChannel<T>;
    }
  };

  private createChannel<T extends {}>(name: string): CrossSiteCommunicationChannel<T> {
    return new CrossSiteCommunicationChannel<T>(name,
      window.location.origin,
      this.targetOrigin,
      this.messageService);
  };

  public closeChannel(name: string) {
    if (this.channels[name]) {
      this.channels[name].destroy();
      delete this.channels[name];
    }
  };
};