<div *ngIf="loading" class="loading-box-spinner">
    <p-progressSpinner></p-progressSpinner>
</div>
<ng-container *ngIf="!loading">
    <p-card header="Form Question Filters" *ngIf="reportFilters?.length > 0">
        <table width="100%" border="1">
            <tr style="background-color: #e0e0e0">
                <td>Question</td>
                <td>Answer</td>
                <td></td>
            </tr>
            <tr *ngFor="let item of reportFilters">
                <td>{{ item.cfim.value.label }}</td>
                <td>{{ item.choice.value || item.choice.optionName }}</td>

                <td>
                    <button
                        type="button"
                        (click)="addOrRemoveFilter(item.cfim, item.choice)"
                        pButton
                        label="Remove Filter"
                        icon="pi pi-filter"
                    ></button>
                </td>
            </tr>
        </table>
    </p-card>
    <div *ngFor="let cfim of reportData | keyvalue">
        <h3>{{cfim.value.label}}</h3>
        <div class="p-grid">
            <div class="p-sm-12 p-md-12">
                <p-table [value]="cfim.value.options">
                    <ng-template pTemplate="body" let-choice>
                        <tr>
                            <td>{{choice.value || choice.optionName}}</td>
                            <td>{{choice.count}}</td>
                            <td>{{choice.count / cfim.value.total * 100 | number: '1.0-0'}}%</td>
                            <td>
                                <button
                                    type="button"
                                    (click)="addOrRemoveFilter(cfim, choice)"
                                    pButton
                                    label="{{ifInFilter(cfim, choice) >= 0 ? 'Remove' : 'Add'}} Filter"
                                    icon="pi pi-filter"
                                ></button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <div class="p-sm-12 p-md-12 resp-container">
                <div class="chart-container" style="display: block">
                    <canvas
                      baseChart
                      height="500"
                      style="height: 250px"
                      [datasets]="cfim.value.barChartData"
                      [labels]="cfim.value.barChartLabels"
                      [options]="barChartOptions"
                      [legend]="barChartLegend"
                      [chartType]="barChartType"
                      [colors]="chartColors"
                    ></canvas>
                  </div>
            </div>
        </div>
        
    </div>
</ng-container>
