import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BasePage } from '@ripple/core';
import { CustomFieldInModule, IGenFormlyOptions } from '@ripple/models';
import { WarpEntityLogService, WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { AfterSaveEvent } from '@ripple/ui';
import { ContactRole, EntityTypes } from '../../_core/models';

@Component({
  selector: 'sagesse-contact-role-details',
  templateUrl: './contact-role-details.component.html',
  styleUrls: ['./contact-role-details.component.scss']
})
export class ContactRoleDetailsComponent extends BasePage implements OnInit {

  contactRole: ContactRole;
  contactRoleService: WarpEntityServiceCache<ContactRole>;

  generatorOptions: IGenFormlyOptions = {
    hiddenCondition: (cfim: CustomFieldInModule) => cfim.unchangeableName.toLowerCase() === 'isprimary',
    styles: {
      name: 'p-sm-12 p-md-12 ui-fluid'
    }
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private logService: WarpEntityLogService,
    private serviceFactory: WarpEntityCacheFactoryService
  ) {
    super(logService);
  }

  ngOnInit(): void {
    this.contactRoleService = this.serviceFactory.get(EntityTypes.ContactRole) as WarpEntityServiceCache<ContactRole>;
    this.route.params.subscribe((params) => {
      const id = parseInt(params.entityId, 10);
      if (isNaN(id)) {
        this.contactRole = ContactRole.empty();
        this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
      } else {
        this.sub = this.contactRoleService.get(id).subscribe((contactRole) => {
          this.contactRole = contactRole;
          this.logService.logView(this.logFromUrl(''), this.getLogFormat(''));
        });
      }
    });
  }

  logFromUrl(url: string): number | number[] {
    return -1;
  }

  getLogFormat(url: string) {
    return '';
  }

  back() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }


  afterSave(e: AfterSaveEvent) {
    if (e.action === 'add') this.router.navigate([`../${e.entity.entityId}`], { relativeTo: this.route });
    else if (e.action === 'delete') {
      const queryParams = {};
      queryParams['msg'] = 'Contact Role Deleted Successfully!';
      this.router.navigate(['../'], { relativeTo: this.route, queryParams });
    }
  }

}
