import { WarpEntity } from '@ripple/models';
import { EntityTypes } from './entity-types';

const checkboxFields = ['selectallusers'];

export class Team extends WarpEntity {
    id: string | number;

    constructor(obj) {
        super(obj);
        this.entityTypeId = EntityTypes.Team;
        // convert checkboxFields Value to true or false
        for (const field of checkboxFields) {
            if (this.properties[field] && this.properties[field].length > 0)
            if (this.properties[field][0].id === 333) this.properties[field] = true;
            else this.properties[field] = false;
        }
        this.modify_preSave(true, Team.teamPreSave);
    }

    static empty(): Team {
        return new Team(super.emptyEntity(EntityTypes.Team));
    }

    static teamPreSave(w: WarpEntity): boolean {
        for (const field of checkboxFields) {
            if (w.properties[field]) w.cfcProperty(field, 333);
            else w.cfcProperty(field, 334);
        }
        return true;
    }

}