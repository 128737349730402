<div class="pane pane--lg-h100">
    <div class="pane__header">
        <div class="header">
            <input type="text" class="w-100" pInputText placeholder="Organization Search..." 
                    [(ngModel)]="organizationSearch"
                    (keyup)="filterOrganizations()" (keyup.Enter)="filterOrganizations(true)" />
        </div>

    </div>
    <div class="pane__body">
        <ripple-scroll-card class="h-100">
            <div class="scroll-card-header">
                <div class="p-grid align-items-center mx-0 mt-1">
                    <div class="p-col-2 text-center">
                        Part of Team
                    </div>
                    <div class="p-col-2 text-center" *ngIf="allowNonMemberOrganizations">
                        Non-Member
                    </div>
                </div>
            </div>
            <ripple-loading-box [loading]="pageLoading" [fullHeight]="true">
                <div class="p-grid align-items-center" *ngFor="let org of organizations">
                    <div class="p-col-2 text-center">
                        <p-checkbox name="organizations" [value]="org" (onChange)="onChange($event, org, true)"
                            [(ngModel)]="team.properties.organization"></p-checkbox>
                    </div>
                    <div class="p-col-2 text-center" *ngIf="allowNonMemberOrganizations">
                        <p-checkbox name="nonMemberOrganizations" [value]="org" (onChange)="onChange($event, org, false)"
                            [(ngModel)]="team.properties.nonmemberorganization"
                            [disabled]="disableNonMember(org)"></p-checkbox>
                    </div>
                    <div class="p-col">
                        <label>{{org.name}}</label>
                    </div>
                </div>
            </ripple-loading-box>
            <div class="scroll-card-footer">
                <p-paginator #myPaginator (onPageChange)="loadAndPaginate($event)" [rows]="pageSize" [totalRecords]="pageTotal"></p-paginator>
            </div>
        </ripple-scroll-card>
    </div>
</div>

<p-dialog header="{{addingOrDeleting}}"
    [(visible)]="addingOrDeletingDialogShow"
    *ngIf="addingOrDeletingDialogShow"
    contentStyleClass="h-100"
    [baseZIndex]="10000"
    [closable]="false"
    [modal]="true">
    <ripple-loading-box [loading]="addingOrDeletingDialogShow"></ripple-loading-box>
    <h3>{{addingOrDeleting}}...</h3>
</p-dialog>