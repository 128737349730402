import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationContact } from '../../../../_core/models';

@Component({
  selector: 'sagesse-organization-contact-card',
  templateUrl: './organization-contact-card.component.html',
  styleUrls: ['./organization-contact-card.component.scss']
})
export class OrganizationContactCardComponent implements OnInit {

  @Input() contact: OrganizationContact;
  @Input() inTeam = false;

  channelListShow = false;
  teamContactId: number;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  }

  get personChar () {
    return this.contact && this.contact.contactEntity && this.contact.contactEntity.properties['first name'] ?
      this.contact.contactEntity.properties['first name'].charAt(0) : '?';
  }

  get displayName() {
    return this.contact ? this.contact.contact.name : '';
  }

  goToContact() {
    this.router.navigate([`/contact/${this.contact.contact.id}`]);
  }

}
