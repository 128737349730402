import { WarpEntity } from '../../models/warp-entity';
// @dynamic
export class DocumentUpload extends WarpEntity {
  static entityTypeId = 614;

  entityTypeId = 614;

  static empty(): DocumentUpload {
    return new DocumentUpload(super.emptyEntity(614));
  }
}
