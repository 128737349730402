<label *ngIf="header">
  {{header}}
  <button *ngIf="removed.length > 0" pButton type="button" class="redo-button" (click)="undoRemove()" [disabled]="to.disabled">
    <span class="pi pi-replay"></span>
  </button>
</label>

<div class="elements">
  <ng-container *ngFor="let field of field.fieldGroup; let i = index;" >
    <ng-container *ngIf="field.templateOptions.asPanel; then panel; else normal"></ng-container>

    <ng-template #panel>
      <p-panel
        [style]="field.templateOptions.style"
        styleClass="form-repeat-panel"
        [toggleable]="true"
        [collapsed]="true"
        [toggler]="'header'"
        expandIcon="pi pi-angle-down" collapseIcon="pi pi-angle-up"
      >
        <p-header>
          <div class="ui-panel-title fb-panel-header" *ngIf="!field.templateOptions.hideTitle">
            <span class="ui-panel-title" [style]="field.templateOptions.headerStyle" *ngIf="!field.templateOptions.allowHtml" [innerText]="getPanelLabel(field, i)"></span>
            <span class="ui-panel-title" [style]="field.templateOptions.headerStyle" *ngIf="field.templateOptions.allowHtml" [innerHTML]="getPanelLabel(field, i)"></span>
          </div>

          <ng-container *ngIf="allowReordering">
            <span style="flex-grow: 1;"></span>

            <button [disabled]="i === field.fieldGroup.length - 1 || to.disabled"
              pButton type="button" class="p-panel-header-icon p-link" (click)="moveAndTrack(i, i + 1)"
            >
              <span class="pi pi-arrow-down"></span>
            </button>

            <button [disabled]="i === 0 || to.disabled"
              pButton type="button" class="p-panel-header-icon p-link" (click)="moveAndTrack(i, i - 1)"
            >
              <span class="pi pi-arrow-up"></span>
            </button>
          </ng-container>

          <button [disabled]="to.disabled"
            pButton type="button" class="p-panel-header-icon p-link delete" (click)="removeAndTrack(i)"
          >
            <span class="pi pi-trash"></span>
          </button>
        </p-header>

        <formly-field [field]="field"></formly-field>
      </p-panel>
    </ng-template>

    <ng-template #normal>
      <div class="p-field">
        <div class="p-grid ui-fluid">
          <formly-field class="p-col" [field]="field"></formly-field>
          <div class="p-col p-col-fixed">
            <button pButton icon="pi pi-times" (click)="removeAndTrack(i)" [disabled]="to.disabled"></button>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>

<ng-container *ngIf="!doBulkAdd; else bulkAdd">
  <div class="button-nav">
    <button style="width: unset;" pButton type="button" (click)="addAndTrack()" icon="pi pi-plus" [label]="(field.fieldGroup.length && to.addText) || to.addFirstText" [disabled]="to.disabled"></button>
    <button style="width: unset;" pButton type="button" *ngIf="to.allowBulkAdd" (click)="doBulkAdd = true" icon="pi pi-plus" [label]="to.bulkAddText || 'Bulk Add'" [disabled]="to.disabled"></button>
  </div>
</ng-container>

<ng-template #bulkAdd>
  <p-fieldset [legend]="to.bulkAddText || 'Bulk Add'">
    <div class="p-grid">
      <textarea
        #bulkAddTextarea
        pInputTextarea
        [rows]="4" [autoResize]="true"
        [formlyAttributes]="field"
        [placeholder]="to.bulkAddPlaceholder || 'Enter one item per line'"
        class="p-col-12"></textarea>
      <div class="p-col-12 button-nav">
        <button style="width: unset;" pButton type="button" (click)="doBulkAdd = false" icon="pi pi-times" label="Cancel" [disabled]="to.disabled"></button>
        <button style="width: unset;" pButton type="button" (click)="bulkAddAndTrack(bulkAddTextarea.value)" icon="pi pi-check" label="Apply" [disabled]="to.disabled"></button>
      </div>
    </div>
  </p-fieldset>

</ng-template>
