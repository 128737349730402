import { Component, Input, OnInit } from '@angular/core';
import { PrivacyService } from '@ripple/services';

@Component({
  selector: 'ripple-privacy-label',
  templateUrl: './privacy-label.component.html',
  styleUrls: ['./privacy-label.component.css']
})
export class PrivacyLabelComponent implements OnInit {
  @Input() defaultLabel: string;
  @Input() privacyLabel: string;
  curLabel: string;

  constructor(public privacyService:PrivacyService) {

  }

  ngOnInit(): void {
    this.curLabel = this.privacyService.isPrivacyModeEnabled() ? this.privacyLabel : this.defaultLabel;
    this.privacyService.privacyModeEnabled.subscribe(privacyModeEnabled => {
      this.curLabel = privacyModeEnabled ? this.privacyLabel : this.defaultLabel;
    });
  }


}
