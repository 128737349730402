<ng-content select=".scroll-card-header">
</ng-content>
<div #body class="scroll-card-body" (scroll)="onScroll($event?.target)">
  <div class="scroll-card-body-content">
    <ng-content>
    </ng-content>
  </div>
</div>
<ng-content select=".scroll-card-footer">

</ng-content>
