import { Observable, forkJoin, from, Subject, of } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { filter as rxjsFilter } from 'rxjs/operators';

import { Injectable, Injector } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';

import { WarpEntityCacheFactoryService, MessageService, GenericWarpEntityService, HubConnectionService, WarpEntityServiceCache } from '@ripple/services';
import { WarpEntityType, IRippleFrontendTemplate, IGenericObject, EntityFilter } from '@ripple/models';

import {
  entityTypes,
  questionTypes,
  questionDataTypes,
  fieldConfigField,
  fieldConfigSection,
  IQuestionDataTypeDescriptor,
} from './service-resources';

import {
  IQuestionTypeDescriptor,
  ExtendedFormlyFieldConfig,
} from './service-resources';
import { IFilterableEntityTypeDescriptor, WarpEntity } from '@ripple/models';
import { HubService } from 'ngx-signalr-hubservice';


@Injectable({
  providedIn: 'root'
})
export class FormBuilderService extends GenericWarpEntityService {
  parentTrees: { label: string, value: string }[];

  constructor(
    private serviceFactory: WarpEntityCacheFactoryService,
    injector: Injector, hubService: HubService,
    private hubConnectionService: HubConnectionService
  ) {
    super(injector, hubConnectionService);
  }

  public getQuestionData(): Observable<IQuestionTypeDescriptor> {
    return from(questionTypes);
  }

  public getQuestionDataTypes(): Observable<IQuestionDataTypeDescriptor> {
    return from(questionDataTypes);
  }

  public getSectionFields(): Observable<FormlyFieldConfig> {
    return from(fieldConfigSection);
  }

  public getFieldFields(): Observable<ExtendedFormlyFieldConfig> {
    return from(fieldConfigField);
  }

  getEntityList(): Observable<IFilterableEntityTypeDescriptor> {
    return from(entityTypes);
  }

  removeFormVersion(template: IRippleFrontendTemplate): Observable<any> {
    return super._delete(`/api/formbuilder/${template.EntityTypeID}/versions/${template.ID}`,
      `Remove Version: ${template.EntityTypeID} version ${template.Version}`);
  }

  saveFormBuilt(formData, formID): Observable<any> {
    if (formID === -1) formID = '';
    return super._post(`/api/formbuilder/${formID}`, formData, `Save Form Data: ${formID}`);
  }

  publishFormBuilt(formID, version?: number): Observable<any> {
    const action = `Publish Form ${formID}` + version ? ` Version ${version}.` : ' Latest Version.';
    return super._get(`/api/formbuilder/${formID}/publish/${version ? version : ''}`, action);
  }

  getForm(formID: number): Observable<WarpEntityType> {
    return super.getEntityStructure(formID); // cached ayo
  }

  deleteForm(formID: number): Observable<WarpEntityType> {
    return super._delete(`/api/formbuilder/${formID}`, `Get Form ${formID}`);
  }

  getFormVersions(formID: number): Observable<IRippleFrontendTemplate[]> {
    return super._get(`/api/formbuilder/${formID}/versions`, `Get Form ${formID} Versions`);
  }

  getForms() {
    return super._get(`/api/formbuilder/`, 'Get All Forms')
      .pipe( map(data => data.map( d => new WarpEntityType(d, this.authService.getLoggedInUser()))) );
  }

  getFormTypes(filter: Record<string, string> = {}) {
    let filterStr: string = Object.entries(filter || {})
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    if (filterStr)
      filterStr = '?' + filterStr;

    return super._get(`/api/formbuilder/formTypes${filterStr}`, 'Get All Forms Types')
    .pipe(map(
      data => Object.keys(data).map(
        d => new WarpEntityType({ ID: parseInt(d, 10), Name: data[d], UnchangeableName: data[d], IconClass: '' }, this.authService.getLoggedInUser()))));
  }

  getFormsParent(): Observable<{ label: string, value: string }[]> {
    if (this.parentTrees !== undefined) return of(this.parentTrees);
    return super._get(`/api/formbuilder/parentTrees`, 'Get parentTrees')
      .pipe(map( pt => this.parentTrees = pt));
  }


  getService(entity: IFilterableEntityTypeDescriptor, model: IGenericObject): WarpEntityServiceCache<WarpEntity> {
      return this.serviceFactory.get(entity.id);
  }


  getEntitiesFromID(entity: IFilterableEntityTypeDescriptor, model: IGenericObject): Observable<WarpEntity[]> {
    const out = new Subject<WarpEntity[]>();
    const service = this.serviceFactory.get(entity.id);

    const filter = (entity.entityFilter instanceof Function ? entity.entityFilter(model) : entity.entityFilter) || EntityFilter.All();
    // const query = service.initQuery(filter).maintainOnUpdate();

    // query.totalForFilter.subscribe(total => {
    //   const done: boolean[] = [];
    //   let list: WarpEntity[] = Array.from({ length: total });
    //   let index = 0;
    //   for (const page of query.entityIterator(service.settings.pageSize)) {
    //     done.push(false);
    //     const currentIndex = index;
    //     page.pipe(first()).toPromise().then(we => {
    //       // place the entities in the array at their correct location
    //       Array.prototype.splice.apply(list, [
    //         ...[currentIndex * service.settings.pageSize, service.settings.pageSize],
    //         ...we,
    //       ]);
    //       out.next(list);
    //       done[done.lastIndexOf(false)] = true;
    //       // if (done.indexOf(false) === -1) out.complete();
    //     });
    //     index ++;
    //   }
    // });
    // service.initQuery(filter, 9999999).maintainOnUpdate().getPage(0).subscribe(entites => {
    //   out.next(entites);
    // });
    // return out;

    let ret = service.initQuery(filter.Subset().idsOnly(), 9999999).getPage(0);
    if (entity.filterProperties)
      return ret.pipe(
        map ( results => results.filter(
          item => {
            return this.checkIfContains(item, entity)
          }
        )))
    return ret;
  }

  // tslint:disable-next-line: no-any <= generic function
  private checkIfContains(item: any, entity: any): boolean {
    if (!item) return true;
    let retVal = true;

    if (entity.filterProperties)
      for (const property in entity.filterProperties)
        if (Array.isArray(entity.filterProperties[property]))
          retVal = retVal && this.checkIfContains(item[property], entity.filterProperties[property]);
        else
          retVal = retVal && item[property] && (item[property].toString().includes(entity.filterProperties[property].toString()));
    else
      if (Array.isArray(entity))
        retVal = entity.every(entityElement => item.some(itemElement => this.checkIfContains(itemElement, entityElement)));
      else
        for (const property in entity)
          if (entity && Array.isArray(entity[property]))
            retVal = retVal && this.checkIfContains(item[property], entity[property]);
          else
            retVal = retVal && item[property] && (item[property].toString().includes(entity[property].toString()));

    return retVal;
  }

  public getAllData(): Observable<[FormlyFieldConfig, ExtendedFormlyFieldConfig]> {
    return forkJoin([ this.getSectionFields(), this.getFieldFields() ]);
  }

  getData(customEndPoint): Observable<any> {
    return super._get(customEndPoint, `get data with custom endpoint: ${customEndPoint}`).pipe(this.mapToWE());
  }
}
