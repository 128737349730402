<div #viewOnly
  *ngIf="to.viewOnly; else textEditor" class="ui-widget ui-corner-all ui-state-disabled"
  style="border: #E0E0E0; border-style: solid; padding: 10px; border-width: 1px;"
  [innerHTML]="replaceNewlinesWithBreaks(formControl.value) | safeHTML"
></div>

<ng-template #textEditor>
  <ripple-editor *ngIf="to.enableRichTextEditor; else editableDiv"
    [formControl]="formControl"
    [formlyAttributes]="field"
    [style]="{'height':'320px'}"
    [logKey]="key"
  ></ripple-editor>
</ng-template>

<ng-template #editableDiv>
  <div #textDiv *ngIf="to.customFormatting; else standard" class="editor" pInputTextarea contenteditable="true" (input)="onDivChange($event)"
    [autoResize]="autoResize" [formlyAttributes]="field" (blur)="blur()">
  </div>
</ng-template>

<ng-template #standard>
  <textarea pInputTextarea [formControl]="formControl"
    [rows]="rows" [autoResize]="autoResize"
    [formlyAttributes]="field" (blur)="blur()">
  </textarea>
</ng-template>
