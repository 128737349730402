import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { environment } from '@ripple/environment';
import { HttpClient } from '@angular/common/http';
import { IGenericObject } from '@ripple/models';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root',
})

export class FileService {

  private restURL = `${environment.restEndpointUrl}/api/v2/files`;
  private _uploadSubs = {};
  private _fileReaders = {};

  public constructor(private http: HttpClient) {

  }

  public uploadFile(
    file: File,
    identifier: string,
    permission = '',
    cfimID: number = null,
    parentEntityID: number = null
  ): BehaviorSubject<string> {
    
    const body: IGenericObject = {
      fileName: file.name,
      identifier,
      permission,
      cfimID,
      parentEntityID,
    };

    const subKey = (body.fileName);
    const bs = new BehaviorSubject(subKey);

    const reader = new FileReader();
    this._fileReaders[subKey] = reader;

    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const buffer = reader.result;
      
      if (buffer instanceof ArrayBuffer) {
        const fileArray = Array.from(new Uint8Array(buffer));
        body.base64 = btoa(fileArray.map((item) => String.fromCharCode(item)).join(''));
        
        const req = this.http.post(this.restURL.concat('/upload'), body).subscribe((value) => {
                      delete this._uploadSubs[bs.value.toString()];
                      bs.next(value.toString())
                    });
        this._uploadSubs[subKey] = req;

        delete this._fileReaders[subKey];
      }
    };

    return bs;
  }

  public cancelUpload(subKey: string): BehaviorSubject<string> {
    const bs = new BehaviorSubject(null);

    if (this._uploadSubs.hasOwnProperty(subKey)) {
      const req = this._uploadSubs[subKey];
      if (req) {
        req.unsubscribe();
        delete this._uploadSubs[subKey];
        bs.next(subKey);
      }
    } else if (this._fileReaders.hasOwnProperty(subKey)) {
      const reader = this._fileReaders[subKey];
      if (reader) {
        reader.abort();
        delete this._fileReaders[subKey];
        bs.next(subKey);
      }
    }
    return bs;
  }
  

  public dumpToFile(filename, json) {
    const blob = new Blob([json], { type: 'text/plain' });
    if ((window.navigator as IGenericObject).msSaveOrOpenBlob)
        (window.navigator as IGenericObject).msSaveBlob(blob, filename);
    else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
  }
}
