// NOTE: this should be rarely used, and only for default values
// IF YOU EVER WANT TO CHANGE A VALUE, it's probably best to do this in the /subscribers/[your site]/src/encironments/environment.ts
// NOT HERE!!!!!!!!!!!!!!!!

const routes = { /* */ };
const host = 'http://localhost:4200';
const rest = 'http://localhost:56696';

export let environment = {
  subscriberId: -1,  // you can probably ignore this, see comment at top of file
  production: false,
  hubUrl: rest + '/signalr',
  host,
  loginPage: '/Login.aspx',
  restEndpointUrl: rest,
  routes,
  caching: {
    defaultLoadStyle: 'lazy',
    defaultPageSize: 100,
  },
  forgotPasswordUrl: rest + '/recoverypass.aspx',
  noteTypeStyling: {
    Default:  {
      highlightColor: 'var(--gray100)',
      bgColor: 'var(--white)',
      iconClass: 'pi pi-tag' },
  },
  eventTypeStyling: {
  },

  tokenName: 'token',
  cookieLocation: 'cookie',

  /** Entities that cannot be ignored on signalR updates */
  entityTypesIgnoreFocus: [ 343 /* Task Notification */ ],

  /* WarpEntityLogService */
  logExemptComponents: [] as string[],

  /** console message filter */
  logFocus: [] as string[],
  groupLogsAfterInteraction: true,

  /* helper functions */
  __mergeEnvironment: (incomingEnv, routes = undefined) => {
    Object.keys(environment).forEach(prop => {
      environment[prop] = incomingEnv[prop];
    });
    if (routes) {
      environment.routes = routes
    }
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
