import { Component, ContentChild, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';

import { BasePage } from '@ripple/core';
import { EntityFilter, IGenFormlyOptions, WarpEntity } from '@ripple/models';
import { MessageService, WarpEntityLogService, WarpEntityServiceCache } from '@ripple/services';

import { AfterSaveEvent, EntityDetailsComponent } from '../entity-details/entity-details.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';


type LoadTransformFunction = (entities: WarpEntity[]) => WarpEntity[];
@Component({
  selector: 'ripple-default-entity-page',
  templateUrl: './default-entity-page.component.html',
  styleUrls: ['./default-entity-page.component.scss']
})
export class DefaultEntityPageComponent<T extends WarpEntity = WarpEntity> extends BasePage implements OnInit {
  get componentName(): string {
    return `DefaultEntityPageComponent - ${this.title || 'Unknown'}`;
  }

  public entity: T;
  selectedEntitySub: Subscription;
  entityChangeSub: Subscription;

  @Input() loadTransform: LoadTransformFunction = null;

  @Input() sortField: string;
  @Input() sortOrder: number = 1;
  @Input() entityConstructor= WarpEntity;
  @Input() title: string;
  @Input() editTitle = 'Details';
  @Input() iconClass: string;
  @Input() entityService: WarpEntityServiceCache<T>;
  @Input() filter: EntityFilter;

  @Input() editGenOptions: IGenFormlyOptions
  @Input() editFieldOrders: string[];
  @Input() showAddButton = true;
  @Input() showDeleteButton = true;
  @Input() showFilter = true;

  @Output()
  selected = new EventEmitter<T>();

  @Output()
  changes = new EventEmitter<{entity: T, model: T['properties']}>();

  @ViewChildren(EntityDetailsComponent)
  private details: QueryList<EntityDetailsComponent>;
  public detail: EntityDetailsComponent;
  private detailSub: Subscription;

  menuButtons: MenuItem[] = [];

  constructor(
    logService: WarpEntityLogService,
    private route: ActivatedRoute,
    private msg: MessageService
  ) {
    super(logService);
  }

  logFromUrl(url: string): number | number[] { return []; }
  getLogFormat(url: string): string { return `Viewed ${this.title} List`; }

  ngOnInit(): void {
    this.nextSub = this.route.queryParams.subscribe((params) => {
      if ('id' in params)
        this.selectById(Number(params.id));
    });
  }

  ngAfterViewInit(): void {
    // overlay panel destroys contents when inactive, so we need to re-create the query
    this.nextSub = this.details.changes.subscribe(details => {
      this.detail = details.first;

      if (this.detailSub)
        this.detailSub.unsubscribe();

      if (this.entityChangeSub && !this.entityChangeSub.closed)
        this.entityChangeSub.unsubscribe();

      if (this.detail) {
        this.nextSub = this.detailSub = this.detail.asyncButtons
          .subscribe(buttons => {
            this.menuButtons = buttons;
            if (this.menuButtons && this.menuButtons[0]) {
              this.menuButtons[0].items = this.menuButtons[0].items.filter(b => (this.showAddButton || !b.label.toLowerCase().includes("add")) && (this.showDeleteButton || !b.label.toLowerCase().includes("delete")))
            }
         });

        this.nextSub = this.entityChangeSub = this.detail.formGroup.valueChanges
          .subscribe((model) => this.changes.emit({entity: this.entity, model}));
      }
    });

    this.details.notifyOnChanges();
  }


  selectById(id: number) {
    console.log('selectById', id);
    let filter = EntityFilter.None.in(id);
    this.nextSub = this.selectedEntitySub = this.entityService.getPage(filter)
      .subscribe((page) => {
        if (page.has(id))
          this.entity = page.get(id);
      });
  }

  edit = (entity: T) => {
    this.select(entity);
  }

  add = () => {
    this.select(new this.entityConstructor(WarpEntity.emptyEntity(this.entityService.entityTypeId)) as T);
  }

  afterSave(e: AfterSaveEvent) {
    switch (e.action) {
      case 'add':
        return this.entity = e.entity as T;
      case 'delete':
        return this.entity = null;
    }
  }

  select(e: T) {
    // if we select an entity and the entity-details just popped back into existence, we need to refresh the ref to the options (idk why)
    if (e && this.editGenOptions)
      this.editGenOptions = {...this.editGenOptions };

    // clear the subscription for previous entity
    if (this.selectedEntitySub && !this.selectedEntitySub.closed)
      this.selectedEntitySub.unsubscribe();

    this.entity = e;

    this.selected.emit(e);
    this.msg.log(this.componentName, `Selected ${e ? e.label : 'none'}`, e, MessageService.VERBOSE);
  }
}
