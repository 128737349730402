import { Component } from '@angular/core';
import { RippleFieldGenericComponent } from '../ripple-field-generic/ripple-field-generic.component';

@Component({
  selector: 'ripple-form-dropdown-button',
  template: `
    <p-splitButton
      *ngIf="!viewOnly"
      [model]="to.items"
      [style]="{'float':'right', 'padding-top' : '30px'}"
      (onClick)="onSaveClick($event)"
      icon="pi pi-cog"
      >
    </p-splitButton>`,
  styleUrls: ['./form-dropdown-button.component.scss']
})
// @dynamic
export class FormDropdownButtonComponent extends RippleFieldGenericComponent {
  protected static availableFields = ['label-for-options', 'question-options-field'];

  onSaveClick($event) {
    if (this.to.onClick) this.to.onClick($event);
  }
}
