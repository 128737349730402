import { AfterViewInit, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { MessageService } from '@ripple/services';
import { Panel } from 'primeng/panel';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'ripple-form-repeat-panel',
  templateUrl: './form-repeat-panel.component.html',
  styleUrls: ['./form-repeat-panel.component.scss']
})
export class FormRepeatPanelComponent extends FieldArrayType implements AfterViewInit {
  doBulkAdd = false;
  @ViewChild('bulkAddTextarea')
  bulkAddTextarea: ElementRef<HTMLTextAreaElement>;

  @ViewChildren(Panel) panels: QueryList<Panel>;

  removed: { model, index: number }[] = [];

  constructor(private msg: MessageService) { super(); }

  ngAfterViewInit(): void {
    // when our panels change, or our model see which ones should be kept open
    forkJoin([
      this.panels.changes,
      this.field.formControl.valueChanges
    ])
    .subscribe(([panels, model]) => {
      this.panels.forEach((panel, i) => {
        const field = this.field.fieldGroup[i];
        const collapsedFunc = field.templateOptions?.collapsed;

        if (collapsedFunc && collapsedFunc instanceof Function) {
          let shouldCollapse = !!collapsedFunc(this.field.fieldGroup[i], i);
          if (!panel.collapsed && shouldCollapse)
            panel.collapse(null);
          else if (panel.collapsed && !shouldCollapse)
            panel.expand(null);
        }
      });
    });
  }

  get header() {
    return this.to.header;
  }

  get toRepeat() {
    return this.to.repeat || { };
  }

  get allowReordering() {
    return this.to.allowReordering;
  }

  _getPanelLabel(field): string | ((smallModel, index, field) => string) {
    return this.to.repeatHeader || field.templateOptions.header;
  }

  getPanelLabel(field: FormlyFieldConfig, i: number) {
    const label = this._getPanelLabel(field);
    return (typeof label === 'string') ? `${label} ${i + 1}` : label(field.form.value, i, field);
  }

  moveAndTrack(oldIndex: number, newIndex: number) {
    if (
      oldIndex === newIndex ||
      newIndex < 0 || newIndex >= this.field.model.length ||
      oldIndex < 0 || oldIndex >= this.field.model.length
    )
      return;

    this.reorder(oldIndex, newIndex);

    if (this.to.change) {
      const value = newIndex >= 0 ? this.field.model[newIndex] : this.field.model[this.field.model.length - 1];
      this.to.change(this.field, { action: 'move', value, oldIndex, newIndex });
    }
  }

  removeAndTrack(index: number) {
    if (confirm('Are you sure you want to delete this entry?'))
    {
      this.removed.push({ model: this.field.model[index], index });
      this.remove(index);
      if (this.to.change)
        this.to.change(this.field, { action: 'remove', value: this.removed[this.removed.length - 1] });

      this.msg.log('FormRepeatPanelComponent', `removing entry ${index}`, this.removed[this.removed.length - 1], this.field.model);
    }
  }

  addAndTrack(i?: number, initialModel?: any, opts?: { markAsDirty: boolean; }) {
    this.add(i, initialModel, opts);
    if (this.to.change) {
      const value = i >= 0 ? this.field.model[i] : this.field.model[this.field.model.length - 1];
      this.to.change(this.field, { action: 'add', value });
    }
  }

  /**
   * Bulk add takes an input and passes it to the onBulkAddLine callback from templateOptions
   * \
   * onBulkAddLine is expected to return an initial model to be added to the form
   */
  bulkAddAndTrack(input: string) {
    if (!input || input.length === 0) {
      this.doBulkAdd = false;
      return;
    }

    if (!this.to.onBulkAddLine) {
      this.bulkAddTextarea.nativeElement.value = 'Error: onBulkAddLine not defined!!';
      setTimeout(() => this.bulkAddTextarea.nativeElement.value = input, 5000);
      return;
    }

    const toAdd = input.split('\n')
      .map((line) => line.trim())
      .filter((line) => line.length > 0);

    if (toAdd.length === 0) {
      this.doBulkAdd = false;
      return;
    }

    toAdd.forEach((line) => this.addAndTrack(undefined, this.to.onBulkAddLine(line)));
    this.doBulkAdd = false;
  }

  undoRemove() {
    const { model, index } = this.removed.pop();
    this.addAndTrack(index, model);
    this.msg.log('FormRepeatPanelComponent', `restoring removed entry ${index}`, this.removed[this.removed.length - 1], this.field.model);
  }

  // courtesy of https://github.com/ngx-formly/ngx-formly/issues/2592
  // https://stackblitz.com/edit/repeat-section-move-with-options-9e51cv?file=src%2Fapp%2Frepeat-section.type.ts

  private reorder(oldI: number, newI: number) {
    this.reorderFields(this.field.fieldGroup, oldI, newI);
    this.reorderItems(this.model, oldI, newI);
    this.reorderItems(this.formControl.controls, oldI, newI);

    // make sure all the headers get a model update event
    this.field.fieldGroup = [...this.field.fieldGroup];
    this.formControl.updateValueAndValidity();
  }

  private reorderItems(obj: any[], oldI: number, newI: number) {
    const old = obj[oldI];
    obj[oldI] = obj[newI];
    obj[newI] = old;
  }

  private reorderFields(obj: any[], oldI: number, newI: number) {
    const old = obj[oldI];
    obj[oldI] = obj[newI];
    obj[oldI].key = `${oldI}`;

    obj[newI] = old;
    obj[newI].key = `${newI}`;
  }
}
