<div class="pane bg-gray100" *ngIf="hasPermission">
    <div class="pane__header pane__header--sticky bg-white">
        <div class="header">
            <h1 class="header__heading">
                Clare's Law Information: {{this.claresLaw?.referencenumber}}
            </h1>
            <div class="header__actions">
                <button pButton icon="pi pi-pencil" label="Fill in Result Form" (click)="goToExternalLink(formLink)">
                </button>
            </div>
        </div>
    </div>
    <div class="pane__body">
        <ripple-loading-box [loading]="loading" [fullHeight]="true">
            <div class="p-grid p-justify-center">
                <div class="p-col-12 p-md-5">
                    <div class="clares-law-info-table" [innerHTML]="infoTable | safeHTML"></div>
                </div>
            </div>
        </ripple-loading-box>
    </div>
</div>

<div *ngIf="!hasPermission" style="padding: 1%; text-align: center; display: inline">
    <p-messages [(value)]="msgs" [closable]="false"></p-messages>
</div>