<ripple-loading-box [loading]="!loaded || switchingEntity" [failed]="failed">
  <ng-template pTemplate="failed">
    Error loading details.
    <p-button label="Try Again" icon="pi pi-refresh" (click)="refresh()"></p-button>
  </ng-template>

  <ng-container *ngIf="devTools">
    <ripple-entity-debug [entity]="fields"></ripple-entity-debug>
    <button (click)="group.markAsPristine()">clean</button>
  </ng-container>


  <div style="text-align: center; width: 100%;">
    <i class="pi pi-spin pi-spinner" *ngIf="generatingFields"></i>
  </div>

  <form
    *ngIf="loaded"
    [formGroup]="group"
    class="{{ modifyPerm ? '' : 'view-only' }}"
    autocomplete="off"
  >
    <formly-form
      #formly
      *ngIf="formlyModel"
      [form]="group"
      [fields]="fields"
      [options]="options"
      [model]="formlyModel"
      (modelChange)="updateEntity($event)"
    ></formly-form>
  </form>

  <div
    class="button-nav button-nav--well button-nav--justify-end overflow-visible"
    *ngIf="!hideButtons && splitButtons && splitButtons.length > 0"
  >
    <ripple-invalid-fields-button
      #invalidFieldsButton
      [show]="!isValid"
      [fields]="fields"
    >
    </ripple-invalid-fields-button>
    <ripple-button-list [buttons]="splitButtons"></ripple-button-list>
  </div>
</ripple-loading-box>

<p-dialog
  class="incoming-changes-dialog"
  [(visible)]="updateModelDialog"
  [draggable]="false"
  [resizable]="false"
  position="top"
  modal="true"
  (onShow)="updateDescription()"
>
  <p-header>
    {{ _description }} data has been modified.
  </p-header>
  <span style="text-align: center">
    <p>
      {{ _description }} has been modified from somewhere else and new values are available.
    </p>
</span>
  <p-footer>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      icon="pi pi-trash"
      label="Discard my changes"
      (click)="discardChanges()"
      style="margin: 0;"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      icon="pi pi-times"
      label="Keep my changes"
      (click)="updateEntity(formlyModel); closeUpdateModelDialog()"
      style="margin: 0;"
    ></button>
  </p-footer>
</p-dialog>

<p-dialog
  class="destroy-dialog"
  [(visible)]="destroyDialog"
  [draggable]="false"
  [resizable]="false"
  modal="true"
  (onShow)="updateDescription()"
>
  <p-header>
    Are you sure that you want to destroy {{ _description }}?
  </p-header>
  <span style="text-align: center">
    <p>After {{ _description }} is destroyed, it can not be recovered</p>
  <input pInputText type="text" [(ngModel)]="destroyText" style="width: 100%;text-align: center;" />
  <p>To confirm, type 'DESTROY'</p>
</span>
  <p-footer>
    <button
      pButton
      type="button"
      class="ui-button-danger"
      icon="pi pi-{{ destroying ? 'spin pi-spinner' : 'times' }}"
      [disabled]="destroying"
      label="Destroy"
      (click)="destroyClick()"
      style="margin: 0;"
    ></button
  ></p-footer>
</p-dialog>
