<ng-container *ngIf="stage === 1">
    <div class="p-grid p-justify-center">
        <div class="p-col-6">
            <div>
                <label>Please first upload the import file:</label>
            </div>
            <div>
                <p-fileUpload #fileUpload mode="basic" name="upload[]" chooseIcon="pi pi-cloud-upload"
                    styleClass="ui-button-secondary file-upload-buttton"
                    customUpload="true" (uploadHandler)="uploadFile($event)"
                    (onUpload)="uploadFile($event)" [auto]="true" chooseLabel="Upload"></p-fileUpload>
            </div>

            <div>{{fileName}}</div>
            <div *ngIf="sourceResult">
                <button pButton class="ui-button-secondary" icon="pi pi-plus" label="Create New Form" (click)="formBuilderShow = true"></button>
                &nbsp;
                <button pButton class="ui-button-secondary" icon="pi pi-arrow-circle-right" label="Next" (click)="stage = 2"></button>
            </div>

        </div>
    </div>
</ng-container>

<ng-container *ngIf="stage === 2">
    <div class="p-grid ui-fluid">
        <div class="p-col-6 p-field">
            <label>Target</label>
            <p-multiSelect
                [options]="entityTypes"
                [filter]="true"
                (onChange)="updateTargetEntityTypes($event)"
                [(ngModel)]="targetEntityTypeIds"
            ></p-multiSelect>
        </div>
        <div class="p-col-6 p-field">
            <label>Source</label>
            <div>{{fileName}}</div>
        </div>


    </div>

    <div *ngIf="sourceResult">
        <ng-container *ngFor="let importerResult of importerResults">
            <h3>{{importerResult.entityType.name}}</h3>
            <div class="p-grid ui-fluid" >
                <div class="p-col-6 p-field">
                    <label>Identifier</label>
                </div>
                <div class="p-col-6 p-field">
                    <p-multiSelect
                        optionLabel="label"
                        [options]="importerResult.entityType.customFieldsInModules"
                        [filter]="true"
                        [(ngModel)]="importerResult.identifyingInfo"
                    ></p-multiSelect>
                </div>
                <div class="p-col-12" style="border-bottom: 1px var(--primary) solid;"></div>
            </div>
            <br/>
            <div class="p-grid ui-fluid">
                <ng-container *ngFor="let cfim of importerResult.entityType.customFieldsInModules">
                    <div class="p-col-6 p-field">
                        <label>{{cfim.label}} ({{cfim.unchangeableName}})</label>
                    </div>
                    <div class="p-col-6 p-field">
                        <p-dropdown
                            [options]="sourceOptions"
                            [(ngModel)]="importerResult.cfimsMapping[cfim.id].sourceColumn"
                            (onChange)="selectSourceColumn(importerResult, cfim, $event)"
                            placeholder="Select a column"
                            [showClear]="true"
                        ></p-dropdown>
                    </div>

                    <!-- text field special -->
                    <div class="p-col-6 p-offset-6"
                        *ngIf="cfim.cf_dataField !== 'customfieldchoiceid' && cfim.cf_dataField !== 'linkedentityid'">
                        <div class="p-grid ui-fluid">
                            <div class="p-col-6 p-field">
                                <label>Or:</label>
                            </div>
                            <div class="p-col-6 p-field">
                                <label>Static Value</label>
                            </div>
                        </div>
                        <div class="p-grid ui-fluid">
                            <div class="p-col-12 p-field">
                                <input type="text" pInputText placeholder="Static Value" [(ngModel)]="importerResult.cfimsMapping[cfim.id].staticValue">
                            </div>
                        </div>
                    </div>

                    <!-- custom field choices special -->
                    <div class="p-col-6 p-offset-6"
                        *ngIf="cfim.cf_dataField === 'customfieldchoiceid' && sourceColumnChoices[importerResult.cfimsMapping[cfim.id].sourceColumn]">
                        <div class="p-grid ui-fluid">
                            <div class="p-col-6 p-field">
                                <label>Multiselect?</label>
                            </div>
                            <div class="p-col-6 p-field">
                                <p-checkbox [(ngModel)]="importerResult.cfimsMapping[cfim.id].ifMultiselect" binary="true"
                                    (onChange)="ifMultiselectChange(importerResult.cfimsMapping[cfim.id].sourceColumn, $event)"
                                ></p-checkbox>
                            </div>
                        </div>
                        <div class="p-grid ui-fluid">
                            <div class="p-col-6 p-field">
                                <label>Choices in Source</label>
                            </div>
                            <div class="p-col-6 p-field">
                                <label>Custom Field Choices</label>
                            </div>
                        </div>
                        <div class="p-grid ui-fluid" *ngFor="let choice of sourceColumnChoices[importerResult.cfimsMapping[cfim.id].sourceColumn]">
                            <div class="p-col-6 p-field">
                                <label>{{choice}}</label>
                            </div>
                            <div class="p-col-6 p-field" *ngIf="importerResult.formChoices.length === 0">
                                <p-dropdown
                                    [options]="cfim.cf_choices"
                                    optionLabel="optionName"
                                    [(ngModel)]="importerResult.cfimsMapping[cfim.id].cfChoicesMapping[choice]"
                                    placeholder="Select a choice"
                                ></p-dropdown>
                            </div>
                            <div class="p-col-6 p-field" *ngIf="importerResult.formChoices.length > 0">
                                <p-dropdown
                                    [options]="getFormChoicesForUnchangeableName(importerResult.formChoices, cfim.unchangeableName)"
                                    optionLabel="optionName"
                                    [(ngModel)]="importerResult.cfimsMapping[cfim.id].cfChoicesMapping[choice]"
                                    placeholder="Select a choice"
                                ></p-dropdown>
                            </div>
                        </div>
                    </div>

                    <!-- linked entity special -->
                    <div class="p-col-6"
                        *ngIf="cfim.cf_dataField === 'linkedentityid'">
                        <div class="p-grid ui-fluid">
                            <div class="p-col-6 p-field">
                                <label>Entity Type</label>
                            </div>
                            <div class="p-col-6 p-field" *ngIf="importerResult.cfimsMapping[cfim.id].linkedEntityInfo.entityTypeId
                                && fullEntityTypes[importerResult.cfimsMapping[cfim.id].linkedEntityInfo.entityTypeId]">
                                <label>CFIM</label>
                            </div>
                        </div>
                        <div class="p-grid ui-fluid">
                            <div class="p-col-6 p-field">
                                <p-dropdown
                                    [options]="entityTypes"
                                    [(ngModel)]="importerResult.cfimsMapping[cfim.id].linkedEntityInfo.entityTypeId"
                                    placeholder="Select an entity type"
                                    (onChange)="linkedEntityTypeSelect($event)"
                                ></p-dropdown>
                            </div>
                            <div class="p-col-6 p-field" *ngIf="importerResult.cfimsMapping[cfim.id].linkedEntityInfo.entityTypeId
                                && fullEntityTypes[importerResult.cfimsMapping[cfim.id].linkedEntityInfo.entityTypeId]">
                                <p-dropdown
                                    [options]="fullEntityTypes[importerResult.cfimsMapping[cfim.id].linkedEntityInfo.entityTypeId].customFieldsInModules"
                                    optionLabel="label"
                                    [(ngModel)]="importerResult.cfimsMapping[cfim.id].linkedEntityInfo.cfim"
                                    placeholder="Select a CFIM"
                                ></p-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-6 p-field"
                        *ngIf="cfim.cf_dataField === 'linkedentityid'"></div>

                    <div class="p-col-12" style="border-bottom: 1px var(--primary) solid;"></div>
                </ng-container>

            </div>
        </ng-container>
    </div>
    <br/>
    <button pButton class="ui-button-secondary" icon="pi pi-plus" label="Import" [disabled]="importing" (click)="importConfirmShow = true"></button>
</ng-container>

<p-dialog
    [(visible)]="formBuilderShow"
    header="Create a New Form"
    [closable]="true"
    appendTo="body"
    [style]="{ width: '50vw' }" [baseZIndex]="10000"
>
    <div class="p-grid ui-fluid">
        <ng-container *ngFor="let sourceColumn of sourceColumns">
            <div class="p-col-6 p-field">
                <p-checkbox name="columnsSelected" [value]="sourceColumn.value" [label]="sourceColumn.label"
                    [(ngModel)]="sourceColumnsSelected"></p-checkbox>
            </div>
            <div class="p-col-6 p-field">
                <p-dropdown
                    *ngIf="sourceColumnsSelected.includes(sourceColumn.value)"
                    [options]="questionTypes"
                    [(ngModel)]="sourceColumnQuestionTypes[sourceColumn.value]"
                    placeholder="Select a Question Type"
                ></p-dropdown>
            </div>
        </ng-container>

    </div>
    <p-footer>
    <p-button
        icon="pi pi-times"
        label="Cancel"
        (click)="formBuilderShow = false"
    ></p-button>
    <p-button
        icon="pi pi-check"
        label="Generate"
        (click)="generateFormBuilderTemplate()"
    ></p-button>
    </p-footer>
</p-dialog>

<p-dialog
    [(visible)]="importConfirmShow"
    *ngIf="importConfirmShow"
    header="Importer Confirmation"
    [closable]="true"
    appendTo="body"
    [style]="{ width: '50vw' }" [baseZIndex]="10000"
>
    <div *ngFor="let importerResult of importerResults">
        <h3>{{importerResult.entityType.name}}</h3>
        <ngx-json-viewer [json]="importerResult" [expanded]="false"></ngx-json-viewer>
    </div>
    <p-footer>
    <p-button
        icon="pi pi-times"
        label="Cancel"
        (click)="importConfirmShow = false"
    ></p-button>
    <p-button
        icon="pi pi-check"
        label="Import"
        (click)="import()"
    ></p-button>
    </p-footer>
</p-dialog>
