import { AfterViewInit, Component, HostBinding, HostListener, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { WarpEntity } from '@ripple/models';
import { GenericWarpEntityService, MessageService, WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { FormBuilderFormState } from '../../../form-builder.component';
import { combineLatest, of, Subject, Subscription } from 'rxjs';
import { delay, filter, map, startWith, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'ripple-form-wrapper-field',
  template: `
    <div *ngIf="to.label && to.hideLabel !== true && to.hideTitle !== true" class="ui-widget label-wrapper" [title]="to.helpText || ''">
      <i *ngIf="markIfDirty && field.formControl.dirty" class="color-dot"></i>
      <label [for]="id">
        {{ to.label }}
        <ng-container *ngIf="to.required && to.hideRequiredMarker !== true">*</ng-container>
      </label>
      <i *ngIf="!to.alwaysShowHelpText && to.helpText && !to.viewOnly" class="ml-2 pi pi-info-circle" style="font-size: 1em" [pTooltip]="to.helpText" tooltipZIndex="99999999"></i>
    </div>

    <ng-container #fieldComponent></ng-container>
    <small *ngIf="to.alwaysShowHelpText && to.helpText">{{ to.helpText }}</small>

    <div class="signature-info" *ngIf="signedBy">
      <label *ngIf="showAllSignatureInfo">
        <span>{{ signedBy.name }}</span><ng-container *ngIf="signedBy.title">
          - <span>{{ signedBy.title }}</span>
        </ng-container><ng-container *ngIf="signedBy.license">,
          <span>{{ signedBy.license }}</span>
        </ng-container>
      </label>
      <span *ngIf="showAllSignatureInfo || dateOnly" [title]="signedBy.date | date:'medium'">{{ signedBy.date | date:'fullDate'}}</span>
    </div>

    <div class="ui-message ui-widget ui-corner-all ui-message-error ui-messages-error" *ngIf="showError">
      <formly-validation-message class="ui-message-text" [field]="field"></formly-validation-message>
    </div>
  `,
  styleUrls: ['./form-wrapper-field.component.scss']
})
export class FormWrapperFieldComponent extends FieldWrapper implements OnDestroy, OnInit {
  @ViewChild('fieldComponent', { read: ViewContainerRef }) fieldComponent: ViewContainerRef;

  @HostBinding('class.changed') changed = false;

  sub = new Subscription();

  userInteraction = new Subject< MouseEvent | KeyboardEvent | FocusEvent>();
  @HostListener('click', ['$event'])
  @HostListener('keydown', ['$event'])
  @HostListener('focusout', ['$event'])
  onUserInteraction(event: MouseEvent | KeyboardEvent | FocusEvent) {
    this.userInteraction.next(event);
  }

  private userService: WarpEntityServiceCache<WarpEntity>;

  constructor(
    private factory: WarpEntityCacheFactoryService,
    private msg: MessageService
  ) {
    super();
    this.userService = this.factory.get<WarpEntity>(583);
  }

  recentChangeFrame: number;

  recentChange() {
    cancelAnimationFrame(this.recentChangeFrame);

    requestAnimationFrame(() => {
      this.changed = true;
      this.recentChangeFrame = requestAnimationFrame(() => this.changed = false);
    });

  }

  @HostListener('click', ['$event.target'])
  onClick(thisElement: HTMLElement) {
    if (this.formControl && !this.formControl.touched) {
      this.log('client interaction, marking as touched');
      this.formControl.markAsTouched();
    }
  }

  get markIfDirty() {
    return this.to.markIfDirty || this.formState.showChanges;
  }

  ngOnInit(): void {
    this.sub.add(this.formDataEntity$?.subscribe(() => this.grabSignedByInfo()));

    this.sub.add(
      combineLatest([
        this.userInteraction.pipe(
          startWith(null as Event),
          map( e => [e, performance.now()] as const)),
        this.formControl.valueChanges.pipe(
          map(v => [v, performance.now()] as const)),
        of([true]).pipe(delay(200), startWith(false)),
      ]).pipe(
        throttleTime(75, undefined, { leading: false, trailing: true}),
        filter(([[userInteraction, timeOfUserInteraction], [newValue, timeOfChange], start]) => {
          // wait for some time before we start, ignore all things
          if (!start)
            return false;

          // the user has not yet interacted
          if (!userInteraction)
            return true;

          const epsilon = 75; // milliseconds
          const timeSinceUserInteraction = timeOfChange - timeOfUserInteraction;

          // value has changed, and user has not interacted for a while -> this is a programmatic change
          if (timeSinceUserInteraction > epsilon)
            return true;
          // user interacted, but value hasnt changed -> this is a user interaction
          else if (timeSinceUserInteraction < -epsilon)
            return false;
          // user interacted, and value has changed, within a short time -> this is a user interaction
          else
            return false;
        }),
      ).subscribe(() => this.recentChange())
    );
  }

  ngOnDestroy(): void {
    try { this.sub.unsubscribe(); } catch (e) { }
  }

  get formDataEntity$() {
    return this.options && (<FormBuilderFormState>this.formState)?.formDataEntity;
  }

  get formDataEntity() {
    return this.formDataEntity$?.value;
  }

  private get _signedField() {
    return this.to.isSignedField?.id;
  }

  get fieldIsSigned() {
    return this._signedField && this._signedField !== 'false';
  }

  get dateOnly() {
    return this._signedField == 'dateOnly';
  }

  get showAllSignatureInfo() {
    return this._signedField == 'true';
  }

  signedBy: {
    id: number,
    name: string,
    date: Date,
    license?: string,
    title?: string,
  } = undefined;

  grabSignedByInfo() {
    const signedFields = this.formDataEntity?.signedFields;
    if (!signedFields || !signedFields[<string>this.key] || !signedFields[<string>this.key][0])
      return this.signedBy = undefined;

    this.log('Getting Signed By Info');

    const signedBy = signedFields[<string>this.key][0];
    this.signedBy = { id: signedBy.id, name: signedBy.name, date: new Date(signedBy.date)};

    this.userService.get(signedBy.id)
      .subscribe((user: WarpEntity) => {
        this.signedBy = {
          id: signedBy.id,
          name: signedBy.name,
          date: new Date(signedBy.date),
          license: user?.properties?.licensenumber,
          title: user?.properties?.designationtitle
        };
    });
  }

  get logName() { return this.field.type; }

  protected log(...args) {
    this.msg.add(
      `FormbuilderWrapper ${this.logName} (${this.to.label})`, ...args, MessageService.VERBOSE);
  }
}
