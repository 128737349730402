import { Component, Input, OnInit } from '@angular/core';
import { EntityFilter, EntityTag, FilterOperator, SubscriptionContainer, WarpEntity } from '@ripple/models';
import { WarpEntityCacheFactoryService, WarpEntityServiceCache } from '@ripple/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ripple-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent extends SubscriptionContainer implements OnInit {

  @Input() entity: WarpEntity;
  @Input() appendTo = 'body';
  filteredTags: EntityTag[] = [];
  tagService: WarpEntityServiceCache<EntityTag>;
  currentSub: Subscription;
  adding = false;

  constructor(
    protected serviceFactory: WarpEntityCacheFactoryService
  ) {
    super();
    console.log('create tag service', EntityTag.entityTypeId);
    this.tagService = this.serviceFactory.get(EntityTag.entityTypeId);
  }

  ngOnInit(): void {
    if(this.entity && 'entitytag' in this.entity?.properties && !(this.entity?.properties.entitytag instanceof Array)) {
      this.entity.properties.entitytag = [];
    }
  }

  ngOnChanges() {
    if(this.entity && 'entitytag' in this.entity?.properties && !(this.entity?.properties.entitytag instanceof Array)) {
      this.entity.properties.entitytag = [];
    }
  }

  search(event, addNew = false) {
    let text = event.query;
    if (addNew) {
      text = event.srcElement && event.srcElement.value ? event.srcElement.value : '';
    }
    console.log('Tag search', text);
    const filter = EntityFilter.Advanced({
      key: 'name',
      value: text,
      operator: FilterOperator.Like
    })
    .Advanced({ entityTypeId: this.entity.entityTypeId })
    .orderBy('name', 'asc');

    if (this.currentSub) this.currentSub.unsubscribe();
    this.nextSub = this.currentSub = this.tagService.initQuery(filter, 9999999).getPage(0).subscribe(result => {
      this.filteredTags = result;
      console.log('result', this.filteredTags);
      if (this.filteredTags.length === 0 && addNew && text) {
        this.addTag(event);
      }
    });
  }

  addTag(event) {
    console.log('addTag', event.srcElement.value);
    if (event.srcElement && event.srcElement.value && !this.adding) {
      const tokenInput = event.srcElement as any;
      const name = tokenInput.value;
      console.log('add new tag: ', name);
      const newTag = EntityTag.empty();
      newTag
        .property('name', name)
        .property('entitytypeid', this.entity.entityTypeId);
      this.adding = true;
      this.tagService.create(newTag).subscribe(newTagId => {
        newTag.id = newTagId;
        newTag.entityId = newTagId;
        if (this.entity.properties.entitytag && this.entity.properties.entitytag instanceof Array) {
          this.entity.properties.entitytag = [...this.entity.properties.entitytag, newTag];
        } else {
          this.entity.properties.entitytag = [newTag];
        }
        tokenInput.value = '';
        this.adding = false;
        
      }, () => {
        alert("You don't have permission to create new tags, please contact a supervisor to add new tags.");
        this.adding = false;
      });
    }
    
  }

}
